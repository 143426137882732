import React from 'react';
import { useNavigate } from 'react-router-dom';
import { goBack } from '../../helpers/helpers';
import './Header.scss';

function Header({ title, isEdit, backRoute }) {
  const navigate = useNavigate();
  return (
    <div className="header">
      <h1
        className="dflex header"
        onClick={() => {
          goBack(navigate, backRoute);
          //setEliminationData(getCombinedData());
        }}
      >
        <img
          style={{ transform: 'rotate(90deg)', opacity: 0.5 }}
          src="/icons/chevron-down.svg"
          alt="back"
        />{' '}
        <div>
          {isEdit ? 'Edit' : 'Add New'} {title}
        </div>
      </h1>
    </div>
  );
}

export default Header;
