export const practitioners = [
  {
    name: 'EMILY JIANG, MD',
    link: 'https://www.ifm.org/practitioners/emily-jiang-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Emily-Jiang-0075_edited-400x400.png',
    contactText:
      'Address\n771 E Daily Dr STE 125\nCamarillo, CA 93010 US\n805-233-3314',
    state: 'ca',
  },
  {
    name: 'AARON EREZ, DO',
    link: 'https://www.ifm.org/practitioners/aaron-erez/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Aaron_Erez_015r-3-400x400.jpg',
    contactText:
      'Address\nConsultations by Videochat or by phone, free intro-call available, please email me or see my website to book an appointment.\nSan Diego, CA 92122 US\n858-255-0770',
    state: 'ca',
  },
  {
    name: 'ALISON HUNTER',
    link: 'https://www.ifm.org/practitioners/alison-mitchell/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n14127 Capri Dr\nSuite 5A\nLos Gatos, CA 95032 US\n(831) 239-2623',
    state: 'ca',
  },
  {
    name: 'AMY M NETT, MD',
    link: 'https://www.ifm.org/practitioners/amy-m-nett-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/AmyNettBrandImages_AshleyKelemen-9-400x400.jpg',
    contactText:
      'Address\n1725 State Street, Suite B\nSanta Barbara, CA 93101 US\n(805) 364-4782',
    state: 'ca',
  },
  {
    name: 'AMY PETRARCA, MS RN LAC DIPL OM IFMCP',
    link: 'https://www.ifm.org/practitioners/amy-petrarca-mstcm-rn-lac-dipl-om/',
    avatar: 'https://www.ifm.org/wp-content/uploads/AmyP8834-400x400.jpg',
    contactText:
      'Address\n406 Cortland Avenue\nSan Francisco, CA 94110 US\n(415) 846-0963',
    state: 'ca',
  },
  {
    name: 'AMYLEE AMOS, MS',
    link: 'https://www.ifm.org/practitioners/amylee-amos-ms-rdn-ldn/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Amylee_Amos-0083_Web-400x400.jpg',
    contactText: 'Address\n4474 Market Street\nSuite 502\nVentura, CA 93003 US',
    state: 'ca',
  },
  {
    name: 'ANA VIRGINIA JOFILI, MD',
    link: 'https://www.ifm.org/practitioners/ana-virginia-kato-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n2001 S Barrington ave\nSuite 316\nLos Angeles, CA 90025 US\n4243695052',
    state: 'ca',
  },
  {
    name: 'ANDREA M DALVE-ENDRES, MD',
    link: 'https://www.ifm.org/practitioners/andrea-michelle-dalve-endres/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/andrea-black-crop-1280x1280-400x400.jpg',
    contactText:
      'Address\n4435 First Street, #345\nLivermore, CA 94551 US\n9253154077',
    state: 'ca',
  },
  {
    name: 'ANGELA K GRIFFITHS, DC',
    link: 'https://www.ifm.org/practitioners/angela-k-griffiths-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/5A3B0005-400x400.jpg',
    contactText:
      'Address\n20885 Redwood Rd., #308\nCastro Valley, CA 94546 US\n(510) 473-5725',
    state: 'ca',
  },
  {
    name: 'ANGELICA SALAZAR, MD',
    link: 'https://www.ifm.org/practitioners/angelica-salazar-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/p2543331425-o860898321-3-400x400.jpg',
    contactText:
      'Address\n5819 Soquel Drive\nSuite A\nSoquel, CA 95073 US\n8313322044',
    state: 'ca',
  },
  {
    name: 'ANITA KRIPLANI, MD',
    link: 'https://www.ifm.org/practitioners/anita-kriplani-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/fullsizeoutput_142f-400x400.jpeg',
    contactText:
      'Address\n1245 Wilshire Blvd\nste 680\nLos Angeles, CA 90017 US\n(213) 269-4576',
    state: 'ca',
  },
  {
    name: 'BASIMA WILLIAMS, DO',
    link: 'https://www.ifm.org/practitioners/basima-williams-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2020-03-23-at-7.46.21-AM-400x400.png',
    contactText:
      'Primary Address\n4025 Avati Dr.\nSan Diego , CA 92117 US\n636-634-0560',
    state: 'ca',
  },
  {
    name: 'BILL BARLEY, MD',
    link: 'https://www.ifm.org/practitioners/bill-barley-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Capture-1-400x400.png',
    contactText:
      'Address\n1004 Fowler Way\nSuite 1\nPlacerville, CA 95667 US\n(530) 626-8003',
    state: 'ca',
  },
  {
    name: 'BRANDY ZACHARY',
    link: 'https://www.ifm.org/practitioners/brandy-ashley-zachary/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.-Zachary-headshot-red-landscape-CROPPED-400x400.jpeg',
    contactText:
      'Primary Address\n2872 Ygnacio Valley Rd.\nSte. 440\nWalnut Creek, CA 94598 US\n925-788-6300',
    state: 'ca',
  },
  {
    name: 'BRENT A CAPLAN, DC',
    link: 'https://www.ifm.org/practitioners/brent-caplan-dc-cfmp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr-Brent-Square-Headshot-400x400.jpg',
    contactText:
      'Primary Address\n1730 S Victoria Ave\nSuite 230\nVentura, CA 93003 US\n(805) 556-7200',
    state: 'ca',
  },
  {
    name: 'BRIANNA BROWNING',
    link: 'https://www.ifm.org/practitioners/brianna-brownfield-lac/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Bri-Portraits-2-small-400x400.jpg',
    contactText:
      'Primary Address\n10049 Martis Valley Rd.\nSuite G\nTruckee, CA 96161 US\n(530) 563-5252',
    state: 'ca',
  },
  {
    name: 'CAMBRIA JUDD, MD',
    link: 'https://www.ifm.org/practitioners/cambria-judd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/cambria-judd-md-400x400.jpg',
    contactText:
      'Address\n4699 Jamboree Rd\nNewport Beach, CA 92660 US\n949 557 0714',
    state: 'ca',
  },
  {
    name: 'CAROLYN FINNEGAN, DC',
    link: 'https://www.ifm.org/practitioners/carolyn-finnegan/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Close-up-Headshot-3-2022-400x400.jpeg',
    contactText:
      'Address\n600 Grand Ave.\nSuite 301\nOakland, CA 94610 US\n5102243436',
    state: 'ca',
  },
  {
    name: 'CHIEN-WEI KUNG',
    link: 'https://www.ifm.org/practitioners/chien-wei-kung-mms-pacrd/',
    avatar: 'https://www.ifm.org/wp-content/uploads/my-profile-400x400.png',
    contactText:
      'Address\n6815 Fair Oaks Blvd\nCarmichael, CA 95608 US\n9164814389',
    state: 'ca',
  },
  {
    name: 'CHRISTINA MARINO, DAOM, L.AC, DIPL.OM, DIPL. AC, MS, IFMCP',
    link: 'https://www.ifm.org/practitioners/christina-marino-daom-lac/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Christina-Headshot2-400x400.jpg',
    contactText:
      'Primary Address\n10645 Riverside Dr\nToluca Lake, CA 91602 US\n818-505-9511',
    state: 'ca',
  },
  {
    name: 'CHRISTOPHER S SPENCER, M.D',
    link: 'https://www.ifm.org/practitioners/christopher-spencer-m-d/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n2474 Alicante Court\nSanta Rosa Valley, CA 93012 US\n8054913195',
    state: 'ca',
  },
  {
    name: 'CLARE CATTARIN',
    link: 'https://www.ifm.org/practitioners/clare-cattarin-msn-fnp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Clare-IFM-picture-400x400.jpeg',
    contactText:
      'Address\n2920 Webster St\nSan Francisco, CA 94123 US\n(415) 531-9775',
    state: 'ca',
  },
  {
    name: 'CORINNE BASCH, MD',
    link: 'https://www.ifm.org/practitioners/corinne-basch-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n4641 Valley East Blvd\nSuite 2\nArcata, CA 95521 US\n(707) 840-4701',
    state: 'ca',
  },
  {
    name: 'CRAIG C MORTENSEN, DC',
    link: 'https://www.ifm.org/practitioners/craig-c-mortensen-dc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.-Craig-updated-pic-400x400.jpg',
    contactText:
      'Primary Address\n151 N. Kraemer Blvd.\nSuite 115\nPlacentia, CA 92870-1836 US\n833-372-7244',
    state: 'ca',
  },
  {
    name: 'CRISTAL SPELLER',
    link: 'https://www.ifm.org/practitioners/cristal-dawn-speller/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2020-01-22-at-5.45.50-PM-400x400.png',
    contactText:
      'Address\n230 N Maryland Ave Suite 110\nGlendale, CA 91206 US\n(818) 551-0464',
    state: 'ca',
  },
  {
    name: 'D DAWN MOTYKA, MD',
    link: 'https://www.ifm.org/practitioners/d-dawn-motyka-md-faafp-fabma/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n930 Mission St\nSuite 5\nSanta Cruz, CA 95060 US\n(831) 421-0197',
    state: 'ca',
  },
  {
    name: 'DANIEL E RIEDERS, MD',
    link: 'https://www.ifm.org/practitioners/daniel-e-rieders-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n2303 Camino Ramon\nSuite 208\nSan Ramon, CA 94583 US\n(650) 447-6213',
    state: 'ca',
  },
  {
    name: 'DANIELLE JACQUELINE DOUGLAS, MD',
    link: 'https://www.ifm.org/practitioners/danielle-jacqueline-douglas/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Headshot-3-400x400.jpeg',
    contactText: 'Address\n7723 Fay Avenue\nLa Jolla , CA 92037 US\n3106976333',
    state: 'ca',
  },
  {
    name: 'DARCY OIKAWA',
    link: 'https://www.ifm.org/practitioners/darcy-oikawa/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n5 Bon Air Rd. Suite 127\nLarkspur , CA 94939 US\n628-224-0762',
    state: 'ca',
  },
  {
    name: 'DAWN JACOBSON, MD',
    link: 'https://www.ifm.org/practitioners/dawn-jacobson-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/DSC03130-copy-400x400.jpg',
    contactText:
      'Address\n8550 Santa Monica Blvd, 2nd Floor\nWest Hollywood, CA 90069 US',
    state: 'ca',
  },
  {
    name: 'DIANNE MICHELLE HINTON, FNP',
    link: 'https://www.ifm.org/practitioners/dianne-hinton-fnp/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1120 13th st\nSuite B\nModesto, CA 95354 US\n(209) 247-9170',
    state: 'ca',
  },
  {
    name: 'ERIK LUNDQUIST, MD',
    link: 'https://www.ifm.org/practitioners/erik-lundquist-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Practioners-in-clinic-2-400x400.jpg',
    contactText:
      'Primary Address\n27450 Ynez Rd\nSuite 100\nTemecula, CA 92590 US\n(951) 383-4333',
    state: 'ca',
  },
  {
    name: 'FILOMENA TRINDADE, MD, MPH, IFMCP, ABOIM. FAARM, ABFM',
    link: 'https://www.ifm.org/practitioners/filomena-trindade-md-mph/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Captura-de-ecr%C3%A3-2019-11-18-%C3%A0s-15.20.07-400x400.png',
    contactText:
      'Primary Address\n603 Capitola Ave\nCapitola, CA 95010 US\n(831) 462-4441',
    state: 'ca',
  },
  {
    name: 'FRANNE BEREZ, MD',
    link: 'https://www.ifm.org/practitioners/franne-berez-md-nd/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n13172 capstone drive\nsan diego, CA 92130 US\n4124016577',
    state: 'ca',
  },
  {
    name: 'GAJA ANDZEL, MD',
    link: 'https://www.ifm.org/practitioners/gaja-andzel/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/gaja-feature-1-150x150-1-400x400.jpg',
    contactText:
      'Primary Address\n25805 Barton Rd\nSuite 107\nLoma Linda, CA 92354 US\n9092226022',
    state: 'ca',
  },
  {
    name: 'GHIBOM GHIM, DAIM, L.AC.',
    link: 'https://www.ifm.org/practitioners/ghibom-ghim/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1901 Railroad Ave STE B\nPittsburg, CA 94565 US\n415-271-2600',
    state: 'ca',
  },
  {
    name: 'HELENE PULNIK, ND',
    link: 'https://www.ifm.org/practitioners/helene-pulnik-nd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.-Helene-Headshot-NEW-400x400.png',
    contactText: '949-416-4670',
    state: 'ca',
  },
  {
    name: 'JEANETTE RYAN, DC',
    link: 'https://www.ifm.org/practitioners/jeanette-ryan-dc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/beachday035edit-crop-3-400x400.jpg',
    contactText:
      'Address\n1964 19th St\nUnit A\nSanta Monica, CA 90404 US\n(310) 395-3653',
    state: 'ca',
  },
  {
    name: 'JEFFREY MARK, MD',
    link: 'https://www.ifm.org/practitioners/jeffrey-mark-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n111 Deerwood Road\nSuite 168\nSan Ramon, CA 94583 US\n925-736-9828',
    state: 'ca',
  },
  {
    name: 'JENNIE WALKER, MD',
    link: 'https://www.ifm.org/practitioners/jennie-walker/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/NIHD14_JWalker-copy-400x400.jpg',
    contactText:
      'Address\n306 Laurel Mountain Road, Suite 203\nPO Box 2485\nMammoth Lakes, CA 93546 US\n7609140797',
    state: 'ca',
  },
  {
    name: 'JESSICA ODOM SANTOS',
    link: 'https://www.ifm.org/practitioners/jessica-odom-santos-fnp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_3951-400x400.jpg',
    contactText:
      'Primary Address\n1234 Telemed Only\nSacramento, CA 95619 US\n(916) 413-3104',
    state: 'ca',
  },
  {
    name: 'JIM HERKIMER, DPT',
    link: 'https://www.ifm.org/practitioners/jim-herkimer/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Jim-Profile-400x400.jpg',
    contactText: 'Address\n13502 Young Street\nSanta Ana, CA 92705 US',
    state: 'ca',
  },
  {
    name: 'JIYEON PARK, DACM, LAC',
    link: 'https://www.ifm.org/practitioners/jiyeon-park/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/FullSizeRender-copy-2-400x400.jpg',
    contactText:
      'Address\n366 San Miguel Drive\nSuite 307\nNewport Beach, CA 92660 US\n949-400-8057',
    state: 'ca',
  },
  {
    name: 'JOHN BEECH, DC',
    link: 'https://www.ifm.org/practitioners/john-beech-dcifmcpcfmp/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n607 E Olive Ave\nTurlock, CA 95380 US\n(209) 667-9555',
    state: 'ca',
  },
  {
    name: 'JOSSE FORD, DACM LAC',
    link: 'https://www.ifm.org/practitioners/josse-ford/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/JosseSquare-small-400x400.png',
    contactText: 'Address\n333 Fulvia St\nEncinitas, CA 92024 US\n7609446400',
    state: 'ca',
  },
  {
    name: 'JUDY LIU, MD, MPH',
    link: 'https://www.ifm.org/practitioners/judy-liu-md-mph/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1200 El Camino Real\nSouth San Francisco, CA 94080 US\n650-742-2100',
    state: 'ca',
  },
  {
    name: 'JULIE DELYNN HELM, NPC',
    link: 'https://www.ifm.org/practitioners/julie-delynn-helm-fnp-c/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_7549-400x400.jpeg',
    contactText:
      'Address\n7575 N. Cedar Avenue\nSuite 104\nFresno, CA 93720 US\n559-840-0779',
    state: 'ca',
  },
  {
    name: 'KARYN FORSYTH DUGGAN',
    link: 'https://www.ifm.org/practitioners/karyn-forsyth-duggan-cnc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/KD-B81A0136-2-3-400x400.jpeg',
    contactText:
      "Address\nAll sessions are conducted via Zoom's HIPAA compliant Telehealth platform\nP.O. Box 2107\nSausalito, CA 94965 US\n4155054423",
    state: 'ca',
  },
  {
    name: 'KAT TOUPS, MD, DFAPA, IFMCP',
    link: 'https://www.ifm.org/practitioners/kat-toups-md-dfapa-ifmcp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Kat-Toups-1MB-400x400.jpg',
    contactText:
      'Address\n2900 Camino Diablo\nSuite 200\nWalnut Creek, CA 94597 US\n(925) 265-2300',
    state: 'ca',
  },
  {
    name: 'KATE P EGAN, ND',
    link: 'https://www.ifm.org/practitioners/kate-p-egan-nd/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_3925-400x400.jpg',
    contactText:
      'Address\n408 Westminster Ave Suite 7\nNewport Beach, CA 92663 US\n949-423-6332',
    state: 'ca',
  },
  {
    name: 'KATHARINE LI',
    link: 'https://www.ifm.org/practitioners/katharine-gilly/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_1253-400x400.jpg',
    contactText:
      'Primary Address\n28 Middagh Street\nSausalito, CA 94965 US\n212-313-9622',
    state: 'ca',
  },
  {
    name: 'KATHERINE BAYARD BISHARAT, MD',
    link: 'https://www.ifm.org/practitioners/katherine-bisharat-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n5931 Stanley Ave\nSuite 8\nCarmichael/Sacramento, CA 95608 US\n(916) 481-4389',
    state: 'ca',
  },
  {
    name: 'KIMBERLY KELLY',
    link: 'https://www.ifm.org/practitioners/kimberly-kelly-ms-rd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/0075_KIM_KELLY_Revised-4-400x400.jpg',
    contactText:
      'Address\n8920 Wlshire Blvd.\nSuite 610\nBeverly Hills , CA 90211 US\n4243893547',
    state: 'ca',
  },
  {
    name: 'KRISTINE BURKE, MD',
    link: 'https://www.ifm.org/practitioners/kristine-burke-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/kristine-burke1-400x400.jpg',
    contactText:
      'Address\n2390 E. Bidwell Street\nSuite 100\nFolsom, CA 95630 US\n(916) 983-5771',
    state: 'ca',
  },
  {
    name: 'LAURA PARIS',
    link: 'https://www.ifm.org/practitioners/laura-marie-paris-lac-nc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/6-Lauri.Headshot.2021-SIZED-copy-400x400.jpg',
    contactText:
      'Primary Address\n820 Bay Ave, Suite 208C\nCapitola, CA 95010 US\n8316559611',
    state: 'ca',
  },
  {
    name: 'LAURIE LOVE',
    link: 'https://www.ifm.org/practitioners/laurie-love-msn-fnp-bc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Headshot_desk-400x400.jpg',
    contactText:
      'Address\n28212 Kelly Johnson Parkway Suite 230\nValencia, CA 91354 US\n(661) 295-7777',
    state: 'ca',
  },
  {
    name: 'LISA LOEGERING, MD',
    link: 'https://www.ifm.org/practitioners/lisa-loegering-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n317 14th Street\nDel Mar, CA 92014 US\n(858) 222-0328',
    state: 'ca',
  },
  {
    name: 'LISA PORTERA-PERRY, DC, IFMCP',
    link: 'https://www.ifm.org/practitioners/lisa-portera-perry-dc-ifmcp/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n345 Sante Fe Drive\nEncinitas, CA 92024 US\n(925) 234-1534',
    state: 'ca',
  },
  {
    name: 'LONNA LARSH, MD',
    link: 'https://www.ifm.org/practitioners/lonna-larsh-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n621 Lighthouse Ave\nSanta Cruz, CA 95060 US\n8314211292',
    state: 'ca',
  },
  {
    name: 'MARINA RASNOW-HILL, MD',
    link: 'https://www.ifm.org/practitioners/marina-rasnow-hill-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/cover-photo-400x400.jpeg',
    contactText:
      'Address\n1102 Corporate Way, Suite 170\nSacramento, CA 95831 US\n(916) 623-4594',
    state: 'ca',
  },
  {
    name: 'MARY ELLEN SULLIVAN CHALMERS, DMD',
    link: 'https://www.ifm.org/practitioners/mary-ellen-sullivan-chalmers-dmd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/MaryEllen-cropped-copy-400x400.jpg',
    contactText:
      'Address\n95 Montgomery Drive\nSuite 106\nSanta Rosa, CA 95404 US\n(707) 545-5440',
    state: 'ca',
  },
  {
    name: 'MARY PARDEE, ND',
    link: 'https://www.ifm.org/practitioners/mary-pardee/',
    avatar: 'https://www.ifm.org/wp-content/uploads/square-400x400.jpg',
    contactText:
      'Primary Address\n4515 Sherman Oaks Ave\nSherman Oaks, CA 91403 US\n7609320849',
    state: 'ca',
  },
  {
    name: 'MAYMIE CHAN, DC',
    link: 'https://www.ifm.org/practitioners/maymie-chan-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Headshot_sm-400x400.jpg',
    contactText:
      'Address\n1520 Stockton St\nSan Francisco, CA 94133 US\n5103756084',
    state: 'ca',
  },
  {
    name: 'MEGAN DAUPHIN',
    link: 'https://www.ifm.org/practitioners/megan-dauphin-mtcm/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Capture-2-400x400.jpg',
    contactText:
      'Address\n43430 Mission Blvd #200\nFremont, CA 94539 US\n510-438-0128',
    state: 'ca',
  },
  {
    name: 'MICHELLE ROSS',
    link: 'https://www.ifm.org/practitioners/michelle-ross-ma-ms-cns/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/10461870_818572581488987_6093580866389525399_o-400x400.jpg',
    contactText:
      'Address\n1920 Tienda Drive\nSuite 101\nLodi, CA 95242 US\n619-719-1739',
    state: 'ca',
  },
  {
    name: 'MILAN GUPTA CHAVARKAR, DNP, FNP, CNM, RN',
    link: 'https://www.ifm.org/practitioners/milan-chavarkar/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IFM-PIC-3-400x400.jpeg',
    contactText:
      'Primary Address\n200 West Campbell Avenue\nCampbell, CA 95008 US\n4083372767',
    state: 'ca',
  },
  {
    name: 'MIRANDA NAYLOR, DO',
    link: 'https://www.ifm.org/practitioners/miranda-naylor/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Headshot-cropped-for-IFM-400x400.png',
    contactText:
      'Address\n535 Encinitas Blvd., Suite 111\nEncinitas, CA 92024 US\n(760) 385-8683',
    state: 'ca',
  },
  {
    name: 'MONA EZZAT, MD',
    link: 'https://www.ifm.org/practitioners/mona-ezzat-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n8901 Activity Rd Suite 205\nSan Diego, CA 92131 US\n858-283-9665',
    state: 'ca',
  },
  {
    name: 'MYRTO ANGELA ASHE, MD',
    link: 'https://www.ifm.org/practitioners/myrto-ashe-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2015-05-16-at-9.31.34-AM.png',
    contactText:
      'Address\nAll consultations are by Zoom or by phone at this time; please check website by clicking on name.\nSan Rafael, CA 94903 US\n415-663-6825',
    state: 'ca',
  },
  {
    name: 'NATALYA DENISSOV, MD',
    link: 'https://www.ifm.org/practitioners/natalya-denissov-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/56B1ED57-57B2-4F89-BB17-65FDBB35ACAB-400x400.jpeg',
    contactText:
      'Address\n1501 TROUSDALE DRIVE 4TH FLOOR\nBURLINGAME, CA 94010 US\n650- 652-8500',
    state: 'ca',
  },
  {
    name: 'PARIS KHARBAT, DO',
    link: 'https://www.ifm.org/practitioners/paris-kharbat-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/paris-as-doc-400x400.jpg',
    contactText:
      'Address\n2811 Wilshire Blvd # 610\nSanta Monica, CA 90403 US\n(310) 453-2335',
    state: 'ca',
  },
  {
    name: 'D. PATRICK MARTIN, MD',
    link: 'https://www.ifm.org/practitioners/pat-martin/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Capture6-400x400.jpg',
    contactText:
      'Address\n1108 4th Street\nSouth Lake Tahoe, CA 96150 US\n(530) 542-5740',
    state: 'ca',
  },
  {
    name: 'PETER J. MURAN',
    link: 'https://www.ifm.org/practitioners/peter-muran-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_9115_jpg-400x400.jpg',
    contactText:
      'Primary Address\n901 Dover Dr.\nSuite 122\nNewport Beach, CA 92660 US\n(888) 315-4777',
    state: 'ca',
  },
  {
    name: 'RACHAEL E GONZALEZ, MD',
    link: 'https://www.ifm.org/practitioners/rachael-gonzalez/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_7571-400x400.jpg',
    contactText:
      'Address\n12655 Jefferson Blvd\n4th Floow\nLos Angeles, CA 90066 US',
    state: 'ca',
  },
  {
    name: 'RACHEL MISTRY, MS',
    link: 'https://www.ifm.org/practitioners/rachel-mistry/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_8477-2-2-400x400.jpg',
    contactText: 'Primary Address\nRoseleaf Court\nLos Gatos, CA 95032 US',
    state: 'ca',
  },
  {
    name: 'RASHEL J TAHZIB, DO AOBFP IFMCP',
    link: 'https://www.ifm.org/practitioners/rashel-j-tahzib/',
    avatar: 'https://www.ifm.org/wp-content/uploads/HKeOC-B1-400x400.jpg',
    contactText:
      'Primary Address\n2170 Century Park East\nSuite 1702\nLos Angeles, CA 90067 US\n(310) 979-3434',
    state: 'ca',
  },
  {
    name: 'REKHA MURALI',
    link: 'https://www.ifm.org/practitioners/rekha-murali/',
    avatar: 'https://www.ifm.org/wp-content/uploads/self-pic-400x400.jpg',
    contactText:
      'Address\n5401 Norris Canyon Road , Suite 204\nSan Ramon, CA 94583 US\n(510)724-6712',
    state: 'ca',
  },
  {
    name: 'ROBERT PEKARI, PA-C',
    link: 'https://www.ifm.org/practitioners/robert-pekari-pa-c/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\nVA Ambulatory Care Center\n26001 Redlands Blvd\nRedlands, CA 92373 US\n909 825 7084',
    state: 'ca',
  },
  {
    name: 'RYAN LAZARUS',
    link: 'https://www.ifm.org/practitioners/ryan-lazarus-msc-cns-dc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Lazarus-Headshot-400x400.jpg',
    contactText:
      'Primary Address\n100 Railroad Ave.\nSuite D\nDanville, CA 94526 US\n9254031500',
    state: 'ca',
  },
  {
    name: 'RYAN M MONAHAN',
    link: 'https://www.ifm.org/practitioners/ryan-m-monahan/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/black-and-white-self-pic-1-400x400.jpg',
    contactText: 'www.peacefulmountainmedicine.com',
    state: 'ca',
  },
  {
    name: 'SANDEEP KAPOOR, MD',
    link: 'https://www.ifm.org/practitioners/sandeep-kapoor-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/fullsizeoutput_8980-400x400.jpeg',
    contactText:
      'Address\n12311 Ventura Blvd\nStudio City, CA 91604 US\n818-392-5408',
    state: 'ca',
  },
  {
    name: 'SANDRA FLEMING, MD',
    link: 'https://www.ifm.org/practitioners/sandra-fleming/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/SmallerSandraPhoto2021-400x400.jpg',
    contactText:
      'Address\nTelemedicine\nwww.coastalfunctionalmed.com\nFort Bragg, CA 95437 US\n707-999-3925',
    state: 'ca',
  },
  {
    name: 'SEPIDEH MOAYED MD',
    link: 'https://www.ifm.org/practitioners/sepideh-moayed/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Sepideh-High-AE9A0052-copy-2-400x400.jpg',
    contactText:
      'Address\n2242 Camden Avenue\nSuite 203\nSan Jose, CA 95124 US\n408-992-5141',
    state: 'ca',
  },
  {
    name: 'SHEILA SALTZMAN, PT, DPT, EP-C, COMT, IFMCP',
    link: 'https://www.ifm.org/practitioners/sheila-b-saltzman-dpt/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2020-05-13-at-8.24.12-PM-400x400.png',
    contactText:
      'Address\n1237 Archer Street\nSan Luis Obispo, CA 93401 US\n805-457-8860',
    state: 'ca',
  },
  {
    name: 'SHEILA WAGNER',
    link: 'https://www.ifm.org/practitioners/sheila-wagner-pt-cn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/SW-15-Small-400x400.jpg',
    contactText:
      'Primary Address\n700 Larkspur Landing Circle\nSuite 205\nLarkspur, CA 94939 US\n415-945-3213',
    state: 'ca',
  },
  {
    name: 'SHILPA SAYANA, MD',
    link: 'https://www.ifm.org/practitioners/shilpa-sayana-md-mph/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Shilpa-Sayana-image-400x400.jpg',
    contactText:
      'Address\n14006 Riverside Dr\nUnit 18\nSherman Oaks, CA 91423 US\n8183314386',
    state: 'ca',
  },
  {
    name: 'SMITHA CHINIGA REDDY, MD',
    link: 'https://www.ifm.org/practitioners/smitha-chiniga-reddy-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/NEw-FM-screenshot--400x400.png',
    contactText:
      'Primary Address\n15725 Pomerado Road Suite 117\nPoway , CA 92064 US\n858-312-1717',
    state: 'ca',
  },
  {
    name: 'SNOWY TAN, ND',
    link: 'https://www.ifm.org/practitioners/snowy-tan/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Headshot-lower-resolution-400x400.jpeg',
    contactText:
      'Address\n710 Lakeway Dr\nSte 210\nSunnyvale, CA 94085 US\n(650) 285-1590',
    state: 'ca',
  },
  {
    name: 'STELLA LEE',
    link: 'https://www.ifm.org/practitioners/stella-lee/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/78027F08-43BD-4963-86C8-49151B551CD8-400x400.jpeg',
    contactText:
      'Address\n1020 S Anaheim Blvd, Suite 103\nAnaheim, CA 92805 US\n(714) 864-3730',
    state: 'ca',
  },
  {
    name: 'STEPHANIE DEPPE',
    link: 'https://www.ifm.org/practitioners/stephanie-deppe/',
    avatar: 'https://www.ifm.org/wp-content/uploads/headshot1-5-400x400.jpg',
    contactText:
      'Address\n8550 Santa Monica Blvd, 2nd Floor West\nHollywood, CA 90069 US',
    state: 'ca',
  },
  {
    name: 'SUSAN PAYROVI, MD',
    link: 'https://www.ifm.org/practitioners/susan-payrovi-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: '6509180306',
    state: 'ca',
  },
  {
    name: 'SUSIE LARSON, MD',
    link: 'https://www.ifm.org/practitioners/susie-larson-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/DSC5103Glow-400x400.jpg',
    contactText:
      'Primary Address\n4148 Casterson Court\nPleasanton, CA 94566 US\n650-954-0044',
    state: 'ca',
  },
  {
    name: 'SYLVIE WELLHAUSEN, DC, DABCI, DACBN',
    link: 'https://www.ifm.org/practitioners/sylvie-wellhausen-dc-dabci-dacbn/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Sylvie-1-168x225-400x400.jpg',
    contactText:
      'Address\n408 Brookside Avenue\nSuite B1\nRedlands, CA 92373 US\n(909) 799-6212',
    state: 'ca',
  },
  {
    name: 'THERESA TSINGIS, PA',
    link: 'https://www.ifm.org/practitioners/theresa-tsingis-pa-ms-dc-mph/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/THERESA-TSINGIS-PHOTO-400x400.jpg',
    contactText:
      'Address\n251 Lafayette Circle\nSte 240\nLafayette, CA 94549 US\n(925) 283-9355',
    state: 'ca',
  },
  {
    name: 'TIGALAT SHALITA, DO',
    link: 'https://www.ifm.org/practitioners/tigalat-shalita-do/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n7230 Medical Center Dr\nSuite 202\nWest Hills, CA 91307 US\n(818) 676-0080',
    state: 'ca',
  },
  {
    name: "TRACEY O'SHEA",
    link: 'https://www.ifm.org/practitioners/tracey-clow-msn-fnp-c/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n2414 Ashby Ave\nSuite 201\nBerkeley, CA 94705 US\n5108496500',
    state: 'ca',
  },
  {
    name: 'TRACY H MCCARTHY, MD',
    link: 'https://www.ifm.org/practitioners/tracy-h-mccarthy-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Dr.Mc-IFM-400x400.jpg',
    contactText:
      "Primary Address\nAll sessions are conducted via Zoom's HIPAA compliant Telehealth platform\nSacramento, CA 95841 US\n(916) 581-2205",
    state: 'ca',
  },
  {
    name: 'TRACY LECLERC, DDS',
    link: 'https://www.ifm.org/practitioners/tracy-leclerc-dds/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.-Tracy-Leclerc-400x400.jpg',
    contactText:
      'Address\n662 Homer Avenue\nPalo Alto, CA 94301 US\n(650) 327-0802',
    state: 'ca',
  },
  {
    name: 'VICTORIA ANNE RIVERA',
    link: 'https://www.ifm.org/practitioners/victoria-anne-rivera-pa-c-nc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_1449-400x400.jpeg',
    contactText:
      'Primary Address\n27206 Calaroga Ave.\nHayward, CA 94545 US\n5106003411',
    state: 'ca',
  },
  {
    name: 'WESLEY G BRADFORD, MD, MPH',
    link: 'https://www.ifm.org/practitioners/wesley-g-bradford-md-mph/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Dr-Bradford-400x400.jpg',
    contactText:
      'Address\n22750 Hawthorne Blvd\nSuite 220\nTorrance, CA 90505-3667 US\n(424) 222-9601',
    state: 'ca',
  },
  {
    name: 'YANNINA "NINA" TORTI, MS',
    link: 'https://www.ifm.org/practitioners/yannina-torti/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/A47FC094-0753-4069-B40C-4E2E6A925A20-400x400.jpeg',
    contactText: 'Address\n7710 Fay Ave.\nLa Jolla, CA 92037 US\n6197961323',
    state: 'ca',
  },
  {
    name: 'YEN INGRID LAI, MD',
    link: 'https://www.ifm.org/practitioners/yen-ingrid-lai-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n667 W. Arrow Hwy\nSuite E\nSan Dimas, CA 91773 US\n(909) 971-9888',
    state: 'ca',
  },
  {
    name: 'ANNA WOLYN, MD',
    link: 'https://www.ifm.org/practitioners/agnieszka-anna-wolyn-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/fullsizeoutput_2de6-400x400.jpeg',
    contactText:
      'Address\n9700 N 91 st ST\nSuite A -115\nScottsdale, AZ 85258 US\n602 309 0054',
    state: 'az',
  },
  {
    name: "ANGELA ELIZABETH O'NEIL, MD",
    link: 'https://www.ifm.org/practitioners/angela-oneil-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/angela-400x400.jpg',
    contactText:
      'Address\n10250 N 92nd Street\nSuite #210\nScottsdale, AZ 85258 US\n4804700606',
    state: 'az',
  },
  {
    name: 'BRYAN GLICK, DO, MBS, IFMCP',
    link: 'https://www.ifm.org/practitioners/bryan-glick-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Bryan-Glick-Web-Profile-Image-1-1mb-400x400.jpg',
    contactText:
      'Address\n7659 E Pinnacle Peak Road\nSuite 105\nScottsdale, AZ 85255 US\n(480) 222-4600',
    state: 'az',
  },
  {
    name: 'CHAD CAMPBELL, PA-C',
    link: 'https://www.ifm.org/practitioners/chad-campbell-pa-c/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Chad-gray-8x10-1-400x400.jpg',
    contactText:
      'Primary Address\n285 N. Broad St\nGlobe, AZ 85501 US\n(928) 487-5967',
    state: 'az',
  },
  {
    name: 'CHERI DIANE DERSAM, MD',
    link: 'https://www.ifm.org/practitioners/cheri-dersam-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Prof-Photo-Smaller-200-x-300-3-400x400.jpg',
    contactText:
      'Address\n3295 N. Drinkwater Boulevard\nSuite 3\nScottsdale, AZ 85251 US\n480-621-8638',
    state: 'az',
  },
  {
    name: 'DANIELLE B BECHERT, MD',
    link: 'https://www.ifm.org/practitioners/danielle-b-bechert-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_1297-400x400.jpg',
    contactText:
      'Address\n5410 North Scottsdale Road, Suite D-100\nParadise Valley, AZ 85253 US\n6028924727',
    state: 'az',
  },
  {
    name: 'DAVID SORENSON',
    link: 'https://www.ifm.org/practitioners/david-sorenson-ms-pac/',
    avatar: 'https://www.ifm.org/wp-content/uploads/David-Profile-400x400.jpg',
    contactText:
      'Address\n18521 East Queen Creek Rd Ste 105 #131\nQueen Creek, AZ 85142 US\n1 5203291938',
    state: 'az',
  },
  {
    name: 'EMILY PARKE, DO',
    link: 'https://www.ifm.org/practitioners/emily-parke-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/emilyparke1-400x400.png',
    contactText:
      'Address\n5410 North Scottsdale Road\nSuite D-100\nParadise Valley, AZ 85253 US\n(602) 892-4727',
    state: 'az',
  },
  {
    name: 'ENIKO LOUD',
    link: 'https://www.ifm.org/practitioners/eniko-loud-dmd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/C45775FB-50CB-40F8-B04A-EAF25BBF09C7-400x400.jpeg',
    contactText:
      'Address\n20950 N Tatum Blvd,\nSuite 300\nPhoenix, AZ 85050 US\n4805634141',
    state: 'az',
  },
  {
    name: 'GABRIELLE JONES PA-C',
    link: 'https://www.ifm.org/practitioners/gabrielle-wudarski/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_2961-min1-400x400.jpg',
    contactText:
      'Address\n5410 North Scottsdale Road\nSuite D-100\nParadise Valley, AZ 85253 US\n6028924727',
    state: 'az',
  },
  {
    name: 'GOBI PARAMANANDAM',
    link: 'https://www.ifm.org/practitioners/gobi-paramanandam/',
    avatar: 'https://www.ifm.org/wp-content/uploads/GP_Photo-1-400x400.jpg',
    contactText:
      'Address\n4611 East Shea Boulevard\nBuilding 3, Suite 190\nPhoenix, AZ 85028 US\n480-758-1757',
    state: 'az',
  },
  {
    name: 'JANIS RUSTAD',
    link: 'https://www.ifm.org/practitioners/janis-rustad-rn-mn-fnp-c/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Untitled-1-400x400.jpg',
    contactText:
      'Primary Address\n8415 N. Pima Road, Ste. #210\nScottsdale, AZ 85258 US\n480-425-8700',
    state: 'az',
  },
  {
    name: 'JYOTI PATEL, MD FAAP FACP ABOIM IFMCP',
    link: 'https://www.ifm.org/practitioners/jyoti-patel/',
    avatar: 'https://www.ifm.org/wp-content/uploads/headshot1-2-400x400.jpg',
    contactText:
      'Address\n11648 East Shea Boulevard\nSuite 115\nScottsdale , AZ 85259 US\n(480) 837-1000',
    state: 'az',
  },
  {
    name: 'LAURA DECESARIS, DC, MSACN',
    link: 'https://www.ifm.org/practitioners/laura-decesaris-dc-msacn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_1080-400x400.jpg',
    contactText:
      'Address\n414 S Lake Mirage Drive\nGilbert, AZ 85233 US\n602-935-8409',
    state: 'az',
  },
  {
    name: 'LAUREN BACHMAN',
    link: 'https://www.ifm.org/practitioners/lauren-ruegg/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_6751-3-400x400.jpg',
    contactText:
      'Address\n16952 W. Bell Road STE 304\nSurprise , AZ 85374 US\n623-440-5816',
    state: 'az',
  },
  {
    name: 'LEXINE M HEBETS, MD',
    link: 'https://www.ifm.org/practitioners/lexine-m-hebets-md-facog/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1661 E Camelback Rd\nSuite 160\nPhoenix, AZ 85016 US\n(602) 241-1671',
    state: 'az',
  },
  {
    name: 'LISA ONA BALLEHR, DO',
    link: 'https://www.ifm.org/practitioners/lisa-ona-ballehr/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/SMALL-SIZE-BIO-PIC-400x400.jpg',
    contactText:
      'Primary Address\n7662 E Hidden Canyon Street\nMesa, AZ 85207 US\n480 395 9220',
    state: 'az',
  },
  {
    name: 'LISA SWANSON',
    link: 'https://www.ifm.org/practitioners/lisa-swanson/',
    avatar: 'https://www.ifm.org/wp-content/uploads/PA164678-400x400.jpg',
    contactText:
      'Address\n10051 E Dynamite Blvd\nG105\nScottsdale, AZ 85262 US\n480-530-1912',
    state: 'az',
  },
  {
    name: 'MARGARET POWELL, DC',
    link: 'https://www.ifm.org/practitioners/margaret-powell-dc-dipl-ac/',
    avatar: 'https://www.ifm.org/wp-content/uploads/dr-p-profile2-400x400.jpg',
    contactText:
      'Primary Address\n3810 N Miller Rd\nScottsdale, AZ 85251 US\n4809900664',
    state: 'az',
  },
  {
    name: 'MELINDA ATIENZA, DO',
    link: 'https://www.ifm.org/practitioners/melinda-atienza-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/profile-pic-400x400.jpeg',
    contactText:
      'Address\n8405 N Pima Center Parkway, Suite 203\nScottsdale, AZ 85258 US\n(480) 587-6930',
    state: 'az',
  },
  {
    name: 'MICHELLE JEFFRIES, DO',
    link: 'https://www.ifm.org/practitioners/michelle-jeffries-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/dr-j-teal-scrubs-door-fountain-400x400.jpg',
    contactText:
      'Address\n5410 North Scottsdale Road\nSuite D-100\nParadise Valley, AZ 85253 US\n602-892-4727',
    state: 'az',
  },
  {
    name: 'RAYETTE POLLARD CASIANO, MD, FACP',
    link: 'https://www.ifm.org/practitioners/rayette-casiano/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/IMG-4671-cropped-400x400.jpg',
    contactText: 'Address\nPO Box 1011\nWaddell, AZ 85355 US\n855-925-8255',
    state: 'az',
  },
  {
    name: 'ROBERT D SHEELER, MD',
    link: 'https://www.ifm.org/practitioners/robert-d-sheeler-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/bobpic-400x400.jpg',
    contactText:
      'Address\n10250 N 92nd Street\nSuite #210\nScottsdale, AZ 85258 US\n480-470-0606',
    state: 'az',
  },
  {
    name: 'SCOTT MILTON JENSEN, MD',
    link: 'https://www.ifm.org/practitioners/scott-milton-jensen-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n6682 Lower Ridge Dr\nLakeside, AZ 85929 US\n9282244270',
    state: 'az',
  },
  {
    name: 'SUSAN DEL SORDI-STAATS, DO',
    link: 'https://www.ifm.org/practitioners/susan-del-sordi-staats-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Headshot-8-400x400.jpeg',
    contactText:
      'Address\n11209 North Tatum Blvd\nSuite 160\nPhoenix, AZ 85028 US\n(480) 285-2180',
    state: 'az',
  },
  {
    name: 'SUSAN S WILDER, MD',
    link: 'https://www.ifm.org/practitioners/susan-s-wilder-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/493lifescapeQ2-400x400.png',
    contactText: 'Address\n8757 E Bell Rd\nScottsdale, AZ 85260 US\n4808605500',
    state: 'az',
  },
  {
    name: 'ZHANNA TARJEFT',
    link: 'https://www.ifm.org/practitioners/zhanna-tarjeft/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/smaller-headshot1-400x400.jpg',
    contactText:
      'Address\n3323 East Baseline Road\nGilbert, AZ 85234 US\n480-550-9551',
    state: 'az',
  },
  {
    name: 'BRIAN P CONNER, DC',
    link: 'https://www.ifm.org/practitioners/brian-p-conner-dc-cns/',
    avatar: 'https://www.ifm.org/wp-content/uploads/headshot-11-400x400.jpg',
    contactText:
      'Address\n366 Federal Rd\nBrookfield, CT 06804 US\n(203) 775-1819',
    state: 'ct',
  },
  {
    name: 'CHI KIM, MD',
    link: 'https://www.ifm.org/practitioners/chi-kim/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/A2897F5E-1B38-4A49-B947-B9869B3A7551-400x400.jpeg',
    contactText:
      'Address\n191 Albany Turnpike, Suite 102\nCanton, CT 06019 US\n8603528265',
    state: 'ct',
  },
  {
    name: 'DAVID LONDON, MD',
    link: 'https://www.ifm.org/practitioners/david-london-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2017-06-23-at-6.16.22-PM1-400x400.png',
    contactText:
      'Address\n567 Vauxhall St. Extension\nSuite 218\nWaterford, CT 06385 US\n(860) 443-5822',
    state: 'ct',
  },
  {
    name: 'DEANNA M APPLETON CHERRONE, MD',
    link: 'https://www.ifm.org/practitioners/deanna-m-appleton-cherrone-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/retouched-photo2-400x400.jpg',
    contactText:
      'Address\n639 Park Rd\nSuite 304\nWest Hartford, CT 06107 US\n(860) 677-4600',
    state: 'ct',
  },
  {
    name: 'DEBORAH KEIGHTLEY, MD',
    link: 'https://www.ifm.org/practitioners/deborah-keightley/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Moms-photo-400x400.jpg',
    contactText:
      'Address\n191 Albany Turnpike\nCanton, CT 06019 US\n860-352-8265',
    state: 'ct',
  },
  {
    name: 'EUGENE GRESH, RPH',
    link: 'https://www.ifm.org/practitioners/eugene-gresh-rph/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n520 Hartford Turnpike\nUnit D\nVernon, CT 06066 US\n(860) 979-0089',
    state: 'ct',
  },
  {
    name: 'JOEL EVANS, MD',
    link: 'https://www.ifm.org/practitioners/joel-evans-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Headshot_Joel_Evans-400x400.jpg',
    contactText:
      'Address\n1011 High Ridge Road\nStamford, CT 06905 US\n203-321-0200',
    state: 'ct',
  },
  {
    name: 'JULIE CAROLINE CONNER, RD',
    link: 'https://www.ifm.org/practitioners/julie-caroline-conner-rd-mph-phd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Fancy-Food-Show-2016-1704-2-400x400.jpg',
    contactText:
      'Address\n366 Federal Road\nBrookfield, CT 06804 US\n(203) 775-1819',
    state: 'ct',
  },
  {
    name: 'KENNETH A LITWIN, MD',
    link: 'https://www.ifm.org/practitioners/kenneth-a-litwin-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/photo-of-ken-at-SHC-leaning-against-brick-wall-20161-400x400.jpg',
    contactText:
      'Address\n27 Glen Rd ,4th floor\nSandy Hook, CT 06482 US\n203-493-0971',
    state: 'ct',
  },
  {
    name: 'LISA GENGO, ND',
    link: 'https://www.ifm.org/practitioners/lisa-gengo-nd-pac/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Lisa-400x400.jpg',
    contactText:
      'Address\n111 High Ridge Rd\n1st Floor\nStamford , CT 06905 US\n(203) 434-9143',
    state: 'ct',
  },
  {
    name: 'LORI GRAHAM',
    link: 'https://www.ifm.org/practitioners/lori-graham-b-s-ot-m-s-exercise-science-post-ms-nutrition-certified-in-functional-medicine/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/70E0C1D8-72E0-4998-9325-E3E48B380936-400x400.jpeg',
    contactText: 'Address\n49 Fishing Trail\nStamford, CT 06903 US\n2038295349',
    state: 'ct',
  },
  {
    name: 'MONIQUE CLASS, MS, APRN, BC',
    link: 'https://www.ifm.org/practitioners/monique-class-ms-aprn-bc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Monique-Class-400x400.jpg',
    contactText:
      'Address\n1011\nHigh Ridge Road\nStamford, CT 06905 US\n(203) 321-0200',
    state: 'ct',
  },
  {
    name: 'RALPH ESPOSITO, ND',
    link: 'https://www.ifm.org/practitioners/ralph-esposito-nd-lac/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/573195E1-9CBD-4DA3-8269-2D09CA83513F-400x400.jpeg',
    contactText: '',
    state: 'ct',
  },
  {
    name: 'ANJALI C DSOUZA, MD',
    link: 'https://www.ifm.org/practitioners/anjali-c-dsouza-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2017-12-11-at-3.18.01-PM-400x400.png',
    contactText:
      'Address\n1915 I Street NW Suite 700\nWashington, DC 20006 US\n202-251-7541',
    state: 'dc',
  },
  {
    name: 'ANNE BERKELEY, ND',
    link: 'https://www.ifm.org/practitioners/anne-berkeley/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/resized-photo-Berkeley-400x400.jpg',
    contactText:
      'Address\n1915 I Street NW Suite 700\nWashington, DC 20006 US\n202-798-0221',
    state: 'dc',
  },
  {
    name: 'ERIN J. BAGSHAW, NP',
    link: 'https://www.ifm.org/practitioners/erin-bagshaw-np/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Photoguzman_NWNP_-017R-2-400x400.jpg',
    contactText:
      'Address\n3000 Connecticut Avenue NW\nSuite 338\nWashington, DC 20008 US\n(202) 483-6672',
    state: 'dc',
  },
  {
    name: 'GREG NATELLO, DO',
    link: 'https://www.ifm.org/practitioners/gregory-natello-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.-Greg-Natello4-sq-3-400x400.jpg',
    contactText: 'Primary Address\nPerimeter Road\nWashington, DC 20762 US',
    state: 'dc',
  },
  {
    name: 'SHEPHALI RANPURIA, M.D.',
    link: 'https://www.ifm.org/practitioners/shephali-ranpuria-m-d/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1915 I Street NW, Suite 700\nWashington, DC 20006 US\n202-251-7541',
    state: 'dc',
  },
  {
    name: 'ASHLEY MAI DEMALINE',
    link: 'https://www.ifm.org/practitioners/ashley-megan-demaline/',
    avatar: 'https://www.ifm.org/wp-content/uploads/mai-400x400.jpg',
    contactText:
      'Address\n76-5914 Mamalahoa Hwy\nHolualoa, HI 96725 US\n8086577877',
    state: 'hi',
  },
  {
    name: 'BRITTANY YAHRAUS',
    link: 'https://www.ifm.org/practitioners/brittany-yahraus/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n105 Paukaa Road\nHilo, HI 96720 US\n808-286-0581',
    state: 'hi',
  },
  {
    name: 'GLORIA HAMADA, DC',
    link: 'https://www.ifm.org/practitioners/gloria-hamada-dc-ccn-ifmcp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Hamada_Gloria_ProfilePic-400x400.jpeg',
    contactText:
      'Address\n1110 Nuuanu Ave\nHonolulu, HI 96817 US\n(808) 222-8199',
    state: 'hi',
  },
  {
    name: 'JOSH STARBUCK, MD',
    link: 'https://www.ifm.org/practitioners/joshua-farrell-starbuck/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/P1124161-Edit-3-min-min-1-400x400.jpg',
    contactText:
      'Address\n161 Wailea Ike Place\nWailea, HI 96753 US\n808-217-8447',
    state: 'hi',
  },
  {
    name: 'KAREN DIANE JOHNSON, MD',
    link: 'https://www.ifm.org/practitioners/karen-diane-johnson-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/sweetpea-2_1_1-400x400.jpg',
    contactText:
      'Address\n54-396 Union Mill Road\nKapaau, HI 96755 US\n808-657-4841',
    state: 'hi',
  },
  {
    name: 'KRISTINA MARIE RODRIGUEZ, RN',
    link: 'https://www.ifm.org/practitioners/kristina-marie-rodriguez/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/KristinaHeadshot1-400x400.jpg',
    contactText: '',
    state: 'hi',
  },
  {
    name: 'LAUREN AMPOLOS',
    link: 'https://www.ifm.org/practitioners/lauren-ampolos/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Round-Lauren-1mb-400x400.jpg',
    contactText:
      'Address\n415 Dairy Rd Ste E323\nKahului, HI 96732 US\n808-518-1619',
    state: 'hi',
  },
  {
    name: 'MARTINA HARMS',
    link: 'https://www.ifm.org/practitioners/martina-harms/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_0547-4-400x400.jpg',
    contactText: '',
    state: 'hi',
  },
  {
    name: 'AMANDA MANLEY',
    link: 'https://www.ifm.org/practitioners/amanda-manley/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n9500 Baptist Health Dr Suite 100\nLittle Rock, AR 72205 US\n501-224-5500',
    state: 'ar',
  },
  {
    name: 'AUNNA HERBST, DO',
    link: 'https://www.ifm.org/practitioners/aunna-herbst-do-nd/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n700 SE Plaza AVE\nBentonville, AR 72712 US\n(479) 715-4645',
    state: 'ar',
  },
  {
    name: 'BRADLY SHANE HOLLAND',
    link: 'https://www.ifm.org/practitioners/bradly-shane-holland/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Photo-on-9-30-19-at-4.58-PM-400x400.jpg',
    contactText:
      'Address\n212 W. Monroe Ave\nSuite A\nLowell, AR 72745 US\n4793892803',
    state: 'ar',
  },
  {
    name: 'HENRI ROCA, MD',
    link: 'https://www.ifm.org/practitioners/henri-roca-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/profile-pic-5-400x400.jpg',
    contactText: 'Address\n513 Main St\nNorth Little Rock, AR 72114 US',
    state: 'ar',
  },
  {
    name: 'KRISTIE GAST, MD',
    link: 'https://www.ifm.org/practitioners/kris-gast-dr-kristie-gast/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/74E9771A-BEA1-4536-A31B-A7A4A9850C8C-400x400.jpeg',
    contactText:
      'Address\n155 COUNTY ROAD 524\nMOUNTAIN HOME, AR 72653-7996 US\n479-806-5683',
    state: 'ar',
  },

  {
    name: 'ABBEY JONES',
    link: 'https://www.ifm.org/practitioners/abbey-jones-msn-anp-bc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/DD774C08-46EF-40A1-B87D-D53C99BD611C-400x400.jpeg',
    contactText:
      'Address\n10999 Ridgegate Parkway\nUnit 410\nLone Tree, CO 80124 US\n303-730-2229',
    state: 'co',
  },
  {
    name: 'ALISON J HOFFMASTER, PA-C',
    link: 'https://www.ifm.org/practitioners/alison-j-hoffmaster-pa-c/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Color_web-400x400.png',
    contactText:
      'Address\n2299 Pearl Street\nSuite107\nBoulder, CO 80302 US\n(303) 663-6480',
    state: 'co',
  },
  {
    name: 'AMY DENICKE, DC',
    link: 'https://www.ifm.org/practitioners/amy-denicke-dc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2022-06-23-at-7.09.06-PM-400x400.png',
    contactText:
      'Address\n1460 E. Valley Road\nSuite 138\nBasalt, CO 81621 US\n(970) 948-7846',
    state: 'co',
  },
  {
    name: 'ANGIE N MARTINEZ, MD',
    link: 'https://www.ifm.org/practitioners/angie-n-martinez-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/20190301_082531-400x400.jpg',
    contactText:
      'Address\n8670 Wolff Ct Suite 250\nWestminster, CO 80031 US\n720-290-5569',
    state: 'co',
  },
  {
    name: 'CHRIS BANTOCK, DC',
    link: 'https://www.ifm.org/practitioners/chris-bantock-dc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/DrBantock_functionalmedicine_headshot-400x400.jpg',
    contactText:
      'Address\n19751 E Mainstreet Suite 265\nParker, CO 80138 US\n(303) 500-3038',
    state: 'co',
  },
  {
    name: 'CHRISTINA L. SONCHAR, DC, FNP-C, IFMCP, DABCI, DACBN',
    link: 'https://www.ifm.org/practitioners/christina-l-sonchar-dc-dabci-dacbn-ccsp-ccep/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Dr-Christie--400x400.jpg',
    contactText:
      'Address\n1750 Telstar Drive\nSuite 201\nColorado Springs, CO 80920 US\n(719) 599-0102',
    state: 'co',
  },
  {
    name: 'CHRISTINE M MAREN, DO',
    link: 'https://www.ifm.org/practitioners/christine-m-maren-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Version-5-400x400.jpg',
    contactText:
      'Primary Address\n8181 Arista Place Suite 100\nBroomfield, CO 80021 US\n(303) 704-4487',
    state: 'co',
  },
  {
    name: 'CHRISTOPHER JON MOTE, DO',
    link: 'https://www.ifm.org/practitioners/christopher-mote-do-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Headshot-400x400.png',
    contactText:
      'Address\n7180 E Orchard Road\nSuite 306\nCentennial, CO 80111 US\n(720) 452-7420',
    state: 'co',
  },
  {
    name: 'CORRIE PILLON, DC',
    link: 'https://www.ifm.org/practitioners/corrie-pillon/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Corrie-1-3-400x400.jpg',
    contactText:
      'Address\n6825 E. Hampden Ave.\nSte. 100\nDenver, CO 80224 US\n303-756-1082',
    state: 'co',
  },
  {
    name: 'CRYSTAL D. DICKERSON, MD',
    link: 'https://www.ifm.org/practitioners/crystal-d-askew-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Headshot-Crystal-Dickerson-400x400.jpg',
    contactText:
      'Address\n6895 E. Hampden Avenue\nDenver, CO 80224 US\n720-575-1922',
    state: 'co',
  },
  {
    name: 'DAMIANA CORCA',
    link: 'https://www.ifm.org/practitioners/damiana-corca/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Damiana-Corca-photo-2-400x400.jpg',
    contactText:
      'Address\n4440 Arapahoe Ave\nSuite 215\nBoulder, CO 80303 US\n303-440-1033',
    state: 'co',
  },
  {
    name: 'DAN LAPERRIERE, MD',
    link: 'https://www.ifm.org/practitioners/daniel-laperriere-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Dan-Photo-400x400.jpg',
    contactText:
      'Address\n275 Century Circle\nSuite 102\nLouisville, CO 80027 US\n(720) 634-3870',
    state: 'co',
  },
  {
    name: 'DAVID GORDON, MD',
    link: 'https://www.ifm.org/practitioners/david-gordon-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Address\nPO Box 200278\nDenver, CO 80220 US\n(720) 625-8800',
    state: 'co',
  },
  {
    name: 'DEVON MARIE NELSON',
    link: 'https://www.ifm.org/practitioners/devon-marie-nelson/',
    avatar: 'https://www.ifm.org/wp-content/uploads/head-shot-5-400x400.jpg',
    contactText:
      'Address\n8683 E Lincoln Ave Suite 110\nLonetree, CO 80124 US\n3034761015',
    state: 'co',
  },
  {
    name: 'SAMUEL SHAY, DC',
    link: 'https://www.ifm.org/practitioners/dr-sam-shay/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/IFM_AIC19_4139-Cropped-less-than-1-mb--400x400.jpg',
    contactText:
      'Primary Address\nAvailable for tele-consults for personalised nutrition and lifestyle. All lab kits can be sent directly to your door. Tele-consults make life easier: You save time and money by not needing to commute or hire a babysitter.\nBoulder, CO 80301 US\nSchedule your free 15-min consult at: https://p.bttr.to/33vooyn',
    state: 'co',
  },
  {
    name: 'ELIZABETH BUELL, MD',
    link: 'https://www.ifm.org/practitioners/elizabeth-buell/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n151 West Lake Street\nFort Collins , CO 80524 US\n970-491-7121',
    state: 'co',
  },
  {
    name: 'ELLICE GOLDBERG, DO',
    link: 'https://www.ifm.org/practitioners/ellice-goldberg/',
    avatar: 'https://www.ifm.org/wp-content/uploads/my-picture-400x400.jpg',
    contactText: 'Address\n12001 W. 63rd Pl\nArvada, CO 80004 US\n7206283708',
    state: 'co',
  },
  {
    name: 'FERA BUTTS',
    link: 'https://www.ifm.org/practitioners/fera-butts/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Fera-Kristine-Headshot-400x400.png',
    contactText:
      'Address\n10099 RidgeGate Parkway\nSuite 440\nLone Tree, CO 80124 US\n3033277300',
    state: 'co',
  },
  {
    name: 'GIA MCCLOSKEY-JUREVICH, NP',
    link: 'https://www.ifm.org/practitioners/gia-mccloskey-jurevich-np/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Giapic-400x400.jpg',
    contactText:
      'Address\n7535 E. Hampden Ave., Suite 410\nDenver, CO 80231 US\n(720) 229-4809',
    state: 'co',
  },
  {
    name: 'GINA MORTELLARO-GOMEZ, DAOM, MSOM, L.AC, NCCAOM DIPL. OM, FABORM, IFMCP',
    link: 'https://www.ifm.org/practitioners/gina-mortellaro-gomez/',
    avatar: 'https://www.ifm.org/wp-content/uploads/drgg-D-crop-400x400.jpg',
    contactText:
      'Address\n7114 W Jefferson Ave\nSte 208\nLAKEWOOD, CO 80235 US\n1-844-739-4325',
    state: 'co',
  },
  {
    name: 'HEATHER CRIBBS, DO',
    link: 'https://www.ifm.org/practitioners/heather-cribbs-do/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n195 Inverness Drive West suite 100\nEnglewood, CO 80112 US',
    state: 'co',
  },
  {
    name: 'FRANCIS IAN HOLLAMAN, DC',
    link: 'https://www.ifm.org/practitioners/ian-hollaman-dc-msc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Ians-headshot-400x400.jpg',
    contactText:
      'Address\n2935 Baseline Road\nSuite 302\nBoulder, CO 80303 US\n(303) 882-8447',
    state: 'co',
  },
  {
    name: 'J THOMAS SVINARICH, MD',
    link: 'https://www.ifm.org/practitioners/j-thomas-svinarich/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/tom-portrait-web-400x400.jpg',
    contactText:
      'Address\n360 Peak One DR\nFrisco, CO 80443-9997 US\n970-668-9772',
    state: 'co',
  },
  {
    name: 'JACQUELYN MARIE PARISET, MD',
    link: 'https://www.ifm.org/practitioners/jacquelyn-marie-pariset-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/A4A2211-2-400x400.jpg',
    contactText:
      'Address\n7535 East Hampden Avenue\nSuite 410\nDenver, CO 80224 US\n(720)229-4809',
    state: 'co',
  },
  {
    name: 'JAMES E CARTER, MD',
    link: 'https://www.ifm.org/practitioners/james-e-carter-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/profile-photo-3-400x400.jpg',
    contactText:
      'Primary Address\n12505 E. 16th Avenue\nAnschutz Inpatient Pavilion 2 3rd floor\nAurora, CO 80045 US\n7208485300',
    state: 'co',
  },
  {
    name: 'JAMIE KYEI-FRIMPONG',
    link: 'https://www.ifm.org/practitioners/jamie-kyei-frimpong-fnp-c/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_9981-400x400.jpg',
    contactText: '720.720-4114',
    state: 'co',
  },
  {
    name: 'JEFF HUNTER, NP',
    link: 'https://www.ifm.org/practitioners/jeff-hunter/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Portrait-small-square-Jeff-2022-400x400.jpeg',
    contactText:
      'Address\n2950 Professional Place\nSuite 145\nColorado Springs, CO 80904 US\n719-428-5141',
    state: 'co',
  },
  {
    name: 'JENNIFER THOMPSON, PA-C',
    link: 'https://www.ifm.org/practitioners/jennifer-hooker/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Portrait-400x400.jpg',
    contactText:
      'Address\n3512 Delgany St\nDenver, CO 80216 US\n(303) 335-9092',
    state: 'co',
  },
  {
    name: 'JENNIFER HORTON, DO',
    link: 'https://www.ifm.org/practitioners/jennifer-horton/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2935580F-6BF6-4883-8736-341A790C166C-400x400.jpeg',
    contactText:
      'Address\nSattva Health & Wellness\n2550 Youngfield\nLakewood, CO 80215 US\n303-895-7256',
    state: 'co',
  },
  {
    name: 'JENNIFER HORTON, DO',
    link: 'https://www.ifm.org/practitioners/jennifer-horton/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2935580F-6BF6-4883-8736-341A790C166C-400x400.jpeg',
    contactText:
      'Address\nSattva Health & Wellness\n2550 Youngfield\nLakewood, CO 80215 US\n303-895-7256',
    state: 'co',
  },
  {
    name: 'JENNIFER HORTON, DO',
    link: 'https://www.ifm.org/practitioners/jennifer-horton/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2935580F-6BF6-4883-8736-341A790C166C-400x400.jpeg',
    contactText:
      'Address\nSattva Health & Wellness\n2550 Youngfield\nLakewood, CO 80215 US\n303-895-7256',
    state: 'co',
  },
  {
    name: 'JENNIFER HORTON, DO',
    link: 'https://www.ifm.org/practitioners/jennifer-horton/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2935580F-6BF6-4883-8736-341A790C166C-400x400.jpeg',
    contactText:
      'Address\nSattva Health & Wellness\n2550 Youngfield\nLakewood, CO 80215 US\n303-895-7256',
    state: 'co',
  },
  {
    name: 'JENNIFER HORTON, DO',
    link: 'https://www.ifm.org/practitioners/jennifer-horton/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2935580F-6BF6-4883-8736-341A790C166C-400x400.jpeg',
    contactText:
      'Address\nSattva Health & Wellness\n2550 Youngfield\nLakewood, CO 80215 US\n303-895-7256',
    state: 'co',
  },
  {
    name: 'JENNIFER HORTON, DO',
    link: 'https://www.ifm.org/practitioners/jennifer-horton/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2935580F-6BF6-4883-8736-341A790C166C-400x400.jpeg',
    contactText:
      'Address\nSattva Health & Wellness\n2550 Youngfield\nLakewood, CO 80215 US\n303-895-7256',
    state: 'co',
  },
  {
    name: 'JENNIFER HORTON, DO',
    link: 'https://www.ifm.org/practitioners/jennifer-horton/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2935580F-6BF6-4883-8736-341A790C166C-400x400.jpeg',
    contactText:
      'Address\nSattva Health & Wellness\n2550 Youngfield\nLakewood, CO 80215 US\n303-895-7256',
    state: 'co',
  },
  {
    name: 'JENNIFER HORTON, DO',
    link: 'https://www.ifm.org/practitioners/jennifer-horton/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2935580F-6BF6-4883-8736-341A790C166C-400x400.jpeg',
    contactText:
      'Address\nSattva Health & Wellness\n2550 Youngfield\nLakewood, CO 80215 US\n303-895-7256',
    state: 'co',
  },
  {
    name: 'JILL CARNAHAN, MD',
    link: 'https://www.ifm.org/practitioners/jill-carnahan-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.-Jill-blue-small-400x400.jpg',
    contactText:
      'Address\n400 S. McCaslin Blvd\nSuite 210\nLouisville, CO 80027 US\n(303) 993-7910',
    state: 'co',
  },
  {
    name: 'JOHN R. DIXON, DC',
    link: 'https://www.ifm.org/practitioners/john-dixon-dc-ccn/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/JRD-professional-400x400.jpg',
    contactText:
      'Address\n110 E. Main St.\nFlorence, CO 81226 US\n719-315-0808',
    state: 'co',
  },
  {
    name: 'KAREN KSIAZEK, MD',
    link: 'https://www.ifm.org/practitioners/karen-ksiazek-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/238-Ksiazek_-400x400.jpg',
    contactText:
      'Address\n8333 Ralston Road\nSuite 1\nArvada, CO 80002 US\n(303)423-5000',
    state: 'co',
  },
  {
    name: 'KATRINA DE ROMANA',
    link: 'https://www.ifm.org/practitioners/katrina-de-romana-heartlight-family-clinic/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Katrina-head-shot-2023-3-400x400.jpg',
    contactText:
      'Address\n1001 S. Perry ST., Suite 104B\nSuite 104B\nCastle Rock, CO 80104 US\n7202565724',
    state: 'co',
  },
  {
    name: 'LAURIE ANN COX',
    link: 'https://www.ifm.org/practitioners/laurie-c-perla-fnp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Laurie-pic-even-smaller-400x400.gif',
    contactText:
      'Address\nSeventh Street Medical Arts Center\nGrand Junction, CO 81501 US\n7192211033',
    state: 'co',
  },
  {
    name: 'LEAH J JOHANSEN',
    link: 'https://www.ifm.org/practitioners/leah-j-johansen/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Johansen-Leah-Photo-2019-400x400.jpg',
    contactText: 'Address\nOnline\nDenver, CO 80202 US\n2183550649',
    state: 'co',
  },
  {
    name: 'LESLIE YEDOR, DAOM, L.AC., IFMCP, PA-S',
    link: 'https://www.ifm.org/practitioners/leslie-yedor/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2022-11-14-at-1.16.15-PM-400x400.png',
    contactText: 'Address\nPO Box 953\nEdwards, CO 81632 US\n(719) 445-8405',
    state: 'co',
  },
  {
    name: 'LYNN MARIE JOSELYN, PA-C',
    link: 'https://www.ifm.org/practitioners/lynn-marie-joselyn-pa-c/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2021-10-24-at-12.51.15-PM-400x400.png',
    contactText:
      'Address\n1372 Avenida Del Sol\nDurango, CO 81301 US\n9704035251',
    state: 'co',
  },
  {
    name: 'MARIA HOPP, MD',
    link: 'https://www.ifm.org/practitioners/maria-hopp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/HoppMariaB3M020whiteSquare-400x400.jpg',
    contactText:
      'Address\n9200 W Cross Dr #315\nLittleton, CO 80123 US\n720-351-2411',
    state: 'co',
  },
  {
    name: 'MARY PAGE SMITH',
    link: 'https://www.ifm.org/practitioners/mary-page-smith/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/039A02B3-BABC-4327-8CA9-04F819FC6026-400x400.jpeg',
    contactText:
      'Primary Address\n30772 Southview Drive, 120\nEvergreen, CO 80439 US\n3038854034',
    state: 'co',
  },
  {
    name: 'MELISSA L MILAN, MD',
    link: 'https://www.ifm.org/practitioners/melissa-milan/',
    avatar: 'https://www.ifm.org/wp-content/uploads/headshot21-400x400.jpg',
    contactText: '(720) 392-5252',
    state: 'co',
  },
  {
    name: 'MEREDITH RUSTHOVEN',
    link: 'https://www.ifm.org/practitioners/meredith-rusthoven-dnp-cnm/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: '720-984-1736',
    state: 'co',
  },
  {
    name: 'NATHAN MORRIS',
    link: 'https://www.ifm.org/practitioners/nathan-morris-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Nathan-Bio-2015-400x400.jpg',
    contactText: 'Address\n88 Hwy 105\nPalmer Lake, CO 80133 US\n719-419-8002',
    state: 'co',
  },
  {
    name: 'PAMELA J. HYDE, DO, ABOG, ABAARM, FAARM',
    link: 'https://www.ifm.org/practitioners/pamela-j-hyde-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2022-08-24-at-5.44.56-PM-1-400x400.png',
    contactText:
      'Primary Address\n2523 Broadway\nSuite 202B\nBoulder, CO 80304 US\n(720) 588-2025',
    state: 'co',
  },
  {
    name: 'RACHEL C FISCHER, MD',
    link: 'https://www.ifm.org/practitioners/rachel-c-fischer-md-mph/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/headshot2_RCFISCHER-400x400.jpg',
    contactText:
      'Address\n3553 Clydesdale Parkway\nSuite 230\nLoveland, CO 80538 US\n(970) 278-0900',
    state: 'co',
  },
  {
    name: 'RANDOLPH JAMES, MD',
    link: 'https://www.ifm.org/practitioners/randolph-james-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Headshot-Randy-2016-400x400.jpg',
    contactText:
      'Address\n403 S. Baldwin\nWoodland Park, CO 80863 US\n(719) 686-7776',
    state: 'co',
  },
  {
    name: 'SANDRA PLYBON, MD',
    link: 'https://www.ifm.org/practitioners/sandra-plybon/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/A23F841D-5CF5-4406-AE94-243BF457BBD4-400x400.jpeg',
    contactText:
      'Address\n2038 Vermont Drive, suite 207\nFort Collins, CO 80525 US',
    state: 'co',
  },
  {
    name: 'SHANNON EVANS, DO',
    link: 'https://www.ifm.org/practitioners/shannon-evans-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/1X5A0357-Edit-Edit-2-400x400.jpg',
    contactText:
      'Address\n4745 Boardwalk Drive Unit C3\nFort Collins, CO 80525 US\n970-305-0101',
    state: 'co',
  },
  {
    name: 'SHEILA KUPERSMITH, FNP',
    link: 'https://www.ifm.org/practitioners/sheila-kupersmith-fnp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Kupersmith-new-headshot-crop-400x400.jpeg',
    contactText:
      'Address\n401 Mason Ct. Unit 101\nFORT COLLINS, CO 80524 US\n9708290077',
    state: 'co',
  },
  {
    name: 'SHELLEY A MEYER, DO',
    link: 'https://www.ifm.org/practitioners/shelley-a-meyer-do-rdn/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Shelley-Picture-400x400.jpg',
    contactText:
      'Address\n3729 West 32nd Avenue\nDenver, CO 80211 US\n(303) 916-1064',
    state: 'co',
  },
  {
    name: 'SHIRLEY OLSON, NP-C',
    link: 'https://www.ifm.org/practitioners/shirley-olson-fnp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/S.-Olson-100-x-300-pxl-400x400.jpg',
    contactText:
      'Address\n195 S. Lena Ste. A\nRidgway, CO 81432 US\n(970) 626-6026',
    state: 'co',
  },
  {
    name: 'STEFANY TESTERMAN, FNP-C, RYT, IFMCP',
    link: 'https://www.ifm.org/practitioners/stefany-carstensen/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/00100lPORTRAIT_00100_BURST20190810110159481_COVER-400x400.jpg',
    contactText:
      'Address\n6551 South Revere Parkway\nSuite 270\nCentennial, CO 80111 US\n303-210-6843',
    state: 'co',
  },
  {
    name: 'SUSAN LAN, DO',
    link: 'https://www.ifm.org/practitioners/susan-lan-do-mph/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Susan-Lan-head-shot-400x400.jpg',
    contactText:
      'Address\n137 Main Street C101\nEdwards, CO 81632 US\n(970) 569-2030',
    state: 'co',
  },
  {
    name: 'SUSANNAH KERWIN, NP',
    link: 'https://www.ifm.org/practitioners/susannah-kerwin/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/SusannahHeadshot2020-copy-400x400.jpg',
    contactText:
      'Primary Address\n50 S. Steele Street\nSuite #950\nDenver, CO 80209 US\n347-450-5892',
    state: 'co',
  },
  {
    name: 'TAMMY SORENSON BESHER',
    link: 'https://www.ifm.org/practitioners/tammy-besher/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/TAMMYSORENSONBESHER-CROP-400x400.jpg',
    contactText:
      'Address\n1262 Bergen Parkway, Suite E-18 #6\nEvergreen, CO 80439 US\n9175756075',
    state: 'co',
  },
  {
    name: 'WANDA BEDINGHAUS, MD',
    link: 'https://www.ifm.org/practitioners/wanda-bedinghaus-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/DSCN1527-400x400.jpg',
    contactText:
      'Primary Address\n7220 West Jefferson Avenue, #204\nLakewood, CO 80235 US\n(303) 986-0492',
    state: 'co',
  },
  {
    name: 'TAMARA J BLOSSIC',
    link: 'https://www.ifm.org/practitioners/tamara-j-blossic-dc-ficpa-ifmcp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Tammy-400x400.png',
    contactText:
      'Address\n20 Montchanin Rd\nSuite 60\nWilmington, DE 19807 US\n(302) 205-4100',
    state: 'de',
  },
  {
    name: 'ANJALI C DSOUZA, MD',
    link: 'https://www.ifm.org/practitioners/anjali-c-dsouza-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2017-12-11-at-3.18.01-PM-400x400.png',
    contactText:
      'Address\n1915 I Street NW Suite 700\nWashington, DC 20006 US\n202-251-7541',
    state: 'dc',
  },
  {
    name: 'ANNE BERKELEY, ND',
    link: 'https://www.ifm.org/practitioners/anne-berkeley/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/resized-photo-Berkeley-400x400.jpg',
    contactText:
      'Address\n1915 I Street NW Suite 700\nWashington, DC 20006 US\n202-798-0221',
    state: 'dc',
  },
  {
    name: 'ERIN J. BAGSHAW, NP',
    link: 'https://www.ifm.org/practitioners/erin-bagshaw-np/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Photoguzman_NWNP_-017R-2-400x400.jpg',
    contactText:
      'Address\n3000 Connecticut Avenue NW\nSuite 338\nWashington, DC 20008 US\n(202) 483-6672',
    state: 'dc',
  },
  {
    name: 'GREG NATELLO, DO',
    link: 'https://www.ifm.org/practitioners/gregory-natello-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.-Greg-Natello4-sq-3-400x400.jpg',
    contactText: 'Primary Address\nPerimeter Road\nWashington, DC 20762 US',
    state: 'dc',
  },
  {
    name: 'SHEPHALI RANPURIA, M.D.',
    link: 'https://www.ifm.org/practitioners/shephali-ranpuria-m-d/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1915 I Street NW, Suite 700\nWashington, DC 20006 US\n202-251-7541',
    state: 'dc',
  },
  {
    name: 'ACCAMMA GENI ABRAHAM, MD',
    link: 'https://www.ifm.org/practitioners/accamma-geni-abraham-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/DR-ABRAHAM-FACEBOOK-400x400.jpg',
    contactText: 'Address\n205 JFK DR\nLake Worth, FL 33462 US\n(561) 432-8935',
    state: 'fl',
  },
  {
    name: 'ADAM PERLMAN, MD',
    link: 'https://www.ifm.org/practitioners/adam-perlman-md-mph/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n4500 San Pablo Road\nJacksonville, FL 32224 US\n9049532000',
    state: 'fl',
  },
  {
    name: 'AMAR KALIDAS, DO',
    link: 'https://www.ifm.org/practitioners/amar-kalidas-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/MG_676755-400x400.jpg',
    contactText:
      'Address\n6651 Vineland Road\nSuite 150\nOrlando, FL 32819 US\n4073559246',
    state: 'fl',
  },
  {
    name: 'ANTHONY SERLE',
    link: 'https://www.ifm.org/practitioners/anthony-serle-bs-dc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/the-tunnel-of-trees1-400x400.jpg',
    contactText:
      'Address\n10752 Deerwood Park Blvd.\nSuite 100\nJacksonville, FL 32256 US\n(904) 814-8417',
    state: 'fl',
  },
  {
    name: 'ASHLEE SEEK',
    link: 'https://www.ifm.org/practitioners/ashlee-seek/',
    avatar: 'https://www.ifm.org/wp-content/uploads/headshot2-3-400x400.jpg',
    contactText: 'Address\n40 SW 1st Ave\nOcala, FL 34471 US\n352-512-0631',
    state: 'fl',
  },
  {
    name: 'BARB M. MAHLMEISTER',
    link: 'https://www.ifm.org/practitioners/barb-m-mahlmeister-rdn-ifmcp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Barb_3-copy-400x400.jpg',
    contactText:
      'Address\n110 W. Country Club Drive\nTampa, FL 33612 US\n8134952719',
    state: 'fl',
  },
  {
    name: 'BETH SEGERHOLM',
    link: 'https://www.ifm.org/practitioners/beth-segerholm/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Root-Pic-43-400x400.jpg',
    contactText:
      'Primary Address\n1000 S Ft Harrison Ave\nClearwater, FL 33756 US\n7273350400',
    state: 'fl',
  },
  {
    name: 'BOJANA JOVANOVIC',
    link: 'https://www.ifm.org/practitioners/bojana-jovanovic-1982/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n40 SW 13St Suite 402\nMiami, FL 33130 US\n+1 (305) 928-6001',
    state: 'fl',
  },
  {
    name: 'BONNIE MACKEY',
    link: 'https://www.ifm.org/practitioners/bonnie-mackey-phd-np-ahn-bc/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\nTeleHealth\nPalm Beach Gardens, FL 33410 US\n(315) 246-8559',
    state: 'fl',
  },
  {
    name: 'CANDICE RAE COLEMAN-POPE',
    link: 'https://www.ifm.org/practitioners/candice-rae-coleman-pope/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/47CDCCBC-97B9-41A5-A37D-97DBE0D9A226-400x400.jpeg',
    contactText:
      'Address\n301 N Tubbs St. Suite 114 (Inside Acupuncture by Molly)\nOakland , FL 34760 US\n407-974-3552',
    state: 'fl',
  },
  {
    name: 'CAROLYN S GEORGE, MD, IFMCP',
    link: 'https://www.ifm.org/practitioners/carolyn-s-george-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/photo-with-white-frame-400x400.jpg',
    contactText:
      'Address\n12651 W. Sunrise Blvd.\nSuite 103\nSunrise, FL 33323 US\n(954) 361-5202',
    state: 'fl',
  },
  {
    name: 'CHARISSE HUOT',
    link: 'https://www.ifm.org/practitioners/charisse-huot/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n807 N. Myrtle Avenue\nClearwater, FL 33755 US\n727-467-2400',
    state: 'fl',
  },
  {
    name: 'CHRISTINE E. MILLER, MS, RD, IFMCP',
    link: 'https://www.ifm.org/practitioners/christine-miller-ms-rd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/professional-picture-400x400.jpg',
    contactText:
      'Address\n2814 W. Parkland Blvd\nTampa, FL 33609 US\n(813) 340-3084',
    state: 'fl',
  },
  {
    name: 'CHRISTOPHER ESTES',
    link: 'https://www.ifm.org/practitioners/christopher-estes/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Chris-Headshot-Small-400x400.jpg',
    contactText:
      'Address\n1680 Meridian Ave\nSuite 603\nMiami Beach, FL 33139 US\n305-397-8229',
    state: 'fl',
  },
  {
    name: 'CHRISTOPHER LEMAY, DO',
    link: 'https://www.ifm.org/practitioners/christopher-lemay/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_6918-1-400x400.jpg',
    contactText:
      'Primary Address\n1095 Military Trail\nP.O. Box 2625\nJupiter, FL 33458 US\n561-739-4068',
    state: 'fl',
  },
  {
    name: 'CINTIA PAZ, MD',
    link: 'https://www.ifm.org/practitioners/cintia-paz/',
    avatar: 'https://www.ifm.org/wp-content/uploads/CINTIA-400x400.jpg',
    contactText:
      'Address\n9700 Stirling Road Suite 107\nCooper City , FL 33024 US\n754-260-5880',
    state: 'fl',
  },
  {
    name: 'COURTNEY GEISTER',
    link: 'https://www.ifm.org/practitioners/courtney-geister/',
    avatar: 'https://www.ifm.org/wp-content/uploads/headshot-7-400x400.jpeg',
    contactText:
      'Address\n2404 N. Courtenay Parkway\nMerritt Island, FL 32953 US\n321-378-5230',
    state: 'fl',
  },
  {
    name: 'CRAIG P. TANIO, MD',
    link: 'https://www.ifm.org/practitioners/craig-peter-tanio-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/5-Rezilir-003-400x400.jpg',
    contactText:
      'Address\n1930 Harrison Ave\nSuite 404\nHollywood, FL 33020 US\n(786) 780-1188',
    state: 'fl',
  },
  {
    name: 'DAVID J BLYWEISS, MD',
    link: 'https://www.ifm.org/practitioners/david-blyweiss-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/43138789_10215503708898734_2630451809902133248_n-1-400x400.jpeg',
    contactText:
      'Address\n2631 E.Oakland Park Blvd\nSuite 110\nFt.Lauderdale, FL 33306 US\n561-565-5800',
    state: 'fl',
  },
  {
    name: 'DAVID C ROSENBERG, MD',
    link: 'https://www.ifm.org/practitioners/david-rosenberg/',
    avatar: 'https://www.ifm.org/wp-content/uploads/David-400x400.jpg',
    contactText:
      'Address\n2141 South Highway A1A Alt.\nSuite 200\nJupiter, FL 33477 US\n5617430005',
    state: 'fl',
  },
  {
    name: 'DAVID SUDDERTH, MD',
    link: 'https://www.ifm.org/practitioners/david-sudderth-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n3210 Cleveland Ave\nSuite 100\nFort Myers , FL 33901 US\n239 275 6690',
    state: 'fl',
  },
  {
    name: 'DEBORAH SIMKIN, MD',
    link: 'https://www.ifm.org/practitioners/deborah-simkin-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/FB_IMG_1563936930935-400x400.jpg',
    contactText:
      'Address\n4641 Gulfstarr Dr\nSuite 106\nDestin, FL 32541 US\n(850) 243-9788',
    state: 'fl',
  },
  {
    name: 'DENISE PRYOR KRUSZYNSKI',
    link: 'https://www.ifm.org/practitioners/denise-pryor-kruszynski/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/6CD1E480-FFD1-4FC3-8013-45915FDC9E11-400x400.jpeg',
    contactText:
      'Address\n7595 SW 33rd Street, # 4th floor\nDavie, FL 33314 US\n954-262-2850',
    state: 'fl',
  },
  {
    name: 'EDUARDO MANUEL MARISTANY, MD',
    link: 'https://www.ifm.org/practitioners/eduardo-manuel-maristany-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/78396995_109444003876851_7220597029741789184_n-400x400.jpg',
    contactText:
      'Address\n800 Goodlette Road North number 270\nNaples, FL 34102 US\n2396497400',
    state: 'fl',
  },
  {
    name: 'N. ELIZABETH MARKOVICH',
    link: 'https://www.ifm.org/practitioners/elizabeth-markovich-np/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_0076-400x400.jpg',
    contactText:
      'Address\n1630 Riggins Rd\nTallahassee, FL 32308 US\n(850) 878-4434',
    state: 'fl',
  },
  {
    name: 'EMILY THERIZA ROWE, MD',
    link: 'https://www.ifm.org/practitioners/emily-rowe/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Headshot-Emily-Rowe-400x400.png',
    contactText:
      'Address\n1680 Meridian Ave\nSuite 603\nMiami Beach, FL 33139 US\n305-397-8229',
    state: 'fl',
  },
  {
    name: 'ERIC SIMS GERKEN, DC',
    link: 'https://www.ifm.org/practitioners/eric-gerken-dc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/US-at-the-Clinic-Santiago-CH-2-400x400.jpg',
    contactText:
      'Primary Address\n4309 Mariner Way\nSuite 401\nFort Myers, FL 33919 US\n239-470-3130',
    state: 'fl',
  },
  {
    name: 'FEDERICO J MARTINEZ, MD',
    link: 'https://www.ifm.org/practitioners/federico-j-martinez-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/FB_IMG_1496880485320-400x400.jpg',
    contactText:
      'Primary Address\n2853 Executive Park Drive\nSUITE 104\nWeston , FL 33331 US\n(954) 573-5464',
    state: 'fl',
  },
  {
    name: 'GABRIEL SUAREZ, MD MBA',
    link: 'https://www.ifm.org/practitioners/gabriel-suarez/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Headshot-small-Suarez-400x400.png',
    contactText:
      'Address\n9835 SW 72nd St Suite 208\nMiami, FL 33173 US\n786-953-8867',
    state: 'fl',
  },
  {
    name: 'GARRIC R VOSLOO',
    link: 'https://www.ifm.org/practitioners/garric-r-vosloo-bsc-hons/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Primary Address\n3144\nScrub Oak Trl\nOviedo, FL 32765 US',
    state: 'fl',
  },
  {
    name: 'HEATHER CHRISTIE, MD',
    link: 'https://www.ifm.org/practitioners/heather-christie/',
    avatar: 'https://www.ifm.org/wp-content/uploads/P23-400x400.jpg',
    contactText:
      'Address\n2191 9th Avenue N\nSuite 240\nSt. Petersburg, FL 33713 US\n727-220-9080',
    state: 'fl',
  },
  {
    name: 'HELEN M MESSIER, FOUNTAIN LIFE',
    link: 'https://www.ifm.org/practitioners/helen-messier-phd-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n6424 Alexandra Louise Drive\nSuite 120\nOrlando, FL 32827 US\n8587366260',
    state: 'fl',
  },
  {
    name: 'HOLLY J NILES, MS',
    link: 'https://www.ifm.org/practitioners/holly-j-niles-ms-cns-ldn-rncp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Holly_Niles-017-linkedin-400x400.jpg',
    contactText:
      'Primary Address\n14241 US HIGHWAY 1\nJuno Beach, FL 33408 US\n860-266-5866',
    state: 'fl',
  },
  {
    name: 'JENNA MONTANA, DC',
    link: 'https://www.ifm.org/practitioners/jenna-montana/',
    avatar: 'https://www.ifm.org/wp-content/uploads/bb-jenjen-400x400.jpg',
    contactText: '(540)-755-0221',
    state: 'fl',
  },
  {
    name: 'JENNIFER BRODER, RD LD/N CISSN IFMCP',
    link: 'https://www.ifm.org/practitioners/jennifer-broder-rd-ldn-cssd-cissn/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n1646 West Snow Ave attn Hyde House\nTampa, FL 33606 US\n8138507592',
    state: 'fl',
  },
  {
    name: 'JULIE C HILL',
    link: 'https://www.ifm.org/practitioners/julie-hill-registered-dietitian/',
    avatar: 'https://www.ifm.org/wp-content/uploads/julie-1-400x400.jpg',
    contactText:
      'Address\n9160 Forum Corporate Pkwy\nSuite 350\nFort Myers, FL 33905 US\n239-220-0288',
    state: 'fl',
  },
  {
    name: 'JULIE SOUTHMAYD, MD',
    link: 'https://www.ifm.org/practitioners/julie-southmayd-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n311 9th street north\nsuite 306\nNaples, FL 34102 US\n(239) 624-0340',
    state: 'fl',
  },
  {
    name: 'KAREN KOFFLER, MD',
    link: 'https://www.ifm.org/practitioners/karen-koffler-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/KK2-400x400.jpg',
    contactText:
      'Primary Address\n1801 NW 9th Ave Suite 470\nMiami, FL 33136 US\n305 243 0965 #1',
    state: 'fl',
  },
  {
    name: 'KATE VAZQUEZ, MMS, PAC',
    link: 'https://www.ifm.org/practitioners/kate-vazquez/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/DSC04147-compressed-400x400.jpg',
    contactText: 'Address\nVirtual Only\nDelray Beach, FL 33444 US',
    state: 'fl',
  },
  {
    name: 'KEEGAN ZIEMBA',
    link: 'https://www.ifm.org/practitioners/keegan-ziemba/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Size-adjusted-400x400.jpg',
    contactText:
      'Primary Address\nServing Virtually in Florida, Pennsylvania, New Hampshire and Virginia\nSt. Petersburg, FL 33702 US\n5615562656',
    state: 'fl',
  },
  {
    name: 'KERRY JENKINS',
    link: 'https://www.ifm.org/practitioners/kerry-whitacre/',
    avatar: 'https://www.ifm.org/wp-content/uploads/G3A0633-400x400.jpg',
    contactText:
      'Address\n22047 State Road 7\nBoca Raton, FL 33428 US\n7862659443',
    state: 'fl',
  },
  {
    name: 'KIM ROSS, DCN, CNS, LDN',
    link: 'https://www.ifm.org/practitioners/kim-ross-ms-cns-ifmcp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2018-ACN-Portraits-097-Copy-400x400.jpg',
    contactText: 'Address\n17520 Gawthrop Dr.\nLakewood Ranch, FL 34211 US',
    state: 'fl',
  },
  {
    name: 'KRISTINE TOHTZ',
    link: 'https://www.ifm.org/practitioners/kristine-tohtz/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/white-top-headshot-2-400x400.jpg',
    contactText:
      'Address\n5078 SE Askew Ave\nStuart, FL 34997 US\n312-898-6327',
    state: 'fl',
  },
  {
    name: 'LANCE TOUSSAINT, PA',
    link: 'https://www.ifm.org/practitioners/lance-toussaint-pa/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_4534-400x400.jpg',
    contactText:
      'Address\n1772 Florence Vista Blvd\nOrlando, FL 32818 US\n(407) 493-4290',
    state: 'fl',
  },
  {
    name: 'LILI BUZSAKI',
    link: 'https://www.ifm.org/practitioners/lili-buzsaki/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/38E7F27C-CF6A-4951-A6B6-1B2EB1F0AE71-400x400.jpeg',
    contactText: '',
    state: 'fl',
  },
  {
    name: 'M ELIZABETH BENNETT',
    link: 'https://www.ifm.org/practitioners/m-elizabeth-bennett/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Me-at-Work-mod-400x400.jpg',
    contactText:
      'Address\n1334 Timberlane Road\nSuite 1\nTallahassee, FL 32312 US\n850-781-9888',
    state: 'fl',
  },
  {
    name: 'MADELEINE M CASTELLANOS, MD',
    link: 'https://www.ifm.org/practitioners/madeleine-m-castellanos-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/castellanos-head-shot-400x400.jpg',
    contactText:
      'Primary Address\n4800 N. Federal Hwy\nBoca Raton, FL 33431 US\n561-316-6717',
    state: 'fl',
  },
  {
    name: 'MARJORIE DAWN BROWN',
    link: 'https://www.ifm.org/practitioners/marjorie-brown/',
    avatar: 'https://www.ifm.org/wp-content/uploads/PA-Pic-in-Room-400x400.jpg',
    contactText:
      'Address\n6228 NW 43rd Street\nSuite B\nGainesville, FL 32653-8871 US\n352-332-6680',
    state: 'fl',
  },
  {
    name: 'MICHELE GRUNDSTEIN, MD',
    link: 'https://www.ifm.org/practitioners/michele-grundstein/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Michele-400x400.jpg',
    contactText:
      'Address\nQuantum Medical & Wellness Center\n1261 South Pine Island Road\nPlantation, FL 33324 US\n(954) 370-1900',
    state: 'fl',
  },
  {
    name: 'MYRA REED, MD',
    link: 'https://www.ifm.org/practitioners/myra-reed-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1814 Thomas Dr\nPanama City Beach, FL 32408-5825 US\n(850) 249-5000',
    state: 'fl',
  },
  {
    name: 'NHI MA DO',
    link: 'https://www.ifm.org/practitioners/nhi-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Do_Nhi_1067164_ID-BADGE-400x400.jpg',
    contactText:
      'Address\n420 S. Nova Rd. Suite 5\nOrmond Beach, FL 32174 US\n386-506-0111',
    state: 'fl',
  },
  {
    name: 'RAM K REDDY, MD',
    link: 'https://www.ifm.org/practitioners/ram-k-reddy-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/07-10-17-US-standard-photos-EL-1237-400x400.jpg',
    contactText:
      'Primary Address\n7232 Sand Lake Rd\nSuite 203 TeleMedicine-Consults- all of Florida, GA & VA\nOrlando, FL 32819 US\n407-384-3000',
    state: 'fl',
  },
  {
    name: 'REBECCA FREESE, MD',
    link: 'https://www.ifm.org/practitioners/rebecca-freese/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/RebeccaFreese43B-400x400.jpg',
    contactText: 'Address\n--\nMiami, FL 33101 US',
    state: 'fl',
  },
  {
    name: 'REGINA DRUZ, MD',
    link: 'https://www.ifm.org/practitioners/regina-druz-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/DSCF5939.jpg_edited-400x400.jpg',
    contactText:
      'Address\nHolistic Heart Centers\nMiami, FL 33160 US\n(800) 588-8628',
    state: 'fl',
  },
  {
    name: 'RICHARD PETERSEN, DC',
    link: 'https://www.ifm.org/practitioners/richard-petersen-dc-ccn/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.-Rick-500x500-2-400x400.jpg',
    contactText:
      'Address\n1000 S Fort Harrison Ave\nClearwater, FL 33756 US\n727-335-0400',
    state: 'fl',
  },
  {
    name: 'R. MICHELE EMERY, MD',
    link: 'https://www.ifm.org/practitioners/rose-michele-emery-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n212 NW 135th Way\nSuite 20\nNewberry, FL 32669 US\n352-204-0120',
    state: 'fl',
  },
  {
    name: 'ROSEMARIE RUTECKI',
    link: 'https://www.ifm.org/practitioners/rosemarie-rutecki/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/DSC06110-Edit-Edit-copy-400x400.png',
    contactText: 'Address\n70th CT\nParkland, FL 33067 US\n312-399-9253',
    state: 'fl',
  },
  {
    name: 'SAIMA QURESHI, MD',
    link: 'https://www.ifm.org/practitioners/saima-qureshi/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/GSW_DSC_6053_pp-copy3-400x400.jpg',
    contactText:
      'Address\nVirtual (serving all of Florida)\nChildren and Adults\nLake Mary, FL 32746 US\n(407) 377-6934',
    state: 'fl',
  },
  {
    name: 'SHILPA PATEL SAXENA, MD',
    link: 'https://www.ifm.org/practitioners/shilpa-saxena/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screenshot-2020-10-28-at-8.17.49-AM-400x400.png',
    contactText:
      'Address\n3820 Northdale Blvd\nSuite 107-A\nTampa, FL 33624 US\n(813) 269-2700',
    state: 'fl',
  },
  {
    name: 'STACEY ROBINSON, MD',
    link: 'https://www.ifm.org/practitioners/stacey-robinson-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Square-headshot-400x400.jpg',
    contactText:
      'Address\n100 2nd Ave S, Ste 904S\nSt. Petersburg, FL 33701 US\n7273298859',
    state: 'fl',
  },
  {
    name: 'STACIE J. STEPHENSON',
    link: 'https://www.ifm.org/practitioners/stacie-stephenson-dc-cns/',
    avatar: 'https://www.ifm.org/wp-content/uploads/0125-2-400x400.jpg',
    contactText:
      'Address\n5900 Broken Sound Parkway NW\nBoca Raton, FL 33487 US',
    state: 'fl',
  },
  {
    name: 'TAMI SINGH, MD',
    link: 'https://www.ifm.org/practitioners/tami-singh/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2020-08-01-at-2.55.03-PM-400x400.png',
    contactText:
      'Address\n4161 NW 5th Street\nSuite 101\nPlantation, FL 33317 US\n9549984468',
    state: 'fl',
  },
  {
    name: 'TRUDY MOON EISEL, DC',
    link: 'https://www.ifm.org/practitioners/trudy-moon-eisel-dc-dacbn-ccn/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1190 Pine Ridge Rd.\nSuite 1\nNaples, FL 34108 US\n(239) 261-1387',
    state: 'fl',
  },
  {
    name: 'VARSHA RATHOD, MD',
    link: 'https://www.ifm.org/practitioners/varsha-rathod-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IFM-Small-400x400.jpg',
    contactText:
      'Primary Address\nTelehealth Visits for Florida & Missouri.\nOrlando , FL 32751 US',
    state: 'fl',
  },
  {
    name: 'VIKKI M PETERSEN, DC',
    link: 'https://www.ifm.org/practitioners/vikki-m-petersen-dc-ccn/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Untitled-design-9-400x400.jpeg',
    contactText:
      'Primary Address\n1000 S. Fort Harrison Ave\nClearwater, FL 33756 US\n(727) 335-0400',
    state: 'fl',
  },
  {
    name: 'WEST-KY ABRAMS, DO',
    link: 'https://www.ifm.org/practitioners/west-ky-abrams-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/DocsPicture-400x400.jpeg',
    contactText:
      'Address\n1951 SW 172nd Ave.\nSuite 308\nMiramar, FL 33029 US\n(954) 431-1904',
    state: 'fl',
  },
  {
    name: 'YOUSEF ELYAMAN, MD',
    link: 'https://www.ifm.org/practitioners/yousef-elyaman-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n7350 SW 60th Ave\nSuite 2\nOcala, FL 34476 US\n(352) 854-5530',
    state: 'fl',
  },
  {
    name: 'ZORAYDA TORRES, MD',
    link: 'https://www.ifm.org/practitioners/zorayda-torres-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n27499 Riverview Center Blvd\nSuite 255\nBonita Springs, FL 34134 US\n(239) 444-5636',
    state: 'fl',
  },
  {
    name: 'ASHLEY LOSEKE',
    link: 'https://www.ifm.org/practitioners/ashley-loseke/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/443DFA08-3A90-4BC9-AE24-7DF08D1C39E0-400x400.jpeg',
    contactText: 'Address\n106 Moran Drive\nBonaire, GA 31005 US',
    state: 'ga',
  },
  {
    name: 'BINDU JEAN ALEXANDER',
    link: 'https://www.ifm.org/practitioners/bindu-alexander/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n401S Main street\nSuite A1\nAlpharetta, GA 30009 US\n6785851639',
    state: 'ga',
  },
  {
    name: 'BRIDGET ALSUP',
    link: 'https://www.ifm.org/practitioners/bridget-alsup/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/769511F3-8C4F-4638-BE01-F8CDD495CFBC-1-400x400.jpeg',
    contactText: 'Address\n100 Commerce Drive, #414\nTyrone, GA 30290 US',
    state: 'ga',
  },
  {
    name: 'CHARLES MICHAEL WISPERT, PA-C',
    link: 'https://www.ifm.org/practitioners/charles-michael-wispert/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_0801-002-400x400.jpg',
    contactText:
      'Address\n3002 Allen Dr. Suite C\nEvans, GA 30809 US\n706 833-4017',
    state: 'ga',
  },
  {
    name: 'CHRISTINE DAECHER, DO',
    link: 'https://www.ifm.org/practitioners/christine-marie-daecher/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Head-shot-3-400x400.jpg',
    contactText:
      'Address\n420 E Gaston Street\nSavannah, GA 31401 US\n7174182841',
    state: 'ga',
  },
  {
    name: 'CONNIE CHEUNG',
    link: 'https://www.ifm.org/practitioners/connie-jeon-dpt-mph-rdld/',
    avatar: 'https://www.ifm.org/wp-content/uploads/conniehead-400x400.png',
    contactText:
      'Primary Address\n686 Peachtree Industrial Blvd\nSuite 140\nSuwanee, GA 30024 US\n6783355566',
    state: 'ga',
  },
  {
    name: 'DEANDRA MCDUFFIE',
    link: 'https://www.ifm.org/practitioners/deandra-mcduffie/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Headshot-22-400x400.jpg',
    contactText:
      'Primary Address\n1550 Janmar Rd\nSte B\nSnellville, GA 30078 US\n7708223031',
    state: 'ga',
  },
  {
    name: 'DENISE PICKETT-BERNARD, PHD',
    link: 'https://www.ifm.org/practitioners/denise-pickett-bernard-phd-rdn-ldn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/DSC_0628-1-400x400.jpg',
    contactText:
      'Address\n760 Old Roswell Road\nSuite 112\nRoswell, GA 30076 US\n(954) 309-5366',
    state: 'ga',
  },
  {
    name: 'ELIZABETH BOARD, MD',
    link: 'https://www.ifm.org/practitioners/elizabeth-board-md-ifmcp-daba/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_9300-2-400x400.jpg',
    contactText:
      'Primary Address\n5755 North Point Parkway\nSuite 74\nAlpharetta, GA 30022 US\n(770) 667-3006',
    state: 'ga',
  },
  {
    name: 'ERINN R. HARRIS, MD',
    link: 'https://www.ifm.org/practitioners/erinn-harris/',
    avatar: 'https://www.ifm.org/wp-content/uploads/1C-400x400.jpg',
    contactText:
      'Address\n4000 Shakerag Hill\nSte 302\nPeachtree City, GA 30269 US\n770-954-5010',
    state: 'ga',
  },
  {
    name: 'HANNAH ELIZABETH GILMORE',
    link: 'https://www.ifm.org/practitioners/hannah-elizabeth-gilmore/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n2401 Dawson Road Suite J\nAlbany, GA 31701 US\n229-347-2538',
    state: 'ga',
  },
  {
    name: 'JAMIE WALRAVEN',
    link: 'https://www.ifm.org/practitioners/jamie-walraven/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1000 Commerce Dr.\nSuite 300\nPeachtree City, GA 30269 US\n6783648414',
    state: 'ga',
  },
  {
    name: 'JENNIFER FRANKLIN, MD',
    link: 'https://www.ifm.org/practitioners/jennifer-franklin/',
    avatar: 'https://www.ifm.org/wp-content/uploads/DSC_0941-2-400x400.jpg',
    contactText:
      'Address\n210 Trilith Pkwy\nSuite 100\nFayetteville, GA 30214 US\n678-210-1956',
    state: 'ga',
  },
  {
    name: 'JOHN B. ABELL, MD',
    link: 'https://www.ifm.org/practitioners/john-b-abell-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n123 No. Westover Blvd\nAlbany, GA 31707 US\n(229) 496-2570',
    state: 'ga',
  },
  {
    name: 'KAREN F. MILLER, MD',
    link: 'https://www.ifm.org/practitioners/karen-f-miller-m-d/',
    avatar: 'https://www.ifm.org/wp-content/uploads/myphoto1-400x400.jpg',
    contactText:
      'Address\n2410 Old Milton Pkwy\nSte A\nAlpharetta, GA 30009 US\n770-676-1027',
    state: 'ga',
  },
  {
    name: 'REBA PEOPLES, MD',
    link: 'https://www.ifm.org/practitioners/reba-peoples-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Reba_Peoples_7836_WEBSIZE-Copy-2-400x400.jpg',
    contactText:
      'Address\n5901 Peachtree-Dunwoody Rd NE\nSuite C65\nAtlanta, GA 30328 US\n678-367-2810',
    state: 'ga',
  },
  {
    name: 'REBECCA HART, ANP-BC, IFMCP',
    link: 'https://www.ifm.org/practitioners/rebecca-hart/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/FB_IMG_1559666356504-400x400.jpg',
    contactText:
      'Address\n130 Canal Street Ste 601\nPooler, GA 31322 US\n9127240955',
    state: 'ga',
  },
  {
    name: 'SERENA KUMAR, NP',
    link: 'https://www.ifm.org/practitioners/serena-kumar-np/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/31D7C5AD-DCBD-4145-86A3-E16C0FE42A21-400x400.jpeg',
    contactText:
      'Address\n4595 Towne Lake Pkwy.\nBldg. 300, Ste. 240\nWoodstock, GA 30189 US\n(770) 693-9094',
    state: 'ga',
  },
  {
    name: 'SONZA L CURTIS',
    link: 'https://www.ifm.org/practitioners/sonza-l-curtis-mpas-pa-c-nd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/FB_IMG_1537580609840-400x400.jpg',
    contactText:
      'Primary Address\n555 Sun Valley Drive Suite C1\nRoswell, GA 30076 US\n6786218100',
    state: 'ga',
  },
  {
    name: 'TRUC NGUYEN, MD',
    link: 'https://www.ifm.org/practitioners/truc-nguyen/',
    avatar: 'https://www.ifm.org/wp-content/uploads/CMED_Office-8-400x400.jpg',
    contactText:
      'Address\n6601 Sugarloaf Pkwy Ste 220\nDuluth, GA 30097-4936 US\n6786293833',
    state: 'ga',
  },
  {
    name: 'VALERIE PERSHAD',
    link: 'https://www.ifm.org/practitioners/valerie-pershad/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_4366copy3-400x400.jpg',
    contactText:
      'Address\n91 W. Wieuca Road NE\nBuilding A Suite 1000\nAtlanta, GA 30342 US\n404-257-5585',
    state: 'ga',
  },
  {
    name: 'CONNIE JEAN ARISPE, MD',
    link: 'https://www.ifm.org/practitioners/connie-j-arispe-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n13 Plaza Drive, Suite 1\nClear Lake, IA 50428 US\n(641) 357-9355',
    state: 'ia',
  },
  {
    name: 'JASON T BRADLEY, ND',
    link: 'https://www.ifm.org/practitioners/jason-t-bradley-dc-nd-dsc-pscd-cn-ma/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/29542553_10155485184660415_8008230543283524755_n-400x400.jpg',
    contactText: 'Address\nPO Box 399\nSolon, IA 52333 US\n319-466-0026',
    state: 'ia',
  },
  {
    name: 'JULIA BUCHKINA, MD',
    link: 'https://www.ifm.org/practitioners/julia-buchkina-md-mph/',
    avatar: 'https://www.ifm.org/wp-content/uploads/jb_picture-400x400.jpg',
    contactText:
      'Address\n221 E. College St\nSuite 211\nIowa City, IA 52240 US\n(319) 471-4727',
    state: 'ia',
  },
  {
    name: 'KARYN SHANKS, MD',
    link: 'https://www.ifm.org/practitioners/karyn-shanks-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Karyn-glam-5-400x400.jpg',
    contactText:
      'Address\n610 Eastbury Dr\nSuite 5\nIowa City, IA 52245 US\n(319) 358-9510',
    state: 'ia',
  },
  {
    name: 'KERRI HUSMAN',
    link: 'https://www.ifm.org/practitioners/kerri-husman/',
    avatar: 'https://www.ifm.org/wp-content/uploads/kerri-275x300-400x400.jpg',
    contactText:
      'Address\n221 East College Street, Suite 211\nIowa City , IA 52240 US\n866-425-8066',
    state: 'ia',
  },
  {
    name: 'LINDA JAGER, MD',
    link: 'https://www.ifm.org/practitioners/linda-jager-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Image-1-29-19-at-8.24-PM-400x400.jpg',
    contactText:
      'Address\n1750 E 53rd Street\nDavenport , IA 52807 US\n5633454325',
    state: 'ia',
  },
  {
    name: 'MEAGAN MCLAUGHLIN DNP, FNP-C',
    link: 'https://www.ifm.org/practitioners/meagan-friess-meaganmmclaughlingmail-com/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n713 1st ave NW\nMount Vernon, IA 52314 US\n319-895-4080',
    state: 'ia',
  },
  {
    name: 'RACHEL M. HEADINGS, ARNP',
    link: 'https://www.ifm.org/practitioners/rachel-headings-arnp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/RH_Aug2020-111-400x400.jpg',
    contactText: '3193825540',
    state: 'ia',
  },
  {
    name: 'RACHEL WANNINGER, DC, IFMCP',
    link: 'https://www.ifm.org/practitioners/rachel-wanninger/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Headshot-horizontal-2-400x400.jpg',
    contactText: 'Address\n1444 NW 124th Ct\nClive, IA 50325 US\n515-278-2782',
    state: 'ia',
  },
  {
    name: 'SHERRY RODDY BREWER, MD',
    link: 'https://www.ifm.org/practitioners/sherry-roddy-brewer-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_3952-400x400.jpg',
    contactText:
      'Address\n1750 E 53rd ST\nDavenport, IA 52807 US\n(563)259-7269',
    state: 'ia',
  },
  {
    name: 'TANYA TEGGATZ, MD',
    link: 'https://www.ifm.org/practitioners/tanya-teggatz-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/C28AE20E-E4D5-4F44-A2CD-4E65ACCA7F4A-400x400.png',
    contactText:
      'Address\n1570 42nd St. NE\nSuite #7\nCedar Rapids, IA 52402 US\n3193934307',
    state: 'ia',
  },
  {
    name: 'AKIKO MATSUMOTO',
    link: 'https://www.ifm.org/practitioners/akiko-matsumoto/',
    avatar: 'https://www.ifm.org/wp-content/uploads/face-400x400.png',
    contactText:
      'Primary Address\n5610 N Frostwood Pky\nPeoriaIL\nPeoria, IL 61615 US\n5133640904 https://functional21medicine.wixsite.com/wellbeing',
    state: 'il',
  },
  {
    name: 'ALISON LECHNER, DO',
    link: 'https://www.ifm.org/practitioners/alison-lechner-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/alison-3-crop-ifm-400x400.jpg',
    contactText:
      'Address\n3233 N Arlington Heights Road\nSuite 302\nArlington Heights, IL 60004 US\n(224) 345-2532',
    state: 'il',
  },
  {
    name: 'AMY S WEILER, DO',
    link: 'https://www.ifm.org/practitioners/amy-s-weiler-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.-Amy-Headshot-400x400.png',
    contactText:
      'Address\n920 N. York Road\nSuite 100\nHinsdale, IL 60521 US\n(773) 784-7000',
    state: 'il',
  },
  {
    name: 'ANDREA MEDINA, DC',
    link: 'https://www.ifm.org/practitioners/andrea-medina-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/dr-andrea-medina.jpg',
    contactText:
      'Address\n122 S. Michigan Ave.\nSuite 1265\nChicago, IL 60603 US\n(312) 939-4121',
    state: 'il',
  },
  {
    name: 'ANITA C. LEE, MD',
    link: 'https://www.ifm.org/practitioners/anita-lee/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n2427 Maloney Drive\nBloomington, IL 61704 US\n309-663-1011',
    state: 'il',
  },
  {
    name: 'BRITTANY M. PARISOT-SEBBY, DC',
    link: 'https://www.ifm.org/practitioners/brittany-m-parisot-sebby-dc-dabci-dabcn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/drsebby-400x400.jpg',
    contactText:
      'Address\n741 McHenry Avenue\nSte G\nCrystal Lake , IL 60014 US\n815-246-2822',
    state: 'il',
  },
  {
    name: 'CARA VANWORMER-HARTMAN, DC',
    link: 'https://www.ifm.org/practitioners/cara-denae-vanwormer-dc-dabci-dacbn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/CARA-IFM-6-400x400.png',
    contactText:
      'Address\n110 E. Ogden Avenue\nHinsdale, IL 60521 US\n630-537-0758',
    state: 'il',
  },
  {
    name: 'DEBRA R BRUNK',
    link: 'https://www.ifm.org/practitioners/debra-r-brunk-dnp-fnp-bc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_1911-400x400.jpeg',
    contactText:
      'Address\n1818 Dempster St.\nEvanston , IL 60202 US\n847-868-0397',
    state: 'il',
  },
  {
    name: 'ENO NSIMA-OBOT, MD',
    link: 'https://www.ifm.org/practitioners/eno-nsima-obot-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Eno92119-134-low-res-400x400.jpg',
    contactText:
      'Address\n15000 South Cicero Avenue\nSuite 102\nOak Forest, IL 60452 US\n630-423-3249',
    state: 'il',
  },
  {
    name: 'EVA VICTORIA CHOMKA, MD',
    link: 'https://www.ifm.org/practitioners/eva-victoria-chomka-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\nSt. Joseph Hospital 2900 N. Lake Shore Drive\nChicago, IL 60657 US\n(312) 401-3043',
    state: 'il',
  },
  {
    name: 'FRANCES BAXLEY, MD',
    link: 'https://www.ifm.org/practitioners/frances-baxley/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Baxley-headshot-black-400x400.jpeg',
    contactText:
      'Address\nPark Center\n2400 Chestnut Avenue, Suite A\nGlenview, IL 60026 US\n847-657-3540',
    state: 'il',
  },
  {
    name: 'HETAL SHAH, MD',
    link: 'https://www.ifm.org/practitioners/hetal-shah-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1051 W. RAND ROAD\nARLINGTON HEIGHTS, IL 60004 US\n8472214700',
    state: 'il',
  },
  {
    name: 'JEANENE CACCOPOLA, DO',
    link: 'https://www.ifm.org/practitioners/jeanene-caccopola-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screenshot.headshot-400x400.png',
    contactText:
      'Address\n550 Fox Glen Ct\nBarrington, IL 60010 US\n(847) 868-0484',
    state: 'il',
  },
  {
    name: 'JESSICA HEHMEYER, DC',
    link: 'https://www.ifm.org/practitioners/jessica-hehmeyer-dc-ldn-cns/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Headshot10-400x400.jpg',
    contactText:
      'Address\n141 West Jackson Blvd\nSuite 1460\nChicago, IL 60604 US\n(312)967-8000',
    state: 'il',
  },
  {
    name: 'JESSICA MONTALVO, MD',
    link: 'https://www.ifm.org/practitioners/jessica-montalvo/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Jessica-Montalvo-Square-Headshot-Listing-400x400.png',
    contactText:
      'Address\n2150 Manchester Road\nSuite 210\nWheaton, IL 60187 US\n630-933-9722',
    state: 'il',
  },
  {
    name: 'JUDY MARSHALL, MS',
    link: 'https://www.ifm.org/practitioners/judy-marshall/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/14F94624-A82B-4AD5-87BE-6896394DF3A6-400x400.jpeg',
    contactText:
      'Address\nNo physical address\nOn line only at this time.\nCary, IL 60013 US\n18152197087',
    state: 'il',
  },
  {
    name: 'LAURIE GOLDMAN, MD',
    link: 'https://www.ifm.org/practitioners/laurie-goldman-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Laurie-Goldman-400x400.jpg',
    contactText:
      'Address\n1655 N Arlington Heights Rd\nSuite 202E\nArlington Heights, IL 60004-3900 US\n1.312.973.1866',
    state: 'il',
  },
  {
    name: 'MARC S FINE, MD',
    link: 'https://www.ifm.org/practitioners/marc-s-fine-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Address\n7900 N Milwaukee\nNiles, IL 60074 US\n8473189595',
    state: 'il',
  },
  {
    name: 'PETER KOZLOWSKI, MD',
    link: 'https://www.ifm.org/practitioners/peter-kozlowski-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/photo-1-400x400.jpg',
    contactText:
      'Primary Address\n90-92 Turner Ave\nElk Grove Village, IL 60007 US\n(847) 626-5758',
    state: 'il',
  },
  {
    name: 'RACHAEL FABBI, DC',
    link: 'https://www.ifm.org/practitioners/rachael-fabbi-dc-dabci/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\nFox Valley Natural Medicine\n301 Hamilton Street\nGeneva , IL 60134 US\n331-248-0284',
    state: 'il',
  },
  {
    name: 'RAJKA MILANOVIC GALBRAITH, MD',
    link: 'https://www.ifm.org/practitioners/rajka-milanovic-galbraith-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_9560ecLR-400x400.jpg',
    contactText:
      'Address\n1S443 Summit Ave\nSuite 203\nOakbrook Terrace, IL 60181 US\n331-472-7422',
    state: 'il',
  },
  {
    name: 'SARAH J ZIELSDORF, MD',
    link: 'https://www.ifm.org/practitioners/sarah-j-zielsdorf-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Zielsdorf-SH-400x400.png',
    contactText:
      'Address\n480 E Roosevelt Rd, Suite 105\nWest Chicago , IL 60185 US\n630-492-1965',
    state: 'il',
  },
  {
    name: 'TERESE BLACK',
    link: 'https://www.ifm.org/practitioners/terese-black-dc/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n4821 N. Damen Ave.\nChicago, IL 60625 US\n773.334.9355',
    state: 'il',
  },
  {
    name: 'TRENT G ORFANOS, MD',
    link: 'https://www.ifm.org/practitioners/trent-g-orfanos-md-facc-abihm-ifmcp-aboim/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1732 W Hubbard St\nSuite 2A\nChicago , IL 60622 US\n(773) 675-1400',
    state: 'il',
  },
  {
    name: 'TYLER SAUNDERS, D.O.',
    link: 'https://www.ifm.org/practitioners/tyler-saunders/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Tyler-Saunders19211-1-400x400.jpg',
    contactText:
      'Address\n5346 N. Clark Street\nChicago, IL 60640 US\n7732938880',
    state: 'il',
  },
  {
    name: 'VANESSA L WILLETTS, DC',
    link: 'https://www.ifm.org/practitioners/vanessa-l-willetts-dc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Willetts_profile_Photo_.25-400x400.png',
    contactText:
      'Address\n1525 E 55th St.\nSuite 300-C\nChicago, IL 60615 US\n(773) 942-2266',
    state: 'il',
  },
  {
    name: 'VICTORIA NEE, MD',
    link: 'https://www.ifm.org/practitioners/victoria-nee-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n111 N Wabash Ave\nSuite 1202\nChicago, IL 60602 US\n(773) 407-5876',
    state: 'il',
  },
  {
    name: 'AMANDA G PATTERSON',
    link: 'https://www.ifm.org/practitioners/amanda-g-patterson/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Compressed-Copy-400x400.jpg',
    contactText:
      'Address\n11008 Darmstadt Rd\nEvansville, IN 47710 US\n8125989888',
    state: 'in',
  },
  {
    name: 'AMY CARTER',
    link: 'https://www.ifm.org/practitioners/amy-carter/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1202 Barbara Drive\nNew Castle, IN 47362 US\n765-599-3400',
    state: 'in',
  },
  {
    name: 'AMY NICLEY, NP',
    link: 'https://www.ifm.org/practitioners/amy-nicley-nurse-practitioner/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Amy-5-400x400.jpeg',
    contactText:
      'Address\n1329 West 96th Street\nIndianapolis, IN 46260 US\n317-660-0888',
    state: 'in',
  },
  {
    name: 'ANGELA HENRIKSEN, MD',
    link: 'https://www.ifm.org/practitioners/angela-henriksen-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/website-photo-400x400.jpeg',
    contactText:
      'Address\n12430 Clark Street\nCarmel , IN 46032 US\n317-938-4559',
    state: 'in',
  },
  {
    name: 'BETH BARTLETT, DC',
    link: 'https://www.ifm.org/practitioners/beth-bartlett-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_0836-400x400.jpg',
    contactText:
      'Primary Address\n953 Monument Drive\nLebanon, IN 46052 US\n630-699-5714',
    state: 'in',
  },
  {
    name: 'BRANDY MANETTA',
    link: 'https://www.ifm.org/practitioners/brandon-manetta/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/35835_1534769095654_2033782_n-400x400.jpg',
    contactText:
      'Address\n9333 N. Meridian St\n110\nIndianapolis, IN 46260 US\n317-669-0820',
    state: 'in',
  },
  {
    name: 'BRIANA ASPY',
    link: 'https://www.ifm.org/practitioners/briana-aspy-pa/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1234 East Dupont Rd Suite 3\nFort Wayne, IN 46845 US\n(260) 425-5970',
    state: 'in',
  },
  {
    name: 'DALLAS E PEAK, MD',
    link: 'https://www.ifm.org/practitioners/dallas-e-peak/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_5163-400x400.jpg',
    contactText:
      'Address\n3209 Smith Valley Road\nSuite 111\nGreenwood, IN 46142 US\nsee website',
    state: 'in',
  },
  {
    name: 'DEANNA SIPE, NP',
    link: 'https://www.ifm.org/practitioners/deanna-sipe-np/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/professional-picture-1-400x400.jpg',
    contactText:
      'Address\n1234 E. Dupont Rd\nSte 3\nFort Wayne, IN 46845 US\n2604255970',
    state: 'in',
  },
  {
    name: 'ELLEN ANTOINE, DO',
    link: 'https://www.ifm.org/practitioners/ellen-antoine-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_2179-2-400x400.jpg',
    contactText:
      'Address\n40 N. Rangeline Rd\nCarmel, IN 46032 US\n(317) 989-8463',
    state: 'in',
  },
  {
    name: 'ERICA LEAZENBY, MD',
    link: 'https://www.ifm.org/practitioners/erica-leazenby-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/EricaLeazenbyMD-Photo-400x400.jpg',
    contactText:
      'Address\n160 West Carmel Drive Suite 245\nCarmel, IN 46032 US\n(317) 975-1011',
    state: 'in',
  },
  {
    name: 'MEREDITH JANE POTRZEBOWSKI, DO',
    link: 'https://www.ifm.org/practitioners/meredith-jane-potrzebowski-do/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n11846 Lakeside Drive\nFishers, IN 46038 US\n317957-9510',
    state: 'in',
  },
  {
    name: 'PAMELA K PEAK, MD',
    link: 'https://www.ifm.org/practitioners/pamela-k-peak-md-facp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Pam-293KB-Photo-400x400.jpg',
    contactText:
      'Address\n3209 W. Smith Valley Road\nSTE 111\nGreenwood, IN 46142 US\nwww.icfmed.com',
    state: 'in',
  },
  {
    name: 'PETER ANTHONY JAKACKI, DC',
    link: 'https://www.ifm.org/practitioners/peter-anthony-jakacki-d-c-m-d/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n2512 E Dupont Rd\nSuite 210\nFort Wayne, IN 46825 US\n(260) 458-3731',
    state: 'in',
  },
  {
    name: 'ROSE WENRICH, MD',
    link: 'https://www.ifm.org/practitioners/rose-wenrich-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n8 Kissinger Drive\nWabash, IN 46992 US\n260-563-7421',
    state: 'in',
  },
  {
    name: 'SARAH WEAVER',
    link: 'https://www.ifm.org/practitioners/sarah-weaver/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/313992405_2694598984006620_2331927723134054340_n-400x400.jpg',
    contactText:
      'Primary Address\n6121 Stoney Creek Dr\nFort Wayne, IN 46825 US\n(260)255-4441',
    state: 'in',
  },
  {
    name: 'SCOTT ANTOINE, DO',
    link: 'https://www.ifm.org/practitioners/scott-antoine-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_2179-400x400.jpg',
    contactText:
      'Address\n40 North Rangeline Road\nCarmel, IN 46032 US\n(317) 989-8463',
    state: 'in',
  },
  {
    name: 'SUSAN BETH JULIAN, NP',
    link: 'https://www.ifm.org/practitioners/susan-beth-julian-np/',
    avatar: 'https://www.ifm.org/wp-content/uploads/jhcprofile-400x400.jpg',
    contactText:
      'Address\n100 W. Main St.\nPO Box 257\nCambridge City , IN 47327 US\n(765) 530-8008',
    state: 'in',
  },
  {
    name: 'TREVOR C MILLER, DC, IFMCP, L.AC., CFMP',
    link: 'https://www.ifm.org/practitioners/trevor-c-miller-d-c/',
    avatar: 'https://www.ifm.org/wp-content/uploads/headshot-15-400x400.jpg',
    contactText:
      'Address\n209 South State St.\nNorth Vernon, IN 47265 US\n8123461757',
    state: 'in',
  },
  {
    name: 'VARSHA NAGARSENKER, MD',
    link: 'https://www.ifm.org/practitioners/varsha-nagarsenker/',
    avatar: 'https://www.ifm.org/wp-content/uploads/work-photo-1-400x400.jpg',
    contactText:
      'Address\n740 W. Green Meadows Drive\nSuite 105\nGreenfield, IN 46140 US\n3173187777',
    state: 'in',
  },
  {
    name: 'ANGELA M RUPP-KATZER',
    link: 'https://www.ifm.org/practitioners/angela-m-katzer-aprn-c/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_6248-400x400.jpg',
    contactText:
      'Address\n8756 W 151st Street\nOverland Park, KS 66221 US\n9133801903',
    state: 'ks',
  },
  {
    name: 'ARIC D COX, DC',
    link: 'https://www.ifm.org/practitioners/aric-cox/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/headshot-white-cinderblock-small-400x400.jpg',
    contactText:
      'Address\n8441 E 32nd St N\nSuite 220\nWichita, KS 67226 US\n316.833.9737',
    state: 'ks',
  },
  {
    name: 'BRIAN LUM, DC',
    link: 'https://www.ifm.org/practitioners/brian-lum-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Dr.-Brian-Lum--400x400.png',
    contactText:
      'Primary Address\n18122 W 119th St.\nOlathe, KS 66061 US\n913-728-5291',
    state: 'ks',
  },
  {
    name: 'CASEY TRAMP, MD',
    link: 'https://www.ifm.org/practitioners/casey-tramp-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/ED4C7639-49C3-4B58-BE29-7771CD049311-400x400.jpeg',
    contactText:
      'Address\n8756 151st St\nOverland Park, KS 66221 US\n(913) 380-1903',
    state: 'ks',
  },
  {
    name: 'CHRISTINE LEWIS, PHARMD',
    link: 'https://www.ifm.org/practitioners/christine-lewis-c/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Christine-Lewis-headshot-smaller-400x400.jpg',
    contactText:
      'Primary Address\n11750 W 135th St\nSte 11\nOverland Park, KS 66221 US\n623-349-7899',
    state: 'ks',
  },
  {
    name: 'DAVID R JOWDY, DC',
    link: 'https://www.ifm.org/practitioners/david-r-jowdy/',
    avatar: 'https://www.ifm.org/wp-content/uploads/K56A2940edit-1-400x400.jpg',
    contactText:
      'Address\n2307 N. Rock Rd.\nSuite 500\nDerby, KS 67037 US\n316-867-0874',
    state: 'ks',
  },
  {
    name: 'EMILY DAY',
    link: 'https://www.ifm.org/practitioners/emily-day-aprn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/EmilyDay-400x400.jpg',
    contactText:
      'Address\n7301 Mission Road Suite 244F\nPrairie Village, KS 66208 US\n913-228-3191',
    state: 'ks',
  },
  {
    name: 'GEORGIA JEAN NAB, DC',
    link: 'https://www.ifm.org/practitioners/georgia-jean-nab-dc-ms/',
    avatar: 'https://www.ifm.org/wp-content/uploads/GJN-book-400x400.jpg',
    contactText: 'Address\n7410 Switzer\nShawnee, KS 66203 US\n913-962-7408',
    state: 'ks',
  },
  {
    name: 'JENNIFER JACKSON, MD',
    link: 'https://www.ifm.org/practitioners/jennifer-jackson-md-facp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2019-02-16-07.26.38-400x400.jpg',
    contactText:
      'Address\n1010 North Kansas\nWichita, KS 67214 US\n316-290-9766',
    state: 'ks',
  },
  {
    name: 'JESSICA M JELLISON, MD',
    link: 'https://www.ifm.org/practitioners/jessica-m-jellison-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Headshot-3-photo-shop-copy-400x400.jpg',
    contactText:
      'Address\n8660 W 96th St\nOverland Park, KS 66212 US\n913-568-0608',
    state: 'ks',
  },
  {
    name: 'JONATHAN STREIT, DC',
    link: 'https://www.ifm.org/practitioners/jonathan-levi-streit/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Jonathan-Streit-400x400.png',
    contactText:
      'Address\n2307 N. Rock Rd\nSuite 500\nDerby, KS 67037 US\n316-867-0874',
    state: 'ks',
  },
  {
    name: 'SARAH J CLAWSON',
    link: 'https://www.ifm.org/practitioners/sarah-clawson/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/WHHC-Flyer-CLose-Up-Head-400x400.png',
    contactText:
      'Address\n7570 W 21st St N\nBldg 1026 Ste C113\nWichita, KS 67205 US\n316-201-6774',
    state: 'ks',
  },
  {
    name: 'TANNER WILSON, DC',
    link: 'https://www.ifm.org/practitioners/tanner-wilson/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/04E7BB57-2115-4F6B-99BF-D85B2BFC6291-400x400.jpeg',
    contactText: 'Address\n414 W 1st Ave\nGarnett , KS 66032 US\n785 448 8796',
    state: 'ks',
  },
  {
    name: 'TONI FORSYTH',
    link: 'https://www.ifm.org/practitioners/toni-forsyth-np/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/20190815_154052-400x400.jpg',
    contactText: '913-399-3039',
    state: 'ks',
  },
  {
    name: 'ANASTASIA JANDES, MD',
    link: 'https://www.ifm.org/practitioners/anastasia-jandes-md-pharmd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/professional_picture-JandesAnstasia-1000x1000-WO-white-circle-400x400.png',
    contactText: 'Address\nPO Box 54448\nLexington, KY 40555 US\n6066180125',
    state: 'ky',
  },
  {
    name: 'JEANNINE PARIKH, M.D.',
    link: 'https://www.ifm.org/practitioners/jeannine-parikh/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n844 Central Avenue\nAshland, KY 41101 US\n6063936193',
    state: 'ky',
  },
  {
    name: 'ROBIN R MEEK, MD',
    link: 'https://www.ifm.org/practitioners/robin-r-meek-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/DrMeek-8-400x400.jpg',
    contactText:
      'Primary Address\n841 Corporate Drive\nSuite 204\nLexington, KY 40503 US\n8593003007',
    state: 'ky',
  },
  {
    name: 'STACEY B FAZENBAKER, MD',
    link: 'https://www.ifm.org/practitioners/stacey-b-fazenbaker-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Staceypic2-400x400.jpeg',
    contactText:
      'Address\n1921 Leitchfield Road\nOwensboro, KY 42303 US\n270 478 5334',
    state: 'ky',
  },
  {
    name: 'CANDICE LEDUFF KNIGHT, MD',
    link: 'https://www.ifm.org/practitioners/candice-leduff-knight-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n2033 N. Hwy 190\nSuite 16\nCovington, LA 70433 US\n(985) 867-5516',
    state: 'la',
  },
  {
    name: 'ELIZABETH MARIE BUCHERT, MD',
    link: 'https://www.ifm.org/practitioners/elizabeth-marie-buchert-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_7228-400x400.jpg',
    contactText:
      "Address\nWoman's Hospital - Physician's Office Building\n500 Rue de la Vie, Suite 401\nBaton Rouge, LA 70817 US\n(225) 250-1025",
    state: 'la',
  },
  {
    name: 'JENNIFER LAWS, FNP-C',
    link: 'https://www.ifm.org/practitioners/jennifer-hooper-laws-fnp-c/',
    avatar: 'https://www.ifm.org/wp-content/uploads/6H1A9220a-400x400.jpg',
    contactText:
      'Address\n10562 S. Glenstone Place\nBaton Rouge, LA 70810 US\n2257677433',
    state: 'la',
  },
  {
    name: 'RENEE D BEYER-BOUDREAUX, FNP',
    link: 'https://www.ifm.org/practitioners/renee-d-beyer-boudreaux-fnp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Renee-pic-400x400.jpg',
    contactText:
      "Address\nWoman's Hospital - Physician's Office Building\n500 Rue de la Vie Suite 401\nBaton Rouge, LA 70817 US\n(225) 250-1025",
    state: 'la',
  },
  {
    name: 'STEPHANIE F CAVE, MD',
    link: 'https://www.ifm.org/practitioners/stephanie-cave-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n10562 S Glenstone Pl\nBaton Rouge, LA 70810 US\n(225) 767-7433',
    state: 'la',
  },
  {
    name: 'AMANDA TURTON HUFF, MS',
    link: 'https://www.ifm.org/practitioners/amanda-turton-huff/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Amanda-Turton-400x400.jpg',
    contactText:
      'Address\n234 Littleton Rd\nSuite 1B\nWESTFORD, MA 01886 US\n978 435 0221',
    state: 'ma',
  },
  {
    name: 'BERNICE WONG',
    link: 'https://www.ifm.org/practitioners/bernice-wong/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n181 Wells Ave\nNewton Center, MA 02459 US\n6179346400',
    state: 'ma',
  },
  {
    name: 'CHRISTOPHER R KEROACK, MD',
    link: 'https://www.ifm.org/practitioners/christopher-r-keroack-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/HD-Keroack-2-400x400.jpg',
    contactText:
      'Address\n2 Medical Center Drive\nSuite 202\nSpringfield, MA 01107 US\n(413) 205-1200',
    state: 'ma',
  },
  {
    name: 'EDWARD LEVITAN, MD',
    link: 'https://www.ifm.org/practitioners/edward-levitan-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Edpicture-400x400.jpg',
    contactText:
      'Address\n181 Wells Avenue\nSuite 202\nNewton, MA 02459 US\n(617) 934-6400',
    state: 'ma',
  },
  {
    name: 'ELIZABETH BOHAM, MD, MS, RD',
    link: 'https://www.ifm.org/practitioners/elizabeth-boham-md-ms-rd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/0D5_4006Retouch-copy-400x400.jpg',
    contactText:
      'Address\n55 Pittsfield Rd Suite 9\nLenox Commons\nLenox, MA 01240 US\n(413) 637-9991',
    state: 'ma',
  },
  {
    name: 'ERICH GOETZEL, MD, MAC, LAC, IFMCP',
    link: 'https://www.ifm.org/practitioners/erich-goetzel-md-mac-lac/',
    avatar: 'https://www.ifm.org/wp-content/uploads/goetzel-flowes-400x400.jpg',
    contactText:
      'Address\n86 Baker Ave Ext\nSuite 100\nConcord, MA 01742 US\n7818004138',
    state: 'ma',
  },
  {
    name: 'JANICE J PEGELS, MD',
    link: 'https://www.ifm.org/practitioners/janice-j-pegels-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n395 Pleasant St.\nNorthampton, MA 01060 US\n(413) 584-7787',
    state: 'ma',
  },
  {
    name: 'JEFFREY B KREHER, MD',
    link: 'https://www.ifm.org/practitioners/jeffrey-b-kreher/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/kreher_headshot_concord-400x400.png',
    contactText: '',
    state: 'ma',
  },
  {
    name: 'JESSICA MILLS JIMISON',
    link: 'https://www.ifm.org/practitioners/jessica-jimison-fnp-c/',
    avatar: 'https://www.ifm.org/wp-content/uploads/JMJ-Headshot-400x400.jpg',
    contactText:
      'Address\nOnline Only at this time\nAmherst, MA 01002 US\n4132009897',
    state: 'ma',
  },
  {
    name: 'JULIA REZNIK, MD',
    link: 'https://www.ifm.org/practitioners/julia-reznik-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/photo-400x400.jpg',
    contactText:
      'Primary Address\n144 North Road, Suite 2250\nSudbury, MA 01776 US\n978-224-8288',
    state: 'ma',
  },
  {
    name: 'KRISTIN BOYT, PA-C',
    link: 'https://www.ifm.org/practitioners/kristin-boyt-pa-c/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n55 Pittsfield Rd\nSuite 9\nLenox, MA 01240 US\n(413) 637-9991',
    state: 'ma',
  },
  {
    name: 'LINDA COFFEY',
    link: 'https://www.ifm.org/practitioners/linda-coffey/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n23 Everett Ave\nBelchertown, MA 01007 US\n(413) 544-4409',
    state: 'ma',
  },
  {
    name: 'MARK A HYMAN, MD',
    link: 'https://www.ifm.org/practitioners/mark-hyman-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Mark-Headshot-2022-400x400.jpg',
    contactText:
      'Address\n55 Pittsfield Road\nSuite 9\nLenox, MA 01240 US\n(413) 637-9991',
    state: 'ma',
  },
  {
    name: 'SAILAJA KANDRA REDDY, MD',
    link: 'https://www.ifm.org/practitioners/sailaja-reddy/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Compressed-square-photo-3-1-400x400.jpg',
    contactText:
      'Address\n463 Worcester Road, Suite 103\nFramingham, MA 01701 US\n5082390000',
    state: 'ma',
  },
  {
    name: 'UGNE ALEKNAITE, NP',
    link: 'https://www.ifm.org/practitioners/ugne-aleknaite-msn/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/ugne-aleknaite-nantucket-functional-medicine-400x400.jpg',
    contactText: 'Address\n6 West creek rd\nNantucket, MA 02554 US\n5088633337',
    state: 'ma',
  },
  {
    name: 'A. GAIL LEE, MD',
    link: 'https://www.ifm.org/practitioners/amelia-lee-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n401 Scott Dr\nSilver Spring , MD 20904 US\n301-384-3387',
    state: 'md',
  },
  {
    name: 'ANIL BAJNATH, MD',
    link: 'https://www.ifm.org/practitioners/anil-bajnath-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Ailto4-s-400x400.jpeg',
    contactText:
      'Primary Address\n2024 West Street\nSuite 202\nAnnapolis , MD 21401 US\n410.858.4086',
    state: 'md',
  },
  {
    name: 'ERIN ELIZABETH PROKOP',
    link: 'https://www.ifm.org/practitioners/erin-e-prokop-crnp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/ErinProkop-.90-400x400.jpg',
    contactText:
      'Address\n10751 Falls Road\nLutherville, MD 21093 US\n410-616-7970',
    state: 'md',
  },
  {
    name: 'JENNIFER GULARSON, PA-C',
    link: 'https://www.ifm.org/practitioners/jennifer-gularson-pa-c/',
    avatar: 'https://www.ifm.org/wp-content/uploads/profile-400x400.png',
    contactText:
      'Address\n3200 Tower Oaks Blvd\n430\nRockville, MD 20852 US\n301-231-5050',
    state: 'md',
  },
  {
    name: 'JENNIFER ROCCA-SEXTON, PA-C',
    link: 'https://www.ifm.org/practitioners/jennifer-rocca-sexton/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/DF8E306E-6E9A-431C-8D78-DCBF2C3E1FFB-400x400.jpeg',
    contactText:
      'Address\nTelehealth practice in Maryland and Florida\nAnnapolis, MD 21401 US\n6673095587',
    state: 'md',
  },
  {
    name: 'KAREN L. HERB',
    link: 'https://www.ifm.org/practitioners/karen-herb/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_5069-400x400.jpeg',
    contactText: '443-295-3076',
    state: 'md',
  },
  {
    name: 'KATERINA TSAPOS PARMELE, MD',
    link: 'https://www.ifm.org/practitioners/katerina-tsapos-parmele/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_1402-400x400.jpg',
    contactText: 'Address\nParke Dr.\nEdgewater, MD 21037 US\n410-279-9865',
    state: 'md',
  },
  {
    name: 'KATHERINE KADIN, DC',
    link: 'https://www.ifm.org/practitioners/katherine-kadin-dc-daccp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/1C976A75-DEEA-4840-BAC6-6DC6F385905C-400x400.jpeg',
    contactText:
      'Address\n6212 Montrose Road\nRockville, MD 20852 US\n240-430-1004',
    state: 'md',
  },
  {
    name: 'KATIE MORRA',
    link: 'https://www.ifm.org/practitioners/katie-elizabeth-morra-ms-rd-cdn/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Untitled-design-22-400x400.png',
    contactText:
      'Address\n60 West St #525\nAnnapolis, MD 21401 US\n(410) 346-5658',
    state: 'md',
  },
  {
    name: 'LEONARD J LEO, DO',
    link: 'https://www.ifm.org/practitioners/leonard-leo-do-dc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.-Leo-J-Leonard-400x400.png',
    contactText:
      'Address\n6321 Executive Boulevard\nRockville, MD 20852 US\n(301) 770-6650',
    state: 'md',
  },
  {
    name: 'LISA LABORWIT, MD',
    link: 'https://www.ifm.org/practitioners/lisa-laborwit/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n6020-K Meadowridge Center Drive\nElkridge, MD 21075 US\n410-504-3656',
    state: 'md',
  },
  {
    name: 'MARY JO FISHBURN',
    link: 'https://www.ifm.org/practitioners/mary-jo-fishburn-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Mary-Jo-Fishburn-37-AB_4-21-400x400.jpg',
    contactText:
      'Address\n7801 York Rd\nSuite 200\nTowson, MD 21204 US\n(410) 821-1785',
    state: 'md',
  },
  {
    name: 'MELISSA TROTMAN',
    link: 'https://www.ifm.org/practitioners/melissa-trotman-crnp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/20840553_10100169602323038_676558869_n-400x400.jpg',
    contactText:
      'Primary Address\n645 Ridgely Ave\nAnnapolis, MD 21401 US\n410-994-7575',
    state: 'md',
  },
  {
    name: 'ROBERT HEDAYA, MD',
    link: 'https://www.ifm.org/practitioners/robert-hedaya-md-dlfapa/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n11300 Rockville Pike\nSuite 914\nRockville, MD 20852 US\n(240) 338-2000',
    state: 'md',
  },
  {
    name: 'ROBERT J REIER, DC',
    link: 'https://www.ifm.org/practitioners/robert-j-reier-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/drrobert-400x400.jpg',
    contactText:
      'Address\n205 East Broadway\nBel Air, MD 21014 US\n410-893-2600',
    state: 'md',
  },
  {
    name: 'ROSEMARIE FILART, MD',
    link: 'https://www.ifm.org/practitioners/rosemarie-filart/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n20300 Great Seneca Parkway STE 215\nGermantown, MD 20877 US\n571-488-9700',
    state: 'md',
  },
  {
    name: 'SKYE STEWART, ND',
    link: 'https://www.ifm.org/practitioners/skye-stewart/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Skye-8-640x480-cropped-400x400.jpg',
    contactText:
      'Address\n3168 Braverton St Suite 330\nEdgewater, MD 21037 US\n410-956-3090',
    state: 'md',
  },
  {
    name: 'STEFANIE DEFIGLIA, CRNP',
    link: 'https://www.ifm.org/practitioners/stefanie-defiglia/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Thumbnail-headshot-in-red-400x400.jpg',
    contactText:
      'Address\n10 South Hanson St\nSuite 4\nEaston, MD 21601 US\n4434963050',
    state: 'md',
  },
  {
    name: 'WENDY S LOFTIS',
    link: 'https://www.ifm.org/practitioners/wendy-loftis/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\nAnnapolis Area\nAnnapolis, MD 21401 US\n443-831-3119',
    state: 'md',
  },
  {
    name: 'BETHANY M. HAYS, MD',
    link: 'https://www.ifm.org/practitioners/bethany-m-hays-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Address\nPO Box 215\nCumberland, ME 04021 US\n(207) 781-4488',
    state: 'me',
  },
  {
    name: 'CARRIE LEVINE',
    link: 'https://www.ifm.org/practitioners/carrie-levine-cnm/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/bioportrait2017-400x400.jpg',
    contactText:
      'Primary Address\n608 River Road\nRiver Road\nNewcastle, ME 04553-4011 US\n2075637000',
    state: 'me',
  },
  {
    name: 'EARL R FREEMAN, DO',
    link: 'https://www.ifm.org/practitioners/earl-freeman/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n20 Woodland Ridge\nAlfred, ME 04002 US\n207-967-3726',
    state: 'me',
  },
  {
    name: 'MARCELLE PICK, OB-GYN GYN NP',
    link: 'https://www.ifm.org/practitioners/marcelle-pick-ob-gyn-gyn-np/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Address\n3 Marina Rd\nYarmouth, ME 04096 US\n(207) 846-6163',
    state: 'me',
  },
  {
    name: 'MAYA RAMIREZ, MD',
    link: 'https://www.ifm.org/practitioners/maya-ramirez-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Work-photo-400x400.jpg',
    contactText:
      'Address\n277 Congress Street\nSuite 1A\nPortland, ME 04101 US\n(207) 200-8185',
    state: 'me',
  },
  {
    name: 'MENEAH R HAWORTH',
    link: 'https://www.ifm.org/practitioners/meneah-r-haworth-aprn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_0991-400x400.jpg',
    contactText:
      'Address\n25 Plaza Dr. Suite 10\nScarborough, ME 04074 US\n2072198445',
    state: 'me',
  },
  {
    name: 'MICHAEL GILMORE, DO',
    link: 'https://www.ifm.org/practitioners/michael-gilmore-do/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n33 Penn Plaza\nSUITE A\nBANGOR, ME 04401 US\n2079410080',
    state: 'me',
  },
  {
    name: 'PERSIS HOPE, NP',
    link: 'https://www.ifm.org/practitioners/persis-hope-np/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n149 North Street\nWaterville\nMaine, ME 04901 US\n2078615101',
    state: 'me',
  },
  {
    name: 'ROBERT SHOLL, MD',
    link: 'https://www.ifm.org/practitioners/robert-sholl-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: '(207) 289-1060',
    state: 'me',
  },
  {
    name: 'AARTI BATAVIA, MS',
    link: 'https://www.ifm.org/practitioners/aarti-batavia-ms-rdn-clt-ifmcp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/aart_batavia_pic-400x400.jpg',
    contactText:
      'Primary Address\n2345 South Huron Parkway\nSuite 425\nAnn Arbor, MI 48104 US\n5172906041',
    state: 'mi',
  },
  {
    name: 'AARTI SOORYA, MD',
    link: 'https://www.ifm.org/practitioners/aarti-soorya/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/29144E51-6CED-4800-B1E1-3D1B67CC0ED8-400x400.jpeg',
    contactText:
      'Address\n130 E Long Lake Rd\nSuite 202\nBloomfield Hills, MI 48304 US\n248-403-8388 ext. 2',
    state: 'mi',
  },
  {
    name: 'ADRIAN DEN BOER, DC',
    link: 'https://www.ifm.org/practitioners/adrian-den-boer-dc-nd/',
    avatar: 'https://www.ifm.org/wp-content/uploads/denboer_large-400x400.png',
    contactText:
      'Address\n2851 Michigan St NE\nSuite 101\nGrand Rapids, MI 49506 US\n(616) 940-7027',
    state: 'mi',
  },
  {
    name: 'ANDREY LUTSKOVSKY, DO',
    link: 'https://www.ifm.org/practitioners/andrey-lutskovsky-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_2125-Copy-400x400.jpg',
    contactText:
      'Address\n31000 Telegraph Rd\nSuite 140\nBingham Farms, MI 48025 US\n(248) 876-4242',
    state: 'mi',
  },
  {
    name: 'BETHANIE THOMPSON, MD',
    link: 'https://www.ifm.org/practitioners/bethanie-thompson-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/200219-4-1-400x400.jpeg',
    contactText:
      'Address\n1003 Lake Dr SE\nGrand Rapids, MI 49506 US\n616-498-6050',
    state: 'mi',
  },
  {
    name: 'CARIN NIELSEN, MD',
    link: 'https://www.ifm.org/practitioners/carin-nielsen-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\nVirtual Healthcare for Michigan Residents\nPetoskey, MI 49770 US\n(231) 638-5585',
    state: 'mi',
  },
  {
    name: 'CARRIE L PALMER',
    link: 'https://www.ifm.org/practitioners/carrie-l-palmer-pa-c/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_5647-2-400x400.jpg',
    contactText:
      'Address\n6934 West Q Ave\nKalamazoo, MI 49009-____ US\n(269) 224-1659',
    state: 'mi',
  },
  {
    name: 'DAWN JOHNSON, DO',
    link: 'https://www.ifm.org/practitioners/dawn-johnson-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Capture3-400x400.jpg',
    contactText:
      'Address\nParsley Health (Virtual Visits Only)\nVirtual, MI 48604 US\n833-447-2775',
    state: 'mi',
  },
  {
    name: 'EMILY CORDES, DO',
    link: 'https://www.ifm.org/practitioners/emily-cordes-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Image-1-400x400.png',
    contactText: 'Address\n1003 Lake Drive\nGrand Rapids, MI 49506 US',
    state: 'mi',
  },
  {
    name: 'ERICA ARMSTRONG, MD',
    link: 'https://www.ifm.org/practitioners/erica-armstrong/',
    avatar: 'https://www.ifm.org/wp-content/uploads/doctorprofile-400x400.jpg',
    contactText: 'Address\n1003 Lake Dr SE\nGrand Rapids, MI 49506 US',
    state: 'mi',
  },
  {
    name: 'ERIN HENDRIKS, MD',
    link: 'https://www.ifm.org/practitioners/erin-hendriks/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Untitled-2-400x400.jpg',
    contactText:
      'Primary Address\n702 West 11 Mile Road\nRoyal Oak , MI 48067 US\n248-284-6777',
    state: 'mi',
  },
  {
    name: 'HEATHER RATLIFF',
    link: 'https://www.ifm.org/practitioners/heather-g-ratliff-rnbsn-ms-mpa/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Heather-Ratliff-small-Photo-TWRN-1290-400x400.jpg',
    contactText: '269 303 0847',
    state: 'mi',
  },
  {
    name: 'JILL KALCICH, MD',
    link: 'https://www.ifm.org/practitioners/jill-kalcich-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Address\n300 Hecla St.\nLaurium, MI 49913 US\n(906) 337-9355',
    state: 'mi',
  },
  {
    name: 'LORI ANN ZETTELL, MD',
    link: 'https://www.ifm.org/practitioners/lori-ann-zettell-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n105 W. Michigan Ave Suite B\nMarshall, MI 49068 US\n269.268.0410',
    state: 'mi',
  },
  {
    name: 'MARY ELIZABETH MILLER-WILSON',
    link: 'https://www.ifm.org/practitioners/mary-e-miller-wilson-anp-bc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/CROP_1884-72DPI-1-400x400.jpg',
    contactText:
      'Address\n543 North Main Street, Suite 122\nRochester, MI 48307 US\n248-315-2286',
    state: 'mi',
  },
  {
    name: 'MARZENA SLATER, MD',
    link: 'https://www.ifm.org/practitioners/marzena-slater/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/8315442A-E076-4007-BD34-1204DC616D07-400x400.jpeg',
    contactText:
      'Address\n1003 lake Dr SE\nGrand Rapids, MI 49506 US\n6164986050',
    state: 'mi',
  },
  {
    name: 'MONA S OTTUM, MS',
    link: 'https://www.ifm.org/practitioners/mona-s-ottum-ms-rdn/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Mona.Ottum_.RD_.CLT_.Headshot.10-400x400.jpg',
    contactText:
      'Address\n398 Green Hills Drive\nSaline, MI 48176-8718 US\n734-547-6403',
    state: 'mi',
  },
  {
    name: 'OMEED SIZAR, DO MPH',
    link: 'https://www.ifm.org/practitioners/omeed-sizar/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/IMG_1645-2-copy-2-400x400.jpg',
    contactText: '',
    state: 'mi',
  },
  {
    name: 'RAMONA WALLACE, DO',
    link: 'https://www.ifm.org/practitioners/ramona-wallace-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG-0816-1-400x400.jpg',
    contactText:
      'Primary Address\n1000 Oakland Dr\nKalamazoo, MI 49008 US\n269 965-8866',
    state: 'mi',
  },
  {
    name: 'ROBIN MENEGUZZO',
    link: 'https://www.ifm.org/practitioners/robin-meneguzzo-fnp-c/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/thumb_robin-outdoor_1024-400x400.jpg',
    contactText:
      'Address\n205 Osceola St.\nLaurium, MI 49913 US\n(906) 337-6560',
    state: 'mi',
  },
  {
    name: 'RYAN H. BARISH, MD',
    link: 'https://www.ifm.org/practitioners/ryan-barish-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_2233-400x400.jpg',
    contactText:
      'Address\n110 E 2nd Street\nRoyal Oak, MI 48067 US\n(833) 246-4347',
    state: 'mi',
  },
  {
    name: 'SARAH KIRSCH, DC',
    link: 'https://www.ifm.org/practitioners/sarah-n-kirsch-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/6D6A6076-400x400.jpg',
    contactText:
      'Address\n2770 Coolidge Highway\nBerkley, MI 48072 US\n248-951-2830',
    state: 'mi',
  },
  {
    name: 'TABATHA BARBER, DO',
    link: 'https://www.ifm.org/practitioners/tabatha-barber-duell/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_00131-400x400.jpg',
    contactText:
      'Address\n205 W Savidge St.\nSpring Lake, MI 49456 US\n616-502-1484',
    state: 'mi',
  },
  {
    name: 'ALLISON GOLNIK, MD',
    link: 'https://www.ifm.org/practitioners/allison-golnik-md-mph/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/29611F24-344B-4F76-8AA9-39B449E9C317_1_201_a-400x400.jpeg',
    contactText:
      'Address\n2535 Univ Ave SE\nMinneapolis, MN 55414 US\n612-672-2350',
    state: 'mn',
  },
  {
    name: 'APRIL MARIE LIND, MD',
    link: 'https://www.ifm.org/practitioners/april-marie-lind-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1409 Willow Street suite 501\nMinneapolis, MN 55403 US\n612-345-5029',
    state: 'mn',
  },
  {
    name: 'BATOOL DAWOOD',
    link: 'https://www.ifm.org/practitioners/batool-dawood/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/SocialMediaUse19-400x400.jpg',
    contactText: 'Address\n627 129th Ln NE\nBlaine, MN 55434 US\n7633168045',
    state: 'mn',
  },
  {
    name: 'BERNARDA ZENKER, MD',
    link: 'https://www.ifm.org/practitioners/bernarda-zenker-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n4660 Slater Road\nSuite #240\nEagan, MN 55122 US\n9524653883',
    state: 'mn',
  },
  {
    name: 'BRIANA MARIE RUEDA, MS',
    link: 'https://www.ifm.org/practitioners/briana-marie-rueda-ms-do/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n7212 Forestview Lane N\nMaple Grove, MN 55369 US\n763-515-4900',
    state: 'mn',
  },
  {
    name: 'CARLY ERICKSON, ND',
    link: 'https://www.ifm.org/practitioners/carly-erickson-nd-msm/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/61162E2E-1A22-4BB0-ADC1-B3117510746C-400x400.jpeg',
    contactText: '3202001477',
    state: 'mn',
  },
  {
    name: 'ERIN ERICKSON, DNP',
    link: 'https://www.ifm.org/practitioners/erin-erickson-dnp-mph-rn/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Erickson-Erin-cropped-headshot-400x400.jpg',
    contactText:
      'Address\n11269 Jefferson Hwy N\nChamplin, MN 55316 US\n612-863-3333',
    state: 'mn',
  },
  {
    name: 'JARA RICE, DNP',
    link: 'https://www.ifm.org/practitioners/jara-rice/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1021 Bandana Blvd E\nSuite 100\nSt Paul, MN 55108 US',
    state: 'mn',
  },
  {
    name: 'JULIE TEBBEN',
    link: 'https://www.ifm.org/practitioners/julie-tebben-cnp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Julie-headshot-sm-002-400x400.jpg',
    contactText:
      'Address\n1310 Highway 96 E\nSuite 204b\nWhite Bear Lake, MN 55110 US\n651-829-3949',
    state: 'mn',
  },
  {
    name: 'KARA PARKER, MD',
    link: 'https://www.ifm.org/practitioners/kara-parker-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\nIntegrative Health Clinic\n2810 Nicollet Ave S\nMinnespolis, MN 55408 US\n(612) 873-7987',
    state: 'mn',
  },
  {
    name: 'KATE ANN OTREMBA DNP',
    link: 'https://www.ifm.org/practitioners/kate-ann-otremba-dnp-adult-gerontolog/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/69788395-E2BB-4E24-A69E-08C02C158373-400x400.jpeg',
    contactText:
      'Address\n8675 Valley Creek Rd\nWoodbury, MN 55125 US\n651-241-3000',
    state: 'mn',
  },
  {
    name: 'KRISTI JANE HUGHES, ND',
    link: 'https://www.ifm.org/practitioners/kristi-hughes-nd/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n5225 Shoreline Drive\nMound, MN 55364 US\n3207624295',
    state: 'mn',
  },
  {
    name: 'LAURA SANDQUIST, DNP',
    link: 'https://www.ifm.org/practitioners/laura-sandquist-dnp-aprn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Headshot-2016-400x400.jpg',
    contactText:
      'Address\n8675 Valley Creek Road\nAllina Health Woodbury\nWoodbury, MN 55125 US\n651-241-3000',
    state: 'mn',
  },
  {
    name: 'LEE ANN ABERLE, ND',
    link: 'https://www.ifm.org/practitioners/lee-aberle-nd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.-Lee-Headshot-400x400.jpg',
    contactText:
      'Primary Address\n22 Wilson Ave NE\nSuite 205\nSt Cloud, MN 56304 US\n(320) 253-4112',
    state: 'mn',
  },
  {
    name: 'LYNELL HAGE, RN',
    link: 'https://www.ifm.org/practitioners/lynell-hage-rn/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n6901 W 84th St\nSte 260\nBloomington, MN 55438 US\n(612) 619-6612',
    state: 'mn',
  },
  {
    name: 'MICHELLE RAE LELWICA',
    link: 'https://www.ifm.org/practitioners/michelle-lelwica/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/michelle-headshot-snip-400x400.jpg',
    contactText:
      'Primary Address\n31108 Government Drive\nPequot Lakes , MN 56472 US\n218.568.5648',
    state: 'mn',
  },
  {
    name: 'NANCY L SUDAK, MD',
    link: 'https://www.ifm.org/practitioners/nancy-sudak-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/nancy-sudak-essentia-image-400x400.jpg',
    contactText:
      'Address\n420 East First St.\nMaildrop: 1S3C50\nDuluth, MN 55805 US\n(218) 786-2125',
    state: 'mn',
  },
  {
    name: 'RICHARD JOSEPH SINDA, DO',
    link: 'https://www.ifm.org/practitioners/richard-joseph-sinda-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/bektoaazovva6gridqd5-400x400.jpg',
    contactText:
      'Address\n7550 France Avenue South\nSuite 215\nEdina, MN 55435 US\n(952) 922-2345',
    state: 'mn',
  },
  {
    name: 'RICHARD MAYFIELD, DC, CCN, DACBN, IFMCP',
    link: 'https://www.ifm.org/practitioners/richard-mayfield-dc-ccn-dacbn/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n3601 Minnesota Drive\nSuite 160\nEdina, MN 55435 US\n(952) 885-0822',
    state: 'mn',
  },
  {
    name: 'RORY FAHERTY, DC',
    link: 'https://www.ifm.org/practitioners/rory-faherty-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_5175-400x400.jpg',
    contactText:
      'Primary Address\n9625 robin oak road\nHopkins, MN 55305 US\n(952) 777-5919',
    state: 'mn',
  },
  {
    name: 'SENIA TUOMINEN',
    link: 'https://www.ifm.org/practitioners/senia-tuominen/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1654 Grand Avenue\nSaint Paul, MN 55105 US\n651-792-5222',
    state: 'mn',
  },
  {
    name: 'TERESA PENA, MD',
    link: 'https://www.ifm.org/practitioners/teresa-pena-md-mba/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Headshot-400x400.jpeg',
    contactText:
      'Address\n1915 Hwy 36 (The Reserve)\nSuite 122\nRoseville , MN 55113 US\n651-564-4884',
    state: 'mn',
  },
  {
    name: 'THOMAS A SULT, MD',
    link: 'https://www.ifm.org/practitioners/thomas-a-sult-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n7900 Chapin Drive NE\nPO Box 607\nNew London, MN 56273 US\n(320) 347-1212',
    state: 'mn',
  },
  {
    name: 'TONYA SWEEZER, DO',
    link: 'https://www.ifm.org/practitioners/tonya-sweezer-nd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/B0516046-41BD-4CE4-83FC-2B96B9225D92-400x400.jpeg',
    contactText:
      'Address\n420 East 1st Street\nDuluth, MN 55805 US\n218-786-2125',
    state: 'mn',
  },
  {
    name: 'VALERIE CARLSON',
    link: 'https://www.ifm.org/practitioners/valerie-carlson-cnp/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n901 3rd St\nWaite Park, MN 56387 US\n3202178480',
    state: 'mn',
  },
  {
    name: 'ZEPHANIE COLE, D.C.',
    link: 'https://www.ifm.org/practitioners/zephanie-cole-d-c/',
    avatar: 'https://www.ifm.org/wp-content/uploads/colorheadshot-400x400.jpg',
    contactText:
      'Address\n204 Lake Avenue, Suite 203\nFairmont, MN 56031 US\n507-235-8485',
    state: 'mn',
  },
  {
    name: 'INEZ KELLEHER, MD',
    link: 'https://www.ifm.org/practitioners/inez-kelleher-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_0788-400x400.jpg',
    contactText: 'Address\n1340 Broad St\nGulfport, MS 39501 US\n2288675012',
    state: 'ms',
  },
  {
    name: 'ADRIANA MCGARITY, MD',
    link: 'https://www.ifm.org/practitioners/adriana-mcgarity/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n300 St James Street\nSuite 104\nColumbia, MO 65201 US\n5732220455',
    state: 'mo',
  },
  {
    name: 'AMY K DAVIS, MD',
    link: 'https://www.ifm.org/practitioners/amy-k-davis-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.-Davis-Head-Shot-400x400.jpg',
    contactText:
      'Address\n485 Wildwood Parkway\nSuite 2\nBallwin, MO 63011 US\n(636) 778-9158',
    state: 'mo',
  },
  {
    name: 'BRADLEY DYER, DO',
    link: 'https://www.ifm.org/practitioners/bradley-dyer-do/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n101 Armour Road\nSuite D\nNorth Kansas City, MO 64116 US\n8165824173',
    state: 'mo',
  },
  {
    name: 'CATHERINE HOHN, MD',
    link: 'https://www.ifm.org/practitioners/catherine-hohn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Headshot-16-400x400.jpg',
    contactText: 'Address\n9160 Clayton Road\nLadue, MO 63124 US\n314-801-8898',
    state: 'mo',
  },
  {
    name: 'JAIME REISE',
    link: 'https://www.ifm.org/practitioners/jaime-reise/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/E160B303-BC2D-4B1D-9B66-77A8EF68D77B-400x400.jpeg',
    contactText:
      'Address\n1034 S. Brentwood Blvd\nSuite 415 University Club\nSaint Louis, MO 63117 US\n(314) 293-8123',
    state: 'mo',
  },
  {
    name: 'JENNIFER OSGOOD',
    link: 'https://www.ifm.org/practitioners/jennifer-osgood/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/20211205_161326-400x400.jpg',
    contactText: '816-974-7131',
    state: 'mo',
  },
  {
    name: 'KENNETH STEVEN SHARLIN, MD',
    link: 'https://www.ifm.org/practitioners/kenneth-steven-sharlin-md-mph/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Pro-photo-KS-400x400.jpg',
    contactText:
      'Address\n5528 North Farmer Branch Road\nOzark, MO 65721 US\n(417) 485-4335',
    state: 'mo',
  },
  {
    name: 'MALAIKA WOODS, MD',
    link: 'https://www.ifm.org/practitioners/malaika-woods-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/marketingpic-red-shirt-smaller-file-400x400.jpeg',
    contactText:
      'Address\n300 Unity Circle North\nSuite A\nLees Summit, MO 64064 US\n8168885200',
    state: 'mo',
  },
  {
    name: 'NICK BODI, MS',
    link: 'https://www.ifm.org/practitioners/nick-bodi/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/IFM-Profile-Pic-400x400.jpg',
    contactText:
      'Address\n1034 S. Brentwood Blvd\nSuite 415\nSaint Louis, MO 63117 US\n3142938123',
    state: 'mo',
  },
  {
    name: 'SHAUN HOLDEN, MD',
    link: 'https://www.ifm.org/practitioners/shaun-holden/',
    avatar: 'https://www.ifm.org/wp-content/uploads/drh-400x400.jpg',
    contactText:
      'Address\n2820 E. Rock Haven Rd. Suite 100\nHARRISONVILLE, MO 64701 US\n816-380-3582',
    state: 'mo',
  },
  {
    name: 'SREE B NARRA, MD',
    link: 'https://www.ifm.org/practitioners/sree-b-narra-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/7652-0015R-400x400.jpg',
    contactText:
      'Address\n1610 Des Peres Road\nSuite 150\nst Louis, MO 63131 US\n3146790076',
    state: 'mo',
  },
  {
    name: 'T.J. WILLIAMS, DC',
    link: 'https://www.ifm.org/practitioners/t-j-williams/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/20171211-_DSF1174-TJ-copy-400x400.jpg',
    contactText:
      'Address\n1034 S. Brentwood Blvd.\nSuite 415\nSt. Louis, MO 63117 US\n314-293-8123',
    state: 'mo',
  },
  {
    name: 'THOMAS SCOTT JAMISON',
    link: 'https://www.ifm.org/practitioners/thomas-jamison/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_0168-1-1-400x400.jpg',
    contactText:
      'Address\n9160 Clayton Rd\nSt Louis , MO 63124 US\n314-801-8898',
    state: 'mo',
  },
  {
    name: 'THOMAS KUCIEJCZYK-KERNAN, MD',
    link: 'https://www.ifm.org/practitioners/thomas-kuciejczyk-kernan-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/thomaskkernan-photo-400x400.jpg',
    contactText:
      'Address\n3930 S. Broadway\nSt. Louis, MO 63118-4626 US\n314-898-1999',
    state: 'mo',
  },
  {
    name: 'VENKATA KADA, MD',
    link: 'https://www.ifm.org/practitioners/venkata-kada/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Photo-VGK-2-400x400.png',
    contactText:
      'Address\n6500 Hospital Drive\nHannibal, MO 63401 US\n573 629 3500',
    state: 'mo',
  },
  {
    name: 'ADRIANA MCGARITY, MD',
    link: 'https://www.ifm.org/practitioners/adriana-mcgarity/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n300 St James Street\nSuite 104\nColumbia, MO 65201 US\n5732220455',
    state: 'mo',
  },
  {
    name: 'AMY K DAVIS, MD',
    link: 'https://www.ifm.org/practitioners/amy-k-davis-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.-Davis-Head-Shot-400x400.jpg',
    contactText:
      'Address\n485 Wildwood Parkway\nSuite 2\nBallwin, MO 63011 US\n(636) 778-9158',
    state: 'mo',
  },
  {
    name: 'BRADLEY DYER, DO',
    link: 'https://www.ifm.org/practitioners/bradley-dyer-do/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n101 Armour Road\nSuite D\nNorth Kansas City, MO 64116 US\n8165824173',
    state: 'mo',
  },
  {
    name: 'CATHERINE HOHN, MD',
    link: 'https://www.ifm.org/practitioners/catherine-hohn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Headshot-16-400x400.jpg',
    contactText: 'Address\n9160 Clayton Road\nLadue, MO 63124 US\n314-801-8898',
    state: 'mo',
  },
  {
    name: 'JAIME REISE',
    link: 'https://www.ifm.org/practitioners/jaime-reise/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/E160B303-BC2D-4B1D-9B66-77A8EF68D77B-400x400.jpeg',
    contactText:
      'Address\n1034 S. Brentwood Blvd\nSuite 415 University Club\nSaint Louis, MO 63117 US\n(314) 293-8123',
    state: 'mo',
  },
  {
    name: 'JENNIFER OSGOOD',
    link: 'https://www.ifm.org/practitioners/jennifer-osgood/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/20211205_161326-400x400.jpg',
    contactText: '816-974-7131',
    state: 'mo',
  },
  {
    name: 'KENNETH STEVEN SHARLIN, MD',
    link: 'https://www.ifm.org/practitioners/kenneth-steven-sharlin-md-mph/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Pro-photo-KS-400x400.jpg',
    contactText:
      'Address\n5528 North Farmer Branch Road\nOzark, MO 65721 US\n(417) 485-4335',
    state: 'mo',
  },
  {
    name: 'MALAIKA WOODS, MD',
    link: 'https://www.ifm.org/practitioners/malaika-woods-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/marketingpic-red-shirt-smaller-file-400x400.jpeg',
    contactText:
      'Address\n300 Unity Circle North\nSuite A\nLees Summit, MO 64064 US\n8168885200',
    state: 'mo',
  },
  {
    name: 'NICK BODI, MS',
    link: 'https://www.ifm.org/practitioners/nick-bodi/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/IFM-Profile-Pic-400x400.jpg',
    contactText:
      'Address\n1034 S. Brentwood Blvd\nSuite 415\nSaint Louis, MO 63117 US\n3142938123',
    state: 'mo',
  },
  {
    name: 'SHAUN HOLDEN, MD',
    link: 'https://www.ifm.org/practitioners/shaun-holden/',
    avatar: 'https://www.ifm.org/wp-content/uploads/drh-400x400.jpg',
    contactText:
      'Address\n2820 E. Rock Haven Rd. Suite 100\nHARRISONVILLE, MO 64701 US\n816-380-3582',
    state: 'mo',
  },
  {
    name: 'SREE B NARRA, MD',
    link: 'https://www.ifm.org/practitioners/sree-b-narra-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/7652-0015R-400x400.jpg',
    contactText:
      'Address\n1610 Des Peres Road\nSuite 150\nst Louis, MO 63131 US\n3146790076',
    state: 'mo',
  },
  {
    name: 'T.J. WILLIAMS, DC',
    link: 'https://www.ifm.org/practitioners/t-j-williams/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/20171211-_DSF1174-TJ-copy-400x400.jpg',
    contactText:
      'Address\n1034 S. Brentwood Blvd.\nSuite 415\nSt. Louis, MO 63117 US\n314-293-8123',
    state: 'mo',
  },
  {
    name: 'THOMAS SCOTT JAMISON',
    link: 'https://www.ifm.org/practitioners/thomas-jamison/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_0168-1-1-400x400.jpg',
    contactText:
      'Address\n9160 Clayton Rd\nSt Louis , MO 63124 US\n314-801-8898',
    state: 'mo',
  },
  {
    name: 'THOMAS KUCIEJCZYK-KERNAN, MD',
    link: 'https://www.ifm.org/practitioners/thomas-kuciejczyk-kernan-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/thomaskkernan-photo-400x400.jpg',
    contactText:
      'Address\n3930 S. Broadway\nSt. Louis, MO 63118-4626 US\n314-898-1999',
    state: 'mo',
  },
  {
    name: 'VENKATA KADA, MD',
    link: 'https://www.ifm.org/practitioners/venkata-kada/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Photo-VGK-2-400x400.png',
    contactText:
      'Address\n6500 Hospital Drive\nHannibal, MO 63401 US\n573 629 3500',
    state: 'mo',
  },
  {
    name: 'BRADLEY L AYLOR, MD',
    link: 'https://www.ifm.org/practitioners/bradley-l-aylor-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n1276 N. 15th AVE\nSuite 101\nBozeman, MT 59715- US\n(406) 522-9067',
    state: 'mt',
  },
  {
    name: 'CLAIRE REYNOLDS',
    link: 'https://www.ifm.org/practitioners/claire-reynolds/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/00CB5BB2-9302-48CE-AA9A-8C6023608956_4_5005_c-400x400.jpeg',
    contactText: 'Address\n3 W. Main St\nCut Bank, MT 59427 US\n4068455566',
    state: 'mt',
  },
  {
    name: 'DEBORAH SOGGE-KERMANI, MD',
    link: 'https://www.ifm.org/practitioners/deborah-sogge-kermani-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n42628 Flathead View Dr.\nPolson, MT 59860 US\n(406) 883-1330',
    state: 'mt',
  },
  {
    name: 'HEATHER MADDOX, MD',
    link: 'https://www.ifm.org/practitioners/heather-maddox/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/heather-headshot-400x400.jpg',
    contactText:
      'Address\n235 North 1st Street West, Suite E\nMissoula, MT 59802 US\n406-531-2550',
    state: 'mt',
  },
  {
    name: 'LINDSEY DAVIS',
    link: 'https://www.ifm.org/practitioners/lindsey-davis/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Untitled-3-400x400.jpg',
    contactText: '',
    state: 'mt',
  },
  {
    name: 'LINDSEY PETERSON',
    link: 'https://www.ifm.org/practitioners/lindsey-peterson/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/lindsey-headshot-400x400.jpg',
    contactText:
      'Address\n235 North 1st Street West\nSuite E\nMissoula, MT 59802 US\n406.531.2550',
    state: 'mt',
  },
  {
    name: 'LOY ANDERSON, MD',
    link: 'https://www.ifm.org/practitioners/loy-anderson/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Loy-Anderson-400x400.jpg',
    contactText:
      'Address\n1601 2nd Ave N\nSuite 430\nGreat Falls, MT 59401 US\n(406) 205-8100',
    state: 'mt',
  },
  {
    name: 'MICHAEL HUTCHINS, MD',
    link: 'https://www.ifm.org/practitioners/michael-john-hutchins/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Mikephoto-400x400.jpg',
    contactText:
      'Address\n2809 Great Northern Loop\nSuite 410\nMissoula, MT 59808 US\n406-210-5259',
    state: 'mt',
  },
  {
    name: 'NATALIE GONZALES, D.O.',
    link: 'https://www.ifm.org/practitioners/natalie-gonzales/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/0ECCD331-D409-451F-9247-96F2C5C0CA3C-400x400.jpeg',
    contactText:
      'Address\n630 North Last Chance Gulch\nHelena, MT 59601 US\n406-459-7481',
    state: 'mt',
  },
  {
    name: 'CASSIE KRAUSE',
    link: 'https://www.ifm.org/practitioners/cassie-krause/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n11810 Nicholas Street\nOmaha, NE 68154 US\n402.307.5510',
    state: 'ne',
  },
  {
    name: 'KIMBERLY K HARM',
    link: 'https://www.ifm.org/practitioners/kimberly-harm-phd-aprn-np/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Kim-Harm-Headshot-400x400.jpg',
    contactText:
      'Address\n1320 Galvin Road South\nBellevue, NE 68005 US\n402-292-6006',
    state: 'ne',
  },
  {
    name: 'MICHELLE MAILE MCCASLIN',
    link: 'https://www.ifm.org/practitioners/michelle-mccaslin-aprn-fnp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/fullsizeoutput_1f-400x400.jpeg',
    contactText:
      'Address\n10701 S. 72nd St\nSuite 100\nPapillion, NE 68046 US\n(402) 827-9421',
    state: 'ne',
  },
  {
    name: 'ELISA J MERCURO, DO',
    link: 'https://www.ifm.org/practitioners/elisa-j-mercuro-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/elisaphoto-400x400.png',
    contactText:
      'Address\n254 North Broadway\nSuite 208\nSalem, NH 03079 US\n603-328-8101',
    state: 'nh',
  },
  {
    name: 'ELIZABETH BOWEN MITCHELL',
    link: 'https://www.ifm.org/practitioners/elizabeth-bowen-mitchell/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1 Oak Ridge Road, Building 1 (Red)\nBuilding 1 (Red)\nWest Lebanon, NH 03784 US\n6032772199',
    state: 'nh',
  },
  {
    name: 'IRINA SEREBRYAKOVA-REINO, NP',
    link: 'https://www.ifm.org/practitioners/irina-serebryakova-reino-ms/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Irni-photo-lab-coat-400x400.png',
    contactText: 'Address\n149 Epping Rd\nExeter, NH 03833 US\n603.3092030',
    state: 'nh',
  },
  {
    name: 'JULIE BROOKS, PA-C, IFMCP, MSHNFM',
    link: 'https://www.ifm.org/practitioners/julie-brooks-pa-c/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Julie-400x400.jpg',
    contactText:
      'Address\n60 Exeter Road\nBuilding 100, Suite 102\nNewmarket, NH 03857 US\n603-273-9269',
    state: 'nh',
  },
  {
    name: 'LAURA A HALL, ND',
    link: 'https://www.ifm.org/practitioners/laura-a-jones-nd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/drjones-900x600-400x400.jpg',
    contactText: 'Address\n7 Broadway\nSt\nConcord, NH 03301 US\n603-369-4626',
    state: 'nh',
  },
  {
    name: 'MADALYN OTTO, ND',
    link: 'https://www.ifm.org/practitioners/madalyn-otto/',
    avatar: 'https://www.ifm.org/wp-content/uploads/headshot-1-400x400.png',
    contactText: 'Address\n7 Broadway\nConcord, NH 03301 US\n(603)369-4626',
    state: 'nh',
  },
  {
    name: 'RACHEL CROTEAU, DO',
    link: 'https://www.ifm.org/practitioners/rachel-croteau/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_3801-3-400x400.jpg',
    contactText:
      'Address\n800 Park Ave\nSuite 101\nKeene, NH 03431 US\n603-803-3925',
    state: 'nh',
  },
  {
    name: 'SETH OSGOOD',
    link: 'https://www.ifm.org/practitioners/seth-osgood/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Seth-78-400x400.jpg',
    contactText:
      'Primary Address\n24 Airport Road\nSuite 302\nWest Lebanon, NH 03784 US\n6032764066',
    state: 'nh',
  },
  {
    name: 'AMY R SPARKS, MD',
    link: 'https://www.ifm.org/practitioners/amy-r-sparks-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Amy-Sparks-470-300-wplok-400x400.jpg',
    contactText:
      'Address\n10785 W Twain Ave\nSuite 221\nLas Vegas, NV 89135 US\n702-722-2200',
    state: 'nv',
  },
  {
    name: 'BILJANA SOFRONIJOSKA RECE',
    link: 'https://www.ifm.org/practitioners/biljana-sofronijoska-rece/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Biljana_Sofronijoska_Rece_RDN_LD_IFMCP_registered_functional_medicine_dietitian_Las_Vegas-400x400.jpg',
    contactText:
      'Address\n2850 W. Horizon Ridge Parkway Suite 200\nHenderson, NV 89052 US\n702.635.4669',
    state: 'nv',
  },
  {
    name: 'BINA PATEL, MD',
    link: 'https://www.ifm.org/practitioners/bina-patel-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n420 S Rampart Blvd\nsuite 410\nLas Vegas, NV 89145 US\n(702) 947-7896',
    state: 'nv',
  },
  {
    name: 'CAROL CHANEY, FNP-BC PHD NUTRITION',
    link: 'https://www.ifm.org/practitioners/carol-chaney/',
    avatar: 'https://www.ifm.org/wp-content/uploads/FB-photo-400x400.jpg',
    contactText:
      'Address\n9330 West Sahara Avenue\nSte 250\nLas Vegas, NV 89117 US\n702 312-1101',
    state: 'nv',
  },
  {
    name: 'DEEPALI KASHYAP',
    link: 'https://www.ifm.org/practitioners/deepali-kashyap/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n2225 Village Walk Drive\nSuite 270\nHenderson, NV 89052 US\n702-983-2010',
    state: 'nv',
  },
  {
    name: 'IAN YAMANE',
    link: 'https://www.ifm.org/practitioners/ian-yamane/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.-Ian-2017-headshot--400x400.jpg',
    contactText:
      'Primary Address\n2851 N. Tenaya Way, Ste. 103\nLAS VEGAS, NV 89128 US\n702-658-0204',
    state: 'nv',
  },
  {
    name: 'RICHARD SY TEH, MD',
    link: 'https://www.ifm.org/practitioners/richard-teh-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n4550 East Charleston Blvd\nlas vegas, NV 89104 US\n7026238810',
    state: 'nv',
  },
  {
    name: 'SHERRI AIKIN',
    link: 'https://www.ifm.org/practitioners/sherri-aikin/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/qjTqR4zfQdeesxgzfAI0aw_mini_14e-400x400.jpg',
    contactText:
      'Address\n645 Sierra Rose Dr.\nSuite 204\nreno, NV 89511 US\n7754035757',
    state: 'nv',
  },
  {
    name: 'STEVE RUDACK',
    link: 'https://www.ifm.org/practitioners/steve-rudack-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_2049.jpg',
    contactText:
      'Address\n8685 W. Sahara Ave.\nSuite 180\nLas Vegas, NV 89117 US\n(702) 362-0336',
    state: 'nv',
  },
  {
    name: 'BIANCA CHIARA, MD',
    link: 'https://www.ifm.org/practitioners/bianca-chiara-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/headshot12-400x400.jpg',
    contactText:
      'Address\n435 South Street\nSuite 160\nMorristown, NJ 07960 US\n973-971-6301',
    state: 'nj',
  },
  {
    name: 'CHRIS CAFFERY, DC',
    link: 'https://www.ifm.org/practitioners/chris-caffery-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/CHRISHEADSHOT-400x400.jpg',
    contactText:
      'Primary Address\n221 Laurel Rd.\nSuite 160\nVoorhees Township, NJ 08043 US\n(856) 888-1860',
    state: 'nj',
  },
  {
    name: 'CHRISTOPHER J BUMP, DC',
    link: 'https://www.ifm.org/practitioners/christopher-j-bump-dc-msifmcp-dcbcn-cns/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Bump-Portrait-400x400.jpg',
    contactText:
      'Address\nPO Box 1010\nRt 94 Viking Village, Suite B\nMcAfee, NJ 07428 US\n(973) 827-3500',
    state: 'nj',
  },
  {
    name: 'DANIEL ZACHARIAS, MD',
    link: 'https://www.ifm.org/practitioners/daniel-zacharias-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n7 Short Hills Ave\nShort Hills, NJ 07078 US\n(973) 912-0006',
    state: 'nj',
  },
  {
    name: 'GEORGE WANG, MD',
    link: 'https://www.ifm.org/practitioners/george-wang/',
    avatar: 'https://www.ifm.org/wp-content/uploads/GF1A08411-2-400x400.jpg',
    contactText:
      'Address\n16 Pocono Road\nDenville, NJ 07834-2908 US\n973-671-1868',
    state: 'nj',
  },
  {
    name: 'JESSICA MILLER, MD',
    link: 'https://www.ifm.org/practitioners/jessica-miller-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/thumb_IMG_9505_1024-400x400.jpg',
    contactText:
      'Address\n3 Hospital Plaza\nMedical Arts Building, Suite 313\nOld Bridge, NJ 08857 US\n732-631-4410',
    state: 'nj',
  },
  {
    name: 'JYOTI MATHEWS, MD',
    link: 'https://www.ifm.org/practitioners/jyoti-mathews-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2018-05-31-at-5.34.05-PM-400x400.png',
    contactText: 'Address\n115 FRANKLIN TURNPIKE\n#183\nMAHWAH, NJ 07430 US',
    state: 'nj',
  },
  {
    name: 'KELLY BLUNDY, IFMCP, DC',
    link: 'https://www.ifm.org/practitioners/kelly-blundy/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Kelly-400x400.png',
    contactText:
      'Primary Address\n2 South Kinderkamack Road\nMontvale, NJ 07645 US\n2017466577',
    state: 'nj',
  },
  {
    name: 'KENNETH J STORCH, MD',
    link: 'https://www.ifm.org/practitioners/kenneth-j-storch-md-phd/',
    avatar: 'https://www.ifm.org/wp-content/uploads/KJS-Edited-2c-400x400.jpg',
    contactText:
      'Address\n210 Malapardis Road\nSuite 202\nCedar Knolls, NJ 07927-1121 US\n(973) 240-5000',
    state: 'nj',
  },
  {
    name: 'LARA ZAKARIA',
    link: 'https://www.ifm.org/practitioners/lara-zakaria-rph-ms-cns-cn/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/20210209_231217-400x400.jpg',
    contactText: '',
    state: 'nj',
  },
  {
    name: 'LARISA GAITOUR, MD',
    link: 'https://www.ifm.org/practitioners/larisa-gaitour-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Mask-Group-2-copy-400x400.png',
    contactText:
      'Address\n229 Main Street # 1083\nFort Lee, NJ 07024 US\n2014299848',
    state: 'nj',
  },
  {
    name: 'LISA M CAMPANELLA-COPPO',
    link: 'https://www.ifm.org/practitioners/lisa-m-campanella-coppo/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/centered-final-face-shot-blue-sweater-grey-wall-400x400.jpeg',
    contactText:
      'Address\n504 Hamburg Turnpike Suite 202\nWayne, NJ 07470 US\n551-316-7740',
    state: 'nj',
  },
  {
    name: 'MAHMOUD EL BANNA, MD',
    link: 'https://www.ifm.org/practitioners/mahmoud-el-banna-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/download-400x400.png',
    contactText: 'Address\n59 Veronica Ave\nSomerset, NJ 08873 US\n7328280200',
    state: 'nj',
  },
  {
    name: 'MARWA HAZZAH',
    link: 'https://www.ifm.org/practitioners/marwa-hazzah/',
    avatar: 'https://www.ifm.org/wp-content/uploads/1-1-400x400.jpg',
    contactText:
      'Address\n16 brook road\nupper saddle river, NJ 07458 US\n973-980-1239',
    state: 'nj',
  },
  {
    name: 'MARYJO A PEAY',
    link: 'https://www.ifm.org/practitioners/maryjo-m-peay-msn-apn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/PROFILE-PIC-400x400.jpg',
    contactText: '973 998 8021',
    state: 'nj',
  },
  {
    name: 'MATHEW J SILVERMAN, DO',
    link: 'https://www.ifm.org/practitioners/mathew-j-silverman-do/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\nPO Box 7287\nFreehold, NJ 07728-7287 US\n(201) 845-4048',
    state: 'nj',
  },
  {
    name: 'MONICA SOOD, MD',
    link: 'https://www.ifm.org/practitioners/monica-sood-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n252 Broad Street\nSuite 2\nRed Bank, NJ 07701 US\n732-743-3106',
    state: 'nj',
  },
  {
    name: 'NICOLE A ANDERSON MD',
    link: 'https://www.ifm.org/practitioners/nicole-anderson/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n113 West Essex Street\nSuite 204\nMaywood, NJ 07607 US\n2012895551',
    state: 'nj',
  },
  {
    name: 'ROBYN C DEL NEGRO, MD',
    link: 'https://www.ifm.org/practitioners/robyn-del-negro/',
    avatar: 'https://www.ifm.org/wp-content/uploads/photo1-1-400x400.jpg',
    contactText:
      'Address\n200 Monmouth Street, ste 350\nRed Bank, NJ 07701 US\n732 820 6680',
    state: 'nj',
  },
  {
    name: 'STEPHANIE STROZUK, MD',
    link: 'https://www.ifm.org/practitioners/stephanie-strozuk-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/logo1-400x400.png',
    contactText:
      'Address\n1250 E Ridgewood Avenue\nRidgewood, NJ 07450 US\n(201) 857-5770',
    state: 'nj',
  },
  {
    name: 'SUSAN A FLANZMAN',
    link: 'https://www.ifm.org/practitioners/susan-a-flanzman/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1 W. Ridgewood Avenue\nSuite 301\nParamus, NJ 07652 US\n201-445-1660',
    state: 'nj',
  },
  {
    name: 'VANESSA GOURDINE',
    link: 'https://www.ifm.org/practitioners/vanessa-gourdine-psyd-msn-apn-c-cfmp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Vanessa-Gourdine-400x400.jpg',
    contactText:
      'Primary Address\n4 Forest Avenue\nSuite 110\nParamus, NJ 07652 US\n(855) 693-9633',
    state: 'nj',
  },
  {
    name: 'ANN MCCARTHY, MD',
    link: 'https://www.ifm.org/practitioners/ann-mccarthy/',
    avatar: 'https://www.ifm.org/wp-content/uploads/152-400x400.jpg',
    contactText:
      'Address\n8300 Carmel N.E.\nBuilding 3, Unit 303, Office 2\nAlbuquerque, NM 87122 US\n505-895-1157',
    state: 'nm',
  },
  {
    name: 'BARBARA MADDOUX, RN, DOM',
    link: 'https://www.ifm.org/practitioners/barbara-maddoux-rn-dom/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Photo-on-11-4-20-at-11.01-AM-400x400.jpg',
    contactText:
      'Address\n8204 Louisiana Blvd NE\nAlbuquerque, NM 87113 US\n(505) 828-9642',
    state: 'nm',
  },
  {
    name: 'DONNA FLEITZ MITCHELL, MD',
    link: 'https://www.ifm.org/practitioners/donna-mitchell/',
    avatar: 'https://www.ifm.org/wp-content/uploads/donna-photo-2-400x400.jpg',
    contactText:
      'Address\n6615 Glenlochy Way NE\nAlbuquerque, NM 87113 US\n720-204-6960',
    state: 'nm',
  },
  {
    name: 'KRISTEN L. BIGGS, MD',
    link: 'https://www.ifm.org/practitioners/kristen-l-biggs-md-rpvi-rphs-facs/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Biggs-IFM-Headshot-profile-pic-cropped-400x400.jpg',
    contactText:
      'Address\n450 St. Michaels Drive\nSanta Fe, NM 87505 US\n(505) 695-7070',
    state: 'nm',
  },
  {
    name: 'ALEXANDRIA DEVITO, MS',
    link: 'https://www.ifm.org/practitioners/alexandria-devito-ms/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n57 W 57th St\nPMB 4454\nNew York, NY 10019 US\n646-780-9525',
    state: 'ny',
  },
  {
    name: 'ANDREA CARLSEN, MD',
    link: 'https://www.ifm.org/practitioners/andrea-carlsen-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/andrea-400x400.jpg',
    contactText:
      'Address\n75 Prospect St\nSuite 102\nHuntington, NY 11743 US\n631-923-0006',
    state: 'ny',
  },
  {
    name: 'ANITA BUROCK STOTTS, MD',
    link: 'https://www.ifm.org/practitioners/anita-burock-stotts-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Anita-in-office-2-400x400.jpg',
    contactText:
      'Address\n2592 Western Avenue\nSuite 102\nAltamont, NY 12009 US\n(518) 355-2060',
    state: 'ny',
  },
  {
    name: 'BOJANA JANKOVIC WEATHERLY, MD',
    link: 'https://www.ifm.org/practitioners/bojana-jankovic-weatherly-md-msc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Profile-picture-low-res-400x400.jpg',
    contactText:
      'Primary Address\n245 5th Avenue\n3rd Floor\nNY, NY 10016 US\n646 6278000',
    state: 'ny',
  },
  {
    name: 'CARA INNUS',
    link: 'https://www.ifm.org/practitioners/cara-innus/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n2355 Union Rd, Suite 100\nCheektowaga, NY 14227 US\n716-898-0755',
    state: 'ny',
  },
  {
    name: 'CHITI PARIKH, MD',
    link: 'https://www.ifm.org/practitioners/chiti-parikh-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Chiti-Parikh-Headshot-2-400x400.jpg',
    contactText:
      'Address\n436 East 69th street\nNew York, NY 10021 US\n646-697-9355',
    state: 'ny',
  },
  {
    name: 'CHRISTINE J DUNCAN, MD',
    link: 'https://www.ifm.org/practitioners/christine-duncan-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_9228-400x400.jpg',
    contactText:
      'Address\n1 Hollow Lane\nSuite 315\nLake Success, NY 11042 US\n516-437-4300',
    state: 'ny',
  },
  {
    name: 'ELENA KLIMENKO, MD',
    link: 'https://www.ifm.org/practitioners/elena-klimenko-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_6116small-400x400.jpg',
    contactText:
      'Address\n280 Madison Avenue\nSuite 905\nNew York, NY 10016 US\n(212) 696-4325',
    state: 'ny',
  },
  {
    name: 'ELIZABETH MANEJIAS',
    link: 'https://www.ifm.org/practitioners/elizabeth-manejias/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n130 west 56 th street\n3 rd floor\nNew York, NY 10019 US\n212-254-6783',
    state: 'ny',
  },
  {
    name: 'EMILY LINZER GORDON, MD',
    link: 'https://www.ifm.org/practitioners/emily-linzer-gordon-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n70 Hatfield Lane\nSuite 101\nGoshen , NY 10924 US\n8452948888',
    state: 'ny',
  },
  {
    name: 'ERIKA M JURASITS, DO',
    link: 'https://www.ifm.org/practitioners/erika-m-jurasits-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Dr-Erika-pic-400x400.jpg',
    contactText:
      'Address\n170 North Country Rd\nSuite 2\nPort Jefferson, NY 11777 US\n(631) 509-6888',
    state: 'ny',
  },
  {
    name: 'GARY GOLDMAN, MD',
    link: 'https://www.ifm.org/practitioners/gary-goldman-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/FullSizeRender-91-400x400.jpg',
    contactText:
      'Address\n1735 York Avenue\nSuite A\nNew York, NY 10128 US\n212-535-6100',
    state: 'ny',
  },
  {
    name: 'HEIDI J RASMUSSEN, MD',
    link: 'https://www.ifm.org/practitioners/heidi-j-rasmussen-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/IMG_5368-4-Copy-400x400.jpg',
    contactText:
      'Address\n434 Church Street\nSaratoga Springs, NY 12866 US\n(518) 769-2893',
    state: 'ny',
  },
  {
    name: 'JENNIFER ANNE GLASSMAN',
    link: 'https://www.ifm.org/practitioners/jennifer-anne-glassman-md-faafp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_0431-1-400x400.jpg',
    contactText:
      'Address\n126 5th Avenue\n2nd Floor\nNew York, NY 10011 US\n8334472775',
    state: 'ny',
  },
  {
    name: 'JENNIFER CLEMENTE, MS, CNS, CDN, IFMCP',
    link: 'https://www.ifm.org/practitioners/jennifer-clemente/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Photo-on-4-4-18-at-1.53-PM-2-2-400x400.jpg',
    contactText: '9176026086',
    state: 'ny',
  },
  {
    name: 'JENNIFER LYNN JENNINGS',
    link: 'https://www.ifm.org/practitioners/jennifer-l-jennings-dnp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_1406-1-400x400.jpg',
    contactText:
      'Address\n1196 E. Main St.\nEast Aurora, NY 14052 US\n(716) 685-2361',
    state: 'ny',
  },
  {
    name: 'JESSICA KAYLOR, MMS, PA-C',
    link: 'https://www.ifm.org/practitioners/jessica-kaylor/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/027CDA21-6B41-4211-B8B6-0D3967BD1A5A-400x400.jpeg',
    contactText:
      'Primary Address\n**DERMATOLOGY complaints only**\n110 East 60th Street Suite 800\nNew York, NY 10022 US\n212-433-4569',
    state: 'ny',
  },
  {
    name: 'JILL R. BARON, MD',
    link: 'https://www.ifm.org/practitioners/jill-r-baron-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1036 Park Avenue\nSuite 1B\nNew York, NY 10028 US\n646-472-5043',
    state: 'ny',
  },
  {
    name: 'JOGESH SYALEE, MD',
    link: 'https://www.ifm.org/practitioners/jogesh-syalee-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Address\nHoward Beach, NY 11414 US',
    state: 'ny',
  },
  {
    name: 'JOSEPH DEBÉ, DC',
    link: 'https://www.ifm.org/practitioners/joseph-debe/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Me-0075-1-400x400.jpg',
    contactText:
      'Address\n265 Sunrise Hwy\nSuite 41\nRockville Centre, NY 11570 US\n516-829-1515',
    state: 'ny',
  },
  {
    name: 'JULIE PERLANSKI, MD',
    link: 'https://www.ifm.org/practitioners/julie-perlanski-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n114 South Shore Rd\nOld Forge, NY 13420 US\n315 369 6619',
    state: 'ny',
  },
  {
    name: 'KELLY HERRON, MD',
    link: 'https://www.ifm.org/practitioners/kelly-herron/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/professional-pic-400x400.jpg',
    contactText:
      'Address\n20 OFFICE PARK WAY\nOFFICE #124\nPITTSFORD, NY 14534 US\n585-760-4104',
    state: 'ny',
  },
  {
    name: 'KRISTIN BRIEN',
    link: 'https://www.ifm.org/practitioners/kristin-brien/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Screenshot_9-1-400x400.png',
    contactText:
      'Address\n121 Main St Suite 157\nNorthport , NY 11768 US\n631-974-2279',
    state: 'ny',
  },
  {
    name: 'LAURA FUNKE, FNP',
    link: 'https://www.ifm.org/practitioners/laura-funke-fnp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/fullsizeoutput_b7f-400x400.jpeg',
    contactText:
      'Address\n200 Sterling Dr\nOrchard Park, NY 14127 US\n716-250-2000',
    state: 'ny',
  },
  {
    name: 'LEAH GEOGHEGAN NP',
    link: 'https://www.ifm.org/practitioners/leah-geoghegan/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Test-400x400.jpg',
    contactText:
      'Address\n347 Fifth Avenue\nOnline & Home Visits Only\nNew York, NY 10016 US\n6468492782',
    state: 'ny',
  },
  {
    name: 'LILLI LINK, MD',
    link: 'https://www.ifm.org/practitioners/lilli-link-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n126 5th Ave 2nd Floor\nNew York, NY 10011 US\n(646) 880-4465',
    state: 'ny',
  },
  {
    name: 'MAGDOLNA SARINGER',
    link: 'https://www.ifm.org/practitioners/magdolna-saringer-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2017-05-25-at-10.02.05-AM-400x400.png',
    contactText:
      'Address\n154 W 70th Street\napt#9N\nNew York, NY 10023 US\n2127212650',
    state: 'ny',
  },
  {
    name: 'MARINA KUCHER, RPA-C, IFMCP',
    link: 'https://www.ifm.org/practitioners/marina-kucher/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n280 Madison Ave\nSuite 905\nNew York, NY 10016 US\n2126964325',
    state: 'ny',
  },
  {
    name: 'MARINA RICHARDS, MD',
    link: 'https://www.ifm.org/practitioners/marina-richards-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n80 Central Park West\nSuite C\nNew York, NY 10023 US\n(212) 362-8200',
    state: 'ny',
  },
  {
    name: 'MARY STRATOS, PA',
    link: 'https://www.ifm.org/practitioners/mary-stratos/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n126 5th Ave\n2nd Floor\nNew York, NY 10011 US\n(833) 447-2775',
    state: 'ny',
  },
  {
    name: 'MEGAN MARUMOTO, MD',
    link: 'https://www.ifm.org/practitioners/megan-marumoto/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\nTalkiatry\n109 W 27th St Suite 5S\nNew York, NY 10001 US',
    state: 'ny',
  },
  {
    name: 'MICHELLE L SEGUIN, MD',
    link: 'https://www.ifm.org/practitioners/michelle-l-seguin-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/image1-400x400.jpg',
    contactText: 'Address\n126 5th Ave\nFloor 2\nNew York, NY 10011-5631 US',
    state: 'ny',
  },
  {
    name: 'MILLIE LEE, MD',
    link: 'https://www.ifm.org/practitioners/millie-lee/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Square-headshot-4-400x400.jpg',
    contactText:
      'Primary Address\n245 Fifth Ave, 3rd Floor\nNew York, NY 10016 US\n(929) 295-2851',
    state: 'ny',
  },
  {
    name: 'NINA PESANTE, MD',
    link: 'https://www.ifm.org/practitioners/nina-pesante-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n300 Main St\nSuite 4\nVestal, NY 13850 US\n(607) 754-3646',
    state: 'ny',
  },
  {
    name: 'NISHA CHELLAM, MD',
    link: 'https://www.ifm.org/practitioners/nisha-chellam-vedamuthu-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/nesha-400x400.jpg',
    contactText:
      'Address\n126, 5th Avenue\n2nd Floor\nNew York, NY 10011 US\n8334472775',
    state: 'ny',
  },
  {
    name: 'PAMELA PESTA, RPH',
    link: 'https://www.ifm.org/practitioners/pamela-pesta/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/72989313-5B0C-4941-998E-3705B2FB8D71-400x400.jpeg',
    contactText:
      'Address\n45 Apeldorn Drive\nSchenectady, NY 12306 US\n5185888618',
    state: 'ny',
  },
  {
    name: 'PHYLLIS YOUNG, PA-C',
    link: 'https://www.ifm.org/practitioners/phyllis-young-pa-c/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Phyllis-office-400x400.jpeg',
    contactText:
      'Address\n8 Rue de Vin Dr\nMarlboro, NY 12542 US\n(845) 913-7737',
    state: 'ny',
  },
  {
    name: 'SARAH LOBISCO, ND',
    link: 'https://www.ifm.org/practitioners/sarah-lobisco-nd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/LoBisco005lowres-400x400.jpg',
    contactText: 'Address\nVirtual Phone\nSchenectady, NY 12306 US',
    state: 'ny',
  },
  {
    name: 'SARITA MAHTANI, MD',
    link: 'https://www.ifm.org/practitioners/sarita-mahtani/',
    avatar: 'https://www.ifm.org/wp-content/uploads/SMworkpic-400x400.jpeg',
    contactText:
      'Address\n436 E 69th St\nNew York, NY 10021 US\n(646) 697-9355',
    state: 'ny',
  },
  {
    name: 'SCOTT J BANKS, DC',
    link: 'https://www.ifm.org/practitioners/scott-j-banks-dc-pc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/DSC00055-400x400.jpeg',
    contactText:
      'Address\n755 New York Ave\nSuite 308\nHuntington, NY 11743 US\n(631) 271-0770',
    state: 'ny',
  },
  {
    name: 'SHERYL LEVENTHAL, MD',
    link: 'https://www.ifm.org/practitioners/sheryl-leventhal-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n707A Executive Blvd\nValley Cottage, NY 10989 US\n(845) 268-1655',
    state: 'ny',
  },
  {
    name: 'SUSAN SPANTON BLUM, MD',
    link: 'https://www.ifm.org/practitioners/susan-blum-md-mph/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/green-square-crop-400x400-1-400x400.jpg',
    contactText:
      'Address\n34 Rye Ridge Plaza\nRye Brook, NY 10573 US\n(914) 652-7800',
    state: 'ny',
  },
  {
    name: 'SUSANNE SALTZMAN, MD',
    link: 'https://www.ifm.org/practitioners/susanne-saltzman/',
    avatar: 'https://www.ifm.org/wp-content/uploads/head-shot3-400x400.jpg',
    contactText:
      'Primary Address\n250 East Hartsdale Avenue\nSuite 22\nHartsdale, NY 10530 US\n(914) 472-0666',
    state: 'ny',
  },
  {
    name: 'TAMARA HAWKINS',
    link: 'https://www.ifm.org/practitioners/tamara-hawkins-2/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/5M1A8544-Edit-1-1-400x400.jpg',
    contactText:
      'Primary Address\n365 W. 125th Street\nOnline Visits Only\nNew York, NY 10027 US\n6468953788',
    state: 'ny',
  },
  {
    name: 'VANESSA LEIGH CARROLL, MD',
    link: 'https://www.ifm.org/practitioners/vanessa-leigh-carroll-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/222109-345887-400x400.jpg',
    contactText:
      'Address\n145 Palisade Street, Suite 200\nDobbs Ferry, NY 10522 US\n9144074698',
    state: 'ny',
  },
  {
    name: 'YELENA DAVYDOVA',
    link: 'https://www.ifm.org/practitioners/yelena-davydova/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\nForest Hills\nFOREST HILLS NY, NY 11375 US\n516-464-7550',
    state: 'ny',
  },
  {
    name: 'AMY FLETCHER, MD',
    link: 'https://www.ifm.org/practitioners/amy-fletcher-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_3440-400x400.png',
    contactText:
      'Address\n6401 Morrison Boulevard\nSuite 2A\nCharlotte, NC 28211 US\n704-390-7150',
    state: 'nc',
  },
  {
    name: 'ANALIA CAMARASA, MS, CNS',
    link: 'https://www.ifm.org/practitioners/analia-camarasa-ms/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Analia-400x400.png',
    contactText:
      'Address\n400 Gilead Rd. #1555\nHuntersville, NC 28070-1555 US\n(704) 274-2005',
    state: 'nc',
  },
  {
    name: 'BRIAN JERBY, MD',
    link: 'https://www.ifm.org/practitioners/brian-l-jerby-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Jerby-photo-400x400.jpg',
    contactText:
      'Address\n3049 Senna Drive\nMatthews, NC 28105 US\n980 339 5155',
    state: 'nc',
  },
  {
    name: 'CLAYTON BELL, M.D.',
    link: 'https://www.ifm.org/practitioners/clayton-bell/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Clayton-Bell-Head-Shot-profile-image-400x400.jpg',
    contactText:
      'Address\n1312 Patton Avenue\nAsheville, NC 28806 US\n828-252-9833',
    state: 'nc',
  },
  {
    name: 'CYNTHIA JANE LIBERT, MD',
    link: 'https://www.ifm.org/practitioners/cynthia-jane-libert-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/color-cropped-headshot-400x400.jpg',
    contactText:
      'Address\n1998 Hendersonville Rd\nSuite 24\nAsheville, NC 28803 US\n8284901545',
    state: 'nc',
  },
  {
    name: 'DANIEL J RASHID, MD',
    link: 'https://www.ifm.org/practitioners/daniel-j-rashid-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Daniel-IFM-400x400.jpeg',
    contactText:
      'Address\n400 Gilead Rd\nUnit #1555\nHuntersville, NC 28070-1555 US\n(704) 274-2005',
    state: 'nc',
  },
  {
    name: 'DIANA WILLIAMS',
    link: 'https://www.ifm.org/practitioners/diana-williams/',
    avatar: 'https://www.ifm.org/wp-content/uploads/3M3A0628-400x400.jpg',
    contactText:
      'Address\n315 1st ave NW\nsuite 102\nHickory, NC 28601 US\n8288381225',
    state: 'nc',
  },
  {
    name: 'DICIE D NATHANI, DC',
    link: 'https://www.ifm.org/practitioners/dicie-d-nathani-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_0033-400x400.jpg',
    contactText:
      'Address\n7101 Creedmoor Road,\nSuite 102\nRaleigh, NC 27613 US\n(919) 848-3333',
    state: 'nc',
  },
  {
    name: 'DIDEM MIRALOGLU',
    link: 'https://www.ifm.org/practitioners/didem-miraloglu/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2023-01-02-at-3.53.04-PM-400x400.png',
    contactText:
      'Address\n3708 Forestview Road\nSuite 202\nRaleigh, NC 27612 US\n9199990831',
    state: 'nc',
  },
  {
    name: 'DONNA CHILDRESS, MD',
    link: 'https://www.ifm.org/practitioners/donna-childress-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/9CFEA9D9-72CE-499F-9168-F0FBD36FF35C-400x400.jpeg',
    contactText: 'Address\n18 Pine Tree Road\nSalisbury, NC 28144 US',
    state: 'nc',
  },
  {
    name: 'ERIC M OSANSKY, DC',
    link: 'https://www.ifm.org/practitioners/eric-m-osansky-dc-ms-ccn-cns/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Photo-EricOsansky-2016-V2-Thumbnail-resize-400x400.jpg',
    contactText:
      'Address\n10020 Monroe Road\nSuite 170-280\nMatthews, NC 28105 US\n888-570-8873',
    state: 'nc',
  },
  {
    name: 'HARLEE PALMER, PA-C',
    link: 'https://www.ifm.org/practitioners/harlee-palmer/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_0779-400x400.jpeg',
    contactText:
      'Address\n6401 Morrison Blvd\nCharlotte, NC 28211 US\n7043907150',
    state: 'nc',
  },
  {
    name: 'JILL C VANARTHOS, PA-C',
    link: 'https://www.ifm.org/practitioners/jill-vanarthos/',
    avatar: 'https://www.ifm.org/wp-content/uploads/unnamed-2-400x400.jpg',
    contactText:
      'Address\n2011 Falls Valley Dr\nSuite 100\nRaleigh, NC 27615 US\n919.208.2314',
    state: 'nc',
  },
  {
    name: 'JOHNNY S GOMES, DO',
    link: 'https://www.ifm.org/practitioners/johnny-s-gomes-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/OIP-400x400.jpg',
    contactText:
      'Address\n113 Davis Street\nMorganton, NC 28655 US\n(828) 536-0320',
    state: 'nc',
  },
  {
    name: 'KAREN PRESTON',
    link: 'https://www.ifm.org/practitioners/karen-preston-fnp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/KP-400x400.jpg',
    contactText:
      'Address\n107 Broad Street\nAsheville, NC 28801 US\n2673770495',
    state: 'nc',
  },
  {
    name: 'LAYNE WEAVER RN, FNP-C',
    link: 'https://www.ifm.org/practitioners/layne-weaver/',
    avatar: 'https://www.ifm.org/wp-content/uploads/WFM_web_icon-400x400.png',
    contactText: '336-444-2040',
    state: 'nc',
  },
  {
    name: 'NANCY PALERMO LIETZ, MD',
    link: 'https://www.ifm.org/practitioners/nancy-palermo-palermo-lietz-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/NancyPalermo-400x400.jpg',
    contactText:
      'Address\n6401 Morrison Blvd\nSuite 2A\nCharlotte, NC 28210 US\n704-390-7150',
    state: 'nc',
  },
  {
    name: 'NICOLE FERRELL',
    link: 'https://www.ifm.org/practitioners/nicole-ferrell-fnp/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n3288 Robinhood Rd.\nWinston Salem, NC 27106 US\n(336) 768-3335',
    state: 'nc',
  },
  {
    name: 'PATRICK HANAWAY, MD',
    link: 'https://www.ifm.org/practitioners/patrick-hanaway-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/patrickhanaway-3-e1568652626193-400x400.jpg',
    contactText: 'Address\n207 Charlotte\nAsheville, NC 28801 US\n8282512700',
    state: 'nc',
  },
  {
    name: 'POLLY JANSEN WATSON, MD',
    link: 'https://www.ifm.org/practitioners/polly-jansen-watson-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Polly-Watson-Polly-Watson-0014-Resized-400x400.jpg',
    contactText:
      'Address\n211 E. Six Forks Rd.\nBuilding A, Suite 120\nRaleigh, NC 27609 US\n919 364 3430',
    state: 'nc',
  },
  {
    name: 'RAJAL PATEL, MD',
    link: 'https://www.ifm.org/practitioners/rajal-patel-md-mph/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2D981DBD-FDD7-4B97-85B7-CB56B42D0272-400x400.jpeg',
    contactText:
      'Address\n150 Fairview Road\nSuite 120\nMooresville, NC 28117 US\n888-531-3844',
    state: 'nc',
  },
  {
    name: 'RUTH ANNE LININGER, MD',
    link: 'https://www.ifm.org/practitioners/ruth-lininger-md-mph/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/restoringbalanceportraits-163.cropped3-400x400.jpg',
    contactText:
      'Primary Address\n2309 Wabash Rd\nChapel Hill, NC 27516 US\n984-364-8441',
    state: 'nc',
  },
  {
    name: "SARAH O'NEILL, MS",
    link: 'https://www.ifm.org/practitioners/sarah-oneill-ms/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/ProfessionalPhoto-400x400.png',
    contactText:
      'Address\n575 Davidson Gateway Drive\nSuite 100\nDavidson, NC 28036 US\n980-289-0657',
    state: 'nc',
  },
  {
    name: 'SONAL TAYLOR, MD',
    link: 'https://www.ifm.org/practitioners/sonal-taylor-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screenshot_20200611-225005_Dropbox-400x400.jpg',
    contactText:
      'Address\n264 Haywood Road\nAsheville, NC 28806 US\n8284587576',
    state: 'nc',
  },
  {
    name: 'SONIA RAPAPORT, MD',
    link: 'https://www.ifm.org/practitioners/sonia-rapaport-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/0F029542-46D8-42DF-A31A-B4A3BC3BC4A7-400x400.jpeg',
    contactText:
      'Address\n121 S Estes Dr\nSuite 205D\nChapel Hill, NC 27514 US\n9199691414',
    state: 'nc',
  },
  {
    name: 'SUSAN D. DENNY, MD',
    link: 'https://www.ifm.org/practitioners/susan-d-denny-md-mph/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Susan-Headshot-400x400.jpg',
    contactText:
      'Address\n3708 Forestview Rd.\nSuite 202\nRaleigh, NC 27612 US\n(919) 999-0831',
    state: 'nc',
  },
  {
    name: 'ZOIE PHILLIPS',
    link: 'https://www.ifm.org/practitioners/zoie-brianne-phillips-mrs/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Phillips-2-400x400.jpg',
    contactText:
      'Address\n527 Old Maccumber Station Rd\nWilmington, NC 28405 US\n910-292-9123',
    state: 'nc',
  },
  {
    name: 'TREVOR HAUSAUER',
    link: 'https://www.ifm.org/practitioners/trevor-hausauer-fnp/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n3540 38th Ave S\nSuite G\nFargo, ND 58104 US\n7015321926',
    state: 'nd',
  },
  {
    name: 'TREVOR HAUSAUER',
    link: 'https://www.ifm.org/practitioners/trevor-hausauer-fnp/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n3540 38th Ave S\nSuite G\nFargo, ND 58104 US\n7015321926',
    state: 'nd',
  },
  {
    name: 'ANSHUL GUPTA',
    link: 'https://www.ifm.org/practitioners/anshul-gupta/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Anshul-Gupta-400x400.jpg',
    contactText: 'Primary Address\n2230 West Laskey Road\nToledo, OH 43613 US',
    state: 'oh',
  },
  {
    name: 'ANUP KANODIA, MD',
    link: 'https://www.ifm.org/practitioners/anup-kanodia-md-mph/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/DiBlasio-Photo-Dr-K-5302-773x1024-copy-400x400.jpg',
    contactText:
      'Address\n660 Cooper Rd.\nSuite 400\nWesterville, OH 43081 US\n614-524-4527',
    state: 'oh',
  },
  {
    name: 'CAROLYN F NEMEC, MD',
    link: 'https://www.ifm.org/practitioners/carolyn-nemec/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/754B5E36-38D6-41DE-B173-420FD5F05A69_1_105_c-400x400.jpeg',
    contactText:
      'Address\n5485 Brookside Trail\nSolon, OH 44139 US\n216-312-3151',
    state: 'oh',
  },
  {
    name: 'CRISTINA VOMERO, PA-C',
    link: 'https://www.ifm.org/practitioners/cristina-vomero/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_0102-400x400.jpg',
    contactText:
      'Address\n7500 Towne Centre\nSuite 500\nBroadview Hts, OH 44147 US\n(440 )537-1599',
    state: 'oh',
  },
  {
    name: 'DANIEL FOSSELMAN',
    link: 'https://www.ifm.org/practitioners/daniel-fosselman/',
    avatar: 'https://www.ifm.org/wp-content/uploads/COPCHeadshot-400x400.jpg',
    contactText:
      'Address\n555 W Schrock Rd\nSTE 110\nWesterville, OH 43081 US\n6148820708',
    state: 'oh',
  },
  {
    name: 'DOROTHY S SPRECHER, MD',
    link: 'https://www.ifm.org/practitioners/dorothy-s-sprecher-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n8398 Kinsman Rd.\nSuite 2\nNovelty, OH 44072 US\n(440) 338-6344',
    state: 'oh',
  },
  {
    name: 'EMILY ROEDERSHEIMER, DO',
    link: 'https://www.ifm.org/practitioners/emily-roedersheimer/',
    avatar: 'https://www.ifm.org/wp-content/uploads/emily-head-3-400x400.png',
    contactText:
      'Address\n75 E. Wilson Bridge Road\nSuite C2\nWorthington, OH 43085 US\n614-547-2829',
    state: 'oh',
  },
  {
    name: 'JANE PALMER SMITH, DC',
    link: 'https://www.ifm.org/practitioners/jane-palmer-smith-dc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.-Jane-Smith-FHCC-2016-1200x1081-400x400.jpg',
    contactText:
      'Address\n890 S. Barron St\nEaton, OH 45320 US\n(937) 456-4555',
    state: 'oh',
  },
  {
    name: 'JENNIFER A BOUCHARD, MS',
    link: 'https://www.ifm.org/practitioners/jennifer-bouchard-ms-fnp/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n8472 E Washington St.\nPO Box 117\nBainbridge Township, OH 44023 US\n216.333.7860',
    state: 'oh',
  },
  {
    name: 'JENNIFER RAWLINS',
    link: 'https://www.ifm.org/practitioners/jennifer-rawlins/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n10484 Kley Rd.\nVersailles, OH 45380 US\n937-526-3016',
    state: 'oh',
  },
  {
    name: 'JOSEPH IUVARA, DC',
    link: 'https://www.ifm.org/practitioners/joseph-iuvara-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_6590-400x400.jpg',
    contactText:
      'Address\n428 B Beecher Rd\nSuite B\nGahanna, OH 43230 US\n(614) 855-5533',
    state: 'oh',
  },
  {
    name: 'KRISTEN HARDING, MD',
    link: 'https://www.ifm.org/practitioners/kristen-harding-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Kristen-400x400.jpg',
    contactText:
      'Address\n7657 Montgomery Road\nSuite 2\nCincinnati, OH 45236 US\n(513) 549-0494',
    state: 'oh',
  },
  {
    name: 'LIBBY WILSON, M.D.',
    link: 'https://www.ifm.org/practitioners/libby-wilson/',
    avatar: 'https://www.ifm.org/wp-content/uploads/1L2A6953-400x400.jpg',
    contactText: 'Address\n2523 WILDFLOWER DR\nSPRINGFIELD, OH 455044480 US',
    state: 'oh',
  },
  {
    name: 'LOGAN KWASNICKA',
    link: 'https://www.ifm.org/practitioners/logan-kwasnicka/',
    avatar: 'https://www.ifm.org/wp-content/uploads/image-1-400x400.jpeg',
    contactText:
      'Address\n1348 Sharon Copley Rd.\nWadsworth, OH 44281 US\n330-591-2444',
    state: 'oh',
  },
  {
    name: 'MATTHEW EVERETT, DO',
    link: 'https://www.ifm.org/practitioners/matthew-everett-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_0228-400x400.jpg',
    contactText:
      'Primary Address\n2105 Kentwell Rd.\nColumbus, OH 43221 US\n614-946-6001',
    state: 'oh',
  },
  {
    name: 'MELISSA YOUNG, MD',
    link: 'https://www.ifm.org/practitioners/melissa-young-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\nChagrin Falls Family Health Center\n551 East Washington Street\nChagrin Falls, OH 44022 US\n(216) 445-6900',
    state: 'oh',
  },
  {
    name: 'MICHAEL T HASMAN, MD',
    link: 'https://www.ifm.org/practitioners/michael-t-hasman-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n8787 Brookpark Road\nParma, OH 44129 US\n(216)-739-7000x40086',
    state: 'oh',
  },
  {
    name: 'NANCY GRUBB, MD',
    link: 'https://www.ifm.org/practitioners/nancy-fazekas-grubb-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n4030 Massillon Road\nSuite C\nUniontown, OH 44685 US\n(330) 699-1500',
    state: 'oh',
  },
  {
    name: 'NATALIE JO FLYNN, MSN, CNP, IFMCP',
    link: 'https://www.ifm.org/practitioners/natalie-jo-flynn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Claire-n-I-400x400.jpg',
    contactText:
      'Address\n19745 Monterey Ave\nEuclid, OH 44119 US\n216-406-0154',
    state: 'oh',
  },
  {
    name: 'NATE BERGMAN, DO',
    link: 'https://www.ifm.org/practitioners/nate-bergman-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Bergman_Headshot-400x400.jpg',
    contactText:
      'Address\n21851 Center Ridge Rd #105\nRocky River, OH 44116 US\n2163371400',
    state: 'oh',
  },
  {
    name: 'RICK HASEMEIER, DC',
    link: 'https://www.ifm.org/practitioners/rick-hasemeier-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Headshot5-400x400.jpg',
    contactText:
      'Address\n345 North Main Street\nUnit 4\nSpringboro, OH 45066 US\n(937) 550-4156',
    state: 'oh',
  },
  {
    name: 'RIFFAT HANIF QADIR, MD',
    link: 'https://www.ifm.org/practitioners/riffat-qadir-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Retouched-picture-400x400.png',
    contactText:
      'Address\n3838 Massillon Road\nSuite 380A\nUniontown, OH 44685 US\n(330) 899-0050',
    state: 'oh',
  },
  {
    name: 'SAIMA KHAWAJA, MD',
    link: 'https://www.ifm.org/practitioners/saima-khawaja-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_9568-400x400.jpg',
    contactText:
      'Primary Address\n4605 Morse Road\nSte 201\nGahanna, OH 43230 US\n6146566455',
    state: 'oh',
  },
  {
    name: 'SANDRA COOPER',
    link: 'https://www.ifm.org/practitioners/sandra-cooper/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n9500 Euclid Ave Q/2\nCleveland, OH 44195 US\n216-445-6900',
    state: 'oh',
  },
  {
    name: 'SARA STEIN, MD',
    link: 'https://www.ifm.org/practitioners/sara-stein-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Sara-chocolate-small-400x400.jpg',
    contactText: 'Primary Address\n3999 Richmond Rd\nBeachwood, OH 44122 US',
    state: 'oh',
  },
  {
    name: 'SARAH CAWLEY',
    link: 'https://www.ifm.org/practitioners/sarah-cawley/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/PracticalHealingJune2022-38-mini-res-400x400.jpg',
    contactText:
      'Address\n1348 Sharon Copley Rd\nWadsworth, OH 44281 US\n3305912444',
    state: 'oh',
  },
  {
    name: 'SEEMA M PATEL',
    link: 'https://www.ifm.org/practitioners/seema-m-patel/',
    avatar: 'https://www.ifm.org/wp-content/uploads/emailpic-400x400.png',
    contactText:
      'Address\n31100 Pinetree Rd\nPepperpike, OH 44124 US\n216.440.5559',
    state: 'oh',
  },
  {
    name: 'SHERRI WHITAKER',
    link: 'https://www.ifm.org/practitioners/sherri-whitaker/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n9500 Euclid Ave, Dept Q2\nCleveland , OH 44195 US\n216-445-6900',
    state: 'oh',
  },
  {
    name: 'TARA MOORE',
    link: 'https://www.ifm.org/practitioners/tara-moore/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Address\nAkron\nCopley, OH 44321 US\n3174066495',
    state: 'oh',
  },
  {
    name: 'TERESA M ESTERLE, MD',
    link: 'https://www.ifm.org/practitioners/teresa-esterle/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Teresa-E-small-400x400.jpg',
    contactText:
      'Address\n6400 E Galbraith Rd\nCincinnati, OH 45236 US\n5137915521',
    state: 'oh',
  },
  {
    name: 'TYLER KELLY, DC',
    link: 'https://www.ifm.org/practitioners/tyler-j-kelly-dc-ms-afmcp-cscs/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/CCCBDDA8-E066-43D7-BEA8-6F9F260D52E1-1-400x400.jpeg',
    contactText:
      'Address\n815 Grandview Ave., STE 250\nSTE 250\nColumbus, OH 43215 US\n614-779-0721',
    state: 'oh',
  },
  {
    name: 'WILLIAM BLUETER, DC',
    link: 'https://www.ifm.org/practitioners/william-blueter/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Bill-headshot-2019-400x400.jpg',
    contactText:
      'Address\n6693 North Chestnut Street\nSuite 145\nRavenna, OH 44266 US\n330-360-0028',
    state: 'oh',
  },
  {
    name: 'NANCY COOPER, APRN-CNP',
    link: 'https://www.ifm.org/practitioners/nancy-cooper/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/1538B236-6910-43D1-B070-710995FB3A48-400x400.png',
    contactText:
      'Address\nSecure Telehealth visits and consultations\nEnid, OK 73703 US',
    state: 'ok',
  },
  {
    name: 'ROYCE LEE BARGAS, DO',
    link: 'https://www.ifm.org/practitioners/royce-bargas-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2019-professional-photo-GIF-400x400.gif',
    contactText:
      'Address\n3839 S Boulevard St\nSuite 100\nEdmond, OK 73013 US\n405-607-4445',
    state: 'ok',
  },
  {
    name: 'SARAH YOAKAM, MD',
    link: 'https://www.ifm.org/practitioners/sarah-yoakam/',
    avatar: 'https://www.ifm.org/wp-content/uploads/eras-photo-400x400.jpg',
    contactText:
      'Address\n1000 W Wilshire Blvd\nSuite 359\nOklahoma City, OK 73116 US\n405-753-0604',
    state: 'ok',
  },
  {
    name: 'SUZANNE KEYES',
    link: 'https://www.ifm.org/practitioners/suzanne-keyes-pharmd/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Blazer-400x400.jpg',
    contactText:
      'Address\n1602 West Third Street\nElk City, OK 73644 US\n580-225-5273',
    state: 'ok',
  },
  {
    name: 'TRICIA BUTNER',
    link: 'https://www.ifm.org/practitioners/tricia-butner-dnp-cnp-aprn/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n2403 A Wrangler Blvd\nSeminole, OK 74868 US\n4056286448',
    state: 'ok',
  },
  {
    name: 'NANCY COOPER, APRN-CNP',
    link: 'https://www.ifm.org/practitioners/nancy-cooper/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/1538B236-6910-43D1-B070-710995FB3A48-400x400.png',
    contactText:
      'Address\nSecure Telehealth visits and consultations\nEnid, OK 73703 US',
    state: 'ok',
  },
  {
    name: 'ROYCE LEE BARGAS, DO',
    link: 'https://www.ifm.org/practitioners/royce-bargas-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2019-professional-photo-GIF-400x400.gif',
    contactText:
      'Address\n3839 S Boulevard St\nSuite 100\nEdmond, OK 73013 US\n405-607-4445',
    state: 'ok',
  },
  {
    name: 'SARAH YOAKAM, MD',
    link: 'https://www.ifm.org/practitioners/sarah-yoakam/',
    avatar: 'https://www.ifm.org/wp-content/uploads/eras-photo-400x400.jpg',
    contactText:
      'Address\n1000 W Wilshire Blvd\nSuite 359\nOklahoma City, OK 73116 US\n405-753-0604',
    state: 'ok',
  },
  {
    name: 'SUZANNE KEYES',
    link: 'https://www.ifm.org/practitioners/suzanne-keyes-pharmd/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Blazer-400x400.jpg',
    contactText:
      'Address\n1602 West Third Street\nElk City, OK 73644 US\n580-225-5273',
    state: 'ok',
  },
  {
    name: 'TRICIA BUTNER',
    link: 'https://www.ifm.org/practitioners/tricia-butner-dnp-cnp-aprn/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n2403 A Wrangler Blvd\nSeminole, OK 74868 US\n4056286448',
    state: 'ok',
  },
  {
    name: 'ALEKSANDRA GIEDWOYN, MD',
    link: 'https://www.ifm.org/practitioners/aleksandra-giedwoyn/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Aleksandra-Giedwoyn-2-400x400.jpg',
    contactText:
      'Address\n736 SE 60th Ave\nPortland, OR 97215 US\n(503) 662-1971',
    state: 'or',
  },
  {
    name: 'BARBARA WINGES CONFLITTI',
    link: 'https://www.ifm.org/practitioners/barbara-winges-conflitti-msn-aprn-fnp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_4066-400x360.jpg',
    contactText: '1-541-482-2985',
    state: 'or',
  },
  {
    name: 'BILL POWELL, DO',
    link: 'https://www.ifm.org/practitioners/bill-powell-do/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n595 N Main St\nSte 2\nAshland, OR 97520 US\n(541) 488-1116',
    state: 'or',
  },
  {
    name: 'CAROLYN F. SELF, FNP',
    link: 'https://www.ifm.org/practitioners/carolyn-f-self-fnp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Carolyn-400x400.jpeg',
    contactText:
      'Address\n1467B Siskiyou Blvd. #2053\nAshland, OR 97520-2336 US\n(541) 482-6777',
    state: 'or',
  },
  {
    name: 'COBY L. HANES, DC',
    link: 'https://www.ifm.org/practitioners/coby-l-hanes-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/HeadShotB-400x400.jpg',
    contactText:
      'Address\n446 NW 3rd Street\nSuite 200\nPrineville, OR 97754 US\n(541) 447-7230',
    state: 'or',
  },
  {
    name: 'CYNDI WORDEN, DO',
    link: 'https://www.ifm.org/practitioners/cyndi-worden-do/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n15 82nd Drive\nSuite 100\nGladstone, OR 97027 US\n5038319231',
    state: 'or',
  },
  {
    name: 'DEBORAH ANDERSON, ND',
    link: 'https://www.ifm.org/practitioners/deborah-anderson-nd/',
    avatar: 'https://www.ifm.org/wp-content/uploads/DrAnderson-2-400x400.jpeg',
    contactText:
      'Primary Address\n5060 SW Philomath\n#336\nCorvallis, OR 97333 US\n541-929-3877',
    state: 'or',
  },
  {
    name: 'ERICHA CLARE, ND',
    link: 'https://www.ifm.org/practitioners/ericha-clare-nd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Ericha-C-7-cropped-square-400x400.jpg',
    contactText:
      'Address\n10365 SE Sunnyside Rd\nSte 210\nClackamas, OR 97015 US\n5038877725',
    state: 'or',
  },
  {
    name: 'ERIN A. BISHOP',
    link: 'https://www.ifm.org/practitioners/erin-a-bishop-arnp-bc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Untitled-design-6-400x400.png',
    contactText:
      'Address\n151 SW Shevlin Hixon Dr.\nSuite 101\nBend, OR 97702 US\n(458) 206-3331',
    state: 'or',
  },
  {
    name: 'JEANETTE WELKER, MN',
    link: 'https://www.ifm.org/practitioners/jeanette-welker-mn-fnp/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Address\n1132 5th St\nSpringfield, OR 97477 US\n5417621155',
    state: 'or',
  },
  {
    name: 'JEFF HORACEK, MD',
    link: 'https://www.ifm.org/practitioners/jeff-horacek-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/jeff-headshot-pdx-400x400.jpg',
    contactText:
      'Primary Address\n33 NICHOLS PKWY STE 300\nHood River, OR 97031 US\n541-716-5786',
    state: 'or',
  },
  {
    name: 'JENNIFER WALLACE, MD',
    link: 'https://www.ifm.org/practitioners/jennifer-wallace-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1235 SE Division St\n208\nPortland, OR 97202 US\n8884801115',
    state: 'or',
  },
  {
    name: 'JULIEN SUCCAR, MD',
    link: 'https://www.ifm.org/practitioners/julien-succar-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Address\n2484 River Rd\nEugene, OR 97404 US\n(541) 222-7650',
    state: 'or',
  },
  {
    name: 'KRISTA ESPENSHIP',
    link: 'https://www.ifm.org/practitioners/krista-espenship-msn/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n16463 Boones Ferry RD\n#300\nLake Oswego, OR 97035 US\n541-748-9634',
    state: 'or',
  },
  {
    name: 'LARA WILLIAMS',
    link: 'https://www.ifm.org/practitioners/lara-williams/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n501 North Graham Street\nSuite 550\nPortland, OR 97227 US\n503-284-5220',
    state: 'or',
  },
  {
    name: 'LAURA MARTELL',
    link: 'https://www.ifm.org/practitioners/laura-martell-nd-lac-fabno/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Laura-400x400.jpg',
    contactText:
      'Address\n9155 SW Barnes Rd, Suite 238\nPortland, OR 97225 US\n971-202-0408',
    state: 'or',
  },
  {
    name: 'ELIZABETH LIPSKI, PHD, CNS, FACN, IFMCP, LDN',
    link: 'https://www.ifm.org/practitioners/liz-lipski-phd-ccn-cns/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Address\n3221 SE 54th Ave\nPortland, OR 97206 US',
    state: 'or',
  },
  {
    name: 'MAGGIE K YU, MD',
    link: 'https://www.ifm.org/practitioners/maggie-k-yu/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/1385591_10151668542362056_1503751218_n_10151668542362056-400x400.jpg',
    contactText:
      'Address\n9450 SW Gemini Drive\nBeaverton, OR 97008 US\n5039089900',
    state: 'or',
  },
  {
    name: 'MARK BOWMAN, MD',
    link: 'https://www.ifm.org/practitioners/mark-bowman-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n15 82nd Drive, Suite 100\nGladstone, OR 97027 US\n5038319231',
    state: 'or',
  },
  {
    name: 'MARY KATE COPPEDGE, NP',
    link: 'https://www.ifm.org/practitioners/mary-kate-coppedge-np/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n151 SW Shevlin Hixon dr #201\nsuite 201\nBend, OR 97702 US\n4582029231',
    state: 'or',
  },
  {
    name: 'MAY KHADEM, MD',
    link: 'https://www.ifm.org/practitioners/may-khadem/',
    avatar: 'https://www.ifm.org/wp-content/uploads/MKMD-400x400.jpg',
    contactText:
      'Address\nFirst Addition\nLake Oswego, OR 97034 US\n(503)479-5823',
    state: 'or',
  },
  {
    name: 'MICHIKO OISHI',
    link: 'https://www.ifm.org/practitioners/michiko-oishi-mshs-pa-c/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n2865 NW Cedar Hills Blvd\nBldg 14\nBeaverton, OR 97005 US\n888-663-6331',
    state: 'or',
  },
  {
    name: 'RACHEL SURPRENANT, ND',
    link: 'https://www.ifm.org/practitioners/rachel-surprenant/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Rach-small-400x400.jpg',
    contactText:
      'Address\n2067 NW Lovejoy\nPortland, OR 97209 US\n503-222-2322',
    state: 'or',
  },
  {
    name: 'STACI WHITMAN',
    link: 'https://www.ifm.org/practitioners/staci-whitman/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Staci-headshot-pink-1-400x400.jpeg',
    contactText:
      'Address\n4548 N Albina Ave\nPortland, OR 97217 US\n9719780009',
    state: 'or',
  },
  {
    name: 'VIRGINIA SHAPIRO, DC',
    link: 'https://www.ifm.org/practitioners/virginia-shapiro-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/DSC4386-400x400.jpg',
    contactText:
      'Address\n915 NW 36th Street\nCorvallis, OR 97330 US\n(541) 738-2711',
    state: 'or',
  },
  {
    name: 'ALEXSANDRA MOTRONI, MD',
    link: 'https://www.ifm.org/practitioners/alexsandra-motroni-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1324 North Church Street\nHazle Township, PA 18202 US\n(570) 778-6466',
    state: 'pa',
  },
  {
    name: 'AMY NUTTER',
    link: 'https://www.ifm.org/practitioners/amy-nutter/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/16904DF4-E9E4-43F3-845B-CA726914715F-400x400.jpeg',
    contactText:
      'Address\n904 Fairthorne Dr\nKennett Square, PA 19348 US\n484-7340701',
    state: 'pa',
  },
  {
    name: 'ANNMARIE MCMANUS',
    link: 'https://www.ifm.org/practitioners/annmarie-mcmanus-mmsc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/MacManus-A-Photo-7.17-400x400.jpeg',
    contactText:
      'Address\n237 West Lancaster Ave, Suite 215\nDevon, PA 19333 US\n484-222-0341',
    state: 'pa',
  },
  {
    name: 'ARIANNE MISSIMER, DR.',
    link: 'https://www.ifm.org/practitioners/arianne-missimer-dr/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Headshot-small-400x400.jpg',
    contactText:
      'Address\n101 Manor Avenue\nDowningtown, PA 19335 US\n302-635-9220',
    state: 'pa',
  },
  {
    name: 'ARTHUR LEWIS, MD',
    link: 'https://www.ifm.org/practitioners/arthur-lewis/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Art-Lewis-photo-cropped-400x400.jpg',
    contactText:
      'Address\n233 Sopher Road\nTitusville, PA 16354 US\n814-208-5080',
    state: 'pa',
  },
  {
    name: 'CHANTELL REAGAN, PHARMD',
    link: 'https://www.ifm.org/practitioners/chantell-sell-reagan-chantellsellhotmail-com/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_5835-400x400.jpg',
    contactText: '2158378830',
    state: 'pa',
  },
  {
    name: 'CONAN SHAW',
    link: 'https://www.ifm.org/practitioners/conan-shaw/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/8CB6D677-6813-40A0-BE7F-2CA44781D0D5-400x400.jpeg',
    contactText:
      'Address\n667 Castle Creek Dr. Ext.\nSeven Fields, PA 16046 US\n724 778-3000',
    state: 'pa',
  },
  {
    name: 'DAVID LOBUR, MD',
    link: 'https://www.ifm.org/practitioners/david-lobur-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.LoburHeadshots-14-400x400.jpg',
    contactText:
      'Address\n3830 S Water St.\nPittsburgh, PA 15203 US\n(412) 422-5433',
    state: 'pa',
  },
  {
    name: 'DEBORAH BERNSTEIN, MD',
    link: 'https://www.ifm.org/practitioners/deborah-bernstein-md-ms/',
    avatar: 'https://www.ifm.org/wp-content/uploads/debandginger-400x400.jpg',
    contactText:
      'Address\n5812 Lower York Road\nLahaska, PA 18931 US\n(215) 794-7880',
    state: 'pa',
  },
  {
    name: 'DEBRA L MEIER',
    link: 'https://www.ifm.org/practitioners/debra-l-meier-pt-nutritionist/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/UNADJUSTEDNONRAW_thumb_8e1-400x400.jpg',
    contactText:
      'Address\n1800 JFK Blvd\nSuite 300\nPhiladelphia, PA 19103 US\n(215) 220-4575',
    state: 'pa',
  },
  {
    name: 'DONNA MARIE KULP, D.C.',
    link: 'https://www.ifm.org/practitioners/donna-kulp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/pic-for-airbnb-400x400.jpg',
    contactText:
      'Address\n52 Morgantown Road Suite 2\nReading , PA 19611 US\n610-375-2234',
    state: 'pa',
  },
  {
    name: 'GEORGIA TETLOW, MD',
    link: 'https://www.ifm.org/practitioners/georgia-tetlow-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_2521-good--400x400.jpg',
    contactText:
      'Address\n200 Eagle Road\nBldg 2, Suite 208\nWayne, PA 19087 US\n(888) 702-7974x2',
    state: 'pa',
  },
  {
    name: 'HEIDI WITTELS, MD',
    link: 'https://www.ifm.org/practitioners/heidi-wittels-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n8200 FLourtown Rd\nWyndmoor, PA 19038 US\n(215) 233-6226',
    state: 'pa',
  },
  {
    name: 'JAISON GOLOJUH, DC',
    link: 'https://www.ifm.org/practitioners/jaison-golojuh/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/E1406940-11EB-44D5-9A7A-C22B44DEF885-400x400.jpeg',
    contactText:
      'Primary Address\n657 C Pittsburgh Rd\nButler, PA 16002 US\n724-586-5858',
    state: 'pa',
  },
  {
    name: 'JAMES CARDASIS, DO',
    link: 'https://www.ifm.org/practitioners/james-cardasis-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/image-3-400x400.jpg',
    contactText:
      'Address\n405 Virginia Ave\nPhoenixville, PA 19460 US\n5757707050',
    state: 'pa',
  },
  {
    name: 'JANINE RIHMLAND, M.D.',
    link: 'https://www.ifm.org/practitioners/janine-rihmland-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n741 Locust Avenue\nWASHINGTON, PA 15301 US\n7249064798',
    state: 'pa',
  },
  {
    name: 'JOHN E NEELY, MD',
    link: 'https://www.ifm.org/practitioners/john-neely-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n1144 Greenwood Drive\nHummelstown, PA 17050 US\n(717) 531-6719',
    state: 'pa',
  },
  {
    name: 'JONATHAN MORLEY, MD',
    link: 'https://www.ifm.org/practitioners/jonathan-morley-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n8200 Flourtown Avenue\nSuite 4B\nWyndmoor, PA 19038 US\n(215) 233-3022',
    state: 'pa',
  },
  {
    name: 'JOSEPH CARCHEDI, MD',
    link: 'https://www.ifm.org/practitioners/joseph-carchedi-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/head-shot-4-400x400.jpg',
    contactText:
      'Address\n1108 N Bethlehem Pike\nBuilding B\nLower Gwynedd, PA 19002 US\n215 780-1898',
    state: 'pa',
  },
  {
    name: 'JOYCE E GIBB, MS',
    link: 'https://www.ifm.org/practitioners/joyce-e-gibb-ms-crnp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/JOYCE-2-400x400.jpg',
    contactText:
      'Address\n215 Allegheny Avenue\nOakmont, PA 15139 US\n(412) 826-9500',
    state: 'pa',
  },
  {
    name: 'JULIA MAE HELSTROM, DO',
    link: 'https://www.ifm.org/practitioners/julia-mae-helstrom-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/julia-headshot-2023-400x400.jpeg',
    contactText:
      'Address\n708 N Shady Retreat Road\nSuit 1\nDoylestown, PA 18901 US\n267-454-7262',
    state: 'pa',
  },
  {
    name: 'KARL HOLTZER, MD',
    link: 'https://www.ifm.org/practitioners/karl-holtzer-md-ms/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Karl-IFM-photo-400x400.jpg',
    contactText:
      'Address\n580 South Aiken Avenue\n#310\nPittsburgh, PA 15232 US\n(412) 623-3023',
    state: 'pa',
  },
  {
    name: 'KRISTANN HEINZ',
    link: 'https://www.ifm.org/practitioners/kristann-heinz-md-rd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Kristann-Website-400x400.jpeg',
    contactText:
      'Address\n85 Sherman Road\nOttsville, PA 18942 US\n610-847-1111',
    state: 'pa',
  },
  {
    name: 'KRISTEN BENTSON, DC',
    link: 'https://www.ifm.org/practitioners/kristen-bentson/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/D4FA8075-C21A-4153-88DA-686259E8DDF3-400x400.jpeg',
    contactText:
      'Address\n5325 Northgate Drive\nSuite 206\nBethlehem, PA 18017 US\n610-868-6816',
    state: 'pa',
  },
  {
    name: 'LIANA RODRIGUEZ',
    link: 'https://www.ifm.org/practitioners/liana-rodriguez-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/kartra-email-signature-headshot-400x400.png',
    contactText:
      'Primary Address\n1120 Centre Turnpike\nOrwigsburg, PA 17961 US\n(570) 366-2613',
    state: 'pa',
  },
  {
    name: "LINDA D'ERAMO, DO",
    link: 'https://www.ifm.org/practitioners/linda-deramo-do/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n962 Beaver Grade Road\nFirst Floor\nMoon Township, PA 15108 US\n(724) 457-6258',
    state: 'pa',
  },
  {
    name: 'LISA SRNKA, MSN, RN, FNP-C, IFMCP',
    link: 'https://www.ifm.org/practitioners/lisa-mahoney/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/LowResolution-10-400x400.jpg',
    contactText:
      'Address\n432 Green Street\n2 nd Fl\nSewickley, PA 15143 US\n412-789-0054',
    state: 'pa',
  },
  {
    name: 'LORI TOMAINO BILLIARD',
    link: 'https://www.ifm.org/practitioners/lori-billiard-msn-fnp-bc-crnp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/bio-pic-LOVGH-400x400.jpg',
    contactText:
      'Primary Address\n840 Walnut Street # 2\nCatasauqua, PA 18032 US\n484-272-2788',
    state: 'pa',
  },
  {
    name: 'LUCIA BUCKLEY, CRNP, IFM-CP',
    link: 'https://www.ifm.org/practitioners/lucia-buckley/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Cropped-400x400.jpeg',
    contactText:
      'Address\n3830 South Water Street\nPittsburgh, PA 15203 US\n412-422-5433',
    state: 'pa',
  },
  {
    name: 'LYNDA SHERLAND, CRNP, RN, IFMCP',
    link: 'https://www.ifm.org/practitioners/lynda-sherland/',
    avatar: 'https://www.ifm.org/wp-content/uploads/lynda-109-400x400.jpeg',
    contactText:
      'Address\n200 Eagle Rd\nSuite 208\nWayne, PA 19087 US\n8887027974',
    state: 'pa',
  },
  {
    name: 'LYNN PETROCELLI',
    link: 'https://www.ifm.org/practitioners/lynn-petrocelli-crnp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_8369111-400x400.jpg',
    contactText:
      'Address\n170 Jamison Lane Suite C\nMonroeville, PA 15146 US\n412-646-1339',
    state: 'pa',
  },
  {
    name: 'MARVIN MCGOWAN, DO',
    link: 'https://www.ifm.org/practitioners/marvin-mcgowan/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Marv-scan-2_2-400x400.jpg',
    contactText:
      'Address\n127 Anderson Street, Ste 101\nPittsburgh, PA 15212 US\n412 515 0000',
    state: 'pa',
  },
  {
    name: 'MARY DIANA DAVIS, DO',
    link: 'https://www.ifm.org/practitioners/mary-diana-davis-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/21-05-07176FULL-1-400x400.jpg',
    contactText:
      'Address\n4284 William Flinn Highway\nCastletown Square South/Suite 206\nAllison Park, PA 15101 US\n(724) 452-5200',
    state: 'pa',
  },
  {
    name: 'MARY REGAN',
    link: 'https://www.ifm.org/practitioners/mary-regan/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/79D27C86-6115-4059-A14C-61874537952E-400x400.jpeg',
    contactText:
      'Primary Address\n2207 Oregon Pike\nSuite 301a\nLancaster, PA 17601 US\n717-490-6227',
    state: 'pa',
  },
  {
    name: 'MELISSA TOMON',
    link: 'https://www.ifm.org/practitioners/melissa-tomon/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/professional-pic-1-400x400.jpg',
    contactText: '267-368-4545',
    state: 'pa',
  },
  {
    name: 'PATRICIA H. LOTITO, MD',
    link: 'https://www.ifm.org/practitioners/patricia-lotito-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/functionaldoctormainline-400x400.gif',
    contactText:
      'Address\n195 W Lancaster Ave\n(Inside The Integrated Healing Center, 2nd floor)\nPaoli, PA 19301 US\n484-320-7178',
    state: 'pa',
  },
  {
    name: 'SHARON J. MAGAN',
    link: 'https://www.ifm.org/practitioners/sharon-j-magan-phd-np/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n201 Penn Center Blvd\nBuilding 1, Suite 555\nPittsburgh, PA 15235 US\n412-376-5500',
    state: 'pa',
  },
  {
    name: 'SHILPI MEHTA, OD',
    link: 'https://www.ifm.org/practitioners/shilpi-mehta-doctor-of-optometry/',
    avatar: 'https://www.ifm.org/wp-content/uploads/ShilpiGradCrop-400x400.jpg',
    contactText: 'Address\n1925 Keith Rd\nAbington, PA 19001 US\n617-969-1907',
    state: 'pa',
  },
  {
    name: 'STEPHANIE SUDJIAN, MS, RD, LDN, CLT, IFMCP',
    link: 'https://www.ifm.org/practitioners/stephanie-sudjian/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_0974-400x400.jpg',
    contactText:
      'Address\n751 Sproul Road #1072\nSpringfield, PA 19064 US\n508-319-9537',
    state: 'pa',
  },
  {
    name: 'VERONICA PORTERFIELD',
    link: 'https://www.ifm.org/practitioners/veronica-porterfield-physician-assistant/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_4181-3-400x400.jpg',
    contactText:
      'Address\n200 Eagle Rd\nSuite 208\nWayne, PA 19087 US\n8887027974',
    state: 'pa',
  },
  {
    name: 'WENDY WARNER, MD',
    link: 'https://www.ifm.org/practitioners/wendy-warner-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/ww2018-400x400.jpg',
    contactText:
      'Address\n950 W Trenton Ave\nSuite 938\nMorrisville, PA 19067 US\n215-741-1600',
    state: 'pa',
  },
  {
    name: 'ACHINA P STEIN, DO',
    link: 'https://www.ifm.org/practitioners/achina-p-stein-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/DD7388818694427A8D1785C1B0013822-400x400.jpg',
    contactText:
      'Address\n105 Wickenden Street\nSuite 6\nProvidence, RI 02903 US\n401-270-4541',
    state: 'ri',
  },
  {
    name: 'JOHN ZWETCHKENBAUM, MD',
    link: 'https://www.ifm.org/practitioners/john-zwetchkenbaum-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr-Z-Head-Shot-3-400x400.jpg',
    contactText:
      'Address\n470 Toll Gate Road\nsuite 205\nWarwick, RI 02886 US\n4017511235',
    state: 'ri',
  },
  {
    name: 'SALLY MILLER DAVIDSON',
    link: 'https://www.ifm.org/practitioners/sally-miller-davidson-ms/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Sally-bio-1-2-400x400.jpg',
    contactText:
      'Address\n250 Wampanoag Trail\nSuite 305\nRiverside , RI 02915 US\n(401) 270-4541',
    state: 'ri',
  },
  {
    name: 'BETTINA HERBERT, MD',
    link: 'https://www.ifm.org/practitioners/bettina-herbert-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Profile.jpeg-400x400.jpg',
    contactText:
      'Primary Address\n852 Lowcountry Blvd.\nSuite 102, Box 3\nMt. Pleasant, SC 29464 US\n843-929-1634',
    state: 'sc',
  },
  {
    name: 'CAROLINE SMYTHE, MD',
    link: 'https://www.ifm.org/practitioners/caroline-smythe-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/SmytheCaroline99933201-005-cropped1-400x400.jpg',
    contactText:
      'Address\nTelemedicine\nCharleston, SC 29412 US\n(843) 735-0801',
    state: 'sc',
  },
  {
    name: 'CICI CARTER',
    link: 'https://www.ifm.org/practitioners/cici-carter/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/CiCi_Headshot2021-14-400x400.jpg',
    contactText:
      'Primary Address\nTelehealth\nCharleston, SC 29401 US\n6164986050',
    state: 'sc',
  },
  {
    name: 'DEBORAH MANKE',
    link: 'https://www.ifm.org/practitioners/deborah-manke/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Deb-prof-pic-smallest-2017-400x400.jpg',
    contactText:
      'Primary Address\n1050 Johnnie Dodds Blvd.\nSte 1251\nCharleston, SC 29465 US\n843-754-5403',
    state: 'sc',
  },
  {
    name: 'ERIN COPENHAVER',
    link: 'https://www.ifm.org/practitioners/erin-copenhaver/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Erin-Georgetown-2-400x400.jpg',
    contactText: 'Address\nPO Box 20204\nCharleston, SC 29413 US\n843-642.8036',
    state: 'sc',
  },
  {
    name: 'HEATHER SHELTON, MD',
    link: 'https://www.ifm.org/practitioners/heather-shelton/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n611 Second Loop Rd\nFlorence, SC 29506 US\n8437779460',
    state: 'sc',
  },
  {
    name: 'JEANNE W SHIFFMAN, MD',
    link: 'https://www.ifm.org/practitioners/jeanne-w-shiffman-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1000 Laurens Rd\nSte B\nGreenville, SC 29607 US\n8649792441',
    state: 'sc',
  },
  {
    name: 'KATHERINE H. BIRCHENOUGH, MD',
    link: 'https://www.ifm.org/practitioners/katherine-h-birchenough-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/OptimalSelfMD_print-9-400x400.jpg',
    contactText:
      'Address\n25 South Laurens Street\nGreenville, SC 29601 US\n(864) 214-5232',
    state: 'sc',
  },
  {
    name: 'AMY L WAGONER, MD',
    link: 'https://www.ifm.org/practitioners/amy-l-wagoner-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n1014 14th Street SE\nWatertown, SD 57201 US\n605-753-0920',
    state: 'sd',
  },
  {
    name: 'CARRIE J. CARLSON, DO',
    link: 'https://www.ifm.org/practitioners/carrie-jane-carlson-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Carrie-J-Carlson-photo-7_2U6A9848-2-400x400.jpg',
    contactText:
      'Address\n7401 S. Bitterroot Place Suite 301\nSioux Falls, SD 57108 US\n605-271-0619',
    state: 'sd',
  },
  {
    name: 'CHERYL M BONES, DC',
    link: 'https://www.ifm.org/practitioners/cheryl-m-bones/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n2333 W 57th St\nSuite 103\nSioux Falls, SD 57108 US\n605-271-5640',
    state: 'sd',
  },
  {
    name: 'JESSICA MORRELL, CNP',
    link: 'https://www.ifm.org/practitioners/jessica-morrell-msn-cnp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/jess-prof-pic-400x400.jpg',
    contactText: 'Address\nPO Box 651\nBRANDON, SD 57005 US\n605-604-0200',
    state: 'sd',
  },
  {
    name: 'KATIE INGLE',
    link: 'https://www.ifm.org/practitioners/katie-ingle/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/9257098F-5D5A-4178-B711-599E20D09E20-400x400.jpeg',
    contactText:
      'Address\n2820 Mt Rushmore Rd\nRapid City, SD 57701 US\n605-342-3280',
    state: 'sd',
  },
  {
    name: 'OLIVIA BINGAMAN, CNP',
    link: 'https://www.ifm.org/practitioners/olivia-bingaman-msn-aprn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Olivia_07-400x400.jpg',
    contactText:
      'Address\n1760 Centre St\nSte B\nRapid City, SD 57703 US\n(605) 872-0404',
    state: 'sd',
  },
  {
    name: 'WAYNE H CARR, DC',
    link: 'https://www.ifm.org/practitioners/wayne-h-carr-dc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr.-Wayne-H.-Carr-400x400.jpg',
    contactText:
      'Address\n2065 Campbell Dr\nHuron, SD 57350 US\n(605) 352-5264',
    state: 'sd',
  },
  {
    name: 'ANGELINA SZUCH',
    link: 'https://www.ifm.org/practitioners/angelina-szuch/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Angelina_02-cropped-400x400.jpg',
    contactText: '855-765-0855',
    state: 'tn',
  },
  {
    name: 'ASHLEY WOODS, M.D.',
    link: 'https://www.ifm.org/practitioners/ashley-woods-m-d/',
    avatar: 'https://www.ifm.org/wp-content/uploads/ashley-woods-400x400.jpg',
    contactText:
      'Address\n103 Continental Place\nSuite 300\nBrentwood, TN 37027 US\n615-370-0094',
    state: 'tn',
  },
  {
    name: 'DAVID H HAASE, MD, BCN, IFMC-MD, DABFM, DABOIM',
    link: 'https://www.ifm.org/practitioners/david-haase-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2017-09-07_1614-small-400x400.png',
    contactText:
      'Address\n103 Continental Place\nSuite 300\nBrentwood, TN 37027 US\n6153700091',
    state: 'tn',
  },
  {
    name: 'DAWN BEAULIEU, MD',
    link: 'https://www.ifm.org/practitioners/dawn-beaulieu/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Beaulieu-headshot-2018-400x400.jpg',
    contactText: 'Address\n1211 21st Avenue South\nNashville, TN 37212 US',
    state: 'tn',
  },
  {
    name: 'DEB YORK',
    link: 'https://www.ifm.org/practitioners/debra-york/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Deb-2-400x400.jpg',
    contactText:
      'Address\n922 Harpeth Valley Place\nSuite 2\nNashville, TN 37221 US\n615-866-5269',
    state: 'tn',
  },
  {
    name: 'DR. LIBBY ORSBURN, M.D.',
    link: 'https://www.ifm.org/practitioners/elizabeth-orsburn-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Libby-Orsburn-21-WEB4-400x400.jpg',
    contactText:
      'Address\n119 Seaboard Lane, Suite 401\nFranklin, TN 37067 US\n615-606-2808',
    state: 'tn',
  },
  {
    name: 'EMILY LYNN SPRING, PA-C',
    link: 'https://www.ifm.org/practitioners/emily-spring/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/SpringFam-3613-copy-400x400.jpg',
    contactText:
      'Address\n1601 Riverside Drive\nSuite C\nNashville, TN 37216 US\n615-241-0212',
    state: 'tn',
  },
  {
    name: 'ERIC POTTER, MD',
    link: 'https://www.ifm.org/practitioners/eric-potter-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Eric_Potter_headshot-web-400x400.jpg',
    contactText:
      'Address\n120 Holiday Court\nSuite 2\nFranklin, TN 37067 US\n(615) 721-2001',
    state: 'tn',
  },
  {
    name: 'GAIL GAGNON, DO',
    link: 'https://www.ifm.org/practitioners/gail-gagnon-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2012-dr-gail-portrait-400x400.jpg',
    contactText: '(312) 919-1878',
    state: 'tn',
  },
  {
    name: 'HANS HINTERKOPF',
    link: 'https://www.ifm.org/practitioners/hans-hinterkopf-pa-c/',
    avatar: 'https://www.ifm.org/wp-content/uploads/hansphoto2-400x400.jpg',
    contactText:
      'Address\n100 Academy St.\nDickson, TN 37055-1836 US\n(931) 623 - 4203',
    state: 'tn',
  },
  {
    name: 'JESSICA BRIERE',
    link: 'https://www.ifm.org/practitioners/jessica-briere/',
    avatar: 'https://www.ifm.org/wp-content/uploads/810A5021-400x400.jpg',
    contactText:
      'Address\n603 Hwy 321 N\nBldg 3 Ste 202\nLenoir City, TN 37771 US\n865-269-2552',
    state: 'tn',
  },
  {
    name: 'JUZER HUSAINI, DO, IFMC-DO, FAIHM',
    link: 'https://www.ifm.org/practitioners/juzer-husaini/',
    avatar: 'https://www.ifm.org/wp-content/uploads/untitled-52-400x400.jpg',
    contactText:
      'Primary Address\n4115 Mallory Lane\nSuite 100-A\nFranklin, TN 37067 US\n615-669-2791',
    state: 'tn',
  },
  {
    name: 'LELA IDUNA, MD',
    link: 'https://www.ifm.org/practitioners/lela-iduna-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Lela-Iduna-400x400.jpg',
    contactText:
      'Address\n3020 Tazewell pike\nKnoxville , TN 37918 US\n865-275-5024',
    state: 'tn',
  },
  {
    name: 'LESLIE MULLINS, PA-C',
    link: 'https://www.ifm.org/practitioners/leslie-harmoush/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/19BD860A-255B-4AC5-A923-41FB641076DE-400x400.jpeg',
    contactText: 'Address\n1325 Duns Lane\nNolensville , TN 37135 US',
    state: 'tn',
  },
  {
    name: 'LEXI WATSON, PHARMD',
    link: 'https://www.ifm.org/practitioners/lexi-watson/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Email-Signature-Photo_500x500-400x400.jpg',
    contactText: '256-384-4434',
    state: 'tn',
  },
  {
    name: 'LINDSEY CARR, DC, CACCP, IFMCP, DABCI',
    link: 'https://www.ifm.org/practitioners/lindsey-carr-dc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/C69116A8-73BA-46DD-8B4E-3533CD46119A-400x400.jpeg',
    contactText: 'Address\n204 N. Evergreen\nMemphis, TN 38112 US\n9017264523',
    state: 'tn',
  },
  {
    name: 'TONYA LAHATTE',
    link: 'https://www.ifm.org/practitioners/tonya-lahatte/',
    avatar: 'https://www.ifm.org/wp-content/uploads/ifmpicture-400x400.png',
    contactText:
      'Address\n1612 Westgate Cir\nSuite 212\nBrentwood, TN 37027 US\n615-927-4281',
    state: 'tn',
  },
  {
    name: 'AMANDA JOHN',
    link: 'https://www.ifm.org/practitioners/amanda-john/',
    avatar: 'https://www.ifm.org/wp-content/uploads/DSC_0169-400x400.jpg',
    contactText:
      'Address\n18720 Stone Oak Parkway Suite 201\nSan Antonio, TX 78258 US\n210-888-1817',
    state: 'tx',
  },
  {
    name: 'ANAND BHATT, MD',
    link: 'https://www.ifm.org/practitioners/anand-bhatt-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n400 W. I-635 Suite 250\nIrving, TX 75063 US\n9724816400',
    state: 'tx',
  },
  {
    name: 'ANN SHIPPY, MD',
    link: 'https://www.ifm.org/practitioners/ann-shippy/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Web-Ann_Shippy-0104-Edit-400x400.jpg',
    contactText:
      'Address\n6836 Bee Cave Road\nSuite 114\nAustin, TX 78746 US\n(512) 732-9975',
    state: 'tx',
  },
  {
    name: 'BETTY MURRAY, PHD-C, MS, CN, IFMCP',
    link: 'https://www.ifm.org/practitioners/betty-murray-cn/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Betty-Headhot-in-Dress-400x400.png',
    contactText:
      'Address\n17000 Preston Rd\nSuite 400\nDallas, TX 75248 US\n(972) 930-0260',
    state: 'tx',
  },
  {
    name: 'BRONWEN MARTIN',
    link: 'https://www.ifm.org/practitioners/bronwen-louise-martin/',
    avatar: 'https://www.ifm.org/wp-content/uploads/smallheadshot-400x400.jpg',
    contactText:
      'Address\n5318 Weslayan St.\nHouston, TX 77005 US\n281-849-4611',
    state: 'tx',
  },
  {
    name: 'CHARLOTTE WIDICK',
    link: 'https://www.ifm.org/practitioners/charlotte-widick/',
    avatar: 'https://www.ifm.org/wp-content/uploads/CWthumb-400x400.jpg',
    contactText:
      'Address\n19026 Ridgewood Parkway, Suite 119\nSan Antonio, TX 78259 US\n2104683042',
    state: 'tx',
  },
  {
    name: 'CHELSEE ALBO, RN IFMCP',
    link: 'https://www.ifm.org/practitioners/chelsee-elizabeth-albo-rn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IV.draw_-400x400.jpg',
    contactText:
      'Address\n1409 Botham Jean Blvd\nUnit 306\nDallas, TX 75209 US\n469-887-1986',
    state: 'tx',
  },
  {
    name: 'CHERYL WINTER, DCN, FNP-BC, APRN, RDN',
    link: 'https://www.ifm.org/practitioners/cheryl-winter-fnp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/cheryl-winter-labcoat-with-fusia-dres-cropped728-KBy-400x400.jpg',
    contactText:
      'Address\n303 Longmire Rd.\nSuite #1001\nConroe, TX 77304 US\n(832) 327-9332',
    state: 'tx',
  },
  {
    name: "CHRISTINA O'BRIEN, RD, DACNB",
    link: 'https://www.ifm.org/practitioners/christina-obrien-rd-dacnb/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/DrOBrienHeadshot-400x400.jpg',
    contactText:
      'Address\n860 Hebron Parkway Suite 1001\nLewisville, TX 75057 US\n(972) 315-9900',
    state: 'tx',
  },
  {
    name: 'CRISTINA MIMS',
    link: 'https://www.ifm.org/practitioners/cristina-mims/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n121 Vision Park Blvd\n#200\nShenandoah, TX 77384 US\n(281) 725-6767',
    state: 'tx',
  },
  {
    name: 'DAVID BRUCE CLARKE, MD',
    link: 'https://www.ifm.org/practitioners/david-bruce-clarke-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Clarke-bw-web-400x400.jpg',
    contactText: 'Address\n911 23rd Street\nCanyon, TX 79015 US\n806-655-2104',
    state: 'tx',
  },
  {
    name: 'DEBORAH ZEBOLD BAIN, MD',
    link: 'https://www.ifm.org/practitioners/deborah-bain-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n5680 Frisco Square Blvd\nSuite 2300\nFrisco, TX 75034 US\n(972) 294-0808',
    state: 'tx',
  },
  {
    name: 'DEBRA HUMMEL',
    link: 'https://www.ifm.org/practitioners/debra-hummel/',
    avatar: 'https://www.ifm.org/wp-content/uploads/headshot-10-400x400.jpg',
    contactText: 'Address\n18 College Dr\nDallas, TX 75028 US',
    state: 'tx',
  },
  {
    name: 'DEREK GUILLORY, MD',
    link: 'https://www.ifm.org/practitioners/derek-guillory-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/LOGO-image-400x400.png',
    contactText:
      'Address\n19026 Ridgewood Parkway\nSuite 119\nSAN ANTONIO, TX 78259 US\n(210) 468-3042',
    state: 'tx',
  },
  {
    name: 'DONALD ELLSWORTH, MD',
    link: 'https://www.ifm.org/practitioners/donald-ellsworth/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/IFM-Headshot-1-400x400.jpeg',
    contactText:
      'Address\n20214 Braidwood Dr.\nKaty, TX 77450 US\n281-579-3600',
    state: 'tx',
  },
  {
    name: 'ELIZABETH SUAREZ-CEDENO, MD',
    link: 'https://www.ifm.org/practitioners/elizabeth-suarez-cedeno/',
    avatar: 'https://www.ifm.org/wp-content/uploads/profilePIC2-400x400.jpg',
    contactText:
      'Address\nDOCISHERE.COM\nCollege Station, TX 77845 US\n(979) 571-1967',
    state: 'tx',
  },
  {
    name: 'EMILY GUTIERREZ, DNP, CPNP, IFMCP, CCN',
    link: 'https://www.ifm.org/practitioners/emily-gutierrez-dnp-cpnp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_2890-400x400.jpg',
    contactText:
      'Address\n5501 Balcones Drive\nSuite A, #305\nAustin, TX 78731 US\n5125998850',
    state: 'tx',
  },
  {
    name: 'GABRIA A CATHCART',
    link: 'https://www.ifm.org/practitioners/gabria-a-cathcart-msn-fnp-c/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/gabria-headshot-black-shirt-2-400x400.jpg',
    contactText:
      'Address\n13590 Ranch Road 12\nWimberley, TX 78676 US\n512-887-1817',
    state: 'tx',
  },
  {
    name: 'GWEN K SHIPE, MD',
    link: 'https://www.ifm.org/practitioners/gwen-k-shipe-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/gwen-2017-photo-400x400.jpg',
    contactText:
      'Address\n17000 Preston Road\nBldg 4, Ste 400\nDallas, TX 75248 US\n9729300260 ext 7',
    state: 'tx',
  },
  {
    name: 'HANNAH MARIE HAMLIN, DO',
    link: 'https://www.ifm.org/practitioners/hannah-marie-hamlin/',
    avatar: 'https://www.ifm.org/wp-content/uploads/headshot-4-400x400.jpeg',
    contactText: 'Address\n1201 Lady Bird Ln\nAustin, TX 78741 US\n8322291048',
    state: 'tx',
  },
  {
    name: 'IMRAN KHAN',
    link: 'https://www.ifm.org/practitioners/imran-khan/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Imran-Khan022-headshot1-1-400x400.jpg',
    contactText:
      'Address\n8708 Technology Forest Pl\nSuite 125-G\nThe Woodlands, TX 77381 US\n(281) 475-7845',
    state: 'tx',
  },
  {
    name: 'INDRANI B RAMAN, MD',
    link: 'https://www.ifm.org/practitioners/indrani-b-raman/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Blue-dress-pic-400x400.jpg',
    contactText:
      'Primary Address\n150 Brand Road\nSuite 100\nMurphy, TX 75094 US\n9727523199',
    state: 'tx',
  },
  {
    name: 'JAY MARSOLAN',
    link: 'https://www.ifm.org/practitioners/jay-marsolan-msn-fnp-c/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Jay1-400x400.jpg',
    contactText:
      'Address\n2834 Bill Owens Parkway\nLongview, TX 75605 US\n903-309-1109',
    state: 'tx',
  },
  {
    name: 'JEANNE W COOK, MD',
    link: 'https://www.ifm.org/practitioners/jeanne-cook-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/06_150811_BrendaLaddPhoto_5x7_V3-Copy-2-400x400.jpg',
    contactText: '512-387-5813',
    state: 'tx',
  },
  {
    name: 'LARA SWEENEY, DC',
    link: 'https://www.ifm.org/practitioners/jennelle-l-sweeney-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Lara-400x400.jpg',
    contactText: 'Address\nHuebner Rd\nSan Antonio, TX 78248 US\n210-844-0277',
    state: 'tx',
  },
  {
    name: 'JENNIFER ENGELS, MD',
    link: 'https://www.ifm.org/practitioners/jennifer-engels-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Engels_9286-400x400.jpg',
    contactText:
      'Address\n9555 Lebanon Rd\nSte 701\nFrisco, TX 75035 US\n9726682636',
    state: 'tx',
  },
  {
    name: 'JENNIFER R KESSMANN, MD',
    link: 'https://www.ifm.org/practitioners/jennifer-r-kessmann-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/DE9E4667-A524-4567-8BC9-FA6680E31530-400x400.jpeg',
    contactText:
      'Primary Address\n12240 Inwood RD STE 500\nDallas, TX 75244-8007 US\n8179121289',
    state: 'tx',
  },
  {
    name: 'JEREMY SMITH, MD',
    link: 'https://www.ifm.org/practitioners/jeremy-smith-2/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Jeremy-Profile-pic-400x400.jpg',
    contactText:
      'Primary Address\n3618 N. University Dr.\nNacogdoches, TX 75965 US\n9362059922',
    state: 'tx',
  },
  {
    name: 'JOHN ROGERS, MD',
    link: 'https://www.ifm.org/practitioners/john-rogers-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IFM-2020-4-400x400.jpg',
    contactText:
      'Address\n17189 IH45S\nSte 245\nThe Woodlands, TX 77385 US\n9362703865',
    state: 'tx',
  },
  {
    name: 'JULIA ANN LIACI, MD',
    link: 'https://www.ifm.org/practitioners/julia-a-liaci-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n1105 N. Central Expressway\nSuite 2310\nAllen, TX 75013 US\n(214) 271-4600',
    state: 'tx',
  },
  {
    name: 'JULIE ANN REARDON, MD',
    link: 'https://www.ifm.org/practitioners/julie-ann-reardon-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Web-Julie_Reardon-0180-Edit.jpg62017HeadShot-400x400.jpg',
    contactText:
      'Address\n1313 Ranch Road 620 South Suite 203\nSuite 203\nLakeway, TX 78734 US\n5128506963',
    state: 'tx',
  },
  {
    name: 'JULIE LONG',
    link: 'https://www.ifm.org/practitioners/julie-long-whnp/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n9442 Capital of Texas Hwy N.\nArboretum Plaza One, Suite 500\nAustin, TX 78759 US\n(512) 343-3665',
    state: 'tx',
  },
  {
    name: 'KAREN CHEN, MD',
    link: 'https://www.ifm.org/practitioners/karen-chen-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_6242-400x400.jpg',
    contactText:
      'Address\n7400 Merton Minter\nSan Antonio, TX 78229 US\n(210) 617-5300',
    state: 'tx',
  },
  {
    name: 'KINA KHATRI, MD',
    link: 'https://www.ifm.org/practitioners/kinnari-khatri/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/9F21A00D-0422-46A0-8949-5199AA011D9D-400x400.jpeg',
    contactText:
      'Address\n4400 State Hwy 121 Suite 300 #021S\nLewisville , TX 75056 US\n469-6479691',
    state: 'tx',
  },
  {
    name: 'KRISTY L CLINTON-HODGES, DC',
    link: 'https://www.ifm.org/practitioners/kristy-l-clinton-ms/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Web-Kristy_Clinton-0429-Edit-400x400.jpg',
    contactText:
      'Address\n3109 Kenai Drive Ste 101\nSte 101\nCedar Park, TX 78613-2540 US\n512-363-5178',
    state: 'tx',
  },
  {
    name: 'LAURA L. MENDEZ',
    link: 'https://www.ifm.org/practitioners/laura-l-mendez-rn-whcnp/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n11439 Spring Cypress Rd., Bldg. D\nTomball, TX 77377 US\n(281) 257-2900',
    state: 'tx',
  },
  {
    name: 'LAURIE L ROBERTSON',
    link: 'https://www.ifm.org/practitioners/laurie-l-robertson/',
    avatar: 'https://www.ifm.org/wp-content/uploads/laurie-400x400.jpg',
    contactText:
      'Primary Address\n17183 IH-45 South\nMOB 1 Suite 650\nThe Woodlands, TX 77385 US\n936-270-3800',
    state: 'tx',
  },
  {
    name: 'LEILA DOOLITTLE, DC, IFMCP, CFMP, CLS, LAC',
    link: 'https://www.ifm.org/practitioners/leila-doolittle-dc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Standing-head-shot-small-400x400.png',
    contactText:
      'Address\n7979 Inwood Rd, Suite 225\nDallas, TX 75209 US\n+1(469) 759-9457',
    state: 'tx',
  },
  {
    name: 'LISA KIMBELL, MD',
    link: 'https://www.ifm.org/practitioners/lisa-kimbell-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/kimbell-084-copy-400x400.jpg',
    contactText:
      'Primary Address\n9515 Windfern Rd\nHouston, TX 77064 US\n281-305-8104',
    state: 'tx',
  },
  {
    name: 'MARGARET APOSTOL, MD',
    link: 'https://www.ifm.org/practitioners/margaret-apostol/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Margaret-Apostol2-400x400.jpg',
    contactText:
      'Primary Address\n400 Parker Square\nSuite 245\nFlower Mound, TX 75028 US\n469-470-0726',
    state: 'tx',
  },
  {
    name: 'MARGARET CHRISTENSEN, MD',
    link: 'https://www.ifm.org/practitioners/margaret-christensen-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n10260 N Central Expressway\nSuite 210\nDallas, TX 75231 US\n469-729-6460',
    state: 'tx',
  },
  {
    name: 'MARY HEBERT, MD',
    link: 'https://www.ifm.org/practitioners/mary-hebert-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n4848 NE Stallings Drive Ste 100\nNacogdoches, TX 75965 US\n(936) 462-4214',
    state: 'tx',
  },
  {
    name: 'MAULI RAJESH DALAL',
    link: 'https://www.ifm.org/practitioners/mauli-rajesh-dalal/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n192 Abner Jackson Parkway\nLake Jackson, TX 77566 US\n9792850007',
    state: 'tx',
  },
  {
    name: 'MAXINE THOMAS, MD',
    link: 'https://www.ifm.org/practitioners/maxine-thomas-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n4725 Wellington St\nGreenville, TX 75401 US\n9034509120',
    state: 'tx',
  },
  {
    name: 'MEAGAN MCDONALD',
    link: 'https://www.ifm.org/practitioners/meagan-mcdonald-agnp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/new-picture-1-400x400.jpg',
    contactText:
      'Address\n2834 Bill Owens Pkwy\nLongview, TX 75605 US\n903 309 1109',
    state: 'tx',
  },
  {
    name: 'MEGAN MCELROY, MS',
    link: 'https://www.ifm.org/practitioners/megan-mcelroy-ms-pa-c/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Cropped-Image-for-Letters-400x400.png',
    contactText: '',
    state: 'tx',
  },
  {
    name: 'MIIKO ROWLEY, MD',
    link: 'https://www.ifm.org/practitioners/miiko-rowley/',
    avatar: 'https://www.ifm.org/wp-content/uploads/For-IFM-site-2-400x400.jpg',
    contactText:
      'Address\n230 Westcott St.\nSuite 208\nTX, TX 77007 US\n7136606620',
    state: 'tx',
  },
  {
    name: 'MINNI MALHOTRA, MD',
    link: 'https://www.ifm.org/practitioners/minni-malhotra/',
    avatar: 'https://www.ifm.org/wp-content/uploads/MWI_7884-400x400.jpg',
    contactText:
      'Primary Address\n26111 Oakridge Drive\nSpring, TX 77380 US\n(832) 246-8437',
    state: 'tx',
  },
  {
    name: 'MONICA JAUREGUI',
    link: 'https://www.ifm.org/practitioners/monica-jauregui/',
    avatar: 'https://www.ifm.org/wp-content/uploads/MJ-smaller-400x400.jpg',
    contactText:
      'Address\n1910 Pacific Ave\nSuite 2000 PMB 1147\nDallas, TX 75201 US',
    state: 'tx',
  },
  {
    name: 'NANDANA MAPAKSHI, MD',
    link: 'https://www.ifm.org/practitioners/nandana-mapakshi-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Nandana-website-picture-400x400.jpg',
    contactText: '972-370-3375',
    state: 'tx',
  },
  {
    name: 'PAYAL PATEL',
    link: 'https://www.ifm.org/practitioners/payal-patel/',
    avatar: 'https://www.ifm.org/wp-content/uploads/P1-400x400.jpg',
    contactText: 'Address\nVirtual practice\nFrisco, TX 75036 US\n469-527-5497',
    state: 'tx',
  },
  {
    name: 'RACHEL CONNER',
    link: 'https://www.ifm.org/practitioners/rachel-conner/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/professionalpic-400x400.jpg',
    contactText: '945-234-1012',
    state: 'tx',
  },
  {
    name: 'REBECCA D FORD, ND',
    link: 'https://www.ifm.org/practitioners/rebecca-d-ford-nd-ms-rn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Rebecca-Ford-400x400.png',
    contactText:
      'Address\n8000 I-10 W\nSuite 600\nSan Antonio, TX 78230 US\n(210) 524-7745',
    state: 'tx',
  },
  {
    name: 'ROSA N SCHNYER',
    link: 'https://www.ifm.org/practitioners/rosa-n-schnyer-daom/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Rosa-08_C_BW-cr-1200x1343-1-400x400.jpg',
    contactText:
      'Address\n4131 Spicewood Springs Road\nD5\nAUSTIN, TX 78759 US\n5127436321',
    state: 'tx',
  },
  {
    name: 'SHANA TATUM',
    link: 'https://www.ifm.org/practitioners/shana-tatum/',
    avatar: 'https://www.ifm.org/wp-content/uploads/DSC_3475-400x400.jpg',
    contactText:
      'Address\n104 W. 12th St. Suite B\nHouston, TX 77008 US\n713-868-4372',
    state: 'tx',
  },
  {
    name: 'SHEILA PATTERSON, MD',
    link: 'https://www.ifm.org/practitioners/sheila-patterson/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_4320-400x400.jpg',
    contactText:
      'Address\n4601 Buffalo Gap Road\nAbilene, TX 79606 US\n325 704 5065',
    state: 'tx',
  },
  {
    name: 'SHEZI KIRMANI, MD',
    link: 'https://www.ifm.org/practitioners/shezi-kirmani/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Capture-400x400.png',
    contactText:
      'Address\n1700 Post Oak Blvd\nSuite 600\nHouston, TX 77056 US\n(346) 240-4911',
    state: 'tx',
  },
  {
    name: 'STEPHEN M CLOUTHIER, DC',
    link: 'https://www.ifm.org/practitioners/stephen-m-clouthier/',
    avatar: 'https://www.ifm.org/wp-content/uploads/image-4-400x400.png',
    contactText: '2817998967',
    state: 'tx',
  },
  {
    name: 'SUSAN ATTEL, FNP',
    link: 'https://www.ifm.org/practitioners/susan-attel-rn-msn-fnp-bc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Headshot-smaller-400x400.jpg',
    contactText:
      'Address\n10260 North Central Expressway, Suite 210\nDallas, TX 75231 US\n469-729-6460',
    state: 'tx',
  },
  {
    name: 'SUZANNE ELLISON, MD',
    link: 'https://www.ifm.org/practitioners/suzanne-ellison-ramsay/',
    avatar: 'https://www.ifm.org/wp-content/uploads/5022-1-400x400.jpg',
    contactText:
      'Primary Address\n200 WEST WINDCREST, 200\nFREDERICKSBURG, TX 78624 US\n830-304-3788',
    state: 'tx',
  },
  {
    name: 'SUZANNE STOVALL, DO',
    link: 'https://www.ifm.org/practitioners/suzanne-stovall-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/ssifmr-Copy-400x400.jpg',
    contactText:
      'Address\n17183 Interstate 45 S\nSuite 650\nThe Woodlands, TX 77385 US\n(936) 270-3891',
    state: 'tx',
  },
  {
    name: 'THERESA HERNANDEZ',
    link: 'https://www.ifm.org/practitioners/theresa-hernandez/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Web-Theresa_Hernandez-4325-Edit-400x400.jpg',
    contactText:
      'Address\n3595 RR 620 South\nSuite 150\nBee Cave, TX 78738 US\n5127815615',
    state: 'tx',
  },
  {
    name: 'THERESA MARIE FONTENOT',
    link: 'https://www.ifm.org/practitioners/theresa-m-fontenot-pa-c/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Untitled-design-5-400x400.jpg',
    contactText:
      'Address\n3939 Texas Blvd\nTexarkana, TX 75503-3207 US\n(903) 255-6398',
    state: 'tx',
  },
  {
    name: 'TONI BOYAJIAN, DNP',
    link: 'https://www.ifm.org/practitioners/toni-boyajian-msn/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n1801 Binz St STE 400\nHouston, TX 77004 US\n(281) 725-6767',
    state: 'tx',
  },
  {
    name: "TRICIA JUDITH O'BRIEN, MD",
    link: 'https://www.ifm.org/practitioners/tricia-judith-obrien-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2019-07-11-at-10.44.55-AM-400x400.png',
    contactText:
      'Address\n4645 Avon Lane\nSuite 110B\nFrisco, TX 75033 US\n(214) 631-9321',
    state: 'tx',
  },
  {
    name: 'VIVIAN ABENA ASAMOAH',
    link: 'https://www.ifm.org/practitioners/vivian-asamoah/',
    avatar: 'https://www.ifm.org/wp-content/uploads/dr-v-image-400x400.jpg',
    contactText:
      'Address\n25230 Kingsland Boulevard\nSte, 101\nKaty, TX 77494 US\n2817469284',
    state: 'tx',
  },
  {
    name: 'ALYSSA VEST',
    link: 'https://www.ifm.org/practitioners/alyssa-vest-fnp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/IMG_20170223_141531_156-400x400.jpg',
    contactText:
      'Address\n55 N Center Street\nAmerican Fork, UT 84003 US\n435-485-7887',
    state: 'ut',
  },
  {
    name: 'BRIAN J. RODGERS, DO',
    link: 'https://www.ifm.org/practitioners/brian-j-rodgers-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/IMG_87371-cropped-bjr-photo-400x400.jpg',
    contactText:
      'Primary Address\n1568 S 500 W\nSte 101\nBountiful, UT 84010 US\n801-874-2388',
    state: 'ut',
  },
  {
    name: 'DANIEL ANGERBAUER MD',
    link: 'https://www.ifm.org/practitioners/daniel-angerbauer/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2022-05-19-silver-lake-04043-copy-400x400.jpg',
    contactText: 'Address\nTelemedicine Practice\n, UT US\n305-707-7045',
    state: 'ut',
  },
  {
    name: 'DREW CHRISTENSEN, MD',
    link: 'https://www.ifm.org/practitioners/drew-christensen-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/1ee89e4.jpg',
    contactText:
      'Address\n380 E Main Street, Building B, Suite 120\nMidway , UT 84049 US\n435-214-7282',
    state: 'ut',
  },
  {
    name: 'ELLOIS W BAILEY',
    link: 'https://www.ifm.org/practitioners/ellois-w-bailey-pmh-np/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Bailey_ElLois_Head2-2-400x400.jpg',
    contactText:
      'Address\n9035 South 1300 East Suite 120\nSANDY, UT 84094 US\n801-455-0045',
    state: 'ut',
  },
  {
    name: 'EMILY POFF',
    link: 'https://www.ifm.org/practitioners/emily-poff/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Address\n48 W 1500 N\nNephi, UT 84648 US\n(435) 623-3211',
    state: 'ut',
  },
  {
    name: 'FATIMA VILICH, MD',
    link: 'https://www.ifm.org/practitioners/fatima-vilich-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Red-Jacket-400x400.jpg',
    contactText:
      'Address\n1910 Prospector Avenue\nSuite 302\nPark City, UT 84060 US\n435-782-9384',
    state: 'ut',
  },
  {
    name: 'JULIE L ROBERTS, NP',
    link: 'https://www.ifm.org/practitioners/julie-l-roberts-np/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/B29D6807-C841-4F4D-99A5-FC409CE15863-400x400.jpeg',
    contactText:
      'Address\nLiVe Well Center; Intermountain Salt Lake Clinic\n389 S. 900 E.\nSalt Lake City, UT 84102 US\n385-282-2700',
    state: 'ut',
  },
  {
    name: "KATHY O'MARA, MD",
    link: 'https://www.ifm.org/practitioners/kathy-omara/',
    avatar: 'https://www.ifm.org/wp-content/uploads/komd-400x400.jpg',
    contactText:
      'Address\n7136 S 2000 E\nCottonwood Heights, UT 84121 US\n801-942-1800',
    state: 'ut',
  },
  {
    name: 'LISA HILL, MD',
    link: 'https://www.ifm.org/practitioners/lisa-hill-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Plant-logo-only-400x400.jpg',
    contactText:
      'Address\n1275 E Fort Union Blvd\nSuite 220\nCottonwood Heights, UT 84047 US\n385-246-1117',
    state: 'ut',
  },
  {
    name: 'MERIN KINIKINI KINIKINI',
    link: 'https://www.ifm.org/practitioners/merin-marie-kinikini-dnp-fnp-rd/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Merin-Picture2-400x400.jpg',
    contactText:
      'Primary Address\n5171 South Cottonwood St\nSalt Lake City, UT 84107 US\n(801) 507-4945',
    state: 'ut',
  },
  {
    name: 'RAY LYNN CARLSON, MD',
    link: 'https://www.ifm.org/practitioners/r-lynn-carlson-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Charises-Favorite-Pic-of-Dr.-Carlson-for-Clinic_narrower-400x400.jpeg',
    contactText:
      'Address\n2135 W Main St Suite B107\nLehi, UT 84043 US\n801-228-1906',
    state: 'ut',
  },
  {
    name: 'TERI UNDERWOOD, RD',
    link: 'https://www.ifm.org/practitioners/teri-underwood-rd-ms-cd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/1-1-1-1-2015-Profile-PIc-400x400.jpg',
    contactText: '(801) 831-6967',
    state: 'ut',
  },
  {
    name: 'TRICIA PETZOLD, MD',
    link: 'https://www.ifm.org/practitioners/tricia-petzold-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Tricia-106-400x400.jpeg',
    contactText:
      'Address\n1515 South 1100 East\nSalt Lake City, UT 84105 US\n801-583-5692',
    state: 'ut',
  },
  {
    name: 'ALYSSA VEST',
    link: 'https://www.ifm.org/practitioners/alyssa-vest-fnp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/IMG_20170223_141531_156-400x400.jpg',
    contactText:
      'Address\n55 N Center Street\nAmerican Fork, UT 84003 US\n435-485-7887',
    state: 'ut',
  },
  {
    name: 'BRIAN J. RODGERS, DO',
    link: 'https://www.ifm.org/practitioners/brian-j-rodgers-do/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/IMG_87371-cropped-bjr-photo-400x400.jpg',
    contactText:
      'Primary Address\n1568 S 500 W\nSte 101\nBountiful, UT 84010 US\n801-874-2388',
    state: 'ut',
  },
  {
    name: 'DANIEL ANGERBAUER MD',
    link: 'https://www.ifm.org/practitioners/daniel-angerbauer/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2022-05-19-silver-lake-04043-copy-400x400.jpg',
    contactText: 'Address\nTelemedicine Practice\n, UT US\n305-707-7045',
    state: 'ut',
  },
  {
    name: 'DREW CHRISTENSEN, MD',
    link: 'https://www.ifm.org/practitioners/drew-christensen-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/1ee89e4.jpg',
    contactText:
      'Address\n380 E Main Street, Building B, Suite 120\nMidway , UT 84049 US\n435-214-7282',
    state: 'ut',
  },
  {
    name: 'ELLOIS W BAILEY',
    link: 'https://www.ifm.org/practitioners/ellois-w-bailey-pmh-np/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Bailey_ElLois_Head2-2-400x400.jpg',
    contactText:
      'Address\n9035 South 1300 East Suite 120\nSANDY, UT 84094 US\n801-455-0045',
    state: 'ut',
  },
  {
    name: 'EMILY POFF',
    link: 'https://www.ifm.org/practitioners/emily-poff/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Address\n48 W 1500 N\nNephi, UT 84648 US\n(435) 623-3211',
    state: 'ut',
  },
  {
    name: 'FATIMA VILICH, MD',
    link: 'https://www.ifm.org/practitioners/fatima-vilich-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Red-Jacket-400x400.jpg',
    contactText:
      'Address\n1910 Prospector Avenue\nSuite 302\nPark City, UT 84060 US\n435-782-9384',
    state: 'ut',
  },
  {
    name: 'JULIE L ROBERTS, NP',
    link: 'https://www.ifm.org/practitioners/julie-l-roberts-np/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/B29D6807-C841-4F4D-99A5-FC409CE15863-400x400.jpeg',
    contactText:
      'Address\nLiVe Well Center; Intermountain Salt Lake Clinic\n389 S. 900 E.\nSalt Lake City, UT 84102 US\n385-282-2700',
    state: 'ut',
  },
  {
    name: "KATHY O'MARA, MD",
    link: 'https://www.ifm.org/practitioners/kathy-omara/',
    avatar: 'https://www.ifm.org/wp-content/uploads/komd-400x400.jpg',
    contactText:
      'Address\n7136 S 2000 E\nCottonwood Heights, UT 84121 US\n801-942-1800',
    state: 'ut',
  },
  {
    name: 'LISA HILL, MD',
    link: 'https://www.ifm.org/practitioners/lisa-hill-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Plant-logo-only-400x400.jpg',
    contactText:
      'Address\n1275 E Fort Union Blvd\nSuite 220\nCottonwood Heights, UT 84047 US\n385-246-1117',
    state: 'ut',
  },
  {
    name: 'MERIN KINIKINI KINIKINI',
    link: 'https://www.ifm.org/practitioners/merin-marie-kinikini-dnp-fnp-rd/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Merin-Picture2-400x400.jpg',
    contactText:
      'Primary Address\n5171 South Cottonwood St\nSalt Lake City, UT 84107 US\n(801) 507-4945',
    state: 'ut',
  },
  {
    name: 'RAY LYNN CARLSON, MD',
    link: 'https://www.ifm.org/practitioners/r-lynn-carlson-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Charises-Favorite-Pic-of-Dr.-Carlson-for-Clinic_narrower-400x400.jpeg',
    contactText:
      'Address\n2135 W Main St Suite B107\nLehi, UT 84043 US\n801-228-1906',
    state: 'ut',
  },
  {
    name: 'TERI UNDERWOOD, RD',
    link: 'https://www.ifm.org/practitioners/teri-underwood-rd-ms-cd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/1-1-1-1-2015-Profile-PIc-400x400.jpg',
    contactText: '(801) 831-6967',
    state: 'ut',
  },
  {
    name: 'TRICIA PETZOLD, MD',
    link: 'https://www.ifm.org/practitioners/tricia-petzold-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Tricia-106-400x400.jpeg',
    contactText:
      'Address\n1515 South 1100 East\nSalt Lake City, UT 84105 US\n801-583-5692',
    state: 'ut',
  },
  {
    name: 'JULIE THOMPSON, DO',
    link: 'https://www.ifm.org/practitioners/julie-thompson/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/2A318697-B362-49E9-841A-E76085FD06C4-400x400.jpeg',
    contactText:
      'Address\n1775 Williston Rd\nSuite 110\nSouth Burlington, VT 05403 US\n802-735-1252',
    state: 'vt',
  },
  {
    name: 'ROBYN JACOBS, MD',
    link: 'https://www.ifm.org/practitioners/robyn-jacobs-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n18 Justin Morrill Mem Hwy\nS Strafford, VT 05070 US\n603 448 7344',
    state: 'vt',
  },
  {
    name: 'AARON HARTMAN, MD',
    link: 'https://www.ifm.org/practitioners/aaron-hartman-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/AaronHartman-Square-400x400.jpg',
    contactText:
      'Address\n1 Park West Circle\nSte 301\nMidlothian, VA 23114 US\n8048933920',
    state: 'va',
  },
  {
    name: 'ANCA SISU, MD',
    link: 'https://www.ifm.org/practitioners/anca-sisu-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Anca-Sisu1-400x400.jpg',
    contactText:
      'Address\n5236 Dawes Ave\nAlexandria, VA 22311 US\n703-215-2454',
    state: 'va',
  },
  {
    name: 'ANISSA RAHMAN, MD',
    link: 'https://www.ifm.org/practitioners/anissa-rahman/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/ANISSA-RAHMAN-005-400x400.jpg',
    contactText: '2145519138',
    state: 'va',
  },
  {
    name: 'APRIL GUMINSKY, MD',
    link: 'https://www.ifm.org/practitioners/april-guminsky/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Dr_April_Guminsky-400x400.jpg',
    contactText:
      'Address\n121 Bulifants Blvd., Suite A\nWilliamsburg, VA 23188 US\n(757) 707-3025',
    state: 'va',
  },
  {
    name: 'CHERYL A BROWN',
    link: 'https://www.ifm.org/practitioners/cheryl-a-brown-dnp-fnp-bc/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n9300 DeWitt Loop\nFt. Belvoir, VA 22060 US\n(571)231-1210',
    state: 'va',
  },
  {
    name: 'CHRISTIAN JENSKI, MD',
    link: 'https://www.ifm.org/practitioners/christian-jenski-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/headshot-1-400x400.jpg',
    contactText:
      'Address\n1 Park West Circle\nSuite 301\nMidlothian, VA 23114 US\n8048933920',
    state: 'va',
  },
  {
    name: 'CYNTHIA D YEW',
    link: 'https://www.ifm.org/practitioners/cynthia-d-yew-msn-np/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/cindy-head-shot-400x400.jpg',
    contactText:
      'Address\n21135 Whitfield Place\nSuite 107\nSterling , VA 20165 US\n7034217000',
    state: 'va',
  },
  {
    name: 'HEATHER MODAY, MD',
    link: 'https://www.ifm.org/practitioners/heather-moday-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/newheadshotblue-copy-400x400.jpg',
    contactText:
      'Primary Address\n358 W Freemason st, 1st floor Suite A\nNorfolk, VA 23510\nNorfolk, VA 23510 US\n2157353552',
    state: 'va',
  },
  {
    name: 'HEATHER RUTH',
    link: 'https://www.ifm.org/practitioners/heather-ruth/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Heather-Web-Edit-150x150-1-400x400.jpg',
    contactText:
      'Primary Address\n1240 North Pitt Street\nOld Town Alexandia, VA 22314 US\n(301) 792-0601',
    state: 'va',
  },
  {
    name: 'JACQUELINE BOONE, MSN',
    link: 'https://www.ifm.org/practitioners/jacqueline-boone/',
    avatar: 'https://www.ifm.org/wp-content/uploads/F69A6367_50-400x400.jpg',
    contactText:
      'Address\n14321 Winter Breeze Drive\nSuite 67\nMidlothian, VA 23113 US\n804-505-3010',
    state: 'va',
  },
  {
    name: 'JANINE DENISE LEX, DC, LPA, IFMCP',
    link: 'https://www.ifm.org/practitioners/janine-lex-dc/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Janine-400x400.png',
    contactText:
      'Address\n3061 Brickhouse Ct\nSuite 107\nVirginia Beach, VA 23452 US\n(757) 491-2598',
    state: 'va',
  },
  {
    name: 'JENNIFER A MCCORD, MD',
    link: 'https://www.ifm.org/practitioners/jennifer-a-mccord-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Jennifer_website_headshot3_sqr-1-400x400.png',
    contactText:
      'Address\n1030 Loftis Blvd\nSuite 101\nNewport News, VA 23606 US\n757-204-5372',
    state: 'va',
  },
  {
    name: 'JOCELYN KILGORE',
    link: 'https://www.ifm.org/practitioners/jocelyn-kilgore/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Jocelyn-Kilgore-155rev-2-1-400x400.jpeg',
    contactText:
      'Address\n5980 9th Street, Bldg 1259\nFt. Belvoir, VA 22060 US',
    state: 'va',
  },
  {
    name: 'KIMBERLY C FRYE',
    link: 'https://www.ifm.org/practitioners/kimberly-c-frye/',
    avatar: 'https://www.ifm.org/wp-content/uploads/headshot-20-400x400.jpg',
    contactText:
      'Address\n2509 George Mason Dr\nUnit 56573\nVirginia Beach, VA 23456 US\n757-550-0829',
    state: 'va',
  },
  {
    name: 'LAURA LAWSON, DO',
    link: 'https://www.ifm.org/practitioners/laura-lawson-do/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n230 Costello Dr.\nSuite 210\nWinchester, VA 22602 US\n(540) 450-5743',
    state: 'va',
  },
  {
    name: 'MARTHA CALIHAN, MD',
    link: 'https://www.ifm.org/practitioners/martha-calihan-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n116-Q Edwards Ferry Road\nLeesburg, VA 20176 US\n(703) 669-6118',
    state: 'va',
  },
  {
    name: 'MARY MIELKE, ND',
    link: 'https://www.ifm.org/practitioners/mary-mielke-mie/',
    avatar: 'https://www.ifm.org/wp-content/uploads/MaryMielke-1-400x400.jpg',
    contactText:
      'Primary Address\n7400 Beaufont Springs Dr\nRichmond, VA 23225 US\n202-935-7433',
    state: 'va',
  },
  {
    name: 'MICHAEL ARTHUR, MD',
    link: 'https://www.ifm.org/practitioners/michael-arthur-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n227 Albemarle Ave SW\nRoanoke, VA 24016 US\n(540) 989-9800',
    state: 'va',
  },
  {
    name: 'MYLENE TRAN HUYNH, MD',
    link: 'https://www.ifm.org/practitioners/mylene-huynh-md-mph/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Mylene-picture_Len-Duong-Camp-400x400.jpg',
    contactText:
      'Address\n8303 Arlington Blvd\nSuite 110\nFairfax, VA 22031 US\n(703) 854-1108',
    state: 'va',
  },
  {
    name: 'PAIGE LYNCH',
    link: 'https://www.ifm.org/practitioners/paige-lynch/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_6605-400x400.jpg',
    contactText: '(540) 412-8181',
    state: 'va',
  },
  {
    name: 'RACHEL MARTIN, NP',
    link: 'https://www.ifm.org/practitioners/rachel-martin/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_2929_jpg-400x400.jpg',
    contactText:
      'Address\n3711 Westerre Pkwy\nUnit C\nRichmond, VA 23233 US\n804-803-1606',
    state: 'va',
  },
  {
    name: 'ROBERT DUCA, DC',
    link: 'https://www.ifm.org/practitioners/robert-duca-dc-ms-dabci/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Primary Address\n2136-D Gallows Road\nDunn Loring, VA 22027 US\n(703) 641-4966',
    state: 'va',
  },
  {
    name: 'SARAH CORCORAN, DC',
    link: 'https://www.ifm.org/practitioners/sarah-corcoran/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Image-6-25-19-at-8.36-AM-400x400.jpg',
    contactText: '216-402-4921',
    state: 'va',
  },
  {
    name: 'SINCLAIR MCCRACKEN, MD',
    link: 'https://www.ifm.org/practitioners/sinclair-mccracken-md/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/SMcCracken-headshot-4x5-400x400.jpg',
    contactText:
      'Address\n704 Thimble Shoals Blvd\nSuite 600A\nNewport News, VA 23606 US\n757-595-5001',
    state: 'va',
  },
  {
    name: 'STEFANIE JENNINGS, FNP-C',
    link: 'https://www.ifm.org/practitioners/stefanie-jennings/',
    avatar: 'https://www.ifm.org/wp-content/uploads/0-copy-400x400.jpeg',
    contactText:
      'Address\n21135 Whitfield Place\nSuite 107\nSterling, VA 20165 US\n703-421-7000',
    state: 'va',
  },
  {
    name: 'TIMOTHY HUDSON, MD',
    link: 'https://www.ifm.org/practitioners/timothy-hudson/',
    avatar: 'https://www.ifm.org/wp-content/uploads/profile-pic-3-400x400.jpg',
    contactText: '804-420-2130',
    state: 'va',
  },
  {
    name: 'TINA RENEE SHIVER, RD',
    link: 'https://www.ifm.org/practitioners/tina-shiver-rd/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Tina-Headshot-400x400.jpg',
    contactText:
      'Address\n5700 W Grace St\nSuite 109\nRichmond, VA 23226 US\n(804) 254-1002',
    state: 'va',
  },
  {
    name: 'AMY ANSTEAD',
    link: 'https://www.ifm.org/practitioners/amy-anstead/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/scrub-headshot-2021-400x400.jpg',
    contactText:
      'Primary Address\n21911 76th Ave W\nSuite 211\nEdmonds, WA 98026 US\n(425) 775-6651',
    state: 'wa',
  },
  {
    name: 'ANNE HARDY',
    link: 'https://www.ifm.org/practitioners/anne-hardy/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/20220219231029_IMG_1311-400x400.jpg',
    contactText:
      'Address\n124th Ave NE PO Box 159\nLake Stevens, WA 98258 US\n4253747552',
    state: 'wa',
  },
  {
    name: 'ARTI CHANDRA, MD',
    link: 'https://www.ifm.org/practitioners/arti-chandra-md-mph/',
    avatar: 'https://www.ifm.org/wp-content/uploads/ACHeadshot-400x400.jpeg',
    contactText: '206-315-7998',
    state: 'wa',
  },
  {
    name: 'BECKY JEAN PRIDEAUX-BLODGETT',
    link: 'https://www.ifm.org/practitioners/becky-jean-prideaux-blodgett-msn-arnp-family/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n7808 Pacific Ave\nsuite 3\nTacoma, WA 98408 US\n2535036178',
    state: 'wa',
  },
  {
    name: 'BONNIE LYNN SPRAGUE',
    link: 'https://www.ifm.org/practitioners/bonnie-lynn-sprague-arnp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/bonnie2cs-400x400.jpg',
    contactText:
      'Address\n1050 Larrabee Ave\nSuite 204\nBellingham, WA 98225 US\n(360) 756-9793',
    state: 'wa',
  },
  {
    name: 'CLAUDIA ARREGOYTIA, MD, ND',
    link: 'https://www.ifm.org/practitioners/claudia-arregoytia/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/compressedBastyr-99-3-2-400x400.jpg',
    contactText:
      'Address\n515 Kirkland Way\nKirkland, WA 98033 US\n425-553-2937',
    state: 'wa',
  },
  {
    name: 'CLAUDINE MAE KUBESH, MD',
    link: 'https://www.ifm.org/practitioners/claudine-kubesh-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n4522 44th Ave. SW\nSuite A\nSeattle, WA 98116 US\n206.557.4844',
    state: 'wa',
  },
  {
    name: 'DAN LUKACZER, ND',
    link: 'https://www.ifm.org/practitioners/dan-lukaczer-nd/',
    avatar: 'https://www.ifm.org/wp-content/uploads/AIC_2014_7201-400x400.jpg',
    contactText: '253-319-1900',
    state: 'wa',
  },
  {
    name: 'DEANNA MINICH, PHD, FACN, CNS, IFMCP',
    link: 'https://www.ifm.org/practitioners/deanna-minich-phd-facn-cns/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Deanna-Headshot-photo-328x400.jpg',
    contactText:
      'Address\nPO Box 8281\nPort Orchard, WA 98366 US\n(360) 731-7705',
    state: 'wa',
  },
  {
    name: 'EASTER HO',
    link: 'https://www.ifm.org/practitioners/easter-ho-nd/',
    avatar: 'https://www.ifm.org/wp-content/uploads/203d490.jpg',
    contactText:
      'Primary Address\n510 A Rainier Ave S\nSeattle, WA 98144 US\n4252479674',
    state: 'wa',
  },
  {
    name: 'ELISE LEAF LELAND, MD',
    link: 'https://www.ifm.org/practitioners/elise-leaf-leland-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n9300 NE Vancouver Mall Dr\nSuite 201\nVancouver, WA 98662 US\n(360) 567-0488',
    state: 'wa',
  },
  {
    name: 'ELIZABETH SUSAN SMOOTS, MD',
    link: 'https://www.ifm.org/practitioners/elizabeth-susan-smoots-md-2/',
    avatar: 'https://www.ifm.org/wp-content/uploads/smoots_photo-1-400x400.jpg',
    contactText: 'Address\n4616 25th Avenue NE\nSte 201\nSeattle, WA 98105 US',
    state: 'wa',
  },
  {
    name: 'GILLIAN EHRLICH',
    link: 'https://www.ifm.org/practitioners/gillian-ehrlich-dnp-fnp/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_1944-1-400x400.jpg',
    contactText:
      'Address\n1818 Westlake Ave N, Suite 106\nSeattle, WA 98109 US\n206-379-1213',
    state: 'wa',
  },
  {
    name: 'JOSEPH J LAMB, MD',
    link: 'https://www.ifm.org/practitioners/joseph-lamb-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/JosephLamb-025-400x400.jpg',
    contactText:
      'Address\n9770 44th Ave NW\n#102\nGig Harbor, WA 98332 US\n253-853-7233',
    state: 'wa',
  },
  {
    name: 'KRISTEN B BROWN, ARNP, IFMCP',
    link: 'https://www.ifm.org/practitioners/kristen-brown/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/IMG_20221116_094307_547-400x400.jpg',
    contactText:
      'Primary Address\n40 Lake Bellevue Dr\nSte 100\nBellevue, WA 98005-2480 US\n2068833266',
    state: 'wa',
  },
  {
    name: 'LINDA S. GOGGIN, MD',
    link: 'https://www.ifm.org/practitioners/linda-s-goggin-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/DSC_4394-400x400.jpg',
    contactText:
      'Address\n1285 Roy Rd.\nBellingham, WA 98229 US\n(360) 739-3611',
    state: 'wa',
  },
  {
    name: 'MELISSA A MEYER, MD',
    link: 'https://www.ifm.org/practitioners/melissa-ayn-meyer/',
    avatar: 'https://www.ifm.org/wp-content/uploads/IMG_5088-400x400.jpeg',
    contactText:
      'Address\n7201 Pioneer Way\nSTE B101\nGig Harbor, WA 98335 US\n(253) 900-2285',
    state: 'wa',
  },
  {
    name: 'MICHAEL MEYER, MD',
    link: 'https://www.ifm.org/practitioners/michael-meyer/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n7201 Pioneer Way\nSte B101\nGig Harbor, WA 98335 US\n253-785-3422',
    state: 'wa',
  },
  {
    name: 'MICHELLE LEARY-CHANG, ND',
    link: 'https://www.ifm.org/practitioners/michelle-leary-nd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Untitled-design-400x400.png',
    contactText:
      'Address\n4915 25th Ave NE\nSuite 104\nSeattle, WA 98105 US\n206.315.7998',
    state: 'wa',
  },
  {
    name: 'MONICA GERMAN, MD',
    link: 'https://www.ifm.org/practitioners/monica-german-md/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Head-Shot-400x400.png',
    contactText:
      'Address\n922 South Cowley Street\n7\nSpokane, WA 99202 US\n(509) 262-8145',
    state: 'wa',
  },
  {
    name: 'SARAH PIESTRUP, FNP',
    link: 'https://www.ifm.org/practitioners/sarah-piestrup-fnp-lac/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n10454 Fox Rd\nLeavenworth, WA 98826 US\n(509) 548-4780',
    state: 'wa',
  },
  {
    name: 'STEPHANIE JONES',
    link: 'https://www.ifm.org/practitioners/stephanie-renee-jones/',
    avatar: 'https://www.ifm.org/wp-content/uploads/Steph-square-400x400.png',
    contactText:
      'Address\n406 SE 131st Ave\nSuite 203\nVancouver, WA 98683 US\n3604339580',
    state: 'wa',
  },
  {
    name: 'STEVEN LABRECQUE, ND, MPH, ABAAHP, IFMCP',
    link: 'https://www.ifm.org/practitioners/steven-labrecque-nd-mph/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/steven-headshot-small-400x400.png',
    contactText:
      'Address\n12600 SE 38th St\nSte 130\nBellevue, WA 98006 US\n4256796056',
    state: 'wa',
  },
  {
    name: 'TIMOTHY R. MORRIS, ND',
    link: 'https://www.ifm.org/practitioners/t-r-morris-nd-ifmcp/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Headshot_T.R._Morris-400x400.jpg',
    contactText:
      'Address\n7041 11th Ave NW\nSeattle, WA 98117 US\n206-947-4915',
    state: 'wa',
  },
  {
    name: 'MARCIA L JONES',
    link: 'https://www.ifm.org/practitioners/marcia-l-kesner/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/05BE25D2-25B1-46F3-B82C-400404862098-400x400.jpeg',
    contactText: 'Address\n8685 Cumberland Rd\nSpringfield , WV 26763 US',
    state: 'wv',
  },
  {
    name: 'NATHAN DOCTRY, MD',
    link: 'https://www.ifm.org/practitioners/nathan-elliott-doctry/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/7140B288-A494-43F3-992F-57009AA71007-400x400.jpeg',
    contactText:
      'Address\n250 Stanaford Road\nBeckley, WV 25801 US\n304 254-2650',
    state: 'wv',
  },
  {
    name: 'AMY M SAPOLA',
    link: 'https://www.ifm.org/practitioners/amy-m-sapola-pharm-d/',
    avatar: 'https://www.ifm.org/wp-content/uploads/1634770064403-400x400.jpg',
    contactText: 'Primary Address\n100 Pine Street\nUnit B\nPepin, WI 54759 US',
    state: 'wi',
  },
  {
    name: 'ANNE SHERMAN',
    link: 'https://www.ifm.org/practitioners/anne-sherman-msn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/SP-logo-sketch-400x400.jpg',
    contactText:
      'Address\n6789 North Green Bay Avenue\nGlendale, WI 53209 US\n(414) 367-6376',
    state: 'wi',
  },
  {
    name: 'CHENG HER, MD',
    link: 'https://www.ifm.org/practitioners/cheng-her-md-ms/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/DadTaosHeadShot-400x400.png',
    contactText:
      'Address\n2950 Darling Court\nLa Crosse, WI 54601 US\n+1 608-790-9292',
    state: 'wi',
  },
  {
    name: 'KATHLEEN G HOPKINS, DO',
    link: 'https://www.ifm.org/practitioners/kathleen-g-hopkins-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/KAT-81-400x400.jpg',
    contactText:
      'Address\nN7422 County Road O\nRiver Falls, WI 54022 US\n(612) 412-5130',
    state: 'wi',
  },
  {
    name: 'KATIE KNIPFER',
    link: 'https://www.ifm.org/practitioners/katie-knipfer-pa-c/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/knipfer-10a-thumb-400x400.jpg',
    contactText:
      'Primary Address\n251 East Cottage Grove Road\nCottage Grove, WI 53527 US\n(608) 839-3515',
    state: 'wi',
  },
  {
    name: 'KELLE ANDERSON, MD',
    link: 'https://www.ifm.org/practitioners/kelle-anderson-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n319 S. Main Street\nRiver Falls, WI 54022 US\n715-425-6701',
    state: 'wi',
  },
  {
    name: 'LARA SALYER, DO',
    link: 'https://www.ifm.org/practitioners/lara-salyer/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2022-03-03-at-1.47.33-PM-400x400.png',
    contactText: 'Address\n1005 16th AVE\nMONROE, WI 53566 US\n608-325-1192',
    state: 'wi',
  },
  {
    name: 'LAURA SMITH, DO',
    link: 'https://www.ifm.org/practitioners/laura-smith/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Address\n1051 West Ave\nRice Lake, WI 54868 US\n715-719-0662',
    state: 'wi',
  },
  {
    name: 'NICOLE GRAMS',
    link: 'https://www.ifm.org/practitioners/nicole-m-grams/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/NGrams-Headshot-Square-400x400.jpg',
    contactText: '(608) 888-0132',
    state: 'wi',
  },
  {
    name: 'SCOTT VANDER WIELEN, DC',
    link: 'https://www.ifm.org/practitioners/scott-vander-wielen-dc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Scott-VW-portrait-50-percent-400x400.jpg',
    contactText:
      'Address\n1486 Kenwood Drive\nMenasha, WI 54952-1133 US\n(920) 722-2100',
    state: 'wi',
  },
  {
    name: 'TAMARA LEE LYDAY, DO',
    link: 'https://www.ifm.org/practitioners/tamara-lyday-do/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n2733 Manitowoc Road #10\nGreen Bay, WI 54311 US\n(920) 412-8184',
    state: 'wi',
  },
  {
    name: 'THOMAS KRIEGER-FRITSCH, DC',
    link: 'https://www.ifm.org/practitioners/thomas-krieger-fritsch-dc-apc/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Address\n211 N Main St\nViroqua, WI 54665 US\n(608) 637-7656',
    state: 'wi',
  },
  {
    name: 'AMY M SAPOLA',
    link: 'https://www.ifm.org/practitioners/amy-m-sapola-pharm-d/',
    avatar: 'https://www.ifm.org/wp-content/uploads/1634770064403-400x400.jpg',
    contactText: 'Primary Address\n100 Pine Street\nUnit B\nPepin, WI 54759 US',
    state: 'wi',
  },
  {
    name: 'ANNE SHERMAN',
    link: 'https://www.ifm.org/practitioners/anne-sherman-msn/',
    avatar: 'https://www.ifm.org/wp-content/uploads/SP-logo-sketch-400x400.jpg',
    contactText:
      'Address\n6789 North Green Bay Avenue\nGlendale, WI 53209 US\n(414) 367-6376',
    state: 'wi',
  },
  {
    name: 'CHENG HER, MD',
    link: 'https://www.ifm.org/practitioners/cheng-her-md-ms/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/DadTaosHeadShot-400x400.png',
    contactText:
      'Address\n2950 Darling Court\nLa Crosse, WI 54601 US\n+1 608-790-9292',
    state: 'wi',
  },
  {
    name: 'KATHLEEN G HOPKINS, DO',
    link: 'https://www.ifm.org/practitioners/kathleen-g-hopkins-do/',
    avatar: 'https://www.ifm.org/wp-content/uploads/KAT-81-400x400.jpg',
    contactText:
      'Address\nN7422 County Road O\nRiver Falls, WI 54022 US\n(612) 412-5130',
    state: 'wi',
  },
  {
    name: 'KATIE KNIPFER',
    link: 'https://www.ifm.org/practitioners/katie-knipfer-pa-c/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/knipfer-10a-thumb-400x400.jpg',
    contactText:
      'Primary Address\n251 East Cottage Grove Road\nCottage Grove, WI 53527 US\n(608) 839-3515',
    state: 'wi',
  },
  {
    name: 'KELLE ANDERSON, MD',
    link: 'https://www.ifm.org/practitioners/kelle-anderson-md/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n319 S. Main Street\nRiver Falls, WI 54022 US\n715-425-6701',
    state: 'wi',
  },
  {
    name: 'LARA SALYER, DO',
    link: 'https://www.ifm.org/practitioners/lara-salyer/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Screen-Shot-2022-03-03-at-1.47.33-PM-400x400.png',
    contactText: 'Address\n1005 16th AVE\nMONROE, WI 53566 US\n608-325-1192',
    state: 'wi',
  },
  {
    name: 'LAURA SMITH, DO',
    link: 'https://www.ifm.org/practitioners/laura-smith/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Address\n1051 West Ave\nRice Lake, WI 54868 US\n715-719-0662',
    state: 'wi',
  },
  {
    name: 'NICOLE GRAMS',
    link: 'https://www.ifm.org/practitioners/nicole-m-grams/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/NGrams-Headshot-Square-400x400.jpg',
    contactText: '(608) 888-0132',
    state: 'wi',
  },
  {
    name: 'SCOTT VANDER WIELEN, DC',
    link: 'https://www.ifm.org/practitioners/scott-vander-wielen-dc/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Scott-VW-portrait-50-percent-400x400.jpg',
    contactText:
      'Address\n1486 Kenwood Drive\nMenasha, WI 54952-1133 US\n(920) 722-2100',
    state: 'wi',
  },
  {
    name: 'TAMARA LEE LYDAY, DO',
    link: 'https://www.ifm.org/practitioners/tamara-lyday-do/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n2733 Manitowoc Road #10\nGreen Bay, WI 54311 US\n(920) 412-8184',
    state: 'wi',
  },
  {
    name: 'THOMAS KRIEGER-FRITSCH, DC',
    link: 'https://www.ifm.org/practitioners/thomas-krieger-fritsch-dc-apc/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText: 'Address\n211 N Main St\nViroqua, WI 54665 US\n(608) 637-7656',
    state: 'wi',
  },
  {
    name: 'DANIELA S GERARD, MD',
    link: 'https://www.ifm.org/practitioners/daniela-s-gerard/',
    avatar:
      'https://www.ifm.org/wp-content/themes/ifm/static/img/profile_image.png',
    contactText:
      'Address\n801 E. 4th St.\nSUITE 17\nGillette, WY 82716 US\n307-670-9200',
    state: 'wy',
  },
  {
    name: 'JACKIE ROVELL',
    link: 'https://www.ifm.org/practitioners/jackie-m-rovell-rd/',
    avatar:
      'https://www.ifm.org/wp-content/uploads/Professional-picture-3-400x400.jpg',
    contactText: '760-715-6953',
    state: 'wy',
  },
];
