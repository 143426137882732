import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import './baseline.scss';

function Baseline({ currentUser, fbUser }) {
  const navigate = useNavigate();
  return (
    <div className="baseline baseline-padding page">
      <div className="user-data">
        <h1>
          Welcome to <span className="logo-font logo">FMD</span>
        </h1>

        <img src={fbUser?.photoURL} alt="avatar" className="avatar" />
        <p>{currentUser?.displayName}</p>
      </div>

      <div className="onboarding-body">
        <h2>
          The objective of Functional Medicine is to identify the underlying
          cause of your chronic health issues.
        </h2>
        <p>
          To begin, we will assess your current overall health by means of our
          detailed questionnaire.
        </p>
        {/* <table>
          <tr>
            <td className="large">1</td>
            <td>
              We'll start by recording your current overall health via our
              comprehensive questionaire.
            </td>
          </tr>
          <tr>
            <td className="large">2</td>
            <td>Get started on your elimination diet to find root causes.</td>
          </tr>
        </table> */}
      </div>
      <div className="footer">
        <Button callback={() => navigate('/baseline-1')}>
          Continue
          <img className="next" src="/icons/chevron-down.svg" alt="Next" />
        </Button>
      </div>
    </div>
  );
}

export default Baseline;
