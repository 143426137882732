const steps = [
  {
    id: 0,
    label: 'Start an elimination diet',
    image: '/landing/elimination-diet-ftr.jpg',
    description:
      'A method of identifying foods that may be causing inflammation by removing them from your diet and gradually reintroducing them.',
  },
  {
    id: 1,
    label: 'Track your symptoms',
    image: '/landing/businessman-looking-smartphone.jpg',
    description:
      'To pinpoint foods that trigger inflammation, complete our questionnaire every other week to track any symptoms that may arise when reintroducing certain foods.',
  },

  {
    id: 2,
    label: 'Track your progress',
    image: '/landing/istockphoto-1184595440-612x612.jpg',
    description:
      'Use our charts to assist you in visualizing the progress of your health journey.',
  },
  {
    id: 3,
    label: 'Track your bloodwork',
    //comingSoon: true,
    image: '/landing/labs.jpg',
    description: 'Record labs against Functional Medicine optimal values.',
  },
  {
    id: 4,
    label: 'Track lifestyle and exercise',
    //comingSoon: true,
    image: '/landing/Earthing.jpg',
    description: (
      <div>
        Record progress on your healthy lifestyle habits like good sleep,{' '}
        <a
          href="https://twitter.com/HealthOverhaul/status/1686695249908047872"
          target="_blank"
          rel="noreferrer"
        >
          grounding/earthing
        </a>
        ,{' '}
        <a
          href="https://youtube.com/shorts/Ay_qvzYZfqE?feature=share"
          target="_blank"
        >
          dead hanging
        </a>
        ,{' '}
        <a
          href="https://www.uclahealth.org/news/6-cold-shower-benefits-consider"
          target="_blank"
          rel="noreferrer"
        >
          cold showers
        </a>
        , and more.
      </div>
    ),
  },
  {
    id: 5,
    label: 'Find doctors and practioners',
    image: '/landing/smiling-touching-arms-crossed-room-hospital.jpg',
    description:
      'If you require guidance on your path towards recovery, we offer a directory of IFM Certified Functional Medicine physicians to assist you.',
  },
];

function Steps() {
  return (
    <div className="steps">
      {steps.map((s, index) => (
        <div
          className="panel"
          key={s.id}
          style={{ backgroundImage: `url(${s.image})` }}
        >
          <div className="overlay" />
          <h3>
            <span>{index + 1}</span>
            {s.label}
          </h3>
          <p>{s.description}</p>
          {s.comingSoon && (
            <span
              className="pill"
              style={{ zIndex: 3, width: 'fit-content', fontSize: '12px' }}
            >
              Coming Soon
            </span>
          )}
        </div>
      ))}
    </div>
  );
}

export default Steps;
