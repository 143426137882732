import React from 'react';
import './Alert.scss';
function Alert({ alert }) {
  return (
    <div className={`alert ${alert.type ? alert.type : ''}`}>
      {alert.icon && alert.icon} {alert.message && alert.message}
    </div>
  );
}

export default Alert;
