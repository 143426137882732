import React, { useEffect, useState } from 'react';
import Points from '../points';
import { questionnaire } from '../questionaireData';
import DatePicker from 'sassy-datepicker';
import '../symptoms.scss';
import Button from '../../../components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { addNewSymptomsToSymptoms } from '../../../helpers/api';
import { weeks } from '../../../helpers/systemData';
import { getCurrentWeek, goBack } from '../../../helpers/helpers';
import { updateSymptoms } from '../../../helpers/api';
import Header from '../../../components/Header/Header';

const getCategoriesWithData = (questions) => {
  const categories = [...new Set(questions.map((q) => q.Category))];
  return categories.map((c) => {
    const data = questions.filter((q) => q.Category === c);
    return { Category: c, data };
  });
};

const categoriesWithData = getCategoriesWithData(questionnaire);

const getSum = (
  newData,
  setTotalScore,
  setFormCompleted,
  setIsBaselineAbleToSubmitForm,
  setOnboardingScore
) => {
  const flat = newData.map((n) => n.data).flat();
  const flatWithScore = flat.filter((s) => s.Score !== '');
  const score = flatWithScore.map((f) => +f.Score)?.reduce((a, b) => a + b, 0);
  setTotalScore(score);
  const isCompleted = flatWithScore.length === flat.length;
  setFormCompleted(isCompleted);
  if (setIsBaselineAbleToSubmitForm) {
    setIsBaselineAbleToSubmitForm(isCompleted);
    setOnboardingScore(score);
  }
};

function AddSymptoms({
  currentUser,
  setCurrentUser,
  isEdit,
  isBaseline,
  submitFromOnboarding,
  setIsBaselineAbleToSubmitForm,
  setOnboardingScore,
  isAdd,
  combined,
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState(categoriesWithData);
  const [date, setDate] = useState(new Date());
  const [toggledPicker, setToggledPicker] = useState(false);
  const [totalScore, setTotalScore] = useState(null);
  const [formCompleted, setFormCompleted] = useState(false);
  const [currentWeek, setCurrentWeek] = useState(weeks[0]);

  const handleSetPointValue = (item) => {
    const newData = [...data].map((d) => {
      if (item.Category === d.Category) {
        d.data.map((dd) => {
          if (dd.Symptom === item.Symptom) {
            dd.Score = item.Score;
            return dd;
          } else {
            return dd;
          }
        });
        return d;
      } else {
        return d;
      }
    });
    setData(newData);
    getSum(
      newData,
      setTotalScore,
      setFormCompleted,
      setIsBaselineAbleToSubmitForm,
      setOnboardingScore
    );
  };

  const onDateChange = (dateValue) => {
    setDate(dateValue);
    setToggledPicker(false);
  };

  useEffect(() => {
    if (submitFromOnboarding) {
      setFormCompleted(true);
      submitQuestionaire();
    }
  }, [submitFromOnboarding]);

  const submitQuestionaire = () => {
    if (formCompleted) {
      //submit to firebase
      const submission = {
        id: currentWeek.id || 0,
        week: currentWeek.week || 0,
        date: date.toDateString(),
        data: data.map((d) => d.data).flat(),
        total: totalScore,
        notes: '',
      };

      if (isEdit) {
        updateSymptoms(submission, currentUser, setCurrentUser);
      } else {
        addNewSymptomsToSymptoms(submission, currentUser, setCurrentUser);
      }

      if (submitFromOnboarding) return;
      navigate('/symptoms');
    }
  };

  //new code
  useEffect(() => {
    const d = currentUser?.symptoms;

    if (!d) return;

    //getCurrentWeek and populate date
    const c = combined?.find((cc) => +cc.id === +id);
    setDate(new Date(c?.startDate));
    setCurrentWeek(weeks?.find((w) => w.id === +id));
    const existingData = Object.values(d);

    if (isEdit) {
      const existingDataArray = existingData.find((d) => +d.id === +id).data;
      setData(getCategoriesWithData(existingDataArray));
    }
  }, [currentUser, id, isEdit, combined]);

  return (
    <div className="questionaire page">
      {!isBaseline && (
        <Header
          title="Symptoms"
          isEdit={isEdit}
          backRoute={`/symptoms/${id}`}
        />
      )}

      <div className="form-item">
        <label>
          {currentWeek.id === 0
            ? 'Date'
            : `Start Date for Week ${currentWeek.week}`}
        </label>
        <div
          className="form-control"
          onClick={() => setToggledPicker(!toggledPicker)}
        >
          {date.toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </div>
        {toggledPicker && <DatePicker onChange={onDateChange} />}
      </div>
      <small>
        <h4>
          Rate each of the following symptoms based upon your typical health
          profile for the past 14 days.
        </h4>
        <p>0: Never or almost never have the symptom</p>
        <p>1: Occasionally have it, effect is not severe</p>
        <p>2: Occasionally have it, effect is severe</p>
        <p>3: Frequently have it, effect is not severe</p>
        <p>4: Frequently have it, effect is severe</p>
      </small>
      {data.map((q, idx) => (
        <div className="line-item" key={idx}>
          <h4>{q.Category}</h4>
          {q.data.map((d, index) => (
            <div key={`line-${index}`}>
              {d.Symptom}
              <Points
                item={{ ...d }}
                score={d.Score}
                handleSetPointValue={handleSetPointValue}
              />
            </div>
          ))}
        </div>
      ))}

      <div className="footer">
        <Button
          callback={() => submitQuestionaire()}
          type={formCompleted ? 'primary' : 'disabled'}
        >
          {formCompleted
            ? `Submit Score of ${totalScore}`
            : `Complete to Submit Score of ${totalScore || 0}`}
        </Button>
      </div>
    </div>
  );
}

export default AddSymptoms;
