import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navigation.scss';
import { remindMe } from '../../helpers/helpers';

const navOptions = [
  {
    id: 0,
    name: 'Home',
    path: '/',
  },
  {
    id: 0,
    name: 'Tasks',
    path: '/calendar',
  },
  {
    id: 1,
    name: 'Elimination Diet',
    path: '/diet',
  },
  {
    id: 2,
    name: 'Symptom Tracker',
    path: '/symptoms',
  },
  {
    id: 3,
    name: 'Practitioners',
    path: '/practitioners',
  },
  {
    id: 4,
    name: 'Progress',
    path: '/progress',
  },
  {
    id: 5,
    name: 'Lab Tracker',
    //comingSoon: true,
    path: '/labs',
  },
  {
    id: 6,
    name: 'Sign Out',
    path: '/sign-out',
  },
];

function Navigation({ currentUser, fbUser }) {
  const navigate = useNavigate();
  const [toggledNav, setToggledNav] = useState(false);

  return (
    <nav>
      <img
        className="menu"
        src="/icons/menu.svg"
        onClick={() => setToggledNav(!toggledNav)}
        alt="Menu"
      />

      {toggledNav && (
        <div className="drawer">
          <div className="close" onClick={() => setToggledNav(false)}>
            <img src="/icons/close.svg" alt="close menu" />
          </div>

          {/* <div
            className="logo-font logo"
            onClick={() => {
              setToggledNav(false);
              navigate('/');
            }}
          >
            FMD
          </div> */}
          <img
            onClick={() => {
              setToggledNav(false);
              navigate('/');
            }}
            className="logo"
            src="/FMD.svg"
            alt="logo"
          />
          <h6>Find the root cause.</h6>

          {navOptions.map((n) => (
            <Link key={n.name} to={n.path} onClick={() => setToggledNav(false)}>
              {n.name}{' '}
              {n.comingSoon && <span className="pill">Coming Soon</span>}
            </Link>
          ))}

          <div className="user-details">
            {fbUser?.photoURL && (
              <img src={fbUser?.photoURL} alt="avatar" className="avatar" />
            )}
            <p>{currentUser?.displayName}</p>
          </div>
          <div className="feedback">
            <a
              href="mailto:enrique@heyheygorgeous.com"
              target="_blank"
              rel="noreferrer"
              className="mobile"
            >
              Feedback
            </a>
            <a
              href="mailto:enrique@heyheygorgeous.com"
              rel="noreferrer"
              className="desktop"
              onClick={() => {
                navigator.clipboard.writeText('enrique@heyheygorgeous.com');
                alert('Copied Email Address');
              }}
            >
              Feedback
            </a>
            <Link to="/terms" onClick={() => setToggledNav(false)}>
              Terms
            </Link>
            <Link to="/privacy" onClick={() => setToggledNav(false)}>
              Privacy
            </Link>
            <a onClick={() => remindMe(currentUser)}>
              Add Reminders to Calendar
            </a>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navigation;
