import React from 'react';
import './Collapse.scss';

function Collapse({ id, children, title, collapsed, handleCollapse }) {
  return (
    <div className="collapse">
      <div className="title" onClick={() => handleCollapse(id)}>
        {title}
        <img
          className={collapsed ? '' : 'active'}
          src="/icons/chevron-down.svg"
          alt="open close"
        />
      </div>
      {!collapsed && <div className="collapse-content">{children}</div>}
    </div>
  );
}

export default Collapse;
