import React, { useEffect, useState } from 'react';
import Login from '../../components/login/login';
import { Link } from 'react-router-dom';

const issues = [
  { id: 0, label: 'Autoimmune' },
  { id: 1, label: 'Metabolic Syndrome' },
  { id: 2, label: 'Leaky Gut' },
  { id: 3, label: 'Chronic Back Pain' },
  { id: 4, label: 'Psoriasis + Excema' },
  { id: 5, label: 'Rheumatoid Arthritis' },
];

const videos = [
  { id: 0, url: '/landing/pexels-ivan-samkov-8878319.mp4' },
  { id: 1, url: '/landing/production ID_4608974.mp4' },
  { id: 2, url: '/landing/pexels-mart-production-8027231.mp4' },
  { id: 3, url: '/landing/pexels-any-lane-5944918.mp4' },
  { id: 4, url: '/landing/pexels-ivan-samkov-5801958.mp4' },
];

function Hero() {
  const [issueId, setIssueId] = useState(0);
  const [videoId, setVideoId] = useState(0);

  const rotateIssues = (id) => {
    let rId = id || issueId;
    setTimeout(() => {
      if (id === issues.length - 1) {
        rId = -1;
      }
      setIssueId(rId + 1);
      rotateIssues(rId + 1);
    }, 2000);
  };

  const rotateVideos = (id) => {
    let rId = id || videoId;
    setTimeout(() => {
      if (id === videos.length - 1) {
        rId = -1;
      }
      setVideoId(rId + 1);
      rotateVideos(rId + 1);
    }, 5000);
  };

  useEffect(() => {
    rotateIssues();
    rotateVideos();
  }, []);

  return (
    <div
      className="hero"
      style={{ backgroundImage: `url(/landing/rm422-047.jpg)` }}
    >
      <div className="overlay" />
      <div className="overlay-bottom" />
      <video
        playsInline
        autoPlay
        muted={true}
        loop
        preload="metadata"
        width="100%"
        height="auto"
        id="bgvid"
        src={videos[videoId].url}
      />
      <div className="hero-content">
        {/*  <div className="logo-font logo">FMD</div> */}
        <img className="logo" src="/FMD.svg" alt="logo" />
        <h1>
          <small>Find the root cause of your</small>
          <br />
          {issues.map((i) => (
            <span className="issue" key={i.id}>
              <b className={issueId === i.id ? 'toggleIn' : 'toggleOut'}>
                {i.label}
              </b>
            </span>
          ))}
        </h1>
      </div>
      <div className="cta">
        <Login />
        <small>
          <span className="faded">By logging in you are agreeing to our</span>{' '}
          <Link to="/terms">Terms of Service</Link>.
        </small>
        {/* <Link to="/register" className="button">
            Create a Free Account
          </Link>
          or <Link to="/login">Login</Link> */}
      </div>
    </div>
  );
}

export default Hero;
