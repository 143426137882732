export const weeks = [
  { id: 1, week: 1 },
  { id: 2, week: 3 },
  { id: 3, week: 5 },
  { id: 4, week: 7 },
  { id: 5, week: 9 },
  { id: 6, week: 11 },
  { id: 7, week: 13 },
  { id: 8, week: 15 },
  { id: 9, week: 17 },
  { id: 10, week: 19 },
  { id: 11, week: 21 },
  { id: 12, week: 23 },
  { id: 13, week: 25 },
  { id: 14, week: 27 },
  { id: 15, week: 29 },
  { id: 16, week: 31 },
  { id: 17, week: 33 },
  { id: 18, week: 35 },
  { id: 19, week: 37 },
  { id: 20, week: 39 },
  { id: 21, week: 41 },
  { id: 22, week: 43 },
  { id: 23, week: 45 },
  { id: 24, week: 47 },
  { id: 25, week: 49 },
  { id: 26, week: 51 },
  { id: 27, week: 53 },
  { id: 28, week: 55 },
  { id: 29, week: 57 },
  { id: 30, week: 59 },
  { id: 31, week: 61 },
  { id: 32, week: 63 },
  { id: 33, week: 65 },
  { id: 34, week: 67 },
  { id: 35, week: 69 },
  { id: 36, week: 71 },
  { id: 37, week: 73 },
  { id: 38, week: 75 },
  { id: 39, week: 77 },
  { id: 40, week: 79 },
  { id: 41, week: 81 },
  { id: 42, week: 83 },
  { id: 43, week: 85 },
  { id: 44, week: 87 },
  { id: 45, week: 89 },
  { id: 46, week: 91 },
  { id: 47, week: 93 },
  { id: 48, week: 95 },
  { id: 49, week: 97 },
  { id: 50, week: 99 },
  { id: 51, week: 101 },
];

export const weeksOld = [
  { id: 0, week: 0 },
  { id: 1, week: 1 },
  { id: 2, week: 3 },
  { id: 3, week: 5 },
  { id: 4, week: 7 },
  { id: 5, week: 9 },
  { id: 6, week: 11 },
  { id: 7, week: 13 },
  { id: 8, week: 15 },
  { id: 9, week: 17 },
  { id: 10, week: 19 },
  { id: 11, week: 21 },
  { id: 12, week: 23 },
  { id: 13, week: 25 },
  { id: 14, week: 27 },
  { id: 15, week: 29 },
  { id: 16, week: 31 },
  { id: 17, week: 33 },
  { id: 18, week: 35 },
  { id: 19, week: 37 },
  { id: 20, week: 39 },
  { id: 21, week: 41 },
  { id: 22, week: 43 },
  { id: 23, week: 45 },
  { id: 24, week: 47 },
  { id: 25, week: 49 },
  { id: 26, week: 51 },
  { id: 27, week: 53 },
  { id: 28, week: 55 },
  { id: 29, week: 57 },
  { id: 30, week: 59 },
  { id: 31, week: 61 },
  { id: 32, week: 63 },
  { id: 33, week: 65 },
  { id: 34, week: 67 },
  { id: 35, week: 69 },
  { id: 36, week: 71 },
  { id: 37, week: 73 },
  { id: 38, week: 75 },
  { id: 39, week: 77 },
  { id: 40, week: 79 },
  { id: 41, week: 81 },
  { id: 42, week: 83 },
  { id: 43, week: 85 },
  { id: 44, week: 87 },
  { id: 45, week: 89 },
  { id: 46, week: 91 },
  { id: 47, week: 93 },
  { id: 48, week: 95 },
  { id: 49, week: 97 },
  { id: 50, week: 99 },
  { id: 51, week: 101 },
];

export const categoriesWithData = [
  {
    Category: 'Sleep',
    data: [
      {
        id: '526dfd88-0196-11ee-be56-0242ac120002',
        Category: 'Sleep',
        Question:
          'Avg. nightly sleep duration: Aim for 7-9 hours of sleep for optimal rest and well-being.',
        Options: [
          {
            id: 'bfe0d42c-0172-11ee-be56-0242ac120002',
            value: '-4',
            points: 0,
          },
          {
            id: 'bfe0d42c-0172-11ee-be56-0242ac120001',
            value: '5',
            points: 1,
          },
          { id: 'c5cb2770-0172-11ee-be56-0242ac120002', value: 6, points: 3 },
          { id: 'cacbe584-0172-11ee-be56-0242ac120002', value: 7, points: 5 },
          { id: 'cef4449e-0172-11ee-be56-0242ac120002', value: 8, points: 6 },
          { id: 'd3b4e7a4-0172-11ee-be56-0242ac120002', value: 9, points: 5 },
          {
            id: 'd8e7739a-0172-11ee-be56-0242ac120002',
            value: '10+',
            points: 5,
          },
        ],
        Score: null,
        Points: null,
      },
      {
        id: '5a670fde-0196-11ee-be56-0242ac120002',
        Category: 'Sleep',
        Question: 'Avg. interruptions during sleep per night.',
        Options: [
          { id: 'dc9a64de-0172-11ee-be56-0242ac120002', value: 0, points: 6 },
          { id: 'e0b96998-0172-11ee-be56-0242ac120002', value: 1, points: 4 },
          { id: 'e4a7d95e-0172-11ee-be56-0242ac120002', value: 2, points: 2 },
          { id: 'e8edfb74-0172-11ee-be56-0242ac120002', value: 3, points: 0 },
          { id: 'ec493b12-0172-11ee-be56-0242ac120002', value: 4, points: 0 },
          {
            id: 'ef9f08f0-0172-11ee-be56-0242ac120002',
            value: '5+',
            points: 0,
          },
        ],
        Score: null,
        Points: null,
      },
    ],
  },
  {
    Category: 'Natural Vitamins',
    data: [
      {
        id: '60d5cbf8-0196-11ee-be56-0242ac120002',
        Category: 'Vitamins',
        Question:
          'Avg. daily sun exposure time (preferably in the morning or afternoon sun)',
        Options: [
          { id: '7245fd9a-0173-11ee-be56-0242ac120002', value: 0, points: 0 },
          {
            id: '7610160e-0173-11ee-be56-0242ac120002',
            value: '15',
            points: 2,
          },
          {
            id: '7993c032-0173-11ee-be56-0242ac120002',
            value: '30',
            points: 4,
          },
          {
            id: '7993c032-0173-11ee-be56-0242ac120002',
            value: '45',
            points: 5,
          },
          {
            id: '7f7d342e-0173-11ee-be56-0242ac120002',
            value: '60',
            points: 6,
          },
          {
            id: '83221cb6-0173-11ee-be56-0242ac120002',
            value: '120',
            points: 5,
          },
          {
            id: '8706bbde-0173-11ee-be56-0242ac120002',
            value: '180+',
            points: 4,
          },
        ],
        Score: null,
        Points: null,
      },
      {
        id: '60d5cbf8-0196-11ee-be56-0242ac120003',
        Category: 'Grounding/Earthing',
        Question: (
          <div>
            Avg. daily time spent Grounding/Earthing: engaging in direct contact
            with the Earth's surface (grass, ground, sand, unpainted concrete,
            large body of water, or grounding mat) with exposed feet/skin,
            either by laying, sitting, walking, or standing, to absorb electrons
            from the Earth. These electrons bind to free radicals in our bodies,
            resulting in a reduction of overall inflammation.{' '}
            <a
              /* href="https://youtube.com/shorts/n-_B__dci-o?feature=share" */
              href="https://twitter.com/HealthOverhaul/status/1686695249908047872"
              target="_blank"
              rel="noreferrer"
            >
              Read About Grounding
            </a>
            .
          </div>
        ),
        Options: [
          { id: '68fe47e1-6815-4bff-a8b9-49f3ac480482', value: 0, points: 0 },
          {
            id: '763827e5-aadd-42df-a781-58c332cc7a39',
            value: '5',
            points: 1,
          },
          {
            id: '2ba38f3e-8a3d-4b48-a83f-e9ab7a776301',
            value: '15',
            points: 2,
          },
          {
            id: '89893212-b5f7-49eb-96e3-18c662460409',
            value: '30',
            points: 3,
          },
          {
            id: '32edd3e8-c332-478d-8ac9-52299720f878',
            value: '60',
            points: 5,
          },
          {
            id: 'd01b8945-dedf-4a8c-9197-bd5a3b41d694',
            value: '120',
            points: 6,
          },
          {
            id: '6de65258-be5a-4e4d-a3e9-f0f0e0e2bf66',
            value: '180+',
            points: 6,
          },
        ],
        Score: null,
        Points: null,
      },
      {
        id: '408a911a-1a15-40dd-a46f-54133e61dfc2',
        Category: 'Cold Showers',
        Question: (
          <div>
            Avg. times you took a cold shower for atleast 3 mins.{' '}
            <a
              /* href="https://youtube.com/shorts/n-_B__dci-o?feature=share" */
              href="https://www.uclahealth.org/news/6-cold-shower-benefits-consider"
              target="_blank"
              rel="noreferrer"
            >
              Read About The Benefits of Cold Showers
            </a>
            .
          </div>
        ),
        Options: [
          { id: '261e89f3-910a-49df-a431-3be4fd3b187e', value: 0, points: 0 },
          {
            id: '81c5cb76-5d4c-4677-9a99-0b14a8bbfb50',
            value: '2',
            points: 1,
          },
          {
            id: '6957bc5e-facd-4db1-8a65-001874fe04b7',
            value: '4',
            points: 2,
          },
          {
            id: 'e496fa77-f91b-4da9-a1be-e042867b9381',
            value: '6',
            points: 3,
          },
          {
            id: 'b67b1586-1eb8-449d-af9f-a7a6a9f439c6',
            value: '8',
            points: 5,
          },
          {
            id: 'dc8e7773-0704-44b9-900c-dcba7c4c537f',
            value: '10',
            points: 6,
          },
          {
            id: '90e537ce-4013-472e-87c4-d9af0e577ffa',
            value: '12+',
            points: 6,
          },
        ],
        Score: null,
        Points: null,
      },
    ],
  },
  {
    Category: 'Diet',
    data: [
      {
        id: '6962aa98-0196-11ee-be56-0242ac120002',
        Category: 'Diet',
        Question: 'Avg. meals/snacks per day. Less frequent preferred.',
        Options: [
          { id: '38a99c4a-0173-11ee-be56-0242ac120002', value: 1, points: 6 },
          { id: '3c2fb0ac-0173-11ee-be56-0242ac120002', value: 2, points: 5 },
          { id: '3f61efd8-0173-11ee-be56-0242ac120002', value: 3, points: 4 },
          { id: '42de5778-0173-11ee-be56-0242ac120002', value: 4, points: 2 },
          { id: '46f4171c-0173-11ee-be56-0242ac120002', value: 5, points: 1 },
          {
            id: '4b43e1b2-0173-11ee-be56-0242ac120002',
            value: '6+',
            points: 0,
          },
        ],
        Score: null,
        Points: null,
      },
      {
        id: '71ab499e-0196-11ee-be56-0242ac120002',
        Category: 'Diet',
        Question: 'Avg. % of protein intake per meal. More protein preferred.',
        Options: [
          { id: '5eb3268a-0174-11ee-be56-0242ac120002', value: 0, points: 0 },
          {
            id: '6266bb02-0174-11ee-be56-0242ac120002',
            value: '10',
            points: 1,
          },
          {
            id: '679d5c70-0174-11ee-be56-0242ac120002',
            value: '20',
            points: 2,
          },
          {
            id: '6b0afad4-0174-11ee-be56-0242ac120002',
            value: '30',
            points: 3,
          },
          {
            id: '7020cd14-0174-11ee-be56-0242ac120002',
            value: '40',
            points: 4,
          },
          {
            id: '7020cd14-0174-11ee-be56-0242ac120002',
            value: '50',
            points: 5,
          },
          {
            id: '7454ef5a-0174-11ee-be56-0242ac120002',
            value: '60+',
            points: 6,
          },
        ],
        Score: null,
        Points: null,
      },
    ],
  },
  {
    Category: 'Fitness',
    data: [
      {
        id: '7ffc34d6-0196-11ee-be56-0242ac120002',
        Category: 'Fitness',
        Question: 'Avg. walking (mins) per day',
        Options: [
          {
            id: '181b75b0-0174-11ee-be56-0242ac120002',
            value: '15',
            points: 0,
          },
          {
            id: '1bccdac8-0174-11ee-be56-0242ac120002',
            value: '20',
            points: 1,
          },
          {
            id: '1fc40b92-0174-11ee-be56-0242ac120002',
            value: '25',
            points: 2,
          },
          {
            id: '23a7fef8-0174-11ee-be56-0242ac120002',
            value: '30',
            points: 3,
          },
          {
            id: '27a3192a-0174-11ee-be56-0242ac120002',
            value: '35',
            points: 4,
          },
          {
            id: '2bcbda28-0174-11ee-be56-0242ac120002',
            value: '40+',
            points: 5,
          },
          {
            id: '302a9f64-0174-11ee-be56-0242ac120002',
            value: '8+',
            points: 6,
          },
        ],
        Score: null,
        Points: null,
      },
      {
        id: '7ffc34d6-0196-11ee-be56-0242ac120002',
        Category: 'Fitness',
        Question: (
          <>
            Avg. walking backwards (mins) per day.{' '}
            <a
              href="https://www.youtube.com/shorts/snPMBNl_vek"
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </a>
            .
          </>
        ),
        Options: [
          {
            id: '181b75b0-0174-11ee-be56-0242ac120002',
            value: '5',
            points: 1,
          },
          {
            id: '1bccdac8-0174-11ee-be56-0242ac120002',
            value: '7',
            points: 2,
          },
          {
            id: '1fc40b92-0174-11ee-be56-0242ac120002',
            value: '8',
            points: 3,
          },
          {
            id: '23a7fef8-0174-11ee-be56-0242ac120002',
            value: '9',
            points: 4,
          },
          {
            id: '27a3192a-0174-11ee-be56-0242ac120002',
            value: '10+',
            points: 5,
          },
        ],
        Score: null,
        Points: null,
      },
      {
        id: '87b0b378-0196-11ee-be56-0242ac120002',
        Category: 'Fitness',
        Question: (
          <div>
            Avg. Dead Hanging (minutes) per day. Hang on a monkey bar or
            similar. If hanging is too difficult, hang with your feet touching
            the ground to help offset the weight. Work your way towards fully
            hanging.{' '}
            <a
              href="https://youtube.com/shorts/Ay_qvzYZfqE?feature=share"
              target="_blank"
            >
              Watch Video Tutorial
            </a>
            .
          </div>
        ),
        Options: [
          { id: '5eb3268a-0174-11ee-be56-0242ac120002', value: 0, points: 0 },
          { id: '6266bb02-0174-11ee-be56-0242ac120002', value: '1', points: 1 },
          { id: '679d5c70-0174-11ee-be56-0242ac120002', value: '2', points: 3 },
          { id: '6b0afad4-0174-11ee-be56-0242ac120002', value: '3', points: 5 },
          { id: '7020cd14-0174-11ee-be56-0242ac120002', value: '4', points: 6 },
          {
            id: '7454ef5a-0174-11ee-be56-0242ac120002',
            value: '5+',
            points: 6,
          },
        ],
        Score: null,
        Points: null,
      },
    ],
  },
];
