export const optimalLabs = {
  date: '',
  notes: '',
  data: [
    {
      analyte: 'WHITE BLOOD COUNT',
      id: 'WHITE BLOOD COUNT',
      refRange: '3.8-10.8',
      refRangeLow: 3.8,
      refRangeHigh: 10.8,
      refRangeLowWoman: 3.8,
      refRangeHighWoman: 10.8,
      units: 'thous/MCL',
      optimalRange: '5-8 thous/MCL',
      optimalLow: 5,
      optimalHigh: 8,
      optimalLowWoman: 5,
      optimalHighWoman: 8,
      userValue: '',
    },
    {
      analyte: 'RED BLOOD COUNT',
      id: 'RED BLOOD COUNT',
      refRange: '3.8-5.10',
      refRangeLow: 3.8,
      refRangeHigh: 5.1,
      refRangeLowWoman: 3.8,
      refRangeHighWoman: 5.1,
      units: 'MILL/MCL',
      optimalRange: 'Male:   4.1-5.6 MILL/MCL\nFemale: 3.9-4.5',
      optimalLow: 4.1,
      optimalHigh: 5.6,
      optimalLowWoman: 3.9,
      optimalHighWoman: 4.5,
      userValue: '',
    },
    {
      analyte: 'HEMOGLOBIN',
      id: 'HEMOGLOBIN',
      refRange: '11.7-15.5',
      refRangeLow: 11.7,
      refRangeHigh: 15.5,
      refRangeLowWoman: 11.7,
      refRangeHighWoman: 15.5,
      units: 'g/dL',
      optimalRange: 'Male:     14.0-15.5 g/dL\nFemale: 13.5-14.5',
      optimalLow: 14,
      optimalHigh: 15.5,
      optimalLowWoman: 13.5,
      optimalHighWoman: 14.5,
      userValue: '',
    },
    {
      analyte: 'HEMATOCRIT',
      id: 'HEMATOCRIT',
      refRange: '35-45',
      refRangeLow: 35,
      refRangeHigh: 45,
      refRangeLowWoman: 35,
      refRangeHighWoman: 45,
      units: '%',
      optimalRange: 'Male:     40-48 %\nFemale: 37-44%',
      optimalLow: 40,
      optimalHigh: 48,
      optimalLowWoman: 37,
      optimalHighWoman: 44,
      userValue: '',
    },
    {
      analyte: 'MCV',
      id: 'MCV',
      refRange: '80-100',
      refRangeLow: 80,
      refRangeHigh: 100,
      refRangeLowWoman: 80,
      refRangeHighWoman: 100,
      units: 'cu. microns',
      optimalRange: '82-89.9 cu. microns',
      optimalLow: 82,
      optimalHigh: 89.9,
      optimalLowWoman: 82,
      optimalHighWoman: 89.9,
      userValue: '',
    },
    {
      analyte: 'MCH',
      id: 'MCH',
      refRange: '27-33',
      refRangeLow: 27,
      refRangeHigh: 33,
      refRangeLowWoman: 27,
      refRangeHighWoman: 33,
      units: 'PG',
      optimalRange: '27-31.9 PG',
      optimalLow: 27,
      optimalHigh: 31.9,
      optimalLowWoman: 27,
      optimalHighWoman: 31.9,
      userValue: '',
    },
    {
      analyte: 'MCHC',
      id: 'MCHC',
      refRange: '32-36',
      refRangeLow: 32,
      refRangeHigh: 36,
      refRangeLowWoman: 32,
      refRangeHighWoman: 36,
      units: 'g/dL',
      optimalRange: '32-36 G/DL',
      optimalLow: 32,
      optimalHigh: 36,
      optimalLowWoman: 32,
      optimalHighWoman: 36,
      userValue: '',
    },
    {
      analyte: 'RDW',
      id: 'RDW',
      refRange: '11.0-15.0',
      refRangeLow: 11,
      refRangeHigh: 15,
      refRangeLowWoman: 11,
      refRangeHighWoman: 15,
      units: '%',
      optimalRange: '<13.0 %',
      optimalLow: 0,
      optimalHigh: 13,
      optimalLowWoman: 0,
      optimalHighWoman: 13,
      userValue: '',
    },
    {
      analyte: 'PLATLETS',
      id: 'PLATLETS',
      refRange: '140-400',
      refRangeLow: 140,
      refRangeHigh: 400,
      refRangeLowWoman: 140,
      refRangeHighWoman: 400,
      units: 'thous/MCL',
      optimalRange: '140-400 thous/MCL',
      optimalLow: 140,
      optimalHigh: 400,
      optimalLowWoman: 140,
      optimalHighWoman: 400,
      userValue: '',
    },
    {
      analyte: 'MPV',
      id: 'MPV',
      refRange: '7.5-11.5',
      refRangeLow: 7.5,
      refRangeHigh: 11.5,
      refRangeLowWoman: 7.5,
      refRangeHighWoman: 11.5,
      units: 'FL',
      optimalRange: '7.5-11.5 FL',
      optimalLow: 7.5,
      optimalHigh: 11.5,
      optimalLowWoman: 7.5,
      optimalHighWoman: 11.5,
      userValue: '',
    },
    {
      analyte: 'ABS.\nLYMPHOCYTES',
      id: 'ABS',
      refRange: '850-3900',
      refRangeLow: 850,
      refRangeHigh: 3900,
      refRangeLowWoman: 850,
      refRangeHighWoman: 3900,
      units: 'cells/MCL',
      optimalRange: '1500-3900 cells/MCL',
      optimalLow: 1500,
      optimalHigh: 3900,
      optimalLowWoman: 1500,
      optimalHighWoman: 3900,
      userValue: '',
    },
    {
      analyte: 'NEURTOPHILS',
      id: 'NEURTOPHILS',
      refRange: '40-74',
      refRangeLow: 40,
      refRangeHigh: 74,
      refRangeLowWoman: 40,
      refRangeHighWoman: 74,
      units: '%',
      optimalRange: '40-60 %',
      optimalLow: 40,
      optimalHigh: 60,
      optimalLowWoman: 40,
      optimalHighWoman: 60,
      userValue: '',
    },
    {
      analyte: 'LYMPHOCYTES',
      id: 'LYMPHOCYTES',
      refRange: '14-46',
      refRangeLow: 14,
      refRangeHigh: 46,
      refRangeLowWoman: 14,
      refRangeHighWoman: 46,
      units: '%',
      optimalRange: '24-44 %',
      optimalLow: 24,
      optimalHigh: 44,
      optimalLowWoman: 24,
      optimalHighWoman: 44,
      userValue: '',
    },
    {
      analyte: 'MONOCYTES',
      id: 'MONOCYTES',
      refRange: '4-13',
      refRangeLow: 4,
      refRangeHigh: 13,
      refRangeLowWoman: 4,
      refRangeHighWoman: 13,
      units: '%',
      optimalRange: '0-7 %',
      optimalLow: 0,
      optimalHigh: 7,
      optimalLowWoman: 0,
      optimalHighWoman: 7,
      userValue: '',
    },
    {
      analyte: 'EOSINOPHILS',
      id: 'EOSINOPHILS',
      refRange: '0-7',
      refRangeLow: 0,
      refRangeHigh: 7,
      refRangeLowWoman: 0,
      refRangeHighWoman: 7,
      units: '%',
      optimalRange: '0-3 %',
      optimalLow: 0,
      optimalHigh: 3,
      optimalLowWoman: 0,
      optimalHighWoman: 3,
      userValue: '',
    },
    {
      analyte: 'BASOPHILS',
      id: 'BASOPHILS',
      refRange: '0-3',
      refRangeLow: 0,
      refRangeHigh: 3,
      refRangeLowWoman: 0,
      refRangeHighWoman: 3,
      units: '%',
      optimalRange: '0-1 %',
      optimalLow: 0,
      optimalHigh: 1,
      optimalLowWoman: 0,
      optimalHighWoman: 1,
      userValue: '',
    },
    {
      analyte: 'GGT',
      id: 'GGT',
      refRange: '5-35',
      refRangeLow: 5,
      refRangeHigh: 35,
      refRangeLowWoman: 5,
      refRangeHighWoman: 35,
      units: 'U/L',
      optimalRange: '10-30     U/L',
      optimalLow: 10,
      optimalHigh: 30,
      optimalLowWoman: 10,
      optimalHighWoman: 30,
      userValue: '',
    },
    {
      analyte: 'LDH',
      id: 'LDH',
      refRange: '100-200',
      refRangeLow: 100,
      refRangeHigh: 200,
      refRangeLowWoman: 100,
      refRangeHighWoman: 200,
      units: 'U/L',
      optimalRange: '140-200 U/L',
      optimalLow: 140,
      optimalHigh: 200,
      optimalLowWoman: 140,
      optimalHighWoman: 200,
      userValue: '',
    },
    {
      analyte: 'AST (SGOT)',
      id: 'AST',
      refRange: '3-35',
      refRangeLow: 3,
      refRangeHigh: 35,
      refRangeLowWoman: 3,
      refRangeHighWoman: 35,
      units: 'U/L',
      optimalRange: '10-30     U/L',
      optimalLow: 10,
      optimalHigh: 30,
      optimalLowWoman: 10,
      optimalHighWoman: 30,
      userValue: '',
    },
    {
      analyte: 'ALT (SGPT)',
      id: 'ALT',
      refRange: '3-40',
      refRangeLow: 3,
      refRangeHigh: 40,
      refRangeLowWoman: 3,
      refRangeHighWoman: 40,
      units: 'U/L',
      optimalRange: '10-30     U/L',
      optimalLow: 10,
      optimalHigh: 30,
      optimalLowWoman: 10,
      optimalHighWoman: 30,
      userValue: '',
    },
    {
      analyte: 'ALK PHOS',
      id: 'ALK PHOS',
      refRange: '20-125',
      refRangeLow: 20,
      refRangeHigh: 125,
      refRangeLowWoman: 20,
      refRangeHighWoman: 125,
      units: 'U/L',
      optimalRange: '70-100   U/L',
      optimalLow: 70,
      optimalHigh: 100,
      optimalLowWoman: 70,
      optimalHighWoman: 100,
      userValue: '',
    },
    {
      analyte: 'TOT. BILIRUBIN',
      id: 'BILIRUBIN',
      refRange: '0.2-1.3',
      refRangeLow: 0.2,
      refRangeHigh: 1.3,
      refRangeLowWoman: 0.2,
      refRangeHighWoman: 1.3,
      units: 'mg/dL',
      optimalRange: '0.2-1.3 mg/dL',
      optimalLow: 0.2,
      optimalHigh: 1.3,
      optimalLowWoman: 0.2,
      optimalHighWoman: 1.3,
      userValue: '',
    },
    {
      analyte: 'DIRECT BIL.',
      id: 'DBILIRUBIN',
      refRange: '0.0-0.2',
      refRangeLow: 0,
      refRangeHigh: 0.2,
      refRangeLowWoman: 0,
      refRangeHighWoman: 0.2,
      units: 'mg/dL',
      optimalRange: '0.0-0.2 mg/dL',
      optimalLow: 0,
      optimalHigh: 0.2,
      optimalLowWoman: 0,
      optimalHighWoman: 0.2,
      userValue: '',
    },
    {
      analyte: 'INDIRECT BIL.',
      id: 'IBILIRUBIN',
      refRange: '0.1-1.0',
      refRangeLow: 0.1,
      refRangeHigh: 1,
      refRangeLowWoman: 0.1,
      refRangeHighWoman: 1,
      units: 'mg/dL',
      optimalRange: '0.1-1.0 mg/dL',
      optimalLow: 0.1,
      optimalHigh: 1,
      optimalLowWoman: 0.1,
      optimalHighWoman: 1,
      userValue: '',
    },
    {
      analyte: 'TOT. PROTEIN',
      id: 'PROTEIN',
      refRange: '6.0-8.3',
      refRangeLow: 6,
      refRangeHigh: 8.3,
      refRangeLowWoman: 6,
      refRangeHighWoman: 8.3,
      units: 'g/dL',
      optimalRange: '6.6-7.4 g/dL',
      optimalLow: 6.6,
      optimalHigh: 7.4,
      optimalLowWoman: 6.6,
      optimalHighWoman: 7.4,
      userValue: '',
    },
    {
      analyte: 'ALBUMIN',
      id: 'ALBUMIN',
      refRange: '3.5-4.9',
      refRangeLow: 3.5,
      refRangeHigh: 4.9,
      refRangeLowWoman: 3.5,
      refRangeHighWoman: 4.9,
      units: 'g/dL',
      optimalRange: '4.0-4.9 g/dL',
      optimalLow: 4,
      optimalHigh: 4.9,
      optimalLowWoman: 4,
      optimalHighWoman: 4.9,
      userValue: '',
    },
    {
      analyte: 'GLOBULIN',
      id: 'GLOBULIN',
      refRange: '2.3-3.4',
      refRangeLow: 2.3,
      refRangeHigh: 3.4,
      refRangeLowWoman: 2.3,
      refRangeHighWoman: 3.4,
      units: 'g/dL',
      optimalRange: '2.4-2.8 g/dL',
      optimalLow: 2.4,
      optimalHigh: 2.8,
      optimalLowWoman: 2.4,
      optimalHighWoman: 2.8,
      userValue: '',
    },
    {
      analyte: 'A/G RATIO',
      id: 'AGRATIO',
      refRange: '0.8-2.0',
      refRangeLow: 0.8,
      refRangeHigh: 2,
      refRangeLowWoman: 0.8,
      refRangeHighWoman: 2,
      units: '',
      optimalRange: '1.5-2.0',
      optimalLow: 1,
      optimalHigh: 2,
      optimalLowWoman: 1,
      optimalHighWoman: 2,
      userValue: '',
    },
    {
      analyte: 'GLUCOSE',
      id: 'GLUCOSE',
      refRange: '65-99',
      refRangeLow: 65,
      refRangeHigh: 99,
      refRangeLowWoman: 65,
      refRangeHighWoman: 99,
      units: 'mg/dL',
      optimalRange: '80-95 mg/dL',
      optimalLow: 80,
      optimalHigh: 95,
      optimalLowWoman: 80,
      optimalHighWoman: 95,
      userValue: '',
    },
    {
      analyte: 'HGB A1c',
      id: 'A1C',
      refRange: '<5.9',
      refRangeLow: 0,
      refRangeHigh: 5.9,
      refRangeLowWoman: 0,
      refRangeHighWoman: 5.9,
      units: '%',
      optimalRange: '<5.0 %',
      optimalLow: 0,
      optimalHigh: 5,
      optimalLowWoman: 0,
      optimalHighWoman: 5,
      userValue: '',
    },
    {
      analyte: 'BUN',
      id: 'BUN',
      refRange: '7-25',
      refRangeLow: 7,
      refRangeHigh: 25,
      refRangeLowWoman: 7,
      refRangeHighWoman: 25,
      units: 'mg/dL',
      optimalRange: '10-15   mg/dL',
      optimalLow: 10,
      optimalHigh: 15,
      optimalLowWoman: 10,
      optimalHighWoman: 15,
      userValue: '',
    },
    {
      analyte: 'CREATININE',
      id: 'CREATININE',
      refRange: '0.5-1.3',
      refRangeLow: 0.5,
      refRangeHigh: 1.3,
      refRangeLowWoman: 0.5,
      refRangeHighWoman: 1.3,
      units: 'mg/dL',
      optimalRange: '0.8-1.1 mg/dL',
      optimalLow: 0.8,
      optimalHigh: 1.1,
      optimalLowWoman: 0.8,
      optimalHighWoman: 1.1,
      userValue: '',
    },
    {
      analyte: 'BU/CR RATIO',
      id: 'BUCR',
      refRange: '6-25',
      refRangeLow: 6,
      refRangeHigh: 25,
      refRangeLowWoman: 6,
      refRangeHighWoman: 25,
      units: '',
      optimalRange: '10-16',
      optimalLow: 10,
      optimalHigh: 16,
      optimalLowWoman: 10,
      optimalHighWoman: 16,
      userValue: '',
    },
    {
      analyte: 'URIC ACID',
      id: 'URIC',
      refRange: 'Male:     3.4-7.0\nFemale: 2.4-6.0',
      refRangeLow: 3.4,
      refRangeHigh: 7,
      refRangeLowWoman: 2.4,
      refRangeHighWoman: 6,
      units: 'mg/dL',
      optimalRange: 'Male:     3.5-5.9 mg/dL\nFemale: 3.0-5.5 mg/dL',
      optimalLow: 3.5,
      optimalHigh: 5.9,
      optimalLowWoman: 3,
      optimalHighWoman: 5.5,
      userValue: '',
    },
    {
      analyte: 'CALCIUM',
      id: 'CALCIUM',
      refRange: '8.5-10.4',
      refRangeLow: 8.5,
      refRangeHigh: 10.4,
      refRangeLowWoman: 8.5,
      refRangeHighWoman: 10.4,
      units: 'mg/dL',
      optimalRange: '9.4-10.0 mg/dL',
      optimalLow: 9.4,
      optimalHigh: 10,
      optimalLowWoman: 9.4,
      optimalHighWoman: 10,
      userValue: '',
    },
    {
      analyte: 'PHOSPHORUS',
      id: 'PHOSPHORUS',
      refRange: '2.5-4.5',
      refRangeLow: 2.5,
      refRangeHigh: 4.5,
      refRangeLowWoman: 2.5,
      refRangeHighWoman: 4.5,
      units: 'mg/dL',
      optimalRange: '3.4-4.0   mg/dL',
      optimalLow: 3.4,
      optimalHigh: 4,
      optimalLowWoman: 3.4,
      optimalHighWoman: 4,
      userValue: '',
    },
    {
      analyte: 'SODIUM',
      id: 'SODIUM',
      refRange: '135-146',
      refRangeLow: 135,
      refRangeHigh: 146,
      refRangeLowWoman: 135,
      refRangeHighWoman: 146,
      units: 'mmol/L',
      optimalRange: '135-142 mmol/L',
      optimalLow: 135,
      optimalHigh: 142,
      optimalLowWoman: 135,
      optimalHighWoman: 142,
      userValue: '',
    },
    {
      analyte: 'POTASSIUM',
      id: 'POTASSIUM',
      refRange: '3.5-5.3',
      refRangeLow: 3.5,
      refRangeHigh: 5.3,
      refRangeLowWoman: 3.5,
      refRangeHighWoman: 5.3,
      units: 'mmol/L',
      optimalRange: '4.0-4.5   mmol/L',
      optimalLow: 4,
      optimalHigh: 4.5,
      optimalLowWoman: 4,
      optimalHighWoman: 4.5,
      userValue: '',
    },
    {
      analyte: 'CHLORIDE',
      id: 'CHLORIDE',
      refRange: '98-110',
      refRangeLow: 98,
      refRangeHigh: 110,
      refRangeLowWoman: 98,
      refRangeHighWoman: 110,
      units: 'mmol/L',
      optimalRange: '100-106 mmol/L',
      optimalLow: 100,
      optimalHigh: 106,
      optimalLowWoman: 100,
      optimalHighWoman: 106,
      userValue: '',
    },
    {
      analyte: 'CO2',
      id: 'CO2',
      refRange: '21-33',
      refRangeLow: 21,
      refRangeHigh: 33,
      refRangeLowWoman: 21,
      refRangeHighWoman: 33,
      units: 'mmol/L',
      optimalRange: '26-31     mmol/L',
      optimalLow: 26,
      optimalHigh: 31,
      optimalLowWoman: 26,
      optimalHighWoman: 31,
      userValue: '',
    },
    {
      analyte: 'ANION GAP',
      id: 'ANIONGAP',
      refRange: '8-16',
      refRangeLow: 8,
      refRangeHigh: 16,
      refRangeLowWoman: 8,
      refRangeHighWoman: 16,
      units: 'mmol/L',
      optimalRange: '8-12       mmol/L',
      optimalLow: 8,
      optimalHigh: 12,
      optimalLowWoman: 8,
      optimalHighWoman: 12,
      userValue: '',
    },
    {
      analyte: 'CHOLESTEROL',
      id: 'CHOLESTEROL',
      refRange: '125-200',
      refRangeLow: 125,
      refRangeHigh: 200,
      refRangeLowWoman: 125,
      refRangeHighWoman: 200,
      units: 'mg/dL',
      optimalRange: '150-220 mg/dL',
      optimalLow: 150,
      optimalHigh: 220,
      optimalLowWoman: 150,
      optimalHighWoman: 220,
      userValue: '',
    },
    {
      analyte: 'TRIGLYCERIDES',
      id: 'TRIGLYCERIDES',
      refRange: '<150',
      refRangeLow: 0,
      refRangeHigh: 150,
      refRangeLowWoman: 0,
      refRangeHighWoman: 150,
      units: 'mg/dL',
      optimalRange: '70-100 mg/DL  (or 40-60% of\ntotal cholesterol)',
      optimalLow: 70,
      optimalHigh: 100,
      optimalLowWoman: 70,
      optimalHighWoman: 100,
      userValue: '',
    },
    {
      analyte: 'HDL',
      id: 'HDL',
      refRange: '>39',
      refRangeLow: 0,
      refRangeHigh: 39,
      refRangeLowWoman: 0,
      refRangeHighWoman: 39,
      units: 'mg/dL',
      optimalRange: '50-60 mg/dL',
      optimalLow: 50,
      optimalHigh: 60,
      optimalLowWoman: 50,
      optimalHighWoman: 60,
      userValue: '',
    },
    {
      analyte: 'LDL',
      id: 'LDL',
      refRange: '< 100',
      refRangeLow: 0,
      refRangeHigh: 100,
      refRangeLowWoman: 0,
      refRangeHighWoman: 100,
      units: 'mg/dL',
      optimalRange: '<100 mg/dL',
      optimalLow: 0,
      optimalHigh: 100,
      optimalLowWoman: 0,
      optimalHighWoman: 100,
      userValue: '',
    },
    {
      analyte: 'Hs-CRP',
      id: 'HSCRP',
      refRange:
        '< 1.0 mg/L Cardiac risk Low: <1.0\nAverage: 1.0-\n3.0\nHigh: >3.0',
      refRangeLow: 1,
      refRangeHigh: 3,
      refRangeLowWoman: 1,
      refRangeHighWoman: 3,
      units: 'mg/dL',
      optimalRange: '<100 mg/dL',
      optimalLow: 0,
      optimalHigh: 100,
      optimalLowWoman: 0,
      optimalHighWoman: 100,
      userValue: '',
    },
    {
      analyte: 'IRON',
      id: 'IRON',
      refRange: '40-175',
      refRangeLow: 40,
      refRangeHigh: 175,
      refRangeLowWoman: 40,
      refRangeHighWoman: 175,
      units: 'ug/dL',
      optimalRange: '50-100 ug/dL',
      optimalLow: 50,
      optimalHigh: 100,
      optimalLowWoman: 50,
      optimalHighWoman: 100,
      userValue: '',
    },
    {
      analyte: 'TIBC',
      id: 'TIBC',
      refRange: '250-450',
      refRangeLow: 250,
      refRangeHigh: 450,
      refRangeLowWoman: 250,
      refRangeHighWoman: 450,
      units: 'ug/dL',
      optimalRange: '250-350 ug/dL',
      optimalLow: 250,
      optimalHigh: 350,
      optimalLowWoman: 250,
      optimalHighWoman: 350,
      userValue: '',
    },
    {
      analyte: '% SATURATION',
      id: 'SATURATION',
      refRange: '15-50',
      refRangeLow: 15,
      refRangeHigh: 50,
      refRangeLowWoman: 15,
      refRangeHighWoman: 50,
      units: '%',
      optimalRange: '20-45 %',
      optimalLow: 20,
      optimalHigh: 45,
      optimalLowWoman: 20,
      optimalHighWoman: 45,
      userValue: '',
    },
    {
      analyte: 'FERRITIN',
      id: 'FERRITIN',
      refRange: 'Male: 12-300\nFemale: 10-150',
      refRangeLow: 12,
      refRangeHigh: 300,
      refRangeLowWoman: 10,
      refRangeHighWoman: 150,
      units: 'ng/mL',
      optimalRange: 'Male: 20-200 ng/mL\nFemale: 10-110 ng/mL',
      optimalLow: 20,
      optimalHigh: 200,
      optimalLowWoman: 10,
      optimalHighWoman: 110,
      userValue: '',
    },
    {
      analyte: 'TSH',
      id: 'TSH',
      refRange: '0.4-4.5',
      refRangeLow: 0.4,
      refRangeHigh: 4.5,
      refRangeLowWoman: 0.4,
      refRangeHighWoman: 4.5,
      units: 'microunits/mL',
      optimalRange: '1.5-3.0  microunits/mL',
      optimalLow: 1.5,
      optimalHigh: 3,
      optimalLowWoman: 1.5,
      optimalHighWoman: 3,
      userValue: '',
    },
    {
      analyte: 'TOT. T4',
      id: 'TOTT4',
      refRange: '5-12',
      refRangeLow: 5,
      refRangeHigh: 12,
      refRangeLowWoman: 5,
      refRangeHighWoman: 12,
      units: 'mcg/dL',
      optimalRange: '5-12       mcg/dL',
      optimalLow: 5,
      optimalHigh: 12,
      optimalLowWoman: 5,
      optimalHighWoman: 12,
      userValue: '',
    },
    {
      analyte: 'FREE T4',
      id: 'T4',
      refRange: '.8-2.4',
      refRangeLow: 0.8,
      refRangeHigh: 2.4,
      refRangeLowWoman: 0.8,
      refRangeHighWoman: 2.4,
      units: 'ng/dL',
      optimalRange: '.8-2.4     ng/dL',
      optimalLow: 0.8,
      optimalHigh: 2.4,
      optimalLowWoman: 0.8,
      optimalHighWoman: 2.4,
      userValue: '',
    },
    {
      analyte: 'TOT. T3',
      id: 'T3',
      refRange: '60-181',
      refRangeLow: 60,
      refRangeHigh: 181,
      refRangeLowWoman: 60,
      refRangeHighWoman: 181,
      units: 'ng/dL',
      optimalRange: '60-181   ng/dL',
      optimalLow: 60,
      optimalHigh: 181,
      optimalLowWoman: 60,
      optimalHighWoman: 181,
      userValue: '',
    },
    {
      analyte: 'FREE T3',
      id: 'FT3',
      refRange: '230-420',
      refRangeLow: 230,
      refRangeHigh: 420,
      refRangeLowWoman: 230,
      refRangeHighWoman: 420,
      units: 'pg/dL',
      optimalRange: '230-420 pg/dL',
      optimalLow: 230,
      optimalHigh: 420,
      optimalLowWoman: 230,
      optimalHighWoman: 420,
      userValue: '',
    },
    {
      analyte: 'VITAMIN D 25-OH',
      id: 'VITAMIND',
      refRange: '30         Deficient\n30-100 Sufficient\n100       Toxic',
      refRangeLow: 30,
      refRangeHigh: 100,
      refRangeLowWoman: 30,
      refRangeHighWoman: 100,
      units: 'ng/mL',
      optimalRange: '40-65 ng/mL',
      optimalLow: 40,
      optimalHigh: 65,
      optimalLowWoman: 40,
      optimalHighWoman: 65,
      userValue: '',
    },
    {
      analyte: 'eGFR',
      id: 'EGFR',
      refRange:
        '>60 mL/min/1.73m2\n(<50 mL/min is defined as chronic kidney disease)',
      refRangeLow: 60,
      refRangeHigh: 'Up',
      refRangeLowWoman: 60,
      refRangeHighWoman: 'Up',
      units: 'mL/min',
      optimalRange: '',
      optimalLow: '',
      optimalHigh: '',
      optimalLowWoman: '',
      optimalHighWoman: '',
      userValue: '',
    },
  ],
};
