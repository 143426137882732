import React from 'react';
import './Searchbar.scss';

function Searchbar({ searchTerm, placeholder, handleSearch }) {
  return (
    <div className="search">
      <input
        className="form-control"
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
      />
    </div>
  );
}

export default Searchbar;
