import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PageTabs.scss';

function Tabs({ selectedTabId, tabs, handleSetSelectedTab }) {
  const navigate = useNavigate();
  return (
    <div className="page-tabs">
      {tabs.map((t) => (
        <div
          key={t.id}
          className={`tab ${selectedTabId === t.id ? 'active' : ''}`}
          onClick={() =>
            t.path ? navigate(t.path) : handleSetSelectedTab(t.id)
          }
        >
          <span className={`${selectedTabId === t.id ? '' : 'faded'}`}>
            {t.label}
          </span>
        </div>
      ))}
    </div>
  );
}

export default Tabs;
