import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navigation from '../../layout/Navigation/Navigation';
import { questionnaire } from '../symptoms/questionaireData';
import '../elimination/elimination.scss';
import { dateIsInWeek } from '../../helpers/helpers';
import { getNoticeCount } from './labsHelpers';
import { v4 as uuidv4 } from 'uuid';
import Placeholder from '../../components/Placeholder/Placeholder';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

/* const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: ['#2A9D8F', '#d92222', '#d92222'],
      borderColor: ['#2A9D8F', '#d92222', '#d92222'],
      borderWidth: 0,
    },
  ],
}; */

function Labs({ type, currentUser, fbUser, combinedDates }) {
  const navigate = useNavigate();
  const [dataWithLabs, setDataWithLabs] = useState(null);
  const newLabId = uuidv4();

  useEffect(() => {
    const d = currentUser?.labs;

    if (!d) return;
    const array = Object.values(d);
    const arrayWithNoticeCounts = array.map((a) => ({
      ...a,
      noticeCounts: getNoticeCount(a.data, a.sex),
    }));
    setDataWithLabs(arrayWithNoticeCounts);
  }, [currentUser, combinedDates]);

  useEffect(() => {
    if (!currentUser || !combinedDates) return;
    const tId = combinedDates.find((c) => dateIsInWeek(c))?.id;

    const element = document.getElementById(`labs-${tId}`);
    if (!element) return;
    if (type) return;
    setTimeout(() => {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 500);
  }, [currentUser, combinedDates]);

  return (
    <>
      <Navigation currentUser={currentUser} fbUser={fbUser} />
      <div
        className={
          type
            ? 'elimination horizontal labs'
            : `elimination page page-with-footer labs`
        }
      >
        <h1>
          Lab Tracker
          {type && <Link to="/labs">View All</Link>}
        </h1>
        {!type && (
          <>
            <div style={{ fontSize: '.85em' }}>
              Keep a record of your labs and compare them to the optimal
              Functional Medicine ranges.
            </div>
            <br />
          </>
        )}
        {!type && (
          <div
            className="floating-button"
            onClick={() => navigate(`/labs/${newLabId}/add`)}
          >
            <img src="/icons/add.svg" alt="add" />
          </div>
        )}
        <div className="grid">
          {!dataWithLabs && type && (
            <div
              className="grid-item empty"
              /* onClick={() => navigate('/calendar')} */
            >
              {/*  <img src="/icons/add.svg" alt="add" style={{ height: '40px' }} /> */}
              Nothing here yet...
            </div>
          )}
          {!dataWithLabs && !type && (
            <Placeholder
              title="Add labs to the Lab Tracker"
              image="/icons/sparkles-outline.svg"
              link="Add New Lab"
              route={`/labs/${newLabId}/add`}
            />
          )}
          {dataWithLabs?.map((m, idx) => (
            <div
              key={`labs-${m.fbId || 1}`}
              id={`labs-${m.fbId || 1}`}
              className={`grid-item grid-item-elimination`}
              onClick={() => navigate(`/labs/${m.fbId}/edit`)}
            >
              <h3 className="">
                {m.date &&
                  new Date(m?.date)?.toLocaleDateString('en-US', {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
              </h3>

              <div style={{ marginTop: '5px' }}>
                <div className="success">Optimal: {m.noticeCounts.optimal}</div>
                <div className="danger">Low: {m.noticeCounts.low}</div>
                <div className="danger">High: {m.noticeCounts.high}</div>
              </div>
              <div
                style={{
                  height: '80px',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '20px',
                }}
              >
                <Pie
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                    },
                  }}
                  data={{
                    labels: ['Optimal', 'Low', 'High'],
                    datasets: [
                      {
                        data: [
                          m.noticeCounts.optimal,
                          m.noticeCounts.low,
                          m.noticeCounts.high,
                        ],
                        backgroundColor: ['#2A9D8F', '#f22424', '#f22424'],
                        borderColor: ['#2A9D8F', '#f22424', '#f22424'],
                        borderWidth: 0,
                      },
                    ],
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Labs;
