import React from 'react';
import './Button.scss';

function Button({ children, type, callback }) {
  return (
    <div className={`button ${type ? type : ''}`} onClick={() => callback()}>
      {children}
    </div>
  );
}

export default Button;
