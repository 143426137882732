import React, { useEffect, useState } from 'react';
import Points from '../../symptoms/points';
import DatePicker from 'sassy-datepicker';
import '../../symptoms/symptoms.scss';
import Button from '../../../components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addNewLifestyleToLifestyle,
  updateLifestyle,
} from '../../../helpers/api';
import { weeks, categoriesWithData } from '../../../helpers/systemData';
import Header from '../../../components/Header/Header';
import Collapse from '../../../components/collapse/Collapse';

function AddLifestyle({
  currentUser,
  setCurrentUser,
  isEdit,
  isBaseline,
  combined,
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([...categoriesWithData]);
  const [date, setDate] = useState(new Date());
  const [toggledPicker, setToggledPicker] = useState(false);
  const [totalScore, setTotalScore] = useState(null);
  const [formCompleted, setFormCompleted] = useState(false);
  const [currentWeek, setCurrentWeek] = useState(weeks[0]);
  const [instructionsCollapsed, setInstructionsCollapsed] = useState(false);

  const handleSetPointValue = (newObject, id, value) => {
    const newCategoriesWithData = [...data];
    newCategoriesWithData.forEach((c) => {
      c.data.forEach((cc) => {
        if (cc.id === id) {
          cc.Points = newObject.Options.find((n) => n.value === value).points;
          cc.Score = newObject.Score;
        }
      });
    });
    setData(newCategoriesWithData);
    const pointsTotal = newCategoriesWithData
      .map((n) => n.data.map((d) => d.Points))
      .flat();

    if (pointsTotal.filter((p) => p === null || p === undefined).length === 0) {
      setFormCompleted(true);
    } else {
      setFormCompleted(false);
    }

    setTotalScore(pointsTotal?.reduce((partialSum, a) => partialSum + a, 0));
  };

  useEffect(() => {
    if (isEdit) return;
    if (!data) setData([...categoriesWithData]);
  }, [data, id]);

  const onDateChange = (dateValue) => {
    setDate(dateValue);
    setToggledPicker(false);
  };

  const submitQuestionaire = () => {
    if (formCompleted) {
      //submit to firebase
      const submission = {
        id: currentWeek.id || 0,
        week: currentWeek.week || 0,
        date: date.toDateString(),
        data: data.map((d) => d.data).flat(),
        total: totalScore,
        notes: '',
      };

      if (isEdit) {
        updateLifestyle(submission, currentUser, setCurrentUser);
      } else {
        addNewLifestyleToLifestyle(submission, currentUser, setCurrentUser);
      }
      navigate(`/lifestyle/${id}`);
    }
  };

  //new code
  useEffect(() => {
    const d = currentUser?.lifestyle;

    if (!d) return;

    //getCurrentWeek and populate date
    const c = combined?.find((cc) => +cc.id === +id);
    setDate(new Date(c?.startDate));
    setCurrentWeek(weeks?.find((w) => w.id === +id));

    if (isEdit) {
      const existingData = Object.values(d);
      const newCategoriesWithData = categoriesWithData.map((c) => {
        c.data.forEach((cc) => {
          const match = existingData
            ?.find((d) => +d.id === +id)
            ?.data.find((d) => cc.id === d.id);
          if (match) {
            cc.Score = match.Score;
            cc.Points = match.Points;
          }
        });
        return c;
      });
      setData(newCategoriesWithData);
    }
  }, [currentUser, id, isEdit, combined]);

  return (
    <div className="questionaire page">
      {!isBaseline && (
        <Header
          title="Daily Activity Log"
          isEdit={isEdit}
          backRoute={`/lifestyle/${id}`}
        />
      )}

      <Collapse
        title="Instructions"
        collapsed={instructionsCollapsed}
        handleCollapse={() => setInstructionsCollapsed(!instructionsCollapsed)}
      >
        <div className="line-item" style={{ flexDirection: 'column' }}>
          <div>
            <p>We suggest the following activities for your daily routine.</p>
            <p>
              If you are filling out this form for the first time, please
              reflect on the past two weeks and provide your best possible
              answers to the questions.
            </p>
            <p>
              It is helpful to maintain a comprehensive record of these
              activities by using your notes app or writing them down. This will
              ensure that you can accurately complete the questionnaire below
              and effectively track your progress every two months.
            </p>
            <p>
              <b>
                To ensure your safety given your current condition, it is
                advisable to consult with your doctor, trainer, and nutritionist
                regarding the suitability of the activities listed below
              </b>
              .
            </p>
          </div>
        </div>
      </Collapse>
      <br />

      <div className="form-item">
        <label>
          {currentWeek.id === 0
            ? 'Date'
            : `Start Date for Week ${currentWeek.week}`}
        </label>

        <div
          className="form-control"
          onClick={() => setToggledPicker(!toggledPicker)}
        >
          {date.toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </div>
        {toggledPicker && <DatePicker onChange={onDateChange} />}
      </div>
      <h2 style={{ marginTop: 20, marginBottom: -10 }}>
        <b>In the past 2 weeks...</b>
      </h2>
      {data.map((q, idx) => (
        <div className="line-item" key={idx}>
          <h4>{q.Category}</h4>
          {q.data.map((d, index) => (
            <div key={`question-${index}`}>
              {d.Question}
              <Points
                item={d}
                handleSetPointValue={handleSetPointValue}
                score={d.Score || null}
                pointsData={d.Options.map((dd) => dd.value)}
              />
            </div>
          ))}
        </div>
      ))}

      <div className="footer">
        <Button
          callback={() => submitQuestionaire()}
          type={formCompleted ? 'primary' : 'disabled'}
        >
          {formCompleted
            ? `Submit Score of ${totalScore}`
            : `Submit Score of ${totalScore || 0}`}
        </Button>
      </div>
    </div>
  );
}

export default AddLifestyle;
