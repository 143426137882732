// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  databaseURL: 'https://thefmd-7a1e6-default-rtdb.firebaseio.com',
  apiKey: 'AIzaSyAtk7PTP8HWj71PiZSBPM7YQbfaKgrKRXY',
  authDomain: 'thefmd-7a1e6.firebaseapp.com',
  projectId: 'thefmd-7a1e6',
  storageBucket: 'thefmd-7a1e6.appspot.com',
  messagingSenderId: '786629977993',
  appId: '1:786629977993:web:cd07ff698d4ebd30062358',
  measurementId: 'G-GQ7ENVN3PY',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const db = getFirestore(app);
const auth = getAuth(app);

export const fire = app;
export const fireStorage = storage;
export const fireStore = db;
export const authFire = auth;
