import { weeks } from './systemData';
import _ from 'lodash';

export const sexOptions = ['Male', 'Female'];

export const getCurrentWeek = (data, isEdit, id, combined) => {
  if (!data || data.length === 0) {
    return weeks[0];
  }
  const lastId = data
    .map((m) => m.id)
    .sort((a, b) => +a - +b)
    .pop();

  let currentWeek;
  if (isEdit) {
    currentWeek = combined.find((w) => +w.id === +id);
  } else {
    currentWeek = combined.find((w) => w.id === lastId + 1);
  }
  return currentWeek;
};

const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getCalendarData = (currentUser, setDates) => {
  if (!currentUser || !currentUser?.symptoms) return;
  let symptoms;
  let diets;
  let lifestyle;

  if (currentUser.diets) {
    diets = Object?.values(currentUser?.diets) || [];
  }
  if (currentUser.symptoms) {
    symptoms = Object?.values(currentUser?.symptoms) || [];
  }
  if (currentUser.lifestyle) {
    lifestyle = Object?.values(currentUser?.lifestyle) || [];
  }

  const baseline = symptoms.find((c) => +c.id === 1);
  const dates = getWeeksWithDates(baseline);
  setDates(dates);

  const combined = dates.map((d, index) => {
    return {
      ...d,
      diet: diets ? diets?.find((d) => +d.id === index + 1) : null,
      lifestyle: lifestyle ? lifestyle?.find((d) => +d.id === index + 1) : null,
      symptoms: symptoms ? symptoms?.find((s) => +s.id === index + 1) : [],
    };
  });
  return combined || [];
};

export const dateIsInWeek = (week) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const weekStart = new Date(week.startDate);
  const weekEnd = new Date(week.endDate);
  weekStart.setHours(0, 0, 0, 0);
  weekEnd.setHours(0, 0, 0, 0);
  if (
    today.getTime() <= weekEnd.getTime() &&
    today.getTime() >= weekStart.getTime()
  ) {
    return true;
  }
  return false;
};

const addWeeksToDate = (dateObj, numberOfDays) => {
  dateObj.setDate(dateObj.getDate() + numberOfDays);
  return dateObj;
};

export const getWeeksWithDates = (baseline) => {
  let weeksWithDates;
  const base = new Date(_.cloneDeep(baseline.date));
  const startDateForAll = new Date(_.cloneDeep(baseline.date));
  const remainingWeeks = weeks.slice(1, weeks.length - 1);

  const remaining = remainingWeeks.map((w, index) => {
    const futureDate = new Date(addWeeksToDate(startDateForAll, 14));
    const thirteenDaysAdded = addDays(futureDate, 13);
    return {
      ...w,
      startDate: new Date(futureDate),
      endDate: thirteenDaysAdded,
    };
  });

  weeksWithDates = [
    {
      ...baseline,
      startDate: base,
      endDate: addDays(base, 13),
    },
    ...remaining,
  ];
  return weeksWithDates;
};

export const goBack = (navigate, backRoute) => {
  window.onpopstate = () => {
    navigate('/');
    return;
  };

  if (backRoute) {
    navigate(backRoute);
    return;
  }

  navigate(-1);
};

function pad(i) {
  return i < 10 ? `0${i}` : `${i}`;
}

export const formatDateTime = (date) => {
  const year = date.getUTCFullYear();
  const month = pad(date.getUTCMonth() + 1);
  const day = pad(date.getUTCDate());
  const hour = pad(date.getUTCHours());
  const minute = pad(date.getUTCMinutes());
  const second = pad(date.getUTCSeconds());
  return `${year}${month}${day}T${hour}${minute}${second}Z`;
};

export const remindMe = (currentUser) => {
  const objData = currentUser.diets || currentUser.symptoms;
  if (objData) {
    const startData = Object.values(objData);
    const firstData = startData.find((s) => s.week === 1);
    const firstDate = new Date(firstData.date);
    const formattedDate = formatDateTime(firstDate);
    window.open(
      `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${formattedDate}&text=The%20FMD%20Reminder&details=The-fmd.com%3A%20Record%20your%20diet%2C%20symptoms%2C%20lifestyle&location=&text=&recur=RRULE:FREQ=WEEKLY;INTERVAL=2;COUNT=50`,
      '_blank'
    );
  }
};
