export const getAllPoints = (questionaireFB, questionnaire) => {
  const points = questionaireFB
    .map((m) => ({
      x: `${m.week}`,
      y: +m.total,
    }))
    ?.sort((a, b) => +a.x - +b.x);

  const totalChart = [
    {
      id: 'Total Symptoms Score',
      color: 'purple',
      data: points,
      chartDataWeeks: points.map((p) => p.x),
      chartDataPoints: points.map((p) => p.y),
    },
  ];

  const categories = [...new Set(questionnaire.map((q) => q.Category))];

  const generateData = (c) => {
    const filteredByCategory = questionaireFB.map((m) =>
      m.data.filter((d) => d.Category === c)
    );
    const scoresByCategory = filteredByCategory;
    return scoresByCategory;
  };

  const charts = categories
    .map((c, index) => {
      return {
        id: index + 1,
        title: c,
        data: generateData(c),
      };
    })
    .map((cc) => ({
      id: cc.title,
      color: 'purple',
      data: cc.data
        .map((ccc) =>
          ccc.map((cccc) => cccc.Score)?.reduce((total, item) => total + item)
        )
        .map((t, index) => ({
          x: questionaireFB.find((m) => m.id === index + 1)?.week,
          y: +t,
        })),
      chartDataWeeks: cc.data
        .map((ccc) =>
          ccc.map((cccc) => cccc.Score)?.reduce((total, item) => total + item)
        )
        .map(
          (t, index) => questionaireFB.find((m) => m.id === index + 1)?.week
        ),
      chartDataPoints: cc.data
        .map((ccc) =>
          ccc.map((cccc) => cccc.Score)?.reduce((total, item) => total + item)
        )
        .map((t) => +t),
    }));

  const chartsWithTotal = [...totalChart, ...charts];
  return chartsWithTotal;
};
