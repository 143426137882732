import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { confetti } from 'tsparticles-confetti';
import './baseline.scss';
import { remindMe } from '../../helpers/helpers';

function BaselineThree({ currentUser, fbUser }) {
  const navigate = useNavigate();

  const duration = 2 * 1000,
    animationEnd = Date.now() + duration,
    defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  const run = () => {
    const interval = setInterval(function () {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);

      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);
  };

  //run();

  return (
    <div className="baseline baseline-padding page">
      <div className="user-data">
        <div className="logo-font logo">FMD</div>
        {/* <img src={fbUser?.photoURL} alt="avatar" className="avatar" />
        <p>{currentUser?.displayName}</p> */}
        <h1>What's next</h1>
      </div>

      <div className="onboarding-body">
        {/* <h2>Start your Health Journey</h2> */}
        <table>
          <tr>
            <td className="large">1</td>
            <td>
              To keep you on track,
              <a onClick={() => remindMe(currentUser)}>
                {' '}
                click this to add reminders to your calendar
              </a>
              .
            </td>
          </tr>

          {/*  <tr>
            <td className="large">2</td>
            <td>
              Utilize your dashboard and calendar as a tool to assist you in
              your efforts to decrease consumption of inflammatory foods through
              the implementation of an Elimination Diet.
            </td>
          </tr> */}
          <tr>
            <td className="large">2</td>
            <td>
              Exclude all processed foods from your diet. They lack the vital
              information present in whole foods (peptides and structure), which
              can cause confusion in your body's response to it.
            </td>
          </tr>
          <tr>
            <td className="large">3</td>
            <td>
              Make sure to get sufficient sleep, sun exposure,
              <a
                href="https://twitter.com/HealthOverhaul/status/1686695249908047872"
                target="_blank"
                rel="noreferrer"
              >
                grounding/earthing
              </a>
              , and exercise and track these on this platform.
            </td>
          </tr>
          <tr>
            <td className="large">4</td>
            <td>
              Safeguard yourself from WiFi and radiation-emitting devices while
              sleeping by enabling airplane mode, using a timer to automatically
              turn off WiFi while sleeping, and maintaining a safe distance from
              electronic gadgets.
            </td>
          </tr>
          <tr>
            <td className="large">5</td>
            <td>
              Browse through our list of available Functional Medicine
              practitioners in your area and determine if you wish to contact
              any of them. These MDs/practitioners are trained to help you find
              the root cause of your chronic conditions and treat those rather
              than prescribing drugs to treat symptoms.
            </td>
          </tr>
        </table>
        <br />
        <br />
        <br />
      </div>
      <div className="footer">
        <Button callback={() => navigate('/')}>
          Continue to Dashboard
          <img className="next" src="/icons/chevron-down.svg" alt="Next" />
        </Button>
      </div>
    </div>
  );
}

export default BaselineThree;
