import { useEffect, useState } from 'react';
import './App.scss';
import { Routes, Route, Navigate } from 'react-router-dom';
import Elimination from './pages/elimination/elimination';
import Home from './pages/home/home';
import AddElimination from './pages/elimination/addElimination/addElimination';
import Symptoms from './pages/symptoms/symptoms';
import SymptomsDetail from './pages/symptoms/symptomsDetail';
import EliminationDetail from './pages/elimination/eliminationDetail';
import AddSymptoms from './pages/symptoms/addSymptoms/addSymptoms';
import Doctors from './pages/doctors/doctors';
import Chart from './pages/chart/chart';
import Landing from './pages/landing/landing';
import Logout from './pages/logout/logout';
import Calendar from './pages/calendar/calendar';

/* Firebase */
import { fire } from './helpers/fire';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Baseline from './pages/baseline/baseline';
import BaselineOne from './pages/baseline/baselineone';
import BaselineTwo from './pages/baseline/baselinetwo';
import BaselineThree from './pages/baseline/baselinethree';
import { getCalendarData } from './helpers/helpers';
import { checkIfFbUserThenSetDb } from './helpers/api';
import Privacy from './pages/terms/privacy';
import Terms from './pages/terms/terms';
import LabsContent from './pages/labs/labsContent';
import Labs from './pages/labs/labs';
import AddLabs from './pages/labs/addLabs/addLabs';
import LabsDetail from './pages/labs/labsDetail';
import Lifestyle from './pages/lifestyle/lifestyle';
import AddLifestyle from './pages/lifestyle/addLifestyle/addLifestyle';
import LifestyleDetail from './pages/lifestyle/lifestyleDetail';
import Admin from './pages/admin/admin';
import AdminDetail from './pages/admin/adminDetail';

const auth = getAuth(fire);

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [fbUser, setFbUser] = useState(null);
  const [dates, setDates] = useState(null);
  const [combined, setCombined] = useState(null);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setFbUser(user);
      checkIfFbUserThenSetDb(currentUser, setCurrentUser);
      //checkIfFbUserThenSetDb(user, currentUser, setCurrentUser);
      return;
    } else {
      setCurrentUser(null);
    }
    return;
  });

  useEffect(() => {
    setCombined(getCalendarData(currentUser, setDates));
  }, [currentUser]);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/diet"
          element={
            <Elimination
              combinedDates={combined}
              currentUser={currentUser}
              fbUser={fbUser}
            />
          }
        />
        <Route
          path="/diet/:id"
          element={
            <EliminationDetail currentUser={currentUser} fbUser={fbUser} />
          }
        />
        <Route
          path="/diet/add"
          element={<AddElimination currentUser={currentUser} fbUser={fbUser} />}
        />
        <Route
          path="/diet/:id/edit"
          element={
            <AddElimination
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
              fbUser={fbUser}
              isEdit={true}
              combined={combined}
            />
          }
        />
        <Route
          path="/diet/:id/add"
          element={
            <AddElimination
              setCurrentUser={setCurrentUser}
              currentUser={currentUser}
              fbUser={fbUser}
              isAdd={true}
              combined={combined}
            />
          }
        />
        <Route
          path="/symptoms"
          element={
            <Symptoms
              combinedDates={combined}
              currentUser={currentUser}
              fbUser={fbUser}
            />
          }
        />
        <Route
          path="/privacy"
          element={<Privacy currentUser={currentUser} fbUser={fbUser} />}
        />
        <Route
          path="/terms"
          element={<Terms currentUser={currentUser} fbUser={fbUser} />}
        />
        <Route
          path="/symptoms/:id"
          element={<SymptomsDetail currentUser={currentUser} fbUser={fbUser} />}
        />
        <Route
          path="/symptoms/:id/add"
          element={
            <AddSymptoms
              setCurrentUser={setCurrentUser}
              currentUser={currentUser}
              fbUser={fbUser}
              isAdd={true}
              combined={combined}
            />
          }
        />
        <Route
          path="/symptoms/:id/edit"
          element={
            <AddSymptoms
              setCurrentUser={setCurrentUser}
              currentUser={currentUser}
              fbUser={fbUser}
              isEdit={true}
              combined={combined}
            />
          }
        />
        <Route
          path="/lifestyle/:id"
          element={
            <LifestyleDetail
              combinedDates={combined}
              currentUser={currentUser}
              fbUser={fbUser}
            />
          }
        />
        <Route
          path="/lifestyle/:id/add"
          element={
            <AddLifestyle
              setCurrentUser={setCurrentUser}
              currentUser={currentUser}
              fbUser={fbUser}
              isEdit={false}
              combined={combined}
            />
          }
        />

        <Route
          path="/lifestyle/:id/edit"
          element={
            <AddLifestyle
              setCurrentUser={setCurrentUser}
              currentUser={currentUser}
              fbUser={fbUser}
              isEdit={true}
              combined={combined}
            />
          }
        />

        <Route
          path="/lifestyle"
          element={<Lifestyle currentUser={currentUser} fbUser={fbUser} />}
        />

        <Route
          path="/labs"
          element={<Labs currentUser={currentUser} fbUser={fbUser} />}
        />

        <Route
          path="/labs/:id"
          element={<LabsDetail currentUser={currentUser} fbUser={fbUser} />}
        />
        <Route
          path="/labs/:id/add"
          element={
            <AddLabs
              setCurrentUser={setCurrentUser}
              currentUser={currentUser}
              fbUser={fbUser}
              isAdd={true}
              combined={combined}
            />
          }
        />
        <Route
          path="/labs/:id/edit"
          element={
            <AddLabs
              setCurrentUser={setCurrentUser}
              currentUser={currentUser}
              fbUser={fbUser}
              isEdit={true}
              combined={combined}
            />
          }
        />
        <Route
          path="/practitioners"
          element={<Doctors currentUser={currentUser} fbUser={fbUser} />}
        />
        <Route
          path="/labs-reference"
          element={<LabsContent currentUser={currentUser} fbUser={fbUser} />}
        />
        <Route
          path="/calendar"
          element={
            <Calendar
              currentUser={currentUser}
              fbUser={fbUser}
              combined={combined}
            />
          }
        />

        <>
          <Route path="/admin" element={<Admin currentUser={currentUser} />} />
          <Route
            path="/admin/:id"
            element={<AdminDetail currentUser={currentUser} />}
          />
        </>

        {currentUser && !currentUser.symptoms && (
          <>
            <Route
              path="/baseline"
              element={<Baseline currentUser={currentUser} fbUser={fbUser} />}
            />
            <Route
              path="/baseline-1"
              element={
                <BaselineOne
                  setCurrentUser={setCurrentUser}
                  currentUser={currentUser}
                  fbUser={fbUser}
                  combined={combined}
                />
              }
            />
          </>
        )}
        <Route
          path="/baseline-2"
          element={<BaselineTwo currentUser={currentUser} fbUser={fbUser} />}
        />
        <Route
          path="/baseline-3"
          element={<BaselineThree currentUser={currentUser} fbUser={fbUser} />}
        />
        <Route
          path="/progress"
          element={
            <Chart
              currentUser={currentUser}
              fbUser={fbUser}
              combined={combined}
            />
          }
        />
        <Route path="/sign-out" element={<Logout />} />

        {currentUser && !currentUser?.symptoms && (
          <Route
            path="/"
            element={<Baseline currentUser={currentUser} fbUser={fbUser} />}
          />
        )}
        {currentUser && (
          <Route
            path="/"
            exact
            element={
              <Home
                currentUser={currentUser}
                fbUser={fbUser}
                combined={combined}
              />
            }
          />
        )}

        {!currentUser && <Route path="/" exact element={<Landing />} />}

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;
