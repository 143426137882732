import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../../layout/Navigation/Navigation';

function Terms({ currentUser, fbUser }) {
  return (
    <>
      <Navigation currentUser={currentUser} fbUser={fbUser} />
      <div className="terms page">
        <h1>Terms</h1>
        <p>Effective Date: January 27, 2022</p>
        <p>
          We hope you love using FMD. We really do. This document contains
          important information that you need to consider before using FMD.
        </p>
        <br />
        <p>
          The following terms and conditions (the &ldquo;Terms of Use&rdquo; or
          the &ldquo;Agreement&rdquo;) constitute an agreement between you and
          FMD, Inc. (&ldquo;FMD,&rdquo; &ldquo;we,&rdquo; or &ldquo;us&rdquo;),
          the operator of the-FMD.com (part of the Hey Hey Gorgeous, LLC) and
          related websites, applications, services and mobile applications, and
          all associated services (collectively, the &ldquo;Services&rdquo;)
          provided by FMD and on/in which these Terms of Use are posted or
          referenced. For the purposes of these Terms of Use, the
          &ldquo;Services&rdquo; include, without limitation, scheduling pages
          maintained by FMD on behalf of third parties and appointment
          scheduling technology integrated into third party websites. This
          Agreement constitutes a contract between you and us that governs your
          access and use of the Services. What does that mean? It means that by
          accessing and/or using our Services, you agree to all the terms and
          conditions of this Agreement. If you do not agree, then you may not
          use the Services. As used in this Agreement, &ldquo;you&rdquo; means
          any visitor, user, or other person who accesses our Services; whether
          or not such person registered for a FMD Account (as defined in Section
          1).{' '}
        </p>
        <p>
          If your use of the Services is terminated for any reason, then: (a)
          this Agreement will continue to apply and be binding upon you with
          regard to your prior use of the Services (as well as any subsequent
          and unauthorized use of the Services), including your indemnification
          obligations as described herein; and (b) any rights or licenses
          granted to us under this Agreement will survive such termination.
        </p>
        <p>
          FMD&rsquo;s collection and use of Personal Data in connection with the
          Services is described in FMD&rsquo;s{' '}
          <Link to="/privacy">Privacy Policy</Link> (&ldquo;Personal Data&rdquo;
          as defined therein).
        </p>
        <p>
          IMPORTANT: PLEASE REVIEW THE ARBITRATION NOTICE AND CLASS ACTION
          WAIVER IN SECTION 17.1 BELOW CAREFULLY, AS IT WILL REQUIRE YOU TO
          RESOLVE DISPUTES BETWEEN YOU AND FMD BY BINDING, INDIVIDUAL
          ARBITRATION. YOU ACKNOWLEDGE AND AGREE THAT YOU AND FMD ARE EACH
          WAIVING THE RIGHT TO A TRIAL BY JURY. YOU FURTHER ACKNOWLEDGE AND
          AGREE THAT YOU WAIVE YOUR RIGHT TO PARTICIPATE AS A PLAINTIFF OR CLASS
          MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING
          AGAINST FMD. BY ENTERING THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE
          THAT YOU HAVE READ AND UNDERSTOOD, AND AGREE TO BE BOUND BY, ALL OF
          THE TERMS AND CONDITIONS OF THIS AGREEMENT AND HAVE TAKEN TIME TO
          CONSIDER THE CONSEQUENCES OF THIS IMPORTANT DECISION.
        </p>
        <p>
          We are constantly trying to improve our Services, so these Terms of
          Use may need to change along with our Services. We reserve the right
          to change the Terms of Use at any time, but if we do, we will place a
          notice on our site, send you an email, and/or notify you by some other
          means.
        </p>
        <p>
          If you don&rsquo;t agree with the new Terms of Use, you are free to
          reject them; unfortunately, that means you will no longer be able to
          use the Services. If you use the Services in any way after a change to
          the Terms of Use is effective, that means you agree to all of the
          changes.
        </p>
        <p>
          Any disputes under this Agreement will be governed by the version of
          Agreement in effect at the time of the first event which gave rise to
          the dispute. Except for changes by us as described here, no other
          amendment or modification of these Terms of Use will be effective
          unless made in writing and signed by both you and us.
        </p>
        <p>
          We may from time to time add new features to the Services, substitute
          a new service for one of the existing Services, or discontinue or
          suspend one or any part of the existing Services. Under no
          circumstances will FMD be liable for any suspension or discontinuation
          of any of the Services or portion thereof, and any use of new features
          or services will be governed by this Agreement.
        </p>
        <p>
          If you create a FMD Account or use the Services on behalf of an
          individual or entity other than yourself, you represent that you are
          authorized by such individual or entity to accept this Agreement on
          such individual&rsquo;s or entity&rsquo;s behalf and bind them to this
          Agreement (in which case, the references to &ldquo;you&rdquo; and
          &ldquo;your&rdquo; in this Agreement, except for in this sentence,
          refer to that individual or entity).
        </p>
        <h2>1. ABOUT THE SERVICES </h2>
        <p>
          Subject to these Terms of Use, FMD grants you a limited,
          non-exclusive, revocable, non-sublicensable, non-transferable license
          to use the Services in accordance with these Terms of Use. Portions of
          the Services can be viewed without a FMD Account. However, to benefit
          from all of the Services we offer, you must create an account (a
          &ldquo;FMD Account&rdquo;) and provide certain basic information about
          yourself. If you do provide us with any information, you authorize FMD
          to use and disclose it as described in our
          <Link to="/privacy">Privacy Policy</Link>.
        </p>
        <p>
          While utilizing the Services, you may encounter certain Content that
          FMD makes available to you. &ldquo;Content&rdquo; means content, text,
          data, graphics, images, photographs, video, audio, information,
          suggestions, articles, scheduling availability, guidance, and other
          materials provided, made available or otherwise found through the
          Services, including, without limitation, Content provided in direct
          response to your questions or postings. You acknowledge that although
          some Content may be provided by healthcare professionals, the
          provision of such Content does not create a medical
          professional/patient relationship, and does not constitute an opinion,
          medical advice, or diagnosis or treatment, but is provided to assist
          you in choosing a doctor, dentist or other healthcare specialist,
          professional, provider, organization, or agent or affiliate thereof
          (collectively, &ldquo;Healthcare Providers&rdquo;) or otherwise be
          generally informative.{' '}
        </p>
        <p>
          WHILE WE MAKE REASONABLE EFFORTS TO PROVIDE YOU WITH ACCURATE CONTENT,
          WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES, WHETHER EXPRESS
          OR IMPLIED, WITH RESPECT TO ANY CONTENT (INCLUDING BUT NOT LIMITED TO
          DESCRIPTIONS OF PROFESSIONAL QUALIFICATIONS, EXPERTISE, QUALITY OF
          WORK, PRICE OR COST INFORMATION, INSURANCE COVERAGE OR BENEFIT
          INFORMATION). IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANYONE ELSE FOR
          ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON ANY SUCH
          CONTENT. FURTHERMORE, WE DO NOT IN ANY WAY ENDORSE, REFER OR RECOMMEND
          ANY INDIVIDUAL OR ENTITY LISTED IN CONTENT AND/OR ACCESSIBLE THROUGH
          THE SERVICES.
        </p>
        <h2>2. WE DO NOT PROVIDE MEDICAL ADVICE</h2>
        <p>
          FMD is not a healthcare provider. The Content that you obtain or
          receive from FMD, its employees, contractors, partners, sponsors,
          advertisers, licensors or otherwise through the Services, is for
          informational, scheduling and payment purposes only. All medically
          related information, including, without limitation, information shared
          via the site, FMD social channels, FMD emails and text messages, and
          FMD advertising is for informational and communicative purposes only.{' '}
        </p>
        <p>
          WHILE WE HOPE THE CONTENT IS USEFUL IN YOUR HEALTHCARE JOURNEY, IT IS
          NOT INTENDED AS A SUBSTITUTE FOR, NOR DOES IT REPLACE, PROFESSIONAL
          MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. DO NOT USE THE SERVICES FOR
          EMERGENCY MEDICAL NEEDS. IF YOU EXPERIENCE A MEDICAL OR MENTAL HEALTH
          EMERGENCY, ARE THINKING ABOUT SUICIDE OR TAKING ACTIONS THAT MAY CAUSE
          HARM TO YOU OR TO OTHERS, IMMEDIATELY CALL A HEALTHCARE PROFESSIONAL
          AND/OR 911. YOUR USE OF THE SERVICES IS SOLELY AT YOUR OWN RISK.
          NOTHING STATED, POSTED, OR AVAILABLE THROUGH ANY SERVICES IS INTENDED
          TO BE, AND MUST NOT BE TAKEN TO BE, THE PRACTICE OF MEDICINE,
          DENTISTRY, NURSING, PHARMACY, OR OTHER PROFESSIONAL HEALTHCARE ADVICE,
          OR THE PROVISION OF MEDICAL CARE.
        </p>
        <p>
          FMD is not a referral service and does not refer, recommend or endorse
          any particular Healthcare Provider, test, procedure, opinion, or other
          information that may appear through the Services. If you rely on any
          Content, you do so solely at your own risk. We encourage you to
          independently confirm any Content relevant to you with other sources,
          including the Healthcare Provider&rsquo;s office, medical associations
          relevant to the applicable specialty, your state medical boards, and
          the appropriate licensing or certification authorities to verify
          listed credentials and education.
        </p>
        <h2>3. NO DOCTOR PATIENT RELATIONSHIP</h2>
        <p>
          DOCTORS, DENTISTS, NURSES, AND OTHER MEDICAL PROFESSIONALS USE THE
          SERVICES TO SHARE CONTENT WITH YOU, BUT YOUR USE OF THIS CONTENT IS
          NOT A SUBSTITUTE FOR HEALTHCARE. NO LICENSED MEDICAL
          PROFESSIONAL/PATIENT RELATIONSHIP IS CREATED WHEN YOU USE THE SERVICES
          OR CONTENT. THIS IS TRUE WHETHER SUCH CONTENT IS PROVIDED BY OR
          THROUGH THE USE OF THE SERVICES OR THROUGH ANY OTHER COMMUNICATIONS
          FROM FMD INCLUDING, WITHOUT LIMITATION, THE &ldquo;FIND A
          DOCTOR&rdquo; FEATURE, FMD ANSWERS, FMD KNOWLEDGE BASE, FMD BLOG, FMD
          SOCIAL CHANNELS, FMD EMAILS OR TEXT MESSAGE LINKS TO OTHER SITES, OR
          ANY ASSISTANCE WE MAY PROVIDE TO HELP YOU FIND AN APPROPRIATE
          HEALTHCARE PROVIDER IN ANY FIELD.
        </p>
        <p>
          FMD encourages Healthcare Providers to use the Services responsibly,
          but we have no control over, and cannot guarantee the availability of,
          any Healthcare Provider at any particular time. We will not be liable
          for cancelled or otherwise unfulfilled appointments, or any injury or
          loss resulting therefrom, or for any other injury or loss resulting or
          arising from, or related to, the use of the Services whatsoever.
        </p>
        <h2>
          4. AUTHORIZATION AND ACKNOWLEDGEMENT; IMPORTANT INFORMATION ABOUT
          HEALTHCARE PROVIDER RELATIONSHIPS AND HEALTHCARE PROVIDER LISTS
        </h2>
        <p>
          In connection with using the Services to schedule appointments with
          Healthcare Providers, you understand that:
        </p>
        <p>
          YOU ARE RESPONSIBLE FOR CHOOSING YOUR OWN HEALTHCARE PROVIDER,
          INCLUDING WITHOUT LIMITATION, DETERMINING WHETHER THE APPLICABLE
          HEALTHCARE PROVIDER IS SUITABLE FOR YOUR HEALTHCARE NEEDS BASED ON
          SPECIALTY, EXPERIENCE, QUALIFICATION, LICENSES AND OTHER IMPORTANT
          FACTS AND CIRCUMSTANCES THAT COULD IMPACT YOUR CARE.
        </p>
        <p>
          FMD or its designee takes certain limited steps to (a) verify that
          Healthcare Providers participating in the Services hold certain active
          licenses, certifications or registrations required by law to practice
          the specialties of the services offered by them through the Services,
          and (b) verify that Healthcare Providers are not listed in the U.S.
          Department of Health and Human Services Office of the Inspector
          General Exclusion database. FMD may also exclude Healthcare Providers
          from our Services who, in FMD&rsquo;s discretion, have engaged in
          inappropriate or unprofessional conduct (including, but not limited
          to, conduct in violation of our Community Standards).
        </p>
        <p>
          While all qualified providers are eligible to use our Services,
          FMD&rsquo;s lists of Healthcare Providers are not exhaustive.
          Healthcare Providers choose whether to participate in the Services,
          what appointment availability, if any, to advertise on their FMD
          profiles, and may also choose to set a maximum amount that they want
          to spend on new patient bookings received through FMD. This means that
          regardless of whether they participate in the Services, Healthcare
          Providers may have additional availability beyond what&rsquo;s listed
          through the Services if you contact them directly. Some Healthcare
          Providers listed through the Services enter into contracts with us,
          and may pay us fees in order to be marketed through or to use the
          Services. If you book an appointment with a Healthcare Provider
          through the Services, FMD may receive a commission.
        </p>
        <p>
          To help you find Healthcare Providers who may be suitable for your
          needs, and enable the maximum choice and diversity of Healthcare
          Providers participating in the Services, we will provide you with
          lists and/or profiles of Healthcare Providers. These results are based
          on information that you provide to us, such as insurance information,
          geographical location, and healthcare specialty and/or services they
          provide. They may also be based on other criteria (including, for
          example, Healthcare Provider availability, past selections by and/or
          ratings of Healthcare Providers by you or by other FMD users, and past
          experience of FMD users with Healthcare Providers). Please note that
          there may be other Healthcare Providers who meet your search criteria
          but are not available through FMD.
        </p>
        <p>
          FMD (a) does not employ, refer, recommend, or endorse any Healthcare
          Providers, (b) does not make any representations or warranties with
          respect to these Healthcare Providers or the quality of the services
          they may provide, (c) is not responsible for ensuring that information
          (including credentials) a Healthcare Provider provides about himself
          or herself is accurate or up-to-date, (d) is not responsible for
          making sure that Healthcare Providers&rsquo; services are actually
          provided or are up to a certain standard of quality, and (e) does not
          receive any additional fees from Healthcare Providers for featuring
          them (i.e., higher or better placement on lists) through the Services
          (subject to Sponsored Results as described below). We don&rsquo;t
          control the actions of any Healthcare Providers and Healthcare
          Providers are not our employees. Note, however, to the extent that you
          use the Services as provided by your employer, FMD may provide lists
          and/or profile previews based also on criteria determined by your
          employer and your employer&rsquo;s agents or advisors. In addition, to
          the extent that FMD serves as a platform for, or provides technical
          support to, the provider directory associated with your health
          coverage, Healthcare Providers will appear in the directory based on
          criteria determined by your health plan or plan sponsor, as
          applicable.
        </p>
        <p>
          We may show you advertisements or sponsored results (&ldquo;Sponsored
          Results&rdquo;) through the Services, including above or interspersed
          within the search results. FMD receives additional fees from
          Healthcare Providers for including them in Sponsored Results, and such
          Sponsored Results are not, and should not be considered, an
          endorsement, referral or recommendation by FMD of the Healthcare
          Provider. Sponsored Results are conspicuously labeled as
          &ldquo;Sponsored&rdquo; or &ldquo;Paid Ad&rdquo; in the Services.
        </p>
        <h2>
          5. THE SERVICES AND CONTENT ARE INFORMATIONAL AND EDUCATIONAL
          RESOURCES
        </h2>
        <p>
          The Services are an informational and educational resource for
          consumers and Healthcare Providers. We may, but have no obligation to,
          publish Content through the Services that is reviewed by our editorial
          personnel. No party (including FMD) involved in the preparation or
          publication of such works guarantees that the Content is timely,
          accurate or complete, and they will not be responsible or liable for
          any errors or omissions in, or for the results obtained from the use
          of, such Content.
        </p>
        <p>Healthcare Provider Content:</p>
        <p>
          Content related to Healthcare Providers and their practices
          (&ldquo;Healthcare Provider Content&rdquo;) is intended for general
          reference purposes only. Healthcare Provider Content may be provided
          by the Healthcare Provider and/or office staff, and collected from
          multiple other data sources that may not be confirmed by the
          Healthcare Provider. While we make efforts to confirm Healthcare
          Provider Content and keep it up to date, it can change frequently and
          may become out of date, incomplete or inaccurate at any time. FMD does
          not provide any advice or certification regarding the qualifications
          of any particular Healthcare Provider.
        </p>
        <p>Procedures/Products/Services:</p>
        <p>
          Procedures, products, services and devices discussed and/or marketed
          through the Services are not applicable to all individuals, patients
          or all clinical situations. Any procedures, products, services or
          devices represented through the Services by advertisers, sponsors, and
          other participants of the Services, either paid or unpaid, are
          presented for your awareness and do not necessarily imply, and we make
          no claims as to, safety or appropriateness for any particular
          individual or prediction of effectiveness, outcome or success.
        </p>
        <p>Insurance Content:</p>
        <p>
          Insurance and insurance related Content (including, without
          limitation, insurance coverage and benefit Content) (&ldquo;Insurance
          Content&rdquo;) is intended for general reference purposes and for
          your convenience only. Insurance Content may be based on Personal Data
          you provide to us as well as Healthcare Provider Content (such as CPT
          codes and other encounter data). Insurance Content may also be
          provided either directly by the insurance provider you identify or via
          a third-party clearinghouse. Insurance Content can change frequently
          and may become out of date, incomplete or inaccurate. You agree that
          you will (a) provide accurate and complete insurance-related Personal
          Data, and (b) verify and maintain accurate insurance-related Personal
          Data (including, without limitation, verifying such Personal Data
          obtained by automated means from an insurance card you provide).
          Although FMD strives to provide you with accurate Insurance Content,
          we are not responsible for any inaccurate, incomplete or outdated
          Insurance Content, and you are responsible for complying with subparts
          (a) and (b) hereof.{' '}
        </p>
        <h2>6. MEDICAL INFORMATION </h2>
        <p>
          You may elect to enter certain medical information into the Services,
          including by requesting an appointment or filling out a medical
          history form (&ldquo;Medical History Form&rdquo;) on behalf of
          yourself or a third party from whom you have authorization to provide
          such information. You can request (and in doing so, authorize) FMD to
          provide this information to your chosen Healthcare Provider. You
          acknowledge and agree that such information will be reviewed and
          approved by you or someone authorized by you at the time of your
          appointment to ensure its accuracy. You also acknowledge that FMD may
          use the data or information you provide in a Medical History Form in
          accordance with our <Link to="/privacy">Privacy Policy</Link>.
        </p>
        <h2>7. YOUR RESPONSIBILITIES</h2>
        <h3>7.1 Your FMD Account Credentials</h3>
        <p>
          When you create a FMD Account, you will provide an email address and
          create a password (collectively, &ldquo;Credentials&rdquo;). You
          should keep your Credentials private and not share your Credentials
          with anyone else. You must immediately notify us if your password has
          been stolen or compromised by sending an email to{' '}
          <a href="mailto:hello@heyheygorgeous.com">hello@heyheygorgeous.com</a>
          . You promise to provide us with accurate, complete, and updated
          registration information about yourself. You may also be able to
          connect to the Services maintained by a third party company, such as
          Facebook, Inc., Google LLC (&ldquo;Google&rdquo;) or Apple Inc.
          (&ldquo;Apple&rdquo;). If you connect to the Services through a third
          party service, you give us permission to access and use your
          information from such third party service as permitted by such third
          party service, and to store your log-in credentials for such third
          party service. You are ultimately in control of how much information
          is accessible to us and may exercise such control by adjusting your
          privacy settings on such third party service. FMD has no control over,
          and assumes no responsibility for, the services, content, accuracy,
          privacy policies, or practices of or opinions expressed by any such
          third party.
        </p>
        <h3>7.2 Your Responsibilities Generally</h3>
        <p>
          While it&rsquo;s free to create a FMD Account and download our app,
          you are still responsible for your healthcare expenses. Any charges
          for any medical or related services rendered by Healthcare Providers
          will apply and will be entirely your responsibility. You are
          responsible for ensuring that all information that you provide to FMD
          is accurate and up-to-date, including your insurance information. Some
          Services may not be available through FMD or your Healthcare Provider
          depending upon a number of factors, including your insurance
          participation. Ultimately, you must resolve any dispute between you or
          any Healthcare Provider arising from any transaction hereunder
          directly with the Healthcare Provider.
        </p>
        <p>
          You are responsible for all use of the Services and for all use of
          your Credentials, including use by others to whom you have given your
          Credentials. You may only use the Services for lawful, non-commercial
          purposes. If your use of the Services is prohibited by applicable
          laws, then you aren&rsquo;t authorized to use the Services. You may
          not use the Services in any manner that could damage, disable,
          overburden, or impair our servers or networks, or interfere with any
          other party&rsquo;s use and enjoyment of the Services. You may not
          attempt to gain unauthorized access to any of the Services, user
          accounts, or computer systems or networks, through hacking, password
          mining or any other means. You may not accumulate or index, directly
          or indirectly, any Content or portion of the Services (including,
          without limitation, Healthcare Provider Content, appointment
          availability, price information, and Insurance Content) for any
          purpose whatsoever.
        </p>
        {/* <p>
        You are also responsible for reviewing and complying with the terms set
        forth in our{' '}
        <a href="https://www.the-FMD.com/about/acceptable-use-policy">
          Acceptable Use Policy
        </a>{' '}
        and our{' '}
        <a href="https://www.the-FMD.com/about/communitystandards/">
          Community Standards
        </a>
        .
      </p> */}
        <p>
          In addition to our rights in these Terms of Use, we may take any legal
          action and implement any technological measures to prevent violations
          of the restrictions hereunder and to enforce these Terms of Use, our
          Acceptable Use Policy, and our Community Standards.
        </p>
        <h3>
          7.3 Responsibilities of Healthcare Providers and Others in the
          Healthcare or Medical Industries
        </h3>
        <p>
          If you are a Healthcare Provider or other person or entity in the
          healthcare or medical industries, regardless of whether you maintain a
          FMD Account or whether you schedule or intend to schedule appointments
          through the Services, you acknowledge and agree that:
        </p>
        <p>
          (a) You will not use the Services to view, access or otherwise use,
          directly or indirectly, price, availability, or other Content for any
          purpose other than your own personal use as a patient or prospective
          patient;
        </p>
        <p>
          (b) You will not use the Services to establish, attempt to establish,
          or enforce, directly or indirectly, any agreement or coordination of
          the prices charged for any product or service; the kinds, frequencies
          or amounts of any product or service offered; or the customer or
          customer categories for any product or service, or otherwise engage or
          attempt to engage in price fixing, output restriction, or customer or
          market allocation; and
        </p>
        <p>
          (c) You will not use the Services, directly or indirectly, to engage
          in any anti-competitive, deceptive or unfair practices (including but
          not limited to booking fraudulent healthcare appointments), or
          otherwise violate applicable antitrust, competition or consumer
          protection laws, or regulations.
        </p>
        <h3>7.4 Disputes Between Users</h3>
        <p>
          If there is a dispute between participants on this site or Services,
          or between users and any third party (including but not limited to
          Healthcare Providers), you agree that FMD is under no obligation to
          become involved. In the event that you have a dispute with one or more
          other users or Healthcare Providers, you release FMD, its directors,
          officers, employees, agents, and successors from claims, demands, and
          damages of every kind or nature, known or unknown, suspected or
          unsuspected, disclosed or undisclosed, arising out of or in any way
          related to such disputes and/or our Services.
        </p>
        <h2>8. Telemedicine Services</h2>
        <p>
          You may be able to utilize certain telemedicine services through the
          Services, including but not limited to via FMD&rsquo;s video service
          (&ldquo;FMD Video Service&rdquo;). FMD is not a healthcare provider,
          insurance provider, or a prescription fulfillment warehouse. In
          providing the FMD Video Service, FMD&rsquo;s role is limited to making
          certain telehealth related information available to you and/or
          facilitating your access to telemedicine services provided by
          Healthcare Providers. FMD is not responsible for such Healthcare
          Providers&rsquo; acts or omissions, or for any content of your
          communications with them. FMD is independent of the Healthcare
          Providers, and does not employ or otherwise exercise any control over
          the services provided by Healthcare Providers, regardless of whether
          they utilize the FMD Video Service.{' '}
        </p>
        <br />
        <h2>9. THIRD PARTY LINKS AND SERVICES </h2>
        <h3>9.1 Links to Other Websites</h3>
        <p>
          While using the Services, you may encounter links to other websites.
          These links are provided for your convenience only and we do not
          endorse these sites or the products and services they provide. You
          acknowledge and agree that we are not responsible or liable for the
          content or accuracy of these other websites. Although we attempt to
          link to trustworthy websites, it is possible that they will contain
          materials that are objectionable, unlawful, or inaccurate and we will
          not be responsible or liable for the legality or decency of material
          contained in or accessed through such other websites. By using the
          Services, you release and hold us harmless from any and all liability
          arising from your use of any third-party website or service. Your
          interactions with organizations and/or individuals found on or through
          the Services, including payment and delivery of goods or services, and
          any other terms, conditions, warranties or representations associated
          with such dealing, are solely between you and such organizations
          and/or individuals. You should make whatever investigation you feel
          necessary or appropriate before proceeding with any online or offline
          transaction with any of these third parties. You agree that FMD will
          not be responsible or liable for any loss or damage of any sort
          incurred as the result of any such dealings.
        </p>
        <h3>9.2 Third Party Software</h3>
        <p>
          We may incorporate third party software as part of certain of the
          Services, including without limitation open source third party
          software. Your use of such third party software is subject to any and
          all applicable additional terms and conditions governing such use
          provided by the third party software provider. Where applicable,
          additional notices relating to the third party software may be
          provided by us, which for example may contain attribution and
          disclaimer notices applicable to the third party software.
        </p>
        <h3>9.3 Application Stores</h3>
        <p>
          You acknowledge and agree that the availability of our mobile
          application is dependent on the third party stores from which you
          download the application (e.g., the Apple App Store, Google Play or
          other store or distribution platform (each, an &ldquo;Application
          Provider&rdquo;)). Each Application Provider may have its own terms
          and conditions to which you must agree before downloading mobile
          applications from such store. You agree to comply with, and your
          license to use our application is conditioned upon your compliance
          with, such Application Provider terms and conditions. To the extent
          such other terms and conditions from such Application Provider are
          less restrictive than, or otherwise conflict with, the terms and
          conditions of these Terms of Use, the more restrictive or conflicting
          terms and conditions in these Terms of Use apply.{' '}
        </p>
        <br />
        <p>
          If you are accessing the Services via an application (an
          &ldquo;Application&rdquo;) through an Application Provider, you
          acknowledge and agree that: (i) these Terms of Use are between you and
          us, and not with the Application Provider, and that we are responsible
          for the Services, not the Application Provider; (ii) the Application
          Provider has no obligation to furnish maintenance and support services
          or handle any warranty claims; (iii) the Application Provider is not
          responsible for addressing any claims you or any third party have
          relating to the Application; (iv) the Application Provider is a third
          party beneficiary of these Terms of Use as related to your use of the
          Application, and the Application Provider will have the right (and
          will be deemed to have accepted the right) to enforce these Terms of
          Use as related to your use of the Application against you; (v) in the
          event of any third-party claim that the Application or your possession
          and use of the Application infringes that third party&rsquo;s
          intellectual property rights, FMD, and not Application Provider, will
          be responsible for the investigation, defense, settlement, and
          discharge of any such infringement claim; and (vi) you are not located
          in a country subject to a U.S. government embargo, or that has been
          designated by the U.S. government as a &ldquo;terrorist
          supporting&rdquo; country, and that you are not listed on any U.S.
          government list of prohibited or restricted parties. The foregoing
          terms apply to your use of all the Services, including the
          Application.
        </p>
        <br />
        <h2>10. PAYMENT AND TRANSACTION PROCESSING</h2>
        <h3>10.1 Payment and Transaction Processing Generally</h3>
        <p>
          You may be permitted to use the Services to pay for Designated
          Provider Services (as defined in Section 10.2), other products or
          services provided by the applicable Healthcare Provider, and/or other
          payment obligations, including, for example, your insurance
          copayments. In connection with such payments, we process your payment
          card information in accordance with our{' '}
          <Link to="/privacy">Privacy Policy</Link>. FMD and/or its payment
          processing partner may collect from the applicable Healthcare Provider
          a transaction processing fee for our billing, collection and payment
          services (the &ldquo;Transaction Processing Services&rdquo;) performed
          in connection with such payment. Please note that any payment terms
          presented to you in the process of using or signing up for a paid
          service or the Transaction Processing Services are deemed part of
          these Terms.{' '}
        </p>
        <h3>10.2 Designated Provider Services</h3>
        <p>
          Certain Healthcare Providers may provide through the Services price
          information for their healthcare and related products and services
          (&ldquo;Designated Provider Services&rdquo;), and permit you to use
          the Services to pay for such Designated Provider Services.
        </p>
        <p>
          Prior to scheduling an appointment for Designated Provider Services,
          please carefully review (a) the price information, and (b) all of the
          products and services that are included and excluded in each
          Designated Provider Service. Healthcare Providers are required to
          provide the applicable Designated Provider Service at or below the
          price indicated at the time of scheduling (&ldquo;Designated
          Price&rdquo;), unless you separately agree otherwise. Services in
          addition to or different from Designated Provider Services may be
          available, offered or rendered by Healthcare Providers. You and your
          Healthcare Providers are responsible for agreeing to pricing for any
          additional or different services. FMD IS NOT RESPONSIBLE FOR YOUR OR
          ANY HEALTHCARE PROVIDER&rsquo;S FAILURE TO AGREE TO PRICING FOR ANY
          ADDITIONAL OR DIFFERENT SERVICES.
        </p>
        <br />
        <h3>
          10.3 Payment of Your Financial Obligations Under Your Insurance
          Coverage and Benefits
        </h3>
        <p>
          Certain Healthcare Providers may permit you to use the Services to pay
          for all or a portion of your financial obligations under your
          insurance coverage and benefits (the &ldquo;Insurance Payment
          Services&rdquo;).
        </p>
        <p>
          While we endeavor to provide accurate Insurance Content (for example,
          your copayment obligation), we make no guarantees and disclaim all
          warranties that the Insurance Content is correct. You acknowledge that
          Insurance Content is provided by the insurance provider or via a
          third-party clearinghouse. Please also keep in mind that (a) Insurance
          Content is constantly being updated (for example, to reflect amounts
          you may have paid earlier towards your deductible), (b) you are likely
          to receive an explanation of benefits (or similar document) from your
          insurance company after you receive your Healthcare Provider&rsquo;s
          services, and (c) you may ultimately owe your Healthcare Provider more
          than the amount you paid through the Insurance Payment Services.
        </p>
        <h3>10.4 Transaction Processing</h3>
        <p>
          The transaction processing fee will not exceed the fair market value
          for the Transaction Processing Services provided by FMD and its
          payment processing partner. Healthcare Providers may separately charge
          you for additional or different products or services.
        </p>
        <p>You acknowledge and agree that:</p>
        <p>
          (a) you are responsible for and you will pay the Designated Price for
          the Designated Provider Services charged through FMD by the applicable
          Healthcare Provider, as well as other amounts the applicable
          Healthcare Provider may charge through FMD for any additional or
          different services rendered during or related to the applicable
          appointment;
        </p>
        <p>
          (b) you remain responsible for paying all amounts required by law
          and/or contract (e.g., health plan agreement), including all
          cost-sharing obligations (such as, but not limited to, copayments,
          deductibles and other coinsurance obligations);
        </p>
        <p>
          (c) if you utilize our Transaction Processing Services, FMD may
          process your payment, and may do so in collaboration with our payment
          processing partner;
        </p>
        <p>
          (d) FMD is not responsible for any charges incurred for any products
          or services provided by Healthcare Provider, including any Designated
          Provider Service;
        </p>
        <p>
          (e) FMD is not responsible for any charges submitted for processing by
          Healthcare Providers;
        </p>
        <p>
          (f) in the event you dispute any fees chargeable or charged through
          FMD by a Healthcare Provider, you will resolve such dispute directly
          with the applicable Healthcare Provider;
        </p>
        <p>
          (g) if you utilize our Transaction Processing Services, you may be
          required to accept the terms of use and privacy policy of our payment
          processing partner with respect to Transaction Processing Services.
          Currently, we use Stripe, Inc. as our payment processing partner. You
          can access Stripe&rsquo;s Terms of Services at{' '}
          <a href="https://stripe.com/us/checkout/legal">
            https://stripe.com/us/checkout/legal
          </a>
          ; and
        </p>
        <p>
          (h) you will promptly review all charges processed through the
          Transaction Processing Services, and immediately notify FMD to the
          extent you have any questions, concerns or disputes; in no event may
          you raise any questions, concerns or disputes after twelve (12) months
          from the date of the applicable transaction; and
        </p>
        <p>
          We are not responsible for any error by, or other acts or omissions
          of, the payment processor. You further acknowledge and agree that
          neither FMD nor our payment processing partner will be responsible if
          either we or our payment processing partner are unable to complete a
          transaction for any reason, including but not limited to:
        </p>
        <p>
          (A) if you have not provided us with accurate, current and complete
          payment information;
        </p>
        <p>
          (B) if you do not have sufficient available funds or available credit
          to complete the transaction;
        </p>
        <p>
          (C) if you do not have an active payment card, or if we are unable to
          confirm your payment card information or your identity;
        </p>
        <p>
          (D) if your account with us, your account with our payment processing
          partner, your access to the Services, or your access to our payment
          processing partner&rsquo;s services has been terminated or suspended
          for any reason;
        </p>
        <p>
          (E) if we or our payment processing partner have reason to believe
          that the requested transaction is unauthorized; or
        </p>
        <p>
          (F) if we terminate or suspend the services we provide to the
          applicable Healthcare Provider.
        </p>
        <br />
        <h2>11. REVIEWS AND OTHER CONTENT YOU POST OR SUBMIT</h2>
        <p>
          You will have the opportunity to submit feedback regarding your
          experiences with Healthcare Providers you find through the Services,
          to submit inquiries concerning possible medical needs, and to
          participate in the other interactive or community features of the
          Services (collectively, &ldquo;Posted Information&rdquo;). It is
          important that you act responsibly when providing Posted Information.{' '}
          {/* Your Posted
        Information must comply with our{' '}
        <a href="https://www.the-FMD.com/about/acceptable-use-policy">
          Acceptable Use Policy
        </a>
        , and your Healthcare Provider reviews must comply with{' '}
        <a href="https://www.the-FMD.com/about/verifiedreviews/">
          our posted guidelines
        </a> */}
          , as well as any internal policies we may develop and implement from
          time to time. Please note that while FMD may moderate Posted
          Information (including but not limited to Healthcare Provider reviews)
          for compliance with the requirements in this paragraph, FMD does not
          endorse or confirm the accuracy of any statements made in such Posted
          Information; Posted Information reflects solely the views or opinions
          of the author, and not of FMD.
        </p>
        <p>
          Please note that all of the following licenses are subject to our{' '}
          <Link to="/privacy">Privacy Policy</Link> to the extent they relate to
          Posted Information that is also your personally-identifiable
          information.
        </p>
        <p>
          By posting Posted Information through the Services, you agree to and
          hereby grant, and you represent and warrant that you have the right to
          grant, to FMD and its affiliates, agents, and contractors an
          irrevocable, perpetual, royalty-free, fully sublicensable, fully paid
          up, worldwide license to use, copy, publicly perform, digitally
          perform, publicly display, and distribute such Posted Information and
          to adapt, edit, translate, prepare derivative works of, incorporate
          into other works, and otherwise fully exploit such Posted Information.
          You represent and warrant that you have all rights to grant such
          licenses to us without infringement or violation of any third party
          rights, including without limitation any privacy rights, publicity
          rights, copyrights, trademarks, contract rights, or any other
          intellectual property or proprietary rights. You may not post Posted
          Information which is false or fraudulent, or which otherwise does not
          accurately represent your opinions and experiences.{' '}
        </p>
        <p>
          You understand and agree that FMD, in performing the required
          technical steps to provide the Services to our users (including you),
          may need to make changes to your Posted Information to conform and
          adapt that Posted Information to the technical requirements of
          connection networks, devices, services, or media, and the foregoing
          licenses include the rights to do so.
        </p>
        <p>
          Posted Information is the sole responsibility of the person from whom
          such Posted Information originated and does not reflect the opinion of
          FMD. FMD does not assume liability for Posted Information or for any
          claims, liabilities, or losses resulting from any Posted Information.{' '}
        </p>
        <p>
          We also welcome and encourage you to provide feedback, comments and
          suggestions for improvements to the Services (&ldquo;Feedback&ldquo;).
          You may submit Feedback by emailing us, reaching out to our social
          networking accounts, or by other means of communication. Any Feedback
          you submit to us will be considered non-confidential and
          non-proprietary to you. By submitting Feedback to us, you grant us a
          non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable,
          perpetual license to use and publish such Feedback for any purpose,
          without compensation to you.
        </p>
        <h2>12. YOUR USE OF CONTENT</h2>
        <p>
          All of the Content is owned by us or our licensors and is protected by
          copyright, trademark, patent, and trade secret laws, other proprietary
          rights, and international treaties. You acknowledge that the Services
          and any underlying technology or software used in connection with the
          Services contain FMD&rsquo;s proprietary information. We give you
          permission to use the Content for personal, non-commercial purposes
          only and do not transfer any intellectual property rights to you by
          virtue of permitting your use of the Services. You may print,
          download, and store information from the Services for your own
          convenience, but you may not copy, distribute, republish (except as
          expressly permitted herein), sell, or exploit any of the Content, or
          exploit the Services in whole or in part, for any commercial gain or
          purpose whatsoever. Except as expressly provided herein, neither FMD
          nor its suppliers grant you any express or implied rights, and all
          rights in the Services not expressly granted by FMD to you are
          retained by FMD.
        </p>
        <h2>13. DISCLAIMER</h2>
        <p>
          We created FMD to improve patients&rsquo; healthcare experiences, and
          we want your experience with FMD to be exceptional. While we work hard
          to make that happen, you acknowledge that we have no control over, and
          no duty to take any action regarding: (a) which users gain access to
          the Services, (b) what Content you access, (c) what effect the Content
          may have on you, (d) how you may interpret or use the Content, or (e)
          what actions you may take as a result of having been exposed to the
          Content. You release us from all liability for your use or inability
          to use any Content. We and our licensors, suppliers, partners, parent,
          subsidiaries or affiliated entities, and each of their respective
          officers, directors, members, employees, consultants, contract
          employees, representatives, and agents, and each of their respective
          successors and assigns (FMD and all such parties together, the
          &ldquo;FMD Parties&rdquo;) make no representations or warranties
          regarding suggestions or recommendations of services or products
          offered or purchased through the Services, and the FMD Parties will
          not be responsible or liable for the accuracy, copyright compliance,
          legality, or decency of material contained in or accessed through the
          Services. We have no special relationship with or fiduciary duty to
          you.{' '}
        </p>
        <p>
          WE (AND OUR LICENSORS AND SUPPLIERS) PROVIDE THE SERVICES &ldquo;AS
          IS&rdquo; AND &ldquo;AS AVAILABLE.&rdquo; WE MAKE NO EXPRESS OR
          IMPLIED WARRANTIES OR GUARANTEES ABOUT THE CONTENT OR SERVICES. TO THE
          MAXIMUM EXTENT PERMITTED BY LAW, WE HEREBY DISCLAIM ALL SUCH
          WARRANTIES, INCLUDING ALL STATUTORY WARRANTIES, WITH RESPECT TO THE
          SERVICES, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES THAT THE
          SERVICES ARE MERCHANTABLE, OF SATISFACTORY QUALITY, ACCURATE, FIT FOR
          A PARTICULAR PURPOSE OR NEED, OR NON-INFRINGING. WE DO NOT GUARANTEE
          THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES
          WILL BE EFFECTIVE, RELIABLE OR ACCURATE OR WILL MEET YOUR
          REQUIREMENTS. WE DO NOT GUARANTEE THAT YOU WILL BE ABLE TO USE THE
          SERVICES (EITHER DIRECTLY OR THROUGH THIRD-PARTY NETWORKS) AT TIMES OR
          LOCATIONS OF YOUR CHOOSING. WE ARE NOT RESPONSIBLE FOR THE ACCURACY,
          RELIABILITY, TIMELINESS OR COMPLETENESS OF INFORMATION PROVIDED BY
          USERS OF THE SERVICES OR ANY OTHER DATA OR INFORMATION PROVIDED OR
          RECEIVED THROUGH THE SERVICES. EXCEPT AS EXPRESSLY SET FORTH HEREIN,
          FMD MAKES NO WARRANTIES ABOUT THE INFORMATION SYSTEMS, SOFTWARE AND
          FUNCTIONS MADE ACCESSIBLE THROUGH THE SERVICES OR ANY OTHER SECURITY
          ASSOCIATED WITH THE TRANSMISSION OF SENSITIVE INFORMATION. FMD DOES
          NOT WARRANT THAT THE SERVICES WILL OPERATE UNINTERRUPTED, ERROR-FREE,
          BUG-FREE OR FREE FROM DEFECTS, THAT LOSS OF DATA WILL NOT OCCUR, OR
          THAT THE SERVICES OR SOFTWARE ARE FREE OF COMPUTER VIRUSES,
          CONTAMINANTS OR OTHER HARMFUL ITEMS.
        </p>
        <h2>14. GENERAL LIMITATION OF LIABILITY</h2>
        <p>
          YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH US IS THE
          CANCELLATION OF YOUR FMD ACCOUNT. IN NO EVENT SHALL OUR CUMULATIVE
          LIABILITY TO YOU FOR ANY AND ALL CLAIMS RELATING TO OR ARISING OUT OF
          YOUR USE OF THE SERVICES, REGARDLESS OF THE FORM OF ACTION, EXCEED THE
          GREATER OF: (a) THE TOTAL AMOUNT OF FEES, IF ANY, THAT YOU PAID TO
          CREATE OR MAINTAIN A FMD ACCOUNT FOR USE OF THE SERVICES, OR (b) $100;
          EXCEPT THAT, FOR ANY AND ALL CLAIMS RELATING TO OR ARISING OUT OF YOUR
          USE OF THE TRANSACTION PROCESSING SERVICES, IN NO EVENT SHALL OUR
          CUMULATIVE LIABILITY THEREOF REGARDLESS OF THE FORM OF ACTION EXCEED
          THE TOTAL AMOUNT OF TRANSACTION PROCESSING FEES, IF ANY, REMITTED TO
          AND RETAINED BY FMD FOR PROVIDING TRANSACTION PROCESSING SERVICES FOR
          APPOINTMENTS MADE BY YOU IN THE SIX (6) MONTHS PRIOR TO THE EVENT
          GIVING RISE TO THE APPLICABLE CLAIM.
        </p>
        <p>
          TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, IN NO EVENT AND UNDER
          NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT
          LIABILITY, OR OTHERWISE) SHALL ANY OF THE FMD PARTIES BE LIABLE TO YOU
          (OR TO ANY THIRD PARTY CLAIMING UNDER OR THROUGH YOU) FOR ANY
          INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, CONSEQUENTIAL OR EXEMPLARY
          DAMAGES ARISING FROM YOUR USE OF, OR INABILITY TO USE, THE SERVICES.
          THESE EXCLUSIONS APPLY TO ANY CLAIMS FOR LOST PROFITS, LOST DATA, LOSS
          OF GOODWILL, BUSINESS INTERRUPTION, WORK STOPPAGE, ACCURACY OF
          RESULTS, COMPUTER FAILURE OR MALFUNCTION, ANY OTHER COMMERCIAL DAMAGES
          OR LOSSES, ANY SUBSTITUTE GOODS, SERVICES, OR TECHNOLOGY, OR MEDICAL
          MALPRACTICE OR NEGLIGENCE OF HEALTHCARE PROVIDERS UTILIZED THROUGH USE
          OF THE SERVICES, OR ANY MATTER BEYOND OUR REASONABLE CONTROL, EVEN IF
          WE KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.
          BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE
          LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN
          SUCH STATES OR JURISDICTIONS, OUR LIABILITY SHALL BE LIMITED IN
          ACCORDANCE HEREIN TO THE MAXIMUM EXTENT PERMITTED BY LAW.
        </p>
        <p>
          YOU SHALL AND HEREBY WAIVE YOUR RIGHTS WITH RESPECT TO CALIFORNIA
          CIVIL CODE SECTION 1542 OR ANY SIMILAR LAW OF ANY JURISDICTION, WHICH
          SAYS IN SUBSTANCE THAT &ldquo;A GENERAL RELEASE DOES NOT EXTEND TO
          CLAIMS WHICH THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT
          TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, AND
          THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR
          HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.&rdquo;
        </p>
        <h2>15. TERMINATION</h2>
        <p>
          If you&rsquo;re not using the Services in accordance with this
          Agreement, we reserve the right, if we determine it is best, to
          terminate, suspend and/or deactivate your FMD Account immediately,
          without notice, if there has been a violation of this Agreement
          {/* , our{' '}
        <a href="https://www.the-FMD.com/about/acceptable-use-policy">
          Acceptable Use Policy
        </a>
        , our{' '}
        <a href="https://www.the-FMD.com/about/communitystandards/">
          Community Standards
        </a>
         */}
          , or other policies and terms posted through the Services by you or by
          someone using your Credentials without authorization. We may also
          terminate, suspend or deactivate your FMD Account for any other
          reason, including inactivity for an extended period. FMD shall not be
          liable to you or any third party for any termination, suspension or
          deactivation of your access to the Services. Further, you agree not to
          attempt to use the Services after any such termination, suspension or
          deactivation (except where deactivation is due solely to inactivity,
          and you are permitted to create another FMD Account). Account
          termination may result in destruction of any content associated with
          your FMD Account. Sections 1 - 6, 9 - 18, and any other provisions
          that, by their nature, should survive termination of these Terms of
          Use shall survive any termination or expiration of these Terms of Use.
        </p>
        <p>
          FMD reserves the right to investigate and, at our discretion, take
          appropriate legal action against anyone who violates these Terms of
          Use, including without limitation, removing any offending
          communication from the Services, terminating the FMD Account of such
          violators, and or blocking your use of the Services.
        </p>
        <h2>16. INDEMNIFICATION</h2>
        <p>
          Upon a request by us, you agree to defend, indemnify, and hold
          harmless the FMD Parties from all liabilities, claims, damages (actual
          and consequential), demands, and expenses, including reasonable
          attorney&rsquo;s fees, that arise from or are related to (a) your use
          of the Services; or (b) the violation of this Agreement (including
          without limitation these Terms of Use, the Acceptable Use Policy, and
          Community Standards); or (c) the violation of any intellectual
          property or other right of any person or entity; or (d) by any person
          using your Credentials without authorization. The foregoing
          indemnification obligation does not apply to liabilities, claims and
          expenses arising as a result of our own gross negligence or
          intentional misconduct.
        </p>
        <h2>17. ARBITRATION AGREEMENT</h2>
        <p>
          We hope that we can work out any disagreements you might have with
          FMD. But if there is a dispute that needs to be further resolved, that
          process will take place according to this section. Please read the
          following ARBITRATION AGREEMENT carefully, because it requires you to
          arbitrate certain disputes and claims with FMD and limits the manner
          in which you can seek relief from FMD. Both you and FMD acknowledge
          and agree that for the purpose of any dispute arising out of relating
          to the subject matter of these Terms of Use, FMD&rsquo;s officers,
          directors, employees, and independent contractors
          (&ldquo;Personnel&rdquo;) are third-party beneficiaries of these Terms
          of Use, and that upon your acceptance of these Terms of Use, Personnel
          will have the right (and will be deemed to have accepted the right) to
          enforce these Terms of Use against you as a third-party beneficiary
          hereof.
        </p>
        <h3>17.1 Arbitration Rules; Applicability of Arbitration Agreement </h3>
        <p>
          The parties shall use their best efforts to settle any dispute, claim,
          question, or disagreement arising out of or relating to the subject
          matter of these Terms directly through good-faith negotiations, which
          shall be a precondition to either party initiating arbitration. If
          such negotiations do not resolve the dispute, it shall be finally
          settled by binding arbitration in New York County, New York. The
          arbitration will proceed in the English language, in accordance with
          the JAMS Streamlined Arbitration Rules and Procedures (the
          &ldquo;Rules&rdquo;) then in effect, by one commercial arbitrator with
          substantial experience in resolving intellectual property and
          commercial contract disputes. The arbitrator shall be selected from
          the appropriate list of JAMS arbitrators in accordance with such
          Rules. Judgment upon the award rendered by such arbitrator may be
          entered in any court of competent jurisdiction.
        </p>
        <h3>17.2 Cost of Arbitration</h3>
        <p>
          The Rules will govern payment of all arbitration fees. FMD will pay
          all arbitration fees for claims less than seventy-five thousand
          ($75,000) dollars. FMD will not seek its attorneys&rsquo; fees and
          costs in arbitration unless the arbitrator determines that your claim
          is frivolous.
        </p>
        <h3>17.3 Small Claims Court; Infringement</h3>
        <p>
          Either you or FMD may assert claims, if they qualify, in small claims
          court in New York County, New York or any United States county where
          you live or work. Furthermore, notwithstanding the foregoing
          obligation to arbitrate disputes, each party shall have the right to
          pursue injunctive or other equitable relief at any time, from any
          court of competent jurisdiction, to prevent the actual or threatened
          infringement, misappropriation or violation of a party&#39;s
          copyrights, trademarks, trade secrets, patents or other intellectual
          property rights.
        </p>
        <h3>17.4 Waiver of Jury Trial </h3>
        <p>
          YOU AND FMD WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO
          COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and FMD are
          instead choosing to have claims and disputes resolved by arbitration.
          Arbitration procedures are typically more limited, more efficient, and
          less costly than rules applicable in court and are subject to very
          limited review by a court. In any litigation between you and FMD over
          whether to vacate or enforce an arbitration award, YOU AND FMD WAIVE
          ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be
          resolved by a judge.
        </p>
        <h3>17.5 Waiver of Class or Consolidated Actions</h3>
        <p>
          ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT
          MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A
          CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE
          ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY
          OTHER CUSTOMER OR USER. If however, this waiver of class or
          consolidated actions is deemed invalid or unenforceable, neither you
          nor FMD is entitled to arbitration; instead all claims and disputes
          will be resolved in a court as set forth in (g) below.
        </p>
        <h3>17.6 Opt-out</h3>
        <p>
          You have the right to opt out of the provisions of this Section by
          sending written notice of your decision to opt out to the following
          address: FMD, Attn: Legal, 568 Broadway 9th Floor, New York, NY 10012,
          postmarked within thirty (30) days of first accepting these Terms. You
          must include (i) your name and residence address, (ii) the email
          address and/or telephone number associated with your account, and
          (iii) a clear statement that you want to opt out of these Terms&rsquo;
          arbitration agreement.
        </p>
        <h3>17.7 Exclusive Venue</h3>
        <p>
          If you send the opt-out notice in Section 17.6, and/or in any
          circumstances where the foregoing arbitration agreement permits either
          you or FMD to litigate any dispute arising out of or relating to the
          subject matter of these Terms in court, then the foregoing arbitration
          agreement will not apply to either party, and both you and FMD agree
          that any judicial proceeding (other than small claims actions) will be
          brought in the state or federal courts located in, respectively, New
          York County, New York, or the federal district in which that county
          falls.
        </p>
        <h3>17.8 Severability</h3>
        <p>
          If the prohibition against class actions and other claims brought on
          behalf of third parties contained above is found to be unenforceable,
          then all of the preceding language in this Arbitration Agreement
          section will be null and void. This arbitration agreement will survive
          the termination of your relationship with FMD.
        </p>
        <h2>18. MISCELLANEOUS</h2>
        <h3>18.1 Electronic Contracting; Copyright Dispute</h3>
        <p>
          Your affirmative act of using the Services and/or creating a FMD
          Account constitutes your electronic signature to this Agreement, which
          includes our Privacy Policy, Acceptable Use Policy and Community
          Standards. This Agreement and any other documents to be delivered in
          connection with the Services may be electronically signed, and any
          electronic signatures appearing on this Agreement or such other
          documents are the same as handwritten signatures for the purposes of
          validity, enforceability, and admissibility.
        </p>
        {/* <p>
        Please visit our{' '}
        <a href="https://www.the-FMD.com/about/acceptable-use-policy">
          Acceptable Use Policy
        </a>{' '}
        to review our copyright dispute policy.
      </p> */}
        <h3>18.2 SMS/Text Communications</h3>
        <p>
          By voluntarily providing your cell phone number to FMD, you agree that
          FMD may contact you by telephone, SMS, or MMS messages to send you
          information that we think may be of interest to you at that phone
          number.{' '}
        </p>
        <p>
          You hereby consent to receiving such communications for transactional,
          operational, or informational purposes, and you represent and warrant
          that each person you register for the Services or for whom you provide
          a wireless phone number has consented to receive communications from
          FMD. You agree to indemnify and hold FMD harmless from and against any
          and all claims, liabilities, damages (actual and consequential),
          losses and expenses (including attorneys&rsquo; fees) arising from or
          in any way related to your breach of the foregoing.{' '}
        </p>
        <p>
          Message and data rates from your mobile telephone service provider may
          apply and are subject to the terms and conditions imposed by your
          provider. You can opt out of receiving text messages at any time by
          adjusting your notification settings in your account or otherwise
          following the given instructions for doing so. Note that opting out of
          receiving all texts may impact your use of the Services.
        </p>
        <p>
          If you change or deactivate the phone number you provided to FMD, you
          have an affirmative obligation to update your account information and
          the phone number(s) attached to your account to prevent us from
          inadvertently communicating with anyone who acquires any phone
          number(s) previously attributed to you, and any new phone number(s)
          you attach to your account may receive FMD&rsquo;s standard SMS
          messages unless you also unsubscribe via the above procedures.
        </p>
        <h3>18.3 Limitation of Claims</h3>
        <p>
          No action arising under or in connection with this Agreement,
          regardless of the form, may be brought by you more than one (1) year
          after the cause of action arose; actions brought thereafter are
          forever barred.
        </p>
        <br />
        <h3>18.4 Severability</h3>
        <p>
          In the event any one or more of the provisions of this Agreement shall
          for any reason be held to be invalid, illegal or unenforceable, the
          remaining provisions of this Agreement shall be unimpaired. Further,
          the invalid, illegal or unenforceable provision shall be replaced by a
          provision that comes closest to the intention of the parties that
          underlie the invalid, illegal or unenforceable provision, except to
          the extent no such provision is valid, legal and enforceable, in which
          case such invalid, illegal or unenforceable provision shall be limited
          or eliminated to the minimum extent necessary so that the other
          provisions of this Agreement remain in full force and effect and
          enforceable.
        </p>
        <h3>18.5 Governing Law; Language</h3>
        <p>
          This Agreement shall be deemed to have been entered into and shall be
          construed and enforced in accordance with the Federal Arbitration Act,
          applicable federal law, and the laws of the State of New York as
          applied to contracts made and to be performed entirely within New
          York, without giving effect to the state&rsquo;s conflicts of law
          statute. This Agreement and all documents referenced herein were
          drafted in the English language and any translations thereof shall not
          be binding on either party to the extent they conflict with the
          English versions.
        </p>
        <h3>18.6 Entire Agreement; Waiver</h3>
        <p>
          This Agreement and any supplemental terms, policies, rules and
          guidelines posted through the Services, each of which are incorporated
          herein by reference,{' '}
          {/* including the{' '}
        <a href="/about/privacypolicy/">Privacy Policy</a>, the{' '}
        <a href="https://www.the-FMD.com/about/acceptable-use-policy">
          Acceptable Use Policy
        </a>{' '}
        and the{' '}
        <a href="https://www.the-FMD.com/about/communitystandards/">
          Community Standards
        </a>
        , */}{' '}
          constitute the entire agreement between you and us and supersede all
          previous written or oral agreements. The failure of either party to
          exercise in any respect any right provided for herein shall not be
          deemed a waiver of any further rights hereunder.
        </p>
        <h3>18.7 Headings</h3>
        <p>
          The headings of the sections of this Agreement are for convenience
          only, do not form a part hereof, and in no way limit, define,
          describe, modify, interpret or construe the meaning, scope or intent
          of this Agreement or any terms or conditions therein.
        </p>
        <h3>18.8 Assignment</h3>
        <p>
          We may assign this Agreement at any time, including, without
          limitation, to any parent, subsidiary, or any affiliated company, or
          as part of the sale to, merger with, or other transfer of our business
          or any assets to another entity. You may not assign, transfer or
          sublicense this Agreement to anyone else and any attempt to do so in
          violation of this section shall be null and void.
        </p>
        <h3>18.9 Eligibility</h3>
        <p>
          You must be 18 years of age or over, or the legal age to form a
          binding contract in your jurisdiction if that age is greater than 18
          years of age, to create a FMD Account or use the Services on your own.
          Those under the age of 13 may not use the Services. If you are between
          the ages of 13 and 18 or the applicable legal age in your
          jurisdiction, you can use the Services only under the supervision of
          your parent or guardian who has agreed to these Terms of Use. By using
          the Services on behalf of a minor child, you represent and warrant
          that you are the parent or legal guardian of such child, and that all
          references in these Terms of Use to &ldquo;you&rdquo; shall refer to
          such child or such other individual on whose behalf you have
          authorization to enter into these Terms of Use and you in your
          capacity as the parent or legal guardian of such child or as the
          authorized party of such individual.
        </p>
        <p>
          If you do not qualify under these Terms of Use, you may not use the
          Services. Use of the Services is void where prohibited by applicable
          law, and the right to access the Services is revoked in such
          jurisdictions. By using the Services, you represent and warrant that
          you have the right, authority, and capacity to enter into these Terms
          of Use. The Services are administered in the U.S. and intended for
          U.S. users only; any use outside of the U.S. or use related to
          activities outside of the U.S. is prohibited and at the user&rsquo;s
          own risk. Users are responsible for compliance with any local, state
          or federal laws applicable to their use of the Services.
        </p>
      </div>
    </>
  );
}

export default Terms;
