export const elimination = [
  {
    randomId: '3354B999',
    id: '15CB6EF2',
    Category: 'Proteins',
    Type: 'Recommendation',
    Food: '',
    Contents:
      'Lean, free-range, grass-fed, organically grown animal protein; non-GMO, organic plant protein; and wild-caught, low-mercury fish preferred.',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'A4340DC0',
    id: 'CB30D3BC',
    Category: 'Proteins',
    Type: 'Eliminate',
    Food: '',
    Contents:
      'Beef/veal, canned meats, cold cuts, eggs, frankfurters, pork, shellfish, whey, soy (miso, natto, tempeh, tofu, textured vegetable protein)',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '0B8140F1',
    id: '2234BE60',
    Category: 'Proteins',
    Type: 'Animal Proteins',
    Food: 'Fish',
    Contents: 'Halibut',
    'Serving Size': '1 oz',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: 'Size of palm of hand',
    active: 'FALSE',
  },
  {
    randomId: '9BF046FB',
    id: '0C7817BF',
    Category: 'Proteins',
    Type: 'Animal Proteins',
    Food: 'Fish',
    Contents: 'Herring',
    'Serving Size': '1 oz',
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'A304F50E',
    id: 'D44675C4',
    Category: 'Proteins',
    Type: 'Animal Proteins',
    Food: 'Fish',
    Contents: 'Mackerel',
    'Serving Size': '1 oz',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'E58C04F8',
    id: '9CDEDA4C',
    Category: 'Proteins',
    Type: 'Animal Proteins',
    Food: 'Fish',
    Contents: 'Salmon',
    'Serving Size': '1 oz',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'C5B4091D',
    id: '8A900C72',
    Category: 'Proteins',
    Type: 'Animal Proteins',
    Food: 'Fish',
    Contents: 'Sardines',
    'Serving Size': '1 oz',
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '48A549C3',
    id: '9360382C',
    Category: 'Proteins',
    Type: 'Animal Proteins',
    Food: 'Fish',
    Contents: 'Tuna',
    'Serving Size': '1 oz',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'D86A910F',
    id: '06A5CAF0',
    Category: 'Proteins',
    Type: 'Animal Proteins',
    Food: 'Wild Game',
    Contents: 'Buffalo',
    'Serving Size': '1 oz',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: 'Size of palm of hand',
    active: 'FALSE',
  },
  {
    randomId: '0B8140F2',
    id: '2234BE61',
    Category: 'Proteins',
    Type: 'Animal Proteins',
    Food: 'Beef (Not in PDF)',
    Contents: 'Grass Fed Beef',
    'Serving Size': '1 oz',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: 'Add only after 6 weeks',
    active: 'FALSE',
  },
  {
    randomId: '0B8140F3',
    id: '2234BE62',
    Category: 'Proteins',
    Type: 'Animal Proteins',
    Food: 'Eggs (Not in PDF, eliminate at first, can introduct later)',
    Contents: 'Eggs',
    'Serving Size': '1 oz',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: 'Add only after 6 weeks',
    active: 'FALSE',
  },
  {
    randomId: 'CFF7B7B1',
    id: '9E7E0403',
    Category: 'Proteins',
    Type: 'Animal Proteins',
    Food: 'Wild Game',
    Contents: 'Elk',
    'Serving Size': '1 oz',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '9937A1B1',
    id: 'E1943726',
    Category: 'Proteins',
    Type: 'Animal Proteins',
    Food: 'Wild Game',
    Contents: 'Lamb',
    'Serving Size': '1 oz',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '33CF71F3',
    id: '9741EC4D',
    Category: 'Proteins',
    Type: 'Animal Proteins',
    Food: 'Wild Game',
    Contents: 'Venison',
    'Serving Size': '1 oz',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '0AE3B180',
    id: 'AC4D043C',
    Category: 'Proteins',
    Type: 'Animal Proteins',
    Food: 'Poultry',
    Contents: 'Chicken',
    'Serving Size': '1 oz',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: 'Size of palm of hand',
    active: 'FALSE',
  },
  {
    randomId: 'B56BDD80',
    id: 'B4D53061',
    Category: 'Proteins',
    Type: 'Animal Proteins',
    Food: 'Poultry',
    Contents: 'Cornish hen',
    'Serving Size': '1 oz',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'E95DFF20',
    id: 'E777AE69',
    Category: 'Proteins',
    Type: 'Animal Proteins',
    Food: 'Poultry',
    Contents: 'Turkey',
    'Serving Size': '1 oz',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'BF4D9CC4',
    id: 'D49FAA97',
    Category: 'Proteins',
    Type: 'Plant Proteins',
    Food: 'Spirulina',
    Contents: 'Spirulina',
    'Serving Size': '2 T',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '6B14FA86',
    id: '480E23E4',
    Category: 'Proteins',
    Type: 'Protein Powders',
    Food: 'Protein Powder',
    Contents: 'Protein Powder (Hemp, pea, rice)',
    'Serving Size': '7 g',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '583D6E05',
    id: '92A73201',
    Category: 'Legumes',
    Type: 'Recommendation',
    Food: '',
    Contents: 'Organic, non-GMO preferred',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '80FA028E',
    id: '01D020AB',
    Category: 'Legumes',
    Type: 'Eliminate',
    Food: '',
    Contents:
      'Soybean products (edamame, miso, soy sauce, tamari, tempeh, tofu, soy milk, soy yogurt, textured vegetable protein)',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: 'TRUE',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'D38FD046',
    id: '01F499D3',
    Category: 'Legumes',
    Type: 'Proteins/Carbs',
    Food: '',
    Contents: 'Bean Soups',
    'Serving Size': '.75 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: 'TRUE',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'EC30EACC',
    id: '841E1FF4',
    Category: 'Legumes',
    Type: 'Proteins/Carbs',
    Food: '',
    Contents: 'Dried Beans (cooked)',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: 'TRUE',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'F303926B',
    id: 'B553CD9F',
    Category: 'Legumes',
    Type: 'Proteins/Carbs',
    Food: '',
    Contents: 'Peas (cooked)',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: 'TRUE',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'D1701C29',
    id: 'D377096C',
    Category: 'Legumes',
    Type: 'Proteins/Carbs',
    Food: '',
    Contents: 'Lentils (cooked)',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: 'TRUE',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '13D7B0E6',
    id: '3A18D604',
    Category: 'Legumes',
    Type: 'Proteins/Carbs',
    Food: '',
    Contents: 'Legume Flour',
    'Serving Size': '.25 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: 'TRUE',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: null,
    id: '6FE1FA92',
    Category: 'Legumes',
    Type: 'Proteins/Carbs',
    Food: '',
    Contents: 'Green peas (cooked)',
    'Serving Size': '.25 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: 'TRUE',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'D39724EB',
    id: 'F0EBC35D',
    Category: 'Legumes',
    Type: 'Proteins/Carbs',
    Food: '',
    Contents: 'Hummus or other bean dip',
    'Serving Size': '.33 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: 'TRUE',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'B087009D',
    id: '293FD397',
    Category: 'Legumes',
    Type: 'Proteins/Carbs',
    Food: '',
    Contents: 'Refried beans, vegerarian',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: 'TRUE',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '8FDF8AD8',
    id: '6039C445',
    Category: 'Diary Alternatives',
    Type: 'Recommendation',
    Food: '',
    Contents: 'Unsweetened, organic preferred',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '2E4FA0EF',
    id: 'E4166AE2',
    Category: 'Diary Alternatives',
    Type: 'Eliminate',
    Food: '',
    Contents:
      'Butter, cheese, cottage cheese, cream, frozen yogurt, ice cream, milk, non-dairy creamers, soy milk, yogurt (dairy and soy), whey',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '1951C201',
    id: '4109EDDA',
    Category: 'Diary Alternatives',
    Type: 'Proteins/Carbs',
    Food: 'Kefir',
    Contents: 'Kefir (Coconut - plain)',
    'Serving Size': '4-6 oz',
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': 'TRUE',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '06EA6D4B',
    id: 'CE21891C',
    Category: 'Diary Alternatives',
    Type: 'Proteins/Carbs',
    Food: 'Milk',
    Contents: 'Milk (Almond, coconut, flaxseed, hazelnut, hemp, rice)',
    'Serving Size': '8 oz',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '2329EB02',
    id: 'F5D87725',
    Category: 'Diary Alternatives',
    Type: 'Proteins/Carbs',
    Food: 'Yogurt',
    Contents: 'Yogurt Coconut (cultured)',
    'Serving Size': '4-6 oz',
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': 'TRUE',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '715DCEA0',
    id: 'E814156A',
    Category: 'Nuts/Seeds',
    Type: 'Recommendation',
    Food: '',
    Contents: 'Unsweetened, unsalted, organic preferred',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '48EE691F',
    id: '2C036D4F',
    Category: 'Nuts/Seeds',
    Type: 'Eliminate',
    Food: '',
    Contents: 'Mixed nuts (with peanuts), peanuts, peanut butter',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '6D91FCE3',
    id: 'CBB61306',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Almonds',
    'Serving Size': 6,
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: 'TRUE',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'B87BC7ED',
    id: 'B78023E2',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Brazil Nuts',
    'Serving Size': 2,
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'DFB79C42',
    id: 'C93254F3',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Cashews',
    'Serving Size': 6,
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: 'TRUE',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '92ADACF1',
    id: '6640E4F0',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Chia seeds',
    'Serving Size': '1 T',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '8C7E0BC9',
    id: '96310B65',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Coconut (dried)',
    'Serving Size': '3 T',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '364337CA',
    id: '7926A61D',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Flaxseed',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: 'TRUE',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'D1F86E3E',
    id: 'F38A91DE',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Hazelnuts',
    'Serving Size': 5,
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'D99F730E',
    id: '8BD2C620',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Hemp seeds',
    'Serving Size': '1 T',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '297CACC1',
    id: 'A5301C1B',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Macadamias',
    'Serving Size': 3,
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'EEECBC42',
    id: '2769150D',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Nut and seed butters',
    'Serving Size': '.5 T',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'E1B7FE23',
    id: '819E2C10',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Pecan Halves',
    'Serving Size': 4,
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'C5344651',
    id: 'B692AEBA',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Pine nuts',
    'Serving Size': '1 T',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '39E8AD07',
    id: '0506F092',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Pistachios',
    'Serving Size': 16,
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: 'TRUE',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '0E6918D6',
    id: '871667AD',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Pumpkin seeds',
    'Serving Size': '1 T',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '3FE52A62',
    id: 'E6A0F2CA',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Sesame seeds',
    'Serving Size': '1 T',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: 'TRUE',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '687834D4',
    id: '6F63D3F9',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Sunflower seeds',
    'Serving Size': '1 T',
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': 'TRUE',
    Glyphosates: 'TRUE',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'F694A9AD',
    id: '7E68B622',
    Category: 'Nuts/Seeds',
    Type: 'Proteins/Fats',
    Food: '',
    Contents: 'Walnut halves',
    'Serving Size': 4,
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'A465BBF5',
    id: '48BA28DA',
    Category: 'Fats/Oils',
    Type: 'Recommendation',
    Food: '',
    Contents: 'Minimally refined, cold-pressed, organic, non-GMO preferred',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '49FDCF45',
    id: '0D6A2D10',
    Category: 'Fats/Oils',
    Type: 'Eliminate',
    Food: '',
    Contents:
      'Butter, corn oil, cottonseed oil, margarine/spreads, mayonnaise, peanut oil, shortening, soybean oil',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '5499434B',
    id: 'F9647F8A',
    Category: 'Fats/Oils',
    Type: 'Fats',
    Food: '',
    Contents: 'Avocado',
    'Serving Size': '2 T or .5 Whole',
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'E554329E',
    id: '0C912B70',
    Category: 'Fats/Oils',
    Type: 'Fats',
    Food: '',
    Contents: 'Coconut milk, regular (canned)',
    'Serving Size': '1.5 T',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '98F9B6AA',
    id: '4A193C91',
    Category: 'Fats/Oils',
    Type: 'Fats',
    Food: '',
    Contents: 'Coconut milk, light (canned)',
    'Serving Size': '3 T',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'D6B5B69F',
    id: 'C44E326B',
    Category: 'Fats/Oils',
    Type: 'Fats',
    Food: '',
    Contents: 'Ghee/clarified butter (grass-fed)',
    'Serving Size': '1 t',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'D6B5B69G',
    id: 'C44E326C',
    Category: 'Fats/Oils',
    Type: 'Fats',
    Food: '',
    Contents: 'Butter (grass-fed)',
    'Serving Size': '1 t',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: 'Add only after 6 weeks',
    active: 'FALSE',
  },
  {
    randomId: '3E9B6AF7',
    id: '3E229D1A',
    Category: 'Fats/Oils',
    Type: 'Fats',
    Food: 'Olives',
    Contents: 'Olives (Black, green, kalamata)',
    'Serving Size': 8,
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: 'TRUE',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '07D50139',
    id: '7C4F42B6',
    Category: 'Fats/Oils',
    Type: 'Fats',
    Food: 'Cooking Oils',
    Contents:
      'Cooking Oils (Avocado, coconut, grapeseed, olive, rice, bran, sesame)',
    'Serving Size': '1 t',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: 'TRUE',
    'Phytic Acid': 'TRUE',
    Glyphosates: 'TRUE',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '0FC94754',
    id: '6BD2B3CE',
    Category: 'Fats/Oils',
    Type: 'Fats',
    Food: 'Salad Oils',
    Contents:
      'Salad Oils (Almond, avocado, flaxseed, grapeseed, hempseed, olive, pumpkin, safflower, sunflower, sesame, walnut)',
    'Serving Size': '1 t',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: 'TRUE',
    'Phytic Acid': 'TRUE',
    Glyphosates: 'TRUE',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '72DE9152',
    id: '546292A4',
    Category: 'Fats/Oils',
    Type: 'Fats',
    Food: '',
    Contents: 'Prepared salad dressing with acceptable oils',
    'Serving Size': '2 T',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'BA5C2B63',
    id: 'B225546D',
    Category: 'Vegetables Non-Starchy',
    Type: 'Recommendation',
    Food: '',
    Contents: 'Organic, non–GMO fruits, vegetables, herbs and spices preferred',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'E3BA3A55',
    id: '3893506D',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Artichoke',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '7BE1EF9E',
    id: '3596B23F',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Arugula',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '4FA30650',
    id: 'B0EECFEC',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Asparagus',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'EF47AB52',
    id: '615DB878',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Bamboo shoots',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'E42BC555',
    id: '7B62CD5C',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Beets (cubed)',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: 'TRUE',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'ECD11EDF',
    id: '9FA55FD9',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Bok choy',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'F366CD93',
    id: 'C20BA544',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Broccoflower',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '9561684A',
    id: 'F35081E9',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Broccoli',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'A94BAEA0',
    id: '5453A64B',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Brussels sprouts',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'EBB768F5',
    id: 'BC87F1BB',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Cabbage',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'D6DDDD53',
    id: 'EBB2E198',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Carrots',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'FB827799',
    id: 'DF0ADCCB',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Cauliflower',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '36439CE9',
    id: '37F11CE9',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Celeriac root',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '955A5004',
    id: 'AA35BA2D',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Celery',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'F82CA657',
    id: '18C1CCC8',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Chard/Swiss chard',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: 'TRUE',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '51EF567D',
    id: 37344515,
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Chervil',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '92DC55F1',
    id: '15AC3717',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Chives',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '7C769D30',
    id: '261750BB',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Cilantro',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '16235FFC',
    id: '3FB023A6',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Cucumbers',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'F6A74A90',
    id: '6ECF71EE',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Daikon radishes',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '61D1520C',
    id: '4439C143',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Eggplant',
    'Serving Size': '.5 Cup',
    'High Histamine': 'TRUE',
    Nightshades: 'TRUE',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '45D54741',
    id: '5B99C49E',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Endive',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'B5E6652D',
    id: '8CFD9E4C',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Escarole',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '0B0F538A',
    id: '377C535D',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Fennel',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 21526243,
    id: 'EE7FDDB3',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: 'Fermented vegetables',
    Contents: 'Fermented Vegies (Kimchi, pickles, sauerkraut)',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': 'TRUE',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'DDE4ED98',
    id: '0943E180',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Garlic',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '4D95A18B',
    id: '1D980907',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Green beans',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': 'TRUE',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '2CDAA9C9',
    id: '224D1EA3',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: 'Greens',
    Contents: 'Greens (Beet, collard, dandelion, kale, mustard, turnip)',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '476563A7',
    id: '0D752531',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Horseradish',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '9DD9DF06',
    id: 'E47F8509',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Jicama',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'EEC50C96',
    id: '79BBED74',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Kohlrabi',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'C652D058',
    id: 'FC806A37',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Leeks',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '301A0353',
    id: '659357FC',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Lettuce, all',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'B496B261',
    id: '3889023E',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Microgreens',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '93D83719',
    id: 'CF11CA09',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Mushrooms',
    'Serving Size': '.5 Cup',
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '0C61349E',
    id: 'F9B25AD3',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Okra',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'DFC194A0',
    id: '70BF9DB1',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Onions',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'ADDF6FF3',
    id: '193695CB',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Parsley',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'C392E75E',
    id: '3F09DC3D',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Peppers, all',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: 'TRUE',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '6AFC5FA2',
    id: '9D56DFB1',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Radicchio',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'EF9CF3CC',
    id: '97CA5630',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Radishes',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '0099493C',
    id: '2689EC8E',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Salsa',
    'Serving Size': '.5 Cup',
    'High Histamine': 'TRUE',
    Nightshades: 'TRUE',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '9670C5B6',
    id: '74B87660',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Sea vegetables',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'D2DAB550',
    id: 'FD8EFEB7',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Scallions',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '97B27C71',
    id: 'A0E8799F',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Shallots',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '2318E0DF',
    id: 'CA3DB5AB',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Snap peas/snow peas',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '4996185D',
    id: '1BB4EAA9',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Spinach',
    'Serving Size': '.5 Cup',
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: 'TRUE',
    Oxalates: 'TRUE',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '158CC63D',
    id: '69AB3186',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Sprouts, all',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'ACBE0B7B',
    id: 'C2C88A10',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: 'Squash',
    Contents: 'Squash (Delicata, spaghetti, yellow)',
    'Serving Size': '.5 Cup',
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'C2C12567',
    id: '0CC14826',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: 'Squash',
    Contents: 'Zucchini',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '736B91B6',
    id: 'BFBD0282',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: 'Squash',
    Contents: 'Pumpkin',
    'Serving Size': '',
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '3F612FA9',
    id: 'B6ECD25B',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Tomato',
    'Serving Size': '.5 Cup',
    'High Histamine': 'TRUE',
    Nightshades: 'TRUE',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '956D167E',
    id: '5E25128D',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Tomato juice',
    'Serving Size': '.5 Cup',
    'High Histamine': 'TRUE',
    Nightshades: 'TRUE',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '6274B72D',
    id: '9B54575D',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Turnips',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '9E372A69',
    id: '131F0789',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Vegetable juice',
    'Serving Size': '.75 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'A8B40B97',
    id: '765896BE',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Water chestnuts',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'AE58CDB3',
    id: 'F9F6B8B7',
    Category: 'Vegetables Non-Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Watercress',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'D19BE227',
    id: '5D34B77C',
    Category: 'Vegetables Starchy',
    Type: 'Recommendation',
    Food: '',
    Contents: 'Organic, non–GMO fruits, vegetables, herbs and spices preferred',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 85441057,
    id: '982C5AC2',
    Category: 'Vegetables Starchy',
    Type: 'Eliminate',
    Food: '',
    Contents: 'Corn, Potato (if avoiding nightshades)',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '4A8BF233',
    id: '1EAC1E0A',
    Category: 'Vegetables Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Acorn squash (cubed)',
    'Serving Size': '1 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '5070525A',
    id: 'C0A7DC32',
    Category: 'Vegetables Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Butternut squash (cubed)',
    'Serving Size': '1 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'A3C1263A',
    id: '021031AB',
    Category: 'Vegetables Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Plantain',
    'Serving Size': '.33 Cup or .5 whole',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '716BA133',
    id: '30369F7F',
    Category: 'Vegetables Starchy',
    Type: 'Carbs',
    Food: 'Potato',
    Contents: 'Potato (Purple, red, sweet, white, yellow)',
    'Serving Size': '.5 Med',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: 'TRUE',
    Saponin: '',
    Oxalates: 'TRUE',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'BCA6893D',
    id: 'C5C88D64',
    Category: 'Vegetables Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Potatoes (mashed,\nmade with non- dairy milk)',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: 'TRUE',
    Saponin: '',
    Oxalates: 'TRUE',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'B1C4FA51',
    id: '66EBC87C',
    Category: 'Vegetables Starchy',
    Type: 'Carbs',
    Food: 'Root vegetables',
    Contents: 'Root Vegetables (Parsnip, rutabaga)',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '0F064FA0',
    id: '482C696D',
    Category: 'Vegetables Starchy',
    Type: 'Carbs',
    Food: '',
    Contents: 'Yam',
    'Serving Size': '.5 Med',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '3A117755',
    id: '20D970C2',
    Category: 'Fruits',
    Type: 'Recommendation',
    Food: '',
    Contents: 'Unsweetened, no sugar added',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '2556A571',
    id: '5D243AFC',
    Category: 'Fruits',
    Type: 'Eliminate',
    Food: '',
    Contents: 'Citrus fruits (if directed by your healthcare provider)',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '24EAA4B4',
    id: '171B73EA',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Apple',
    'Serving Size': '1 Sm',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '5DF41FBF',
    id: '54F6D036',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Applesauce',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '3FE487DD',
    id: 'B692AD3B',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Apricots',
    'Serving Size': 4,
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: 'TRUE',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '4DD5FAB8',
    id: '85156C40',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Banana',
    'Serving Size': '.5 Med',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'CF5E1DEC',
    id: 'B639D209',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Blackberries',
    'Serving Size': '.75 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '6F668496',
    id: '5EEEA9DE',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Blueberries',
    'Serving Size': '.75 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '4EF74CB9',
    id: 'C9EB4C41',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Dried fruit (no sulfites)',
    'Serving Size': '2 T',
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'FFEC5C51',
    id: 'F30821B6',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Figs',
    'Serving Size': 3,
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'CBA39505',
    id: '9A01B29A',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Grapes',
    'Serving Size': 15,
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 95976365,
    id: '141B2208',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Grapefruit',
    'Serving Size': '.5 Med',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'B60102AE',
    id: '12B2C9E0',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Juices (diluted)',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'CC0E8E1C',
    id: '16F0F2F6',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Kiwi',
    'Serving Size': '1 Med',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: 'TRUE',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'D00F207E',
    id: '919F3192',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Kumquats',
    'Serving Size': 4,
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '8389EE6A',
    id: '9C7F7022',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Lemon',
    'Serving Size': 1,
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '08D9F5E1',
    id: '630F1C3D',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Lime',
    'Serving Size': 1,
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '5956A3EB',
    id: '248EFBB8',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Melon, all',
    'Serving Size': '1 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'DF69508D',
    id: 'F4F6BA0C',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Mango',
    'Serving Size': '.5 Sm',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'DE588FB8',
    id: 'D43F0B56',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Nectarine',
    'Serving Size': '1 Sm',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'F484DD91',
    id: '9A633D6B',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Orange',
    'Serving Size': '1 Med',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '6AF7F389',
    id: 'E54ED817',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Papaya',
    'Serving Size': '1 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '3CE1D6BA',
    id: '489B942D',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Peach',
    'Serving Size': '1 Sm',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '7627603B',
    id: '5D63F079',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Pear',
    'Serving Size': '1 Sm',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '4357491E',
    id: '5E4E4452',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Persimmon',
    'Serving Size': 0.5,
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'D91093E5',
    id: 'BFE0B236',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Pineapple',
    'Serving Size': '.75 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'D9023799',
    id: 'E6D80731',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Plums',
    'Serving Size': '2 Sm',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '20BD30D5',
    id: '7578F432',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Pomegranate seeds',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'B0131CEB',
    id: '6E450CEC',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Prunes',
    'Serving Size': '3 Med',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'D4FA6DDA',
    id: '32D35C34',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Raisins',
    'Serving Size': '2 T',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: 'TRUE',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'B19291A9',
    id: '0869096F',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Raspberries',
    'Serving Size': '1 Cup',
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: 'TRUE',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'AE052366',
    id: '0F6A2A45',
    Category: 'Fruits',
    Type: 'Carbs',
    Food: '',
    Contents: 'Tangerines',
    'Serving Size': '2 Sm',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '6F031D47',
    id: 'B4886FE8',
    Category: 'Gluten-Free Grains',
    Type: 'Recommendation',
    Food: '',
    Contents: 'Unsweetened, sprouted, organic preferred',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'F37DDD9F',
    id: '97C67EFB',
    Category: 'Gluten-Free Grains',
    Type: 'Eliminate',
    Food: '',
    Contents: 'Barley, corn, emmer, farro, kamut, rye, spelt, triticale, wheat',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '2AA26F84',
    id: '79CF80C4',
    Category: 'Gluten-Free Grains',
    Type: 'Carbs',
    Food: '',
    Contents: 'Amaranth',
    'Serving Size': '.75 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '74FAD1EC',
    id: '8E5D58B4',
    Category: 'Gluten-Free Grains',
    Type: 'Carbs',
    Food: '',
    Contents: 'Brown rice cakes',
    'Serving Size': 2,
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '646C8D09',
    id: '0D7A0C9F',
    Category: 'Gluten-Free Grains',
    Type: 'Carbs',
    Food: '',
    Contents: 'Buckwheat/kasha',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'A187E190',
    id: '6505D60C',
    Category: 'Gluten-Free Grains',
    Type: 'Carbs',
    Food: 'Crackers',
    Contents: 'Crackers (Nut, seed, rice)',
    'Serving Size': 4,
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '3B27AEB8',
    id: '4459135B',
    Category: 'Gluten-Free Grains',
    Type: 'Carbs',
    Food: 'Flours for baking',
    Contents: 'Baking Flour (Arrowroot, sorghum, tapioca)',
    'Serving Size': '3 T',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '85C3082C',
    id: 'A2747B36',
    Category: 'Gluten-Free Grains',
    Type: 'Carbs',
    Food: '',
    Contents: 'Millet',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'D7E7D8D4',
    id: '1C91A003',
    Category: 'Gluten-Free Grains',
    Type: 'Carbs',
    Food: 'Oats',
    Contents: 'Oats (Rolled, steel-cut)',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: 'TRUE',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'DA45DCEB',
    id: '4B9F7C14',
    Category: 'Gluten-Free Grains',
    Type: 'Carbs',
    Food: '',
    Contents: 'Quinoa',
    'Serving Size': '.5 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: 'TRUE',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: 'TRUE',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: null,
    id: '944287DD',
    Category: 'Gluten-Free Grains',
    Type: 'Carbs',
    Food: '',
    Contents: 'Rice',
    'Serving Size': '.33 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'A3F30B8C',
    id: '34AC5948',
    Category: 'Gluten-Free Grains',
    Type: 'Carbs',
    Food: '',
    Contents: 'Teff',
    'Serving Size': '.75 Cup',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 14725353,
    id: '6C74EEBC',
    Category: 'Beverages',
    Type: 'Recommendation',
    Food: '',
    Contents: 'Unsweetened, no sugar added',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '2FC8E5F2',
    id: '804746D2',
    Category: 'Beverages',
    Type: '',
    Food: '',
    Contents: 'Filtered water',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'CB8D26A5',
    id: '2F95B665',
    Category: 'Beverages',
    Type: '',
    Food: '',
    Contents: 'Sparkling/mineral water',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'CA48744B',
    id: '03492AEB',
    Category: 'Beverages',
    Type: '',
    Food: '',
    Contents: 'Unsweetened coconut water',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: 'CD4B02EA',
    id: '85F43C16',
    Category: 'Beverages',
    Type: '',
    Food: '',
    Contents: 'Green tea',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '7992A824',
    id: '359933C9',
    Category: 'Beverages',
    Type: '',
    Food: '',
    Contents: 'Fresh juiced fruits/vegetables',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '874B9B04',
    id: 'E3A50800',
    Category: 'Spices',
    Type: '',
    Food: '',
    Contents: 'All Herbs and Spices',
    'Serving Size': '',
    'High Histamine': '',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '1F539528',
    id: '8C834B3A',
    Category: 'Condiments',
    Type: '',
    Food: 'Condiments',
    Contents: 'Mustard',
    'Serving Size': '1 T',
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': '',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
  {
    randomId: '4FEC7CB9',
    id: '9FED53AF',
    Category: 'Condiments',
    Type: '',
    Food: 'Condiments',
    Contents: 'Vinegars',
    'Serving Size': '1 T',
    'High Histamine': 'TRUE',
    Nightshades: '',
    'Fermented Foods': 'TRUE',
    Lectin: '',
    Gluten: '',
    Tannin: '',
    Phytoestrogen: '',
    'Phytic Acid': '',
    Glyphosates: '',
    Casein: '',
    Phytoalexins: '',
    Saponin: '',
    Oxalates: '',
    Tips: '',
    active: 'FALSE',
  },
];
