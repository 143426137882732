import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Tabs from '../../components/PageTabs/PageTabs';
import { weeks } from '../../helpers/systemData';
import { confetti } from 'tsparticles-confetti';
import { goBack } from '../../helpers/helpers';
import Points from '../symptoms/points';
import './lifestyle.scss';
import Placeholder from '../../components/Placeholder/Placeholder';

function LifestyleDetail({ currentUser }) {
  const { id } = useParams();
  const [symptomsData, setSymptomsData] = useState(null);
  const [pageData, setPageData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const d = currentUser?.lifestyle;
    if (!d) return;
    const array = Object.values(d);
    let found;
    if (id) {
      found = array.find((m) => +m.id === +id);
    } else {
      found = array.find((m) => +m.id === 1);
    }

    setPageData(found);
    const categories = [...new Set(found?.data.map((q) => q.Category))];
    const categoriesWithData = categories.map((c) => {
      const data = found.data.filter((q) => q.Category === c);
      return { Category: c, data };
    });
    setSymptomsData(categoriesWithData);
  }, [id, currentUser]);

  useEffect(() => {
    if (getScoreStyle(pageData?.total) === 'success') {
      run();
    }
  }, []);

  const duration = 2 * 1000,
    animationEnd = Date.now() + duration,
    defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  const run = () => {
    const interval = setInterval(function () {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);

      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);
  };

  const getScoreStyle = (score, count) => {
    const ratio = score / count;
    if (ratio > 0.75) {
      return 'success';
    }
    if (ratio <= 0.74 && ratio > 0.5) {
      return 'warning';
    }
    return 'danger';
  };

  const getScoreClass = (score) => {
    if (score < 3) return 'danger';
    if (score >= 3 && score < 5) return 'warning';
    if (score >= 5) return 'success';
  };

  const tabData = [
    {
      id: 0,
      label: 'Diet',
      path: `/diet/${id}`,
    },
    {
      id: 1,
      label: 'Symptoms',
      path: `/symptoms/${id}`,
    },
    {
      id: 2,
      label: 'Daily',
      path: `/lifestyle/${id}`,
    },
  ];

  return (
    <div className="symptoms-detail lifestyle-detail page">
      <>
        <h1 className="link">
          <span onClick={() => goBack(navigate)}>
            <img src="/icons/chevron-down.svg" alt="back" />

            <>
              Week {pageData?.week || weeks[id - 1]?.week || weeks[0].week} &{' '}
              {pageData?.week + 1 ||
                weeks[id - 1]?.week + 1 ||
                weeks[0].week + 1}
            </>
          </span>
          <small className="faded">
            {pageData && (
              <>
                Started:{' '}
                {new Date(pageData?.date)?.toLocaleDateString('en-US', {
                  weekday: 'short',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </>
            )}
          </small>
        </h1>
        {+id !== 0 && <Tabs tabs={tabData} selectedTabId={2} />}
      </>
      {pageData && (
        <div className="card card-vertical">
          <>
            <h2 className="score">
              <span className={`${getScoreStyle(pageData?.total)}`}>
                {pageData?.total}
              </span>
              <small>
                <span className="faded">/</span>{' '}
                {pageData?.data.length && pageData?.data.length * 6}
              </small>
            </h2>
            <h5 className="faded">
              {getScoreStyle(pageData?.total) === 'success' &&
                'You are crushing it in life!'}
              {getScoreStyle(pageData?.total) === 'warning' &&
                "Getting there, let's do this!"}
              {getScoreStyle(pageData?.total) === 'danger' &&
                'Put in the work, you can do this!'}
            </h5>
            <div className="top-right">
              <Link className="header-link" to={`/lifestyle/${id}/edit`}>
                <small>Edit</small>
              </Link>
            </div>

            {symptomsData?.map((q, idx) => (
              <div className="item" key={idx}>
                <h4
                  style={{ marginBottom: 0, fontSize: '1.25em', opacity: 0.5 }}
                >
                  {q.Category}
                </h4>
                {q.data.map((d, index) => (
                  <div key={`line-${index}`} className="line-item">
                    {d.Question}{' '}
                    <Points
                      pointClass={getScoreClass(d.Points)}
                      item={d}
                      score={d.Score || null}
                      pointsData={d.Options.map((dd) => dd.value)}
                    />
                  </div>
                ))}
              </div>
            ))}
          </>
        </div>
      )}
      {!pageData && (
        <Placeholder
          title="Add your daily activity log progress"
          image=""
          link="Add"
          route={`/lifestyle/${id}/add`}
        />
      )}
    </div>
  );
}

export default LifestyleDetail;
