import React from 'react';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function Logout({ setCurrentUser }) {
  const auth = getAuth();
  const navigate = useNavigate();

  auth
    .signOut()
    .then(() => {
      // Sign-out successful.
      navigate('/');
      setCurrentUser(null);
    })
    .catch((error) => {
      // An error happened.
    });
  return <div></div>;
}

export default Logout;
