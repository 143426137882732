import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../../layout/Navigation/Navigation';

function Privacy({ currentUser, fbUser }) {
  return (
    <>
      <Navigation currentUser={currentUser} fbUser={fbUser} />
      <div className="page">
        <h1>Privacy Policy</h1>
        <p>Effective date: February 17, 2023</p>
        <p>
          At FMD (Hey Hey Gorgeous, LLC). (&ldquo;FMD,&rdquo; &ldquo;we,&rdquo;
          or &ldquo;us&rdquo;), we are committed to protecting your privacy, and
          we take great care with your personal information that we gather when
          you access or use FMD.com and related websites, applications, and
          services owned and operated by FMD and that link to this Privacy
          Policy (collectively, the &ldquo;Services&rdquo;). This Privacy Policy
          is meant to help those that use our Services to explore providers or
          book appointments (&ldquo;Users&rdquo;) and doctors, dentists, or
          other healthcare specialists, professionals, providers, organizations
          or agents, or affiliates thereof that use our marketing services
          (&ldquo;Healthcare Providers,&rdquo; and collectively with Users,
          &ldquo;you,&rdquo; or &ldquo;your&rdquo;) understand how we treat your
          personal information.
          <span>
            BY USING OR ACCESSING THE SERVICES IN ANY MANNER, YOU ACKNOWLEDGE
            THAT YOU ACCEPT THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY
            POLICY, AND YOU HEREBY CONSENT THAT WE WILL COLLECT, USE, AND SHARE
            YOUR INFORMATION IN THE FOLLOWING WAYS. IF YOU DO NOT AGREE WITH
            THIS PRIVACY POLICY, YOU MAY NOT USE THE SERVICES. IF YOU USE THE
            SERVICES ON BEHALF OF SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN ENTITY
            (SUCH AS YOUR EMPLOYER), YOU REPRESENT THAT YOU ARE AUTHORIZED BY
            SUCH INDIVIDUAL OR ENTITY TO ACCEPT THIS PRIVACY POLICY ON SUCH
            INDIVIDUAL&rsquo;S OR ENTITY&rsquo;S BEHALF.
          </span>
          Any use of FMD&rsquo;s Services is at all times subject to the
          Agreement (as the term &ldquo;Agreement&rdquo; is defined in our
          <Link to="/terms">Terms of Use</Link>, which incorporates this Privacy
          Policy). You may print a copy of this Privacy Policy by clicking{' '}
          <a href="javascript:window.print()">here</a>. Thank you so much for
          choosing FMD.
        </p>
        <h2>Privacy Policy Table of Contents</h2>
        <ul>
          <li>
            <h3>
              <u>
                <a href="#hipaa-and-phi">HIPAA and PHI</a>
              </u>
            </h3>
          </li>
          <li>
            <h3>
              <u>
                <a href="#personal-data">Personal Data</a>
              </u>
            </h3>
            <ul>
              <li>
                <h3>
                  <u>
                    <a href="#user-personal-data">User Personal Data</a>
                  </u>
                </h3>
                <ul>
                  <li>
                    <h3>
                      <u>
                        <a href="#user-categories-of-personal-data">
                          Categories of Personal Data We Collect
                        </a>
                      </u>
                    </h3>
                  </li>
                  <li>
                    <h3>
                      <u>
                        <a href="#user-categories-of-sources">
                          Categories of Sources of Personal Data
                        </a>
                      </u>
                    </h3>
                  </li>
                  <li>
                    <h3>
                      <u>
                        <a href="#user-commercial-purposes">
                          Commercial or Business Purposes for Collecting Data
                        </a>
                      </u>
                    </h3>
                  </li>
                  <li>
                    <h3>
                      <u>
                        <a href="#user-how-we-share">
                          How We Share Your Personal Data
                        </a>
                      </u>
                    </h3>
                  </li>
                </ul>
              </li>
              <li>
                <h3>
                  <u>
                    <a href="#healthcare-provider-personal">
                      Healthcare Provider Personal Data
                    </a>
                  </u>
                </h3>
                <ul>
                  <li>
                    <h3>
                      <u>
                        <a href="#provider-categories-of-personal-data">
                          Categories of Personal Data We Collect
                        </a>
                      </u>
                    </h3>
                  </li>
                  <li>
                    <h3>
                      <u>
                        <a href="#provider-categories-of-sources">
                          Categories of Sources of Personal Data
                        </a>
                      </u>
                    </h3>
                  </li>
                  <li>
                    <h3>
                      <u>
                        <a href="#provider-commercial-purposes">
                          Commercial or Business Purposes for Collecting Data
                        </a>
                      </u>
                    </h3>
                  </li>
                  <li>
                    <h3>
                      <u>
                        <a href="#provider-how-we-share">
                          How We Share Your Personal Data
                        </a>
                      </u>
                    </h3>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <h3>
              <u>
                <a href="#tracking-tools">
                  Tracking Tools, Advertising and Opt-Out
                </a>
              </u>
            </h3>
          </li>
          <li>
            <h3>
              <u>
                <a href="#data-security">Data Security and Retention</a>
              </u>
            </h3>
          </li>
          <li>
            <h3>
              <u>
                <a href="#childrens-privacy">Children&rsquo;s Privacy</a>
              </u>
            </h3>
          </li>
          <li>
            <h3>
              <u>
                <a href="#info-non-personal-nor-phi">
                  How We Use Information That is Neither Personal Data nor PHI
                </a>
              </u>
            </h3>
          </li>
          <li>
            <h3>
              <u>
                <a href="#controlling-personal-notifications">
                  Controlling Your Personal Data &amp; Notifications
                </a>
              </u>
            </h3>
          </li>
          <li>
            <h3>
              <u>
                <a href="#california-rights">
                  California Rights &amp; Disclosures
                </a>
              </u>
            </h3>
          </li>
          <li>
            <h3>
              <u>
                <a href="#virginia-rights">Virginia Rights &amp; Disclosures</a>
              </u>
            </h3>
          </li>
          <li>
            <h3>
              <u>
                <a href="#colorado-rights">Colorado Rights &amp; Disclosures</a>
              </u>
            </h3>
          </li>
          <li>
            <h3>
              <u>
                <a href="#exercising-rights">Exercising Your Rights</a>
              </u>
            </h3>
          </li>
          <li>
            <h3>
              <u>
                <a href="#changes-to-policy">Changes to This Privacy Policy</a>
              </u>
            </h3>
          </li>
          <li>
            <h3>
              <u>
                <a href="#contact-information">Contact Information</a>
              </u>
            </h3>
          </li>
        </ul>
        <h3>
          <u>
            <a name="hipaa-and-phi"> HIPAA and PHI</a>
          </u>
        </h3>
        <p>
          Certain demographic, health and/or health-related information that FMD
          collects about Users as part of providing the Services to our
          Healthcare Providers may be &ldquo;protected health information&rdquo;
          or &ldquo;PHI&rdquo; and governed by the Health Insurance Portability
          and Accountability Act and its implementing regulations
          (&ldquo;HIPAA&rdquo;). Specifically, when (i) FMD is providing
          administrative, operational, and other services to a Health Care
          Provider and this Healthcare Provider is a &ldquo;Covered
          Entity&rdquo; (as such term is defined in HIPAA); and (ii) in order to
          provide those services, FMD receives identifiable information about a
          User on behalf of the Healthcare Provider, FMD is acting as a
          &ldquo;Business Associate&rdquo; (as such term is defined in HIPAA) of
          the Health Care Provider, and this identifiable information is
          regulated as PHI.
        </p>
        <p>
          HIPAA provides specific protections for the privacy and security of
          PHI and restricts how PHI is used and disclosed.
          <span>
            Please read the Notice of Privacy Practices of your Health Provider
            to understand how your PHI can be used and disclosed. HIPAA does not
            apply to Personal Data (defined below) that is not PHI.{' '}
          </span>
          Personal data that a User provides to FMD when FMD is not acting as a
          Business Associate is not PHI. To provide just a few examples, when
          you (i) create an account, (ii) search for Healthcare Providers or
          available appointments with Healthcare Providers, (iii) complete
          general medical history forms that are not required by or provided by
          a particular Healthcare Provider (&ldquo;General Medical History
          Forms&rdquo;), (iv) post reviews, or (v) provide device/IP Information
          or Web Analytics information by browsing our websites (see below).
        </p>
        <a></a>
        <h3>
          <u>
            <a name="personal-data">Personal Data</a>
          </u>
        </h3>
        <p>
          The following subsections detail the categories of Personal Data that
          we collect and have collected over the past twelve (12) months.
          &ldquo;Personal Data&rdquo; means any information that identifies or
          relates to a particular individual and also includes information
          referred to as &ldquo;personally identifiable information&rdquo; or
          &ldquo;personal information&rdquo; under applicable data privacy laws,
          rules, or regulations. For each category of Personal Data, these
          subsections also set out the source of that Personal Data, our
          commercial or business purpose for collecting that Personal Data, and
          the categories of third parties with whom we share that Personal Data.
          More information regarding those sources and categories are set forth
          below.
        </p>
        <h3>
          <u>COVID-19 Data</u>
        </h3>
        <p>
          Personal Data that a User provides to FMD for the purpose of Covid-19
          vaccine scheduling may be shared with local, state, and federal public
          health authorities. These authorities may require or request
          information maintained by FMD in connection with your appointments to
          receive the Covid-19 vaccination. By using this service, you agree
          that FMD may provide any data related to your Covid-19 vaccine to
          government authorities and that the data sent to those authorities may
          contain Personal Data.
        </p>
        <h3>
          <u>
            <a name="user-personal-data">User Personal Data</a>
          </u>
        </h3>
        <p>
          <span>
            THE FOLLOWING SUBSECTIONS APPLY ONLY TO USERS. IF YOU ARE A
            HEALTHCARE PROVIDER, PLEASE SEE THE
            <a href="#healthcare-provider-personal">
              HEALTHCARE PROVIDER PERSONAL DATA
            </a>
            SECTION BELOW.
          </span>
        </p>
        <br />
        <h3>
          <u>
            <a name="user-categories-of-personal-data">
              Categories of Personal Data We Collect
            </a>
          </u>
        </h3>
        <br />
        <div>
          <table>
            <thead>
              <tr>
                <th colspan="1">
                  <p>Category of Personal Data</p>
                </th>
                <th colspan="1">
                  <p>Examples of Personal Data Collected</p>
                </th>
                <th colspan="1">
                  <p>Source</p>
                </th>
                <th colspan="1">
                  <p>
                    Third Parties With Whom We Share Data For Business Purposes
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="1">
                  <p>Payment Information</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Payment card type</li>
                    <li>Last four digits of payment card</li>
                    <li>Billing contact</li>
                    <li>Billing email</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <li>
                    Service Providers (specifically, our current payment
                    processing partner, Stripe, Inc. (&ldquo;Stripe&rdquo;)
                  </li>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Device/IP Information</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>IP address</li>
                    <li>Device ID</li>
                    <li>Domain server</li>
                    <li>
                      Type of device/operating system/browser used to access the
                      Services
                    </li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                    <li>Third Parties</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Ad Networks</li>
                    <li>
                      Third-Party Business Partners You Access Through the
                      Services
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <p>Web Analytics</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Web page interactions</li>
                    <li>
                      Referring webpage/source through which you access the
                      Services
                    </li>
                    <li>Non-identifiable request IDs</li>
                    <li>
                      Statistics associated with the interaction between device
                      or browser and the Services
                    </li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                    <li>Third Parties</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Ad Networks</li>
                    <li>
                      Third-Party Business Partners You Access Through the
                      Service
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <p>Geolocation Data</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>IP address-based location information</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Ad Networks</li>
                    <li>
                      Third-Party Business Partners You Access Through the
                      Service
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <p>
                    Other Identifying Information That You Voluntarily Choose to
                    Provide
                  </p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Unique identifiers such as passwords</li>
                    <li>
                      Personal Data in emails, letters, or other communications
                      you send to us
                    </li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <p>User Contact Data</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>First and last name</li>
                    <li>E-mail</li>
                    <li>Phone number</li>
                    <li>Mailing address</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Ad Networks</li>
                    <li>Healthcare Providers</li>
                    <li>Insurance Providers</li>
                    <li>Health Information Exchanges</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <p>User Demographic Data</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Gender and/or gender identity</li>
                    <li>Age</li>
                    <li>Date of birth</li>
                    <li>Zip code</li>
                    <li>Race</li>
                    <li>Sexual orientation</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <li>You</li>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Ad Networks</li>
                    <li>Healthcare Providers</li>
                    <li>Health Information Exchanges</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <p>Medical Data</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Health conditions</li>
                    <li>Healthcare Providers visited</li>
                    <li>Reasons for visit</li>
                    <li>Dates of visit</li>
                    <li>
                      Medical history and health information you provide us
                    </li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Healthcare Providers</li>
                    <li>Insurance Providers</li>
                    <li>Health Information Exchanges</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <p>Insurance Information</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Insurance carrier</li>
                    <li>Insurance plan</li>
                    <li>Member ID</li>
                    <li>Group ID</li>
                    <li>Payer ID</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Healthcare Providers</li>
                    <li>Health Information Exchanges</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <p>Booking Appointment Data</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Appointment date/time</li>
                    <li>Provider information</li>
                    <li>Appointment procedure</li>
                    <li>
                      Whether or not user is a new patient for a particular
                      provider
                    </li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                    <li>Third Parties</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Healthcare Providers</li>
                    <li>Health Information Exchanges</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <p>Social Network Data</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>E-mail</li>
                    <li>Phone number</li>
                    <li>Username</li>
                    <li>IP address</li>
                    <li>Device ID</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                    <li>Third Parties</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Ad Networks</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="3">
                  <p>
                    Categories of Data that may be Considered
                    &ldquo;Sensitive&rdquo; Under certain privacy laws
                  </p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Health information, such as:</li>
                  </ul>
                  <ul>
                    <li>Health conditions</li>
                    <li>Healthcare Providers visited</li>
                    <li>Reasons for visit</li>
                    <li>Dates of visit</li>
                    <li>
                      Medical history and health information you provide us
                    </li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Healthcare Providers</li>
                    <li>Insurance Providers</li>
                    <li>Health Information Exchanges</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Sexual orientation</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Ad Networks</li>
                    <li>Healthcare Providers</li>
                    <li>Health Information Exchanges</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      Unique identifiers such as your account login and password
                    </li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <h3>
          <u>
            <a name="user-categories-of-sources">
              Categories of Sources of Personal Data
            </a>
          </u>
        </h3>
        <div>
          <table>
            <thead>
              <tr>
                <th colspan="2">
                  <p>Categories of Sources of Personal Data</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="2">
                  <p>From You</p>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <p>When You Provide Information Directly to Us</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      When you create an account or use our interactive tools
                      and services, such as searching for Healthcare Providers
                      or available appointments with Healthcare Providers and
                      completing Medical History Forms prior to Healthcare
                      Provider appointments.
                    </li>
                    <li>
                      When you voluntarily provide information in free-form text
                      boxes through the Services or through responses to surveys
                      and questionnaires, or post reviews.
                    </li>
                    <li>When you send us an email or otherwise contact us.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>
                    When Personal Data is Automatically Collected When You Use
                    the Services
                  </p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Through Cookies (defined below).</li>
                    <li>
                      If you download and install certain applications and
                      software we make available, we may receive and collect
                      information transmitted from your computing device for the
                      purpose of providing you the relevant Services, such as
                      information regarding when you are logged on and available
                      to receive updates or alert notices.
                    </li>
                    <li>
                      If you download our mobile application or use a
                      location-enabled browser, we may receive information about
                      your location and mobile device, as applicable.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <p>From Third Parties</p>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Service Providers</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      We may use service providers to analyze how you interact
                      and engage with the Services, or to help us provide you
                      with customer support.
                    </li>
                    <li>
                      We may use service providers to obtain information to
                      generate leads and to create user profiles.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Analytics Partners</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      We may work with analytics partners to provide us
                      analytics on website traffic or the usage of the Services.
                    </li>
                    <li>
                      We use this data to optimize and market our Services.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Healthcare Providers</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      We may receive certain data from your Healthcare
                      Provider(s) to facilitate booking appointments.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Social Networks</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      If you provide your social network account credentials to
                      us or otherwise sign in to the Services through a
                      third-party site or service, you understand some content
                      and/or information in those accounts may be transmitted
                      into your account with us.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Advertising Partners</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      We receive information about you from some of our service
                      providers who assist us with marketing or promotional
                      services related to how you interact with our Services,
                      advertisements or communications.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <h3>
          <u>
            <a name="user-commercial-purposes">
              Commercial or Business Purposes for Collecting Data
            </a>
          </u>
        </h3>
        <ul>
          <li>
            <h3>Providing, Customizing, and Improving the Services</h3>
            <ul>
              <li>
                Creating and managing your account or other user profiles.
              </li>
              <li>Billing our healthcare provider clients.</li>
              <li>
                Providing you with the products, services, and information you
                request.
              </li>
              <li>
                Meeting or fulfilling the reason you provided the information to
                us.
              </li>
              <li>Providing support and assistance for the Services.</li>
              <li>
                Improving the Services, including testing, research, internal
                analytics, and product development.
              </li>
              <li>
                Personalizing the Services, website content, and communications
                based on your preferences.
              </li>
              <li>Fraud protection, security, and debugging.</li>
            </ul>
          </li>
          <li>
            <h3>Marketing the Services</h3>
            <ul>
              <li>Marketing and selling the Services.</li>
              <li>
                Showing you advertisements, including interest-based or online
                behavioral advertising.
              </li>
            </ul>
          </li>
          <li>
            <h3>Corresponding with You</h3>
            <ul>
              <li>
                Responding to correspondence that we receive from you,
                contacting you when necessary or requested, including to remind
                you of an upcoming appointment, and sending you information
                about FMD or the Services.
              </li>
              <li>
                Sending emails and other communications that display content
                that we think will interest you and according to your
                preferences including notifying you about certain resources,
                Healthcare Providers or Services.
              </li>
            </ul>
          </li>
          <li>
            <h3>Legal Requirements</h3>
            <ul>
              <li>
                Fulfilling our legal obligations under applicable law,
                regulation, court order or other legal process, such as
                preventing, detecting and investigating security incidents and
                potentially illegal or prohibited activities.
              </li>
              <li>
                Protecting the rights, property or safety of you, FMD or another
                party.
              </li>
              <li>Enforcing any agreements with you.</li>
              <li>
                Responding to claims that any posting or other content violates
                third-party rights.
              </li>
              <li>Resolving disputes.</li>
            </ul>
          </li>
        </ul>
        <br />
        <h3>
          <u>
            <a name="user-how-we-share">How We Share Your Personal Data</a>
          </u>
        </h3>
        <p>
          In certain circumstances, we may share your Personal Data with the
          following categories of service providers and other third parties for
          the indicated business purposes:
        </p>
        <br />
        <div>
          <table>
            <thead>
              <tr>
                <th colspan="1">
                  <p>
                    Categories of Third Parties With Whom We Share Personal Data
                  </p>
                </th>
                <th colspan="1">
                  <p>Business Purpose for Sharing Data</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="2" rowspan="1">
                  <p>Service Providers</p>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <p>Payment Processors</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      Our payment processing partner (currently Stripe, Inc.
                      (&ldquo;Stripe&rdquo;)) collects your voluntarily provided
                      payment card information necessary to process your
                      payment.
                    </li>
                    <li>
                      Please see Stripe&rsquo;s terms of service and privacy
                      policy for information on its use and storage of Personal
                      Data.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Security and Fraud Prevention Consultants</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      Detecting security incidents, protecting against
                      malicious, deceptive, fraudulent, or illegal activity, and
                      prosecuting those responsible for that activity.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>
                    <span>
                      Hosting, Technology and Communications Providers;
                    </span>
                  </p>
                  <p>
                    <span>Fulfillment Providers;</span>
                  </p>
                  <p>
                    <span>Data Storage Providers;</span>
                  </p>
                  <p>
                    <span>Analytics Providers;</span>
                  </p>
                  <p>
                    <span>Insurance Verification Providers;</span>
                  </p>
                  <p>
                    <span>Staff Augmentation Personnel</span>
                  </p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      To perform operational services (such as hosting, billing,
                      fulfillment, data storage, security, insurance
                      verification, web service analytics) and/or make certain
                      services, features or functionality available to our
                      Users.
                    </li>
                    <li>
                      Debugging to identify and repair errors that impair
                      intended functionality.
                    </li>
                    <li>
                      Short-term, transient use of Personal Data that is not
                      used by another party to build a User profile or otherwise
                      alter your user experience outside the current
                      interaction.
                    </li>
                    <li>
                      Performing services on our behalf, including maintaining
                      or servicing accounts, providing customer service,
                      processing or fulfilling orders and transactions,
                      verifying customer information, processing payments,
                      providing financing, providing advertising or marketing
                      services, providing analytic services, or providing
                      similar services on behalf of the business or service
                      provider.
                    </li>
                    <li>
                      Undertaking internal research for technological
                      development and demonstration.
                    </li>
                    <li>
                      Undertaking activities to verify or maintain the quality
                      or safety of our Services.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="2" rowspan="1">
                  <p>Selected Recipients</p>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Analytics Partners</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      To track how users found or were referred to the Services
                      and otherwise interact with the Services.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Ad Networks</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Ad customizing and serving.</li>
                    <li>
                      Auditing related to a current interaction and concurrent
                      transactions, including, but not limited to, counting ad
                      impressions to unique visitors, verifying positioning and
                      quality of ad impressions, and auditing compliance with
                      this specification and other standards.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Healthcare Providers</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      Healthcare Providers with whom Users choose to schedule
                      through the Services. For example, if you complete a
                      Medical History Form using the Services in advance of an
                      appointment and elect to share it with your selected
                      Healthcare Provider.
                    </li>
                    <li>
                      If you choose to use the applicable Services, Healthcare
                      Providers in order to enable them to refer you to, and
                      make appointments with, other Healthcare Providers on your
                      behalf or to perform analyses on potential health issues
                      or treatments.
                    </li>
                    <li>In the event of an emergency.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Insurance Providers</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      To determine eligibility and cost-sharing obligations and
                      to otherwise obtain benefit plan information on your
                      behalf.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Health Information Exchanges</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      Health Information Exchanges and related organizations
                      that collect and organize User information (such as
                      Regional Health Information Organizations) to make your
                      information more securely and easily accessible to your
                      Healthcare Providers. The goal of such organizations is to
                      facilitate access to health information to improve the
                      safety, quality, and efficiency of patient-centered care.
                      More information on Health Information Exchanges can be
                      found
                      <a href="https://www.healthit.gov/HIE">here</a>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <p>Parties You Authorize, Access or Authenticate</p>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Other Users</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      Any information that you may reveal in a review posting or
                      online discussion, or forum is intentionally open to the
                      public and is not in any way private. We recommend that
                      you carefully consider whether to disclose any Personal
                      Data in any public posting or forum. What you have written
                      may be seen and/or collected by third parties and may be
                      used by others in ways we are unable to control or
                      predict. You can learn more about our reviews process at
                      FMD.com/verified reviews.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>
                    Third-Party Business Partners You Access Through the
                    Services
                  </p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      We will share certain Personal Data if you choose to use
                      any service to log in to the Services.
                    </li>
                    <li>
                      To meet or fulfill the reason you provided the information
                      to us.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <h3>
          <a name="healthcare-provider-personal">
            Healthcare Provider Personal Data
          </a>
        </h3>
        <p>
          <span>
            THE FOLLOWING SUBSECTIONS APPLY ONLY TO HEALTHCARE PROVIDERS. IF YOU
            ARE A USER, PLEASE SEE THE
            <a href="#user-personal-data">USER PERSONAL DATA</a> SECTION ABOVE.
          </span>
        </p>
        <br />
        <h3>
          <a name="provider-categories-of-personal-data">
            Categories of Personal Data We Collect
          </a>
        </h3>
        <br />
        <div>
          <table>
            <thead>
              <tr>
                <th colspan="1">
                  <p>Category of Personal Data</p>
                </th>
                <th colspan="1">
                  <p>Examples of Personal Data Collected</p>
                </th>
                <th colspan="1">
                  <p>Source</p>
                </th>
                <th colspan="1">
                  <p>
                    Third Parties With Whom We Share Data For Business Purposes
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Payment Information</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Payment card type</li>
                    <li>Last four digits of payment card</li>
                    <li>Billing contact</li>
                    <li>Billing email</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      Service Providers (specifically, our current payment
                      processing partner, Stripe, Inc. (&ldquo;Stripe&rdquo;)
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Device/IP Information</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>IP address</li>
                    <li>Device ID</li>
                    <li>Domain server</li>
                    <li>
                      Type of device/operating system/browser used to access the
                      Services
                    </li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                    <li>Third Parties</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Ad Networks</li>
                    <li>
                      Third-Party Business Partners You Access Through the
                      Services
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Web Analytics</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>webpage interactions</li>
                    <li>
                      Referring webpage/source through which you access the
                      Services
                    </li>
                    <li>Non-identifiable request IDs</li>
                    <li>
                      Statistics associated with the interaction between device
                      or browser and the Services
                    </li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                    <li>Third Parties</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Ad Networks</li>
                    <li>
                      Third-Party Business Partners You Access Through the
                      Services
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Geolocation Data</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>IP address-based location information</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                    <li>Third Parties</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Ad Networks</li>
                    <li>
                      Third-Party Business Partners You Access Through the
                      Services
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>
                    Other Identifying Information That You Voluntarily Choose to
                    Provide
                  </p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Unique identifiers such as passwords</li>
                    <li>Personal Data in emails or letters you send to us</li>
                    <li>
                      Personal information that you disclose over the phone
                    </li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Healthcare Provider Contact Data</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>First and last name</li>
                    <li>E-mail</li>
                    <li>Phone number</li>
                    <li>Mailing address</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                    <li>Third Parties</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Ad Networks</li>
                    <li>Healthcare Providers</li>
                    <li>Insurance Providers</li>
                    <li>Health Information Exchanges</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Healthcare Provider Demographic Data</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Gender and/or gender identity</li>
                    <li>Age</li>
                    <li>Date of birth</li>
                    <li>Zip code</li>
                    <li>Race</li>
                    <li>Sexual orientation</li>
                    <li>Spoken language</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                    <li>Third Parties</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Ad Networks</li>
                    <li>Healthcare Providers</li>
                    <li>Health Information Exchanges</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Professional License Information</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Professional licenses</li>
                    <li>Education history</li>
                    <li>Specialties and certifications</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                    <li>Third Parties</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="2">
                  <p>
                    Categories of data that may be considered
                    &ldquo;Sensitive&rdquo; under certain privacy laws
                  </p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Sexual orientation</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                    <li>Third Parties</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                    <li>Ad Networks</li>
                    <li>Healthcare Providers</li>
                    <li>Health Information Exchanges</li>
                  </ul>
                  <ul>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      Unique identifiers such as your account login and password
                    </li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>You</li>
                  </ul>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Service Providers</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <h3>
          <u>
            <a name="provider-categories-of-sources">
              Categories of Sources of Personal Data
            </a>
          </u>
        </h3>
        <br />
        <div>
          <table>
            <thead>
              <tr>
                <th colspan="2">
                  <p>Categories of Sources of Personal Data</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="2" rowspan="1">
                  <p>From You</p>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>When You Provide Information Directly to Us</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>When you create an account.</li>
                    <li>When you send us an email or otherwise contact us.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>
                    When Personal Data is Automatically Collected When You Use
                    the Services
                  </p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Through Cookies (defined below).</li>
                    <li>
                      If you download and install certain applications and
                      software we make available, we may receive and collect
                      information transmitted from your computing device for the
                      purpose of providing you the relevant Services, such as
                      information regarding when you are logged on and available
                      to receive updates or alert notices.
                    </li>
                    <li>
                      If you download our mobile application or use a
                      location-enabled browser, we may receive information about
                      your location and mobile device, as applicable.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="2" rowspan="1">
                  <p>From Third Parties</p>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Service Providers</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      We may use service providers to analyze how you interact
                      and engage with the Services, or to help us provide you
                      with customer support.
                    </li>
                    <li>
                      We may use service providers to obtain information to
                      generate leads and to create user profiles.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Analytics Partners</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      We may work with analytics partners to provide us
                      analytics on website traffic or the usage of the Services.
                    </li>
                    <li>
                      We use this data to optimize and market our Services.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Government or Public Records</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      We may use government or other public records for
                      onboarding or verifying Healthcare Providers.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Advertising Partners</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      We receive information about you from some of our service
                      providers who assist us with marketing or promotional
                      services related to how you interact with our Services,
                      advertisements, or communications.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <h3>
          <u>
            <a name="provider-commercial-purposes">
              Commercial or Business Purposes for Collecting Data
            </a>
          </u>
        </h3>
        <ul>
          <li>
            <h3>Providing, Customizing, and Improving the Services</h3>
            <ul>
              <li>Creating and managing your account or other user profile.</li>
              <li>
                Producing invoices and billing statements for our clients.
              </li>
              <li>
                Providing you with the products, services, and information you
                request.
              </li>
              <li>
                Meeting or fulfilling the reason you provided the information to
                us.
              </li>
              <li>Providing support and assistance for the Services.</li>
              <li>
                Improving the Services, including testing, research, internal
                analytics, and product development.
              </li>
              <li>
                Personalizing the Services, website content, and communications
                based on your preferences.
              </li>
              <li>Fraud protection, security, and debugging.</li>
            </ul>
          </li>
          <li>
            <h3>Marketing the Services</h3>
            <ul>
              <li>Marketing and selling the Services.</li>
              <li>
                Showing you advertisements, including interest-based or online
                behavioral advertising.
              </li>
            </ul>
          </li>
          <li>
            <h3>Corresponding with You</h3>
            <ul>
              <li>
                Responding to correspondence that we receive from you,
                contacting you when necessary or requested, including to remind
                you of an upcoming appointment, and sending you information
                about FMD or the Services.
              </li>
              <li>
                Sending emails and other communications that display content
                that we think will interest you and according to your
                preferences including notifying you about certain resources,
                Healthcare Providers, or services.
              </li>
            </ul>
          </li>
          <li>
            <h3>Legal Requirements</h3>
            <ul>
              <li>
                Fulfilling our legal obligations under applicable law,
                regulation, court order, or other legal processes, such as
                preventing, detecting, and investigating security incidents and
                potentially illegal or prohibited activities.
              </li>
              <li>
                Protecting the rights, property, or safety of you, FMD, or
                another party.
              </li>
              <li>Enforcing any agreements with you.</li>
              <li>
                Responding to claims that any posting or other content violates
                third-party rights.
              </li>
              <li>Resolving disputes.</li>
            </ul>
          </li>
          <li>
            <h3>Onboarding Verification</h3>
            <ul>
              <li>
                Confirming providers have the necessary credentials to practice
                in the state where advertised.
              </li>
            </ul>
          </li>
        </ul>
        <br />
        <h3>
          <u>
            <a name="provider-how-we-share">
              How We Disclose Your Personal Data
            </a>
          </u>
        </h3>
        <p>
          In certain circumstances, we may disclose your Personal Data to the
          following categories of service providers and other third parties for
          the indicated business purposes:
        </p>
        <br />
        <div>
          <table>
            <thead>
              <tr>
                <th colspan="1">
                  <p>
                    Categories of Third Parties With Whom We Share Personal Data
                  </p>
                </th>
                <th colspan="1">
                  <p>Business Purpose for Sharing Data</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="2" rowspan="1">
                  <p>Service Providers</p>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Payment Processors</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      Our payment processing partner (currently Stripe, Inc.
                      (&ldquo;Stripe&rdquo;)) collects your voluntarily provided
                      payment card information necessary to process your
                      payment.
                    </li>
                    <li>
                      Please see Stripe&rsquo;s terms of service and privacy
                      policy for information on its use and storage of Personal
                      Data.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Security and Fraud Prevention Consultants</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      Detecting security incidents, protecting against
                      malicious, deceptive, fraudulent, or illegal activity, and
                      prosecuting those responsible for that activity.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Hosting, Technology, and Communications Providers;</p>
                  <p>Fulfillment Providers;</p>
                  <p>Data Storage Providers;</p>
                  <p>Analytics Providers;</p>
                  <p>Insurance Verification Providers;</p>
                  <p>Staff Augmentation Personnel</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      To perform operational services (such as hosting, billing,
                      fulfillment, data storage, security, insurance
                      verification, web service analytics) and/or make certain
                      services, features or functionality available to our
                      Users.
                    </li>
                    <li>
                      Debugging to identify and repair errors that impair
                      existing intended functionality.
                    </li>
                    <li>
                      Short-term, transient use of Personal Data that is not
                      used by another party to build a User profile or otherwise
                      alter your user experience outside the current
                      interaction.
                    </li>
                    <li>
                      Performing services on our behalf, including maintaining
                      or servicing accounts, providing customer service,
                      processing or fulfilling orders and transactions,
                      verifying customer information, processing payments,
                      providing financing, providing advertising or marketing
                      services, providing analytic services, or providing
                      similar services on behalf of the business or service
                      provider.
                    </li>
                    <li>
                      Undertaking internal research for technological
                      development and demonstration.
                    </li>
                    <li>
                      Undertaking activities to verify or maintain the quality
                      or safety of our Services.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="2" rowspan="1">
                  <p>Selected Recipients</p>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Analytics Partners</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      To track how users found or were referred to the Services
                      and otherwise interact with the Services.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Ad Networks</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>Ad customizing and serving.</li>
                    <li>
                      Auditing related to a current interaction and concurrent
                      transactions, including, but not limited to, counting ad
                      impressions to unique visitors, verifying positioning and
                      quality of ad impressions, and auditing compliance with
                      this specification and other standards.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>Health Information Exchanges</p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      Health Information Exchanges and related organizations
                      that collect and organize User information (such as
                      Regional Health Information Organizations) to make your
                      information more securely and easily accessible to your
                      Healthcare Providers. The goal of such organizations is to
                      facilitate access to health information to improve the
                      safety, quality, and efficiency of patient-centered care.
                      More information on Health Information Exchanges can be
                      found
                      <a href="https://www.healthit.gov/HIE">here</a>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="2" rowspan="1">
                  <p>Parties You Authorize, Access, or Authenticate</p>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <p>
                    Third-Party Business Partners You Access Through the
                    Services
                  </p>
                </td>
                <td colspan="1" rowspan="1">
                  <ul>
                    <li>
                      We will share certain Personal Data if you choose to use
                      any service to log in to the Services.
                    </li>
                    <li>
                      To meet or fulfill the reason you provided the information
                      to us.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <h3>THE FOLLOWING SECTIONS APPLY TO ONLY TO USERS.</h3>
        <br />
        <p>Legal Obligations</p>
        <p>
          We may share any Personal Data that we collect with third parties in
          conjunction with any of the activities set forth under &ldquo;Meeting
          Legal Requirements and Enforcing Legal Terms&rdquo; in the &ldquo;Our
          Commercial or Business Purposes for Collecting Personal Data&rdquo;
          sections above.
        </p>
        <br />
        <p>Business Transfers</p>
        <p>
          All Personal Data may be transferred to a third party if we undergo a
          merger, acquisition, bankruptcy, or other transaction in which that
          third party assumes control of our business (in whole or in part).
          Should one of these events occur, we will make reasonable efforts to
          notify you before your information becomes subject to different
          privacy and security policies and practices.
        </p>
        <br />
        <p>Data that is Not Personal Data</p>
        <p>
          We may create aggregated and/or de-identified data from the Personal
          Data we collect, including by removing information that makes the data
          personally identifiable to a particular user. We may use such
          aggregated, de-identified, or anonymized data and share it with third
          parties for our lawful business purposes, including to analyze, build,
          and improve the Services and promote our business, provided that we
          will not share such data in a manner that could identify you.
        </p>
        <br />
        <h3>
          <u>
            <a name="tracking-tools">
              Tracking Tools, Advertising, and Opt-Out
            </a>
          </u>
        </h3>
        <p>
          The following sections provide additional information about how we
          collect your Personal Data.
        </p>
        <p>Information Collected Automatically</p>
        <p>
          The Services use cookies and similar technologies such as pixel tags,
          web beacons, clear GIFs, mobile identifiers, and JavaScript
          (collectively, &ldquo;Cookies&rdquo;) to enable our servers to
          recognize your web browser and tell us how and when you visit and use
          our Services. We do this to analyze trends, learn about and advertise
          to our user base, and operate and improve our Services. For example,
          we use Cookies to tailor the Services or customize advertisements by
          tracking navigation habits, measuring performance, storing
          authentication status so re-entering credentials is not required,
          customizing user experiences with the Services, and for analytics and
          fraud prevention. Cookies are small pieces of data &ndash; usually
          text files &ndash; placed on your computer, tablet, phone, or similar
          device when you use that device to visit our Services. We may also
          supplement the information we collect from you with information
          received from third parties, including third parties that have placed
          their own Cookies on your device(s).
        </p>
        <p>We use the following types of Cookies:</p>
        <ul>
          <li>
            <u>Essential Cookies</u>. Essential Cookies are required to provide
            you with features or services you have requested. For example,
            certain Cookies enable you to log into the secure areas of our
            Services. Disabling these Cookies may make certain features and
            services unavailable.
          </li>
          <li>
            <u>Functional Cookies</u>. Functional Cookies are used to record
            your choices and settings regarding our Services, maintain your
            preferences over time and recognize you when you return to our
            Services. These Cookies help us to personalize our content for you,
            greet you by name, and remember your preferences (for example, your
            choice of language or region).
          </li>
          <li>
            <u>Performance/Analytical Cookies</u>. Performance/Analytical
            Cookies allow us to understand how visitors use our Services such as
            by collecting information about the number of visitors to the
            Services, what pages visitors view on our Services, how long
            visitors are viewing pages on the Services, mouse clicks, mouse
            movements, scrolling activity, and text typed into the Services.
            Performance/Analytical Cookies also help us measure the performance
            of our advertising campaigns to help us improve our campaigns and
            the content for those who engage with our advertising. For example,
            Google Inc. (&ldquo;Google&rdquo;) uses cookies in connection with
            its Google Analytics services. Google&rsquo;s ability to use and
            share information collected by Google Analytics about your visits to
            the Services is subject to the Google Analytics Terms of Use and the
            Google Privacy Policy. You have the option to opt-out of
            Google&rsquo;s use of cookies by visiting the
            <a href="http://www.google.com/privacy_ads.html">
              Google advertising opt-out page
            </a>
            or the
            <a href="https://tools.google.com/dlpage/gaoptout/">
              Google Analytics Opt-out Browser Add-on page
            </a>
            .
          </li>
          <li>
            <u>Retargeting/Advertising Cookies</u>. Retargeting/Advertising
            Cookies collect data about your online activity and identify your
            interests to provide advertising that we believe is relevant to you.
            For more information about this, please see the section below titled
            &ldquo;Information about Interest-Based Advertisements.&rdquo;
          </li>
          <li>
            <u>Web Beacons</u>Web Beacons (e.g., clear GIFs or pixel tags) are
            tiny graphic image files embedded in a webpage or email that may be
            used to collect information about the use of our Services, the web
            services of selected advertisers, and the emails, special
            promotions, or newsletters that we send. The information collected
            by Web Beacons allows us to analyze how many people are using the
            Services, using selected publishers&rsquo; web services or opening
            emails, and for what purpose. Also, it allows us to enhance our
            interest-based advertising (discussed further below).
          </li>
          <li>
            <u>Mobile Device Identifiers</u>Mobile device identifiers help FMD
            learn more about our Users&rsquo; demographics and internet
            behaviors. Mobile device identifiers are data stored on mobile
            devices that may track mobile devices and data, activities occurring
            on and through it, and the applications installed on it. Mobile
            device identifiers enable the collection of Personal Data, such as
            media access control, address, location, and tracking data,
            including without limitation IP address, domain server, type of
            device(s) used to access the Services, web browser(s) used to access
            the Services, referring webpage or other source through which you
            accessed the Services, other statistics, and information associated
            with the interaction between your browser or device and the
            Services.
          </li>
          <li>
            <u>Cross Device Matching</u>. To determine if users have interacted
            with content across multiple devices and to match such devices, we
            may work with partners who analyze device activity data and/or rely
            on your information (including demographic, geographic, and
            interest-based data). We may also provide de-identified data to
            these partners to supplement this analysis. Based on this data, we
            may display targeted advertisements across devices that we believe
            are associated or use this data to further analyze usage of Services
            across devices.
          </li>
        </ul>
        <br />
        <p>
          You can decide whether or not to accept Cookies through your internet
          browser&rsquo;s settings. Most browsers have an option for turning off
          the Cookie feature, which will prevent your browser from accepting new
          Cookies, as well as (depending on your browser software) allowing you
          to decide on acceptance of each new Cookie in a variety of ways. You
          may also be able to reject mobile device identifiers by activating the
          appropriate setting on your mobile device. You can also delete all
          Cookies that are already on your computer. Although you are not
          required to accept FMD&rsquo;s Cookies, if you block, reject, or
          delete them, you may have to manually adjust some preferences every
          time you access the Services, as some functionalities may not work.
        </p>
        <br />
        <p>
          To explore what Cookie settings are available to you, look in the
          &ldquo;preferences&rdquo; or &ldquo;options&rdquo; section of your
          browser&rsquo;s menu. To find more information about Cookies,
          including how to manage and delete Cookies, please visit
          <a href="http://www.allaboutcookies.org/">here</a>.
        </p>
        <br />
        <p>
          Your browser may offer you a &ldquo;Do Not Track&rdquo; option, which
          allows you to signal to operators of websites and web applications,
          and services that you do not wish such operators to track certain of
          your online activities over time and across different websites. Not
          all browsers offer a Do Not Track option, and there is currently no
          industry consensus as to what constitutes a Do Not Track signal.
          Please note that, for these reasons and because of our use of Cookies,
          our Services, like many website operators, do not support &ldquo;Do
          Not Track&rdquo; requests sent from a browser at this time. To find
          out more about &ldquo;Do Not Track,&rdquo; you can visit
          <a href="http://www.allaboutdnt.com">here</a>.
        </p>
        <p>Information about Interest-Based Advertisements</p>
        <p>
          We may serve advertisements, and also allow third-party ad networks,
          including third-party ad servers, ad agencies, ad technology vendors
          and research firms, to serve advertisements through the Services.
          These advertisements may be targeted to users who fit certain general
          profile categories or display certain preferences or behaviors
          (&ldquo;Interest-Based Ads&rdquo;). Information for Interest-Based Ads
          (including Personal Data) may be provided to us by you or derived or
          inferred from the online activity or usage patterns of particular
          users on the Services and/or services of third parties. Such
          information may include IP address, mobile device ID, operating
          system, browser, webpage interactions, geographic location, and
          demographic information, such as gender and age range. Such
          information may be gathered through tracking users&rsquo; activities
          across time and unaffiliated properties, including when you leave the
          Services. To accomplish this, we or our service providers may deliver
          Cookies, including Web Beacons, from an ad network to you through the
          Services. Web Beacons allow ad networks to provide anonymized,
          aggregated auditing, research and reporting for us and for
          advertisers. This information helps FMD learn more about our
          Users&rsquo; demographics and internet behaviors. Web Beacons also
          enable ad networks to serve targeted advertisements to you when you
          visit other websites. Web Beacons allow ad networks to view, edit or
          set their own Cookies on your browser, just as if you had requested a
          webpage from their site.
        </p>
        <br />
        <p>
          We comply with the Digital Advertising Alliance (&ldquo;DAA&rdquo;)
          Self-Regulatory Principles for Online Behavioral Advertising. Through
          the DAA and Network Advertising Initiative (&ldquo;NAI&rdquo;),
          several media and marketing associations have developed an industry
          self-regulatory program to give users a better understanding of and
          greater control over ads that are customized based on a user&rsquo;s
          online behavior across different websites and properties. To make
          choices about Interest-Based Ads from participating third parties,
          including to opt-out of receiving behaviorally targeted advertisements
          from participating organizations, please visit the DAA&rsquo;s or
          NAI&rsquo;s User opt-out pages, which are located
          <a href="http://www.networkadvertising.org/choices/">here</a>, or
          install the DAA&rsquo;s AppChoice app (for
          <a href="https://itunes.apple.com/us/app/appchoices/id894822870?mt=8">
            iOS
          </a>
          ; for
          <a href="https://play.google.com/store/apps/details?id=com.DAA.appchoices&hl=en">
            Android
          </a>
          ) on your mobile computing device. When you use these opt-out
          features, an &ldquo;opt-out&rdquo; Cookie will be placed on your
          computer, tablet, or mobile computing device, indicating that you do
          not want to receive Interest-Based Ads from NAI or DAA member
          companies. If you delete Cookies on your computer, tablet, or mobile
          computing device, you may need to opt-out again. For information about
          how to opt out of Interest-Based Ads on mobile devices, please visit
          <a href="http://www.applicationprivacy.org/expressing-your-behavioral-advertising-choices-on-a-mobile-device">
            here
          </a>
          . You will need to opt out of each browser and device for which you
          desire to apply these opt-out features.
        </p>
        <br />
        <p>
          Please note that even after opting out of Interest-Based Ads, you may
          still see FMD advertisements that are not interest-based (i.e., not
          targeted toward you). Also, opting out does not mean that FMD is no
          longer using tracking tools. FMD may still collect information about
          your use of the Services even after you have opted out of
          Interest-Based Ads and may still serve advertisements to you via the
          Services based on information collected via the Services.
        </p>
        <br />
        <h3>
          <u>
            <a name="data-security">Data Security</a>
          </u>
        </h3>
        <p>
          The security of your Personal Data is important to us. We seek to
          protect your Personal Data from unauthorized access, use, and
          disclosure using appropriate physical, technical, organizational, and
          administrative security measures based on the type of Personal Data
          and how we process that data. We endeavor to follow generally accepted
          industry standards to protect the Personal Data submitted to us, both
          during transmission and in storage. For example, the Services use
          industry-standard Secure Sockets Layer (SSL) technology to allow for
          the encryption of Personal Data. We store and process your information
          on our servers in the United States and abroad. We maintain what we
          consider industry-standard backup and archival systems. You should
          also help protect your data by appropriately selecting and protecting
          your password and/or other sign-on mechanisms; limiting access to your
          computer or device and browser; and signing off after you have
          finished accessing your account. Although we work to protect the
          security of your account and other data that we hold in our records,
          for example, by making good faith efforts to store Personal Data in a
          secure operating environment that is not open to the public, please be
          aware that no method of transmitting data over the Internet or storing
          data is completely secure. We cannot and do not guarantee the complete
          security of any data you share with us, and except as expressly
          required by law, we are not responsible for the theft, destruction,
          loss, or inadvertent disclosure of your information or content.
        </p>
        <p>
          If at any time during or after our relationship, we believe that the
          security of your Personal Data may have been compromised, we may seek
          to notify you of that development.&nbsp;If a notification is
          appropriate, we will endeavor to notify you as promptly as possible
          under the circumstances.&nbsp;If we have your e-mail address, we may
          notify you by e-mail to the most recent e-mail address you have
          provided us in your account profile. Please keep your e-mail address
          in your account up to date. You can update that e-mail address anytime
          in your account profile. If you receive a notice from us, you can
          print it to retain a copy. To receive these notices, you must check
          your e-mail account using your computer or mobile device and email
          application software.
          <span>
            You consent to our use of e-mail as a means of such notification. If
            you prefer for us to use the U.S. Postal Service to notify you in
            this situation, please e-mail us at
            <a target="_blank" href="mailto:Privacy-Notice@FMD.com">
              Privacy-Notice@FMD.com
            </a>
          </span>
          . Please include your address when you submit your request. You can
          make this election at any time, and it will apply to notifications we
          make after a reasonable time thereafter for us to process your
          request. You may also use this e-mail address to request a print copy,
          at no charge, of an electronic notice we have sent to you regarding a
          compromise of your Personal Data.
        </p>
        <br />
        <h3>
          <u>
            <a name="data-retention">Data Retention</a>
          </u>
        </h3>
        <p>
          We retain Personal Data about you as necessary to provide our Services
          or to perform our business or commercial purposes for collecting your
          Personal Data. When establishing a retention period for specific
          categories of data, we consider who we collected the data from, our
          need for the Personal Data, why we collected the Personal Data, and
          the sensitivity of the Personal Data. In some cases, we retain
          Personal Data for longer, if doing so is necessary to comply with our
          legal obligations, resolve disputes or collect fees owed, provide our
          Services, or is otherwise permitted or required by applicable law,
          rule or regulation. We may further retain information in an anonymous
          or aggregated form where that information would not identify you
          personally.
        </p>
        <p>For example:</p>
        <ul>
          <li>
            We retain your account information and credentials for as long as
            you have an account with us.
          </li>
          <li>
            We retain your device/IP data for as long as we need it to ensure
            that our systems are working appropriately, effectively, and
            efficiently.
          </li>
          <li>
            We retain any protected health information (&ldquo;PHI&rdquo;)
            consistent with our obligations under our Business Associate
            Agreements with Covered Entities and HIPAA.
          </li>
        </ul>
        <br />
        <h3>
          <u>
            <a name="childrens-privacy">Children&rsquo;s Privacy</a>
          </u>
        </h3>
        <p>
          The Services are not directed to or intended for use by children under
          13 years of age. If you are a child under the age of 13, please do not
          attempt to register for or otherwise use the Services or send us any
          Personal Data. By accessing, using, and/or submitting information to
          or through the Services, you represent that you are over the age of
          13. As noted in the Terms of Use, we do not knowingly collect or
          solicit Personal Data from children under the age of 13. If we learn
          that we have received any Personal Data directly from a child under
          age 13 without first receiving their parent&rsquo;s verified consent,
          we will use that Personal Data only to respond directly to that child
          (or their parent or legal guardian) to inform the child that they
          cannot use the Services. We will then subsequently delete that
          child&rsquo;s Personal Data. If you believe that a child under 13 may
          have provided us with Personal Data, please contact us at
          <a target="_blank" href="mailto:hello@heyheygorgeous.com">
            hello@heyheygorgeous.com
          </a>
          .
        </p>
        <p>
          If you are between the age thirteen (13) and the age of majority in
          your place of residence, you may use the Services only with the
          consent of or under the supervision of your parent or legal guardian.
          If you are a parent or legal guardian of a minor child, you may, in
          compliance with the Agreement, use the Services on behalf of such
          minor child. Any information that you provide us while using the
          Services on behalf of your minor child will be treated as Personal
          Data as otherwise provided herein.
        </p>
        <p>
          If you use the Services on behalf of another person, regardless of
          age, you agree that FMD may contact you for any communication made in
          connection with providing the Services or any legally required
          communications. You further agree to forward or share any such
          communication with any person for whom you are using the Services on
          behalf.
        </p>
        <br />
        <h3>
          <u>
            <a name="info-non-personal-nor-phi">
              How We Use Information That is Neither Personal Data nor PHI
            </a>
          </u>
        </h3>
        <p>
          Certain information that FMD collects may be neither Personal Data nor
          PHI, including information that does not include any identifiable
          information at collection or which we have de-identified and/or
          aggregated from Personal Data or PHI. We may use this information for
          any purpose permitted by applicable law, including but not limited to
          purposes of better understanding who uses FMD and how we can deliver a
          better digital healthcare experience.
        </p>
        <br />
        <h3>
          <u>
            <a name="controlling-personal-notifications">
              Controlling Your Personal Data &amp; Notifications
            </a>
          </u>
        </h3>
        <p>
          If you are a registered user of the Services, you can modify certain
          Personal Data or account information by logging in and accessing your
          account. If you wish to close your account, please e-mail us at
          <a target="_blank" href="mailto:hello@heyheygorgeous.com">
            hello@heyheygorgeous.com
          </a>
          . FMD will use reasonable efforts to delete your account as soon as
          reasonably possible. Please note, however, that FMD reserves the right
          to retain information from closed accounts consistent with our
          internal data retention policies and procedures.
        </p>
        <p>
          You must promptly notify us if any of your account data is lost,
          stolen, or used without permission.
        </p>
        <br />
        <h3>
          <u>
            <a name="california-rights">California Rights and Disclosures</a>
          </u>
        </h3>
        <p>
          The California Consumer Privacy Act of 2018, as amended by the
          California Privacy Rights Act (&ldquo;CCPA&rdquo;), provides
          California residents with specific rights regarding their personal
          information. Please see the &ldquo;Exercising Your Rights&rdquo;
          section below for instructions regarding how to exercise these rights.
          This section describes your CCPA rights and explains how to exercise
          those rights. If you have any questions about this section or whether
          any of the following applies to you, please contact us at
          <a target="_blank" href="mailto:hello@heyheygorgeous.com">
            hello@heyheygorgeous.com
          </a>
          and indicate &ldquo;California Rights&rdquo; in the subject line of
          your communication.
        </p>
        <p>Access</p>
        <p>
          You have the right to request certain information about our collection
          and use of your Personal Data over the past 12 months, including the
          following:
        </p>
        <ul>
          <li>
            The categories of Personal Data that we have collected about you.
          </li>
          <li>
            The categories of sources from which that Personal Data was
            collected.
          </li>
          <li>
            The business or commercial purpose for collecting or selling your
            Personal Data.
          </li>
          <li>
            The categories of third parties with whom we have shared your
            Personal Data.
          </li>
          <li>
            The specific pieces of Personal Data that we have collected about
            you.
          </li>
        </ul>
        <p>
          If we have disclosed your Personal Data for a business purpose over
          the past 12 months, we will identify the categories of Personal Data
          shared with each third-party recipient. If we have sold your Personal
          Data over the past 12 months, we will identify the categories of
          Personal Data purchased by third-party recipients.
        </p>
        <p>Deletion</p>
        <p>
          You have the right to request that we delete the Personal Data that we
          have collected from you. Under the CCPA, this right is subject to
          certain exceptions: for example, we may need to retain your Personal
          Data to provide you with the Services or complete a transaction or
          other action you have requested. If your deletion request is subject
          to one of these exceptions, we may deny your deletion request.
        </p>
        <p>Correction</p>
        <p>
          You have the right to request that we correct any inaccurate Personal
          Data we have collected about you. Under the CCPA, this right is
          subject to certain exceptions: for example, if we decide, based on the
          totality of circumstances related to your Personal Data, that such
          data is correct. If your correction request is subject to one of these
          exceptions, we may deny your request.
        </p>
        <p>Processing of Sensitive Personal Information Opt-Out</p>
        <p>
          We collect Personal Data that is considered &ldquo;Sensitive Personal
          Information&rdquo; under the CCPA. Because we use or disclose
          Sensitive Personal Information for purposes other than those set forth
          in section 7027(m) of the CCPA regulations, California residents have
          the right to request that we limit the use or sharing of their
          Sensitive Personal Information (&ldquo;Right to Limit&rdquo;). The
          Right to Limit allows California residents to direct a business that
          collects Sensitive Personal Information to limit its use of this
          information to that use which is necessary to perform the services or
          provide the goods reasonably expected by an average consumer who
          requests those goods or services, to perform the services set forth in
          paragraphs (2), (4), (5), and (8) of subdivision (e) of Section
          1798.140, and as authorized in the CCPA regulations.
        </p>
        <p>
          <a name="california-personal-sharing">
            Personal Data Sharing or Selling
          </a>
        </p>
        <p>
          Under the CCPA, California residents have certain rights when a
          business &ldquo;shares&rdquo; or &ldquo;sells&rdquo; Personal Data
          with third parties for purposes of cross-contextual behavioral
          advertising. Under the laws of the CCPA, such &ldquo;sharing&rdquo; is
          also considered &ldquo;selling.&rdquo; Accordingly, we have shared and
          sold the foregoing categories of Personal Data for the purposes of
          cross-contextual behavioral advertising:
        </p>
        <ul>
          <li>Demographic data</li>
          <li>Web analytics or other similar network activity information</li>
        </ul>
        <br />
        <p>
          As described in the &ldquo;Tracking Tools, Advertising and
          Opt-Out&rdquo; section above, we have incorporated Cookies from
          certain third parties into our Services. These Cookies allow those
          third parties to receive information about your activity on our
          Services that is associated with your browser or device. Those third
          parties may use that data to serve you relevant ads on our Services or
          on other websites you visit. Under the CCPA, sharing your data through
          third-party Cookies for online advertising may be considered a
          &ldquo;sale&rdquo; of information. You can opt out of data selling
          and/or sharing by following the instructions in this section.
        </p>
        <p>
          We share Personal Data with the following categories of third parties:
        </p>
        <ul>
          <li>Advertising partners</li>
        </ul>
        <br />
        <p>
          Over the past 12 months, we have shared your Personal Data with the
          categories of third parties listed above for the following purposes:
        </p>
        <ul>
          <li>Marketing and advertising the Services.</li>
          <li>
            Showing you advertisements, including interest-based or online
            behavioral advertising.
          </li>
        </ul>
        <br />
        <p>
          Once you have submitted an opt-out request, we will not ask you to
          reauthorize the sharing of your Personal Data for at least 12 months.
          To our knowledge, we do not share the Personal Data of minors under 16
          years of age.
        </p>
        <br />
        <p>
          We Will Not Discriminate Against You for Exercising Your Rights Under
          the CCPA.
        </p>
        <p>
          We will not discriminate against you for exercising your rights under
          the CCPA. We will not deny you our goods or services, charge you
          different prices or rates, or provide you with a lower quality of
          goods and services if you exercise your rights under the CCPA.
        </p>
        <p>Financial Incentives</p>
        <p>
          At FMD, we value your data because it allows us to better understand
          your goals and preferences and improve our products and services to
          better serve you. From time to time, we may offer a financial
          incentive for your participation in our user research. The financial
          incentive we offer is based on our determination of the estimated
          value of your data. If you participate in the research, you may be
          asked to submit personal information in surveys, forms, or through
          your account. The personal information you submit in connection with
          this research will only be used internally to improve our product; we
          will never share or sell this data to third parties.
        </p>
        <p>
          Through your participation in the research, we may collect the
          following categories of personal information about you:
        </p>
        <ul>
          <li>
            Identifiers, such as your name, address, unique personal identifier,
            online identifier, internet protocol address, email address, account
            name, social security number, driver&rsquo;s license number,
            passport number, or other similar identifiers.
          </li>
          <li>
            Characteristics of protected classifications under California or
            federal law.
          </li>
          <li>
            Commercial information, such as products or services you&rsquo;ve
            purchased, obtained, or considered, or other purchasing or consuming
            histories or tendencies.
          </li>
          <li>
            Internet or other electronic network activity information, such as
            your browsing history, search history, and information regarding
            your interaction with an internet website, application, or
            advertisement.
          </li>
          <li>Geolocation data.</li>
          <li>
            Audio, electronic, visual, thermal, olfactory, or similar
            information. For example, we may collect your voice recordings.
          </li>
          <li>Professional or employment-related information.</li>
          <li>
            Education information, defined as information that is not publicly
            available personally identifiable information as defined in the
            Family Educational Rights and Privacy Act (20 U.S.C. Sec. 1232g; 34
            C.F.R. Part 99).
          </li>
          <li>
            Any other personal information you may enter into a free-form field
            of our surveys or forms.
          </li>
          <li>
            Inferences drawn from any of the foregoing categories of personal
            information.
          </li>
        </ul>
        <p>
          Participation in the financial incentive is entirely optional. If you
          participate in a financial incentive, you agree that we are not
          required to comply with your right to know or delete personal
          information collected in exchange for the financial incentive. If you
          are a participant in a financial incentive we offer, you may withdraw
          from the financial incentive at any time by emailing us at the email
          address provided to you with your individual notice of the financial
          incentive.
        </p>
        <p>Other California Resident Rights</p>
        <p>
          Under California Civil Code Sections 1798.83-1798.84, California
          residents are entitled to contact us to prevent disclosure of Personal
          Data to third parties for such third parties&rsquo; direct marketing
          purposes. To submit such a request, please contact us at
          <a target="_blank" href="mailto:hello@heyheygorgeous.com">
            hello@heyheygorgeous.com
          </a>
          .
        </p>
        <br />
        <h3>
          <u>
            <a name="virginia-rights">
              Virginia Resident Rights and Disclosures
            </a>
          </u>
        </h3>
        <p>
          If you are a Virginia resident, you have the rights set forth under
          the Virginia Consumer Data Protection Act (&ldquo;VCDPA&rdquo;).
          Please see the &ldquo;Exercising Your Rights&rdquo; section below for
          instructions regarding how to exercise these rights. Please note that
          we may process Personal Data of our customers&rsquo; end users or
          employees in connection with our provision of certain services to our
          customers. If we are processing your Personal Data as a service
          provider, you should contact the entity that collected your Personal
          Data in the first instance to address your rights with respect to such
          data. Additionally, please note that these rights are subject to
          certain conditions and exceptions under applicable law, which may
          permit or require us to deny your request.
        </p>
        <p>
          If there are any conflicts between this section and any other
          provision of this Privacy Policy and you are a Virginia resident, the
          portion that is more protective of Personal Data shall control to the
          extent of such conflict. If you have any questions about this section
          or whether any of the following rights apply to you, please contact us
          at
          <a target="_blank" href="mailto:hello@heyheygorgeous.com">
            hello@heyheygorgeous.com
          </a>
          and indicate &ldquo;Virginia Rights&rdquo; in the subject line of your
          communication.
        </p>
        <p>Access</p>
        <p>
          You have the right to request confirmation of whether or not we are
          processing your Personal Data and to access your Personal Data.
        </p>
        <p>Correction</p>
        <p>
          You have the right to correct inaccuracies in your Personal Data, to
          the extent such correction is appropriate in consideration of the
          nature of such data and our purposes of processing your Personal Data.
        </p>
        <p>Portability</p>
        <p>
          You have the right to request a copy of your Personal Data in a
          machine-readable format to the extent technically feasible.
        </p>
        <p>Deletion</p>
        <p>You have the right to delete your Personal Data.</p>
        <p>Opt-Out of Certain Processing Activities</p>
        <ul>
          <li>
            You have the right to opt-out to the processing of your Personal
            Data for targeted advertising purposes. As discussed in the
            &ldquo;Personal Data Sharing&rdquo; section of the
            <a href="#california-personal-sharing">
              &ldquo;California Rights&rdquo; section of this Privacy Policy
            </a>
            , we process your Personal Data for targeted advertising purposes.
            To opt-out of our processing of Personal Data for targeted
            advertising purposes, please see the
            <a href="#exercising-rights">
              &ldquo;Exercising Your Rights&rdquo; section
            </a>
            .
          </li>
          <li>
            You have the right to opt-out to the sale of your Personal Data. We
            do not currently sell your Personal Data as defined under the VCDPA.
          </li>
          <li>
            You have the right to opt-out from processing your Personal Data for
            the purposes of profiling in furtherance of decisions that produce
            legal or similarly significant effects to you, if applicable.
          </li>
        </ul>
        <p>Appealing a Denial</p>
        <p>
          If we refuse to take action on a request within a reasonable period of
          time after receiving your request in accordance with this section. In
          such appeal, you must (1) provide sufficient information to allow us
          to verify that you are the person about whom the original request
          pertains and to identify the original request and (2) provide a
          description of the basis of your appeal. Please note that your appeal
          will be subject to your rights and obligations afforded to you under
          the VCDPA. We will respond to your appeal within 60 days of receiving
          your request. If we deny your appeal, you have the right to contact
          the Virginia Attorney General using the methods described
          <a href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint">
            here
          </a>
          .
        </p>
        <p>You may appeal a decision by us using the following methods:</p>
        <ul>
          <li>
            Email us at:
            <a target="_blank" href="mailto:hello@heyheygorgeous.com">
              hello@heyheygorgeous.com
            </a>
            (title must include &ldquo;VCDPA Appeal&rdquo;)
          </li>
          <li>Call us at: (855) 962-3621</li>
        </ul>
        <br />
        <h3>
          <u>
            <a name="colorado-rights">Colorado Rights and Disclosure</a>
          </u>
        </h3>
        <p>
          If you are a Colorado resident, you have the rights set forth under
          the Colorado Privacy Act (&ldquo;CPA&rdquo;). Please see the
          &ldquo;Exercising Your Rights&rdquo; section below for instructions
          regarding how to exercise these rights. Please note that we may
          process Personal Data of our customers&rsquo; end users or employees
          in connection with our provision of certain services to our customers.
          If we are processing your Personal Data as a processor, you should
          contact the entity that collected your Personal Data in the first
          instance to address your rights with respect to such data.
          Additionally, please note that these rights are subject to certain
          conditions and exceptions under applicable law, which may permit or
          require us to deny your request.
        </p>
        <p>
          If there are any conflicts between this section and any other
          provision of this Privacy Policy and you are a Colorado resident, the
          portion that is more protective of Personal Data shall control to the
          extent of such conflict. If you have any questions about this section
          or whether any of the following rights apply to you, please contact us
          at
          <a target="_blank" href="mailto:hello@heyheygorgeous.com">
            hello@heyheygorgeous.com
          </a>
          and indicate &ldquo;Colorado Rights&rdquo; in the subject line of your
          communication.
        </p>
        <p>Access</p>
        <p>
          You have the right to request confirmation of whether or not we are
          processing your Personal Data and to access your Personal Data.
        </p>
        <p>Correction</p>
        <p>
          You have the right to correct inaccuracies in your Personal Data, to
          the extent such correction is appropriate in consideration of the
          nature of such data and our purposes of processing your Personal Data.
        </p>
        <p>Portability</p>
        <p>
          You have the right to request a copy of your Personal Data in a
          machine-readable format to the extent technically feasible.
        </p>
        <p>Deletion</p>
        <p>You have the right to delete your Personal Data.</p>
        <p>Opt-Out of Certain Processing Activities</p>
        <ul>
          <li>
            You have the right to opt-out of the processing of your Personal
            Data for targeted advertising purposes and disclosures of your
            Personal Data which constitute a &ldquo;sale.&rdquo; As discussed in
            the
            <a href="#california-personal-sharing">
              &ldquo;California Right&rdquo; section of this Privacy Policy
              (specifically, see &ldquo;Personal Data Sharing or Selling&rdquo;)
            </a>
            , we may process your Personal Data for targeted advertising
            purposes, and some of our data sharing relationships may constitute
            a &ldquo;sale.&rdquo; To opt-out of such processing activities,
            please see the
            <a href="#exercising-rights">
              &ldquo;Exercising Your Rights&rdquo; section
            </a>
            .
          </li>
          <li>
            You have the right to opt-out to the sale of your Personal Data. We
            do not currently sell your Personal Data as defined under the CPA.
          </li>
          <li>
            You have the right to opt-out from processing your Personal Data for
            the purposes of profiling in furtherance of decisions that produce
            legal or similarly significant effects to you, if applicable.
          </li>
        </ul>
        <p>Appealing a Denial</p>
        <p>
          If we refuse to take action on a request within a reasonable period of
          time after receiving your request in accordance with this section. In
          your appeal, you must (1) provide sufficient information to allow us
          to verify that you are the person about whom the original request
          pertains and to identify the original request and (2) provide a
          description of the basis of your appeal. Please note that your appeal
          will be subject to your rights and obligations afforded to you under
          the CPA. We will respond to your appeal within 45 days of receiving
          your request. If you have any questions about your appeal, you have
          the right to contact the Colorado Attorney General using the methods
          described here.
        </p>
        <p>You may appeal a decision by us using the following methods:</p>
        <ul>
          <li>
            Email us at:
            <a target="_blank" href="mailto:hello@heyheygorgeous.com">
              hello@heyheygorgeous.com
            </a>
            (title must include &ldquo;CPA Appeal&rdquo;)
          </li>
          <li>Call us at: (855) 962-3621</li>
        </ul>
        <br />
        <h3>
          <u>
            <a name="exercising-rights">Exercising Your Rights</a>
          </u>
        </h3>
        <p>
          To exercise the rights under the CCPA, VCDPA, or CPA as described
          above, you must send us a request that (1) provides sufficient
          information to allow us to verify that you are the person about whom
          we have collected Personal Data and (2) describes your request in
          sufficient detail to allow us to understand, evaluate, and respond to
          it. Each request that meets both of these criteria will be considered
          a &ldquo;Valid Request.&rdquo; We will only use Personal Data provided
          in a Valid Request to verify you and complete your request. You do not
          need an account to submit a Valid Request.
        </p>
        <p>
          We will respond to your Valid Request within the applicable time
          period required by law. We will not charge you a fee for making a
          Valid Request unless your Valid Request(s) is excessive, repetitive,
          or manifestly unfounded. If we determine that your Valid Request
          warrants a fee, we will notify you of the fee and explain that
          decision before completing your request.
        </p>
        <p>
          To the extent rights are afforded to you, you may submit a Valid
          Request using the following methods:
        </p>
        <ul>
          <li>
            You can submit requests to access (by portable copy or otherwise),
            delete, correct by the following methods:
          </li>
        </ul>
        <ul>
          <li>
            If you have an account with us, you can submit a request through the
            &ldquo;Data Privacy&rdquo; tab in your account settings.
          </li>
          <li>
            If you do not have an account with us, please submit the form found
            in the &ldquo;Privacy Settings&rdquo; at
            <a href="https://www.FMD.com/privacy-settings">
              https://www.FMD.com/privacy-settings
            </a>
          </li>
        </ul>
        <ul>
          <li>
            You can opt-out from the processing of sensitive information or
            opt-out from &ldquo;selling,&rdquo; &ldquo;sharing,&rdquo; or
            targeted advertising, as applicable, by the following methods:
          </li>
        </ul>
        <ul>
          <li>
            If you have an account with us, you can opt-out through the
            &ldquo;Data Privacy&rdquo; tab in your account settings.
          </li>
          <li>
            You can visit the &ldquo;Privacy Settings&rdquo; page at
            <a href="https://www.FMD.com/privacy-settings">
              https://www.FMD.com/privacy-settings
            </a>
            .
          </li>
        </ul>
        <br />
        <p>
          If you have questions or difficulty submitting a Valid Request, you
          can call us at (855) 962-3621 or email us at
          <a target="_blank" href="mailto:hello@heyheygorgeous.com">
            hello@heyheygorgeous.com
          </a>
          .
        </p>
        <br />
        <h3>
          <u>
            <a name="changes-to-policy">Changes to this Privacy Policy</a>
          </u>
        </h3>
        <p>
          We reserve the right to amend our Privacy Policy at our discretion and
          at any time. When we make changes to the Privacy Policy, we will
          notify you by email or through a notice on our website homepage. Use
          of the information we collect is subject to the Privacy Policy in
          effect at the time such information is collected.
        </p>
        <h3>
          <u>
            <a name="contact-information">Contact Information</a>
          </u>
        </h3>
        <p>
          If you have any questions or comments about this Privacy Policy, the
          ways in which we collect and use your Personal Data, your choices, and
          rights regarding such use, please do not hesitate to contact us at:
        </p>
        <ul>
          <li>
            E-mail:
            <a target="_blank" href="mailto:hello@heyheygorgeous.com">
              hello@heyheygorgeous.com
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Privacy;
