import React from 'react';
const defaultData = [0, 1, 2, 3, 4];
function Points({ item, handleSetPointValue, pointsData, pointClass }) {
  const data = pointsData || defaultData;

  const handleCallback = (d) => {
    const newItem = { ...item };
    newItem.Score = d;
    handleSetPointValue(newItem, item?.id, d);
  };

  return (
    <div className="points">
      {data.map((d, index) => (
        <div
          key={`points-${index}`}
          className={`point-item ${item?.Score === d ? 'active' : ''} ${
            pointClass && item?.Score === d && pointClass
          }`}
          onClick={() => handleCallback(d)}
        >
          {d}
        </div>
      ))}
    </div>
  );
}

export default Points;
