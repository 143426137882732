import { dateIsInWeek } from '../../helpers/helpers';
import React, { useEffect, useState } from 'react';
import { questionnaire } from '../symptoms/questionaireData';
import Navigation from '../../layout/Navigation/Navigation';
import '../elimination/elimination.scss';
import { Link, useNavigate } from 'react-router-dom';
import './calendar.scss';

function Calendar({ type, combined, currentUser, fbUser }) {
  const [filtered, setFiltered] = useState(null);

  const getScoreStyle = (score) => {
    if (+score < questionnaire.length * 4 * 0.25) {
      return 'success';
    }
    if (+score <= questionnaire.length * 4 * 0.5) {
      return 'warning';
    }
    return 'danger';
  };

  const getLifestyleScoreStyle = (score, count) => {
    const ratio = score / count;
    if (ratio > 0.75) {
      return 'success';
    }
    if (ratio <= 0.74 && ratio > 0.5) {
      return 'warning';
    }
    return 'danger';
  };

  useEffect(() => {
    if (!currentUser || !combined) return;
    const tId = combined.find((c) => dateIsInWeek(c))?.id;
    const filteredCombined = combined.filter((c) => c.id <= tId);
    setFiltered(filteredCombined);
    const element = document.getElementById(`calendar-${tId || 0}`);
    if (!element) return;
    if (type) return;
    setTimeout(() => {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 500);
  }, [currentUser, combined]);

  const navigate = useNavigate();
  return (
    <>
      {!type && <Navigation currentUser={currentUser} fbUser={fbUser} />}
      <div
        className={
          type
            ? 'elimination horizontal calendar'
            : `elimination page page-with-footer calendar`
        }
      >
        <h1>
          Weekly Tasks{/* Calendar */}
          {type && <Link to="/calendar">View All</Link>}
        </h1>

        {!type && (
          <>
            <div style={{ fontSize: '.85em' }}>Your bi-weekly tasks.</div>
            <br />
          </>
        )}

        <div className="grid">
          {filtered
            ?.sort((a, b) => b.id - a.id)
            ?.map((m) => (
              <div
                key={`calendar-${m.id}`}
                id={`calendar-${m.id}`}
                className={`grid-item grid-item-elimination ${
                  dateIsInWeek(m) ? 'active' : ''
                }`}
              >
                <h3>
                  {m.week === 0 ? (
                    <>Baseline (Week 1)</>
                  ) : (
                    <>
                      Week {m.week} & {m.week + 1}{' '}
                    </>
                  )}
                </h3>
                <small className="date faded">
                  {m.startDate &&
                    new Date(m?.startDate)?.toLocaleDateString('en-US', {
                      weekday: 'short',
                      month: 'short',
                      day: 'numeric',
                    })}{' '}
                  -{' '}
                  {m.endDate &&
                    new Date(m?.endDate)?.toLocaleDateString('en-US', {
                      weekday: 'short',
                      //year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                </small>
                {/* Todo: Enrique */}
                <div className="line-item">
                  {m.symptoms ? (
                    <h4 onClick={() => navigate(`/symptoms/${m.id}`)}>
                      <span className="title">
                        Symptom Score{' '}
                        <img
                          className="next"
                          src="/icons/chevron-down.svg"
                          alt="link"
                        />
                      </span>

                      <div style={{ marginTop: '2px' }}>
                        <span className={getScoreStyle(m.symptoms.total)}>
                          {m.symptoms.total}
                        </span>
                        <small className="faded">
                          {' '}
                          / {questionnaire.length * 4}
                        </small>
                      </div>
                    </h4>
                  ) : (
                    <Link to={`/symptoms/${m.id}/add`}>
                      <img src="/icons/add.svg" alt="Add" /> Symptoms
                    </Link>
                  )}
                </div>

                <div className="line-item">
                  {m.diet ? (
                    <>
                      <h4 onClick={() => navigate(`/diet/${m.id}`)}>
                        <span className="title">
                          Diet{' '}
                          <img
                            className="next"
                            src="/icons/chevron-down.svg"
                            alt="link"
                          />
                        </span>
                      </h4>
                      {m.diet?.data?.map((d, index) => (
                        <small className="faded" key={`contents-${index}`}>
                          {d.Contents}
                          {index < m.diet.data.length - 1 && ', '}
                        </small>
                      ))}
                    </>
                  ) : (
                    <Link to={`/diet/${m.id}/add`}>
                      <img src="/icons/add.svg" alt="Add" /> Diet
                    </Link>
                  )}
                </div>
                <div className="line-item">
                  {m.lifestyle ? (
                    <>
                      <h4 onClick={() => navigate(`/lifestyle/${m.id}`)}>
                        <span className="title">
                          Daily Activities{' '}
                          <img
                            className="next"
                            src="/icons/chevron-down.svg"
                            alt="link"
                          />
                        </span>
                      </h4>
                      <div style={{ marginTop: '2px' }}>
                        <span
                          className={getLifestyleScoreStyle(
                            m.lifestyle.total,
                            m.lifestyle.data.length * 6
                          )}
                        >
                          <b>{m.lifestyle.total}</b>
                        </span>
                        <small className="faded">
                          {' '}
                          / {m.lifestyle.data.length * 6}
                        </small>
                      </div>
                      {/* {m.lifestyle?.data?.map((d, index) => (
                        <small className="faded" key={`contents-${index}`}>
                          {d.Category}
                          {index < m.lifestyle.data.length - 1 && ', '}
                        </small>
                      ))} */}
                    </>
                  ) : (
                    <Link to={`/lifestyle/${m.id}/add`}>
                      <img src="/icons/add.svg" alt="Add" /> Daily Activities
                    </Link>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default Calendar;
