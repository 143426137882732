import { mockQuestionaire } from '../../data/mockQuestionaire';
import { questionnaire } from '../symptoms/questionaireData';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../../layout/Navigation/Navigation';
import './chart.scss';
import { getAllPoints } from './chartHelpers';
import Elimination from '../elimination/elimination';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function Chart({ currentUser, showTotal, type, fbUser, combined }) {
  const [chartsWithTotal, setChartsWithTotal] = useState(null);
  useEffect(() => {
    const d = currentUser?.symptoms;
    if (!d) return;
    const array = Object.values(d);
    /* const cData = array
      .map((m) => {
        const scored = m.data.filter((m) => m.Score > 0);
        return { ...m, scored };
      })
      .sort((a, b) => +b.id - +a.id); */
    setChartsWithTotal(getAllPoints(array, questionnaire));
  }, [currentUser]);

  return (
    <>
      <Navigation currentUser={currentUser} fbUser={fbUser} />
      <div className={type ? '' : 'chart-page-container'}>
        <div className={`chart  ${type ? 'horizontal' : 'page'}`}>
          <h1>
            Symptom Progress
            {type && <Link to="/progress">View All</Link>}
          </h1>
          <div className="charts-container">
            {chartsWithTotal?.map((c, index) => (
              <div className="card" key={`chart-${index}`}>
                <div className="single-chart">
                  <h3 style={{ color: 'black' }}>{c.id}</h3>
                  {/* <ResponsiveLine
                    data={[c]}
                    margin={{ top: 50, right: 10, bottom: 25, left: 30 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                      type: 'linear',
                      min: 'auto',
                      max: 'auto',
                      stacked: true,
                      reverse: false,
                    }}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      orient: 'bottom',
                      tickSize: 0,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: 'Week',
                      legendOffset: 25,
                      legendPosition: 'center',
                    }}
                    axisLeft={{
                      orient: 'left',
                      tickSize: 0,
                      tickPadding: 10,
                      tickRotation: 0,
                      //legend: 'Score',
                      legendOffset: -30,
                      legendPosition: 'center',
                    }}
                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    colors={['#ff3344']}
                    legends={[
                      {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemBackground: 'rgba(0, 0, 0, .03)',
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                  /> */}
                  <br />
                  <br />
                  <br />
                  <Line
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          grid: {
                            display: false,
                          },
                        },
                        y: {
                          grid: {
                            display: false,
                          },
                        },
                      },
                    }}
                    data={{
                      label: '# of Votes',
                      labels: c.chartDataWeeks,
                      datasets: [
                        {
                          fill: true,
                          data: c.chartDataPoints,
                          borderColor:
                            c.chartDataPoints[c.chartDataPoints.length - 1] <=
                            c.chartDataPoints[c.chartDataPoints.length - 2]
                              ? 'rgb(53, 162, 235)'
                              : 'rgb(242, 36, 36)',
                          backgroundColor:
                            c.chartDataPoints[c.chartDataPoints.length - 1] <=
                            c.chartDataPoints[c.chartDataPoints.length - 2]
                              ? 'rgba(53, 162, 235, 0.5)'
                              : 'rgb(255, 146, 146)',
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="chart-companion">
          {!type && (
            <>
              <p>
                The current week you're on does not affect your charts above.
              </p>

              <Elimination
                type="horizontal"
                currentUser={currentUser}
                fbUser={fbUser}
                combinedDates={combined}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Chart;
