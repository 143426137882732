export const questionnaire = [
  {
    Category: 'Head',
    Symptom: 'Headaches',
    Score: '',
  },
  {
    Category: 'Head',
    Symptom: 'Faintness',
    Score: '',
  },
  {
    Category: 'Head',
    Symptom: 'Dizziness',
    Score: '',
  },
  {
    Category: 'Head',
    Symptom: 'Insomnia',
    Score: '',
  },
  {
    Category: 'Eyes',
    Symptom: 'Watery or itchy eyes',
    Score: '',
  },
  {
    Category: 'Eyes',
    Symptom: 'Swollen, reddened or sticky eyeballs',
    Score: '',
  },
  {
    Category: 'Eyes',
    Symptom: 'Bags or dark circles under eyes',
    Score: '',
  },
  {
    Category: 'Eyes',
    Symptom:
      'Blurred or tunnel vision (does not include near or far-sightedness)',
    Score: '',
  },
  {
    Category: 'Ears',
    Symptom: 'Itchy ears',
    Score: '',
  },
  {
    Category: 'Ears',
    Symptom: 'Earaches, ear infections',
    Score: '',
  },
  {
    Category: 'Ears',
    Symptom: 'Drainage from ear',
    Score: '',
  },
  {
    Category: 'Ears',
    Symptom: 'Ringing in ears, hearing loss',
    Score: '',
  },
  {
    Category: 'Nose',
    Symptom: 'Stuffy nose',
    Score: '',
  },
  {
    Category: 'Nose',
    Symptom: 'Sinus problems',
    Score: '',
  },
  {
    Category: 'Nose',
    Symptom: 'Hay fever',
    Score: '',
  },
  {
    Category: 'Nose',
    Symptom: 'Sneezing attacks',
    Score: '',
  },
  {
    Category: 'Nose',
    Symptom: 'Excessive mucus formation',
    Score: '',
  },
  {
    Category: 'Mouth/Throat',
    Symptom: 'Chronic coughing',
    Score: '',
  },
  {
    Category: 'Mouth/Throat',
    Symptom: 'Gagging, frequent need to clear throat',
    Score: '',
  },
  {
    Category: 'Mouth/Throat',
    Symptom: 'Sore throat, hoarseness, loss of voice',
    Score: '',
  },
  {
    Category: 'Mouth/Throat',
    Symptom: 'Swollen or discolored tongue, gums, lips',
    Score: '',
  },
  {
    Category: 'Mouth/Throat',
    Symptom: 'Canker sores',
    Score: '',
  },
  {
    Category: 'Skin',
    Symptom: 'Acne',
    Score: '',
  },
  {
    Category: 'Skin',
    Symptom: 'Hives, rashes, dry skin',
    Score: '',
  },
  {
    Category: 'Skin',
    Symptom: 'Hair loss',
    Score: '',
  },
  {
    Category: 'Skin',
    Symptom: 'Flushing, hot flashes',
    Score: '',
  },
  {
    Category: 'Skin',
    Symptom: 'Excessive sweating',
    Score: '',
  },
  {
    Category: 'Heart',
    Symptom: 'Irregular or skipped heartbeat',
    Score: '',
  },
  {
    Category: 'Heart',
    Symptom: 'Rapid or pounding heartbeat',
    Score: '',
  },
  {
    Category: 'Heart',
    Symptom: 'Chest pain',
    Score: '',
  },
  {
    Category: 'Lungs',
    Symptom: 'Chest congestion',
    Score: '',
  },
  {
    Category: 'Lungs',
    Symptom: 'Asthma, bronchitis',
    Score: '',
  },
  {
    Category: 'Lungs',
    Symptom: 'Shortness of breath',
    Score: '',
  },
  {
    Category: 'Lungs',
    Symptom: 'Difficulty breathing',
    Score: '',
  },
  {
    Category: 'Digestive Tract',
    Symptom: 'Nausea, vomiting',
    Score: '',
  },
  {
    Category: 'Digestive Tract',
    Symptom: 'Diarrhea',
    Score: '',
  },
  {
    Category: 'Digestive Tract',
    Symptom: 'Constipation',
    Score: '',
  },
  {
    Category: 'Digestive Tract',
    Symptom: 'Bloated feeling',
    Score: '',
  },
  {
    Category: 'Digestive Tract',
    Symptom: 'Belching, passing gas',
    Score: '',
  },
  {
    Category: 'Digestive Tract',
    Symptom: 'Heartburn',
    Score: '',
  },
  {
    Category: 'Digestive Tract',
    Symptom: 'Intestinal/stomach pain',
    Score: '',
  },
  {
    Category: 'Joints/Muscle',
    Symptom: 'Pain or aches in joints',
    Score: '',
  },
  {
    Category: 'Joints/Muscle',
    Symptom: 'Arthritis',
    Score: '',
  },
  {
    Category: 'Joints/Muscle',
    Symptom: 'Stiffness or limitation of movement',
    Score: '',
  },
  {
    Category: 'Joints/Muscle',
    Symptom: 'Pain or aches in muscles',
    Score: '',
  },
  {
    Category: 'Joints/Muscle',
    Symptom: 'Feeling of weakness or tiredness',
    Score: '',
  },
  {
    Category: 'Weight',
    Symptom: 'Binge eating/drinking',
    Score: '',
  },
  {
    Category: 'Weight',
    Symptom: 'Craving certain foods',
    Score: '',
  },
  {
    Category: 'Weight',
    Symptom: 'Excessive weight',
    Score: '',
  },
  {
    Category: 'Weight',
    Symptom: 'Compulsive eating',
    Score: '',
  },
  {
    Category: 'Weight',
    Symptom: 'Water retention',
    Score: '',
  },
  {
    Category: 'Weight',
    Symptom: 'Underweight',
    Score: '',
  },
  {
    Category: 'Energy/Activity',
    Symptom: 'Fatigue/sluggishness',
    Score: '',
  },
  {
    Category: 'Energy/Activity',
    Symptom: 'Apathy, lethargy',
    Score: '',
  },
  {
    Category: 'Energy/Activity',
    Symptom: 'Hyperactivity',
    Score: '',
  },
  {
    Category: 'Energy/Activity',
    Symptom: 'Restlessness',
    Score: '',
  },
  {
    Category: 'Mind',
    Symptom: 'Poor memory',
    Score: '',
  },
  {
    Category: 'Mind',
    Symptom: 'Confusion, poor comprehension',
    Score: '',
  },
  {
    Category: 'Mind',
    Symptom: 'Poor concentration',
    Score: '',
  },
  {
    Category: 'Mind',
    Symptom: 'Poor physical coordination',
    Score: '',
  },
  {
    Category: 'Mind',
    Symptom: 'Difficulty in making decisions',
    Score: '',
  },
  {
    Category: 'Mind',
    Symptom: 'Stuttering or stammering',
    Score: '',
  },
  {
    Category: 'Mind',
    Symptom: 'Slurred speech',
    Score: '',
  },
  {
    Category: 'Mind',
    Symptom: 'Learning disabilities',
    Score: '',
  },
  {
    Category: 'Emotions',
    Symptom: 'Mood swings',
    Score: '',
  },
  {
    Category: 'Emotions',
    Symptom: 'Anxiety, fear, nervousness',
    Score: '',
  },
  {
    Category: 'Emotions',
    Symptom: 'Anger, irritability, aggressiveness',
    Score: '',
  },
  {
    Category: 'Emotions',
    Symptom: 'Depression',
    Score: '',
  },
  {
    Category: 'Other',
    Symptom: 'Frequent illness',
    Score: '',
  },
  {
    Category: 'Other',
    Symptom: 'Frequent or urgent urination',
    Score: '',
  },
  {
    Category: 'Other',
    Symptom: 'Genital itch or discharge',
    Score: '',
  },
];
