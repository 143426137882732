import React, { useEffect, useState } from 'react';
import { questionnaire } from '../symptoms/questionaireData';
import Navigation from '../../layout/Navigation/Navigation';
import './elimination.scss';
import { Link, useNavigate } from 'react-router-dom';
import Placeholder from '../../components/Placeholder/Placeholder';
import { dateIsInWeek } from '../../helpers/helpers';

function Elimination({ type, currentUser, fbUser, combinedDates }) {
  const [combined, setCombined] = useState(null);

  useEffect(() => {
    const d = currentUser?.diets;
    if (!d) return;
    const cData = Object.values(d)
      .map((m) => {
        const matchedData = Object.values(d).find((q) => +q.id === +m.id);
        return {
          ...m,
          symptomsData: matchedData.data,
          symptomsDataTotal: matchedData.total,
        };
      })
      .sort((a, b) => b.id - a.id);
    //add combinedDates to cData
    if (!combinedDates) return;
    const elim = cData.map((c) => {
      const data = combinedDates.find((cc) => cc.id === c.id);
      return { ...c, startDate: data.startDate, endDate: data.endDate };
    });
    setCombined(elim);
  }, [currentUser, combinedDates]);

  useEffect(() => {
    if (!currentUser || !combinedDates) return;
    const tId = combinedDates.find((c) => dateIsInWeek(c))?.id;
    const element = document.getElementById(`diet-${tId}`);
    if (!element) return;
    if (type) return;
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }, 500);
    }
  }, [currentUser, combinedDates]);

  const navigate = useNavigate();
  return (
    <>
      {!type && <Navigation currentUser={currentUser} fbUser={fbUser} />}
      <div
        className={
          type ? 'elimination horizontal' : `elimination page page-with-footer`
        }
      >
        {/* {!type && (
          <div
            className="floating-button"
            onClick={() => navigate('/diet/add')}
          >
            <img src="/icons/add.svg" alt="add" />
          </div>
        )} */}

        <h1>
          Elimination Diet
          {type && <Link to="/diet">View All</Link>}
        </h1>

        {!type && (
          <>
            <div style={{ fontSize: '.85em' }}>
              Test your food sensitivity. Start with as little variation as
              possible but make sure you are getting adequate nutrition during
              this exercise. Consult a dietitian or doctor prior to starting
              this exercise.{' '}
              <a href="/Elimination_Diet_Food_Plan_v9_1.pdf" target="_blank">
                Download PDF
              </a>
            </div>
            <br />
          </>
        )}

        <div className="grid">
          {/* {!combined && (
            <div
              className="grid-item add-new"
              onClick={() => navigate('/diet/add')}
            >
              <img src="/icons/add.svg" alt="add" style={{ height: '40px' }} />
              Start your Elimination Diet
            </div>
          )} */}
          {!combined && type && (
            <div
              className="grid-item empty"
              /* onClick={() => navigate('/calendar')} */
            >
              {/*  <img src="/icons/add.svg" alt="add" style={{ height: '40px' }} /> */}
              Nothing here yet...
            </div>
          )}
          {!combined && !type && (
            <Placeholder
              title="Use the calendar to add your elimination diet for the week you're in."
              image=""
              link="Go to Calendar"
              route="/calendar"
            />
          )}
          {combined?.map((m) => (
            <div
              key={`diet-${m.id}`}
              id={`diet-${m.id}`}
              className={`grid-item grid-item-elimination ${
                dateIsInWeek(m) ? 'active' : ''
              }`}
              onClick={() => navigate(`/diet/${m.id}`)}
            >
              <h3 className="">
                {m.week === 0 ? (
                  <>
                    Baseline <img src="/icons/chevron-down.svg" alt="link" />
                  </>
                ) : (
                  <>
                    Week {m.week} & {m.week + 1}
                    <img src="/icons/chevron-down.svg" alt="link" />
                  </>
                )}
              </h3>
              <small className="faded">
                {m.date &&
                  new Date(m?.date)?.toLocaleDateString('en-US', {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
              </small>
              <div className="elimination-line-item-container">
                {m.data?.map((d, index) => (
                  <div className="line-item" key={`line-${index}`}>
                    <div>{d.Contents}</div>
                    {/* <small className="faded">{d.Category}</small> */}
                  </div>
                ))}
              </div>
              {/* Todo: Enrique */}
              {m.symptomsDataTotal && (
                <small className="badge">
                  {m.symptomsDataTotal} / {questionnaire.length * 4} Symptom
                  Score
                </small>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Elimination;
