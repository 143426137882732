import React, { useState } from 'react';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import './login.scss';
import { useNavigate } from 'react-router-dom';

const provider = new GoogleAuthProvider();
const Login = ({ setIsLoggedIn, setCurrentUser, currentUser }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const auth = getAuth();
  const navigate = useNavigate();

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        navigate('/');
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  setTimeout(() => {
    setIsLoaded(true);
  }, 1500);

  const loginUser = () => {
    signInWithEmailAndPassword(
      auth,
      email,
      password,
      setIsLoggedIn,
      setCurrentUser
    )
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        setIsLoggedIn(true);
        setCurrentUser(user);
        navigate('/');
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setErrorMessage('Incorrect Username and Password');
      });
  };

  return (
    <div className="login button" onClick={() => signInWithGoogle()}>
      <img src="/icons/logo-google.svg" alt="Google" />
      Login with Google
    </div>
  );
};

export default Login;
