import React from 'react';
import { Link } from 'react-router-dom';
import Login from '../../components/login/login';
import Hero from './hero';
import './landing.scss';
import Steps from './steps';
import Alert from '../../components/Alert/Alert';

function Landing() {
  return (
    <div className="landing">
      <Alert
        alert={{
          message: 'Free for the first 500 users.',
          icon: '👋',
          type: 'warning',
        }}
      />
      <Hero />
      <div className="process">
        <br />
        <img
          src="/landing/FMTree.png"
          alt="Functional Medicine"
          style={{ width: '70%', minWidth: '200px' }}
        />
        <h2>Functional Medicine</h2>
        <h5>
          A personalized approach that focuses on{' '}
          <b>treating the root causes</b> of disease and promoting overall
          health. It considers genetic, environmental, and lifestyle factors and
          aims to address underlying imbalances. It emphasizes preventive care,
          lifestyle modifications, and patient involvement in their own health.
        </h5>
        <br />
        <img src="/landing/FMProcess.png" alt="Functional Medicine" />
        <br />
        <br />
      </div>
      <div className="inflammation">
        <h2>Reduce Your Inflammation</h2>
        <img src="/landing/InflammationScan.jpeg" alt="Inflammation" />
      </div>
      <div>
        <h2>With These Steps</h2>
        <Steps />
      </div>
      <div>
        <h2>Program Preview</h2>
        <div className="preview">
          <video
            playsInline
            autoPlay
            muted={true}
            loop
            preload="metadata"
            width="100%"
            height="auto"
            id="bgvid"
            src="/landing/screen2.mp4"
          />
        </div>
      </div>
      <div className="bottom">
        <div className="cta">
          <Login />
          <small>
            <span className="faded">By logging in you are agreeing to our</span>{' '}
            <Link to="/terms">Terms of Service</Link>.
          </small>
          <br />
          <br />
          <br />
          <br />
        </div>
        <div className="links">
          <span className="faded">© FMD 2023</span>
          <a href="mailto:hello@heyheygorgeous.com">Contact</a>
          <Link to="/terms">Terms of Service</Link>
        </div>
      </div>
    </div>
  );
}

export default Landing;
