import React, { useEffect, useState } from 'react';
import Button from '../../../components/Button/Button';
import Collapse from '../../../components/collapse/Collapse';
import { elimination } from './../eliminationData.js';
import _ from 'lodash';
import DatePicker from 'sassy-datepicker';
import { useNavigate, useParams } from 'react-router-dom';
import { weeks } from '../../../helpers/systemData';

import '../elimination.scss';
import { addNewDietToDiets, updateDiet } from '../../../helpers/api';
import Header from '../../../components/Header/Header';

const getCombinedData = (dataIds) => {
  const elim = _.cloneDeep(elimination);
  const elim2 = _.cloneDeep(elimination);
  const categories = [...new Set(elim?.map((e) => e.Category))];
  const categoriesWithData = categories?.map((c, index) => {
    let formedData;
    if (dataIds) {
      formedData = elim
        .filter((e) => e.Category === c)
        ?.map((ee) => {
          if (dataIds.includes(ee.id)) {
            ee.active = 'TRUE';
          } else {
            ee.active = 'FALSE';
          }
          return ee;
        });
    } else {
      formedData = elim2.filter((e) => e.Category === c);
    }
    return { id: index, name: c, data: formedData, collapsed: false };
  });
  return categoriesWithData;
};

const isLabel = (cc) => {
  if (cc.Type === 'Recommendation' || cc.Type === 'Eliminate') {
    return true;
  }
  return false;
};

function AddElimination({
  currentUser,
  setCurrentUser,
  isEdit,
  isAdd,
  isBaseline,
  submitFromOnboarding,
  combined,
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [eliminationData, setEliminationData] = useState(getCombinedData());
  const [allExpanded, setAllExpanded] = useState(false);
  const [instructionsCollapsed, setInstructionsCollapsed] = useState(false);
  const [date, setDate] = useState(new Date());
  const [toggledPicker, setToggledPicker] = useState(false);
  const [isDisabled, setIsDisabled] = useState(isEdit ? false : true);
  const [currentWeek, setCurrentWeek] = useState(weeks[0]);

  const handleCollapse = (id) => {
    const editedData = eliminationData?.map((e) => {
      if (e.id === id) {
        e.collapsed = !e.collapsed;
      }
      return e;
    });
    setEliminationData(editedData);
  };

  useEffect(() => {
    const d = currentUser?.diets;
    //getCurrentWeek and populate date
    const c = combined?.find((cc) => +cc.id === +id);
    setDate(new Date(c?.startDate));
    setCurrentWeek(weeks.find((w) => w.id === +id));

    if (!d) return;
    const existingData = Object.values(d);
    //const cWeek = getCurrentWeek(existingData, isEdit || isAdd, id, combined);
    //setCurrentWeek(cWeek);

    if (isEdit) {
      const existingDataIds = existingData
        .find((d) => d.id === +id)
        .data?.map((dd) => dd.id);
      setEliminationData(getCombinedData(existingDataIds));
    } else {
      setEliminationData(getCombinedData());
    }

    return () => {
      setEliminationData(getCombinedData());
    };
  }, [currentUser, id, isEdit, combined]);

  useEffect(() => {
    if (submitFromOnboarding) {
      handleSubmitForm();
    }
  }, [submitFromOnboarding]);

  const onDateChange = (dateValue) => {
    setDate(dateValue);
    setToggledPicker(false);
  };

  const handleSetActive = (cc) => {
    const newData = [...eliminationData].map((e) => {
      e.data.map((ee) => {
        if (ee.id === cc.id) {
          if (ee.active === 'TRUE') {
            ee.active = 'FALSE';
          } else {
            ee.active = 'TRUE';
          }
          return ee;
        }
        return ee;
      });
      return e;
    });
    setEliminationData(newData);
    const fbData = newData.map((n) => {
      const active = n.data.filter((nn) => nn.active === 'TRUE');
      if (active) {
        return active;
      }
    });
    if (fbData.flat().length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const handleExpandAll = () => {
    const expanded = allExpanded;
    if (expanded) {
      const newData = [...eliminationData].map((e) => ({
        ...e,
        collapsed: false,
      }));
      setEliminationData(newData);
    } else {
      const newData = [...eliminationData].map((e) => ({
        ...e,
        collapsed: true,
      }));
      setEliminationData(newData);
    }
    setAllExpanded(!allExpanded);
  };

  const handleSubmitForm = () => {
    //save to firebase
    const filteredData = eliminationData
      .map((n) => {
        const active = n.data.filter((nn) => nn.active === 'TRUE');
        if (active) {
          return active;
        }
      })
      .flat();

    const fbData = {
      id: currentWeek?.id || 1,
      week: currentWeek?.week || 1,
      data: filteredData,
      date: date.toDateString(),
    };

    if (isEdit) {
      updateDiet(fbData, currentUser, setCurrentUser);
    } else {
      addNewDietToDiets(fbData, currentUser, setCurrentUser);
    }
    if (isBaseline) return;
    navigate('/diet');
  };

  return (
    <div className="elimination page page-with-footer">
      {!isBaseline && (
        <Header
          title="Elimination Diet"
          isEdit={isEdit}
          backRoute={`/diet/${id}`}
        />
      )}

      <Collapse
        title="Instructions"
        collapsed={instructionsCollapsed}
        handleCollapse={() => setInstructionsCollapsed(!instructionsCollapsed)}
      >
        <div className="line-item" style={{ flexDirection: 'column' }}>
          {/* {currentWeek.id === 0 && (
            <div>
              <p>
                Kindly select the foods that you have consumed frequently during
                the last month in the list below.
              </p>
              <p>
                This will act as a standard reference point for future
                comparison.
              </p>
            </div>
          )} */}
          {/* {currentWeek.id > 0 && ( */}
          <div>
            <p>
              The aim of this exercise is to evaluate your food sensitivity. It
              is crucial to ensure that you receive proper nutrition during the
              process while starting with minimal variations.{' '}
              <b>
                If you are unsure about your nutrition, seek advice from a
                dietitian or doctor
              </b>
              .
            </p>

            <p>
              <b>
                Start by choosing up to three to five items from the list below.
              </b>{' '}
              If your diet permits, the most common option is to begin with
              white rice and one type of animal meat, and ghee or olive oil
              which ensures adequate nutrition.
            </p>

            <p>Every two weeks, introduce a new item from the list.</p>

            <p>
              If a food item causes problems, consider eliminating it for the
              time being and reintroducing it later. Only add one item every two
              weeks so you are able to pinpoint the negative reaction to that
              particular food.
            </p>

            <p>Tip: Avoid processed foods and stick to whole foods.</p>
          </div>
          {/* )} */}
        </div>
      </Collapse>
      <br />

      <div className="form-item">
        <label>
          {currentWeek.id === 0
            ? 'Date'
            : `Start Date for Week ${currentWeek.week}`}
        </label>
        <div
          className="form-control"
          onClick={() => setToggledPicker(!toggledPicker)}
        >
          {date.toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </div>
        {toggledPicker && <DatePicker onChange={onDateChange} />}
      </div>
      <div className="actions">
        {allExpanded ? (
          <Button type="link" callback={() => handleExpandAll()}>
            Expand All
          </Button>
        ) : (
          <Button type="link" callback={() => handleExpandAll()}>
            Collapse All
          </Button>
        )}
      </div>

      {eliminationData?.map((c) => (
        <Collapse
          key={c.name}
          id={c.id}
          title={c.name}
          collapsed={c.collapsed}
          handleCollapse={handleCollapse}
        >
          {c?.data?.map((cc, index) => (
            <div
              key={index}
              className={isLabel(cc) ? 'tip' : 'line-item link'}
              onClick={() => handleSetActive(cc)}
            >
              {isLabel(cc) && <div className="label">{cc.Type}</div>}
              {!isLabel(cc) && (
                <img
                  className={cc.active === 'TRUE' ? 'success' : ''}
                  src={
                    cc.active === 'TRUE'
                      ? '/icons/checkmark-circle.svg'
                      : '/icons/circle.svg'
                  }
                  alt="Checkbox"
                />
              )}
              <div>
                <span
                  style={{ textTransform: 'capitalize', marginRight: '10px' }}
                >
                  {cc.Contents}
                </span>
                <small className="faded">{cc.Tips && cc.Tips}</small>
              </div>
            </div>
          ))}
        </Collapse>
      ))}
      <br />
      <div className="footer">
        <Button
          callback={() => handleSubmitForm()}
          type={isDisabled ? 'disabled' : ''}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default AddElimination;
