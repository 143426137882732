import React from 'react';
import { Link } from 'react-router-dom';
import './Placeholder.scss';

function Placeholder({ title, image, link, route }) {
  return (
    <div className="placeholder">
      {image && <img src={image} alt={title} />}
      {title && <h1>{title}</h1>}
      {link && (
        <Link to={route} className="button">
          {link}
        </Link>
      )}
    </div>
  );
}

export default Placeholder;
