import React, { useEffect, useState } from 'react';
import Button from '../../../components/Button/Button';
import Collapse from '../../../components/collapse/Collapse';
import { elimination } from './../../elimination/eliminationData.js';
import _ from 'lodash';
import DatePicker from 'sassy-datepicker';
import { useNavigate, useParams } from 'react-router-dom';
import { weeks } from '../../../helpers/systemData';
import { sexOptions } from '../../../helpers/helpers';
import './../../elimination/elimination.scss';
import { addNewLabsToLabs, updateLab } from '../../../helpers/api';
import Header from '../../../components/Header/Header';
import { optimalLabs } from '../optimalLabs';
import { getNotice } from '../labsHelpers';
import { mockLabs } from './addLabsMock';
import './../labs.scss';

const getCombinedData = (date) => {
  optimalLabs.date = date;
  optimalLabs.data.forEach((d) => {
    d.collapsed = false;
  });
  return optimalLabs;
};

const isLabel = (cc) => {
  if (cc.Type === 'Recommendation' || cc.Type === 'Eliminate') {
    return true;
  }
  return false;
};

function AddLabs({
  currentUser,
  setCurrentUser,
  isEdit,
  isBaseline,
  combined,
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [labData, setLabData] = useState(null);
  const [allExpanded, setAllExpanded] = useState(false);
  const [instructionsCollapsed, setInstructionsCollapsed] = useState(false);
  const [date, setDate] = useState(new Date());
  const [toggledPicker, setToggledPicker] = useState(false);
  const [isDisabled, setIsDisabled] = useState(isEdit ? false : true);
  const [currentWeek, setCurrentWeek] = useState(weeks[0]);
  const [selectedSex, setSelectedSex] = useState('Male');

  const handleCollapse = (id) => {
    const editedData = _.cloneDeep(labData);
    editedData?.data?.forEach((e) => {
      if (e.id === id) {
        e.collapsed = !e.collapsed;
      }
    });
    setLabData(editedData);
  };

  const handleInput = (id, text) => {
    const editedData = _.cloneDeep(labData);
    editedData?.data?.forEach((e) => {
      if (e.id === id) {
        e.userValue = text;
      }
    });
    setLabData(editedData);
    const notAnsweredAll = editedData?.data?.find(
      (d) => d.userValue.length < 0
    );
  };

  useEffect(() => {
    //const cWeek = getCurrentWeek(existingData, isEdit || isAdd, id, combined);
    //setCurrentWeek(cWeek);

    if (isEdit) {
      const d = currentUser?.labs;
      if (!d) return;
      const existingData = Object.values(d);

      const currentLab = existingData.find((e) => e.fbId === id);
      setLabData(currentLab);
    } else {
      setLabData(getCombinedData(date));
    }

    return () => {
      setLabData(getCombinedData(date));
    };
  }, [currentUser, id, isEdit, combined]);

  const onDateChange = (dateValue) => {
    setDate(dateValue);
    setToggledPicker(false);
  };

  const handleExpandAll = () => {
    const expanded = allExpanded;
    if (expanded) {
      const newData = [...labData].map((e) => ({
        ...e,
        collapsed: false,
      }));
      labData(newData);
    } else {
      const newData = [...labData].map((e) => ({
        ...e,
        collapsed: true,
      }));
      labData(newData);
    }
    setAllExpanded(!allExpanded);
  };

  const handleSubmitForm = () => {
    //save to firebase
    const labDataWithDate = {
      ...labData,
      date: date.toDateString(),
      sex: selectedSex,
    };

    if (isEdit) {
      updateLab(labDataWithDate, currentUser, setCurrentUser);
    } else {
      addNewLabsToLabs(labDataWithDate, currentUser, setCurrentUser);
    }
    if (isBaseline) return;
    navigate('/labs');
  };

  return (
    <div className="elimination page page-with-footer add-labs">
      {!isBaseline && (
        <Header title="Labs" isEdit={isEdit} backRoute={`/labs`} />
      )}
      <Collapse
        title="Instructions"
        collapsed={instructionsCollapsed}
        handleCollapse={() => setInstructionsCollapsed(!instructionsCollapsed)}
      >
        <div className="line-item" style={{ flexDirection: 'column' }}>
          {/* {currentWeek.id === 0 && (
            <div>
              <p>
                Kindly select the foods that you have consumed frequently during
                the last month in the list below.
              </p>
              <p>
                This will act as a standard reference point for future
                comparison.
              </p>
            </div>
          )} */}
          {/* {currentWeek.id > 0 && ( */}
          <div>
            <p>
              Keep a record of your labs and compare them to the optimal
              Functional Medicine ranges. Please note that this tool is provided
              for informational purposes only, and if you have any questions,
              you should consult a doctor.
            </p>

            <p>
              Note: Make sure you are entering values in the same metric as
              labeled.
            </p>
          </div>
          {/* )} */}
        </div>
      </Collapse>
      <br />

      <div className="form-item" style={{ marginBottom: '15px' }}>
        <label>Date of Bloodwork/Labs</label>
        <div
          className="form-control"
          onClick={() => setToggledPicker(!toggledPicker)}
        >
          {date.toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </div>
        {toggledPicker && <DatePicker onChange={onDateChange} />}
      </div>

      <div className="form-item">
        <label>Sex</label>
        <div className="line-item">
          {sexOptions.map((s) => (
            <div className="option" onClick={() => setSelectedSex(s)}>
              <img
                className={selectedSex === s ? 'success' : ''}
                src={
                  selectedSex === s
                    ? '/icons/checkmark-circle.svg'
                    : '/icons/circle.svg'
                }
                alt="Checkbox"
              />
              {s}
            </div>
          ))}
        </div>
      </div>

      <div className="actions">
        {allExpanded ? (
          <Button type="link" callback={() => handleExpandAll()}>
            Expand All
          </Button>
        ) : (
          <Button type="link" callback={() => handleExpandAll()}>
            Collapse All
          </Button>
        )}
      </div>
      {labData?.data?.map((c) => (
        <Collapse
          title={
            <>
              {c.analyte}
              {c.userValue && getNotice(c, selectedSex)}
            </>
          }
          collapsed={c.collapsed}
          handleCollapse={() => handleCollapse(c.id)}
        >
          <div id={c.id} title={c.analyte} className="line-item lab-item">
            <div className="input-group">
              <input
                type="number"
                value={c.userValue}
                onChange={(e) => handleInput(c.id, e.target.value)}
                placeholder="Enter value"
              />
              {c.units}
            </div>
            <small>
              Optimal Range: {c.optimalRange}, Ref Range: {c.refRange}
            </small>
          </div>
        </Collapse>
      ))}
      <br />
      <div className="footer">
        <Button callback={() => handleSubmitForm()}>Save</Button>
      </div>
    </div>
  );
}

export default AddLabs;
