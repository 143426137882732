import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Tabs from '../../components/PageTabs/PageTabs';
import { weeks } from '../../helpers/systemData';
import '../../components/card/card.scss';
import { goBack } from '../../helpers/helpers';

function EliminationDetail({ currentUser }) {
  const [dietData, setDietData] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const d = currentUser?.diets;
    if (!d) return;
    const found = Object.values(d).find((m) => +m.id === +id);
    setDietData(found);
  }, [id, currentUser]);

  const tabData = [
    {
      id: 0,
      label: 'Diet',
      path: `/diet/${id}`,
    },
    {
      id: 1,
      label: 'Symptoms',
      path: `/symptoms/${id}`,
    },
    {
      id: 2,
      label: 'Daily',
      path: `/lifestyle/${id}`,
    },
  ];

  return (
    <div className="elimination-detail page">
      <h1 className="link">
        <span
          /* onClick={() => navigate('/diet')} */ onClick={() =>
            goBack(navigate)
          }
        >
          <img src="/icons/chevron-down.svg" alt="back" />
          {dietData?.week === 0 ? (
            'Baseline'
          ) : (
            <>
              Week {dietData?.week || weeks[id - 1].week} &{' '}
              {dietData?.week + 1 || weeks[id - 1].week + 1}
            </>
          )}
        </span>
        <small className="faded">
          {dietData?.date ? (
            <>
              Started:{' '}
              {new Date(dietData?.date)?.toLocaleDateString('en-US', {
                weekday: 'short',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </>
          ) : (
            'Not Recorded'
          )}
        </small>
      </h1>
      <Tabs tabs={tabData} selectedTabId={0} />

      {!dietData && (
        <Link to={`/diet/${id}/add`}>
          <div className="card add-missing">
            <img src="/icons/add.svg" alt="add" style={{ height: '40px' }} />
            Record your Elimination Diet for Week {weeks[id - 1].week}
          </div>
        </Link>
      )}
      {dietData && (
        <div className="card card-vertical">
          <h2 className="link">
            {dietData?.id === 0
              ? 'Baseline (Week 0)'
              : `Week ${dietData?.week || weeks[id - 1].week} & ${
                  dietData?.week + 1 || weeks[id - 1].week + 1
                }`}
            <Link to={`/diet/${id}/edit`}>
              <small>Edit</small>
            </Link>
          </h2>
          <div className="item">
            {dietData?.data?.map((d, idx) => (
              <div className="line-item" key={idx}>
                <b>
                  {d.Contents} <small>{d.Category}</small>
                </b>{' '}
                <small className="faded">{d['Serving Size']} per serving</small>
                <div className="meta">
                  {d.Gluten && <div>Gluten</div>}
                  {d.Glyphosates && <div>Glyphosates</div>}
                  {d['High Histamine'] && <div>High Histamine</div>}
                  {d.Lectin && <div>Lectin</div>}
                  {d.Nightshades && <div>Nightshade</div>}
                  {d.Oxalates && <div>Oxalate</div>}
                  {d['Phytic Acid'] && <div>Phytic Acid</div>}
                  {d.Phytoalexins && <div>Phytoalexin</div>}
                  {d.Saponin && <div>Saponin</div>}
                  {d.Tannin && <div>Tannin</div>}
                </div>
                {d.notes && <div className="notes">{d.notes}</div>}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default EliminationDetail;
