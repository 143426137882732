import React from 'react';
import './labsContent.scss';

function LabsContent() {
  return (
    <div className="labsContent">
      <div id="page-container">
        <div id="pf1" className="pf w0 h0" data-page-no="1">
          <div className="pc pc1 w0 h0">
            <div className="t m0 x1 h2 y1 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y2 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y3 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y4 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y5 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y7 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y8 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x2 h3 y9 ff2 fs1 fc0 sc0 ls0 ws0">
              Functional Medicine U<span className="_ _0"></span>niversity’s{' '}
              <span className="_ _0"></span>
              <span className="ff3"> </span>
            </div>
            <div className="t m0 x3 h3 ya ff3 fs1 fc0 sc0 ls0 ws0">
              Functional Diagnostic <span className="_ _0"></span>Medicine{' '}
              <span className="_ _0"></span>{' '}
            </div>
            <div className="t m0 x4 h3 yb ff3 fs1 fc0 sc0 ls0 ws0">
              Training Program<span className="_ _0"></span>{' '}
            </div>
            <div className="t m0 x1 h3 yc ff3 fs1 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x5 h3 yd ff3 fs1 fc0 sc0 ls0 ws0">
              Module 1 * <span className="_ _0"></span>
              <span className="ls1">Lesson </span>7{' '}
            </div>
            <div className="t m0 x1 h2 ye ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 yf ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y10 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y11 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y12 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x6 h4 y13 ff4 fs2 fc0 sc0 ls0 ws0">
              Blood Chemistry <span className="_ _1"></span>&amp; CBC A
              <span className="_ _1"></span>nalysis
              <span className="ff1 fs0"> </span>
            </div>
            <div className="t m0 x1 h2 y14 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y15 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y16 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x7 h5 y17 ff5 fs3 fc0 sc0 ls0 ws0">
              By <span className="_ _1"></span>W<span className="_ _0"></span>
              ayne L. Sodano, D.C., D.A.B.C.I., &amp; Ron Grisanti, D.C.,
              D.A.B.C.O., M.S.{' '}
            </div>
            <div className="t m0 x8 h5 y18 ff5 fs3 fc1 sc0 ls0 ws0">
              http://www.FunctionalMedicineUniversity
              <span className="_ _1"></span>
              .com
              <span className="_ _0"></span>
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y19 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y1a ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y1b ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y1c ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y1d ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 y1e ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x9 h6 y1f ff3 fs4 fc0 sc0 ls0 ws0">
              Limits of Liabi<span className="_ _1"></span>lity
              <span className="_ _1"></span> &amp; Disclaimer of{' '}
              <span className="_ _1"></span>Warranty{' '}
            </div>
            <div className="t m0 x1 h2 y20 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h7 y21 ff5 fs4 fc0 sc0 ls0 ws0">
              We have designed<span className="_ _1"></span> this book to
              <span className="_ _1"></span> provide infor
              <span className="_ _1"></span>
              mation in regard<span className="_ _1"></span> to the subject
              matter
              <span className="_ _1"></span> covered. It i
              <span className="_ _1"></span>s made av
              <span className="_ _1"></span>ailable with the understan
              <span className="_ _1"></span>ding that the au
              <span className="_ _1"></span>
              thors{' '}
            </div>
            <div className="t m0 xa h7 y22 ff5 fs4 fc0 sc0 ls0 ws0">
              are not liable for <span className="_ _1"></span>the miscon
              <span className="_ _1"></span>ceptions or mi
              <span className="_ _1"></span>
              suse of infor<span className="_ _1"></span>mation provide
              <span className="_ _1"></span>d. The purpo
              <span className="_ _1"></span>se of this book is
              <span className="_ _1"></span> to edu
              <span className="_ _1"></span>cate. It is not meant to be a
              comprehen
              <span className="_ _1"></span>sive{' '}
            </div>
            <div className="t m0 xa h7 y23 ff5 fs4 fc0 sc0 ls0 ws0">
              source for the topi<span className="_ _1"></span>c covered, and{' '}
              <span className="_ _1"></span>is not in
              <span className="_ _1"></span>
              tended as a sub<span className="_ _1"></span>stitute for{' '}
              <span className="_ _1"></span>
              medical diagno<span className="_ _1"></span>sis or treatmen
              <span className="_ _1"></span>t, or intende
              <span className="_ _1"></span>d as a substitu
              <span className="_ _1"></span>te for medic
              <span className="_ _1"></span>a<span className="_ _0"></span>l
              counseling.{' '}
            </div>
            <div className="t m0 xa h7 y24 ff5 fs4 fc0 sc0 ls0 ws0">
              Information containe<span className="_ _1"></span>d in this
              <span className="_ _1"></span> book should not
              <span className="_ _1"></span> be construed
              <span className="_ _1"></span> as a claim or rep
              <span className="_ _1"></span>resentation{' '}
              <span className="_ _1"></span>
              that any treat<span className="_ _1"></span>ment, proce
              <span className="_ _1"></span>ss<span className="_ _0"></span> or
              interpretation <span className="_ _1"></span>mentione
              <span className="_ _1"></span>d{' '}
            </div>
            <div className="t m0 xa h7 y25 ff6 fs4 fc0 sc0 ls0 ws0">
              constitutes a cur<span className="_ _1"></span>e, palliative,
              <span className="_ _1"></span> or ameliorative
              <span className="_ _1"></span>. The infor
              <span className="_ _1"></span>
              mation cove
              <span className="_ _1"></span>red is intended{' '}
              <span className="_ _1"></span>
              to supple<span className="_ _1"></span>ment the pr
              <span className="_ _1"></span>
              actitioner’s{' '}
              <span className="ff5">
                k<span className="_ _0"></span>now<span className="_ _1"></span>
                ledge of their pat<span className="_ _1"></span>ient. It should
                be{' '}
              </span>
            </div>
            <div className="t m0 xa h7 y26 ff5 fs4 fc0 sc0 ls0 ws0">
              considered as adjun<span className="_ _1"></span>ctive and
              <span className="_ _1"></span> support to othe
              <span className="_ _1"></span>r diagnosti
              <span className="_ _1"></span>c medical procedures.{' '}
            </div>
            <div className="t m0 xa h7 y27 ff5 fs4 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h7 y28 ff5 fs4 fc0 sc0 ls0 ws0">
              This material <span className="_ _1"></span>contains elemen
              <span className="_ _1"></span>ts protected
              <span className="_ _1"></span> under Inter
              <span className="_ _1"></span>national and Fe
              <span className="_ _1"></span>dera<span className="_ _1"></span>l
              Copyright law<span className="_ _1"></span>s and treaties.{' '}
              <span className="_ _1"></span>Any unauthoriz
              <span className="_ _1"></span>
              ed
              <span className="_ _0"></span> reprint or use o
              <span className="_ _1"></span>f this mater
              <span className="_ _1"></span>
              ial is{' '}
            </div>
            <div className="t m0 xa h7 y29 ff5 fs4 fc0 sc0 ls0 ws0">
              prohibited.{' '}
            </div>
            <div className="t m0 xa h2 y2a ff1 fs0 fc0 sc0 ls0 ws0">
              {' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
            </div>
            <div className="t m0 x2 h7 y2b ff5 fs4 fc0 sc0 ls0 ws0">
              Functional Medicine<span className="_ _1"></span> University; Fu
              <span className="_ _1"></span>nctional Diagno
              <span className="_ _1"></span>
              stic Medicine<span className="_ _1"></span> Training Progra
              <span className="_ _1"></span>m/
              <span className="ff6">
                Insider’s Guide<span className="_ _1"></span>
                <span className="ff5"> </span>
              </span>
            </div>
            <div className="t m0 x4 h7 y2c ff5 fs4 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson <span className="ls2">7:</span> Blood Che
              <span className="_ _1"></span>mistry &amp; CBC Analy
              <span className="_ _1"></span>sis{' '}
            </div>
            <div className="t m0 xb h7 y2d ff5 fs4 fc0 sc0 ls0 ws0">
              Copyrigh<span className="_ _1"></span>t © 2010 Functional
              <span className="_ _1"></span> Medicine Univer
              <span className="_ _1"></span>
              sity, All Right<span className="_ _1"></span>s Reserve
              <span className="_ _1"></span>d{' '}
            </div>
            <div className="t m0 xa h8 y2e ff3 fs0 fc0 sc0 ls0 ws0"> </div>
            <a
              className="l"
              href="http://www.functionalmedicineuniversity.com/"
            >
              <div
                className="d m1"
                style={{
                  borderStyle: 'none',
                  position: 'absolute',
                  left: '274.560000px',
                  bottom: '468.240000px',
                  width: '245.920000px',
                  height: '15.870000px',
                  backgroundColor: 'rgba(255,255,255,0.000001)',
                }}
              ></div>
            </a>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf2" className="pf w0 h0" data-page-no="2">
          <div className="pc pc2 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x11 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">1 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h8 y31 ff3 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa ha y32 ff8 fs0 fc0 sc0 ls0 ws0">
              Contents{' '}
            </div>
            <div className="t m0 xa h2 y33 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y34 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y35 ff1 fs0 fc0 sc0 ls0 ws0">
              Why order blood t<span className="_ _1"></span>esting?{' '}
              <span className="_ _3"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span> 2{' '}
            </div>
            <div className="t m0 xa h2 y36 ff1 fs0 fc0 sc0 ls0 ws0">
              Reference Rang<span className="_ _1"></span>es{' '}
              <span className="_ _4"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span> 2{' '}
            </div>
            <div className="t m0 xa h2 y37 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span> I<span className="_ _1"></span>
              nitial Blood Test Panel <span className="_ _5"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> 3 <span className="_ _6"> </span>{' '}
            </div>
            <div className="t m0 xa h2 y38 ff1 fs0 fc0 sc0 ls0 ws0">
              Complete Blood C<span className="_ _1"></span>ount with Differ
              <span className="_ _1"></span>ential{' '}
              <span className="_ _7"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> 4{' '}
            </div>
            <div className="t m0 xa h2 y39 ff1 fs0 fc0 sc0 ls0 ws0">
              Comprehensive M<span className="_ _1"></span>etabolic Panel{' '}
              <span className="_ _8"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> 11{' '}
            </div>
            <div className="t m0 xa h2 y3a ff1 fs0 fc0 sc0 ls0 ws0">
              Additional Metabol<span className="_ _1"></span>ic Tests{' '}
              <span className="_ _9"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span> 21{' '}
            </div>
            <div className="t m0 xa h2 y3b ff1 fs0 fc0 sc0 ls0 ws0">
              Lipid Panel <span className="_ _6"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _a"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span> 24{' '}
            </div>
            <div className="t m0 xa h2 y3c ff1 fs0 fc0 sc0 ls0 ws0">
              Iron Panel <span className="_ _b"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span> 26{' '}
            </div>
            <div className="t m0 xa h2 y3d ff1 fs0 fc0 sc0 ls0 ws0">
              Thyroid Panel <span className="_ _c"></span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span> 28{' '}
              <span className="_ _d"> </span>{' '}
            </div>
            <div className="t m0 xa h2 y3e ff1 fs0 fc0 sc0 ls0 ws0">
              Additional Tests<span className="_ _1"></span>/Analytes{' '}
              <span className="_ _e"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span> 29{' '}
            </div>
            <div className="t m0 xa h2 y3f ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s Blood Test Re
              <span className="_ _1"></span>
              sult Track<span className="_ _1"></span>ing Form
              <span className="ff1">
                {' '}
                <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
                <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
                <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
                <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
                <span className="_ _2"> </span> 32{' '}
              </span>
            </div>
            <div className="t m0 xa h2 y40 ff1 fs0 fc0 sc0 ls0 ws0">
              References <span className="_ _7"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>{' '}
              <span className="_ _2"> </span> <span className="_ _2"> </span>36{' '}
            </div>
            <div className="t m0 xa h2 y41 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y42 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y43 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y44 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y45 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y46 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y47 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y48 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y49 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y4a ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y4b ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y4c ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y4d ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y4e ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y4f ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y50 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y51 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y52 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y53 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y54 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y55 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y56 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf3" className="pf w0 h0" data-page-no="3">
          <div className="pc pc3 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x11 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">2 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa ha y57 ff8 fs0 fc0 sc0 ls0 ws0">
              Blood tests are used <span className="_ _1"></span>to assess nu
              <span className="_ _1"></span>merous disord
              <span className="_ _1"></span>
              ers and body proc<span className="_ _1"></span>esses. They a
              <span className="_ _1"></span>re performed{' '}
              <span className="_ _1"></span>
              for the fol<span className="_ _1"></span>lowing{' '}
            </div>
            <div className="t m0 xa ha y32 ff8 fs0 fc0 sc0 ls0 ws0">
              reasons:{' '}
            </div>
            <div className="t m0 xa h2 y33 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x12 h2 y58 ff6 fs0 fc0 sc0 ls0 ws0">
              •
              <span className="ff5">
                {' '}
                <span className="_ _f"> </span>
                <span className="ff1">
                  Assessment of nu<span className="_ _1"></span>tritional st
                  <span className="_ _1"></span>atus{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y59 ff6 fs0 fc0 sc0 ls0 ws0">
              •
              <span className="ff5">
                {' '}
                <span className="_ _f"> </span>
                <span className="ff1">
                  Screening for di<span className="_ _1"></span>sease (PSA){' '}
                </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y5a ff6 fs0 fc0 sc0 ls0 ws0">
              •
              <span className="ff5">
                {' '}
                <span className="_ _f"> </span>
                <span className="ff1">
                  To establish a d<span className="_ _1"></span>iagnosis{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y5b ff6 fs0 fc0 sc0 ls0 ws0">
              •
              <span className="ff5">
                {' '}
                <span className="_ _f"> </span>
                <span className="ff1">
                  To monitor the<span className="_ _1"></span>rapy (blood t
                  <span className="_ _1"></span>hinners, diabet
                  <span className="_ _1"></span>e<span className="ls3">s)</span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y5c ff6 fs0 fc0 sc0 ls0 ws0">
              •
              <span className="ff5">
                {' '}
                <span className="_ _f"> </span>
                <span className="ff1">
                  To screen for tox<span className="_ _1"></span>ins/drug
                  <span className="_ _1"></span>s{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y5d ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa ha y5e ff8 fs0 fc0 sc0 ls0 ws0">
              Reference Rang<span className="_ _1"></span>es{' '}
            </div>
            <div className="t m0 xa h2 y5f ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y60 ff1 fs0 fc0 sc0 ls0 ws0">
              Dr. Harry Eideni<span className="_ _1"></span>er, Jr., whose work
              is wide
              <span className="_ _1"></span>ly recogniz
              <span className="_ _1"></span>ed and well respe
              <span className="_ _1"></span>cted in the area of ba
              <span className="_ _1"></span>lancing blood c
              <span className="_ _1"></span>
              hem
              <span className="_ _1"></span>istry,{' '}
            </div>
            <div className="t m0 xa h2 y61 ff7 fs0 fc0 sc0 ls0 ws0">
              stated to me “ B<span className="_ _1"></span>lood chemistry, i
              <span className="_ _1"></span>rrespectiv
              <span className="_ _1"></span>e o
              <span className="ff1">
                f range used (optim<span className="_ _1"></span>al or
                reference), i<span className="_ _1"></span>s simply a too
                <span className="_ _1"></span>l to be used with other{' '}
              </span>
            </div>
            <div className="t m0 xa h2 y62 ff7 fs0 fc0 sc0 ls0 ws0">
              diagnostic criteri<span className="_ _1"></span>a”.{' '}
              <span className="ff1"> </span>
            </div>
            <div className="t m0 xa h2 y63 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y64 ff1 fs0 fc0 sc0 ls0 ws0">
              Dr. Eidenier was<span className="_ _1"></span> generous{' '}
              <span className="_ _1"></span>enough to gran
              <span className="_ _1"></span>t permission to refe
              <span className="_ _1"></span>rence his wo
              <span className="_ _1"></span>rk throug
              <span className="_ _1"></span>hout this{' '}
              <span className="_ _0"></span>lesson. His con
              <span className="_ _1"></span>
              tribution,{' '}
            </div>
            <div className="t m0 xa h2 y65 ff1 fs0 fc0 sc0 ls0 ws0">
              along with our res<span className="_ _1"></span>earch will prov
              <span className="_ _1"></span>ide you with valuab
              <span className="_ _1"></span>le insight to{' '}
              <span className="_ _1"></span>
              balancing blood <span className="_ _1"></span>chemistry and u
              <span className="_ _1"></span>nderstandin
              <span className="_ _1"></span>g what the{' '}
            </div>
            <div className="t m0 xa h2 y66 ff1 fs0 fc0 sc0 ls0 ws0">
              numbers really<span className="_ _1"></span> mean.{' '}
            </div>
            <div className="t m0 xa h2 y67 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y68 ff7 fs0 fc0 sc0 ls0 ws0">
              Conventional <span className="_ _1"></span>reference rang
              <span className="_ _1"></span>es generally
              <span className="_ _1"></span> cover 95% of „hea
              <span className="_ _1"></span>lthy‟ population
              <span className="ff1">
                , and does not ac<span className="_ _1"></span>count for a rang
                <span className="_ _1"></span>e of sig
                <span className="_ _1"></span>ns and{' '}
              </span>
            </div>
            <div className="t m0 xa h2 y69 ff1 fs0 fc0 sc0 ls0 ws0">
              symptom<span className="_ _1"></span>s related to a subcl
              <span className="_ _1"></span>inical or physiologic
              <span className="_ _1"></span>al problem. These r
              <span className="_ _1"></span>anges are deriv
              <span className="_ _1"></span>
              ed by assay<span className="_ _1"></span>ing specim
              <span className="_ _1"></span>
              ens from{' '}
            </div>
            <div className="t m0 xa h2 y6a ff7 fs0 fc0 sc0 ls0 ws0">
              individuals who m<span className="_ _1"></span>eet criteria for{' '}
              <span className="_ _1"></span>„g<span className="ff1">o</span>od‟
              health (e.g
              <span className="_ _1"></span>., „have no k
              <span className="_ _1"></span>
              nown health proble<span className="_ _1"></span>m
              <span className="_ _1"></span>s, are ambulatory, not on{' '}
              <span className="_ _1"></span>any{' '}
            </div>
            <div className="t m0 xa h2 y6b ff1 fs0 fc0 sc0 ls0 ws0">
              medications and hav<span className="_ _1"></span>e a norm
              <span className="_ _1"></span>al BMI){' '}
            </div>
            <div className="t m0 xa h2 y6c ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y6d ff1 fs0 fc0 sc0 ls0 ws0">
              There is also a s<span className="_ _1"></span>ignificant am
              <span className="_ _1"></span>ount of biological variabi
              <span className="_ _1"></span>lity with reference{' '}
              <span className="_ _1"></span>ranges. An exam
              <span className="_ _1"></span>
              ple of this is seru<span className="_ _1"></span>m iron, which{' '}
            </div>
            <div className="t m0 xa h2 y6e ff1 fs0 fc0 sc0 ls0 ws0">
              is higher in the m<span className="_ _1"></span>orning an
              <span className="_ _1"></span>d can drop by as m
              <span className="_ _1"></span>
              uch as 5<span className="_ _1"></span>0% if the spec
              <span className="_ _1"></span>imen is obtai
              <span className="_ _1"></span>
              ned at 2 p.m<span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 xa h2 y6f ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y70 ff1 fs0 fc0 sc0 ls0 ws0">
              It is recomm<span className="_ _1"></span>ended that all follow u
              <span className="_ _1"></span>p blood tes
              <span className="_ _1"></span>
              ting be tak<span className="_ _1"></span>en at the same time of
              day
              <span className="_ _1"></span> as the original t
              <span className="_ _1"></span>
              est.{' '}
            </div>
            <div className="t m0 xa h2 y71 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y72 ff1 fs0 fc0 sc0 ls0 ws0">
              Other variables in<span className="_ _1"></span>clude:{' '}
            </div>
            <div className="t m0 xa h2 y73 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x12 h2 y74 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">Pregnancy </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y75 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">Exercise </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y76 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  Tourniquets (in<span className="_ _1"></span>crease potas
                  <span className="_ _1"></span>sium and lactate{' '}
                  <span className="_ _1"></span>and decreases pH){' '}
                </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y77 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  Circadian rhythm<span className="_ _1"></span>s{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y78 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">Medications </span>
              </span>
            </div>
            <div className="t m0 xa h2 y79 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y7a ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y7b ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y7c ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y7d ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y7e ff1 fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf4" className="pf w0 h0" data-page-no="4">
          <div className="pc pc4 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x11 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">3 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa ha y57 ff8 fs0 fc0 sc0 ls0 ws0">
              The History of Op<span className="_ _1"></span>timal R
              <span className="_ _1"></span>anges{' '}
            </div>
            <div className="t m0 xa ha y32 ff8 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y33 ff1 fs0 fc0 sc0 ls0 ws0">
              The history of op<span className="_ _1"></span>timal rang
              <span className="_ _1"></span>es dates{' '}
              <span className="ff7">
                back to the 1980‟<span className="_ _1"></span>s. Dr. Eid
                <span className="_ _1"></span>enier explain
                <span className="_ _1"></span>
                ed to m<span className="_ _1"></span>e that a method cal
                <span className="_ _1"></span>led the{' '}
                <span className="ff1"> </span>
              </span>
            </div>
            <div className="t m0 xa h2 y34 ff7 fs0 fc0 sc0 ls0 ws0">
              „Biochemical B<span className="_ _1"></span>iopsy‟ was{' '}
              <span className="_ _1"></span>originally use
              <span className="_ _1"></span>d in an attem
              <span className="_ _1"></span>pt to look fo
              <span className="_ _1"></span>r changes in blo
              <span className="_ _1"></span>
              od composition <span className="_ _1"></span>to detect ca
              <span className="_ _1"></span>ncer. The{' '}
              <span className="ff1"> </span>
            </div>
            <div className="t m0 xa h2 y35 ff1 fs0 fc0 sc0 ls0 ws0">
              methods used fo<span className="_ _1"></span>r analysis were e
              <span className="_ _1"></span>lectrophores
              <span className="_ _1"></span>
              is, atomic spect<span className="_ _1"></span>r
              <span className="_ _0"></span>
              oscopy<span className="_ _1"></span>, hormonal st
              <span className="_ _1"></span>udies, and standa
              <span className="_ _1"></span>
              rd hematol<span className="_ _1"></span>ogical{' '}
            </div>
            <div className="t m0 xa h2 y7f ff1 fs0 fc0 sc0 ls0 ws0">
              studies. This in<span className="_ _1"></span>formation w
              <span className="_ _1"></span>as integrated wi
              <span className="_ _1"></span>
              th inform<span className="_ _1"></span>ation form
              <span className="_ _1"></span> physical exam
              <span className="_ _1"></span>
              s, symptom
              <span className="_ _1"></span> analysis, urinalysis, hair
              <span className="_ _1"></span> <span className="_ _0"></span>{' '}
            </div>
            <div className="t m0 xa h2 y80 ff1 fs0 fc0 sc0 ls0 ws0">
              mineral analysis, s<span className="_ _1"></span>tool analy
              <span className="_ _1"></span>sis, and other d
              <span className="_ _1"></span>
              iagnostic c<span className="_ _1"></span>riteria. Over 10,000 p
              <span className="_ _1"></span>eople were studies, and{' '}
              <span className="_ _1"></span>this infor
              <span className="_ _1"></span>
              mation is{' '}
            </div>
            <div className="t m0 xa h2 y81 ff1 fs0 fc0 sc0 ls0 ws0">
              now used as wha<span className="_ _1"></span>t is referre
              <span className="_ _1"></span>d to as the{' '}
              <span className="ffa">
                ‘optimal <span className="_ _1"></span>ranges’
                <span className="ff1">. </span>
              </span>
            </div>
            <div className="t m0 xa h2 y82 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hb y83 ff7 fs0 fc0 sc0 ls0 ws0">
              Integ<span className="_ _1"></span>rating the optimal ran
              <span className="_ _1"></span>ges with a patien
              <span className="_ _1"></span>
              t‟s history and phy<span className="_ _1"></span>sical exam
              <span className="_ _1"></span> allow us to us
              <span className="_ _1"></span>e blood testing
              <span className="_ _1"></span> in a more{' '}
            </div>
            <div className="t m0 xa h2 y84 ff1 fs0 fc0 sc0 ls0 ws0">
              preventive and f<span className="_ _1"></span>unctional m
              <span className="_ _1"></span>anner.{' '}
            </div>
            <div className="t m0 xa h2 y85 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa ha y86 ff8 fs0 fc0 sc0 ls0 ws0">
              Functional Med<span className="_ _1"></span>icine University{' '}
              <span className="_ _1"></span>Initial Blood T
              <span className="_ _1"></span>
              est Panel{' '}
            </div>
            <div className="t m0 xa ha y87 ff8 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y88 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  Com<span className="_ _1"></span>plete Blood Count with Differ
                  <span className="_ _1"></span>ential{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y89 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  Com<span className="_ _1"></span>prehensive Metabolic Panel{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y8a ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">Lipid Panel </span>
              </span>
            </div>
            <div className="t m0 xa h2 y8b ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">Iron Panel </span>
              </span>
            </div>
            <div className="t m0 xa h2 y8c ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">TSH </span>
              </span>
            </div>
            <div className="t m0 xa h2 y8d ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">Total T4 </span>
              </span>
            </div>
            <div className="t m0 xa h2 y8e ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1" id="T3">
                  Total T3{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y8f ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">Uric Acid </span>
              </span>
            </div>
            <div className="t m0 xa h2 y90 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1 ls4">
                  GGT<span className="ls0"> </span>
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y91 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">Cardio CRP </span>
              </span>
            </div>
            <div className="t m0 xa h2 y92 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  Hemog<span className="_ _1"></span>lobin A1c{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y93 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  Vitamin D 25-<span className="_ _1"></span>Hydroxy{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y94 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">eGFR </span>
              </span>
            </div>
            <div className="t m0 xa h2 y95 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa ha y96 ff8 fs0 fc0 sc0 ls0 ws0">
              Functional Med<span className="_ _1"></span>icine Universi
              <span className="ffb">
                ty’s <span className="_ _1"></span>Blood Test Resu
                <span className="_ _1"></span>lt Tracking{' '}
                <span className="_ _1"></span>
                Form
                <span className="ff8"> </span>
              </span>
            </div>
            <div className="t m0 xa ha y97 ff8 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y98 ff1 fs0 fc0 sc0 ls0 ws0">
              This form is av<span className="_ _1"></span>ailable at{' '}
              <span className="fc1">
                www<span className="_ _1"></span>.FunctionalMedicineUn
                <span className="_ _1"></span>iversity.com
                <span className="_ _1"></span>
                <span className="fc0">
                  {' '}
                  on line library. A<span className="_ _1"></span> copy of it is
                  inclu
                  <span className="_ _1"></span>ded at the e
                  <span className="_ _1"></span>
                  nd of{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y99 ff7 fs0 fc0 sc0 ls0 ws0">
              your insiders‟ guid<span className="_ _1"></span>e. Using
              <span className="_ _1"></span> this form w
              <span className="_ _1"></span>
              ill allow you to<span className="_ _1"></span> look at all of{' '}
              <span className="_ _1"></span>the positive t
              <span className="_ _1"></span>
              est results and in<span className="_ _1"></span>tegrate the
              <span className="_ _1"></span>m into the
              <span className="_ _0"></span>
              <span className="ff1"> </span>
            </div>
            <div className="t m0 xa h2 y9a ff7 fs0 fc0 sc0 ls0 ws0">
              patient‟s histo<span className="_ _1"></span>ry and physical ex
              <span className="_ _1"></span>am to form
              <span className="_ _1"></span> a working diagnosi
              <span className="_ _1"></span>s. It m
              <span className="_ _1"></span>ay{' '}
              <span className="ff1">
                also be used in decision m<span className="_ _1"></span>aking
                <span className="_ _1"></span> for advanced{' '}
              </span>
            </div>
            <div className="t m0 xa h2 y9b ff1 fs0 fc0 sc0 ls0 ws0">
              diagnostic testing<span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 xa h2 y9c ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa ha y9d ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  Note: It is extrem<span className="_ _1"></span>ely im
                  <span className="_ _1"></span>portant that you are{' '}
                  <span className="_ _1"></span>registered{' '}
                  <span className="_ _1"></span>
                  with <span className="ff8">CLIA</span>{' '}
                  <span className="ff8">
                    (Clinical Labo<span className="_ _1"></span>ratory Improv
                    <span className="_ _1"></span>ement{' '}
                  </span>
                </span>
              </span>
            </div>
            <div className="t m0 x12 ha y9e ff8 fs0 fc0 sc0 ls0 ws0">
              Amendments)
              <span className="ff1">
                {' '}
                befo<span className="_ _1"></span>re you beg
                <span className="_ _1"></span>
                in to perform<span className="_ _1"></span> in-office testing. A
                <span className="_ _1"></span> 1998 Am
                <span className="_ _1"></span>
                endment expanded ea<span className="_ _1"></span>rlier
                regulations to{' '}
              </span>
            </div>
            <div className="t m0 x12 h2 y9f ff1 fs0 fc0 sc0 ls0 ws0">
              include all laboratories, regardless of size or location
              (including physician office laboratories) that test human
            </div>
            <div className="t m0 x12 h2 ya0 ff1 fs0 fc0 sc0 ls0 ws0">
              specimens. This m<span className="_ _1"></span>eans that all{' '}
              <span className="_ _1"></span>laboratory test
              <span className="_ _1"></span>
              ing, with the excep<span className="_ _1"></span>tion of waiv
              <span className="_ _1"></span>ed tests (e.g
              <span className="_ _1"></span>
              ., urine dipstick<span className="_ _1"></span>) are{' '}
            </div>
            <div className="t m0 x12 h2 ya1 ff1 fs0 fc0 sc0 ls0 ws0">
              inspected for com<span className="_ _1"></span>pliance with CLI
              <span className="_ _1"></span>A regulations by th
              <span className="_ _1"></span>e Center fo
              <span className="_ _1"></span>r Medica
              <span className="_ _1"></span>re and Medica
              <span className="_ _1"></span>id Services (CMS
              <span className="_ _1"></span>) or ano
              <span className="_ _1"></span>ther{' '}
            </div>
            <div className="t m0 x12 h2 ya2 ff1 fs0 fc0 sc0 ls0 ws0">
              agency recog<span className="_ _1"></span>nized by CMS. To che
              <span className="_ _1"></span>ck for your s
              <span className="_ _1"></span>
              tate com<span className="_ _1"></span>pliance regulations g
              <span className="_ _1"></span>o to:{' '}
              <span className="fc1">
                www.cms.hhs.gov<span className="_ _1"></span>
                <span className="fc0">. </span>
              </span>
            </div>
            <a
              className="l"
              href="http://www.functionalmedicineuniversity.com/"
            >
              <div
                className="d m1"
                style={{
                  borderStyle: 'none',
                  position: 'absolute',
                  left: '274.560000px',
                  bottom: '468.240000px',
                  width: '245.920000px',
                  height: '15.870000px',
                  backgroundColor: 'rgba(255,255,255,0.000001)',
                }}
              ></div>
            </a>
            <a className="l" href="http://www.cms.hhs.gov/">
              <div
                className="d m1"
                style={{
                  borderStyle: 'none',
                  position: 'absolute',
                  left: '274.560000px',
                  bottom: '468.240000px',
                  width: '245.920000px',
                  height: '15.870000px',
                  backgroundColor: 'rgba(255,255,255,0.000001)',
                }}
              ></div>
            </a>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf5" className="pf w0 h0" data-page-no="5">
          <div className="pc pc5 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x11 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">4 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y7 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y32 ffd fs0 fc0 sc0 ls0 ws0">
              Complete Blood Coun<span className="_ _1"></span>t with Differen
              <span className="_ _1"></span>tial{' '}
            </div>
            <div className="t m0 xa h2 y33 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x13 hc y58 ffc fs0 fc0 sc0 ls0 ws0">
              White blood coun<span className="_ _1"></span>t{' '}
            </div>
            <div className="t m0 x14 hc ya3 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y5a ff1 fs0 fc0 sc0 ls0 ws0">
              Leukocytes (white <span className="_ _1"></span>blood cells) o
              <span className="_ _1"></span>f the peripher
              <span className="_ _1"></span>
              al blood <span className="_ _1"></span>are divided in
              <span className="_ _1"></span>to two groups
              <span className="_ _1"></span>, the granulocy
              <span className="_ _1"></span>tes and the n
              <span className="_ _1"></span>on<span className="_ _0"></span>-{' '}
            </div>
            <div className="t m0 xa h2 ya4 ff1 fs0 fc0 sc0 ls0 ws0">
              granulocytes. Cel<span className="_ _1"></span>ls in these
              <span className="_ _1"></span> categories are:{' '}
            </div>
            <div className="t m0 x14 h2 ya5 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x14 h2 ya6 ff1 fs0 fc0 sc0 ls0 ws0">
              Granulocytes:{' '}
            </div>
            <div className="t m0 x14 h2 ya7 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x14 h2 ya8 ff1 fs0 fc0 sc0 ls0 ws0">
              {' '}
              <span className="_ _8"> </span> <span className="_ _2"> </span>
              Polym
              <span className="_ _1"></span>orphonuclear Neu
              <span className="_ _1"></span>
              trophils (Segmented an<span className="_ _1"></span>d Bands){' '}
            </div>
            <div className="t m0 x14 h2 ya9 ff1 fs0 fc0 sc0 ls0 ws0">
              {' '}
              <span className="_ _8"> </span> <span className="_ _2"> </span>
              Eosinophi
              <span className="_ _1"></span>ls{' '}
            </div>
            <div className="t m0 x14 h2 yaa ff1 fs0 fc0 sc0 ls0 ws0">
              {' '}
              <span className="_ _8"> </span> <span className="_ _2"> </span>
              Basoph
              <span className="_ _1"></span>ils{' '}
            </div>
            <div className="t m0 x14 h2 yab ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x14 h2 yac ff1 fs0 fc0 sc0 ls0 ws0">
              Non-Granulocy<span className="_ _1"></span>tes:{' '}
            </div>
            <div className="t m0 x14 h2 yad ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x14 h2 y3e ff1 fs0 fc0 sc0 ls0 ws0">
              {' '}
              <span className="_ _8"> </span> <span className="_ _2"> </span>Lym
              <span className="_ _1"></span>phocytes{' '}
            </div>
            <div className="t m0 x14 h2 yae ff1 fs0 fc0 sc0 ls0 ws0">
              {' '}
              <span className="_ _8"> </span> <span className="_ _2"> </span>
              Monocyte
              <span className="_ _1"></span>s{' '}
            </div>
            <div className="t m0 x14 h2 yaf ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x14 hb yb0 ff7 fs0 fc0 sc0 ls0 ws0">
              The major funct<span className="_ _1"></span>ion of WBC‟s is to{' '}
              <span className="_ _1"></span>fight infec
              <span className="_ _1"></span>
              tion, react ag<span className="_ _1"></span>ainst foreign bod
              <span className="_ _1"></span>ies or tissue
              <span className="_ _1"></span>
              s, and defend <span className="_ _1"></span>the body
              <span className="_ _1"></span> by{' '}
            </div>
            <div className="t m0 x14 h2 yb1 ff1 fs0 fc0 sc0 ls0 ws0">
              phagocytosis.{' '}
            </div>
            <div className="t m0 xa h2 yb2 ff1 fs0 fc0 sc0 ls0 ws0">
              {' '}
              <span className="ffd"> </span>
            </div>
            <div className="t m0 xa h2 yb3 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 x15 h2 yb4 ff1 fs0 fc0 sc0 ls0 ws0">
              {' '}
              <span className="_ _e"> </span>{' '}
            </div>
            <div className="t m0 x15 h2 yb5 ff1 fs0 fc0 sc0 ls0 ws0">
              {' '}
              <span className="_ _e"> </span>
              <span className="ffc">Increased levels:</span>{' '}
            </div>
            <div className="t m0 x15 h2 yb6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x16 h2 yb7 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Childhood Disea<span className="_ _1"></span>ses (Measl
                  <span className="_ _1"></span>es, Mumps, Chick
                  <span className="_ _1"></span>en-Pox, Rubella, Etc){' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 yb8 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Acute Viral o<span className="_ _1"></span>r Bacterial I
                  <span className="_ _1"></span>nfection{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 yb9 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Steroid Use </span>
              </span>
            </div>
            <div className="t m0 x16 h2 yba ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Thyroid Storm<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 ybb ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Intestinal para<span className="_ _1"></span>sites{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 ybc ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Some ty<span className="_ _1"></span>pes of cancer{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 ybd ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Infectious m<span className="_ _1"></span>ononucleosis{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 ybe ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Adrenal dysfun<span className="_ _1"></span>ction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 ybf ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yc0 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yc1 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yc2 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yc3 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yc4 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yc5 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x15 h2 yc6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf6" className="pf w0 h0" data-page-no="6">
          <div className="pc pc6 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x11 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">5 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x17 hc y7 ffc fs0 fc0 sc0 ls0 ws0"
              id="White Blood Count"
            >
              White Blood Count{' '}
              <span className="ffa">
                ( con<span className="_ _1"></span>’t)
                <span className="ffc"> </span>
              </span>
            </div>
            <div className="t m0 x15 h2 y8 ff1 fs0 fc0 sc0 ls0 ws0">
              {' '}
              <span className="_ _e"> </span>{' '}
            </div>
            <div className="t m0 x15 h2 y33 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els
              <span className="ff1">: </span>
            </div>
            <div className="t m0 x15 h2 y34 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x16 h2 y35 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Chronic viral or bac<span className="_ _1"></span>terial in
                  <span className="_ _1"></span>fection{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y7f ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Autoimm<span className="_ _1"></span>une disorder{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y80 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Systemic Lupus Ery<span className="_ _1"></span>thematosis{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y81 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Hepatitis </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y82 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Vitamin B12, <span className="_ _1"></span>Vitamin B6 and fo
                  <span className="_ _1"></span>lic acid anem
                  <span className="_ _1"></span>
                  ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y83 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Intestinal para<span className="_ _1"></span>sites (chronic
                  <span className="_ _1"></span>){' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y84 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Rheumatoid arth<span className="_ _1"></span>ritis{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y85 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Iron deficiency<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 ye ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y87 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x18 hc yc7 ffc fs0 fc0 sc0 ls0 ws0"
              id="Red Blood Count"
            >
              Red Blood Coun<span className="_ _1"></span>t{' '}
            </div>
            <div className="t m0 x1 hc yc8 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y3e ff1 fs0 fc0 sc0 ls0 ws0">
              Red blood <span className="_ _0"></span>cells{' '}
              <span className="_ _0"></span>
              are <span className="_ _0"></span>respons
              <span className="_ _1"></span>
              ible for<span className="_ _0"></span> carrying oxygen fr
              <span className="_ _0"></span>om the{' '}
              <span className="_ _0"></span>lungs <span className="_ _0"></span>
              to <span className="_ _0"></span>body tissues and{' '}
              <span className="_ _0"></span>the <span className="_ _0"></span>
              transference of <span className="_ _0"></span>carbon{' '}
              <span className="_ _0"></span>dioxide{' '}
            </div>
            <div className="t m0 xa h2 yae ff1 fs0 fc0 sc0 ls0 ws0">
              from <span className="_ _0"></span>the{' '}
              <span className="_ _0"></span>
              tissues <span className="_ _0"></span>to{' '}
              <span className="_ _0"></span>the <span className="_ _0"></span>
              lungs. <span className="_ _0"></span>T
              <span className="_ _0"></span>he <span className="_ _0"></span>
              shape <span className="_ _0"></span>of{' '}
              <span className="_ _0"></span>the <span className="_ _0"></span>
              red <span className="_ _0"></span>cell{' '}
              <span className="_ _0"></span>enables{' '}
              <span className="_ _0"></span>the <span className="_ _12"></span>
              maxim<span className="_ _1"></span>um a
              <span className="_ _0"></span>mount <span className="_ _0"></span>
              of <span className="_ _0"></span>hemoglobin{' '}
              <span className="_ _0"></span>to <span className="_ _0"></span>be{' '}
              <span className="_ _0"></span>used. <span className="_ _0"></span>
              T<span className="_ _0"></span>his t<span className="_ _0"></span>
              est<span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xa h2 yaf ff1 fs0 fc0 sc0 ls0 ws0">
              determines the tot<span className="_ _1"></span>al number of{' '}
              <span className="_ _1"></span>erythrocytes{' '}
              <span className="_ _1"></span>
              found in a <span className="_ _1"></span>cubic millim
              <span className="_ _1"></span>
              eter of blood.{' '}
            </div>
            <div className="t m0 xa h2 yb0 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yb1 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 yc9 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x14 hc yb3 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa hc yb4 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x19 h2 yb5 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Polycythem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x19 h2 yb6 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Respiratory distr<span className="_ _1"></span>ess (asthma/em
                  <span className="_ _1"></span>physem
                  <span className="_ _1"></span>a){' '}
                </span>
              </span>
            </div>
            <div className="t m0 x19 h2 yb7 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">High altitude </span>
              </span>
            </div>
            <div className="t m0 xa h2 yb8 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x15 hc yb9 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 x14 hc yba ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x15 h2 ybb ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Iron anem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x15 h2 ybc ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Vitamin B12, Vitam<span className="_ _1"></span>in B6 and/or
                  fo
                  <span className="_ _1"></span>lic acid anem
                  <span className="_ _1"></span>
                  ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x15 h2 ybd ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Liver and renal dy<span className="_ _1"></span>sfunction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x15 h2 ybe ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Some cancers </span>
              </span>
            </div>
            <div className="t m0 x15 h2 ybf ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Hemorrhage </span>
              </span>
            </div>
            <div className="t m0 xa h2 yc0 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yc1 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yc2 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yc3 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yc4 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yc5 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yc6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf7" className="pf w0 h0" data-page-no="7">
          <div className="pc pc7 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x11 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">6 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x1a hc y7 ffc fs0 fc0 sc0 ls0 ws0"
              id="HEMOGLOBIN"
            >
              Hemoglobin{' '}
            </div>
            <div className="t m0 x1 hc y8 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yca ff1 fs0 fc0 sc0 ls0 ws0">
              Hemoglobin (HGB<span className="_ _1"></span>) is the m
              <span className="_ _1"></span>ajor component o
              <span className="_ _1"></span>f the red blood{' '}
              <span className="_ _1"></span>cell (RBC
              <span className="_ _1"></span>) and functions{' '}
              <span className="_ _1"></span>
              in the transport o<span className="_ _1"></span>f oxyg
              <span className="_ _1"></span>en.<span className="_ _0"></span>{' '}
            </div>
            <div className="t m0 xa h2 y58 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 ya3 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y5a ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x14 hc ya4 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls{' '}
            </div>
            <div className="t m0 x14 hc ya5 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x15 h2 ycb ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Dehydration </span>
              </span>
            </div>
            <div className="t m0 x15 h2 ycc ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Emphysem<span className="_ _1"></span>a{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x15 h2 ycd ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Asthma </span>
              </span>
            </div>
            <div className="t m0 x15 h2 yce ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Polycythem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 ycf ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x14 hc yd0 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 yc7 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x15 h2 yc8 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Iron deficiency anem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x15 h2 yd1 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Microscopic interna<span className="_ _1"></span>l bleeding{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x15 h2 yd2 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Digestive inflam<span className="_ _1"></span>mation{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1 h2 yd3 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x1b hc yd4 ffc fs0 fc0 sc0 ls0 ws0"
              id="HEMATOCRIT"
            >
              Hematocrit{' '}
            </div>
            <div className="t m0 xa hc yd5 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yc9 ff1 fs0 fc0 sc0 ls0 ws0">
              The packed cel<span className="_ _1"></span>l volume (HC
              <span className="_ _1"></span>T) is the percen
              <span className="_ _1"></span>
              tage of the total v<span className="_ _1"></span>olume occupied
              <span className="_ _1"></span> by packed red{' '}
              <span className="_ _1"></span>
              blood cells (RBC<span className="_ _1"></span>) when a{' '}
            </div>
            <div className="t m0 xa h2 yb3 ff1 fs0 fc0 sc0 ls0 ws0">
              given v<span className="_ _1"></span>olume of whole blood is cen
              <span className="_ _1"></span>trifuged.{' '}
            </div>
            <div className="t m0 xa h2 yb4 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yb5 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 yb6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc yb7 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 x15 hc yb8 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x15 h2 yb9 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Dehydration </span>
              </span>
            </div>
            <div className="t m0 x15 h2 yba ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Emphysem<span className="_ _1"></span>a{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x15 h2 ybb ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Asthma </span>
              </span>
            </div>
            <div className="t m0 x15 h2 ybc ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Polycythem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1d h2 ybd ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x15 hc ybe ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 x15 hc ybf ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x15 h2 yc0 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Iron deficiency anem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x15 h2 yc1 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Microscopic interna<span className="_ _1"></span>l bleeding{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x15 h2 yc2 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Digestive inflam<span className="_ _1"></span>mation{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x14 h2 yc3 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x14 h2 yc4 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x14 h2 yc5 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x14 h2 yc6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf8" className="pf w0 h0" data-page-no="8">
          <div className="pc pc8 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x11 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">7 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1e hc y7 ffc fs0 fc0 sc0 ls0 ws0" id="MCV">
              MCV{' '}
            </div>
            <div className="t m0 x1 hc y8 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yca ff1 fs0 fc0 sc0 ls0 ws0">
              The MCV indicat<span className="_ _1"></span>es the volum
              <span className="_ _1"></span>e in cubic m
              <span className="_ _1"></span>
              icrons occupied by an <span className="_ _1"></span>average sing
              <span className="_ _1"></span>le red blood ce
              <span className="_ _1"></span>
              ll. A MCV in<span className="_ _1"></span>crease along
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xa h2 y58 ff1 fs0 fc0 sc0 ls0 ws0">
              with an increase <span className="_ _1"></span>or decrease in{' '}
              <span className="_ _1"></span>the MCH is a s
              <span className="_ _1"></span>
              ignificant find<span className="_ _1"></span>ing for vitam
              <span className="_ _1"></span>in B12, folic a
              <span className="_ _1"></span>
              cid, iron, coppe<span className="_ _1"></span>r or vitam
              <span className="_ _1"></span>in B6{' '}
            </div>
            <div className="t m0 xa h2 ya3 ff1 fs0 fc0 sc0 ls0 ws0">
              anemia. MC<span className="_ _1"></span>V and MCH should alway
              <span className="_ _1"></span>s be viewed tog
              <span className="_ _1"></span>
              ether to as<span className="_ _1"></span>certain a poss
              <span className="_ _1"></span>ible v<span className="_ _1"></span>
              itamin B6, vitam<span className="_ _1"></span>in B12 or folic ac
              <span className="_ _1"></span>id{' '}
            </div>
            <div className="t m0 xa h2 y5a ff1 fs0 fc0 sc0 ls0 ws0">
              anemia. The susp<span className="_ _1"></span>icion of vitam
              <span className="_ _1"></span>in B6, vitamin B12 or{' '}
              <span className="_ _1"></span>folic acid an
              <span className="_ _1"></span>
              emia should be<span className="_ _1"></span> confirm
              <span className="_ _1"></span>
              ed with a serum<span className="_ _1"></span> or urinary
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xa h2 ya4 ff1 fs0 fc0 sc0 ls0 ws0">
              methy<span className="_ _1"></span>lmalonic acid and ho
              <span className="_ _1"></span>mocysteine.{' '}
            </div>
            <div className="t m0 xa h2 ya5 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 ycb ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Significanc<span className="_ _1"></span>e{' '}
            </div>
            <div className="t m0 xa h2 ycc ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x14 hc ycd ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 x14 h2 yce ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x15 h2 ycf ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Vitamin B12/Fol<span className="_ _1"></span>ic acid anem
                  <span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x15 h2 yd0 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Dehydration </span>
              </span>
            </div>
            <div className="t m0 x14 h2 yc7 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x14 h2 yc8 ff1 fs0 fc0 sc0 ls0 ws0">
              Decreased level<span className="_ _1"></span>s:{' '}
            </div>
            <div className="t m0 x14 h2 yd1 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x19 h2 yd2 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Iron anem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x19 h2 yd3 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Microscopic interna<span className="_ _1"></span>l bleeding{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x14 h2 yd4 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc yd6 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1e hc yc9 ffc fs0 fc0 sc0 ls0 ws0" id="MCH">
              MCH{' '}
            </div>
            <div className="t m0 x1 hc yb3 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yd7 ff1 fs0 fc0 sc0 ls0 ws0">
              MCH indicates the<span className="_ _1"></span> weight of hem
              <span className="_ _1"></span>oglobin in a s
              <span className="_ _1"></span>
              ingle red blo<span className="_ _1"></span>od cell. MCH i
              <span className="_ _1"></span>ncrease or decr
              <span className="_ _1"></span>
              ease along w<span className="_ _1"></span>ith an increase or{' '}
            </div>
            <div className="t m0 xa h2 yd8 ff1 fs0 fc0 sc0 ls0 ws0">
              decrease in MC<span className="_ _1"></span>V is a signif
              <span className="_ _1"></span>icant finding
              <span className="_ _1"></span> for vitamin B12, fo
              <span className="_ _1"></span>lic acid, iron,
              <span className="_ _1"></span> copper and v
              <span className="_ _1"></span>
              itamin B6 anem<span className="_ _1"></span>ia.
              <span className="_ _0"></span>{' '}
            </div>
            <div className="t m0 xa h2 yd9 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yda ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 ydb ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc ydc ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa hc ydd ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 yde ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Vitamin B12/Fol<span className="_ _1"></span>ic acid anem
                  <span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa hc ydf ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc ye0 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa hc ye1 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 ye2 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Iron anem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 ye3 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Internal bleeding </span>
              </span>
            </div>
            <div className="t m0 x20 h2 ye4 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Toxic effects of l<span className="_ _1"></span>ead, aluminum
                  <span className="_ _1"></span>, cadm
                  <span className="_ _1"></span>ium, and other toxic meta
                  <span className="_ _1"></span>ls{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 ye5 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Vitamin B6 anem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 ye6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 ye7 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 ye8 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 ye9 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf9" className="pf w0 h0" data-page-no="9">
          <div className="pc pc9 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x11 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">8 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1a h2 y7 ff1 fs0 fc0 sc0 ls0 ws0">
              {' '}
              <span className="_ _2"> </span>
              <span className="ffc" id="MCHC">
                MCHC{' '}
              </span>
            </div>
            <div className="t m0 x1 h2 y8 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yca ff1 fs0 fc0 sc0 ls0 ws0">
              MCHC indicates <span className="_ _1"></span>the average hem
              <span className="_ _1"></span>oglobin concent
              <span className="_ _1"></span>
              ration per un<span className="_ _1"></span>it volum
              <span className="_ _1"></span>e (100mL) of pack
              <span className="_ _1"></span>
              ed red blood cel<span className="_ _1"></span>ls.{' '}
            </div>
            <div className="t m0 xa h2 y58 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 ya3 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y5a ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x14 hc ya4 ffc fs0 fc0 sc0 ls0 ws0">
              {' '}
              <span className="_ _8"> </span>Increase
              <span className="_ _1"></span>d levels:{' '}
            </div>
            <div className="t m0 xa h2 ya5 ff1 fs0 fc0 sc0 ls0 ws0">
              {' '}
              <span className="_ _e"> </span> <span className="_ _13"> </span>{' '}
            </div>
            <div className="t m0 x20 h2 ycb ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Vitamin B12/Fol<span className="_ _1"></span>ic acid anem
                  <span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 ycc ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Dehydration </span>
              </span>
            </div>
            <div className="t m0 x1d h2 ycd ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc yce ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 ycf ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 yd0 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Internal bleeding </span>
              </span>
            </div>
            <div className="t m0 x20 h2 yc7 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Toxic effects o<span className="_ _1"></span>f lead, alum
                  <span className="_ _1"></span>inum, cadm
                  <span className="_ _1"></span>
                  ium, and other toxic m<span className="_ _1"></span>etals{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 yc8 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Vitamin B6 anem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x21 hc yd1 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc yea ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1e hc yd3 ffc fs0 fc0 sc0 ls0 ws0" id="RDW">
              RDW{' '}
            </div>
            <div className="t m0 x1 hc yd4 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yd5 ff1 fs0 fc0 sc0 ls0 ws0">
              The RDW is an <span className="_ _1"></span>electronic m
              <span className="_ _1"></span>easurem
              <span className="_ _1"></span>ent of anisocytosi
              <span className="_ _1"></span>s (red cell v
              <span className="_ _1"></span>ariability). An{' '}
              <span className="_ _1"></span>
              RDW increase <span className="_ _1"></span>is primari
              <span className="_ _1"></span>ly <span className="_ _0"></span>
              indicative of{' '}
            </div>
            <div className="t m0 xa h2 yeb ff1 fs0 fc0 sc0 ls0 ws0">
              iron deficiency<span className="_ _1"></span> anemia; how
              <span className="_ _1"></span>ever, it can als
              <span className="_ _1"></span>o be increased wi
              <span className="_ _1"></span>th vitam
              <span className="_ _1"></span>in B12 and/or fo
              <span className="_ _1"></span>
              late anem<span className="_ _1"></span>ia.
              <span className="_ _0"></span>{' '}
            </div>
            <div className="t m0 xa h2 yec ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x22 hc yed ffc fs0 fc0 sc0 ls0 ws0">
              Platelets{' '}
            </div>
            <div className="t m0 x1 hc yee ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yef ff1 fs0 fc0 sc0 ls0 ws0">
              Platelets are involv<span className="_ _1"></span>ed with t
              <span className="_ _1"></span>he clotting of b
              <span className="_ _1"></span>
              lood coagulation, vasocon<span className="_ _1"></span>striction,
              and
              <span className="_ _1"></span> vascular in
              <span className="_ _1"></span>
              tegrity. Break<span className="_ _1"></span>s in small{' '}
            </div>
            <div className="t m0 xa h2 yf0 ff1 fs0 fc0 sc0 ls0 ws0">
              vessels are occluded by<span className="_ _1"></span> adhesion and{' '}
              <span className="_ _1"></span>agg<span className="_ _1"></span>
              regation of platele<span className="_ _1"></span>ts.{' '}
            </div>
            <div className="t m0 xa h2 yf1 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yf2 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 yf3 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc yf4 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 x1c hc yf5 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 yf6 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Rheumatoid arth<span className="_ _1"></span>ritis{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 yf7 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Arteriosclerosi<span className="_ _1"></span>s{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 yf8 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Some ty<span className="_ _1"></span>pes of cancer{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 yf9 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Inflamm<span className="_ _1"></span>atory arthritis{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 yfa ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Several types of <span className="_ _1"></span>anemia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 yfb ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Polycythem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 yfc ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  During pregnancy<span className="_ _1"></span> (slight increa
                  <span className="_ _1"></span>se){' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 yfd ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yfe ff1 fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pfa" className="pf w0 h0" data-page-no="a">
          <div className="pc pca w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x11 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">9 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x23 hc y7 ffc fs0 fc0 sc0 ls0 ws0">
              Platelets (cont)
            </div>
            <div className="t m0 xa h2 y8 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y33 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y34 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y35 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Idiopathic throm<span className="_ _1"></span>bocytopenia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y7f ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Blood loss </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y80 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Thrombocy<span className="_ _1"></span>topenia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x24 hc y81 ffc fs0 fc0 sc0 ls0 ws0">
              Neutrophils{' '}
            </div>
            <div className="t m0 x1 hc y82 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y83 ff1 fs0 fc0 sc0 ls0 ws0">
              The chief funct<span className="_ _1"></span>ion of the neutrop
              <span className="_ _1"></span>hil is that of ph
              <span className="_ _1"></span>
              agocytosis.{' '}
            </div>
            <div className="t m0 xa h2 y84 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y85 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 ye ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c h2 yf ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls
              <span className="ff1">: </span>
            </div>
            <div className="t m0 xa h2 y10 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 y11 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Active viral or bac<span className="_ _1"></span>terial infect
                  <span className="_ _1"></span>ion{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y12 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc yff ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y100 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 y101 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Chronic viral or bac<span className="_ _1"></span>terial in
                  <span className="_ _1"></span>fections{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa hc y102 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y103 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x25 hc y104 ffc fs0 fc0 sc0 ls0 ws0"
              id="LYMPHOCYTES"
            >
              Lymphocytes{' '}
            </div>
            <div className="t m0 x1 hc y105 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y106 ff1 fs0 fc0 sc0 ls0 ws0">
              Lymphocy<span className="_ _1"></span>tes help to destroy
              <span className="_ _1"></span> the toxic produ
              <span className="_ _1"></span>
              cts of prot<span className="_ _1"></span>ein metabolism
              <span className="_ _1"></span>. They migrate to areas of in
              <span className="_ _1"></span>flamm<span className="_ _1"></span>
              ation in both{' '}
            </div>
            <div className="t m0 xa h2 y107 ff1 fs0 fc0 sc0 ls0 ws0">
              early and late st<span className="_ _1"></span>ages of the i
              <span className="_ _1"></span>nflamm<span className="_ _1"></span>
              atory process.{' '}
            </div>
            <div className="t m0 xa hc y108 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y109 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Significanc<span className="_ _1"></span>e{' '}
            </div>
            <div className="t m0 xa h2 y10a ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y10b ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y10c ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 y10d ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Chronic viral and<span className="_ _1"></span> bacterial infe
                  <span className="_ _1"></span>ctions{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y76 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Viral infection (m<span className="_ _1"></span>um
                  <span className="_ _1"></span>ps, rubella, Mononucleosi
                  <span className="_ _1"></span>s){' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y10e ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y10f ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y110 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 y111 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Active viral and<span className="_ _1"></span> bacterial in
                  <span className="_ _1"></span>fections{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y112 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Leukem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y113 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Immunodeficiency<span className="_ _1"></span> disease{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y114 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Lupus </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y115 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Prednisone </span>
              </span>
            </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pfb" className="pf w0 h0" data-page-no="b">
          <div className="pc pcb w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">10 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x27 hc y7 ffc fs0 fc0 sc0 ls0 ws0"
              id="MONOCYTES"
            >
              Monocytes{' '}
            </div>
            <div className="t m0 x21 h2 y8 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y33 ff1 fs0 fc0 sc0 ls0 ws0">
              Monos phagocy<span className="_ _1"></span>tize some bacteria, pa
              <span className="_ _1"></span>rticulate m
              <span className="_ _1"></span>
              atter and protoz<span className="_ _1"></span>oa. In the inflam
              <span className="_ _1"></span>matory<span className="_ _1"></span>{' '}
              process neutrophils <span className="_ _1"></span>predominate{' '}
            </div>
            <div className="t m0 xa h2 y34 ff1 fs0 fc0 sc0 ls0 ws0">
              for about three <span className="_ _1"></span>days then they
              <span className="_ _1"></span> break up and the m
              <span className="_ _1"></span>onocy<span className="_ _1"></span>
              tes remain to phagocy<span className="_ _1"></span>tize fragm
              <span className="_ _1"></span>
              ents of cells, et<span className="_ _1"></span>c. This is{' '}
              <span className="_ _1"></span>the<span className="_ _0"></span>{' '}
            </div>
            <div className="t m0 xa h2 y35 ff1 fs0 fc0 sc0 ls0 ws0">
              reason you will of<span className="_ _1"></span>ten find an
              increase <span className="_ _1"></span>in the m
              <span className="_ _1"></span>
              onocytes during<span className="_ _1"></span> the recovery phas
              <span className="_ _1"></span>e of an infec
              <span className="_ _1"></span>
              tion.{' '}
            </div>
            <div className="t m0 xa h2 y7f ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y80 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y81 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x14 hc y82 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 x14 hc y83 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 y84 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Viral infections <span className="_ _1"></span>(mononucleosis){' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa hc y85 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 ye ff1 fs0 fc0 sc0 ls0 ws0">
              Important consid<span className="_ _1"></span>erations with{' '}
              <span className="_ _1"></span>an increased m
              <span className="_ _1"></span>
              onocyte percentag<span className="_ _1"></span>e:{' '}
            </div>
            <div className="t m0 xa h2 yf ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 y10 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Always rule out l<span className="_ _1"></span>iver dysfu
                  <span className="_ _1"></span>nction with increased m
                  <span className="_ _1"></span>onocytes{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y11 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Increased m<span className="_ _1"></span>onocytes indica
                  <span className="_ _1"></span>te excessive tiss
                  <span className="_ _1"></span>ue breakdo
                  <span className="_ _1"></span>
                  wn{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y12 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  An increase in th<span className="_ _1"></span>e monocy
                  <span className="_ _1"></span>tes with an increase{' '}
                  <span className="_ _1"></span>in the basoph
                  <span className="_ _1"></span>
                  ils (above 1.0) <span className="_ _1"></span>and even a m
                  <span className="_ _1"></span>ild increase in th
                  <span className="_ _1"></span>e{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x28 h2 yff ff1 fs0 fc0 sc0 ls0 ws0">
              eosinophils (abov<span className="_ _1"></span>e 3.0) is reason{' '}
              <span className="_ _1"></span>to suspect in
              <span className="_ _1"></span>
              testinal p<span className="_ _1"></span>arasites.{' '}
            </div>
            <div className="t m0 x1f h2 y100 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1 ls6">
                  Th
                  <span className="ls0">
                    e m<span className="_ _1"></span>onocyte percent
                    <span className="_ _1"></span>age will freque
                    <span className="_ _1"></span>ntly increase wit
                    <span className="_ _1"></span>h a decrease in{' '}
                    <span className="_ _1"></span>the lym
                    <span className="_ _1"></span>
                    phocyte percentag<span className="_ _1"></span>e with{' '}
                  </span>
                </span>
              </span>
            </div>
            <div className="t m0 x28 h2 y101 ff7 fs0 fc0 sc0 ls0 ws0">
              Hodgkin‟s disease <span className="_ _1"></span>and several oth
              <span className="_ _1"></span>er forms of c
              <span className="_ _1"></span>
              ancer.<span className="ff1"> </span>
            </div>
            <div className="t m0 x29 h2 y116 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 hc y117 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x24 hc y104 ffc fs0 fc0 sc0 ls0 ws0">
              Eosinophils{' '}
            </div>
            <div className="t m0 x1 hc y105 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 xa h2 y106 ff1 fs0 fc0 sc0 ls0 ws0"
              id="EOSINOPHILS"
            >
              Eosinophils hav<span className="_ _1"></span>e an important{' '}
              <span className="_ _1"></span>role in detoxifi
              <span className="_ _1"></span>
              cation and the b<span className="_ _1"></span>reakdown and r
              <span className="_ _1"></span>emoval o
              <span className="_ _1"></span>f pr
              <span className="_ _0"></span>otein.<span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xa h2 y107 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y108 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y109 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y10a ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y10b ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y10c ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Intestinal para<span className="_ _1"></span>sites{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y10d ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Food and environm<span className="_ _1"></span>ental allerg
                  <span className="_ _1"></span>y/sensitivity{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y76 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Asthma </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y10e ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Emphysem<span className="_ _1"></span>a{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y10f ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y110 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y111 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 y112 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  An eosinophil count<span className="_ _1"></span> at or below
                  1<span className="_ _1"></span>% or less is an
                  <span className="_ _1"></span> uncomm
                  <span className="_ _1"></span>on finding and if prese
                  <span className="_ _1"></span>nt adrenal co
                  <span className="_ _1"></span>rtical{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x28 h2 y113 ff1 fs0 fc0 sc0 ls0 ws0">
              hyperfunction sho<span className="_ _1"></span>uld be ruled out.{' '}
            </div>
            <div className="t m0 x29 h2 y114 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x29 h2 y115 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pfc" className="pf w0 h0" data-page-no="c">
          <div className="pc pcc w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">11 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x2a hc y7 ffc fs0 fc0 sc0 ls0 ws0"
              id="BASOPHILS"
            >
              Basophils{' '}
            </div>
            <div className="t m0 x1 hc y8 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yca ff1 fs0 fc0 sc0 ls0 ws0">
              During inflam<span className="_ _1"></span>mation, baso
              <span className="_ _1"></span>phils deliver hepa
              <span className="_ _1"></span>
              rin to the inf<span className="_ _1"></span>lamed tissue to p
              <span className="_ _1"></span>revent clot
              <span className="_ _1"></span>
              ting; therefore<span className="_ _1"></span>, basophils w
              <span className="_ _1"></span>ill almost{' '}
            </div>
            <div className="t m0 xa h2 y58 ff1 fs0 fc0 sc0 ls0 ws0">
              be increased wit<span className="_ _1"></span>h tissue infl
              <span className="_ _1"></span>amm<span className="_ _1"></span>
              ation. Basophils also contain h<span className="_ _1"></span>
              istamine and <span className="_ _1"></span>serotonin.{' '}
            </div>
            <div className="t m0 xa h2 ya3 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y118 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y119 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y37 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls{' '}
            </div>
            <div className="t m0 x1c hc y11a ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y11b ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Tissue inflamm<span className="_ _1"></span>ation{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y11c ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Intestinal para<span className="_ _1"></span>sites{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y11d ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Polycythem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y11e ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y11f ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y120 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y121 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Stress </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y122 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Acute allergic re<span className="_ _1"></span>actions{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y123 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Hyperthyroidism<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y8b ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y124 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y125 ffd fs0 fc0 sc0 ls0 ws0">
              Comprehensive <span className="_ _1"></span>Metabolic{' '}
              <span className="_ _1"></span>Panel{' '}
            </div>
            <div className="t m0 xa hc y126 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x2b hc y127 ffc fs0 fc0 sc0 ls0 ws0" id="AST">
              AST{' '}
            </div>
            <div className="t m0 x2c hc y128 ffc fs0 fc0 sc0 ls0 ws0">
              Aspartate Amino T<span className="_ _1"></span>ransferase{' '}
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 x1 hc y129 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y12a ff1 fs0 fc0 sc0 ls0 ws0">
              ASOT/AST is an<span className="_ _1"></span> enzym
              <span className="_ _1"></span>e found in high concentra
              <span className="_ _1"></span>tions in the hear
              <span className="_ _1"></span>
              t, liver, skeleto<span className="_ _1"></span>n, m
              <span className="_ _1"></span>uscles, kidneys, and pan
              <span className="_ _1"></span>creas. I
              <span className="_ _1"></span>t is{' '}
            </div>
            <div className="t m0 xa h2 y12b ff1 fs0 fc0 sc0 ls0 ws0">
              functionally sim<span className="_ _1"></span>ilar to SGPT/AL
              <span className="_ _1"></span>T; however, i
              <span className="_ _1"></span>t is not increased as m
              <span className="_ _1"></span>uch as SGPT/
              <span className="_ _1"></span>ALT in liver pr
              <span className="_ _1"></span>
              oblems. AST rev<span className="_ _1"></span>ersibly{' '}
            </div>
            <div className="t m0 xa h2 y12c ff1 fs0 fc0 sc0 ls0 ws0">
              transfers the am<span className="_ _1"></span>ino group of aspar
              <span className="_ _1"></span>tic acid to alpha-
              <span className="_ _1"></span>
              keloglutaric acid <span className="_ _1"></span>to form
              <span className="_ _1"></span> oxaloacetic acid and g
              <span className="_ _1"></span>lutamic acid
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 xa h2 y12d ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y12e ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y12f ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y130 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y131 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y132 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Heart disease </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y133 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Liver disease </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y134 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Musculoskeletal<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y135 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Congestive heart <span className="_ _1"></span>failure{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y136 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Acute pancreati<span className="_ _1"></span>tis{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y137 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y138 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y139 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y13a ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Vitamin B6 anem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y13b ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Renal disease<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pfd" className="pf w0 h0" data-page-no="d">
          <div className="pc pcd w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">12 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x2b hc y7 ffc fs0 fc0 sc0 ls7 ws0">
              ALT<span className="ls0"> </span>
            </div>
            <div className="t m0 x2d hc y8 ffc fs0 fc0 sc0 ls0 ws0">
              Alanine Aminotrans<span className="_ _1"></span>ferase{' '}
            </div>
            <div className="t m0 x1 hc y33 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y58 ff1 fs0 fc0 sc0 ls0 ws0">
              SGPT/ALT is use<span className="_ _0"></span>d to i
              <span className="_ _0"></span>dentify
              <span className="_ _1"></span> hepatocellular disease.{' '}
              <span className="_ _0"></span> It is{' '}
              <span className="_ _0"></span>functionally similar to{' '}
              <span className="_ _0"></span>SGOT/AST.
              <span className="_ _0"></span> <span className="_ _0"></span>Alt
              reversibly transfers the{' '}
            </div>
            <div className="t m0 xa h2 ya3 ff1 fs0 fc0 sc0 ls0 ws0">
              amino acid group<span className="_ _1"></span> Alanine to alph
              <span className="_ _1"></span>a-ketoglutam
              <span className="_ _1"></span>ic acid to form
              <span className="_ _1"></span> pyruv
              <span className="_ _1"></span>
              ic acid and glutam<span className="_ _1"></span>ic acid.{' '}
            </div>
            <div className="t m0 xa h2 y5a ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y119 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y37 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y13c ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 x1c hc y13d ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y13e ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Cirrhosis of th<span className="_ _1"></span>e liver{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y13f ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Acute and chron<span className="_ _1"></span>ic liver nec
                  <span className="_ _1"></span>rosis{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y140 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Hepatitis/m<span className="_ _1"></span>ononucleosis{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y141 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Epstein-Barr v<span className="_ _1"></span>irus and cytom
                  <span className="_ _1"></span>egalovirus{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y142 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Acetaminophen<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y143 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c h2 y144 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els
              <span className="ff1">: </span>
            </div>
            <div className="t m0 xa h2 y145 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 y146 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Vitamin B6 anem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y147 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y148 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x2e hc y149 ffc fs0 fc0 sc0 ls0 ws0">
              Alkaline Phosphata<span className="_ _1"></span>se{' '}
            </div>
            <div className="t m0 x1 hc y14a ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y14b ff1 fs0 fc0 sc0 ls0 ws0">
              ALP is a mem<span className="_ _1"></span>ber of a family
              <span className="_ _1"></span> of zinc m
              <span className="_ _1"></span>
              etalloprotein enzym<span className="_ _1"></span>es that function{' '}
              <span className="_ _1"></span>to split off a te
              <span className="_ _1"></span>
              rminal phosph<span className="_ _1"></span>ate group from
              <span className="_ _1"></span> an{' '}
            </div>
            <div className="t m0 xa h2 y14c ff1 fs0 fc0 sc0 ls0 ws0">
              organic phosphate<span className="_ _1"></span> ester, therefore{' '}
              <span className="_ _1"></span>a decreased se
              <span className="_ _1"></span>
              rum ALP is frequen<span className="_ _1"></span>tly associat
              <span className="_ _1"></span>ed with zinc def
              <span className="_ _1"></span>
              iciency. Alkaline{' '}
            </div>
            <div className="t m0 xa h2 y14d ff1 fs0 fc0 sc0 ls0 ws0">
              phosphatase is loca<span className="_ _1"></span>ted in se
              <span className="_ _1"></span>veral tissues. I
              <span className="_ _1"></span>n addition to the liver,{' '}
              <span className="_ _1"></span>it is also in
              <span className="_ _1"></span> bone, intestine, k
              <span className="_ _1"></span>idney, and pla
              <span className="_ _1"></span>
              centa. The I<span className="_ _1"></span>SO{' '}
            </div>
            <div className="t m0 xa h2 y14e ff1 fs0 fc0 sc0 ls0 ws0">
              enzym<span className="_ _1"></span>es of alkaline phosphatase a
              <span className="_ _1"></span>re helpful in det
              <span className="_ _1"></span>
              ermining the sour<span className="_ _1"></span>ce of increase
              <span className="_ _1"></span>d blood level.{' '}
            </div>
            <div className="t m0 xa h2 y14f ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y150 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y151 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y152 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y153 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y154 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Healing fractu<span className="_ _1"></span>re{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y155 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Liver cancer </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y156 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Cirrhosis </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y157 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Normal pregnancy<span className="_ _1"></span> (third trim
                  <span className="_ _1"></span>ester){' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y158 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Impairment of b<span className="_ _1"></span>ile flow{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y159 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
              </span>
              Paget‟s disease of b<span className="_ _1"></span>one
              <span className="ff1"> </span>
            </div>
            <div className="t m0 xa h2 y15a ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y9e ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y15b ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y15c ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Biliary obstruct<span className="_ _1"></span>ion{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y15d ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Zinc deficiency </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y15e ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Vitamin C insuf<span className="_ _1"></span>ficiency{' '}
                </span>
              </span>
            </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pfe" className="pf w0 h0" data-page-no="e">
          <div className="pc pce w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">13 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y7 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x2f hc y15f ffc fs0 fc0 sc0 ls0 ws0"
              id="BILIRUBIN"
            >
              Total Bilirubin{' '}
            </div>
            <div className="t m0 x1 hc yca ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y160 ff1 fs0 fc0 sc0 ls0 ws0">
              Bilirubin is the e<span className="_ _1"></span>nd product
              <span className="_ _1"></span> of hemog
              <span className="_ _1"></span>lobin breakdown{' '}
              <span className="_ _1"></span>by the spleen, liver k
              <span className="_ _1"></span>upfer cells and{' '}
              <span className="_ _1"></span>
              the bone marrow<span className="_ _1"></span> (RES). In the{' '}
            </div>
            <div className="t m0 xa h2 y161 ff1 fs0 fc0 sc0 ls0 ws0">
              liver, the indire<span className="_ _1"></span>ct bilirubin is com
              <span className="_ _1"></span>bined with Gl
              <span className="_ _1"></span>
              ucuronic acid to<span className="_ _1"></span> form a water{' '}
              <span className="_ _1"></span>soluble com
              <span className="_ _1"></span>
              pound, direct <span className="_ _1"></span>or conjug
              <span className="_ _1"></span>
              ated{' '}
            </div>
            <div className="t m0 xa h2 y118 ff1 fs0 fc0 sc0 ls0 ws0">
              bilirubin, which <span className="_ _1"></span>is secreted in th
              <span className="_ _1"></span>e bile. Bilirubin is clas
              <span className="_ _1"></span>sified as:{' '}
            </div>
            <div className="t m0 xa h2 y119 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y162 ff1 fs0 fc0 sc0 ls0 ws0">
              Total Bilirubin <span className="ff7">–</span> A com
              <span className="_ _1"></span>bination of{' '}
              <span className="_ _1"></span>
              direct and ind<span className="_ _1"></span>irect bil
              <span className="_ _1"></span>
              irubin{' '}
            </div>
            <div className="t m0 xa h2 ya6 ff1 fs0 fc0 sc0 ls0 ws0">
              Direct Bilirubin <span className="ff7">–</span> post-
              <span className="_ _1"></span>hepatic, wat
              <span className="_ _1"></span>
              er-soluble (con<span className="_ _1"></span>jugated){' '}
            </div>
            <div className="t m0 xa h2 ya7 ff1 fs0 fc0 sc0 ls0 ws0">
              Indirect Bilirub<span className="_ _1"></span>in{' '}
              <span className="ff7">–</span> Pre-hepatic, water-
              <span className="_ _1"></span>insoluble (unco
              <span className="_ _1"></span>
              njugated){' '}
            </div>
            <div className="t m0 xa h2 ya8 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 ya9 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 yaa ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc yab ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls: Conjugated (
              <span className="_ _1"></span>direct) Bilirub
              <span className="_ _1"></span>
              in{' '}
            </div>
            <div className="t m0 xa h2 y163 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y164 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Gallstones (bi<span className="_ _1"></span>liary obstruct
                  <span className="_ _1"></span>ion){' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y165 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Liver metastasis<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y166 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Extrahepatic du<span className="_ _1"></span>ct obstruction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y167 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y168 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls: Unconjugated (
              <span className="_ _1"></span>indirect)
              <span className="_ _1"></span> Bilirubin{' '}
            </div>
            <div className="t m0 xa h2 y169 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y16a ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Gilbert syndrom<span className="_ _1"></span>e (congenital enz
                  <span className="_ _1"></span>ym<span className="_ _1"></span>
                  e deficiency interr<span className="_ _1"></span>upting con
                  <span className="_ _1"></span>jugation of bilirubin){' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y8f ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Hepatitis </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y16b ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Cirrhosis </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y16c ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Hemoly<span className="_ _1"></span>ticanemia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x21 h2 y16d ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y16e ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els: None significan
              <span className="_ _1"></span>t{' '}
            </div>
            <div className="t m0 xa h2 y16f ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x30 hc y170 ffc fs0 fc0 sc0 ls0 ws0"
              id="PROTEIN"
            >
              Total Protein<span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 x1 hc y171 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y172 ff1 fs0 fc0 sc0 ls0 ws0">
              Total protein <span className="_ _1"></span>is the com
              <span className="_ _1"></span>bination of album
              <span className="_ _1"></span>
              in and total globu<span className="_ _1"></span>lin; therefore{' '}
              <span className="_ _1"></span>the total protein v
              <span className="_ _1"></span>alue will be a
              <span className="_ _1"></span>
              ffected by the{' '}
            </div>
            <div className="t m0 xa h2 y173 ff1 fs0 fc0 sc0 ls0 ws0">
              albumin and to<span className="_ _1"></span>tal globulin. I
              <span className="_ _1"></span>t is possible to hav
              <span className="_ _1"></span>e a nor
              <span className="_ _1"></span>mal total protein w
              <span className="_ _1"></span>ith an abnormal total g
              <span className="_ _1"></span>lobulin or alb
              <span className="_ _1"></span>
              umin.{' '}
            </div>
            <div className="t m0 xa h2 y174 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y175 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y176 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y177 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 x1c hc y178 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y179 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Digestive dysfunc<span className="_ _1"></span>tion{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y17a ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Dehydration </span>
              </span>
            </div>
            <div className="t m0 xa h2 y17b ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y17c ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 x1c hc y2a ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y55 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Protein malnutr<span className="_ _1"></span>ition/Am
                  <span className="_ _1"></span>ino acid need{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y56 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
              </span>
              Digestive inflam<span className="_ _1"></span>mation (Le
              <span className="_ _1"></span>aky<span className="_ _1"></span>{' '}
              Gut Syndrome, Gastrit<span className="_ _1"></span>is, Colitis, I
              <span className="_ _1"></span>leitis, Crohn‟s D
              <span className="_ _1"></span>i
              <span className="ff1">
                se<span className="_ _0"></span>ase, I
                <span className="_ _1"></span>BS, Etc){' '}
              </span>
            </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pff" className="pf w0 h0" data-page-no="f">
          <div className="pc pcf w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">14 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x22 hc y7 ffc fs0 fc0 sc0 ls0 ws0"
              id="ALBUMIN"
            >
              Albumin{' '}
            </div>
            <div className="t m0 x1 hc y15f ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y17d ff1 fs0 fc0 sc0 ls0 ws0">
              Albumin is produc<span className="_ _1"></span>ed almost en
              <span className="_ _1"></span>tirely by the{' '}
              <span className="_ _1"></span>
              liver; there<span className="_ _1"></span>fore, liver funct
              <span className="_ _1"></span>ion has a ma
              <span className="_ _1"></span>
              jor influence on a<span className="_ _1"></span>lbumin produc
              <span className="_ _1"></span>tion{' '}
            </div>
            <div className="t m0 xa h2 y17e ff1 fs0 fc0 sc0 ls0 ws0">
              and serum album<span className="_ _1"></span>in levels. It is also
              respo
              <span className="_ _1"></span>nsible fo
              <span className="_ _1"></span>r about 80 percent of{' '}
              <span className="_ _1"></span>the collo
              <span className="_ _1"></span>id-osmotic pressure between b
              <span className="_ _1"></span>lood and{' '}
            </div>
            <div className="t m0 xa h2 y17f ff1 fs0 fc0 sc0 ls0 ws0">
              tissue fluids and <span className="_ _1"></span>serves as the t
              <span className="_ _1"></span>ransport protein{' '}
              <span className="_ _1"></span>
              for many<span className="_ _1"></span> substances. When albumin is
              dec
              <span className="_ _1"></span>reased, osm
              <span className="_ _1"></span>
              otic pressure is{' '}
            </div>
            <div className="t m0 xa h2 y180 ff1 fs0 fc0 sc0 ls0 ws0">
              disturbed. Decrea<span className="_ _1"></span>sed albumin i
              <span className="_ _1"></span>s always an indic
              <span className="_ _1"></span>
              ation of signifi<span className="_ _1"></span>cant liver or{' '}
              <span className="_ _1"></span>immune dy
              <span className="_ _1"></span>
              sfunction and in m<span className="_ _1"></span>any case
              <span className="_ _1"></span>s;{' '}
            </div>
            <div className="t m0 xa h2 y181 ff1 fs0 fc0 sc0 ls0 ws0">
              decreased album<span className="_ _1"></span>in indicated frank
              <span className="_ _1"></span> or developing
              <span className="_ _1"></span> free radical p
              <span className="_ _1"></span>athology
              <span className="_ _1"></span> (neoplasm). When albumin is dec
              <span className="_ _1"></span>reased and{' '}
            </div>
            <div className="t m0 xa h2 y182 ff1 fs0 fc0 sc0 ls0 ws0">
              globulins are <span className="_ _1"></span>increased, chronic{' '}
              <span className="_ _1"></span>liver diseas
              <span className="_ _1"></span>e is general
              <span className="_ _1"></span>ly suspected.{' '}
            </div>
            <div className="t m0 xa h2 y183 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y184 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y185 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x12 hc y186 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y187 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 y188 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Dehydration </span>
              </span>
            </div>
            <div className="t m0 x29 h2 y189 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y18a ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 x1f h2 y3d ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Liver/biliary<span className="_ _1"></span> dysfunction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y18b ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Neoplasm<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y18c ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Protein malnutr<span className="_ _1"></span>ition/amino{' '}
                  <span className="_ _1"></span>acid need{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa hc y18d ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc yd3 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x22 hc y18e ffc fs0 fc0 sc0 ls0 ws0"
              id="GLOBULIN"
            >
              Globulin{' '}
            </div>
            <div className="t m0 x1 hc y18f ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y190 ff1 fs0 fc0 sc0 ls0 ws0">
              Total globulin <span className="_ _1"></span>is a combina
              <span className="_ _1"></span>tion of the alph
              <span className="_ _1"></span>a 1, alpha 2,
              <span className="_ _1"></span> beta and gamm
              <span className="_ _1"></span>a fractions{' '}
              <span className="_ _1"></span>
              and most laborato<span className="_ _1"></span>ries calculate th
              <span className="_ _1"></span>e total{' '}
            </div>
            <div className="t m0 xa h2 y191 ff1 fs0 fc0 sc0 ls0 ws0">
              serum globulin by<span className="_ _1"></span> subtracting
              <span className="_ _1"></span> the serum
              <span className="_ _1"></span> albumin from the total serum
              <span className="_ _1"></span> protein. An{' '}
              <span className="_ _1"></span>increase or dec
              <span className="_ _1"></span>
              rease in any <span className="_ _1"></span>of these{' '}
            </div>
            <div className="t m0 xa h2 y192 ff1 fs0 fc0 sc0 ls0 ws0">
              fractions (alpha <span className="_ _1"></span>1, alpha 2,{' '}
              <span className="_ _1"></span>beta and/or gam
              <span className="_ _1"></span>
              ma) will ca<span className="_ _1"></span>use an increase o
              <span className="_ _1"></span>r decrease{' '}
              <span className="_ _1"></span>in the total g
              <span className="_ _1"></span>lobulin. For th
              <span className="_ _1"></span>is reason,{' '}
            </div>
            <div className="t m0 xa h2 y193 ff1 fs0 fc0 sc0 ls0 ws0">
              care must be tak<span className="_ _1"></span>en when m
              <span className="_ _1"></span>aking a diagnosis
              <span className="_ _1"></span> based upon the to
              <span className="_ _1"></span>tal globulin alone.{' '}
              <span className="_ _1"></span>Total globulin{' '}
              <span className="_ _1"></span>
              is useful wit<span className="_ _1"></span>h other tests{' '}
            </div>
            <div className="t m0 xa h2 y194 ff1 fs0 fc0 sc0 ls0 ws0">
              for assessing deg<span className="_ _1"></span>enerative, inf
              <span className="_ _1"></span>lammatory
              <span className="_ _1"></span> and infectious pro
              <span className="_ _1"></span>cesses and wi
              <span className="_ _1"></span>th BUN,{' '}
              <span className="_ _1"></span>serum phosphorus,{' '}
              <span className="_ _1"></span>serum
              <span className="_ _1"></span>, gastrin,{' '}
            </div>
            <div className="t m0 xa h2 y195 ff1 fs0 fc0 sc0 ls0 ws0">
              urinary indican, sub<span className="_ _1"></span>jectiv
              <span className="_ _1"></span>e indicators and ot
              <span className="_ _1"></span>her tests loc
              <span className="_ _1"></span>
              ated below to <span className="_ _1"></span>confirm a need for HCI
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 xa h2 y196 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y197 ff1 fs0 fc0 sc0 ls0 ws0">
              About 90% of th<span className="_ _1"></span>e alpha-1-globulin is{' '}
              <span className="_ _1"></span>alpha-1-
              <span className="_ _1"></span>
              antitrysin. I<span className="_ _1"></span>t is a circulating
              inhib
              <span className="_ _1"></span>itor of pro
              <span className="_ _1"></span>
              teolysis. A def<span className="_ _1"></span>iciency is{' '}
            </div>
            <div className="t m0 xa h2 y172 ff1 fs0 fc0 sc0 ls0 ws0">
              associated with both<span className="_ _1"></span> lung and liv
              <span className="_ _1"></span>er disease. Alp
              <span className="_ _1"></span>
              ha-1-antitrypsin is also an<span className="_ _1"></span>{' '}
              acute-phase <span className="_ _1"></span>reactant pro
              <span className="_ _1"></span>
              tein and elev<span className="_ _1"></span>ated levels{' '}
            </div>
            <div className="t m0 xa h2 y173 ff1 fs0 fc0 sc0 ls0 ws0">
              are seen in and chron<span className="_ _1"></span>ic inflam
              <span className="_ _1"></span>matory disorders.
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xa h2 y198 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y199 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y19a ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y19b ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 x16 hc y19c ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x19 h2 y19d ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Hypochlorhydria<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x19 h2 y19e ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Liver dysfunction/He<span className="_ _1"></span>patic infec
                  <span className="_ _1"></span>tions{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x19 h2 y19f ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Neoplasm </span>
              </span>
            </div>
            <div className="t m0 xa h2 y1a0 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y1a1 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y1a2 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c h2 y1a3 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Digestive dysfunc<span className="_ _1"></span>tion (prim
                  <span className="_ _1"></span>ary inflamm
                  <span className="_ _1"></span>
                  ation or infla<span className="_ _1"></span>mm
                  <span className="_ _1"></span>ation secondary to HCI
                  <span className="_ _1"></span> insufficiency
                  <span className="_ _1"></span>){' '}
                </span>
              </span>
            </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf10" className="pf w0 h0" data-page-no="10">
          <div className="pc pc10 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">15 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x31 hc y7 ffc fs0 fc0 sc0 ls0 ws0"
              id="AGRATIO"
            >
              AG/Ratio{' '}
            </div>
            <div className="t m0 x1 hc y15f ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y17d ff1 fs0 fc0 sc0 ls0 ws0">
              The value of the A/G R<span className="_ _1"></span>atio{' '}
              <span className="_ _1"></span>is limited because o
              <span className="_ _1"></span>f the co
              <span className="_ _1"></span>
              untless num<span className="_ _1"></span>ber of variables
              <span className="_ _1"></span> in the componen
              <span className="_ _1"></span>
              ts compris<span className="_ _1"></span>ing total{' '}
            </div>
            <div className="t m0 xa h2 y17e ff1 fs0 fc0 sc0 ls0 ws0">
              globulin (alpha 1, a<span className="_ _1"></span>lpha 2, beta{' '}
              <span className="_ _1"></span>and gamm
              <span className="_ _1"></span>a). An abnorm
              <span className="_ _1"></span>al A/G ration, often ca
              <span className="_ _1"></span>lled a supp
              <span className="_ _1"></span>
              ressed, low o<span className="_ _1"></span>r reversed ratio,
              <span className="_ _1"></span> is{' '}
            </div>
            <div className="t m0 xa h2 y17f ff1 fs0 fc0 sc0 ls0 ws0">
              generally consider<span className="_ _1"></span>ed a refle
              <span className="_ _1"></span>ction of hepat
              <span className="_ _1"></span>
              ic dysfunctio<span className="_ _1"></span>n.{' '}
            </div>
            <div className="t m0 xa h2 y180 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y181 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y1a4 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y1a5 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls: Not consi
              <span className="_ _1"></span>dered significant{' '}
            </div>
            <div className="t m0 xa h2 y184 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y1a6 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y1a7 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y1a8 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Liver/biliary<span className="_ _1"></span> dysfunction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1a9 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Chronic renal disea<span className="_ _1"></span>se{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1aa ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Some ty<span className="_ _1"></span>pes of cancer{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1ab ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Sarcoidosis </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1ac ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Collagen disease<span className="_ _1"></span>s{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1ad ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Severe infect<span className="_ _1"></span>ions{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1ae ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Systemic inflamm<span className="_ _1"></span>ation{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1af ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Burns </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1b0 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Digestive inflam<span className="_ _1"></span>mation{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1 hc y1b1 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 hc y1b2 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x32 hc y1b3 ffc fs0 fc0 sc0 ls0 ws0"
              id="GLUCOSE"
            >
              Glucose{' '}
            </div>
            <div className="t m0 x1 hc y1b4 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y1b5 ff1 fs0 fc0 sc0 ls0 ws0">
              Glucose levels a<span className="_ _1"></span>re influenc
              <span className="_ _1"></span>ed by carbohy
              <span className="_ _1"></span>
              drate intake, <span className="_ _1"></span>stress, glandular{' '}
              <span className="_ _1"></span>and liver f
              <span className="_ _1"></span>
              unction.{' '}
            </div>
            <div className="t m0 xa h2 y1b6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y1b7 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y1b8 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y1b9 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls: Fasting glucose{' '}
            </div>
            <div className="t m0 xa h2 y1ba ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y173 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Acute stress respons<span className="_ _1"></span>e{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y174 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Diabetes: Trigly<span className="_ _1"></span>cerides incr
                  <span className="_ _1"></span>eased and often i
                  <span className="_ _1"></span>ncreased a
                  <span className="_ _1"></span>
                  bove the cholest<span className="_ _1"></span>erol level w
                  <span className="_ _1"></span>hen a Type I
                  <span className="_ _1"></span>V{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x33 h2 y1bb ff1 fs0 fc0 sc0 ls0 ws0">
              Hyperlipoprotei<span className="_ _1"></span>nemia is presen
              <span className="_ _1"></span>t (Metabolic Sy
              <span className="_ _1"></span>
              ndrome/Sy<span className="_ _1"></span>ndrome{' '}
              <span className="ff7">–</span>
              <span className="ls8">X)</span>{' '}
            </div>
            <div className="t m0 x20 h2 y19a ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Thiamine insuffi<span className="_ _1"></span>ciency{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1bc ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Dysinsulinism<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y1bd ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y1be ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y1bf ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y1c0 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Fasting hypog<span className="_ _1"></span>lycemia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1c1 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Sever liver diseas<span className="_ _1"></span>e{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1c2 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Hypothyroidism<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y1c3 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf11" className="pf w0 h0" data-page-no="11">
          <div className="pc pc11 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">16 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1e hc y7 ffc fs0 fc0 sc0 ls0 ws0" id="BUN">
              BUN{' '}
            </div>
            <div className="t m0 x1 hc y15f ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y17d ff1 fs0 fc0 sc0 ls0 ws0">
              BUN <span className="_"> </span>reflects{' '}
              <span className="_ _14"> </span>
              the <span className="_ _14"> </span>d
              <span className="_ _1"></span>
              ifference <span className="_"> </span>between{' '}
              <span className="_ _14"> </span>produ
              <span className="_ _1"></span>ction <span className="_"> </span>
              and <span className="_ _14"> </span>clearance{' '}
              <span className="_"> </span>of <span className="_ _14"> </span>
              urea. <span className="_"> </span>
              Increased <span className="_ _14"> </span>BUN{' '}
              <span className="_"> </span>
              may <span className="_"> </span>be{' '}
              <span className="_ _14"> </span>due{' '}
              <span className="_ _14"> </span>to <span className="_"> </span>
              increased{' '}
            </div>
            <div className="t m0 xa h2 y17e ff1 fs0 fc0 sc0 ls0 ws0">
              production <span className="_ _12"></span>or{' '}
              <span className="_ _12"></span>
              decreas<span className="_ _1"></span>ed{' '}
              <span className="_ _12"></span>
              excretion<span className="_ _1"></span>.{' '}
              <span className="_ _12"></span>
              Urea <span className="_ _12"></span>is{' '}
              <span className="_ _12"></span>the <span className="_ _12"></span>
              chief <span className="_ _0"></span>end{' '}
              <span className="_ _12"></span>product{' '}
              <span className="_ _12"></span>of <span className="_ _12"></span>
              protein <span className="_ _12"></span>
              metabolism<span className="_ _1"></span>{' '}
              <span className="_ _12"></span>
              and <span className="_ _12"></span>is{' '}
              <span className="_ _12"></span>
              formed <span className="_ _12"></span>almost{' '}
              <span className="_ _12"></span>
              entirely
              <span className="_ _1"></span> <span className="_ _12"></span>by{' '}
            </div>
            <div className="t m0 xa h2 y17f ff1 fs0 fc0 sc0 ls0 ws0">
              the liver <span className="ls9">th</span>roug
              <span className="_ _1"></span>h the urea c
              <span className="_ _1"></span>
              ycle. I<span className="_ _1"></span>t is removed alm
              <span className="_ _1"></span>
              ost entirely by
              <span className="_ _1"></span> the kidneys.{' '}
            </div>
            <div className="t m0 xa h2 y1c4 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y1c5 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y1c6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y1c7 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y1c8 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y1c9 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Chronic renal dy<span className="_ _1"></span>sfunction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1ca ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Renal hypertensi<span className="_ _1"></span>on{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y187 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Prescribed diuret<span className="_ _1"></span>ics{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1cb ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Dehydration </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1cc ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Cirrhosis of th<span className="_ _1"></span>e liver{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1cd ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Benign prostate hy<span className="_ _1"></span>pertrophy{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1ce ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Urinary obstructio<span className="_ _1"></span>n{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y1cf ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y1d0 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y1d1 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y1d2 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Liver failure </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1d3 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Protein malnutr<span className="_ _1"></span>ition{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1d4 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Celiac </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1d5 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y1d6 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x27 hc y1d7 ffc fs0 fc0 sc0 ls0 ws0"
              id="CREATININE"
            >
              Creatinine{' '}
            </div>
            <div className="t m0 x1 hc y1d8 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y1d9 ff1 fs0 fc0 sc0 ls0 ws0">
              Creatinine testing<span className="_ _1"></span> is used to
              <span className="_ _1"></span> diagnose im
              <span className="_ _1"></span>
              paired renal fu<span className="_ _1"></span>nction. I
              <span className="_ _1"></span>t is a waste product
              <span className="_ _1"></span> formed by sponta
              <span className="_ _1"></span>neous decom
              <span className="_ _1"></span>
              position{' '}
            </div>
            <div className="t m0 xa h2 y1da ff1 fs0 fc0 sc0 ls0 ws0">
              of creatine which i<span className="_ _1"></span>s an intrinsic
              subs
              <span className="_ _1"></span>tance required in t
              <span className="_ _1"></span>he contraction o
              <span className="_ _1"></span>f muscle; th
              <span className="_ _1"></span>
              erefore patie
              <span className="_ _1"></span>nts with incre
              <span className="_ _1"></span>
              ased muscle{' '}
            </div>
            <div className="t m0 xa h2 y1db ff1 fs0 fc0 sc0 ls0 ws0">
              mass may<span className="_ _1"></span> have slightly hig
              <span className="_ _1"></span>her creatinine l
              <span className="_ _1"></span>
              evels and patients w<span className="_ _1"></span>ith decrea
              <span className="_ _1"></span>sed muscle m
              <span className="_ _1"></span>
              ass will have slig<span className="_ _1"></span>htly lower lev
              <span className="_ _1"></span>els of{' '}
            </div>
            <div className="t m0 xa h2 y1dc ff1 fs0 fc0 sc0 ls0 ws0">
              creatinine. Thus, se<span className="_ _1"></span>rum cre
              <span className="_ _1"></span>atinine is not as{' '}
              <span className="_ _1"></span>
              sensitive an indicator <span className="_ _1"></span>of early rena
              <span className="_ _1"></span>l disease as BU
              <span className="_ _1"></span>N which incr
              <span className="_ _1"></span>
              eases more{' '}
            </div>
            <div className="t m0 xa h2 y1dd ff1 fs0 fc0 sc0 ls0 ws0">
              rapidly than serum<span className="_ _1"></span> creatini
              <span className="_ _1"></span>ne in the early
              <span className="_ _1"></span> stages of ren
              <span className="_ _1"></span>
              al disease.{' '}
            </div>
            <div className="t m0 xa h2 y1de ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y1df ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y4d ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y1e0 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y1e1 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y1e2 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Chronic renal dy<span className="_ _1"></span>sfunction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1e3 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Benign prostate hy<span className="_ _1"></span>pertrophy{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y1e4 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y1e5 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y1e6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y1e7 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Serum creatinine <span className="_ _1"></span>is normally
                  <span className="_ _1"></span> decreased with c
                  <span className="_ _1"></span>hildren, pat
                  <span className="_ _1"></span>
                  ients who hav<span className="_ _1"></span>e less than av
                  <span className="_ _1"></span>erage muscle m
                  <span className="_ _1"></span>
                  ass and{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x33 h2 y1e8 ff1 fs0 fc0 sc0 ls0 ws0">
              geriatric patient<span className="_ _1"></span>s with reduced m
              <span className="_ _1"></span>uscle mass{' '}
            </div>
            <div className="t m0 x20 h2 y1e9 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Muscle wasting<span className="_ _1"></span> diseases{' '}
                </span>
              </span>
            </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf12" className="pf w0 h0" data-page-no="12">
          <div className="pc pc12 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">17 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 hc y7 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x34 hc y15f ffc fs0 fc0 sc0 ls0 ws0" id="BUCR">
              BUN/Creatinine Ra<span className="_ _1"></span>tio{' '}
            </div>
            <div className="t m0 x27 hc yca ffc fs0 fc0 sc0 ls0 ws0">
              BU/CR RA{' '}
            </div>
            <div className="t m0 x1 hc y160 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y1ea ff1 fs0 fc0 sc0 ls0 ws0">
              The BUN/Creat<span className="_ _1"></span>inine ratio is useful
              wh
              <span className="_ _1"></span>en assessi
              <span className="_ _1"></span>ng patients with chron
              <span className="_ _1"></span>ic renal dys
              <span className="_ _1"></span>function; how
              <span className="_ _1"></span>
              ever, the ratio<span className="_ _1"></span> may be{' '}
            </div>
            <div className="t m0 xa h2 y1c4 ff1 fs0 fc0 sc0 ls0 ws0">
              skewed when a h<span className="_ _1"></span>igh normal BU
              <span className="_ _1"></span>N is present w
              <span className="_ _1"></span>
              ith a low<span className="_ _1"></span> normal creatini
              <span className="_ _1"></span>ne or a hig
              <span className="_ _1"></span>h normal creatinine
              <span className="_ _1"></span> with a low norm
              <span className="_ _1"></span>al{' '}
            </div>
            <div className="t m0 xa h2 y1eb ff1 fs0 fc0 sc0 ls0 ws0">
              BUN. Therefore, <span className="_ _1"></span>the ratio is g
              <span className="_ _1"></span>enerally not as v
              <span className="_ _1"></span>
              aluable diag<span className="_ _1"></span>nostically
              <span className="_ _1"></span> as the quantitative BUN and crea
              <span className="_ _1"></span>tinine.{' '}
            </div>
            <div className="t m0 xa h2 y1a4 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y13c ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y1ec ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y1ed ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y1ee ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y1ef ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Congestive heart <span className="_ _1"></span>failure{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1f0 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Intestinal bleed<span className="_ _1"></span>ing{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1f1 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Shock </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1f2 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Hypotension </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1f3 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Dehydration </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1f4 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Renal disease<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y1f5 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y1f6 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y68 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y1f7 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Low protein diets<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1f8 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Malnutrition<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa hc y1f9 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y1b3 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x22 hc y1fa ffc fs0 fc0 sc0 ls0 ws0"
              id="CALCIUM"
            >
              Calcium{' '}
            </div>
            <div className="t m0 x1 hc y1fb ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y1fc ff1 fs0 fc0 sc0 ls0 ws0">
              Calcium is abso<span className="_ _1"></span>rbed from
              <span className="_ _1"></span> the upper part of the sm
              <span className="_ _1"></span>all intestine.{' '}
              <span className="_ _1"></span>
              The amount of abs<span className="_ _1"></span>orption depen
              <span className="_ _1"></span>ds upon the a
              <span className="_ _1"></span>
              cidity of the{' '}
            </div>
            <div className="t m0 xa h2 y1fd ff1 fs0 fc0 sc0 ls0 ws0">
              intestinal contents<span className="_ _1"></span> and the a
              <span className="_ _1"></span>mount of phosphat
              <span className="_ _1"></span>e present. C
              <span className="_ _1"></span>
              alcium relates to b<span className="_ _1"></span>one metabo
              <span className="_ _1"></span>lism, the drawing
              <span className="_ _1"></span> of fats thr
              <span className="_ _1"></span>ough{' '}
            </div>
            <div className="t m0 xa h2 y1fe ff1 fs0 fc0 sc0 ls0 ws0">
              the intestinal wa<span className="_ _1"></span>ll and prote
              <span className="_ _1"></span>in absorption. The am
              <span className="_ _1"></span>ount of prote
              <span className="_ _1"></span>
              in in the blood <span className="_ _1"></span>affects the cal
              <span className="_ _1"></span>cium level. Ca
              <span className="_ _1"></span>
              lcium<span className="_ _1"></span> provides{' '}
            </div>
            <div className="t m0 xa h2 y1ff ff1 fs0 fc0 sc0 ls0 ws0">
              a mobilizing fac<span className="_ _1"></span>tor in trau
              <span className="_ _1"></span>ma, infections, s
              <span className="_ _1"></span>
              tress and is<span className="_ _1"></span> used rapidly fo
              <span className="_ _1"></span>r repair of ti
              <span className="_ _1"></span>
              ssue in con<span className="_ _1"></span>junction with vi
              <span className="_ _1"></span>tamin A,{' '}
            </div>
            <div className="t m0 xa h2 y200 ff1 fs0 fc0 sc0 ls0 ws0">
              vitamin C, m<span className="_ _1"></span>agnesium, phosphoru
              <span className="_ _1"></span>s, iodine an
              <span className="_ _1"></span>d unsaturated fatty ac
              <span className="_ _1"></span>ids. Calcium
              <span className="_ _1"></span> exists in the io
              <span className="_ _1"></span>
              nized form<span className="_ _1"></span> (about 55{' '}
            </div>
            <div className="t m0 xa h2 y201 ff1 fs0 fc0 sc0 ls0 ws0">
              percent) and the non-<span className="_ _1"></span>diffusible form
              <span className="_ _1"></span> (about 45 per
              <span className="_ _1"></span>
              cent), which <span className="_ _1"></span>is bound to prote
              <span className="_ _1"></span>in (chiefly album
              <span className="_ _1"></span>
              in). Note<span className="_ _1"></span>: in the{' '}
            </div>
            <div className="t m0 xa h2 y1df ff1 fs0 fc0 sc0 ls0 ws0">
              diagnosis of hy<span className="_ _1"></span>percalcem
              <span className="_ _1"></span>ia, serum calcium
              <span className="_ _1"></span> should be m
              <span className="_ _1"></span>
              easured at least thre
              <span className="_ _1"></span>e times because serum
              <span className="_ _1"></span> calcium can chang
              <span className="_ _1"></span>e{' '}
            </div>
            <div className="t m0 xa h2 y202 ff1 fs0 fc0 sc0 ls0 ws0">
              significantly<span className="_ _1"></span> when the albu
              <span className="_ _1"></span>min is incre
              <span className="_ _1"></span>
              ased or decreas<span className="_ _1"></span>ed.{' '}
            </div>
            <div className="t m0 xa h2 y203 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y4f ff1 fs0 fc0 sc0 ls0 ws0">
              Interpretation o<span className="_ _1"></span>f Serum C
              <span className="_ _1"></span>alcium{' '}
            </div>
            <div className="t m0 xa h2 y204 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y205 ff1 fs0 fc0 sc0 ls0 ws0">
              Total serum<span className="_ _1"></span> calcium levels{' '}
              <span className="_ _1"></span>in patients wi
              <span className="_ _1"></span>
              th low or high serum<span className="_ _1"></span> albumin l
              <span className="_ _1"></span>evels may
              <span className="_ _1"></span> not reflect the levels o
              <span className="_ _1"></span>f ionized (free){' '}
            </div>
            <div className="t m0 xa h2 y206 ff1 fs0 fc0 sc0 ls0 ws0">
              calcium levels. Fo<span className="_ _1"></span>r each 1g/dL de
              <span className="_ _1"></span>crease in serum
              <span className="_ _1"></span> album<span className="_ _1"></span>
              in level, total serum
              <span className="_ _1"></span> calcium
              <span className="_ _1"></span> will be reduced by
              <span className="_ _1"></span> 0.8 mg
              <span className="_ _1"></span>
              /dL. This{' '}
            </div>
            <div className="t m0 xa h2 y207 ff1 fs0 fc0 sc0 ls0 ws0">
              decrease will no<span className="_ _1"></span>t affect the{' '}
              <span className="_ _1"></span>ionized calcium
              <span className="_ _1"></span> levels. I
              <span className="_ _1"></span>n patients with hy
              <span className="_ _1"></span>poalbum
              <span className="_ _1"></span>inemia or hyperalbum
              <span className="_ _1"></span>inemia, serum{' '}
            </div>
            <div className="t m0 xa h2 y113 ff1 fs0 fc0 sc0 ls0 ws0">
              calcium levels <span className="_ _1"></span>should be correcte
              <span className="_ _1"></span>d by using the fo
              <span className="_ _1"></span>
              llowing<span className="_ _1"></span> formula:{' '}
            </div>
            <div className="t m0 xa h2 yfd ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x35 ha ya1 ff8 fs0 fc0 sc0 ls0 ws0">
              Corrected calciu<span className="_ _1"></span>m = [0.8 x
              <span className="_ _1"></span> (4.0 <span className="ffb">–</span>{' '}
              <span className="ffb">
                patient’s albumin<span className="_ _1"></span>)] + serum ca
                <span className="_ _1"></span>lcium
                <span className="ff8"> </span>
              </span>
            </div>
            <div className="t m0 x1 ha y15e ff8 fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf13" className="pf w0 h0" data-page-no="13">
          <div className="pc pc13 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">18 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x36 hc y7 ffc fs0 fc0 sc0 ls0 ws0">
              Calcium{' '}
              <span className="ffa">
                (con’t)<span className="_ _1"></span>
                <span className="ffc"> </span>
              </span>
            </div>
            <div className="t m0 xa h2 y8 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y33 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y34 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y35 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y7f ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Parathyroid hy<span className="_ _1"></span>perfunction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y80 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Cancer, </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y81 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Hypervitam<span className="_ _1"></span>inosis D{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y82 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Sarcoidosis </span>
              </span>
            </div>
            <div className="t m0 xa h2 y83 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y84 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y85 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 ye ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Parathyroid hy<span className="_ _1"></span>pofunction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 yf ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Digestive dysfunc<span className="_ _1"></span>tion (hypochlo
                  <span className="_ _1"></span>rhydria){' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y10 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Low normal to<span className="_ _1"></span>tal protein or
                  <span className="_ _1"></span> albumin: Slig
                  <span className="_ _1"></span>
                  ht decreases <span className="_ _1"></span>of calcium
                  <span className="_ _1"></span> are comm
                  <span className="_ _1"></span>
                  on with low total prot<span className="_ _1"></span>ein or{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x33 h2 y11 ff1 fs0 fc0 sc0 ls0 ws0">
              albumin. Serum<span className="_ _1"></span> calcium
              <span className="_ _1"></span> measures total ca
              <span className="_ _1"></span>
              lcium, about<span className="_ _1"></span> half of which{' '}
              <span className="_ _1"></span>is bound to plasm
              <span className="_ _1"></span>a pr<span className="_ _0"></span>
              oteins. <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 x20 h2 y12 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Vitamin D insuf<span className="_ _1"></span>ficiency{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 yff ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Protein malnutr<span className="_ _1"></span>ition{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y100 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Osteoporosis </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y101 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Pancreatitis </span>
              </span>
            </div>
            <div className="t m0 xa h2 y116 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x37 hc y117 ffc fs0 fc0 sc0 ls0 ws0"
              id="SODIUM"
            >
              Serum Sodium{' '}
            </div>
            <div className="t m0 x1 hc y104 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y105 ff1 fs0 fc0 sc0 ls0 ws0">
              Sodium <span className="_ _0"></span>is{' '}
              <span className="_ _0"></span>t<span className="_ _0"></span>he{' '}
              <span className="_ _0"></span>most <span className="_ _0"></span>
              abundant <span className="_ _0"></span>
              cation <span className="_ _0"></span>in{' '}
              <span className="_ _0"></span>the <span className="_ _12"></span>
              extra-cellular <span className="_ _0"></span>
              fluid. <span className="_ _0"></span>It{' '}
              <span className="_ _0"></span>is <span className="_ _12"></span>
              most <span className="_ _0"></span>
              important <span className="_ _0"></span>in{' '}
              <span className="_ _0"></span>
              osmotic <span className="_ _0"></span>regulation{' '}
              <span className="_ _0"></span>of
              <span className="_ _0"></span> <span className="_ _0"></span>extra
              <span className="_ _0"></span>-cellular
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xa h2 y106 ff1 fs0 fc0 sc0 ls0 ws0">
              fluid balance, <span className="_ _0"></span>acid-base balance{' '}
              <span className="_ _0"></span>and renal,{' '}
              <span className="_ _0"></span>
              cardiac, and <span className="_ _0"></span>adrenal function.{' '}
              <span className="_ _0"></span>Its pr<span className="_ _0"></span>
              imary function i<span className="_ _0"></span>n the{' '}
              <span className="_ _0"></span>
              body is <span className="_ _0"></span>to{' '}
              <span className="_ _0"></span>
              chemically<span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xa h2 y107 ff1 fs0 fc0 sc0 ls0 ws0">
              maintain <span className="_ _15"></span>osmotic{' '}
              <span className="_ _15"></span>pressure
              <span className="_ _1"></span> <span className="_ _15"></span>and{' '}
              <span className="_ _16"></span>
              acid-base <span className="_ _15"></span>balance{' '}
              <span className="_ _15"></span>and <span className="_ _15"></span>
              to <span className="_ _15"></span>transmit{' '}
              <span className="_ _15"></span>
              nerve <span className="_ _15"></span>
              impulses. <span className="_ _15"></span>{' '}
              <span className="_ _15"></span>
              Aldosterone <span className="_ _15"></span>causes{' '}
              <span className="_ _15"></span>conserv
              <span className="_ _1"></span>ation{' '}
              <span className="_ _15"></span>of{' '}
            </div>
            <div className="t m0 xa h2 y108 ff1 fs0 fc0 sc0 ls0 ws0">
              sodium. ADH<span className="_ _1"></span> controls the{' '}
              <span className="_ _1"></span>reabsorption of
              <span className="_ _1"></span> water in the
              <span className="_ _1"></span> kidneys, affect
              <span className="_ _1"></span>ing sodium
              <span className="_ _1"></span> levels by dilution an
              <span className="_ _1"></span>d concentra
              <span className="_ _1"></span>tion.{' '}
            </div>
            <div className="t m0 xa hc y208 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y10a ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y10b ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y10c ffc fs0 fc0 sc0 lsa ws0">
              In
              <span className="ls0">
                creased leve<span className="_ _1"></span>ls:{' '}
              </span>
            </div>
            <div className="t m0 xa h2 y10d ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y76 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Chronic renal dy<span className="_ _1"></span>sfunction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y10e ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Dehydration </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y10f ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Adrenal cortical hy<span className="_ _1"></span>perfunction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y110 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y111 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y112 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y113 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Adrenal cortical hy<span className="_ _1"></span>pofuncti
                  <span className="_ _1"></span>on{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y114 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Diarrhea </span>
              </span>
            </div>
            <div className="t m0 xa hc y115 ffc fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf14" className="pf w0 h0" data-page-no="14">
          <div className="pc pc14 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">19 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x38 hc y7 ffc fs0 fc0 sc0 ls0 ws0"
              id="POTASSIUM"
            >
              Potassium{' '}
            </div>
            <div className="t m0 xa h2 y8 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y33 ff1 fs0 fc0 sc0 ls0 ws0">
              Potassium <span className="_ _17"></span>is{' '}
              <span className="_ _17"></span>
              the <span className="_ _15"></span>major{' '}
              <span className="_ _17"></span>
              cation <span className="_ _15"></span>found{' '}
              <span className="_ _17"></span>
              in <span className="_ _15"></span>the{' '}
              <span className="_ _17"></span>
              intracellular <span className="_ _17"></span>fluid.{' '}
              <span className="_ _17"></span>Only{' '}
              <span className="_ _17"></span>a <span className="_ _15"></span>
              small <span className="_ _17"></span>part{' '}
              <span className="_ _17"></span>of <span className="_ _15"></span>
              the <span className="_ _17"></span>total{' '}
              <span className="_ _15"></span>body{' '}
              <span className="_ _17"></span>potassium
              <span className="_ _1"></span> <span className="_ _17"></span>
              stores <span className="_ _15"></span>are{' '}
            </div>
            <div className="t m0 xa h2 y34 ff1 fs0 fc0 sc0 ls0 ws0">
              contained <span className="_ _17"></span>in{' '}
              <span className="_ _12"></span>
              the <span className="_ _17"></span>serum/plasm
              <span className="_ _1"></span>
              a. <span className="_ _17"></span>Potassium
              <span className="_ _1"></span> <span className="_ _17"></span>is{' '}
              <span className="_ _17"></span>also{' '}
              <span className="_ _17"></span>e<span className="_ _1"></span>
              ssential <span className="_ _12"></span>t
              <span className="_ _0"></span>o <span className="_ _17"></span>m
              <span className="_ _1"></span>aintenance{' '}
              <span className="_ _17"></span>o<span className="_ _1"></span>f{' '}
              <span className="_ _17"></span>pH <span className="_ _12"></span>
              (bl
              <span className="_ _0"></span>ood <span className="_ _17"></span>
              and <span className="_ _12"></span>urine){' '}
              <span className="_ _17"></span>and <span className="_ _17"></span>
              m<span className="_ _1"></span>aintenance{' '}
              <span className="_ _12"></span>of{' '}
            </div>
            <div className="t m0 xa h2 y35 ff1 fs0 fc0 sc0 ls0 ws0">
              osmotic <span className="_ _17"></span>press
              <span className="_ _1"></span>
              ure. <span className="_ _17"></span>Potass
              <span className="_ _1"></span>
              ium
              <span className="_ _1"></span> <span className="_ _17"></span>
              should <span className="_ _17"></span>always{' '}
              <span className="_ _12"></span>be <span className="_ _17"></span>
              viewed <span className="_ _17"></span>in{' '}
              <span className="_ _17"></span>relation{' '}
              <span className="_ _12"></span>to <span className="_ _17"></span>
              the <span className="_ _17"></span>other{' '}
              <span className="_ _17"></span>e<span className="_ _1"></span>
              lectrolytes. <span className="_ _17"></span>Intracel
              <span className="_ _1"></span>lular{' '}
              <span className="_ _17"></span>co<span className="_ _1"></span>
              ncentration <span className="_ _17"></span>i
              <span className="_ _1"></span>s{' '}
            </div>
            <div className="t m0 xa h2 y7f ff1 fs0 fc0 sc0 ls0 ws0">
              about <span className="_ _0"></span>150 mEq/L and{' '}
              <span className="_ _0"></span>serum concentration{' '}
              <span className="_ _0"></span>is <span className="_ _0"></span>
              about 4 mEq/L. <span className="_ _0"></span> T
              <span className="_ _0"></span>his <span className="_ _0"></span>
              ratio is <span className="_ _0"></span>a{' '}
              <span className="_ _0"></span>determinant factor i
              <span className="_ _0"></span>n maintaining
              <span className="_ _1"></span> <span className="_ _0"></span>
              membrane{' '}
            </div>
            <div className="t m0 xa h2 y80 ff1 fs0 fc0 sc0 ls0 ws0">
              electrical potent<span className="_ _1"></span>ial, which is im
              <span className="_ _1"></span>portant for n
              <span className="_ _1"></span>
              euromuscular tissue.{' '}
            </div>
            <div className="t m0 xa h2 y81 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x39 hc y82 ffa fs0 fc0 sc0 ls0 ws0">
              Potassium (con’t)<span className="_ _1"></span>
              <span className="ffc"> </span>
            </div>
            <div className="t m0 x1 hc y83 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y84 ff1 fs0 fc0 sc0 ls0 ws0">
              Symptoms of hy<span className="_ _1"></span>perkalemia:
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xa h2 y85 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x12 h2 y209 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">Nausea </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y20a ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">Vomiting </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y20b ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  Intestinal colic<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y20c ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">Diarrhea </span>
              </span>
            </div>
            <div className="t m0 xa h2 y123 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y8b ff1 fs0 fc0 sc0 ls0 ws0">
              Symptoms of hy<span className="_ _1"></span>pokalem
              <span className="_ _1"></span>ia:{' '}
            </div>
            <div className="t m0 xa h2 y124 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x12 h2 y20d ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  Decreased con<span className="_ _1"></span>tractibility of
                  <span className="_ _1"></span> smooth, skeletal{' '}
                  <span className="_ _1"></span>and cardia
                  <span className="_ _1"></span> muscles{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y20e ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">Weakness </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y20f ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">Paralysis </span>
              </span>
            </div>
            <div className="t m0 xa h2 y210 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y211 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y212 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y213 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y214 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y215 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Adrenal cortical hy<span className="_ _1"></span>pofunction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y216 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Renal dysfunc<span className="_ _1"></span>tion{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y217 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y218 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y219 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y21a ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Diarrhea </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y21b ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Adrenal cortical hy<span className="_ _1"></span>perfunction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y21c ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Drug diuretics </span>
              </span>
            </div>
            <div className="t m0 xa h2 y21d ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y21e ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 hc y21f ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 hc y220 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 hc y221 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 hc y222 ffc fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf15" className="pf w0 h0" data-page-no="15">
          <div className="pc pc15 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">20 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x22 hc y7 ffc fs0 fc0 sc0 ls0 ws0"
              id="CHLORIDE"
            >
              Chloride{' '}
            </div>
            <div className="t m0 x1 hc y8 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y33 ff1 fs0 fc0 sc0 ls0 ws0">
              Chloride is the pri<span className="_ _1"></span>nciple ani
              <span className="_ _1"></span>on found in the{' '}
              <span className="_ _1"></span>
              serum. The chlo<span className="_ _1"></span>ride level along
              <span className="_ _1"></span> with so
              <span className="_ _1"></span>dium, potassium
              <span className="_ _1"></span> and CO2,{' '}
              <span className="_ _1"></span>are{' '}
            </div>
            <div className="t m0 xa h2 y34 ff1 fs0 fc0 sc0 ls0 ws0">
              important in evalua<span className="_ _1"></span>ting acid-
              <span className="_ _1"></span>base relationships,
              <span className="_ _1"></span> state of hy
              <span className="_ _1"></span>
              dration, as well <span className="_ _1"></span>as adrenal and r
              <span className="_ _1"></span>enal function. Ch
              <span className="_ _1"></span>
              loride{' '}
            </div>
            <div className="t m0 xa h2 y35 ff1 fs0 fc0 sc0 ls0 ws0">
              concentration usua<span className="_ _1"></span>lly varies inv
              <span className="_ _1"></span>ersely with C
              <span className="_ _1"></span>
              O2; hence, incre<span className="_ _1"></span>ased chlor
              <span className="_ _1"></span>ide is comm
              <span className="_ _1"></span>
              only associated with <span className="_ _1"></span>renal or sy
              <span className="_ _1"></span>stemic{' '}
            </div>
            <div className="t m0 xa h2 y7f ff1 fs0 fc0 sc0 ls0 ws0">
              acidosis and dec<span className="_ _1"></span>reased chloride with
              sy
              <span className="_ _1"></span>stemic alk
              <span className="_ _1"></span>
              alosis. Chloride is <span className="_ _1"></span>one of the e
              <span className="_ _1"></span>lements requir
              <span className="_ _1"></span>
              ed for the prod<span className="_ _1"></span>uction of{' '}
            </div>
            <div className="t m0 xa h2 y80 ff1 fs0 fc0 sc0 ls0 ws0">
              HCI by<span className="_ _1"></span> the chief cells of the stom
              <span className="_ _1"></span>ach.{' '}
            </div>
            <div className="t m0 xa h2 y81 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y82 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y83 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y84 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y85 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 ye ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Renal dysfunc<span className="_ _1"></span>tion{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1f h2 yf ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Dehydration </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y10 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y11 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y12 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 yff ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Hypochlorhydria<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y100 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1e hc y101 ffc fs0 fc0 sc0 ls0 ws0" id="CO2">
              CO/2{' '}
            </div>
            <div className="t m0 x1 hc y116 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y117 ff1 fs0 fc0 sc0 ls0 ws0">
              CO2 is the amount o<span className="_ _1"></span>f base bound a
              <span className="_ _1"></span>s bicarbonate{' '}
              <span className="_ _1"></span>
              in the blood tha<span className="_ _1"></span>t is available{' '}
              <span className="_ _1"></span>for the neutr
              <span className="_ _1"></span>
              alization of fi<span className="_ _1"></span>xed acids such as{' '}
            </div>
            <div className="t m0 xa h2 y104 ff1 fs0 fc0 sc0 ls0 ws0">
              lactic acid and hy<span className="_ _1"></span>drochloric acid.
              <span className="_ _1"></span> CO2 refers on
              <span className="_ _1"></span>
              ly to base boun<span className="_ _1"></span>d as bicarbonat
              <span className="_ _1"></span>e and not the tot
              <span className="_ _1"></span>
              al base of the <span className="_ _1"></span>blood. CO2{' '}
            </div>
            <div className="t m0 xa h2 y105 ff1 fs0 fc0 sc0 ls0 ws0">
              represents the re<span className="_ _1"></span>serve of alk
              <span className="_ _1"></span>ali readily av
              <span className="_ _1"></span>
              ailable for the neu<span className="_ _1"></span>tralization of{' '}
              <span className="_ _1"></span>acids. Condi
              <span className="_ _1"></span>
              tions involv<span className="_ _1"></span>ing primary
              <span className="_ _1"></span> CO2 excess{' '}
            </div>
            <div className="t m0 xa h2 y106 ff1 fs0 fc0 sc0 ls0 ws0">
              and deficit cannot <span className="_ _1"></span>be deter
              <span className="_ _1"></span>mined by
              <span className="_ _1"></span> CO2 alone, serum
              <span className="_ _1"></span> chloride must be ch
              <span className="_ _1"></span>ecked for invers
              <span className="_ _1"></span>e values when{' '}
              <span className="_ _1"></span>
              metabolic{' '}
            </div>
            <div className="t m0 xa h2 y107 ff1 fs0 fc0 sc0 ls0 ws0">
              alkalosis is suspe<span className="_ _1"></span>cted, howev
              <span className="_ _1"></span>er, irrespective of the ch
              <span className="_ _1"></span>loride value wh
              <span className="_ _1"></span>
              en the CO2 i<span className="_ _1"></span>s 25 or less, the p
              <span className="_ _1"></span>robability
              <span className="_ _1"></span> of systemic{' '}
            </div>
            <div className="t m0 xa h2 y108 ff1 fs0 fc0 sc0 ls0 ws0">
              acidosis is very<span className="_ _1"></span> high.{' '}
            </div>
            <div className="t m0 xa h2 y109 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y10a ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y10b ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y10c ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y10d ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y76 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Metabolic alkalo<span className="_ _1"></span>sis{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y10e ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Vomiting </span>
              </span>
            </div>
            <div className="t m0 xa h2 y10f ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y110 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y111 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y112 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Thiamine deficie<span className="_ _1"></span>ncy: Anion g
                  <span className="_ _1"></span>ap is generally
                  <span className="_ _1"></span> increased{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y113 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Diabetes </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y114 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Metabolic acidos<span className="_ _1"></span>is{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y115 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf16" className="pf w0 h0" data-page-no="16">
          <div className="pc pc16 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">21 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y7 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y32 ffd fs0 fc0 sc0 ls0 ws0">
              Additional Metab<span className="_ _1"></span>olic Tests{' '}
            </div>
            <div className="t m0 xa hc y223 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1e hc y34 ffc fs0 fc0 sc0 ls4 ws0" id="GGT">
              GGT<span className="ls0"> </span>
            </div>
            <div className="t m0 x3a hc y35 ffc fs0 fc0 sc0 ls0 ws0">
              Gamma-Glutamyl Tr<span className="_ _1"></span>anspeptid
              <span className="_ _1"></span>ase{' '}
            </div>
            <div className="t m0 x1 hc y7f ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y80 ff1 fs0 fc0 sc0 ls0 ws0">
              GGTP is a m<span className="_ _1"></span>ore sensitive and spe
              <span className="_ _1"></span>cific indicato
              <span className="_ _1"></span>r of liver dy
              <span className="_ _1"></span>sfunction than s
              <span className="_ _1"></span>erum alk
              <span className="_ _1"></span>aline phosphatase{' '}
              <span className="_ _1"></span>(ALP) and in som
              <span className="_ _1"></span>e{' '}
            </div>
            <div className="t m0 xa h2 y81 ff1 fs0 fc0 sc0 ls0 ws0">
              conditions than SGP<span className="_ _1"></span>T/ALT. it{' '}
              <span className="_ _1"></span>is increased{' '}
              <span className="_ _1"></span>
              in all common form<span className="_ _1"></span>s of liver/biliary
              dy
              <span className="_ _1"></span>sfunction an
              <span className="_ _1"></span>d is generally
              <span className="_ _1"></span> increased{' '}
            </div>
            <div className="t m0 xa h2 y82 ff1 fs0 fc0 sc0 ls0 ws0">
              above SGPT/ALT <span className="_ _1"></span>and SGOT/AS
              <span className="_ _1"></span>T with biliary
              <span className="_ _1"></span> tree problem
              <span className="_ _1"></span>s (gall bladde
              <span className="_ _1"></span>r, comm
              <span className="_ _1"></span>on bile duct, and pancrea
              <span className="_ _1"></span>s), obstruc
              <span className="_ _1"></span>tive{' '}
            </div>
            <div className="t m0 xa h2 y83 ff1 fs0 fc0 sc0 ls0 ws0">
              disease and alcohol<span className="_ _1"></span>ism.{' '}
            </div>
            <div className="t m0 xa h2 y84 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y85 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 ye ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc yf ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y10 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y11 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Biliary obstruct<span className="_ _1"></span>ion{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y12 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Alcoholism </span>
              </span>
            </div>
            <div className="t m0 x20 h2 yff ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Bile duct and gal<span className="_ _1"></span>l bladder i
                  <span className="_ _1"></span>nflamm
                  <span className="_ _1"></span>
                  ation/Biliary insufficien<span className="_ _1"></span>
                  cy/Biliary
                  <span className="_ _1"></span> Stasis{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y100 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Acute and chron<span className="_ _1"></span>ic pancreat
                  <span className="_ _1"></span>itis/Pancreati
                  <span className="_ _1"></span>c insufficiency{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y101 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y116 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y117 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 y104 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Vitamin B6 anem<span className="_ _1"></span>ia: generally dec
                  <span className="_ _1"></span>reased below 10{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y105 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y106 ff1 fs0 fc0 sc0 ls0 ws0">
              *Increased GG<span className="_ _1"></span>TP with an increased{' '}
              <span className="_ _1"></span>serum alk
              <span className="_ _1"></span>aline phosphatas
              <span className="_ _1"></span>e (ALP) indic
              <span className="_ _1"></span>ates a probabl
              <span className="_ _1"></span>e liver/biliary
              <span className="_ _1"></span> problem
              <span className="_ _1"></span>. A{' '}
            </div>
            <div className="t m0 xa h2 y107 ff1 fs0 fc0 sc0 ls0 ws0">
              normal GGTP wi<span className="_ _1"></span>th an incre
              <span className="_ _1"></span>ased serum A
              <span className="_ _1"></span>LP indicates a
              <span className="_ _1"></span> probable bone p
              <span className="_ _1"></span>roblem.{' '}
            </div>
            <div className="t m0 xa h2 y108 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 hc y109 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x23 hc y10a ffc fs0 fc0 sc0 ls0 ws0">
              Hemoglobin A1c{' '}
            </div>
            <div className="t m0 x1 hc y10b ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y10c ff1 fs0 fc0 sc0 ls0 ws0">
              Glycated hem<span className="_ _1"></span>oglobins are an i
              <span className="_ _1"></span>rreversible gluc
              <span className="_ _1"></span>
              ose-protein bond whi<span className="_ _1"></span>ch extends
              throug
              <span className="_ _1"></span>h the life of the red
              <span className="_ _1"></span> bloo<span className="_ _1"></span>d
              cell (90-
            </div>
            <div className="t m0 xa h2 y10d ff1 fs0 fc0 sc0 ls0 ws0">
              120 days). Glycated <span className="_ _1"></span>hemoglobin v
              <span className="_ _1"></span>alues are used to
              <span className="_ _1"></span> assess long-
              <span className="_ _1"></span>
              term glucose control in
              <span className="_ _1"></span> diabetes, espe
              <span className="_ _1"></span>
              cially patients w<span className="_ _1"></span>ith{' '}
            </div>
            <div className="t m0 xa h2 y76 ff7 fs0 fc0 sc0 ls0 ws0">
              insulin dependan<span className="_ _1"></span>t diabetes{' '}
              <span className="_ _1"></span>who‟s urinary
              <span className="_ _1"></span> and fasting b
              <span className="_ _1"></span>lood glucose{' '}
              <span className="_ _1"></span>levels vary sig
              <span className="_ _1"></span>
              nifican
              <span className="ff1">
                tly from day to day<span className="_ _1"></span>.{' '}
              </span>
            </div>
            <div className="t m0 xa h2 y10e ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y10f ffc fs0 fc0 sc0 ls0 ws0">
              Note:
              <span className="ff1">
                {' '}
                As the RBC<span className="_ _1"></span> circulates, som
                <span className="_ _1"></span>e of the gluco
                <span className="_ _1"></span>
                se from the b<span className="_ _1"></span>lood com
                <span className="_ _1"></span>bines with hem
                <span className="_ _1"></span>
                oglobin to form<span className="_ _1"></span> glycohem
                <span className="_ _1"></span>oglobin.{' '}
              </span>
            </div>
            <div className="t m0 xa h2 y110 ff1 fs0 fc0 sc0 ls0 ws0">
              The amount of gly<span className="_ _1"></span>cosolated hem
              <span className="_ _1"></span>og<span className="_ _1"></span>
              lobin found in the RBC<span className="_ _1"></span> is dependent
              upon th
              <span className="_ _1"></span>e am<span className="_ _1"></span>
              ount available in the b<span className="_ _1"></span>lood.{' '}
            </div>
            <div className="t m0 xa h2 y111 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y112 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y113 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y114 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 hc y115 ffc fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf17" className="pf w0 h0" data-page-no="17">
          <div className="pc pc17 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">22 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x3b hc y7 ffc fs0 fc0 sc0 ls0 ws0">
              Hemoglobin A1c{' '}
              <span className="ffa">
                (co<span className="_ _1"></span>n’t)
                <span className="ffc"> </span>
              </span>
            </div>
            <div className="t m0 xa h2 y8 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y33 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y34 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y35 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y7f ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 y80 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Diabetes </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y81 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Patients with splen<span className="_ _1"></span>ectomy{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y82 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y83 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 x20 h2 y84 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Chronic blood <span className="_ _1"></span>loss
                  <span className="ffc"> </span>
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y85 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Hemoly<span className="_ _1"></span>tic anemia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 ye ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y87 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x2a hc yc7 ffc fs0 fc0 sc0 ls0 ws0" id="URIC">
              Uric Acid{' '}
            </div>
            <div className="t m0 x1 hc yc8 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y3e ff1 fs0 fc0 sc0 ls0 ws0">
              Uric acid is th<span className="_ _1"></span>e principle end produ
              <span className="_ _1"></span>ct of purin
              <span className="_ _1"></span>e, nucleic acid and n
              <span className="_ _1"></span>ucleoprote
              <span className="_ _1"></span>in metabolism
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 xa h2 yae ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y224 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y225 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y226 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y227 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y228 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Gout </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y229 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Rheumatoid arth<span className="_ _1"></span>ritis{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y22a ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y22b ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y22c ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 y22d ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Molybdenum insu<span className="_ _1"></span>fficiency: I
                  <span className="_ _1"></span>f the uric acid{' '}
                  <span className="_ _1"></span>is decrease
                  <span className="_ _1"></span>d with a norm
                  <span className="_ _1"></span>al MCV/MCH
                  <span className="_ _1"></span>, moly
                  <span className="_ _1"></span>
                  bdenum{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x28 h2 y22e ff1 fs0 fc0 sc0 ls0 ws0">
              insufficiency shou<span className="_ _1"></span>ld be ruled out v
              <span className="_ _1"></span>ia blood or u
              <span className="_ _1"></span>
              rine. M<span className="_ _1"></span>olybdenum insufficiency
              <span className="_ _1"></span> is comm
              <span className="_ _1"></span>on with sensitivity{' '}
            </div>
            <div className="t m0 x28 h2 y22f ff1 fs0 fc0 sc0 ls0 ws0">
              to perfumes, exhaus<span className="_ _1"></span>t and other gases
              <span className="_ _1"></span> and sulfites used
              <span className="_ _1"></span> in food pre
              <span className="_ _1"></span>
              servation.{' '}
            </div>
            <div className="t m0 xa h2 y230 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y231 ff1 fs0 fc0 sc0 ls0 ws0">
              Molybdenum is k<span className="_ _1"></span>nown to funct
              <span className="_ _1"></span>ion as a cofacto
              <span className="_ _1"></span>r for three enzym
              <span className="_ _1"></span>es:{' '}
            </div>
            <div className="t m0 xa h2 y232 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x12 ha y233 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff8">
                  Sulfite oxidase
                  <span className="ff1">
                    : ca<span className="_ _1"></span>talyz
                    <span className="_ _1"></span>
                    es the transformation o<span className="_ _1"></span>f
                    sulfite to su
                    <span className="_ _1"></span>lfate{' '}
                  </span>
                </span>
              </span>
            </div>
            <div className="t m0 x12 ha y234 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff8">
                  Xanthine oxidase<span className="_ _1"></span>
                  <span className="ff1">
                    : catalyzes the br<span className="_ _1"></span>eakdown of n
                    <span className="_ _1"></span>ucleotides to fo
                    <span className="_ _1"></span>rm uric acid{' '}
                  </span>
                </span>
              </span>
            </div>
            <div className="t m0 x12 ha y235 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff8">
                  Aldehyde oxidase
                  <span className="ff1">
                    {' '}
                    an<span className="_ _1"></span>d xanthine oxid
                    <span className="_ _1"></span>ase: cataly
                    <span className="_ _1"></span>
                    ze hydroxy<span className="_ _1"></span>lation reactions{' '}
                    <span className="_ _1"></span>that involve a num
                    <span className="_ _1"></span>ber of diffe
                    <span className="_ _1"></span>
                    rent{' '}
                  </span>
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y236 ff1 fs0 fc0 sc0 ls0 ws0">
              molecules with sim<span className="_ _1"></span>ilar che
              <span className="_ _1"></span>mical structures.{' '}
            </div>
            <div className="t m0 xa h2 y237 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y238 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y239 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y23a ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y23b ff1 fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf18" className="pf w0 h0" data-page-no="18">
          <div className="pc pc18 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">23 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x24 hc y7 ffc fs0 fc0 sc0 ls0 ws0"
              id="PHOSPHORUS"
            >
              Phosphorus{' '}
            </div>
            <div className="t m0 x1 hc y15f ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y17d ff1 fs0 fc0 sc0 ls0 ws0">
              Several factors are <span className="_ _1"></span>important in reg
              <span className="_ _1"></span>ulating serum
              <span className="_ _1"></span> phosphorus
              <span className="_ _1"></span>, including the{' '}
              <span className="_ _1"></span>parathy
              <span className="_ _1"></span>roid hormone (PTH) and
              <span className="_ _1"></span> the{' '}
            </div>
            <div className="t m0 xa h2 y17e ff1 fs0 fc0 sc0 ls0 ws0">
              functional state<span className="_ _1"></span> of the kidne
              <span className="_ _1"></span>ys. PTH is m
              <span className="_ _1"></span>
              ostly responsible <span className="_ _1"></span>for increased{' '}
              <span className="_ _1"></span>serum calcium
              <span className="_ _1"></span> and decreased{' '}
              <span className="_ _1"></span>serum phosphorus
              <span className="_ _1"></span>;{' '}
            </div>
            <div className="t m0 xa h2 y17f ff1 fs0 fc0 sc0 ls0 ws0">
              hence there is a re<span className="_ _1"></span>ciprocal r
              <span className="_ _1"></span>elationship between the
              <span className="_ _1"></span> two. Phospho
              <span className="_ _1"></span>
              rus is very im<span className="_ _1"></span>portant in the phy
              <span className="_ _1"></span>siology
              <span className="_ _1"></span> of bone and in{' '}
            </div>
            <div className="t m0 xa h2 y180 ff1 fs0 fc0 sc0 ls0 ws0">
              the formation of <span className="_ _1"></span>active com
              <span className="_ _1"></span>pounds such as pho
              <span className="_ _1"></span>
              spholipi<span className="_ _1"></span>ds, nucleic acids, A
              <span className="_ _1"></span>TP, creatine phos
              <span className="_ _1"></span>
              phate and com<span className="_ _1"></span>plexes requ
              <span className="_ _1"></span>ired{' '}
            </div>
            <div className="t m0 xa h2 y181 ff1 fs0 fc0 sc0 ls0 ws0">
              for the utiliz<span className="_ _1"></span>ation of glucos
              <span className="_ _1"></span>e. Phosphorus is a g
              <span className="_ _1"></span>eneral indicator of dig
              <span className="_ _1"></span>estive funct
              <span className="_ _1"></span>
              ion, when decr<span className="_ _1"></span>eased &lt;3.0 w
              <span className="_ _1"></span>ith an{' '}
            </div>
            <div className="t m0 xa h2 y182 ff1 fs0 fc0 sc0 ls0 ws0">
              increased (&gt;2.8<span className="_ _1"></span>) or decreas
              <span className="_ _1"></span>ed (&lt;2.4) to
              <span className="_ _1"></span>
              tal serum g<span className="_ _1"></span>lobulin and/or inc
              <span className="_ _1"></span>reased BUN (
              <span className="_ _1"></span>
              &gt;15), hypochlo<span className="_ _1"></span>rhydria is pr
              <span className="_ _1"></span>obable.{' '}
            </div>
            <div className="t m0 xa h2 y23c ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y23d ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y23e ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y1a7 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y23f ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y240 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Parathyroid hy<span className="_ _1"></span>pofunction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y241 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Acute and chron<span className="_ _1"></span>ic renal
                  dysfuncti
                  <span className="_ _1"></span>on{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y242 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Sarcoidosis </span>
              </span>
            </div>
            <div className="t m0 xa h2 y243 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y244 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y146 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y1d1 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Parathyroid hy<span className="_ _1"></span>perfunction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y18e ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Digestive dysfunc<span className="_ _1"></span>tion (Hyp
                  <span className="_ _1"></span>ochlorhydria){' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y18f ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Vitamin D defic<span className="_ _1"></span>iency{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa hc y245 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x27 hc y246 ffc fs0 fc0 sc0 ls0 ws0"
              id="ANIONGAP"
            >
              Anion Gap{' '}
            </div>
            <div className="t m0 x1 hc y17 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y247 ff1 fs0 fc0 sc0 ls0 ws0">
              Anion gap is a c<span className="_ _1"></span>alculation from
              <span className="_ _1"></span> sodium, potassium
              <span className="_ _1"></span>, CO2 and ch
              <span className="_ _1"></span>
              loride to asc
              <span className="_ _1"></span>ertain the level o
              <span className="_ _1"></span>f unmeasured anions and{' '}
            </div>
            <div className="t m0 xa h2 y248 ff1 fs0 fc0 sc0 ls0 ws0">
              cations. (Sodium<span className="_ _1"></span> + Potassium
              <span className="_ _1"></span>) <span className="ff7">–</span>{' '}
              (chloride + CO2
              <span className="_ _1"></span>){' '}
            </div>
            <div className="t m0 xa h2 y249 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y24a ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y24b ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y1ff ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y200 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y201 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Thiamine deficie<span className="_ _1"></span>ncy{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y1df ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Diabetes </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y202 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Lactic acidosis<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y203 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Dehydration </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y24c ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Toxin ingestion<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y24d ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Metabolic acidos<span className="_ _1"></span>is{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y24e ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Renal dysfunc<span className="_ _1"></span>tion{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y24f ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y250 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y15c ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y251 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Renal dysfunc<span className="_ _1"></span>tion{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y252 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Lithium toxicity<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf19" className="pf w0 h0" data-page-no="19">
          <div className="pc pc19 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">24 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y7 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y32 ffd fs0 fc0 sc0 ls0 ws0">
              Lipid Panel{' '}
            </div>
            <div className="t m0 xa hc y223 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x24 hc y34 ffc fs0 fc0 sc0 ls0 ws0"
              id="CHOLESTEROL"
            >
              Cholesterol{' '}
            </div>
            <div className="t m0 x1 hc y35 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y7f ff1 fs0 fc0 sc0 ls0 ws0">
              Cholesterol is a s<span className="_ _1"></span>teroid norm
              <span className="_ _1"></span>ally found in al
              <span className="_ _1"></span>l body cells and p
              <span className="_ _1"></span>lasma. Most o
              <span className="_ _1"></span>f the inform
              <span className="_ _1"></span>
              ation relative <span className="_ _1"></span>to incre
              <span className="_ _1"></span>
              ased or{' '}
            </div>
            <div className="t m0 xa h2 y80 ff1 fs0 fc0 sc0 ls0 ws0">
              decreased chole<span className="_ _1"></span>sterol can b
              <span className="_ _1"></span>e extrapolated{' '}
              <span className="_ _1"></span>
              to trigly<span className="_ _1"></span>cerides. In g
              <span className="_ _1"></span>eneral, cholesterol i
              <span className="_ _1"></span>s incre
              <span className="_ _0"></span>ased in m
              <span className="_ _1"></span>ost endocrin
              <span className="_ _1"></span>e or organ{' '}
            </div>
            <div className="t m0 xa h2 y81 ff1 fs0 fc0 sc0 ls0 ws0">
              hypofunction and de<span className="_ _1"></span>creased in m
              <span className="_ _1"></span>ost endocrine and o
              <span className="_ _1"></span>rgan hy
              <span className="_ _1"></span>
              perfunction.{' '}
            </div>
            <div className="t m0 xa h2 y82 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y83 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y84 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y85 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 ye ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 yf ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Coronary artery<span className="_ _1"></span> disease{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y10 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Diabetes </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y11 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Primary thy<span className="_ _1"></span>roid hypofuncti
                  <span className="_ _1"></span>on or hypofunction{' '}
                  <span className="_ _1"></span>secondary
                  <span className="_ _1"></span> to anterior pituita
                  <span className="_ _1"></span>ry hypofunction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y12 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc yff ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els{' '}
            </div>
            <div className="t m0 x1c hc y253 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y101 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Thyroid hyperfunc<span className="_ _1"></span>tion and/or an
                  <span className="_ _1"></span>terior pitu
                  <span className="_ _1"></span>
                  itary hyperfunct<span className="_ _1"></span>ion{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y116 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Cancer </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y117 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
              </span>
              Crohn‟s disease<span className="_ _1"></span>
              <span className="ff1"> </span>
            </div>
            <div className="t m0 x20 h2 y104 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Cirrhosis of th<span className="_ _1"></span>e liver,{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y105 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Celiac disease </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y106 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Malnutrition<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y107 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x30 hc y108 ffc fs0 fc0 sc0 ls0 ws0"
              id="TRIGLYCERIDES"
            >
              Triglycerides{' '}
            </div>
            <div className="t m0 x1 hc y109 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y10a ff1 fs0 fc0 sc0 ls0 ws0">
              Serum triglycer<span className="_ _1"></span>ides (TG) are a f
              <span className="_ _1"></span>amily of com
              <span className="_ _1"></span>
              plex lipid<span className="_ _1"></span>s composed of glycerol
              este
              <span className="_ _1"></span>rified with th
              <span className="_ _1"></span>
              ree fatty acids <span className="_ _1"></span>(saturated{' '}
            </div>
            <div className="t m0 xa h2 y10b ff1 fs0 fc0 sc0 ls0 ws0">
              and unsaturated<span className="_ _1"></span>) of the sam
              <span className="_ _1"></span>e or different le
              <span className="_ _1"></span>
              ngths. They <span className="_ _1"></span>are not soluble{' '}
              <span className="_ _1"></span>in blood and
              <span className="_ _1"></span> constitute 95 perc
              <span className="_ _1"></span>ent of the fat{' '}
              <span className="_ _1"></span>stored{' '}
            </div>
            <div className="t m0 xa h2 y10c ff1 fs0 fc0 sc0 ls0 ws0">
              in adipose tissue. <span className="_ _1"></span>Triglyceri
              <span className="_ _1"></span>des travel in{' '}
              <span className="_ _1"></span>
              the blood with <span className="_ _1"></span>cholestero
              <span className="_ _1"></span>l; therefore they should be drawn
              tog
              <span className="_ _1"></span>ether and v
              <span className="_ _1"></span>
              iewed{' '}
            </div>
            <div className="t m0 xa h2 y10d ff1 fs0 fc0 sc0 ls0 ws0">
              together. A pati<span className="_ _1"></span>ent who is c
              <span className="_ _1"></span>orrectly m
              <span className="_ _1"></span>
              etabolizing thei<span className="_ _1"></span>r fats, proteins
              <span className="_ _1"></span>, and carbohy
              <span className="_ _1"></span>
              drates will gene<span className="_ _1"></span>rally have ab
              <span className="_ _1"></span>out half as{' '}
            </div>
            <div className="t m0 xa h2 y76 ff1 fs0 fc0 sc0 ls0 ws0">
              much trigly<span className="_ _1"></span>ceride as cholesterol. Ex
              <span className="_ _1"></span>ample <span className="ff7">–</span>{' '}
              <span className="lsb">if</span> the serum
              <span className="_ _1"></span> cholesterol is 200 the{' '}
              <span className="_ _1"></span>trigly
              <span className="_ _1"></span>ceride level should be ar
              <span className="_ _1"></span>ound 100.{' '}
            </div>
            <div className="t m0 xa h2 y10e ff1 fs0 fc0 sc0 ls0 ws0">
              Classic research f<span className="_ _1"></span>ro the Fram
              <span className="_ _1"></span>ing<span className="_ _1"></span>ham
              studies in the 197<span className="_ _1"></span>0s identified hig
              <span className="_ _1"></span>h serum
              <span className="_ _1"></span> cholesterol as a hea
              <span className="_ _1"></span>rt disease{' '}
              <span className="_ _1"></span>risk factor{' '}
            </div>
            <div className="t m0 xa h2 y10f ff1 fs0 fc0 sc0 ls0 ws0">
              (above 200 mg<span className="_ _1"></span>/dL); however subsequ
              <span className="_ _1"></span>ent Framing
              <span className="_ _1"></span>ham studies as we
              <span className="_ _1"></span>ll as other pe
              <span className="_ _1"></span>er reviewe
              <span className="_ _1"></span>d studies (Hel
              <span className="_ _1"></span>si<span className="_ _0"></span>
              nki, etc),{' '}
            </div>
            <div className="t m0 xa h2 y110 ff1 fs0 fc0 sc0 ls0 ws0">
              indicated that in<span className="_ _1"></span>creased trigly
              <span className="_ _1"></span>cerides in con
              <span className="_ _1"></span>
              junction with dec<span className="_ _1"></span>reased HDL cho
              <span className="_ _1"></span>lesterol (
              <span className="_ _1"></span>
              Metabolic Sy<span className="_ _1"></span>ndrome/Sy
              <span className="_ _1"></span>ndrome<span className="_ _0"></span>
              -X){' '}
            </div>
            <div className="t m0 xa h2 y111 ff1 fs0 fc0 sc0 ls0 ws0">
              was a more signi<span className="_ _1"></span>ficant indicato
              <span className="_ _1"></span>r for coronary
              <span className="_ _1"></span> artery disease th
              <span className="_ _1"></span>an high serum
              <span className="_ _1"></span> cholesterol a
              <span className="_ _1"></span>
              lone.{' '}
            </div>
            <div className="t m0 xa h2 y112 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y113 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y114 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y115 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf1a" className="pf w0 h0" data-page-no="1a">
          <div className="pc pc1a w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">25 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x3c hc y7 ffc fs0 fc0 sc0 ls0 ws0">
              Triglycerides{' '}
              <span className="ffa">
                (con<span className="_ _1"></span>’t)
                <span className="ffc"> </span>
              </span>
            </div>
            <div className="t m0 xa h2 y8 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Significanc<span className="_ _1"></span>e{' '}
            </div>
            <div className="t m0 xa h2 y33 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y34 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y35 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 y7f ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Dysinsulinism<span className="_ _1"></span> (Metabolic Sy
                  <span className="_ _1"></span>ndrome/Sy
                  <span className="_ _1"></span>
                  ndrome-<span className="ls8">X)</span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y80 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Hypothy<span className="_ _1"></span>roidism{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y81 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y82 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y83 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 y84 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Thyroid Hyperfun<span className="_ _1"></span>ction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y85 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Vegetarian die<span className="_ _1"></span>ts{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1f h2 ye ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Autoimm<span className="_ _1"></span>une phenomenon: cholest
                  <span className="_ _1"></span>erol will be no
                  <span className="_ _1"></span>rmal to m
                  <span className="_ _1"></span>
                  ildly decreased, tr<span className="_ _1"></span>iglycerides w
                  <span className="_ _1"></span>ill be significantly{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x28 h2 yf ff1 fs0 fc0 sc0 ls0 ws0">
              decreased in re<span className="_ _1"></span>lation to cho
              <span className="_ _1"></span>lesterol (usual
              <span className="_ _1"></span>
              ly less than 3<span className="_ _1"></span>0 percent of the{' '}
              <span className="_ _1"></span>total cholesterol)
              <span className="_ _1"></span> and the HDL{' '}
            </div>
            <div className="t m0 x28 h2 y10 ff1 fs0 fc0 sc0 ls0 ws0">
              cholesterol will <span className="_ _1"></span>be significantly
              <span className="_ _1"></span> increased in{' '}
              <span className="_ _1"></span>
              relation to the <span className="_ _1"></span>total choles
              <span className="_ _1"></span>terol.<span className="ffc"> </span>
            </div>
            <div className="t m0 x1f h2 y11 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Genetic predispo<span className="_ _1"></span>sition
                  <span className="ffc"> </span>
                </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y12 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Cirrhosis of th<span className="_ _1"></span>e liver
                  <span className="ffc"> </span>
                </span>
              </span>
            </div>
            <div className="t m0 x1f h2 yff ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Celiac disease<span className="ffc"> </span>
                </span>
              </span>
            </div>
            <div className="t m0 x29 hc y100 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x29 hc y101 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1e hc y116 ffc fs0 fc0 sc0 ls0 ws0" id="HDL">
              H<span className="ls4">DL</span>{' '}
            </div>
            <div className="t m0 x3d hc y117 ffc fs0 fc0 sc0 ls0 ws0">
              High Density Lipopro<span className="_ _1"></span>tein C
              <span className="_ _1"></span>holesterol{' '}
            </div>
            <div className="t m0 x1 hc y104 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y105 ff1 fs0 fc0 sc0 ls0 ws0">
              HDL is a class o<span className="_ _1"></span>f heterogeneous p
              <span className="_ _1"></span>articles of vary
              <span className="_ _1"></span>
              ing densi<span className="_ _1"></span>ty and size cont
              <span className="_ _1"></span>aining lipid and pro
              <span className="_ _1"></span>tein. It inc
              <span className="_ _1"></span>
              ludes cholestero<span className="_ _1"></span>l,{' '}
            </div>
            <div className="t m0 xa h2 y106 ff1 fs0 fc0 sc0 ls0 ws0">
              triglycerides, pho<span className="_ _1"></span>spholipids
              <span className="_ _1"></span> and apopro
              <span className="_ _1"></span>
              teins. Phospholipids p<span className="_ _1"></span>redom
              <span className="_ _1"></span>inate in HDL. HDL functions in{' '}
              <span className="_ _1"></span>the transport of{' '}
            </div>
            <div className="t m0 xa hb y107 ff7 fs0 fc0 sc0 ls0 ws0">
              cholesterol to th<span className="_ _1"></span>e liver for m
              <span className="_ _1"></span>etabolism
              <span className="_ _1"></span> to bile acids, h
              <span className="_ _1"></span>ence it is thoug
              <span className="_ _1"></span>ht of as the „g
              <span className="_ _1"></span>
              ood‟ form of cho<span className="_ _1"></span>lesterol.{' '}
              <span className="_ _1"></span>HDL levels{' '}
            </div>
            <div className="t m0 xa h2 y108 ff1 fs0 fc0 sc0 ls0 ws0">
              must be viewed in <span className="_ _1"></span>relation to the{' '}
              <span className="_ _1"></span>total choles
              <span className="_ _1"></span>
              terol and LD<span className="_ _1"></span>L/VLDL cholestero
              <span className="_ _1"></span>l. Within reason, i
              <span className="_ _1"></span>f the cholesterol is high,{' '}
            </div>
            <div className="t m0 xa h2 y109 ff1 fs0 fc0 sc0 ls0 ws0">
              but the HDL is also h<span className="_ _1"></span>igh an
              <span className="_ _1"></span>d the LDL is norm
              <span className="_ _1"></span>
              al, the patient <span className="_ _1"></span>is considered{' '}
              <span className="_ _1"></span>to be well p
              <span className="_ _1"></span>
              rotected.{' '}
            </div>
            <div className="t m0 xa h2 y10a ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y10b ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y10c ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y10d ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y76 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 y10e ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Increased HDL cho<span className="_ _1"></span>lesterol is g
                  <span className="_ _1"></span>enerally not consi
                  <span className="_ _1"></span>dered to be a pa
                  <span className="_ _1"></span>thologic find
                  <span className="_ _1"></span>
                  ing, howev<span className="_ _1"></span>er it
                  <span className="_ _0"></span> is often found{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x28 h2 y10f ff1 fs0 fc0 sc0 ls0 ws0">
              significantly<span className="_ _1"></span> higher than norm
              <span className="_ _1"></span>al with alcohol
              <span className="_ _1"></span>
              ism and autoim<span className="_ _1"></span>mune diseases{' '}
            </div>
            <div className="t m0 xa h2 y110 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y111 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y112 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1f h2 y113 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Thyroid hyperfunc<span className="_ _1"></span>tion{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y114 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Diabetes </span>
              </span>
            </div>
            <div className="t m0 x1f h2 y115 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Dysinsulinism<span className="_ _1"></span> (Metabolic Sy
                  <span className="_ _1"></span>ndrome/Sy
                  <span className="_ _1"></span>
                  ndrome-<span className="ls8">X)</span>{' '}
                </span>
              </span>
            </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf1b" className="pf w0 h0" data-page-no="1b">
          <div className="pc pc1b w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">26 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x3e hc y7 ffc fs0 fc0 sc0 ls0 ws0" id="LDL">
              LDL{' '}
            </div>
            <div className="t m0 x9 hc y8 ffc fs0 fc0 sc0 ls0 ws0">
              Low Density Lipop<span className="_ _1"></span>rotein Choleste
              <span className="_ _1"></span>rol{' '}
            </div>
            <div className="t m0 x1 hc y33 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y34 ff1 fs0 fc0 sc0 ls0 ws0">
              An association ex<span className="_ _1"></span>ists between LDL
              and H<span className="_ _1"></span>DL choleste
              <span className="_ _1"></span>
              rol. As the LDL <span className="_ _1"></span>increased{' '}
              <span className="_ _1"></span>
              the HDL w<span className="_ _1"></span>ill generally dec
              <span className="_ _1"></span>rease; hence{' '}
            </div>
            <div className="t m0 xa h2 y35 ff1 fs0 fc0 sc0 ls0 ws0">
              the association i<span className="_ _1"></span>n the diagnosis{' '}
              <span className="_ _1"></span>of atherosclero
              <span className="_ _1"></span>
              sis, dysinsul<span className="_ _1"></span>inism, corona
              <span className="_ _1"></span>ry artery disease{' '}
              <span className="_ _1"></span>
              and frank or dev<span className="_ _1"></span>eloping{' '}
            </div>
            <div className="t m0 xa h2 y7f ff1 fs0 fc0 sc0 ls0 ws0">
              diabetes. I<span className="_ _1"></span>n most cases the LD
              <span className="_ _1"></span>L level is a m
              <span className="_ _1"></span>
              ajor part of the decision <span className="_ _1"></span>relevant
              to in
              <span className="_ _1"></span>itiation of d
              <span className="_ _1"></span>
              ietary therapy<span className="_ _1"></span> and/or{' '}
            </div>
            <div className="t m0 xa h2 y80 ff1 fs0 fc0 sc0 ls0 ws0">
              supplemental suppo<span className="_ _1"></span>rt.{' '}
            </div>
            <div className="t m0 xa h2 y81 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y82 ff1 fs0 fc0 sc0 ls0 ws0">
              Good scientific ev<span className="_ _1"></span>idence exists th
              <span className="_ _1"></span>at indicated{' '}
              <span className="_ _1"></span>
              oxidized LD<span className="_ _1"></span>L cholesterol is t
              <span className="_ _1"></span>aken up by
              <span className="_ _1"></span> macrophages form
              <span className="_ _1"></span>ing the foam
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xa h2 y83 ff1 fs0 fc0 sc0 ls0 ws0">
              cells of atherosc<span className="_ _1"></span>lerotic plaques, ph
              <span className="_ _1"></span>enolic substan
              <span className="_ _1"></span>
              ces (red wine, fruit<span className="_ _1"></span>s and vegetable
              <span className="_ _1"></span>s, etc. and v
              <span className="_ _1"></span>
              itamin E) prevent the{' '}
            </div>
            <div className="t m0 xa h2 y84 ff1 fs0 fc0 sc0 ls0 ws0">
              oxidation of LDL cho<span className="_ _1"></span>lester
              <span className="_ _1"></span>ol.{' '}
            </div>
            <div className="t m0 x1 h2 y85 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 h2 ye ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x32 hc yf ffc fs0 fc0 sc0 ls4 ws0" id="HSCRP">
              Hs<span className="ls0">-CRP </span>
            </div>
            <div className="t m0 x1 h2 y10 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y11 ff1 fs0 fc0 sc0 ls0 ws0">
              C-reactive pro<span className="_ _1"></span>tein (CRP) is produ
              <span className="_ _1"></span>ced prim
              <span className="_ _1"></span>arily by the{' '}
              <span className="_ _1"></span>liver during
              <span className="_ _1"></span> an acute inflam
              <span className="_ _1"></span>matory process
              <span className="_ _1"></span> or other dis
              <span className="_ _1"></span>
              ease. I<span className="_ _1"></span>t rises{' '}
            </div>
            <div className="t m0 xa h2 y12 ff1 fs0 fc0 sc0 ls0 ws0">
              rapidly, but nonsp<span className="_ _1"></span>ecifically in
              respon
              <span className="_ _1"></span>se to tissue{' '}
              <span className="_ _1"></span>
              injury and inflam<span className="_ _1"></span>mation. CRP i
              <span className="_ _1"></span>s a m<span className="_ _1"></span>
              ore sensitive indicato<span className="_ _1"></span>r of{' '}
            </div>
            <div className="t m0 xa h2 yff ff1 fs0 fc0 sc0 ls0 ws0">
              inflammation <span className="_ _1"></span>than the erythrocy
              <span className="_ _1"></span>te sedimenta
              <span className="_ _1"></span>
              tion rate. <span className="_ _1"></span>CRP is an excell
              <span className="_ _1"></span>ent test to m
              <span className="_ _1"></span>
              onitor the effica<span className="_ _1"></span>cy of supplem
              <span className="_ _1"></span>ents or{' '}
            </div>
            <div className="t m0 xa h2 y100 ff1 fs0 fc0 sc0 ls0 ws0">
              prescribed drugs on sy<span className="_ _1"></span>stemic inflam
              <span className="_ _1"></span>mation or injury
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 xa h2 y101 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yd5 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 yeb ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc yec ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 yed ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 yee ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Viral and bacte<span className="_ _1"></span>rial infectio
                  <span className="_ _1"></span>n{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 yef ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Non specific tissue<span className="_ _1"></span> inflamm
                  <span className="_ _1"></span>ation or injury{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 yf0 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Coronary artery<span className="_ _1"></span> inflamm
                  <span className="_ _1"></span>ation/atheroscleros
                  <span className="_ _1"></span>is{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 yf1 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Rheumatoid arth<span className="_ _1"></span>ritis{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 yf2 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yf3 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y154 ffd fs0 fc0 sc0 ls0 ws0" id="IRON">
              Iron Panel{' '}
            </div>
            <div className="t m0 xa hc y254 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x3e hc ybd ffc fs0 fc0 sc0 ls0 ws0">Iron </div>
            <div className="t m0 x1 hc ybe ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 ybf ff1 fs0 fc0 sc0 ls0 ws0">
              A comm<span className="_ _1"></span>on mistake m
              <span className="_ _1"></span>
              ade by many<span className="_ _1"></span> doctors when ordering
              <span className="_ _1"></span> a red blood coun
              <span className="_ _1"></span>t and indices is no
              <span className="_ _1"></span>t ordering a se
              <span className="_ _1"></span>rum<span className="_ _1"></span>{' '}
              iron <span className="_ _0"></span>and{' '}
            </div>
            <div className="t m0 xa h2 yc0 ff1 fs0 fc0 sc0 ls0 ws0">
              serum ferritin. W<span className="_ _1"></span>ithout the serum
              <span className="_ _1"></span> iron or ferri
              <span className="_ _1"></span>
              tin value, the am<span className="_ _1"></span>ount of inorg
              <span className="_ _1"></span>anic iron (serum
              <span className="_ _1"></span> iron) or stored
              <span className="_ _1"></span> iron (fer
              <span className="_ _1"></span>ritin)<span className="_ _0"></span>{' '}
            </div>
            <div className="t m0 xa h2 yc1 ff1 fs0 fc0 sc0 ls0 ws0">
              available to conv<span className="_ _1"></span>ert to hemog
              <span className="_ _1"></span>lobin (organic iron)
              <span className="_ _1"></span> is unk
              <span className="_ _1"></span>nown.{' '}
            </div>
            <div className="t m0 xa h2 yc2 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yc3 ff1 fs0 fc0 sc0 ls0 ws0">
              Daily iron loss <span className="_ _1"></span>is small and occu
              <span className="_ _1"></span>rs mainly in{' '}
              <span className="_ _1"></span>
              the feces - about 0.6 mg<span className="_ _1"></span> per day. I
              <span className="_ _1"></span>n premenopausal wom
              <span className="_ _1"></span>en, iron los
              <span className="_ _1"></span>
              ses can{' '}
            </div>
            <div className="t m0 xa h2 yc4 ff1 fs0 fc0 sc0 ls0 ws0">
              be up to 1.3 mg<span className="_ _1"></span> per day.{' '}
            </div>
            <div className="t m0 xa h2 yc5 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa ha ya2 ff8 fs0 fc0 sc0 ls0 ws0">
              Never give iron <span className="_ _1"></span>without the bene
              <span className="_ _1"></span>fit of a blood te
              <span className="_ _1"></span>
              st because of the possibi<span className="_ _1"></span>lity o
              <span className="_ _1"></span>f iron toxicity or over
              <span className="_ _1"></span>load.{' '}
            </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf1c" className="pf w0 h0" data-page-no="1c">
          <div className="pc pc1c w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">27 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x24 hc y7 ffa fs0 fc0 sc0 ls0 ws0">
              Iron (con’t)<span className="ffc"> </span>
            </div>
            <div className="t m0 xa h2 y8 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y33 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y58 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y17f ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y1c4 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x20 h2 y1c5 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Hemochrom<span className="_ _1"></span>atosis{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x20 h2 y255 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Hepatic dysfunct<span className="_ _1"></span>ion{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y1a5 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y184 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 x12 h2 y185 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x16 h2 y186 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Chronic blood <span className="_ _1"></span>loss{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y1a8 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Iron deficiency anem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y1a9 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Menses </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y1aa ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Cancer </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y1ab ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Hepatic dysfunct<span className="_ _1"></span>ion{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y1ac ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
              </span>
              Crohn‟s disease<span className="_ _1"></span>
              <span className="ff1"> </span>
            </div>
            <div className="t m0 x1e hc y1ad ffc fs0 fc0 sc0 ls0 ws0" id="TIBC">
              TIBC{' '}
            </div>
            <div className="t m0 x3f hc y1ae ffc fs0 fc0 sc0 ls0 ws0">
              Total Iron Binding C<span className="_ _1"></span>apacity{' '}
            </div>
            <div className="t m0 x1 hc y256 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y257 ff1 fs0 fc0 sc0 ls0 ws0">
              Transferrin is respon<span className="_ _1"></span>sible f
              <span className="_ _1"></span>or 50 to 70 percen
              <span className="_ _1"></span>t of the iron bind
              <span className="_ _1"></span>ing capacity
              <span className="_ _1"></span> of the serum
              <span className="_ _1"></span>. TIBC is the m
              <span className="_ _1"></span>easurem
              <span className="_ _1"></span>ent of all{' '}
            </div>
            <div className="t m0 xa h2 y258 ff1 fs0 fc0 sc0 ls0 ws0">
              proteins availab<span className="_ _1"></span>le for bindi
              <span className="_ _1"></span>ng iron.{' '}
            </div>
            <div className="t m0 xa h2 yb2 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y259 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y90 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y25a ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 x1c h2 y25b ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Iron anem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y25c ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y25d ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y25e ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c h2 y25f ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Inflamm<span className="_ _1"></span>atory diseases{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y260 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Cirrhosis </span>
              </span>
            </div>
            <div className="t m0 xa h2 y261 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x32 hc y262 ffc fs0 fc0 sc0 ls0 ws0"
              id="FERRITIN"
            >
              Ferritin{' '}
            </div>
            <div className="t m0 x1 hc y263 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y264 ff1 fs0 fc0 sc0 ls0 ws0">
              Ferritin is the secon<span className="_ _1"></span>d m
              <span className="_ _1"></span>ost abundant iron-bearing prote
              <span className="_ _1"></span>in in the body
              <span className="_ _1"></span>. It functions{' '}
              <span className="_ _1"></span>as an iron storage d
              <span className="_ _1"></span>epot in t
              <span className="_ _1"></span>he liver,{' '}
            </div>
            <div className="t m0 xa h2 y265 ff1 fs0 fc0 sc0 ls0 ws0">
              spleen, and bone m<span className="_ _1"></span>arrow. C
              <span className="_ _1"></span>irculating ferr
              <span className="_ _1"></span>
              itin levels a<span className="_ _1"></span>ppear to refle
              <span className="_ _1"></span>ct the distribution of iron b
              <span className="_ _1"></span>etween the{' '}
            </div>
            <div className="t m0 xa h2 y266 ff1 fs0 fc0 sc0 ls0 ws0">
              reticuloendothel<span className="_ _1"></span>ial system (
              <span className="_ _1"></span>RES) cells and b
              <span className="_ _1"></span>
              lood cells. <span className="_ _1"></span>Three facto
              <span className="_ _1"></span>rs generally
              <span className="_ _1"></span> determine serum
              <span className="_ _1"></span> ferritin lev
              <span className="_ _1"></span>els:<span className="_ _0"></span>{' '}
            </div>
            <div className="t m0 xa h2 y267 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x12 h2 y268 ff1 fs0 fc0 sc0 ls0 ws0">
              1.
              <span className="ff5">
                {' '}
                <span className="_ _18"> </span>
              </span>
              The iron content o<span className="_ _1"></span>f the bod
              <span className="_ _1"></span>y tissues.{' '}
            </div>
            <div className="t m0 x12 h2 y269 ff1 fs0 fc0 sc0 ls0 ws0">
              2.
              <span className="ff5">
                {' '}
                <span className="_ _18"> </span>
              </span>
              The rate of ferri<span className="_ _1"></span>tin dispersal f
              <span className="_ _1"></span>rom the tissues{' '}
            </div>
            <div className="t m0 x12 h2 y26a ff1 fs0 fc0 sc0 ls0 ws0">
              3.
              <span className="ff5">
                {' '}
                <span className="_ _18"> </span>
              </span>
              The rate of ferri<span className="_ _1"></span>tin extraction from
              <span className="_ _1"></span> the blood.{' '}
            </div>
            <div className="t m0 xa h2 y26b ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y13a ff1 fs0 fc0 sc0 ls0 ws0">
              Aberrations in <span className="_ _1"></span>the latter two reg
              <span className="_ _1"></span>ulatory proces
              <span className="_ _1"></span>
              ses are p<span className="_ _1"></span>resent in m
              <span className="_ _1"></span>
              any free radical <span className="_ _1"></span>diseases (canc
              <span className="_ _1"></span>er, rheumato
              <span className="_ _1"></span>
              id, arthritis,{' '}
            </div>
            <div className="t m0 xa h2 y13b ff1 fs0 fc0 sc0 ls0 ws0">
              etc.){' '}
            </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf1d" className="pf w0 h0" data-page-no="1d">
          <div className="pc pc1d w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">28 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x40 hc y7 ffc fs0 fc0 sc0 ls0 ws0">
              Ferritin <span className="ffa">(con’t)</span>{' '}
            </div>
            <div className="t m0 xa h2 y8 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 yca ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y160 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y1ea ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y1c4 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x12 h2 y26c ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Iron ov<span className="_ _1"></span>erload/Hemochromatosis{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y26d ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Inflamm<span className="_ _1"></span>atory disease{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y13c ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Hepatitis </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y13d ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">HIV infection </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y23e ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Cancer </span>
              </span>
            </div>
            <div className="t m0 xa h2 y26e ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x12 hc y140 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y141 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x12 h2 y26f ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Iron deficiency anem<span className="_ _1"></span>ia{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y20b ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x12 h2 y144 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y270 ffd fs0 fc0 sc0 ls0 ws0">
              Thyroid Panel<span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xa hc y271 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1e hc y147 ffc fs0 fc0 sc0 ls0 ws0" id="TSH">
              sTSH{' '}
            </div>
            <div className="t m0 x9 hc y272 ffc fs0 fc0 sc0 ls0 ws0">
              Serum Thyroid St<span className="_ _1"></span>imulating H
              <span className="_ _1"></span>ormone{' '}
            </div>
            <div className="t m0 x1 hc y273 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y274 ff1 fs0 fc0 sc0 ls0 ws0">
              The TSH assay<span className="_ _1"></span> was originally
              <span className="_ _1"></span> used to confirm
              <span className="_ _1"></span> or rule out p
              <span className="_ _1"></span>
              rimary thy
              <span className="_ _1"></span>roid hypofunction: how
              <span className="_ _1"></span>ever, the new sensitive TSH{' '}
            </div>
            <div className="t m0 xa h2 y275 ff1 fs0 fc0 sc0 ls0 ws0">
              (sTSH) assays perm<span className="_ _1"></span>it recognition o
              <span className="_ _1"></span>f thyroid hy
              <span className="_ _1"></span>
              perfunction a<span className="_ _1"></span>s well. Sensitive{' '}
              <span className="_ _1"></span>TSH (sTSH) h
              <span className="_ _1"></span>as becom
              <span className="_ _1"></span>e the best sing
              <span className="_ _1"></span>le test{' '}
            </div>
            <div className="t m0 xa h2 y276 ff1 fs0 fc0 sc0 ls0 ws0">
              for determining<span className="_ _1"></span> thyroid hy
              <span className="_ _1"></span>po and hyperfunction.{' '}
            </div>
            <div className="t m0 xa h2 y277 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y278 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y279 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y27a ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y27b ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c h2 y27c ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Primary thyroid hy<span className="_ _1"></span>pofunction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y27d ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Liver dysfunction<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y27e ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Large doses of iod<span className="_ _1"></span>ine{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y27f ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">HIV </span>
              </span>
            </div>
            <div className="t m0 xa h2 y280 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y281 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y282 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c h2 y283 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Thyroid hyperfunc<span className="_ _1"></span>tion secondary
                  <span className="_ _1"></span> to anterior p
                  <span className="_ _1"></span>
                  ituitary hy<span className="_ _1"></span>pofunction{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1 h2 y284 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 hc y285 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 hc y286 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 hc y287 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1 hc y288 ffc fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf1e" className="pf w0 h0" data-page-no="1e">
          <div className="pc pc1e w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">29 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x41 hc y7 ffc fs0 fc0 sc0 ls0 ws0">
              Serum Thyroxine (T4)<span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 x2f hc y8 ffc fs0 fc0 sc0 ls0 ws0">
              Free and Total{' '}
            </div>
            <div className="t m0 x1 hc y33 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y58 ff1 fs0 fc0 sc0 ls0 ws0" id="T4">
              T4 is the ma<span className="_ _1"></span>jor hormone secreted by
              <span className="_ _1"></span> the thyro
              <span className="_ _1"></span>id gland. I
              <span className="_ _1"></span>t is transported throug
              <span className="_ _1"></span>h t<span className="_ _0"></span>he
              blood bound
              <span className="_ _1"></span> to thyrox
              <span className="_ _1"></span>ine binding
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xa h2 ya3 ff1 fs0 fc0 sc0 ls0 ws0">
              globulin (TBG), p<span className="_ _1"></span>realbumi
              <span className="_ _1"></span>n and album
              <span className="_ _1"></span>in. Alterations in binding
              <span className="_ _1"></span> capacity
              <span className="_ _1"></span> or the quantity of{' '}
              <span className="_ _1"></span>TBG may
              <span className="_ _1"></span> increase or decr
              <span className="_ _1"></span>ease{' '}
            </div>
            <div className="t m0 xa ha y5a ff1 fs0 fc0 sc0 ls0 ws0">
              total T4 without <span className="_ _1"></span>causing sy
              <span className="_ _1"></span>mptoms. T4 secr
              <span className="_ _1"></span>
              etion is stim<span className="_ _1"></span>ulated by thyroid st
              <span className="_ _1"></span>imulating
              <span className="_ _1"></span> hormone (TSH).{' '}
              <span className="ff8">Free T4</span> is a very{' '}
            </div>
            <div className="t m0 xa h2 ya4 ff1 fs0 fc0 sc0 ls0 ws0">
              small portion of <span className="_ _1"></span>total thyro
              <span className="_ _1"></span>xine (general
              <span className="_ _1"></span>
              ly less than 0.<span className="_ _1"></span>04 percent); howev
              <span className="_ _1"></span>er, it is t
              <span className="_ _1"></span>he metabolically ac
              <span className="_ _1"></span>tive frac
              <span className="_ _1"></span>tion.{' '}
            </div>
            <div className="t m0 xa h2 ya5 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 ycb ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x41 hc ycc ffc fs0 fc0 sc0 ls0 ws0">
              Triiodothryonin<span className="_ _1"></span>e (T3){' '}
            </div>
            <div className="t m0 x2f hc ycd ffc fs0 fc0 sc0 ls0 ws0">
              Free and Total{' '}
            </div>
            <div className="t m0 x1 hc yce ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 ycf ff1 fs0 fc0 sc0 ls0 ws0">
              T3 is a thyroid ho<span className="_ _1"></span>rmone pr
              <span className="_ _1"></span>oduced mainly
              <span className="_ _1"></span> from periph
              <span className="_ _1"></span>eral conversion
              <span className="_ _1"></span> of thyroxine (T4). Appro
              <span className="_ _1"></span>ximately one{' '}
              <span className="_ _1"></span>
              third of T4 is{' '}
            </div>
            <div className="t m0 xa h2 yd0 ff1 fs0 fc0 sc0 ls0 ws0">
              converted to T3. <span className="_ _1"></span>T3 has a higher b
              <span className="_ _1"></span>iological ac
              <span className="_ _1"></span>
              tivity than <span className="_ _1"></span>T4 and will bind{' '}
              <span className="_ _1"></span>to protein (thy
              <span className="_ _1"></span>
              roid binding g<span className="_ _1"></span>lobulin,{' '}
            </div>
            <div className="t m0 xa h2 yc7 ff1 fs0 fc0 sc0 ls0 ws0">
              transthyretin, and<span className="_ _1"></span> albumin){' '}
              <span className="_ _1"></span>less efficient
              <span className="_ _1"></span>
              ly than T4. <span className="_ _1"></span>T3 and T4 exist{' '}
              <span className="_ _1"></span>in the serum
              <span className="_ _1"></span> in both bound and free forms.{' '}
            </div>
            <div className="t m0 xa h2 yc8 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y289 ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y28a ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x38 hc y28b ffc fs0 fc0 sc0 ls0 ws0">
              T3 and T4{' '}
            </div>
            <div className="t m0 x1 hc y28c ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y28d ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 xa h2 y28e ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y28f ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y290 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c h2 y291 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Primary hy<span className="_ _1"></span>perthyroidism (Grav
                  <span className="_ _1"></span>es){' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y292 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
              </span>
              Acute thyroiditis <span className="_ _1"></span>(Early Ha
              <span className="_ _1"></span>shimoto‟s)
              <span className="ff1"> </span>
            </div>
            <div className="t m0 xa h2 y293 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y278 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y294 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c h2 y295 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Hypothyroidism </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y296 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Hepatic disease </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y297 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Iodine insufficiency<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y298 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
              </span>
              Cushing‟s diseas<span className="_ _1"></span>e
              <span className="ff1"> </span>
            </div>
            <div className="t m0 xa h2 y299 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y29a ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y29b ffd fs0 fc0 sc0 ls0 ws0">
              Additional Tests/Anal<span className="_ _1"></span>ytes{' '}
            </div>
            <div className="t m0 xa hc y29c ffd fs0 fc0 sc0 ls0 ws0"> </div>
            <div
              className="t m0 x39 hc y29d ffc fs0 fc0 sc0 ls0 ws0"
              id="VITAMIND"
            >
              Vitamin D 25-<span className="ls4">OH</span>{' '}
            </div>
            <div className="t m0 x1 hc y29e ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y24e ff1 fs0 fc0 sc0 ls0 ws0">
              Beyond osteoporo<span className="_ _1"></span>sis, negativ
              <span className="_ _1"></span>e health effects o
              <span className="_ _1"></span>f vitamin D insu
              <span className="_ _1"></span>
              fficiency inclu
              <span className="_ _1"></span>de strong
              <span className="_ _1"></span> influences on the dev
              <span className="_ _1"></span>elopm
              <span className="_ _1"></span>ent of{' '}
            </div>
            <div className="t m0 xa h2 y24f ff1 fs0 fc0 sc0 ls0 ws0">
              cancer and autoim<span className="_ _1"></span>m
              <span className="_ _1"></span>
              une diseases, such as insul<span className="_ _1"></span>
              in-dependent diabetes and m<span className="_ _1"></span>ultiple
              sclero
              <span className="_ _1"></span>sis. Vitam
              <span className="_ _1"></span>in D up-regulates the{' '}
            </div>
            <div className="t m0 xa h2 y29f ff1 fs0 fc0 sc0 ls0 ws0">
              immune response an<span className="_ _1"></span>d down-
              <span className="_ _1"></span>regulates cell proli
              <span className="_ _1"></span>feration and inf
              <span className="_ _1"></span>
              lammation.{' '}
            </div>
            <div className="t m0 xa h2 y2a0 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2a1 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2a2 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2a3 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf1f" className="pf w0 h0" data-page-no="1f">
          <div className="pc pc1f w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">30 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x42 hc y7 ffc fs0 fc0 sc0 ls0 ws0">
              Vitamin D 25-<span className="ls4">OH</span>{' '}
              <span className="ffa">
                (con’t)<span className="_ _1"></span>
                <span className="ffc"> </span>
              </span>
            </div>
            <div className="t m0 xa hc y8 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y33 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa hc y34 ffc fs0 fc0 sc0 ls0 ws0">
              Clinical Significanc<span className="_ _1"></span>e{' '}
            </div>
            <div className="t m0 xa h2 ya3 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y180 ffc fs0 fc0 sc0 ls0 ws0">
              Increased leve<span className="_ _1"></span>ls:{' '}
            </div>
            <div className="t m0 xa h2 y1eb ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c h2 y255 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Vitamin D toxic<span className="_ _1"></span>ity (hyperc
                  <span className="_ _1"></span>alcemia){' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y2a4 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c hc y1c8 ffc fs0 fc0 sc0 ls0 ws0">
              Decreased lev<span className="_ _1"></span>els:{' '}
            </div>
            <div className="t m0 xa h2 y1c9 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x1c h2 y2a5 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Rickets (children<span className="_ _1"></span>) osteomal
                  <span className="_ _1"></span>acia (adults){' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y2a6 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Osteoporosis </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y188 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Diabetes mellitus<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y2a7 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Osteoarthritis </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y2a8 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Hypertension </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y2a9 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Cardiovascular dise<span className="_ _1"></span>ase{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y2aa ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Metabolic syndrom<span className="_ _1"></span>e{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y2ab ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Depression </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y2ac ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Cancers of the breas<span className="_ _1"></span>t, prost
                  <span className="_ _1"></span>ate, and colon{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y257 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Musculoskeletal p<span className="_ _1"></span>ain{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1c h2 y258 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Chronic low-back<span className="_ _1"></span> pain{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1c h2 yb2 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Inflamm<span className="_ _1"></span>ation{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x1 hc y2ad ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x2e hc y2ae ffc fs0 fc0 sc0 ls0 ws0">
              Creatinine Clearan<span className="_ _1"></span>ce{' '}
            </div>
            <div className="t m0 xa h2 y2af ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2b0 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  Normal finding<span className="_ _1"></span>s{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y2b1 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x16 h2 y196 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Adult (&lt;40 yrs)<span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y197 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x43 h2 y2b2 ff1 fs0 fc0 sc0 ls0 ws0">
              Male: 107-<span className="_ _1"></span>139 mL/m
              <span className="_ _1"></span>
              in Female: 87-107 m<span className="_ _1"></span>L/min{' '}
            </div>
            <div className="t m0 x16 h2 y2b3 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x16 h2 y2b4 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Values decrease 6.<span className="_ _1"></span>5 mL/m
                  <span className="_ _1"></span>in/decade of life a
                  <span className="_ _1"></span>fter age 20 y
                  <span className="_ _1"></span>
                  ears with decline <span className="_ _1"></span>in glom
                  <span className="_ _1"></span>erular filtration ra
                  <span className="_ _1"></span>te (GFR).{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x44 h2 y2b5 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2b6 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">Indications </span>
              </span>
            </div>
            <div className="t m0 xa h2 y281 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x16 h2 y1e2 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  The creatinine cle<span className="_ _1"></span>arance is used{' '}
                  <span className="_ _1"></span>to measure the
                  <span className="_ _1"></span> GFR of the k
                  <span className="_ _1"></span>idney.{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y1e3 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2b7 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  Creatinine is <span className="_ _1"></span>excreted entir
                  <span className="_ _1"></span>ely by the k
                  <span className="_ _1"></span>
                  idneys and ther<span className="_ _1"></span>efore is direct
                  <span className="_ _1"></span>ly proportion
                  <span className="_ _1"></span>
                  al to the GFR (<span className="_ _1"></span>i.e., the number
                  o<span className="_ _1"></span>f{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y2b8 ff1 fs0 fc0 sc0 ls0 ws0">
              milliliters of fi<span className="_ _1"></span>ltrate made b
              <span className="_ _1"></span>y the kidney
              <span className="_ _1"></span>s per minute){' '}
            </div>
            <div className="t m0 x45 h2 y2b9 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2ba ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  The CrCl test <span className="_ _1"></span>requires a 24-hour
                  u<span className="_ _1"></span>rine collection{' '}
                  <span className="_ _1"></span>and a serum
                  <span className="_ _1"></span> creatinine lev
                  <span className="_ _1"></span>el. CrCl is then computed usi
                  <span className="_ _1"></span>ng the{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x12 h2 y2bb ff1 fs0 fc0 sc0 ls0 ws0">
              following form<span className="_ _1"></span>ula:{' '}
            </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf20" className="pf w0 h0" data-page-no="20">
          <div className="pc pc20 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">31 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x42 hc y7 ffc fs0 fc0 sc0 ls0 ws0">
              Creatinine Clearan<span className="_ _1"></span>ce{' '}
              <span className="ffa">(con’t)</span>{' '}
            </div>
            <div className="t m0 x46 h2 y8 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x46 h2 y33 ff1 fs0 fc0 sc0 ls0 ws0">
              Creatinine clear<span className="_ _1"></span>ance = UV/
              <span className="_ _1"></span>P{' '}
            </div>
            <div className="t m0 x16 h2 y34 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xf ha y35 ff8 fs0 fc0 sc0 ls0 ws0">
              U
              <span className="ff1">
                = number of m<span className="_ _1"></span>illigram
                <span className="_ _1"></span>s per deciliter of cre
                <span className="_ _1"></span>atinine ex
                <span className="_ _1"></span>
                creted in the u<span className="_ _1"></span>rine over{' '}
              </span>
            </div>
            <div className="t m0 x46 h2 y7f ff1 fs0 fc0 sc0 ls0 ws0">
              24 hours{' '}
            </div>
            <div className="t m0 xf ha y80 ff8 fs0 fc0 sc0 ls0 ws0">
              V
              <span className="ff1">
                = volum<span className="_ _1"></span>e of urine in milliliters
                pe
                <span className="_ _1"></span>r minute{' '}
              </span>
            </div>
            <div className="t m0 xf ha y81 ff8 fs0 fc0 sc0 ls0 ws0">
              P
              <span className="ff1">
                = serum creatin<span className="_ _1"></span>ine in millig
                <span className="_ _1"></span>rams per decili
                <span className="_ _1"></span>
                ter{' '}
              </span>
            </div>
            <div className="t m0 xf h2 y82 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y83 ff1 fs0 fc0 sc0 ls0 ws0">
              Clinical Signifi<span className="_ _1"></span>cance{' '}
            </div>
            <div className="t m0 x45 h2 y84 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2bc ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  Increased Level<span className="_ _1"></span>s{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y2bd ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Exercise </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y2be ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">Pregnancy </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y2bf ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  High cardiac o<span className="_ _1"></span>utput syndrom
                  <span className="_ _1"></span>es:{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 hc y2c0 ffc fs0 fc0 sc0 ls0 ws0">
              As blood flow incre<span className="_ _1"></span>ases to the ki
              <span className="_ _1"></span>dney, GFR and C
              <span className="_ _1"></span>
              rCl increase.{' '}
            </div>
            <div className="t m0 x16 hc y2c1 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2c2 ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  Decreased Lev<span className="_ _1"></span>els{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y2c3 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Impaired kidney<span className="_ _1"></span> function (e.g
                  <span className="_ _1"></span>., renal artery
                  <span className="_ _1"></span> atherosclerosis, g
                  <span className="_ _1"></span>lomerulonephrit
                  <span className="_ _1"></span>is, acute tubu
                  <span className="_ _1"></span>
                  lar necrosis{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x16 h2 y2c4 ff7 fs0 fc0 sc0 ls0 ws0">
              –
              <span className="ff5">
                {' '}
                <span className="_ _11"> </span>
                <span className="ff1">
                  Conditions causing<span className="_ _1"></span> decreased GF
                  <span className="_ _1"></span>R (e.g., conges
                  <span className="_ _1"></span>tive heart fa
                  <span className="_ _1"></span>
                  ilure [CHF], c<span className="_ _1"></span>irrhosis with asc
                  <span className="_ _1"></span>ites, shock,{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x44 h2 y2c5 ff1 fs0 fc0 sc0 ls0 ws0">
              dehydration{' '}
            </div>
            <div className="t m0 x12 hc y2c6 ffc fs0 fc0 sc0 ls0 ws0">
              Conditions that a<span className="_ _1"></span>re associated wi
              <span className="_ _1"></span>th decrease
              <span className="_ _1"></span>d blood flow to t
              <span className="_ _1"></span>he kidney wi
              <span className="_ _1"></span>ll decrease GFR.{' '}
            </div>
            <div className="t m0 x12 hc y2c7 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x47 hc y2c8 ffc fs0 fc0 sc0 ls0 ws0" id="EGFR">
              Glomerular Fil<span className="_ _1"></span>tration Rate
              <span className="_ _1"></span>, estimated (eGFR){' '}
            </div>
            <div className="t m0 x48 hc y2c9 ffc fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x12 h2 y2ca ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  Early detection of <span className="_ _1"></span>chronic k
                  <span className="_ _1"></span>idney disease{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y2cb ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x12 h2 y2cc ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  Chronic kidney dis<span className="_ _1"></span>ease is
                  defined a<span className="_ _1"></span>s GFR &lt;50 m
                  <span className="_ _1"></span>
                  l/min.{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y2cd ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x12 h2 y2ce ff9 fs0 fc0 sc0 ls0 ws0">
              ·
              <span className="ff5">
                {' '}
                <span className="_ _10"> </span>
                <span className="ff1">
                  Calculated by us<span className="_ _1"></span>ing serum crea
                  <span className="_ _1"></span>tinine, urea n
                  <span className="_ _1"></span>
                  itrogen, al<span className="_ _1"></span>bumin concentr
                  <span className="_ _1"></span>ation, age, sex, and{' '}
                  <span className="_ _1"></span>race{' '}
                </span>
              </span>
            </div>
            <div className="t m0 xa h2 y2cf ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x49 h2 y175 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2d0 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2d1 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2d2 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2d3 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2d4 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2d5 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2d6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2d7 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 xa h2 y2d8 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
          </div>
          <div
            className="pi"
            data-data='{"ctm":[1.500000,0.000000,0.000000,1.500000,0.000000,0.000000]}'
          ></div>
        </div>
        <div id="pf21" className="pf w0 h0" data-page-no="21">
          <div className="pc pc21 w0 h0">
            <div className="t m0 xc h2 y1 ff7 fs0 fc0 sc0 ls0 ws0">
              Functional Medicin<span className="_ _1"></span>e University
              <span className="_ _1"></span>‟s <span className="ff1"> </span>
            </div>
            <div className="t m0 xd h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">
              Functional Diag<span className="_ _1"></span>nostic Medicine{' '}
              <span className="_ _1"></span>Training Program
              <span className="_ _1"></span>{' '}
            </div>
            <div className="t m0 xe h2 y3 ff1 fs0 fc0 sc0 ls0 ws0">
              Mod 1 * Lesson 7:<span className="_ _1"></span> Blood Chem
              <span className="_ _1"></span>istry &amp; CBC A
              <span className="_ _1"></span>
              nalysis{' '}
            </div>
            <div className="t m0 xf h2 y4 ff1 fs0 fc0 sc0 ls0 ws0">
              By Way<span className="_ _1"></span>ne L. Sodano, D.C., D
              <span className="_ _1"></span>.A.B.C.I
              <span className="_ _1"></span>., &amp; Ron Grisanti, D.C., D.A
              <span className="_ _1"></span>.B.C.O., M.S
              <span className="_ _1"></span>.{' '}
            </div>
            <div className="t m0 x10 h2 y5 ff1 fs0 fc1 sc0 ls0 ws0">
              http://www.Func<span className="_ _1"></span>tionalMedicineUniv
              <span className="_ _1"></span>ersity.com
              <span className="fc0"> </span>
            </div>
            <div className="t m0 x1 h2 y6 ff1 fs0 fc0 sc0 ls0 ws0"> </div>
            <div className="t m0 x26 h2 y2f ff1 fs0 fc0 sc0 ls0 ws0">32 </div>
            <div className="t m0 x1 h9 y30 ff1 fs5 fc0 sc0 ls0 ws0"> </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LabsContent;
