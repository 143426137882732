import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import SymptomsDetail from '../../pages/symptoms/symptomsDetail';
import './baseline.scss';

function BaselineTwo({ currentUser, fbUser }) {
  const navigate = useNavigate();

  return (
    <div className="baseline page">
      <div className="user-data">
        <div className="logo-font logo">FMD</div>
        <img src={fbUser?.photoURL} alt="avatar" className="avatar" />
        <p>{currentUser?.displayName}</p>
        <h1>Your Inflammation Score</h1>
      </div>

      <SymptomsDetail
        currentUser={currentUser}
        fbUser={fbUser}
        isOnboarding={true}
      />

      <div className="footer">
        <Button callback={() => navigate('/baseline-3')}>
          Next
          <img className="next" src="/icons/chevron-down.svg" alt="Next" />
        </Button>
      </div>
    </div>
  );
}

export default BaselineTwo;
