import React, { useState, useEffect } from 'react';
import './admin.scss';
import { useNavigate, useParams } from 'react-router-dom';
import Home from '../home/home';
import { getUserDataByUserID } from '../../helpers/api';
import { getCalendarData } from '../../helpers/helpers';

function AdminDetail({ currentUser, fbUser }) {
  const [userData, setUserData] = useState(null);
  const [dates, setDates] = useState(null);
  const [combinedData, setCombinedData] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();

  const getUserData = async () => {
    if (currentUser?.email === 'enriqueflorendo@gmail.com') {
      const user = await getUserDataByUserID(null, id);
      setUserData(user);
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    if (!userData) {
      if (currentUser) {
        getUserData();
      }
    }
  }, [id]);

  useEffect(() => {
    const cData = getCalendarData(userData, setDates);
    setCombinedData(cData);
  }, [userData]);

  return (
    <div className="admin page">
      <div className="heading">
        <h1>
          {userData.displayName} <small>{userData.email}</small>
        </h1>
      </div>
      <Home currentUser={userData} combined={combinedData} isComponent={true} />
    </div>
  );
}

export default AdminDetail;
