import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import AddSymptoms from '../symptoms/addSymptoms/addSymptoms';
import './baseline.scss';

function BaselineOne({ setCurrentUser, currentUser, fbUser, combined }) {
  const navigate = useNavigate();
  const [isBaselineAbleToSubmitForm, setIsBaselineAbleToSubmitForm] =
    useState(false);
  const [submitFromOnboarding, setSubmitFromOnboarding] = useState(false);
  const [onboardingScore, setOnboardingScore] = useState(0);

  useEffect(() => {
    if (currentUser?.symptoms) {
      if (currentUser?.diets) {
        navigate('/baseline-3');
      } else {
        navigate('baseline-2');
      }
    }
  }, [currentUser]);

  const handleSubmit = () => {
    if (!isBaselineAbleToSubmitForm) return;
    setSubmitFromOnboarding(true);
    setTimeout(() => {
      navigate('/baseline-2');
    }, 500);
  };
  return (
    <div className="baseline page">
      <h1>Questionaire</h1>
      <AddSymptoms
        setCurrentUser={setCurrentUser}
        isBaseline={true}
        submitFromOnboarding={submitFromOnboarding}
        setIsBaselineAbleToSubmitForm={setIsBaselineAbleToSubmitForm}
        currentUser={currentUser}
        setOnboardingScore={setOnboardingScore}
        combined={combined}
      />
      <div className="footer">
        <Button type="short" callback={() => navigate('/baseline')}>
          <img className="back" src="/icons/chevron-down.svg" alt="back" />
        </Button>
        <div
          className={isBaselineAbleToSubmitForm ? 'button' : 'button disabled'}
          onClick={() => handleSubmit()}
        >
          Submit Symptoms
          <div className="icon-text">
            <small className="faded">Score {onboardingScore}</small>
            <img className="next" src="/icons/chevron-down.svg" alt="Next" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BaselineOne;
