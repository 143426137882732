import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Searchbar from '../../components/Searchbar/Searchbar';
import { doctors } from './doctorsData';
import { practitioners } from '../../data/practitioners';
import './doctors.scss';
import '../elimination/elimination.scss';
import Navigation from '../../layout/Navigation/Navigation';

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

function Doctors({ type, currentUser, fbUser }) {
  const [searchTerm, setSearchTerm] = useState('');

  const formattedPractitioners = practitioners.map((p) => ({
    ...p,
    address: p.contactText.split('US')[0].split('Address')[1],
    phone: formatPhoneNumber(
      p.contactText.split('US')[1]?.replace(/[- )(]/g, '')
    ),
  }));

  const submitForm = () => {
    //submit Google Form
  };
  return (
    <>
      {!type && <Navigation currentUser={currentUser} fbUser={fbUser} />}
      <div
        className={
          type
            ? 'elimination horizontal doctors'
            : `elimination page page-with-footer doctors`
        }
      >
        <h1>
          Practioners
          {type && <Link to="/practitioners">View All</Link>}
        </h1>

        {!type && (
          <>
            <div style={{ fontSize: '.85em' }}>
              Functional medicine is a personalized approach to healthcare that
              focuses on identifying and addressing the root causes of chronic
              diseases, rather than just treating symptoms. It considers the
              complex interactions between genetics, lifestyle, and
              environmental factors to develop individualized treatment plans.
            </div>
            <br />
          </>
        )}
        {!type && (
          <Searchbar
            placeholder="Search by designation, zip, city, or name..."
            handleSearch={setSearchTerm}
            searchTerm={searchTerm}
          />
        )}

        <div className="grid">
          {formattedPractitioners
            ?.filter((d) => {
              if (searchTerm.length === 0) return d;
              const lowerCasedSearch = searchTerm.toLowerCase();
              if (d.name.toLowerCase().includes(lowerCasedSearch)) return true;
              if (d.contactText.toString().includes(lowerCasedSearch))
                return true;
              if (d.state.toLowerCase().includes(lowerCasedSearch)) return true;
            })
            .map((m, index) => (
              <div className="grid-item" key={`doctors-${index}`}>
                <div
                  className="avatar-container logo-font"
                  style={{ backgroundImage: `url(${m.avatar})` }}
                >
                  {!m.avatar && 'FMD'}
                </div>
                <h3>
                  <a href={m.link} target="_blank" rel="noreferrer">
                    {m.name}
                  </a>
                </h3>
                <small className="faded">
                  <a
                    href="https://www.ifm.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="ifm-logo"
                      src="/icons/icon-ifm-logo.svg"
                      alt="IFM Certified Practitioner"
                    />
                    IFM Certified
                  </a>
                </small>

                <div className="line-item">
                  <div>{m.address}</div>
                </div>
                <div className="line-item">
                  <a href={`tel:${m.phone}`}>{m.phone}</a>
                </div>
                {/* {m.virtual && (
                  <div className="line-item">
                    <div>Telehealth/Remote Available</div>
                  </div>
                )} */}
                {/* <button onClick={() => submitForm(m)}>Request Virtual Appointment</button> */}
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default Doctors;
