import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navigation from '../../layout/Navigation/Navigation';
import { questionnaire } from './questionaireData';
import '../elimination/elimination.scss';
import { dateIsInWeek } from '../../helpers/helpers';

function Symptoms({ type, currentUser, fbUser, combinedDates }) {
  const navigate = useNavigate();
  const [dataWithScored, setDataWithScore] = useState(null);

  const getScoreStyle = (score) => {
    if (+score < dataWithScored[0]?.data.length * 4 * 0.25) {
      return 'success';
    }
    if (+score <= dataWithScored[0]?.data.length * 4 * 0.5) {
      return 'warning';
    }
    return 'danger';
  };

  useEffect(() => {
    const d = currentUser?.symptoms;
    if (!d) return;
    const array = Object.values(d);
    const cData = array
      .map((m) => {
        const scored = m.data.filter((m) => m.Score > 0);
        return { ...m, scored };
      })
      .sort((a, b) => +b.id - +a.id);

    //add combinedDates to cData
    if (!combinedDates) return;
    const elim = cData.map((c) => {
      const data = combinedDates.find((cc) => cc.id === c.id);
      return { ...c, startDate: data.startDate, endDate: data.endDate };
    });
    setDataWithScore(elim);
  }, [currentUser, combinedDates]);

  useEffect(() => {
    if (!currentUser || !combinedDates) return;
    const tId = combinedDates.find((c) => dateIsInWeek(c))?.id;

    const element = document.getElementById(`symptoms-${tId}`);
    if (!element) return;
    if (type) return;
    setTimeout(() => {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 500);
  }, [currentUser, combinedDates]);
  return (
    <>
      <Navigation currentUser={currentUser} fbUser={fbUser} />
      <div
        className={
          type ? 'elimination horizontal' : `elimination page page-with-footer`
        }
      >
        <h1>
          Symptom Tracker
          {type && <Link to="/symptoms">View All</Link>}
        </h1>
        {!type && (
          <>
            <div style={{ fontSize: '.85em' }}>
              Record of the symptoms you experience while on the elimination
              diet. A lower score is better.
            </div>
            <br />
          </>
        )}
        <div className="grid">
          {dataWithScored?.map((m, idx) => (
            <div
              key={`symptoms-${m.id || 1}`}
              id={`symptoms-${m.id || 1}`}
              className={`grid-item grid-item-elimination ${
                dateIsInWeek(m) ? 'active' : ''
              }`}
              onClick={() => navigate(`/symptoms/${m.id}`)}
            >
              <h3 className="">
                {m?.week === 0 ? (
                  'Baseline'
                ) : (
                  <>
                    Week {m.week} & {m.week + 1}
                    <img src="/icons/chevron-down.svg" alt="link" />
                  </>
                )}
              </h3>
              <small className="faded">
                {m.date &&
                  new Date(m?.date)?.toLocaleDateString('en-US', {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
              </small>
              <h2>
                <span className={`${getScoreStyle(m?.total)}`}>{m.total}</span>
                <small className="faded">/ {questionnaire?.length * 4}</small>
              </h2>
              <div className="scored">
                {m.scored.map((s, index) => (
                  <small className="faded" key={`scored-${index}`}>
                    {s.Symptom}
                    {m.scored.length === index + 1 ? '' : ', '}
                  </small>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Symptoms;
