import React, { useEffect, useState } from 'react';
import './admin.scss';
import { getAdminData } from '../../helpers/api';
import { Link, useNavigate } from 'react-router-dom';

const headers = [
  { title: 'Email' },
  { title: 'Name' },
  { title: 'Started' },
  { title: 'Symptom', type: 'short' },
  { title: 'Diet', type: 'short' },
  { title: 'Lifestyle', type: 'short' },
  { title: 'Labs', type: 'short' },
];

function Admin({ currentUser }) {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  if (!data) {
    if (currentUser) {
      if (currentUser?.email === 'enriqueflorendo@gmail.com') {
        getAdminData(setData, setFilteredData);
      } else {
        navigate('/');
      }
    }
  }

  useEffect(() => {
    if (data) {
      const newData = [...data];
      setFilteredData(
        newData.filter(
          (n) =>
            n.email?.toLowerCase()?.includes(searchQuery) ||
            n.displayName?.toLowerCase()?.includes(searchQuery)
        )
      );
    }
  }, [searchQuery]);

  return (
    <div className="admin page">
      <h1>Admin</h1>
      <div className="search">
        <input
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search"
        />
      </div>
      <div className="div-table">
        <div className="div-row">
          {headers.map((h) => (
            <div className={`div-cell ${h.type ? h.type : ''}`}>{h.title}</div>
          ))}
        </div>
        {filteredData &&
          filteredData.map((d) => (
            <div className="div-row">
              <div className="div-cell">
                <Link to={`/admin/${d.id}`}>{d.email}</Link>
              </div>
              <div className="div-cell">{d.displayName}</div>
              <div className="div-cell">
                {d.symptoms && Object.values(d.symptoms)[0]?.date}
              </div>
              <div className="div-cell short">
                {d.symptoms && Object.values(d.symptoms)?.length}
              </div>
              <div className="div-cell short">
                {d.diets && Object.values(d.diets)?.length}
              </div>
              <div className="div-cell short">
                {d.lifestyle && Object.values(d.lifestyle)?.length}
              </div>
              <div className="div-cell short">
                {d.labs && Object.values(d.labs)?.length}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Admin;
