import React from 'react';
import Navigation from '../../layout/Navigation/Navigation';
import Doctors from '../doctors/doctors';
import Elimination from '../elimination/elimination';
import Symptoms from '../symptoms/symptoms';
import Chart from '../chart/chart';
import './home.scss';
import Calendar from '../calendar/calendar';
import Labs from '../labs/labs';

function Home({ currentUser, fbUser, combined, isComponent }) {
  return (
    <>
      <Navigation currentUser={currentUser} fbUser={fbUser} />
      <div className="home">
        {/* <div className="logo-font logo">FMD</div> */}
        {!isComponent && (
          <>
            {' '}
            <img className="logo" src="/FMD.svg" alt="logo" />
            <h6>
              <b>
                Today
                <span>
                  {' '}
                  is{' '}
                  {new Date().toLocaleDateString('en-US', {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                </span>
              </b>
            </h6>
          </>
        )}

        <div className="container">
          <Calendar
            type="horizontal"
            currentUser={currentUser}
            fbUser={fbUser}
            combined={combined}
          />
        </div>
        {/* <div className="container">
          <Daily
            type="horizontal"
            currentUser={currentUser}
            fbUser={fbUser}
            combined={combined}
          />
        </div> */}
        <div className="container">
          <Elimination
            type="horizontal"
            currentUser={currentUser}
            fbUser={fbUser}
            combinedDates={combined}
          />
        </div>

        <div className="container">
          <Symptoms
            type="horizontal"
            currentUser={currentUser}
            fbUser={fbUser}
            combinedDates={combined}
          />
        </div>
        {!isComponent && (
          <div className="container">
            <Doctors
              type="horizontal"
              currentUser={currentUser}
              fbUser={fbUser}
            />
          </div>
        )}

        <div className="container">
          <Labs type="horizontal" currentUser={currentUser} fbUser={fbUser} />
        </div>

        <div className="container">
          <Chart
            showTotal={true}
            type="horizontal"
            currentUser={currentUser}
            fbUser={fbUser}
          />
        </div>
      </div>
    </>
  );
}

export default Home;
