import { authFire, fireStore } from './fire';
import {
  addDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  getDocs,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

export const getUserDataByUserID = async (setCurrentUser, idFromAdmin) => {
  const user = authFire.currentUser;
  const id = idFromAdmin || user.uid;
  if (id) {
    const docRef = doc(fireStore, 'users', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setCurrentUser && setCurrentUser(docSnap.data());
      return docSnap.data();
    } else {
      //create user
      console.log('no such user.');
    }
  }
};

export const getAdminData = async (setData, setFilteredData) => {
  const user = authFire.currentUser;
  if (user.uid) {
    const collectionData = [];
    const querySnapshot = await getDocs(collection(fireStore, 'users'));
    querySnapshot.forEach((doc) => {
      collectionData.push(doc.data());
    });
    setData(collectionData);
    setFilteredData(collectionData);
    return collectionData;
  }
};

const updateCurrentUser = async (collection, updatedCurrentUser) => {
  const res = await setDoc(
    doc(fireStore, 'users', updatedCurrentUser.id),
    updatedCurrentUser
  );
  return res;
};

export const addNewSymptomsToSymptoms = async (
  newSymptoms,
  currentUser,
  setCurrentUser
) => {
  const fbId = uuidv4();

  const res = await setDoc(
    doc(fireStore, 'users', currentUser.id),
    {
      symptoms: {
        [fbId]: {
          ...newSymptoms,
          fbId,
        },
      },
    },
    { merge: true }
  );
  setTimeout(() => {
    getUserDataByUserID(setCurrentUser);
  }, 500);
  return res;
};

export const addNewLifestyleToLifestyle = async (
  newLifestyle,
  currentUser,
  setCurrentUser
) => {
  const fbId = uuidv4();

  const res = await setDoc(
    doc(fireStore, 'users', currentUser.id),
    {
      lifestyle: {
        [fbId]: {
          ...newLifestyle,
          fbId,
        },
      },
    },
    { merge: true }
  );
  setTimeout(() => {
    getUserDataByUserID(setCurrentUser);
  }, 500);
  return res;
};

export const addNewDietToDiets = async (
  newDiet,
  currentUser,
  setCurrentUser
) => {
  const fbId = uuidv4();

  const res = await setDoc(
    doc(fireStore, 'users', currentUser.id),
    {
      diets: {
        [fbId]: {
          ...newDiet,
          fbId,
        },
      },
    },
    { merge: true }
  );
  setTimeout(() => {
    getUserDataByUserID(setCurrentUser);
  }, 500);
  return res;
};

export const updateLab = async (newLab, currentUser, setCurrentUser) => {
  const res = await setDoc(
    doc(fireStore, 'users', currentUser.id),
    {
      labs: {
        [newLab.fbId]: {
          ...newLab,
        },
      },
    },
    { merge: true }
  );
  setTimeout(() => {
    getUserDataByUserID(setCurrentUser);
  }, 500);
  return res;
};

export const addNewLabsToLabs = async (newLab, currentUser, setCurrentUser) => {
  const fbId = uuidv4();

  const res = await setDoc(
    doc(fireStore, 'users', currentUser.id),
    {
      labs: {
        [fbId]: {
          ...newLab,
          fbId,
        },
      },
    },
    { merge: true }
  );
  setTimeout(() => {
    getUserDataByUserID(setCurrentUser);
  }, 500);

  return res;
};

export const checkIfFbUserThenSetDb = async (currentUser, setCurrentUser) => {
  const user = authFire.currentUser;

  if (user.uid) {
    const docRef = doc(fireStore, 'users', user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      if (currentUser) return;
      setCurrentUser(docSnap.data());
      return docSnap.data();
    } else {
      //create user
      const userToSave = {
        id: user.uid,
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        isAnonymous: user.isAnonymous,
        paypal: '',
        diets: '',
        symptoms: '',
      };
      await setDoc(doc(fireStore, 'users', user.uid), userToSave);
      setCurrentUser(userToSave);
    }
  }
};

export const updateDiet = async (newDiet, currentUser, setCurrentUser) => {
  //find fbId in diets
  const dietArray = Object.values(currentUser.diets);
  const fbId = dietArray.find((d) => +d.id === +newDiet.id).fbId;

  const res = await setDoc(
    doc(fireStore, 'users', currentUser.id),
    {
      diets: {
        [fbId]: {
          ...newDiet,
          fbId,
        },
      },
    },
    { merge: true }
  );
  setTimeout(() => {
    getUserDataByUserID(setCurrentUser);
  }, 500);
  return res;
};

export const updateSymptoms = async (
  newSymptoms,
  currentUser,
  setCurrentUser
) => {
  //find fbId in diets
  const symptomArray = Object.values(currentUser.symptoms);
  const fbId = symptomArray.find((d) => +d.id === +newSymptoms.id).fbId;

  const res = await setDoc(
    doc(fireStore, 'users', currentUser.id),
    {
      symptoms: {
        [fbId]: {
          ...newSymptoms,
          fbId,
        },
      },
    },
    { merge: true }
  );
  setTimeout(() => {
    getUserDataByUserID(setCurrentUser);
  }, 500);
  return res;
};

export const updateLifestyle = async (
  newLifestyle,
  currentUser,
  setCurrentUser
) => {
  //find fbId in diets
  const lifestyleArray = Object.values(currentUser.lifestyle);
  const fbId = lifestyleArray.find((d) => +d.id === +newLifestyle.id).fbId;

  const res = await setDoc(
    doc(fireStore, 'users', currentUser.id),
    {
      lifestyle: {
        [fbId]: {
          ...newLifestyle,
          fbId,
        },
      },
    },
    { merge: true }
  );
  setTimeout(() => {
    getUserDataByUserID(setCurrentUser);
  }, 500);
  return res;
};
