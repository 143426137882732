export const getNotice = (c, selectedSex) => {
  if (selectedSex === 'Male') {
    if (+c.optimalLow > +c.userValue) {
      return <div className="danger">LOW</div>;
    }
    if (+c.optimalHigh < +c.userValue) {
      return <div className="danger">HIGH</div>;
    }
    if (+c.optimalLow <= +c.userValue && +c.optimalHigh >= +c.userValue) {
      return <div className="success">OPTIMAL</div>;
    }
  }
  if (selectedSex === 'Female') {
    if (+c.optimalLowWoman > +c.userValue) {
      return <div className="danger">LOW</div>;
    }
    if (+c.optimalHighWoman < +c.userValue) {
      return <div className="danger">HIGH</div>;
    }
    if (
      +c.optimalLowWoman <= +c.userValue &&
      +c.optimalHighWoman >= +c.userValue
    ) {
      return <div className="success">OPTIMAL</div>;
    }
  }
};

const incrementCount = (c, count, selectedSex) => {
  if (selectedSex === 'Male') {
    if (+c.optimalLow > +c.userValue) {
      count.low = count.low + 1;
    }
    if (+c.optimalHigh < +c.userValue) {
      count.high = count.high + 1;
    }
    if (+c.optimalLow <= +c.userValue && +c.optimalHigh >= +c.userValue) {
      count.optimal = count.optimal + 1;
    }
  }
  if (selectedSex === 'Female') {
    if (+c.optimalLowWoman > +c.userValue) {
      count.low = count.low + 1;
    }
    if (+c.optimalHighWoman < +c.userValue) {
      count.high = count.high + 1;
    }
    if (
      +c.optimalLowWoman <= +c.userValue &&
      +c.optimalHighWoman >= +c.userValue
    ) {
      count.optimal = count.optimal + 1;
    }
  }
  return count;
};

export const getNoticeCount = (selectedLabData, selectedSex) => {
  let counted = {
    low: 0,
    high: 0,
    optimal: 0,
  };

  selectedLabData.forEach((c) => {
    counted = incrementCount(c, counted, selectedSex);
  });
  return counted;
};
